<div class="container-fluid bg-container p-0 pt-1">
  <div class="px-1 pb-3">
    <app-search-panel styleClass="row jumbotron px-0 m-0 py-2 pb-0"></app-search-panel>

    <div *ngIf="!error && showSearchHint" class="alert alert-secondary mb-1" i18n="search not yet started message" role="alert">Enter search term and click
      search...
    </div>

    <!-- Show error -->
    <div *ngIf="error" class="alert alert-danger mb-1" i18n="search error message" role="alert">{{error}}</div>

    <!-- Show search results -->
    <app-listings-view (loadMoreListings)="loadMoreListings($event)" [infiniteScrollPercentage]="90" [listings]="listings" [periodInDays]="periodInDays"
                       [showMyListingMarker]="true" [thereIsMore]="thereIsMore" [totalCount]="totalCount"></app-listings-view>

    <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true"></app-loading-spinner>

  </div>
</div>
