import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorMessageConverterPipe} from './auth/pipes/error-message-converter.pipe';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    // {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptorService, multi: true},
    ErrorMessageConverterPipe
  ],
})
export class CoreModule {
}
