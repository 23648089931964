import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Category} from '../../../../shared/models/category.interface';
import {UtilService} from '../../../../shared/util.service';
import {RatingSummary} from '../../../../shared/models/ratingSummary.interface';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {UserPublic} from '../../../../shared/models/userPublic.interface';
import {UserService} from '../../../../shared/services/user.service';
import {Listing} from '../../../../shared/models/listing.interface';
import {CategoryService} from '../../../../shared/services/category.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.reducer';

@Component({
  selector: 'app-report-listing-preview',
  templateUrl: './report-listing-preview.component.html',
  styleUrls: ['./report-listing-preview.component.scss'],
})
export class ReportListingPreviewComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  category?: Category;
  lender?: UserPublic;
  lenderRatingSummary?: RatingSummary;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public utilService: UtilService,
      public categoryService: CategoryService,
      private cdRef: ChangeDetectorRef,
  ) {
    super(store);
  }

  _listing: Listing | undefined;

  get listing(): Listing | undefined {
    return this._listing;
  }

  @Input() set listing(listing: Listing | undefined) {
    this._listing = listing;
    if (!listing)
      return;

    // Load category
    if (listing.categoryId)
      this.categoryService.getCategoriesById().then(wrapper => {
        if (wrapper.data)
          this.category = wrapper.data.get(listing.categoryId!);
        if (wrapper.errorMessage)
          this.addError($localize`Error loading category for listing\: ${wrapper.errorMessage}`);
      });

    // Load lender
    if (listing.lenderUid)
      this.userService.fetchUserPublic(listing.lenderUid).then(wrapper => {
        if (wrapper.data) {
          this.lender = wrapper.data;
          this.lenderRatingSummary = this.userService.calculateRatingSummary(this.lender);
        }
        if (wrapper.errorMessage)
          this.addError($localize`Error loading lender for listing\: ${wrapper.errorMessage}`);
      });
  }

  ngOnInit(): void {
    super.ngOnInit();

  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

}
