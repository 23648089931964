import {Component, OnDestroy, OnInit} from '@angular/core';
import {TitleService} from '../../shared/services/title.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.css'],
})
export class LoggedOutComponent implements OnInit, OnDestroy {
  private navigateAwayTimeout?: NodeJS.Timeout;

  constructor(private titleService: TitleService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.titleService.setTitle($localize`Logged out`);
    this.navigateAwayTimeout = setTimeout(() => {
      this.router.navigate(['/']);
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.navigateAwayTimeout)
      clearTimeout(this.navigateAwayTimeout);
  }

}
