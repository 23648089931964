<div *ngIf="visible" class="component-container">
    <button (click)="onCancel()" aria-label="Cancel" class="cancel-button" i18n-aria-label="Cancel" i18n-matTooltip="Cancel" id="booking-date-selection"
            mat-icon-button matTooltip="Cancel">
        <mat-icon>close</mat-icon>
    </button>

    <div *ngIf="listingDoesNotExist" class="alert alert-danger" i18n="suggest another time error listing not existing">The listing for
        <i>{{ transaction?.transactionListing?.name }}</i> does no longer exist. The booking is still valid, but you cannot change the date and time any more.
    </div>

    <form (ngSubmit)="onSuggest()" *ngIf="bookForm !== undefined && transaction && listing" [appDateRangePicker]="{rentParams: rentParams, listing: listing,
          availableDayTimestamps: availableDayTimestamps, dateRangeInvalidDates: dateRangeInvalidDates, transactionUid: transaction?.uid}"
          [formGroup]="bookForm">
        <!-- Price table-->
        <app-listing-price-table [currencyId]="transaction.currencyId" [listing]="listing"></app-listing-price-table>

        <!-- Book form -->
        <h1 class="paragraph-headline" i18n="Suggest another time">Suggest another time</h1>
        <div class="mb-2">
            <app-info-card i18n="warning changing booking time changes price" icon="warning">Changing the booking time can result in a different booking price.
            </app-info-card>
        </div>
        <div class="container m-0 p-0">
            <div class="row m-0 p-0">
                <div class="col m-0 p-0">
                    <mat-form-field>
                        <mat-label i18n="rent date range picker placeholder">Rent period</mat-label>
                        <mat-date-range-input [dateFilter]="availabilityFilter" [max]="maxDate" [min]="minDate" [rangePicker]="picker">
                            <input formControlName="dateFrom"
                                   i18n-placeholder="rent date range picker rent from placeholder" id="dateFrom" matStartDate placeholder="Rent from">
                            <input formControlName="dateUntil"
                                   i18n-placeholder="rent date range picker rent until placeholder" id="dateUntil" matEndDate placeholder="Rent until">
                        </mat-date-range-input>
                        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="col m-0 p-0">
                    <div class="material-time-picker">
                        <mat-form-field>
                            <mat-label i18n="pick-up time">Pick-up time</mat-label>
                            <input [format]="timePickerFormat" [ngxTimepicker]="timeFromPicker" formControlName="timeFrom" matInput readonly>
                            <ngx-material-timepicker #timeFromPicker [minutesGap]="5"></ngx-material-timepicker>
                            <ngx-material-timepicker-toggle [for]="timeFromPicker" matSuffix><span class="material-icons">schedule</span>
                            </ngx-material-timepicker-toggle>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col m-0 p-0">
                    <div class="material-time-picker">
                        <mat-form-field>
                            <mat-label i18n="return time">Return time</mat-label>
                            <input [format]="timePickerFormat" [ngxTimepicker]="timeUntilPicker" formControlName="timeUntil" matInput readonly>
                            <ngx-material-timepicker #timeUntilPicker [minutesGap]="5"></ngx-material-timepicker>
                            <ngx-material-timepicker-toggle [for]="timeUntilPicker" matSuffix><span class="material-icons">schedule</span>
                            </ngx-material-timepicker-toggle>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <app-listing-date-range-validation-messages [bookForm]="bookForm" [dateRangeInvalidDates]="dateRangeInvalidDates" [listing]="listing"
                                                    [rentParams]="rentParams"
                                                    [transactionUid]="transaction.uid"></app-listing-date-range-validation-messages>

        <app-booking-price-table [bookForm]="bookForm" [currencyId]="transaction.currencyId" [currentRentPeriod]="transaction.numberOfDays"
                                 [rentParams]="rentParams"
                                 [showPrice]="true"></app-booking-price-table>

        <!-- Error messages -->
        <div *ngFor="let error of errors" class="alert alert-danger" role="alert">{{ error }}</div>

        <p *ngIf="user?.uid === transaction.lenderUid"><span
                i18n>If you submit your suggestion, you're sending the borrower a binding booking request. If they accept, the broker contract is completed. Therefore, submitting a suggestion is the same as accepting a booking request from the borrower. </span><span
                [innerHTML]="bookingConfirmationTerms"></span></p>

        <app-mat-icon-button *ngIf="updateBookingPeriod" [callback]="onSuggest.bind(this)" [disabled]="!bookForm?.valid" color="primary"
                             cssClass="mr-2 mb-3" i18n-label="Suggest new date and time button" icon="edit_calendar"
                             label="Suggest new date and time"></app-mat-icon-button>
        <app-mat-icon-button [callback]="onCancel.bind(this)" color="warn"
                             cssClass="mr-2 mb-3" i18n-label="cancel" icon="close"
                             label="Cancel"></app-mat-icon-button>
    </form>
</div>
