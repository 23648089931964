import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GENERAL_REPORT_CATEGORIES, LISTING_REPORT_CATEGORIES, RATING_REPORT_CATEGORIES, ReportCategory} from '../../../shared/models/reportCategory.interface';
import {ReportType} from '../../../shared/models/reportType.type';
import {Editor} from 'ngx-editor';
import {UserService} from '../../../shared/services/user.service';
import {User} from '../../../shared/models/user.interface';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {toolbar, toolbarMd1, toolbarMd2, toolbarSm1, toolbarSm2, toolbarSm3} from '../../../shared/config/editorToolbar';
import {options} from '../../../shared/config/editorOptions';
import {LanguageService} from '../../../shared/services/language.service';
import {MailTemplate} from '../../../shared/models/mailTemplate.interface';
import {environment} from '../../../../environments/environment';
import {setReportCategoryInTemplate} from '../../../shared/clientServerShared';
import {Listing} from '../../../shared/models/listing.interface';
import {Rating} from '../../../shared/models/rating.interface';
import {Mail} from '../../../shared/models/mail.interface';
import firebase from 'firebase/app';
import {MailService} from '../../../shared/services/mail.service';
import {AppState} from '../../../store/app.reducer';
import {Store} from '@ngrx/store';
import Timestamp = firebase.firestore.Timestamp;
import FirebaseError = firebase.FirebaseError;

@Component({
  selector: 'app-confirm-send-message-dialog',
  templateUrl: './confirm-send-message-dialog.component.html',
  styleUrls: ['./confirm-send-message-dialog.component.css'],
})
export class ConfirmSendMessageDialogComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  title: string;
  message: string;
  listing?: Listing;
  rating?: Rating;
  categoryId?: string;
  receiverUid?: string;
  action?: ActionType;
  type?: ReportType;
  public transactionUid?: string;

  receiver?: User;

  notifyForm!: FormGroup;
  reportCategories: ReportCategory[] = [];

  messageMaxLength = 10000;

  /**
   * WYSIWYG editor for item description
   */
  editor: Editor = new Editor(options);
  toolbarSm1 = toolbarSm1;
  toolbarSm2 = toolbarSm2;
  toolbarSm3 = toolbarSm3;
  toolbarMd1 = toolbarMd1;
  toolbarMd2 = toolbarMd2;
  toolbar = toolbar;

  private readonly spinnerKeyCreateMail = 'createMail';

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public dialogRef: MatDialogRef<ConfirmSendMessageDialogComponent>,
      public languageService: LanguageService,
      @Inject(MAT_DIALOG_DATA) public data: ConfirmSendMessageDialogModel,
      private mailService: MailService,
      private cdRef: ChangeDetectorRef,
      private formBuilder: FormBuilder) {
    super(store);
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.listing = data.listing;
    this.rating = data.rating;
    this.categoryId = data.categoryId;
    this.receiverUid = data.receiverUid;
    this.action = data.action;
    this.type = data.type;
    this.transactionUid = data.transactionUid;
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.type === 'listing') {
      this.reportCategories = [...LISTING_REPORT_CATEGORIES, ...GENERAL_REPORT_CATEGORIES];
    }
    if (this.type === 'rating') {
      this.reportCategories = [...RATING_REPORT_CATEGORIES, ...GENERAL_REPORT_CATEGORIES];
    }
    this.notifyForm = this.createNotifyForm();

    if (this.receiverUid)
      this.user$.subscribe(user => {
        if (user)
          this.receiver = user;
      });
    this.fetchUserActionResult$.subscribe(result => {
      if (result.errorMessage)
        this.addError(result.errorMessage);
    });

  }

  onConfirm(): void {
    // Create mail
    if (this.notifyForm.value.notify)
      this.createMail();

    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  getYesButtonLabel(): string {
    switch (this.notifyForm.value.notify) {
      case true: {
        switch (this.action) {
          case 'deleteListing':
            return $localize`Delete listing and notify`;
          case 'deleteRating':
            return $localize`Delete rating and notify`;
          case 'disableListing':
            return $localize`Disable listing and notify`;
          case 'enableListing':
            return $localize`Enable listing and notify`;
        }
        break;
      }
      case false: {
        switch (this.action) {
          case 'deleteListing':
            return $localize`Delete listing`;
          case 'deleteRating':
            return $localize`Delete rating`;
          case 'disableListing':
            return $localize`Disable listing`;
          case 'enableListing':
            return $localize`Enable listing`;
        }
        break;
      }
    }
    return $localize`Yes`;

  }

  private createNotifyForm(): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
      notify: [true],
      categoryId: [this.categoryId],
      message: [null, [Validators.required, Validators.maxLength(this.messageMaxLength)]],
    });

  }

  private createMail() {
    const lang = this.receiver?.settings?.lang ? this.receiver.settings.lang : 'en';
    const template: MailTemplate = {
      name: `${environment.mailTemplateAdminNotify}_${lang}`,
      data: {
        applicationRootUrl: environment.applicationRootUrl,
        displayName: this.receiver?.displayName,
        senderDisplayName: this.user?.displayName,
        senderUid: this.user?.uid,
        message: this.notifyForm.value.message,
      },
    };

    setReportCategoryInTemplate(template, this.notifyForm.value.categoryId);

    if (this.listing) {
      template.data.listingUid = this.listing.uid;
      template.data.listingName = this.listing.name;
      if (this.listing.imgUrls && this.listing.imgUrls[0]?.thumb)
        template.data.listingImgUrlThumb = this.listing.imgUrls[0].thumb;
      template.data.listingSummary = this.listing.summary;
      template.data.listingDescription = this.listing.description;
    }

    if (this.rating) {
      template.data.ratingUid = this.rating.uid;
      template.data.ratingComment = this.rating.comment;
    }

    switch (this.action) {
      case 'deleteListing': {
        template.data.listingWasDeleted = true;
        break;
      }
      case 'deleteRating': {
        template.data.ratingWasDeleted = true;
        template.data.transactionUid = this.transactionUid;
        break;
      }
      case 'disableListing': {
        template.data.listingWasDisabled = true;
        break;
      }
      case 'enableListing': {
        template.data.listingWasEnabled = true;
        break;
      }
    }
    // Create mail
    const mail: Mail = {to: this.receiver?.email, template, creationDate: Timestamp.now(), replyTo: this.user?.email};
    this.addLoadingSpinnerMessage(this.spinnerKeyCreateMail, $localize`Creating mail...`);
    this.mailService.sendMail(mail).then(value => {
          this.addSuccess($localize`Mail was created.`);
          this.removeLoadingSpinnerMessage(this.spinnerKeyCreateMail);
        },
        (reason: FirebaseError) => {
          this.addError($localize`Error creating mail\: ${reason}`);
          this.removeLoadingSpinnerMessage(this.spinnerKeyCreateMail);
        });
  }
}

/**
 * Class to represent confirm / send message dialog model. *
 */
export class ConfirmSendMessageDialogModel {

  constructor(public title: string,
              public message: string,
              public listing?: Listing,
              public rating?: Rating,
              public categoryId?: string,
              public receiverUid?: string,
              public action?: ActionType,
              public type?: ReportType,
              public transactionUid?: string,
  ) {
  }
}

export type ActionType = 'enableListing' | 'disableListing' | 'deleteListing' | 'deleteRating';
