import {Component, OnDestroy, OnInit} from '@angular/core';
import {BrowserService} from '../../browser.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UtilService} from '../../../shared/util.service';
import Util from '../../../shared/util';
import {Listing} from '../../../shared/models/listing.interface';
import {Category} from '../../../shared/models/category.interface';
import {SharedService} from '../../../shared/services/shared.service';
import {takeUntil} from 'rxjs/operators';
import {CurrencyService} from '../../../shared/services/currency.service';
import {CategoryService} from '../../../shared/services/category.service';
import {TitleService} from '../../../shared/services/title.service';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {AnalyticsEventName, AnalyticsService} from '../../../shared/services/analytics.service';
import {FacebookEventName, FacebookService} from '../../../shared/services/facebook.service';
import {environment} from '../../../../environments/environment';
import {MetadataService} from '../../../shared/services/metadata.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {selectSearchListings} from '../../store/browser.selectors';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css'],
})
export class CategoryComponent extends BaseComponent implements OnInit, OnDestroy {

  paramsSubscription: Subscription | undefined;
  category: Category | undefined;
  categoryId: string | undefined;
  path: Category[] = [];
  listings: Listing[] = [];
  error: string | undefined;

  totalCount = 0;
  thereIsMore = true;
  searching = false;
  allCategories: Category[] = [];
  periodInDays = 1;
  subcategoriesShown = false;
  searchListings$ = this.store.select(selectSearchListings).pipe(takeUntil(this.destroy$));

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private browserService: BrowserService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private metadataService: MetadataService,
              private utilService: UtilService,
              public sharedService: SharedService,
              public currencyService: CurrencyService,
              public categoryService: CategoryService,
              private titleService: TitleService,
              private facebookService: FacebookService,
              private analyticsService: AnalyticsService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Retrieve selected id from activatedRoute params
    this.paramsSubscription = this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe(
        (params) => {
          const categoryId = params.get('categoryId');
          // If there is no category, navigate away
          if (categoryId === null || categoryId === undefined) {
            this.router.navigate(['..'], {relativeTo: this.activatedRoute});
            return;
          }
          this.analyticsService.logEvent(AnalyticsEventName.CATEGORY_VIEW, {categoryId});
          this.facebookService.logEvent(FacebookEventName.ViewContent, {
            content_category: categoryId,
            product_catalog_id: environment.facebookCatalogId,
          });
          this.categoryId = categoryId;
          this.listings = [];
          this.thereIsMore = false;
          this.browserService.updateSearchTerm();
          this.browserService.fetchLatestListings(this.categoryId);
          Util.scrollToTop();

          this.categoryService.getCategories().then(wrapper => {
            if (wrapper.data) {
              this.allCategories = wrapper.data;
              this.updateView();
            }
          });
        },
    );
    this.searchListings$.subscribe((searchListings) => {
      this.showLoadingSpinner = searchListings.searching;
      this.error = searchListings.searchResult?.errorMessage;
      this.listings = searchListings.searchResult ? searchListings.searchResult.listings : [];
      this.thereIsMore = searchListings.searchResult ? searchListings.searchResult?.thereIsMore : false;
      this.totalCount = searchListings?.searchResult?.totalCount !== undefined ? searchListings.searchResult.totalCount : 0;
      this.searching = searchListings.searching;
      this.periodInDays = searchListings.searchResult?.periodInDays ? searchListings.searchResult?.periodInDays : 1;
    });


    this.browserService.periodInDaysSubject.pipe(takeUntil(this.destroy$)).subscribe(periodInDays => {
      this.periodInDays = periodInDays;
    });
    this.metadataService.updateTags(this.category ? this.categoryService.getCategoryName(this.category) : $localize`Category view`,
        this.category ? this.categoryService.getCategoryDescription(this.category) : $localize`Category view`,
        $localize`Blitzshare, sharing community, private rentals, borrow almost everything, rent out all the things`,
        this.category?.imgUrlThumb ? [this.category.imgUrlThumb] : [],
    );
  }

  updateView(): void {

    if (this.allCategories.length === 0 || !this.categoryId)
      return;
    // Load the path (as a side effect of getCategoriesPathRecursive)
    this.path = [];
    this.category = this.categoryService.getCategoriesPathRecursive(this.categoryId, this.allCategories, this.path);
    this.browserService.selectedCategorySubject.next(this.category);
    if (this.category === undefined) {
      this.router.navigate(['..'], {relativeTo: this.activatedRoute});
      return;
    }
    this.titleService.setTitle(this.categoryService.getCategoryName(this.category));
  }


  /**
   * Requests more search listings
   * @param count requested number of additional listings
   */
  loadMoreListings(count: number): void {
    if (!this.listings || !this.thereIsMore || this.searching)
      return;
    this.browserService.fetchMoreSearchResults();
  }

  onCategorySelected(cat: Category) {
    this.category = cat;
    this.router.navigate(['/browse', 'category', cat.id]);
  }
}
