import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {setBookingMessage, setBookingParams} from './book.actions';
import {Listing} from '../../shared/models/listing.interface';


export interface BookState {
  listing?: Listing;
  // Note: dateFrom?: Date, dateUntil?: Date, timeFrom?: string and timeUntil?: string; were removed. Instead, the values from browser store will be used from now on.
  bookingMessage?: string;
}

export const initialState: BookState = {
  listing: undefined,
};

function logDebugMessages(actionName: string, state: BookState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

const reducer: ActionReducer<BookState> = createReducer(
    initialState,

    on(setBookingParams, (state, {listing}) => {
      // Make an immutable copy. State changes must always be immutable by convention.
      const newState = {...state, listing};
      logDebugMessages('setBookingParams', state, newState);
      return newState;
    }),
    on(setBookingMessage, (state, {bookingMessage}) => {
      // Make an immutable copy. State changes must always be immutable by convention.
      const newState = {...state, bookingMessage: bookingMessage};
      logDebugMessages('setBookingMessage', state, newState);
      return newState;
    }),
);

export function bookReducer(state: BookState | undefined, action: Action): BookState {
  return reducer(state, action);
}

