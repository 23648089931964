import {Component, Input, OnInit} from '@angular/core';
import {Rating} from '../../models/rating.interface';
import {TransactionRole} from '../../enums/transactionRole.enum';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css'],
})
export class RatingComponent implements OnInit {

  @Input() rating?: Rating;
  @Input() showListing? = false;
  @Input() linkRating = false;

  constructor() {
  }

  public get transactionRole(): typeof TransactionRole {
    return TransactionRole;
  }

  ngOnInit(): void {
  }

}
