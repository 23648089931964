import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {
  abort,
  fetchLatestListingsFromAlgolia,
  fetchMoreSearchResultsFromAlgolia,
  fetchSearchResultsFromAlgolia,
  resetSearch,
  searchFailed,
  setDateAndTimePeriod,
  setMoreSearchResults,
  setSearchCategory,
  setSearchResults,
  setSearchSortIndex,
  updateSearchTerm,
} from './browser.actions';
import {environment} from '../../../environments/environment';
import {Listing} from '../../shared/models/listing.interface';
import {SearchResult} from '../../shared/models/searchResult.interface';
import {Search} from '../../shared/models/search.interface';


export interface BrowserState {
  searchListings: SearchListings;
  fetchListing: FetchListing;
  listingsByIdCache: Map<string, Listing>;
}

export interface SearchListings {
  search: Search;
  searching: boolean;
  searchResult: SearchResult;
}

export interface FetchListing {
  id: string | undefined;
  maxAgeInSec: number | undefined;
  listing: Listing | undefined;
  errorMessage: string | undefined;
}


export const initialState: BrowserState = {
  searchListings: {
    search: {
      radius: 20,
    },
    searching: false,
    searchResult: {
      listings: [],
      thereIsMore: false,
    },
  },
  listingsByIdCache: new Map<string, Listing>(),
  fetchListing: {
    id: undefined,
    maxAgeInSec: environment.defaultListingCacheAgeInSec,
    listing: undefined,
    errorMessage: undefined,
  },
};

function logDebugMessages(actionName: string, state: BrowserState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

const reducer: ActionReducer<BrowserState> = createReducer(
    initialState,

    // Searching
    on(resetSearch, (state) => {
      const searchResult: SearchResult = {...state.searchListings.searchResult, listings: [], thereIsMore: false, errorMessage: undefined};
      const searchListings: SearchListings = {...state.searchListings, searchResult, searching: false};
      const newState = {...state, searchListings};
      logDebugMessages('resetSearch', state, newState);
      return newState;
    }),
    on(updateSearchTerm, (state, {searchTerm}) => {
      // Make an immutable copy. State changes must always be immutable by convention.
      const searchListings: SearchListings = {...state.searchListings, search: {...state.searchListings.search, searchTerm}};
      const newState = {...state, searchListings};
      logDebugMessages('updateSearchTerm', state, newState);
      return newState;
    }),
    on(setSearchCategory, (state, {categoryId}) => {
      // Make an immutable copy. State changes must always be immutable by convention.
      const searchListings: SearchListings = {...state.searchListings, search: {...state.searchListings.search, categoryId}};
      const newState = {...state, searchListings};
      logDebugMessages('setSearchCategory', state, newState);
      return newState;
    }),
    on(setSearchSortIndex, (state, {sortIndex}) => {
      // Make an immutable copy. State changes must always be immutable by convention.
      const searchListings: SearchListings = {...state.searchListings, search: {...state.searchListings.search, sortIndex}};
      const newState = {...state, searchListings};
      logDebugMessages('setSearchSortIndex', state, newState);
      return newState;
    }),
    on(setDateAndTimePeriod, (state, {dateFrom, dateUntil, timeFrom, timeUntil}) => {
      // Make an immutable copy. State changes must always be immutable by convention.
      let searchListings = state.searchListings;
      if (timeFrom && timeUntil)
        searchListings = {...state.searchListings, search: {...state.searchListings.search, dateFrom, dateUntil, timeFrom, timeUntil}};
      else
        searchListings = {...state.searchListings, search: {...state.searchListings.search, dateFrom, dateUntil}};
      const newState = {...state, searchListings};
      logDebugMessages('setDateAndTimePeriod', state, newState);
      return newState;

    }),
    on(fetchSearchResultsFromAlgolia, (state, {search}) => {
      const searchResult: SearchResult = {...state.searchListings.searchResult, listings: [], thereIsMore: false, errorMessage: undefined};
      const searchListings: SearchListings = {...state.searchListings, search: {...search, offset: 0}, searching: true, searchResult};
      const newState = {...state, searchListings};
      logDebugMessages('fetchSearchResultsFromAlgolia', state, newState);
      return newState;
    }),
    on(fetchMoreSearchResultsFromAlgolia, (state) => {
      const searchResult: SearchResult = {...state.searchListings.searchResult, thereIsMore: false, errorMessage: undefined};
      const search: Search = {...state.searchListings.search, offset: state.searchListings.searchResult.listings.length};
      const searchListings: SearchListings = {...state.searchListings, searching: true, searchResult, search};
      const newState = {...state, searchListings};
      logDebugMessages('fetchMoreSearchResultsFromAlgolia', state, newState);
      return newState;
    }),
    on(fetchLatestListingsFromAlgolia, (state, {categoryId}) => {
      const search: Search = {...state.searchListings.search, categoryId, lenderUid: undefined};
      const searchListings: SearchListings = {...state.searchListings, search};
      const newState = {...state, searchListings};
      logDebugMessages('fetchLatestListingsFromAlgolia', state, newState);
      return newState;
    }),
    on(setSearchResults, (state, {searchResult}) => {
      const listingsByIdCache: Map<string, Listing> = addListingsToCache(searchResult.listings, state.listingsByIdCache);
      const searchListings: SearchListings = {...state.searchListings, searching: false, searchResult};
      const newState = {...state, searchListings, listingsByIdCache};
      logDebugMessages('setSearchResults', state, newState);
      return newState;
    }),
    on(setMoreSearchResults, (state, {searchResult}) => {
      const listings: Listing[] = [];
      listings.push(...state.searchListings.searchResult.listings);
      listings.push(...searchResult.listings);
      const listingsByIdCache: Map<string, Listing> = addListingsToCache(searchResult.listings, state.listingsByIdCache);
      const newSearchResult: SearchResult = {...searchResult, listings};
      const searchListings: SearchListings = {...state.searchListings, searching: false, searchResult: newSearchResult};
      const newState = {...state, searchListings, listingsByIdCache};
      logDebugMessages('setMoreSearchResults', state, newState);
      return newState;
    }),
    on(searchFailed, (state, {errorMessage}) => {
      const searchResult: SearchResult = {...state.searchListings.searchResult, errorMessage, thereIsMore: false};
      const searchListings: SearchListings = {...state.searchListings, searching: false, searchResult};
      const newState = {...state, searchListings};
      logDebugMessages('searchFailed', state, newState);
      return newState;
    }),

    on(abort, (state, {reason}) => {
      const newState = {...state};
      logDebugMessages('Aborting. Reason: ' + reason, state, newState);
      return newState;
    }),
);

export function browserReducer(state: BrowserState | undefined, action: Action): BrowserState {
  return reducer(state, action);
}

/**
 * Clones the given map and adds the given listings to the new map.
 * @param listings listings to be added to cloned map.
 * @param listingsByIdCache map of listings by ID. Key = listingId, value = listing.
 */
function addListingsToCache(listings: Listing[], listingsByIdCache: Map<string, Listing>): Map<string, Listing> {
  if (!(listingsByIdCache instanceof Map))
    listingsByIdCache = new Map<string, Listing>();

  // Create copy to keep immutability
  const newListingsByIdCache: Map<string, Listing> = new Map(listingsByIdCache);
  for (const listing of listings) {
    if (listing.uid !== undefined) {
      newListingsByIdCache.set(listing.uid, listing);
    }
  }
  return newListingsByIdCache;
}


