import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserPublic} from '../../../shared/models/userPublic.interface';
import {UserService} from '../../../shared/services/user.service';
import {UtilService} from '../../../shared/util.service';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {SharedService} from '../../../shared/services/shared.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';


@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css'],
})
export class UserInfoComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() listingUid?: string;
  @Input() userPublicUid?: string;
  userPublic?: UserPublic;
  errorMessage?: string;


  showWriteMessageComponent = false;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private sharedService: SharedService,
      public utilService: UtilService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.userPublicUid) {
      this.userService.fetchUserPublic(this.userPublicUid).then(wrapper => {
        this.userPublic = wrapper.data;
        this.errorMessage = wrapper.errorMessage;
        if (!this.userPublic)
          this.errorMessage = $localize`The user was not found or does not exist.`;
      });
    }
  }


  onSendMessage(): void {
    this.showWriteMessageComponent = true;
    this.utilService.scrollToId('write-message');
  }

  getDate(date: Date): Date {
    return date;
  }
}
