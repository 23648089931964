import {Toolbar} from 'ngx-editor';

export const toolbarSm1: Toolbar = [['bold', 'italic', 'underline', 'strike'],
  ['text_color', 'background_color']];
export const toolbarSm2: Toolbar = [[{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
  ['ordered_list', 'bullet_list'], ['code']];
export const toolbarSm3: Toolbar = [['align_left', 'align_center', 'align_right', 'align_justify'], ['link', 'image']];
export const toolbarMd1: Toolbar = [['bold', 'italic', 'underline', 'strike'],
  ['text_color', 'background_color'], [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}]];
export const toolbarMd2: Toolbar = [['ordered_list', 'bullet_list'], ['code'], ['align_left', 'align_center', 'align_right', 'align_justify'], ['link', 'image']];
export const toolbar: Toolbar = [
  ...toolbarSm1,
  ...toolbarSm2,
  ...toolbarSm3,
  // ['blockquote'],
];
