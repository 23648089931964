import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

@Directive({
  selector: '[appLte]',
  providers: [{provide: NG_VALIDATORS, useExisting: LteDirective, multi: true}],
})
export class LteDirective implements Validator {
  @Input() appLte = 0;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.appLte ? lteValidator(this.appLte)(control)
      : null;
  }
}

export function lteValidator(maxValue: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const lte = control.value > maxValue;
    return lte ? {appLte: {value: control.value}} : null;
  };
}
