<div class="card-group">
    <div *ngFor="let category of categories" class="card inner-card mb-1">
        <a *ngIf="category.imgUrlThumb" [routerLink]="['/browse','category',category?.id]">
            <img [src]="category.imgUrlThumb" alt="{{categoryService.getCategoryName(category)}} image"
                 class="card-img-top contained-150h"
                 default="./assets/images/default/default-category-w300.webp"
                 i18n-alt="Category image alternative text"></a>
        <div class="card-body bg-white">
            <a [routerLink]="['/browse','category',category?.id]"><h2 class="paragraph-headline-2">{{categoryService.getCategoryName(category)}}</h2></a>
            <p class="card-text">{{categoryService.getCategoryDescription(category)|shorten:100:true}}</p>
            <ul>
                <li *ngFor="let subsubcategory of categoryService.getSubcategories(category)">
                    <app-category-list-item [category]="subsubcategory"></app-category-list-item>
                </li>
            </ul>
        </div>
    </div>
</div>
