import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LatestListingsComponent} from './browse/latest-listings/latest-listings.component';
import {BrowserComponent} from './browser.component';
import {ListingComponent} from './listing/listing.component';
import {CategoryComponent} from './browse/category/category.component';
import {AllCategoriesComponent} from './browse/all-categories/all-categories.component';
import {SearchComponent} from './search/search.component';

const browserRoutes: Routes = [
  {
    path: 'browse',
    component: BrowserComponent,
    children: [
      {path: '', component: LatestListingsComponent, data: {reuse: true}},
      {path: 'latest-listings', component: LatestListingsComponent, data: {reuse: true}},
      {path: 'category/:categoryId', component: CategoryComponent, data: {reuse: true}},
      {path: 'category', component: AllCategoriesComponent, data: {reuse: true}},
      {path: 'listing/:listingId', component: ListingComponent, data: {reuse: false}}, // Reusing does not work, because this.listing will be set to the last listing, when reloading
    ],
  },
  {path: 'search', component: SearchComponent, data: {reuse: true}},
];

@NgModule({
  imports: [RouterModule.forChild(browserRoutes)],
  exports: [RouterModule],
})
export class BrowserRoutingModule {
}
