<mat-toolbar color="primary">

  <a class="navbar-brand" routerLink="/" style="cursor: pointer;">
    <img alt="Blitzshare Logo"
         class="align-top img-fluid app-icon d-block d-xs-none"
         i18n-alt="Blitzshare icon alt text"
         src="./assets/images/icons/icon-w64.webp"
    >
    <picture>
      <source media="(max-width: 150px)" srcset="./assets/images/logos/logo-w150.webp">
      <source media="(max-width: 200px)" srcset="./assets/images/logos/logo-w200.webp">
      <source media="(max-width: 300px)" srcset="./assets/images/logos/logo-w300.webp">
      <img alt="Alexander Schwartz logo" class="align-top img-fluid app-logo d-none d-xs-block" i18n-alt="Alt text of Alexander Schwartz logo"
           src="./assets/images/logos/logo-w500.webp">
    </picture>
  </a>

  <form (ngSubmit)="onSubmit()" *ngIf="searchForm !== undefined" [formGroup]="searchForm" class="d-none d-md-block form-inline mr-2">
    <input (change)="syncSearchTerm()" (keyup)="syncSearchTerm()" (paste)="syncSearchTerm()"
           (search)="syncSearchTerm()"
           aria-label="Search" class="form-control mr-sm-2 search-input"
           formControlName="searchTerm" i18n-aria-label="Search"
           i18n-placeholder="header search input field placeholder text|The placeholder text of the search field in the site header" id="searchTermHeader"
           placeholder="Search" type="search">

    <button aria-label="Search" class="btn btn-accent" i18n-aria-label="Search" id="searchButtonHeader" routerLink="/search" routerLinkActive="active"
            type="submit">
      <svg class="bi bi-search" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
      </svg>
    </button>
  </form>
  <a class="btn btn-accent mr-2 d-none d-md-block" i18n="list item header button text" routerLink="/listing/create"
     routerLinkActive="active">List for rent</a>
  <a class="btn btn-accent mr-2 d-none d-lg-block" i18n="browse header link text" routerLink="/browse/latest-listings"
     routerLinkActive="active">Latest listings</a>
  <a class="btn btn-accent mr-2 d-none d-xl-block" i18n="All categories header link text" routerLink="/browse/category"
     routerLinkActive="active">Categories</a>
  <a class="btn btn-accent d-none d-xl-block" i18n="my blitzshare button|my blitzshare button" routerLink="/account/"
     routerLinkActive="active">My Blitzshare</a>

  <button [matMenuTriggerFor]="menu" aria-label="Menu" class="d-block d-xl-none" i18n-aria-label="Menu" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <a class="btn btn-menu d-block d-md-none text-left" i18n="list item header button text" mat-menu-item routerLink="/listing/create"
       routerLinkActive="menu-active">List for rent</a>
    <a class="btn btn-menu d-block d-lg-none text-left" i18n="browse header link text" mat-menu-item routerLink="/browse/latest-listings"
       routerLinkActive="menu-active">Latest listings</a>
    <a class="btn btn-menu d-block d-xl-none text-left" i18n="All categories header link text" mat-menu-item routerLink="/browse/category"
       routerLinkActive="menu-active">Categories</a>
    <a class="btn btn-menu d-block d-xl-none text-left" i18n="my blitzshare button|my blitzshare button" mat-menu-item routerLink="/account"
       routerLinkActive="menu-active">My Blitzshare</a>
    <a [matMenuTriggerFor]="langMenu" aria-label="Language" class="btn btn-menu d-block d-lg-none text-left" i18n="language selection menu"
       i18n-aria-label="Language menu button" mat-menu-item>Language</a>

    <mat-menu #langMenu="matMenu">
      <button (click)="selectLang(lang)" *ngFor="let lang of langs" [class]="lang.code === currentLang?.code ? 'bg-primary text-white' : ''" mat-menu-item>
        <img class="mr-1" src="./assets/images/icons/flags/{{lang.iconCode}}-{{iconSize}}.webp">
        <span>{{languageService.getLanguageNameByCode(lang.code)}}</span>
      </button>
    </mat-menu>

  </mat-menu>

  <span class="spacer"></span>
  <button aria-label="Search" class="mr-2 d-block d-md-none" color="accent" i18n-aria-label="Search" mat-icon-button routerLink="/search">
    <mat-icon>search</mat-icon>
  </button>

  <app-lang-selector class="d-none d-lg-block mr-2"></app-lang-selector>
  <app-conversations-button class="mr-2"></app-conversations-button>
  <app-notifications-button class="mr-2"></app-notifications-button>
  <app-login-button></app-login-button>
</mat-toolbar>

<app-announcements></app-announcements>
<app-account-setup-warning></app-account-setup-warning>


