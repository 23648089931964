import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {addListingPrivateToCache, addListingToCache} from './listing.actions';
import {Listing} from '../../shared/models/listing.interface';
import {ListingPrivate} from '../../shared/models/listingPrivate.interface';


export interface ListingState {
  listings: Listing[];
  listingsPrivate: ListingPrivate[];
}

export const initialState: ListingState = {
  listings: [],
  listingsPrivate: [],
};

function logDebugMessages(actionName: string, state: ListingState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

const reducer: ActionReducer<ListingState> = createReducer(
    initialState,

    on(addListingToCache, (state, {listing}) => {
      const newListings: Listing[] = [];
      const nowUtc = new Date().getTime();
      for (const listingFromState of state.listings) {
        // Skip the listing with the ID of the given listing, because we will add the given listing object later
        if (listing.uid === listingFromState.uid)
          continue;
        // Also skip, if the cached listing is too old (or has no cache date)
        if (!listingFromState.cacheDate || (nowUtc - listingFromState.cacheDate.getTime() > environment.defaultListingCacheAgeInSec * 1000))
          continue;
        const listingCopy: Listing = {...listingFromState};
        newListings.push(listingCopy);
      }
      newListings.push(listing);
      const newState = {...state, listings: newListings};

      logDebugMessages('addListingToCache', state, newState);
      return newState;
    }),
    on(addListingPrivateToCache, (state, {listingPrivate}) => {
      const newListingsPrivate: ListingPrivate[] = [];
      const nowUtc = new Date().getTime();
      for (const listingPrivateFromState of state.listingsPrivate) {
        // Skip the listingPrivate with the ID of the given listingPrivate, because we will add the given listingPrivate object later
        if (listingPrivate.uid === listingPrivateFromState.uid)
          continue;
        // Also skip, if the cached listingPrivate is too old (or has no cache date)
        if (!listingPrivateFromState.cacheDate || (nowUtc - listingPrivateFromState.cacheDate.getTime() > environment.defaultListingCacheAgeInSec * 1000))
          continue;
        const listingPrivateCopy: ListingPrivate = {...listingPrivateFromState};
        newListingsPrivate.push(listingPrivateCopy);
      }
      newListingsPrivate.push(listingPrivate);
      const newState = {...state, listingsPrivate: newListingsPrivate};

      logDebugMessages('addListingPrivateToCache', state, newState);
      return newState;
    }),
);

export function listingReducer(state: ListingState | undefined, action: Action): ListingState {
  return reducer(state, action);
}

