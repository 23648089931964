<div class="container-fluid bg-container p-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Admin" routerLink="..">Admin</a>
      <li class="breadcrumb-item">
        <span i18n="categories editor headline">Categories editor</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="category editor headline">Category editor</h1>
    <p class="jumbotron-lead" i18n="category editor lead text">Manage categories: Add new one, alter existing ones, change hierarchies and add or change
      translations.</p>
  </div>


  <!-- Show errors and success messages-->
  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <div class="row">
    <div class="col lists-col">
      <app-categories-editor-lists (onCategorySelected)="onCategorySelected($event)" [categories]="categories" [resetSubject]="resetSubject"
                                   [showTrashBin]="showTrashBin"></app-categories-editor-lists>
    </div>
    <div class="col edit-col">
      <app-categories-editor-form [category]="selectedCategory" [resetSubject]="resetSubject"></app-categories-editor-form>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-mat-icon-button [callback]="onSave.bind(this)" [params]="[]" color="primary" cssClass="mr-1 mb-3" i18n-label="button label save" icon="save"
                           label="Save"></app-mat-icon-button>
      <app-mat-icon-button [callback]="onReset.bind(this)" [params]="[]" color="warn" cssClass="mr-1 mb-3" i18n-label="button label reset" icon="restart_alt"
                           label="Reset"></app-mat-icon-button>
      <app-mat-icon-button [callback]="onImportExport.bind(this)" [params]="[]" color="accent" cssClass="mr-1 mb-3" i18n-label="button label import export"
                           icon="import_export" label="Import/Export"></app-mat-icon-button>
      <app-mat-icon-button [callback]="onUpdateStore.bind(this)" [params]="[]" color="accent" cssClass="mr-1 mb-3" i18n-label="button label update store"
                           icon="sync"
                           label="Update store"></app-mat-icon-button>
      <mat-slide-toggle [(ngModel)]="showTrashBin" color="primary" i18n="show trash bin">Show trash bin</mat-slide-toggle>
    </div>

  </div>
</div>
