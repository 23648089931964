import {User} from '../models/user.interface';
import {Message} from '../models/message.interface';
import {Notification} from '../models/notification.interface';
import {Transaction} from '../models/transaction.interface';
import {Listing} from '../models/listing.interface';
import {Rating} from '../models/rating.interface';
import {UserPublic} from '../models/userPublic.interface';
import {Payment} from '../models/payment.interface';
import {ListingPrivate} from '../models/listingPrivate.interface';
import firebase from 'firebase/app';
// for cloud firestore
import {Conversation} from '../models/conversation.interface';
import {Announcement} from '../models/announcement.interface';
import {Category} from '../models/category.interface';
import {Faq} from '../models/faq.interface';
import {Report} from '../models/report.interface';
import {Currency} from '../models/currency.interface';
import {Referral} from '../models/referral.interface';
import DocumentData = firebase.firestore.DocumentData;


export function convertToUser(data: DocumentData): User {
  if (!data?.uid || !data.displayName)
    throw new Error(`Invalid user. Data is missing: data?.uid=${data?.uid}, data.displayName=${data.displayName}`);

  const user: User = {
    creationDate: data.creationDate,
    uid: data.uid,
    displayName: data.displayName,
    ...data,
  };
  if (data.address)
    user.address = data.address;
  if (data.settings)
    user.settings = data.settings;
  if (data.firstName)
    user.firstName = data.firstName;
  if (data.lastName)
    user.lastName = data.lastName;
  if (data.telephoneNumber)
    user.telephoneNumber = data.telephoneNumber;
  if (data.admin)
    user.admin = data.admin;
  if (data.email)
    user.email = data.email;
  if (data.emailVerified)
    user.emailVerified = data.emailVerified;
  if (data.lastEditDate)
    user.lastEditDate = data.lastEditDate;
  if (data.photoURL)
    user.photoURL = data.photoURL;
  return user;
}

export function convertToUserPublic(data: DocumentData): UserPublic {
  if (!data?.uid || !data.displayName)
    throw new Error(`Invalid user. Data is missing: data?.uid=${data?.uid}, data.displayName=${data.displayName}`);

  const userPublic: UserPublic = {
    creationDate: data.creationDate,
    uid: data.uid,
    displayName: data.displayName,
    ...data,
  };
  if (data.ratings)
    userPublic.ratings = data.ratings;
  if (data.imgUrl)
    userPublic.imgUrl = data.imgUrl;
  if (data.imgUrlThumb)
    userPublic.imgUrlThumb = data.imgUrlThumb;
  return userPublic;
}

export function convertToMessage(data: DocumentData) {
  if (!data?.message || !data?.senderUid || !data?.receiverUid || !data?.date)
    throw new Error(`Invalid message. Data is missing: data?.message=${data?.message}, data?.senderUid=${data?.senderUid},
    data?.receiverUid=${data?.receiverUid}, data?.date=${data?.date}`);

  const message: Message = {
    message: data.message,
    senderUid: data.senderUid,
    receiverUid: data.receiverUid,
    date: data.date,
    ...data,
  };
  if (data.listingUid)
    message.listingUid = data.listingUid;
  return message;
}

export function convertToCategory(data: DocumentData): Category {
  if (!data?.id || !data?.strings || data?.level === undefined)
    throw new Error(`Invalid conversation. Data is missing: data?.id=${data?.id}, data?.strings=${data?.strings}, data?.level=${data?.level}`);

  const category: Category = {
    id: data.id,
    imgUrlFull: data.imgUrlFull,
    strings: data.strings,
    level: data.level,
    ...data,
  };
  if (data.imgUrlFull)
    category.imgUrlFull = data.imgUrlFull;
  if (data.imgUrlFull)
    category.imgUrlThumb = data.imgUrlThumb;
  if (data.subcategories)
    category.subcategories = data.subcategories;
  return category;
}

export function convertToCurrency(data: DocumentData): Currency {
  if (!data?.id || !data?.identifier || !data?.symbol || !data?.strings)
    throw new Error(`Invalid conversation. Data is missing: data?.id=${data?.id}, data?.strings=${data?.strings}, data?.symbol=${data?.symbol}, data?.identifier=${data?.identifier}`);

  const currency: Currency = {
    id: data.id,
    identifier: data.identifier,
    strings: data.strings,
    symbol: data.symbol,
    ...data,
  };
  return currency;
}

export function convertToConversation(data: DocumentData): Conversation {
  if (!data?.user1Uid || !data?.user2Uid || !data?.latestMessageDate || data?.user1Read === undefined || data?.user2Read === undefined)
    throw new Error(`Invalid conversation. Data is missing: data?.user1Uid=${data?.user1Uid}, data?.user2Uid=${data?.user2Uid},
     data?.latestMessageDate=${data?.latestMessageDate}, data?.user1Read=${data?.user1Read}, data?.user2Read=${data?.user2Read}`);

  const conversation: Conversation = {
    user1Uid: data.user1Uid,
    user2Uid: data.user2Uid,
    user1Read: data.user1Read,
    user2Read: data.user2Read,
    latestMessageDate: data.latestMessageDate,
    ...data,
  };
  if (data.listingUid)
    conversation.listingUid = data.listingUid;
  if (data.embeddedListing)
    conversation.embeddedListing = data.embeddedListing;
  return conversation;
}

export function convertToNotification(data: DocumentData, uid: string): Notification {
  if (!data?.userUid || !data?.creationDate || !data?.notificationType || data?.read === undefined)
    throw new Error(`Invalid notification. Data is missing: data?.userUid=${data?.userUid}, data?.creationDate=${data?.creationDate},
     data?.notificationType=${data?.notificationType}, data?.read=${data?.read}`);

  const notification: Notification = {
    uid,
    userUid: data.userUid,
    read: data.read,
    creationDate: data.creationDate,
    notificationType: data.notificationType,
    actionUrl: data.actionUrl,
    ...data,
  };
  if (data.otherUser)
    notification.otherUser = data.otherUser;
  if (data.embeddedListing)
    notification.embeddedListing = data.embeddedListing;
  if (data.embeddedTransaction)
    notification.embeddedTransaction = data.embeddedTransaction;
  return notification;
}

export function convertToReferral(data: DocumentData, uid: string): Referral {
  if (!data?.name || data?.provisionPercentage === undefined || !data?.referrerUid)
    throw new Error(`Invalid referral. Data is missing: data?.name=${data?.name}, data?.provisionPercentage=${data?.provisionPercentage},
     data?.referrerUid=${data?.referrerUid}`);

  const referral: Referral = {
    name: data.name,
    provisionPercentage: data.provisionPercentage,
    referrerUid: data.referrerUid,
    uid,
    ...data,
  };
  if (data.lastEditDate)
    referral.lastEditDate = data.lastEditDate;
  if (data.creationDate)
    referral.creationDate = data.creationDate;
  if (data.commission)
    referral.commission = data.commission;
  if (data.transactionCount)
    referral.transactionCount = data.transactionCount;
  return referral;
}

export function convertToTransaction(data: DocumentData): Transaction {

  if (!data?.bookingDate || !data?.borrowerUid || !data?.lenderUid || !data?.listingUid || data?.logs === undefined || data?.numberOfDays === undefined || data?.pricePerDay === undefined
      || data?.transactionListing === undefined || !data?.targetPickupDate || !data?.targetReturnDate || !data?.lastUpdate || !data.paymentState)
    throw new Error(`Invalid transaction. Data is missing. ${data?.bookingDate} ${data?.borrowerUid} ${data?.lenderUid} ${data?.listingUid} ${data?.logs} ${data?.numberOfDays} ${data?.pricePerDay} ${data?.transactionListing} ${data?.targetPickupDate} ${data?.targetReturnDate} ${data?.lastUpdate} ${data.paymentState}`);

  const transaction: Transaction = {
    bookingDate: data.bookingDate,
    borrowerUid: data.borrowerUid,
    lenderUid: data.lenderUid,
    listingUid: data.listingUid,
    logs: data.logs,
    numberOfDays: data.numberOfDays,
    pricePerDay: data.pricePerDay,
    transactionListing: data.transactionListing,
    targetPickupDate: data.targetPickupDate,
    targetReturnDate: data.targetReturnDate,
    lastUpdate: data.lastUpdate,
    paymentState: data.paymentState,
    ...data,
  };
  if (data.paidAmount)
    transaction.paidAmount = data.paidAmount;
  if (data.refundedAmount)
    transaction.refundedAmount = data.refundedAmount;
  if (data.receiverAmount)
    transaction.receiverAmount = data.receiverAmount;
  if (data.newPeriodSuggestion)
    transaction.newPeriodSuggestion = data.newPeriodSuggestion;
  if (data.periodSuggestionState)
    transaction.periodSuggestionState = data.periodSuggestionState;

  return transaction;
}

export function convertToAnnouncement(data: DocumentData): Announcement {
  if (!data?.message || !data.creationDate || !data.priority || !data.environment)
    throw new Error(`Invalid announcement. Data is missing. data?.message=${data?.message}, data?.creationDate=${data?.creationDate}, data?.priority=${data?.priority}, data?.environment=${data?.environment}`);

  const announcement: Announcement = {
    message: data.message,
    priority: data.priority,
    environment: data.environment,
    creationDate: data.creationDate,
    ...data,
  };

  if (data.lastEditDate)
    announcement.lastEditDate = data.lastEditDate;
  if (data.validFrom)
    announcement.validFrom = data.validFrom;
  if (data.validUntil)
    announcement.validUntil = data.validUntil;
  return announcement;
}

export function convertToFaq(data: DocumentData): Faq {
  if (!data.creationDate)
    throw new Error(`Invalid faq. Data is missing. data?.creationDate=${data?.creationDate}`);

  const faq: Faq = {
    strings: data.strings,
    priority: data.priority,
    creationDate: data.creationDate,
    ...data,
  };
  return faq;
}

export function convertToReport(data: DocumentData): Report {
  if (!data.creationDate || !data.reporterUid || !data.categoryId || !data.reason || data.allowContact === undefined || !data.type)
    throw new Error(`Invalid report. Data is missing. data?.creationDate=${data?.creationDate}, data?.reporterUid=${data?.reporterUid}, data?.categoryId=${data?.categoryId}, data?.reason=${data?.reason},
     data?.allowContact=${data?.allowContact}, data?.type=${data?.type}`);

  const report: Report = {
    reporterUid: data.reporterUid,
    categoryId: data.categoryId,
    reason: data.reason,
    allowContact: data.allowContact,
    creationDate: data.creationDate,
    type: data.type,
    ...data,
  };
  return report;
}

export function convertToListing(data: DocumentData): Listing {
  const listing: Listing = {
    ...data,
  };
  if (data.name)
    listing.name = data.name;
  if (data.summary)
    listing.summary = data.summary;
  if (data.description)
    listing.description = data.description;
  if (data.categoryId)
    listing.categoryId = data.categoryId;
  if (data.imgUrls)
    listing.imgUrls = data.imgUrls;
  if (data.prices)
    listing.prices = data.prices;
  if (data.lowestPricePerDay)
    listing.lowestPricePerDay = data.lowestPricePerDay;
  if (data.highestPricePerDay)
    listing.highestPricePerDay = data.highestPricePerDay;
  if (data.currencyId)
    listing.currencyId = data.currencyId;
  if (data.availabilities)
    listing.availabilities = data.availabilities;
  if (data.address)
    listing.address = data.address;
  if (data.lenderUid)
    listing.lenderUid = data.lenderUid;
  if (data.creationDate)
    listing.creationDate = data.creationDate;
  if (data.lastEditDate)
    listing.lastEditDate = data.lastEditDate;
  if (data.draft)
    listing.draft = data.draft;
  if (data.cacheDate)
    listing.cacheDate = data.cacheDate;
  if (data.bookingPeriodMap)
    listing.bookingPeriodMap = data.bookingPeriodMap;
  if (data.disabled)
    listing.disabled = data.disabled;
  if (data.geohash)
    listing.geohash = data.geohash;
  if (data.geohashCoords)
    listing.geohashCoords = data.geohashCoords;
  return listing;
}

export function convertToListingPrivate(data: DocumentData): ListingPrivate {
  const listing: ListingPrivate = {
    ...data,
  };

  if (data.uid)
    listing.uid = data.uid;
  if (data.lenderUid)
    listing.lenderUid = data.lenderUid;
  if (data.address)
    listing.address = data.address;
  if (data.cacheDate)
    listing.cacheDate = data.cacheDate;
  return listing;
}

export function convertToRating(data: DocumentData): Rating {
  if (!data?.receiverUid || !data?.raterUid || !data?.rater || !data?.receiverRole || !data?.transactionUid || !data?.embeddedListing || !data?.overall
      || !data?.date)
    throw new Error(`Invalid rating. Data is missing: data?.receiverUid=${data?.receiverUid}, data?.raterUid=${data?.raterUid}, data?.rater=${data?.rater},
     data?.receiverRole=${data?.receiverRole}, data?.transactionUid=${data?.transactionUid}, data?.embeddedListing=${data?.embeddedListing}, data?.overall=${data?.overall}`);

  const rating: Rating = {
    receiverUid: data.receiverUid,
    receiverRole: data.receiverRole,
    raterUid: data.raterUid,
    rater: data.rater,
    transactionUid: data.transactionUid,
    embeddedListing: data.embeddedListing,
    overall: data.overall,
    date: data.date,
    ...data,
  };
  if (data.comment)
    rating.comment = data.comment;
  if (data.itemAsDescribed)
    rating.itemAsDescribed = data.itemAsDescribed;
  if (data.itemReturnCondition)
    rating.itemReturnCondition = data.itemReturnCondition;
  if (data.punctuality)
    rating.punctuality = data.punctuality;
  if (data.friendliness)
    rating.friendliness = data.friendliness;
  return rating;
}

export function convertToPayment(data: DocumentData, uid: string): Payment {
  if (!data?.creationDate || !data?.creditedUserId || !data?.creditedWalletId || !data?.currencyId || data?.declaredAmount === undefined || data?.escrowAmount === undefined || data?.refundedAmount === undefined ||
      !data?.escrowUserId || !data?.escrowWalletId || !data?.logs || data?.payInFeeNet === undefined || data?.payInFeeVat === undefined || data?.paidAmount === undefined || !data?.paymentMethod
      || data?.receiverAmount === undefined || !data?.receiverUid || !data?.senderUid || !data?.state || !data.senderUserId)
    throw new Error(`Invalid payment. Data is missing: data?.creationDate='${data?.creationDate}', data?.creditedUserId='${data?.creditedUserId}
    ', data?.creditedWalletId='${data?.creditedWalletId}', data?.currencyId='${data?.currencyId}', data?.declaredAmount='${data?.declaredAmount}
    ', data.escrowAmount='${data.escrowAmount}',  data.refundedAmount='${data.refundedAmount}', data.escrowUserId='${data.escrowUserId}
    ', data.escrowWalletId='${data.escrowWalletId}', data.logs='${data.logs}', data.payInFeeNet='${data.payInFeeNet}', data.payInFeeVat='${data.payInFeeVat}
    ', data.paidAmount='${data.paidAmount}',  data.paymentMethod='${data.paymentMethod}', data.receiverAmount='${data.receiverAmount}
    ', data.receiverUid='${data.receiverUid}',  data.senderUid='${data.senderUid}', data.state='${data.state}', data.senderUserId='${data.senderUserId}'.`);

  const payment: Payment = {
    uid,
    creationDate: data.creationDate,
    creditedUserId: data.creditedUserId,
    creditedWalletId: data.creditedWalletId,
    currencyId: data.currencyId,
    declaredAmount: data.declaredAmount,
    escrowAmount: data.escrowAmount,
    escrowUserId: data.escrowUserId,
    escrowWalletId: data.escrowWalletId,
    logs: data.logs,
    payInFeeNet: data.payInFeeNet,
    payInFeeVat: data.payInFeeVat,
    paidAmount: data.paidAmount,
    paymentMethod: data.paymentMethod,
    receiverAmount: data.receiverAmount,
    refundedAmount: data.refundedAmount,
    receiverUid: data.receiverUid,
    senderUid: data.senderUid,
    senderUserId: data.senderUserId,
    state: data.state,
    ...data,
  };
  if (data.transactionUid)
    payment.transactionUid = data.transactionUid;
  if (data.transferFeeNet)
    payment.transferFeeNet = data.transferFeeNet;
  if (data.transferFeeVat)
    payment.transferFeeVat = data.transferFeeVat;
  if (data.refundDate)
    payment.refundDate = data.refundDate;
  if (data.mangopayTransactionId)
    payment.mangopayTransactionId = data.mangopayTransactionId;
  if (data.senderWalletId)
    payment.senderWalletId = data.senderWalletId;
  if (data.refundTarget)
    payment.refundTarget = data.refundTarget;
  if (data.referrerAmount)
    payment.referrerAmount = data.referrerAmount;
  if (data.creditedUserReferralUid)
    payment.creditedUserReferralUid = data.creditedUserReferralUid;
  return payment;
}
