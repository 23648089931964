<div class="container bg-container px-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
      <li class="breadcrumb-item">
        <span i18n="messages headline">My messages</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="my messages headline">My messages</h1>
    <p class="jumbotron-lead" i18n="my messages lead text">Read and write messages.</p>
  </div>


  <!-- Show error, if no conversations could be loaded -->
  <div *ngIf="error" class="row">
    <div class="col">
      <div class="alert alert-danger" i18n="my conversations error message" role="alert">
        My conversations could not be loaded: {{error}}
      </div>
    </div>
  </div>

  <app-conversation-preview *ngFor="let conversation of conversations" [conversation]="conversation"></app-conversation-preview>
  <!--  <ul>-->
  <!--    <li *ngFor="let conversation of conversations">{{conversation.receiverUid}} - {{conversation.senderUid}} - {{conversation.listingUid}}-->
  <!--      - {{conversation.latestMessageDate.toDate()|moment:'LLLL'}}</li>-->
  <!--  </ul>-->

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true"></app-loading-spinner>
  <p *ngIf="conversations" class="mx-1" i18n="message shown conversations">Showing {{conversations.length}} conversations.</p>

  <div *ngIf="thereIsMore">
    <button (click)="onClickShowMore()" class="btn btn-primary" i18n="show more" type="button">Show more</button>
  </div>


</div>
