import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {Observable, Subject} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {takeUntil} from 'rxjs/operators';
import firebase from 'firebase/app';
import * as moment from 'moment';
import {SharedService} from '../../../services/shared.service';
import {environment} from '../../../../../environments/environment';
import Util from '../../../util';
import {ImgUrls} from '../../../models/imgUrls.interface';
import Locale from '../../../services/locale';
import UploadTaskSnapshot = firebase.storage.UploadTaskSnapshot;
import StringFormat = firebase.storage.StringFormat;

const TARGET_EXTENSION = '.webp';
const THUMBNAIL_WIDTH = environment.thumbnailSize;
const THUMBNAIL_SUFFIX = environment.thumbnailSuffix;

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.css'],
})
export class UploadTaskComponent implements OnInit, OnDestroy {
  destroy$: Subject<null> = new Subject();
  @Input() file: File | undefined;
  @Input() base64Img: string | undefined;
  @Input() uploadPath: string | undefined;

  /**
   * If required, you can specify a filename without extension here, e.g. 'electronics' for the electronics category. The thumbnail will get an appropriate suffix, e.g. '.thumbs'. In any case, an extension, e.g. '.webm' will be added.
   * If no filename is specified, the default filename consisting of the current date and time in the format 'YYYY-MM-DD_kk-mm-ss.sss' + the first 50 characters of the uploded file will be used.
   */
  @Input() filename?: string;
  @Output() onUploadFinished = new EventEmitter<{ file: File, downloadUrls: ImgUrls }>();

  task: AngularFireUploadTask | undefined;

  percentage: Observable<number | undefined> | undefined;
  snapshot: Observable<any> | undefined;
  downloadURL: string | undefined;


  numberFormatLocale = Locale.numberFormatLocale();

  constructor(private storage: AngularFireStorage,
              private db: AngularFirestore,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    this.sharedService.startImageUploadEmitter.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (state)
        this.startUpload();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  startUpload() {
    if (!this.base64Img)
      return;

    // The storage path
    const path = `${this.uploadPath}/${this.getFilename()}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = ref.putString(this.base64Img, StringFormat.DATA_URL);

    // Retrieve the downloadURL and emit it. Also call the onUploadCallback
    this.task.then(() => {
      ref.getDownloadURL().toPromise().then(downloadUrl => {
        this.downloadURL = downloadUrl;
        this.createAndUploadThumbnail(path);
      });
    });

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges();


  }

  isActive(snapshot: UploadTaskSnapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  private createAndUploadThumbnail(path: string) {
    if (!path || !this.base64Img)
      return;

    const extension = Util.getFileExtension(path);
    if (!extension)
      return;
    path = path?.replace(extension, `${THUMBNAIL_SUFFIX}.${extension}`);

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    this.resizeBase64Img(this.base64Img, THUMBNAIL_WIDTH, THUMBNAIL_WIDTH).then((resizedImage: any) => {
      const task = ref.putString(resizedImage, StringFormat.DATA_URL);

      // Retrieve the downloadURL and emit it. Also call the onUploadCallback
      task.then(() => {
        ref.getDownloadURL().toPromise().then(downloadUrlThumb => {
          if (this.downloadURL && this.file) {
            const downloadUrls: ImgUrls = {full: this.downloadURL, thumb: downloadUrlThumb};
            // Emit the file and url to the parent component (image uploader), so that the task can be removed from it
            this.onUploadFinished.emit({file: this.file, downloadUrls: downloadUrls});
          }
        });
      });
    });

  }

  /**
   * Delivers the filename for the upload.
   *
   * If specified in the filename input, that filename will be used, e.g. 'electronics' for the electronics category. The thumbnail will get an appropriate suffix, e.g. '.thumbs'. In any case, an extension, e.g. '.webm' will be added.
   * If no filename is specified, the default filename consisting of the current date and time will be used.
   *
   * If there is no filename input, a name is created from the current date and time in the format 'YYYY-MM-DD_kk-mm-ss.sss' + the first 50 characters of the uploded file..
   */
  private getFilename() {
    // If there is a filename input, use it
    if (this.filename)
      return this.filename + TARGET_EXTENSION;

    // There is no filename input. Construct the filename

    const formattedDate = moment(new Date()).format('YYYY-MM-DD_kk-mm-ss.sss');

    let filename = this.file?.name;
    if (!filename)
      return `${formattedDate}${TARGET_EXTENSION}`;
    const extension = Util.getFileExtension(filename);
    if (extension)
      filename = filename.replace(`.${extension}`, '');

    return `${formattedDate}_${filename?.substr(0, 50).replace(' ', '_')}${TARGET_EXTENSION}`;
  }

  /**
   * Resizes the given base64 image to the given wanted dimensions. Resizing is done in that way that the aspect ratio is preserved and the image gets as small
   * as possible, while both wanted dimensions are kept as a minimum. E.g. a 1200x1600 image with targetWidth: 300, targetHeight: 300 will be resized to 300x400
   * @param base64Image img as base 64 data URL
   * @param targetWidth target width
   * @param targetHeight target height
   * @return a promise with the resized image
   */
  private resizeBase64Img(base64Image: string, targetWidth: number, targetHeight: number) {
    return new Promise(async function (resolve, reject) {

      // Create an image to receive the Data URI
      const img = new Image;

      img.onload = function () {
        const sourceWidth = img.width;
        const sourceHeight = img.height;
        // Only resize, if the source image is bigger than the target size
        if (sourceWidth <= targetWidth || sourceHeight <= targetHeight)
          return resolve(base64Image);

        // Create a canvas and get its context.
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Determine, how to scale the image
        const sourceAspectRation = sourceWidth / sourceHeight;
        const targetAspectRatio = targetWidth / targetHeight;

        if ((sourceAspectRation >= 1 && targetAspectRatio >= 1) || sourceAspectRation < 1 && targetAspectRatio < 1) {
          targetWidth = targetHeight / sourceHeight * sourceWidth;
          // targetHeight stays what it is (function argument value)
        } else {
          // targetWidth stays what it is (function argument value)
          targetHeight = targetWidth / sourceWidth * sourceHeight;
        }

        // We set the dimensions at the wanted size.
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        // We resize the image with the canvas method drawImage();
        ctx?.drawImage(img, 0, 0, targetWidth, targetHeight);

        const dataURL = canvas.toDataURL('image/webp', 50);

        // This is the return of the Promise
        resolve(dataURL);
      };

      // Set the image as the img source
      img.src = base64Image;

    });
  }
}
