<a (click)="onNotificationClicked()" [routerLink]="notification?.actionUrl ? notification?.actionUrl : ''" class="no-underline">
  <div *ngIf="notification" [class]="notification.read ? 'read' : 'unread'">
    <div class="row m-0 p-1 notification-box">

      <div class="col mx-1 p-0">
        <p *ngIf="notification.embeddedListing" class="sender-name">{{notification.embeddedListing.name}}</p>

        <div *ngIf="notification.embeddedTransaction as transaction">
          <p class="message">
            <app-log-message *ngIf="user" [actingUserName]="getActingUserName(user)" [borrower]="getBorrower()"
                             [lender]="getLender()" [log]="createLog()" [transaction]="transaction"></app-log-message>
          </p>

          <div
                  *ngIf="transaction.periodSuggestionState === 'SUGGESTION_ACCEPTED'">
            <p *ngIf="transaction.targetPickupDate && transaction.targetPickupDate.toDate()" class="message">
              Pickup: {{transaction.targetPickupDate.toDate() | moment:'LLLL'}}</p>
            <p *ngIf="transaction.targetReturnDate && transaction.targetReturnDate.toDate()" class="message">
              Return: {{transaction.targetReturnDate.toDate() | moment:'LLLL'}}</p>
          </div>
        </div>
        <p [title]="notification.creationDate.toDate() | moment:'LLLL'"
           class="date">{{utilService.createFromNowString(notification.creationDate.toDate())}} </p>
      </div>
      <div *ngIf="notification.embeddedListing?.imgUrlThumb" align="right" class=" m-0 p-0">
        <img [src]="notification.embeddedListing?.imgUrlThumb" class="contained-60w-60h"
             default="./assets/images/default/default-listing-w300.webp">
      </div>
    </div>
  </div>
</a>
