<div *ngIf="userPublic">
  <span *ngIf="ratingSummary && ratingSummary.overallScore && ratingSummary.overallCount>0">
      <app-bs-star-rating [rating]="ratingSummary.overallScore" size="medium"></app-bs-star-rating><br>
      <small *ngIf="ratingSummary.overallCount" i18n="number of ratings">
        {ratingSummary.overallCount, plural, =0 {no ratings} =1 {1 rating} other {{{ratingSummary.overallCount}} ratings}}</small>
    </span>

  <span *ngIf="!ratingSummary || !ratingSummary.overallScore" i18n="No ratings yet">No ratings yet</span>
  <p class="mt-1" i18n="User card registration date text">
    Registered since {{ utilService.getDate(userPublic.creationDate) | moment:'LL'}}</p>
</div>
