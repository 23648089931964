import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {Announcement} from '../../../shared/models/announcement.interface';
import {UserService} from '../../../shared/services/user.service';
import {TitleService} from '../../../shared/services/title.service';
import {LayoutService} from '../../../layout/layout.service';
import firebase from 'firebase/app';
import {MILLIS_PER_DAY} from '../../../shared/constants/numbers';
import Util from '../../../shared/util';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-announcements-editor-form',
  templateUrl: './announcements-editor-form.component.html',
  styleUrls: ['./announcements-editor-form.component.scss'],
})
export class AnnouncementsEditorFormComponent extends BaseComponent implements OnInit {
  messageMaxLength = 1000;
  form: FormGroup = this.createForm();
  @Output() onSaved = new EventEmitter<Announcement>();

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private formBuilder: FormBuilder,
      private titleService: TitleService,
      private layoutService: LayoutService) {
    super(store);
  }

  _announcement?: Announcement;

  get announcement(): Announcement | undefined {
    return this._announcement;
  }

  @Input() set announcement(announcement: Announcement | undefined) {
    this._announcement = announcement;
    this.setFormValue(announcement);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  save(): void {

    const now = Timestamp.now();
    const announcement: Announcement = {
      message: this.form?.value.message,
      creationDate: this.announcement?.creationDate ? this.announcement.creationDate : now,
      environment: this.form?.value.environment,
      priority: this.form?.value.priority,
    };

    if (!announcement.environment) {
      this.addError($localize`Environment is missing.`);
      return;
    }
    if (!announcement.priority) {
      this.addError($localize`Priority is missing.`);
      return;
    }

    if (this.form?.value.validFrom)
      announcement.validFrom = this.form?.value.validFrom;
    if (this.form?.value.validUntil)
      announcement.validUntil = this.form?.value.validUntil;

    if (!this.announcement?.uid) {
      return this.insertAnnouncement(announcement);
    } else {
      announcement.uid = this.announcement.uid;
      announcement.lastEditDate = now;
      return this.updateAnnouncement(announcement);
    }
  }

  private createForm(): FormGroup {
    const now = Timestamp.now();
    const validFrom = now.toDate();
    const validUntil = Timestamp.fromMillis(Timestamp.now().toMillis() + 7 * MILLIS_PER_DAY).toDate();

    return this.formBuilder.group({
      message: ['', [Validators.required, Validators.maxLength(this.messageMaxLength)]],
      validFrom: [validFrom],
      validUntil: [validUntil],
      environment: ['all', [Validators.required]],
      priority: [1, [Validators.required]],
    });
  }

  private setFormValue(announcement?: Announcement) {
    if (!announcement) {
      this.form.reset();
      return;
    }
    this.form.patchValue({
      message: announcement.message,
      validFrom: Util.getDate(announcement.validFrom),
      validUntil: Util.getDate(announcement.validUntil),
      environment: announcement.environment,
      priority: announcement.priority,
    });

  }

  private insertAnnouncement(announcement: Announcement) {
    this.enableLoadingSpinner($localize`Inserting announcement...`);
    this.layoutService.insertAnnouncement(announcement, savedAnnouncement => {
          this.announcement = announcement;
          this.onSaved.emit(announcement);
          this.disableLoadingSpinner();
          this.form.reset();
        },
        error => {
          this.addError($localize`Error inserting the announcement\: ${error}`);
          this.disableLoadingSpinner();
        });
  }

  private updateAnnouncement(announcement: Announcement) {
    this.enableLoadingSpinner($localize`Updating announcement...`);
    this.layoutService.updateAnnouncement(announcement.uid!, announcement, announcement, false, () => {
          this.onSaved.emit(announcement);
          this.disableLoadingSpinner();
        },
        error => {
          this.addError($localize`Error updating the announcement\: ${error}`);
          this.disableLoadingSpinner();
        });
  }
}
