import {Component, ElementRef, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ConversationsDialogComponent, ConversationsDialogModel} from '../../../social/conversations-dialog/conversations-dialog.component';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import firebase from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthProvider} from 'ngx-auth-firebaseui';
import {AnalyticsEventName, AnalyticsService} from '../../../shared/services/analytics.service';
import {FacebookEventName, FacebookService} from '../../../shared/services/facebook.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {AuthService} from '../../auth.service';
import FirebaseAuthUser = firebase.User;


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent extends BaseComponent implements OnInit {
  closeEventEmitter?: EventEmitter<void> = new EventEmitter<void>();
  providers = AuthProvider;

  private readonly _matDialogRef: MatDialogRef<ConversationsDialogComponent>;
  private readonly target: ElementRef;


  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private authService: AuthService,
              public dialogRef: MatDialogRef<ConversationsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConversationsDialogModel,
              private router: Router,
              private analyticsService: AnalyticsService,
              private facebookService: FacebookService,
              private angularFireAuth: AngularFireAuth) {
    super(store);
    this._matDialogRef = dialogRef;
    this.target = data.target;
    this.closeEventEmitter = data.closeEventEmitter;
  }

  ngOnInit() {
    super.ngOnInit();
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.target.nativeElement.getBoundingClientRect();
    matDialogConfig.position = {left: `${rect.right >= 350 ? rect.right - 290 : rect.left}px`, top: `${rect.bottom}px`};
    this._matDialogRef.updatePosition(matDialogConfig.position);
  }

  closeDialog() {
    this.closeEventEmitter?.emit();
  }

  onSuccessfulLogin(user: FirebaseAuthUser): void {
    const accountCreation = user.metadata.creationTime === user.metadata.lastSignInTime;
    if (accountCreation) {

      this.onAccountCreation(user);
    }

    console.log(user);
    this.closeDialog();
  }

  handleError($event: any): void {
    console.log($event);
  }

  /**
   * Called on first successful login after account creation. This method is only called once per account.
   * @param user immutable user object. Don't change the user object in the firestore at this point. ngx-auth-ui does some manipulations of the user and might overwrite any changes, we do here.
   */
  private onAccountCreation(user: firebase.User) {
    // Don't change the user object in the firestore at this point. ngx-auth-ui does some manipulations of the user and might overwrite any changes, we do here.
    console.log('New account was created.');
    this.analyticsService.logEvent(AnalyticsEventName.ACCOUNT_CREATED);
    this.facebookService.logEvent(FacebookEventName.CompleteRegistration, {content_name: user.uid});

    setTimeout(args => {
      this.router.navigate(['/account/firstSteps']);
    }, 2500);

  }
}

/**
 * Class to represent login dialog model.
 */
export class LoginDialogModel {

  constructor(public target: ElementRef, public closeEventEmitter?: EventEmitter<void>) {
  }
}

