import {Component, OnDestroy, OnInit} from '@angular/core';
import {SharedService} from './shared/services/shared.service';
import {CurrencyService} from './shared/services/currency.service';
import {CategoryService} from './shared/services/category.service';
import {Subject} from 'rxjs';
import {MetadataService} from './shared/services/metadata.service';
import {UpdateService} from './update.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$: Subject<null> = new Subject();

  constructor(private sharedService: SharedService,
              public currencyService: CurrencyService,
              private metadataService: MetadataService,
              public categoryService: CategoryService,
              private updateService: UpdateService) {
    // check the service worker for updates
    this.updateService.checkForUpdates();
  }

  ngOnInit(): void {
    this.metadataService.setDefaultTags();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

}

