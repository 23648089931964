import {createAction, props} from '@ngrx/store';
import {SearchResult} from '../../shared/models/searchResult.interface';
import {Search} from '../../shared/models/search.interface';

export const resetSearch = createAction('[Browser] Resetting search results');
export const updateSearchTerm = createAction('[Browser] Updating search term', props<{ searchTerm?: string }>());
export const setSearchCategory = createAction('[Browser] Setting search category', props<{ categoryId: string | undefined }>());
export const setSearchSortIndex = createAction('[Browser] Setting search sort index name', props<{ sortIndex: string }>());
export const setDateAndTimePeriod = createAction('[Browser] Setting date and time period', props<{ dateFrom: Date, dateUntil: Date, timeFrom?: string, timeUntil?: string }>());

export const runCurrentSearch = createAction('[Browser] (Re)run the current search without changing any parameters)');
export const fetchSearchResultsFromAlgolia = createAction('[Browser] Fetching search results from Algolia', props<{ search: Search }>());
export const fetchMoreSearchResultsFromAlgolia = createAction('[Browser] Fetching more search from Algolia results and adding them to the result list');
export const setSearchResults = createAction('[Browser] Setting search results', props<{ searchResult: SearchResult }>());
export const setMoreSearchResults = createAction('[Browser] Setting more search results to be added to the result list',
  props<{ searchResult: SearchResult }>());
export const searchFailed = createAction('[Browser] Search failed', props<{ errorMessage: string, code?: number }>());

export const fetchLatestListingsFromAlgolia = createAction('[Browser] Fetching latest listing from Algolia', props<{ categoryId: string | undefined }>());
export const abort = createAction('[Browser] Aborting', props<{ reason: string }>());
export const setFetchingFalse = createAction('[Browser] Resetting the fetching flag(s) of this store to false');

