import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilService} from '../../shared/util.service';
import {TitleService} from '../../shared/services/title.service';
import {takeUntil} from 'rxjs/operators';
import {ReportType} from '../../shared/models/reportType.type';
import {ListingService} from '../../listing/listing.service';
import {Listing} from '../../shared/models/listing.interface';
import {BookService} from '../../book/book.service';
import {Rating} from '../../shared/models/rating.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Util from '../../shared/util';
import {GENERAL_REPORT_CATEGORIES, LISTING_REPORT_CATEGORIES, RATING_REPORT_CATEGORIES, ReportCategory} from '../../shared/models/reportCategory.interface';
import {SocialService} from '../social.service';
import {Report} from '../../shared/models/report.interface';
import {UserService} from '../../shared/services/user.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  type?: ReportType;
  uid?: string;
  listing?: Listing;
  rating?: Rating;

  reportForm!: FormGroup;

  reportCategories: ReportCategory[] = [];
  reasonMinLength = 10;
  reasonMaxLength = 1000;

  category?: ReportCategory;
  reason?: string;
  allowContact?: boolean;
  reportSent = false;

  private readonly spinnerKeySendingReport = 'sendingReport';
  private readonly spinnerKeyFetchingListing = 'fetchingListing';
  private readonly spinnerKeyFetchingRating = 'fetchingRating';

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private modalService: NgbModal,
      private formBuilder: FormBuilder,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      public utilService: UtilService,
      private listingService: ListingService,
      private socialService: SocialService,
      private bookService: BookService,
      private titleService: TitleService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Report`);
    this.reportForm = this.createReportForm();
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {

          // Reset everything
          this.category = undefined;
          this.reason = undefined;
          this.allowContact = false;
          this.reportSent = false;
          this.reportForm.reset();

          this.reportCategories = [];
          const type = params.get('type');
          if (!type)
            this.addError($localize`Invalid link. The type parameter is missing. A reporting link must contain '/listing/' or '/rating/'.`);
          else if (!this.isReportType(type))
            this.addError($localize`Invalid link. The type parameter is missing. A reporting link must contain '/listing/' or '/rating/'.`);
          else
            this.type = type as ReportType;

          const uid = params.get('uid');
          if (!uid)
            this.addError($localize`Invalid link. The ID parameter is missing. A reporting link must contain an alphanumeric ID value.`);
          else
            this.uid = uid;

          if (this.type === 'listing' && this.uid) {
            this.loadListing(this.uid);
            this.reportCategories = [...LISTING_REPORT_CATEGORIES, ...GENERAL_REPORT_CATEGORIES];
          }
          if (this.type === 'rating' && this.uid) {
            this.loadRating(this.uid);
            this.reportCategories = [...RATING_REPORT_CATEGORIES, ...GENERAL_REPORT_CATEGORIES];
          }
        },
    );
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  isReportType(input: string): input is ReportType {
    return ['listing', 'rating'].includes(input);
  }

  onSendReport(): void {
    if (!this.firebaseUser) {
      this.addError($localize`You need to login first.`);
      return;
    }
    this.reason = this.reportForm.value.reason;
    if (!this.reason) {
      this.addError($localize`Please enter an explanation.`);
      return;
    }
    if (!this.type) {
      this.addError($localize`Rating type is missing.`);
      return;
    }

    const categoryId = this.reportForm.value.categoryId;
    this.category = this.getCategoryById(categoryId);
    this.allowContact = this.reportForm.value.allowContact;

    this.addLoadingSpinnerMessage(this.spinnerKeySendingReport, $localize`Sending report...`);
    this.socialService.insertReport(this.firebaseUser.uid, categoryId, this.reason, !!this.allowContact, this.type, report => {
          this.removeLoadingSpinnerMessage(this.spinnerKeySendingReport);
          this.reportSent = true;
          Util.scrollToTop();
        },
        error => {
          this.addError($localize`Error submitting your report\: ${error}`);
          this.removeLoadingSpinnerMessage(this.spinnerKeySendingReport);
        }, this.listing?.uid, this.rating?.uid);
  }

  private loadListing(uid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyFetchingListing, $localize`Fetching listing...`);
    this.listingService.fetchListing(uid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyFetchingListing);
      if (wrapper.data)
        this.listing = wrapper.data;
      if (wrapper.errorMessage)
        this.addError($localize`Error loading listing\: ${wrapper.errorMessage}`);
    });
  }

  private loadRating(uid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyFetchingRating, $localize`Fetching rating...`);
    this.bookService.fetchRating(uid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyFetchingRating);
      if (wrapper.data)
        this.rating = wrapper.data;
      if (wrapper.errorMessage)
        this.addError($localize`Error loading rating\: ${wrapper.errorMessage}`);
    });
  }

  private createReportForm(): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
      categoryId: [null, Validators.required],
      reason: [null, [Validators.required, Validators.minLength(this.reasonMinLength), Validators.maxLength(this.reasonMaxLength)]],
      allowContact: [null],
    });

  }

  private getCategoryById(id: string) {
    return this.reportCategories.find(repCat => repCat.id === id);
  }

}
