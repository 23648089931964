import {AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbCarousel, NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {Listing} from '../../models/listing.interface';
import {ImgUrls} from '../../models/imgUrls.interface';
import {CANNOT_BE_UNDONE} from '../../constants/strings';
import {UtilService} from '../../util.service';
import Util from '../../util';

@Component({
  selector: 'app-image-carousel-modal-content',
  templateUrl: './image-carousel-modal-content.component.html',
  styleUrls: ['./image-carousel-modal-content.component.css'],
})
export class ImageCarouselModalContentComponent implements AfterViewInit, AfterViewChecked, AfterContentChecked {
  @Input() selectedId = 0;
  @Input() imgUrls: ImgUrls[] = [];
  /**
   * The name of the entity (listing, category, user), which this image belongs to
   */
  @Input() altName: Listing | undefined;
  @Input() deleteImageCallback: ((index: number) => void) | undefined;


  @ViewChild('imageCarousel') imageCarousel: NgbCarousel | undefined;

  constructor(public activeModal: NgbActiveModal,
              private carouselConfig: NgbCarouselConfig,
              public utilService: UtilService,
              private cdRef: ChangeDetectorRef) {
    // customize default values of carousels used by this component tree
    carouselConfig.interval = 0;
    carouselConfig.keyboard = true;
    carouselConfig.pauseOnHover = true;
  }

  ngAfterViewInit(): void {
    if (this.imageCarousel) {
      this.imageCarousel.focus();
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ESC') {
      this.activeModal.dismiss('ESC press');
    }
  }

  onDeleteImage(): any {
    this.activeModal.dismiss('ESC press');

    if (this.deleteImageCallback && this.selectedId !== undefined) {

      const title = $localize`Are you sure you want to delete this image?`;
      this.utilService.showConfirmDialog(title, CANNOT_BE_UNDONE, this.deleteImage.bind(this),
        undefined, undefined, undefined, undefined, 'no');
    }
  }

  deleteImage(): void {
    if (this.deleteImageCallback)
      this.deleteImageCallback(+this.selectedId);
  }

  isImageActive(i: number) {
    return Util.isSlideActive(i, this.selectedId, this.imgUrls.length);
  }


}

