import {Injectable} from '@angular/core';
import {Country} from '../models/country.interface';
import Locale from './locale';

@Injectable({
  providedIn: 'root',
})
export class CountryService {

  countries: Country[] = [];
  countryNames: string[] = [];
  /**
   * Map of country names by country code. Key = Code, Value = name
   */
  countryNamesByCode = new Map<string, string>();

  constructor() {

    const countries = require('i18n-iso-countries');
    countries.registerLocale(require('i18n-iso-countries/langs/' + Locale.countryLocale() + '.json'));

    let countryNames: any[] = countries.getNames(Locale.countryLocale(), {select: 'official'});

    countryNames = Object.values(countryNames).sort();
    countryNames.forEach(c => {
      const alpha2Code = countries.getAlpha2Code(c, Locale.countryLocale());
      const countryName = c as string;
      this.countries.push({name: countryName, code: alpha2Code});
      this.countryNames.push(countryName);
      this.countryNamesByCode.set(alpha2Code, countryName);
    });
  }

  /**
   * Delivers the country name for the given alpha 2 country code.
   * @param code alpha 2 country code, e.g. DE, US, CN...
   * @return full country name in the language set in environment.countryLocale
   */
  public getCountryNameByCode(code: string): string | undefined {
    return this.countryNamesByCode.get(code);
  }

}
