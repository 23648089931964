<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [message]="loadingSpinnerMessage"
                     [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

<div class="container bg-container px-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
      <li class="breadcrumb-item">
        <span i18n="referral program headline">Referral program</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="referral program headline">Referral program</h1>
    <p class="jumbotron-lead" i18n="referral program lead text">Recruit new users and earn money. Create and manage referral links and see, how they perform.</p>
  </div>

  <h1 class="paragraph-headline" i18n="referral how it works">How it works</h1>

  <ol>
    <li i18n="referral how it works step 1">Create a referral link.</li>
    <li i18n="referral how it works step 2">Share your referral link with friends, on social media, on your blog etc.</li>
    <li i18n="referral how it works step 3">New users create accounts using your referral link.</li>
    <li i18n="referral how it works step 4">Your recruited users generate revenue by renting things out.</li>
    <li i18n="referral how it works step 5">You receive a share of Blitzshare's commission directly into your <a [routerLink]="['..','payment-account']">wallet</a>.</li>
  </ol>

  <div class="mb-3">
    <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
  </div>

  <div *ngIf="referrals && referrals.length>0">
    <h1 class="paragraph-headline" i18n="my referral links" id="my-referral">My referral links</h1>
    <table class="table table-striped table-responsive">
      <thead>
      <th i18n>Link</th>
      <th i18n>Commission percentage</th>
      <th i18n>Earned commission</th>
      <th i18n>Transactions</th>
      <th i18n>Recruited users</th>
      <th i18n>Creation date</th>
      <th i18n>Edit</th>
      </thead>
      <tbody>
      <tr *ngFor="let referral of referrals">

        <td>
          <a [href]="getReferralLink(referral.name)">{{getReferralLink(referral.name)}}</a>
        </td>
        <td>
          {{referral.provisionPercentage * 100 | number:'1.0-1': numberFormatLocale }}%
        </td>
        <td>
          <app-commission-view [commission]="referral.commission"></app-commission-view>
        </td>
        <td>
          {{referral.transactionCount ? referral.transactionCount : 0}}
        </td>
        <td>
          <span *ngIf="referral.uid && referral.uid && referreesCountByReferralUid.get(referral.uid) !== undefined; else countLoading">
          {{referreesCountByReferralUid.get(referral.uid)}}
        </span>
          <ng-template #countLoading>
            <span i18n>Loading...</span>
          </ng-template>
        </td>
        <td>
        <span *ngIf="referral.creationDate">
        {{getDate(referral.creationDate) | moment:'LLL'}}
          </span>
          <small *ngIf="referral.lastEditDate"><br>
            <span i18n="last edit">Last edited: </span>
            {{getDate(referral.lastEditDate) | moment:'LLL'}}
          </small>
        </td>
        <td>
          <app-mat-icon-button [callback]="onEditReferralClick.bind(this)" [params]="[referral.uid]" cssClass="mr-2 mb-3" icon="edit"></app-mat-icon-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <mat-card *ngIf="editReferral" class="mb-3">
    <app-edit-referral (onCancel)="this.editReferral=undefined" (onDelete)="onDeleteReferral($event)" (onReferralEdited)="onEditReferral($event)" [referral]="editReferral"></app-edit-referral>
  </mat-card>

  <p *ngIf="!referrals || referrals.length == 0" i18n="you don't have any ref links">You have not yet created a referral link.</p>

  <form (ngSubmit)="onCreateReferralClick(referralLinkNameRef.value)" *ngIf="!referrals || referrals.length < maxReferralsCount" [formGroup]="referralForm" class="form-inline">
    <input #referralLinkNameRef [maxlength]="referralLinkNameMaxLength" aria-label="Referral name"
           class="form-control mr-2 mb-2 referral-name-input" formControlName="referralName" i18n-aria-label="Referral name"
           i18n-placeholder="referral name input field placeholder text|The placeholder text of the referral name input field" id="referralName"
           placeholder="Referral name" type="input">

    <app-mat-icon-button [disabled]="referralLinkNameRef.value.trim().length==0" color="primary"
                         cssClass="mr-2 mb-2" i18n-label="button label create referral" icon="add" label="Create referral link"
                         type="submit"></app-mat-icon-button>

    <p *ngIf="referralLinkNameRef.value.trim().length>0" class="align-self-end"><span i18n="your referral link">Your referral link:</span> {{getReferralLink(referralLinkNameRef.value)}}</p>
  </form>

  <h2 class="paragraph-headline-2 mt-3" i18n="referral further information headline">Further information</h2>
  <p i18n="referral further information paragraph 1">In order to get a commission, you need to have a <a [routerLink]="['..','payment-account']">wallet</a> for currency of the associated transaction. Example: If you recruit a user, who rents out things in USD, you need to have a USD wallet to get a commission. So it's best to create a wallet for each currency available.</p>

</div>
