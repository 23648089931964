<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<input #search (cancel)="filter(search.value)" (emptied)="filter(search.value)"
       (keyup)="filter(search.value)" (paste)="filter(search.value)"
       (reset)="filter(search.value)"
       (search)="filter(search.value)"
       aria-label="Search"
       class="form-control mb-3"
       i18n-placeholder="faq search input field placeholder text|The placeholder text of the search field in the FAQ component"
       id="searchTermFaq"
       placeholder="Search" type="search">

<mat-accordion #accordion="matAccordion" class="accordion-headers-align">

  <mat-expansion-panel (afterExpand)="setStep(i)" (closed)="closed()" *ngFor="let faq of faqs; index as i" [expanded]="step === i" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span [innerHTML]="layoutService.getFaqQuestion(faq) | highlight : filterTerm" class="thick"></span>
      </mat-panel-title>
      <mat-icon *ngIf="faq.matIcon">{{faq.matIcon}}</mat-icon>
    </mat-expansion-panel-header>

    <div [innerHTML]="layoutService.getFaqAnswer(faq) | highlight : filterTerm"></div>

    <mat-action-row>
      <button *ngIf="user?.admin && !editMode" [routerLink]="['/admin','faqs',faq.uid]" color="warn" i18n="Edit" mat-button>Edit</button>
      <button (click)="prevStep()" *ngIf="i>0" color="primary" i18n="Previous" mat-button>Previous</button>
      <button (click)="nextStep()" *ngIf="i< faqs.length" color="primary" i18n="Next" mat-button>Next</button>
    </mat-action-row>
  </mat-expansion-panel>


</mat-accordion>
