import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {ReportCategory} from '../../../shared/models/reportCategory.interface';
import {AppState} from '../../../store/app.reducer';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-report-sent',
  templateUrl: './report-sent.component.html',
  styleUrls: ['./report-sent.component.scss'],
})
export class ReportSentComponent extends BaseComponent implements OnInit {

  @Input() category?: ReportCategory;
  @Input() reason?: string;
  @Input() allowContact?: boolean;

  constructor(
      protected store: Store<AppState>,
      userService: UserService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}

