import {createAction, props} from '@ngrx/store';
import {Announcement} from '../../shared/models/announcement.interface';
import {Faq} from '../../shared/models/faq.interface';

export const clearAnnouncementsCache = createAction('[Layout] clearing announcement cache');
export const addAnnouncementToCache = createAction('[Layout] adding announcement to cache', props<{ announcement: Announcement }>());
export const addAnnouncementsToCache = createAction('[Layout] adding announcements to cache', props<{ announcements: Announcement[] }>());
export const setAnnouncementsLastFetch = createAction('[Layout] setting last announcements fetch date', props<{ announcementsLastFetch: Date }>());

export const clearFaqsCache = createAction('[Layout] clearing faq cache');
export const addFaqToCache = createAction('[Layout] adding faq to cache', props<{ faq: Faq }>());
export const addFaqsToCache = createAction('[Layout] adding faqs to cache', props<{ faqs: Faq[] }>());
export const setFaqsLastFetch = createAction('[Layout] setting last faqs fetch date', props<{ faqsLastFetch: Date }>());

export const setRefName = createAction('[Layout] setting ref name', props<{ refName: string }>());
