<div class="container-fluid bg-container p-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Admin" routerLink="..">Admin</a>
      <li class="breadcrumb-item">
        <span i18n="announcements headline">Announcements</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="announcements headline">Announcements</h1>
    <p class="jumbotron-lead" i18n="announcements lead text">View and manage announcements.</p>
  </div>

  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>


  <div class="row">
    <div class="col">
      <app-mat-icon-button [callback]="createNew.bind(this)" color="primary" cssClass="mb-3" i18n-label icon="campaign"
                           label="Create new announcement"></app-mat-icon-button>
      <app-announcements-editor-list (onDeleted)="onDeletion($event)" (onSelected)="onSelection($event)"
                                     [announcements]="announcements"></app-announcements-editor-list>
    </div>
    <div class="col">
      <app-announcements-editor-form (onSaved)="onSaved($event)" [announcement]="selectedAnnouncement"></app-announcements-editor-form>
    </div>
  </div>

</div>
