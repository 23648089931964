import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../shared/services/title.service';

@Component({
  selector: 'app-admin-overview',
  templateUrl: './admin-overview.component.html',
  styleUrls: ['./admin-overview.component.scss'],
})
export class AdminOverviewComponent implements OnInit {

  constructor(private titleService: TitleService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle($localize`Admin`);
  }
}
