<h1 class="paragraph-headline" i18n="name-email headline">My user name and email</h1>

<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<ngx-auth-firebaseui-user [canDeleteAccount]="false" [canLogout]="false" cancelButtonText="Cancel" editButtonText="Edit"
                          emailNotVerifiedText="Email not verified" emailText="Email"
                          emailVerifiedText="Email is verified" i18n-cancelButtonText="Cancel button text" i18n-editButtonText="Edit button text"
                          i18n-emailNotVerifiedText="email not verified" i18n-emailText="Email" i18n-emailVerifiedText="email is verified"
                          i18n-nameText="name" i18n-saveChangesButtonText="save button text" nameText="Name"
                          saveChangesButtonText="Save"></ngx-auth-firebaseui-user>
