import {Component, Input, OnInit} from '@angular/core';
import {TransactionState} from '../../enums/transactionState.enum';
import {TransactionPeriodSuggestionState} from '../../models/transactionPeriodSuggestionState.type';

@Component({
    selector: 'app-transaction-state',
    templateUrl: './transaction-state.component.html',
    styleUrls: ['./transaction-state.component.css'],
})
export class TransactionStateComponent implements OnInit {

    @Input() state ?: TransactionState;
    @Input() periodSuggestionState ?: TransactionPeriodSuggestionState;

    constructor() {
    }

    public get transactionState(): typeof TransactionState {
        return TransactionState;
    }

    ngOnInit(): void {
    }

}
