<div *ngIf="rating">
    <div *ngIf="showListing" class="row">
        <div class="col">
            <p class="mb-1" i18n>Rating for <a [routerLink]="['/browse','listing',rating.embeddedListing.uid]">{{rating.embeddedListing.name}}</a></p>
        </div>
    </div>
    <div class="row w-100">
        <div class="col-auto">

            <ng-container *ngIf="linkRating && rating?.uid">
                <a [routerLink]="['/social','rating',rating.uid]">
                    <app-rating-star-table [rating]="rating"></app-rating-star-table>
                </a>
            </ng-container>
            <ng-container *ngIf="!linkRating || !rating?.uid">
                <app-rating-star-table [rating]="rating"></app-rating-star-table>
            </ng-container>


        </div>
        <div *ngIf="showListing" class="col-auto img-col">
            <a [routerLink]="['/browse','listing',rating.embeddedListing.uid]"><img [src]="rating.embeddedListing?.imgUrlThumb" class="contained-150h-150w"
                                                                                    default="./assets/images/default/default-listing-w300.webp"></a>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-auto mr-0 pr-0">
            <app-user-thumb
                    [userPublic]="{imgUrlThumb: rating.rater.imgUrlThumb, uid: rating.raterUid, displayName: rating.rater.displayName ? rating.rater.displayName : ''}"></app-user-thumb>
        </div>
        <div *ngIf="rating.comment" class="col">{{rating.comment}}</div>
        <div *ngIf="!rating.comment" class="col"><i i18n>No comment.</i></div>
    </div>
</div>
