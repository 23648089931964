<div class="container bg-container p-1">

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="Imprint headline">Imprint</h1>
    <p class="jumbotron-lead" i18n="Imprint lead text">Find out, who is responsible for the Blitzshare platform.</p>
  </div>


  <h1 class="paragraph-headline" i18n>Business information</h1>
  <p i18n>
    Registration number: A21064834<br>
    Registered in the City of Munich.<br>
    Municipality code: 09162000
  </p>

  <h1 class="paragraph-headline" i18n>Site operator and person responsible</h1>
  <app-responsible-contact-details></app-responsible-contact-details>


</div>
