import {Injectable} from '@angular/core';
import {FunctionsService} from '../shared/services/functions.service';
import * as MangoPay from '../shared/models/mangopay/typings';
import {CurrencyISO} from '../shared/models/mangopay/typings/types';
import {PayInRequest} from '../shared/models/mangopay/payInRequest.interface';
import {PaymentMethod} from '../shared/models/mangopay/paymentMethod.type';
import {environment} from '../../environments/environment';
import {Payment} from '../shared/models/payment.interface';
import {ValidatePayInRequest} from '../shared/models/mangopay/validatePayInRequest.interface';
import {convertAmount} from './mangopayStatic';
import CardWebPayInData = MangoPay.payIn.CardWebPayInData;
import BankWireDirectPayInData = MangoPay.payIn.BankWireDirectPayInData;
import CardDirectPayInData = MangoPay.payIn.CardDirectPayInData;

@Injectable({
  providedIn: 'root',
})
export class MangopayService {

  constructor(
    private functionService: FunctionsService) {
  }

  convertAmount(amount: number, currency: CurrencyISO | string, mangopayToBlitzshare: boolean): number {
    return convertAmount(amount, currency, mangopayToBlitzshare);
  }

  /**
   * Creates a payIn on Mangopay using a cloud function. That function also creates a payment document in the firestore
   * @param senderUid Blitzshare user UID of the payment sender
   * @param senderUserId Mangopay user ID of the payment sender. Mangopay calls this AuthorId
   * @param senderWalletId Mangopay wallet ID of the payment sender.
   * @param amount
   * @param currencyIdentifier
   * @param receiverUid
   * @param returnUrl
   * @param paymentMethod
   * @param onSuccessCallback
   * @param onErrorCallback
   * @param transactionUid
   */
  createPayIn(senderUid: string, senderUserId: string, senderWalletId: string | undefined, amount: number, currencyIdentifier: string, receiverUid: string, returnUrl: string, paymentMethod: PaymentMethod,
              onSuccessCallback: (payInData: CardWebPayInData | CardDirectPayInData | BankWireDirectPayInData) => void, onErrorCallback: (errorMessage: string) => void, transactionUid?: string,
              languageCountryCode?: string): void {


    const request: PayInRequest = {
      senderUid, senderUserId, senderWalletId, receiverUid, returnUrl, currencyIdentifier, paymentMethod,
      amount: Math.round(convertAmount(amount, currencyIdentifier as CurrencyISO, false)),
      languageCountryCode: languageCountryCode ? languageCountryCode : 'en',
    };
    if (transactionUid)
      request.transactionUid = transactionUid;
    switch (paymentMethod) {
      case 'CB_VISA_MASTERCARD':
      case 'DINERS':
      case 'MAESTRO':
      case 'P24':
      case 'IDEAL':
      case 'BCMC':
      case 'PAYLIB':
      case 'GIROPAY':
      case 'SOFORT':
      case 'BANK_WIRE':
        break;
    }
    this.functionService.createMangopayPayIn(request, onSuccessCallback, onErrorCallback);
  }

  isCurrencyAvailable(paymentMethod: PaymentMethod, currencyId?: string) {
    if (!currencyId)
      return false;
    const currencies: string[] = environment.mangopayCurrenciesByPaymentMethod[paymentMethod];
    return currencies.indexOf('*') > -1 || currencies.indexOf(currencyId) > -1;
  }

  validatePayment(paymentUid: string, onSuccessCallback: (payment: Payment) => void, onErrorCallback: (errorMessage: string) => void) {
    const request: ValidatePayInRequest = {paymentUid};
    this.functionService.validateMangopayPayment(request, onSuccessCallback, onErrorCallback);

  }


  /**
   * Determines the best sender wallet for a pay in. This is the one with the given currency, if available. Otherwise undefined is returned.
   * @param currencyId ID of the currency, e.g. 'EUR'
   * @param wallets? the sender's wallets
   * @return ID of the escrow wallet for the given currency or undefined
   */
  determineSenderWallet(currencyId: string, wallets?: MangoPay.wallet.WalletData[]): string | undefined {
    if (!wallets)
      return undefined;
    for (let wallet of wallets) {
      if (wallet.Currency === currencyId)
        return wallet.Id;
    }
    return undefined;
  }

}
