import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../auth/auth.service';

@Component({
  selector: 'app-not-logged-in',
  templateUrl: './not-logged-in.component.html',
  styleUrls: ['./not-logged-in.component.css'],
})
export class NotLoggedInComponent implements OnInit {

  @Input() message: string | undefined;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  goToLogin(): void {
    this.authService.openLoginDropdown();
  }
}
