import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {TitleService} from '../../shared/services/title.service';
import {NgCacheRouteReuseService} from 'ng-cache-route-reuse';

@Component({
  selector: 'app-borrowing',
  templateUrl: './borrowing.component.html',
  styleUrls: ['./borrowing.component.css'],
})
export class BorrowingComponent extends BaseComponent implements OnInit {


  constructor(
      protected store: Store<AppState>,
      private cacheRouteReuseService: NgCacheRouteReuseService,
      private titleService: TitleService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setTitle();

    this.cacheRouteReuseService
        .onAttach(BorrowingComponent)
        .subscribe((component) => {
          this.setTitle();
        });
  }

  private setTitle() {
    this.titleService.setTitle($localize`Borrowing`);
  }
}
