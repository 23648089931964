<div [class]="level === 'danger'? 'alert alert-danger' : (level === 'warning' ? 'alert alert-warning' : (level === 'info' ? 'alert alert-info' : ''))">
  <table>
    <tr>
      <td class="info-cell">
        <mat-icon aria-hidden="false" aria-label="icon">{{icon}}</mat-icon>
      </td>
      <td>
        <ng-content></ng-content>
      </td>
    </tr>
  </table>
</div>
