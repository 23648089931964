import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {
  clearFirebaseUser,
  clearUser,
  fetchUser,
  fetchUserFailed,
  fetchUserSucceeded,
  setFirebaseUser,
  updateUser,
  updateUserFailed,
  updateUserFromFirebaseUserFailed,
  updateUserFromFirebaseUserSkipped,
  updateUserMerge,
  updateUserSucceeded,
} from './auth.actions';
import {Store} from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import {selectAuth, selectFirebaseUser} from './auth.selectors';
import {from, Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {firestore} from '../../app.module';
import {User} from '../../shared/models/user.interface';
import {userConverter} from '../../shared/services/user.service';
// @ts-ignore
import firebase, {DocumentSnapshot} from 'firebase';
import Util from '../../shared/util';
import {FirebaseUser} from '../../shared/models/firebaseUser.interface';

@Injectable()
export class AuthEffects {


  constructor(private actions: Actions,
              private httpClient: HttpClient,
              private store: Store<fromApp.AppState>) {
  }

  fetchUser = createEffect(() => this.actions.pipe(
      ofType(fetchUser, setFirebaseUser),
      withLatestFrom(this.store.select(selectFirebaseUser)),
      switchMap(action => this.handleFetchUser(action[1])),
  ));

  /**
   * Handles fetching a firestore user
   * @param authState current auth state
   * @return Observable of firestore get request
   */
  private handleFetchUser(firebaseUser?: FirebaseUser): Observable<any> {
    let uid = firebaseUser?.uid;
    if (!uid)
      return of(fetchUserFailed({fetchUserErrorMessage: 'You are not logged in.'}));
    try {
      return from(firestore.collection(environment.firestoreCollectionUsers).doc(uid).withConverter(userConverter).get()).pipe(
          map(userSnapshot => this.handleFetchUserResponse(userSnapshot, firebaseUser!)),
          catchError((error) => this.handleFetchUserError(error)),
      );
    } catch (error) {
      return this.handleFetchUserError(error);
    }
  }

  private handleFetchUserResponse = (userSnapshot: DocumentSnapshot<User>, firebaseUser: FirebaseUser) => {
    const user: User | undefined = userSnapshot.data();
    if (user) {
      this.updateUserFromFirebaseUser(user, firebaseUser);
      return fetchUserSucceeded({user});
    } else
      return fetchUserFailed({fetchUserErrorMessage: 'User not found'});
  };

  private handleFetchUserError(error: any): Observable<any> {
    return of(fetchUserFailed({fetchUserErrorMessage: Util.createErrorString(error, $localize`Fetching user account info failed.`)}));
  }

  fetchUserSucceeded = createEffect(() => this.actions.pipe(
      ofType(fetchUserSucceeded),
      withLatestFrom(this.store.select(selectFirebaseUser)),
      switchMap(action => this.updateUserFromFirebaseUser(action[0].user, action[1])),
  ));

  updateUserFromFirebaseUser(user: User, firebaseUser?: FirebaseUser): Observable<any> {
    if (!user?.uid)
      return of(updateUserFromFirebaseUserFailed({updateUserErrorMessage: 'User does not exist'}));

    let somethingWasChanged = false;
    let userUpdate: User = {uid: user.uid};

    if (firebaseUser?.email && user?.email !== firebaseUser?.email) {
      userUpdate = {...userUpdate, email: firebaseUser.email};
      somethingWasChanged = true;
      console.log(`Updating user's email: Previous value: '${user.email}'. New value: '${firebaseUser.email}'`);
    }
    if (firebaseUser?.emailVerified !== undefined && user?.emailVerified !== firebaseUser?.emailVerified) {
      userUpdate = {...userUpdate, emailVerified: firebaseUser.emailVerified};
      somethingWasChanged = true;
      console.log(`Updating user's emailVerified state: Previous value: '${user.emailVerified}'. New value: '${firebaseUser.emailVerified}'`);
    }
    if (firebaseUser?.displayName && user?.displayName !== firebaseUser?.displayName) {
      userUpdate = {...userUpdate, displayName: firebaseUser.displayName};
      somethingWasChanged = true;
      console.log(`Updating user's email: Previous value: '${user.displayName}'. New value: '${firebaseUser.displayName}'`);
    }
    if (firebaseUser?.telephoneNumber && user?.telephoneNumber === undefined) {
      userUpdate = {...userUpdate, telephoneNumber: firebaseUser.telephoneNumber};
      somethingWasChanged = true;
      console.log(`Updating user's telephoneNumber: Previous value: '${user.telephoneNumber}'. New value: '${firebaseUser.telephoneNumber}'`);
    }
    if (firebaseUser?.photoURL && user?.photoURL === undefined) {
      userUpdate = {...userUpdate, photoURL: firebaseUser.photoURL};
      somethingWasChanged = true;
      console.log(`Updating user's photoURL: Previous value: '${user.photoURL}'. New value: '${firebaseUser.photoURL}'`);
    }
    if (somethingWasChanged)
      return of(updateUserMerge({userUpdate}));

    return of(updateUserFromFirebaseUserSkipped())
  }


  updateUser = createEffect(() => this.actions.pipe(
      ofType(updateUser),
      switchMap(({user}) => this.handleUpdateUser(user)),
  ));

  /**
   * Handles updating a firestore user
   * @param userUpdate user to be written into the firestore
   * @return Observable of firestore get request
   */
  private handleUpdateUser(userUpdate: User): Observable<any> {
    try {
      return from(firestore.collection(environment.firestoreCollectionUsers).doc(userUpdate.uid).set(userUpdate)).pipe(
          map(() => updateUserSucceeded({user: userUpdate})),
          catchError((error) => this.handleUpdateUserFailed(error)),
      );
    } catch (error) {
      return this.handleUpdateUserFailed(error);
    }
  }

  private handleUpdateUserFailed(error: any): Observable<any> {
    return of(updateUserFailed({updateUserErrorMessage: Util.createErrorString(error, $localize`Fetching user account info failed.`)}));
  }


  updateUserMerge = createEffect(() => this.actions.pipe(
      ofType(updateUserMerge),
      withLatestFrom(this.store.select(selectAuth)),
      switchMap((action) => this.handleUpdateUserMerge(action[0].userUpdate, action[1].user),
      )));

  /**
   * Handles updating a firestore user
   * @param userUpdate user to be written into the firestore
   * @param oldUser user before the update
   * @return Observable of firestore get request
   */
  private handleUpdateUserMerge(userUpdate: User, oldUser?: User): Observable<any> {
    if (!oldUser)
      return of(updateUserFailed({updateUserErrorMessage: 'Old user does not exist. Merge update is not possible'}));
    const user = {...oldUser, ...userUpdate};
    return of(updateUser({user}));
  }

  clearFirebaseUser = createEffect(() => this.actions.pipe(
      ofType(clearFirebaseUser),
      switchMap(() => of(clearUser()))));

}




