import {Injectable} from '@angular/core';

declare var fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class FacebookService {

  constructor() {
  }

  public logEvent(eventName: FacebookEventName, properties?: FacebookEventProperties) {
    fbq('track', eventName, properties);
  }


}

/**
 * See https://developers.facebook.com/docs/facebook-pixel/reference/
 */
export enum FacebookEventName {
  AddPaymentInfo = 'AddPaymentInfo',
  AddToCart = 'AddToCart',
  AddToWishlist = 'AddToWishlist',
  CompleteRegistration = 'CompleteRegistration',
  Contact = 'Contact',
  CustomizeProduct = 'CustomizeProduct',
  Donate = 'Donate',
  FindLocation = 'FindLocation',
  InitiateCheckout = 'InitiateCheckout',
  Lead = 'Lead',
  PageView = 'PageView',
  Purchase = 'Purchase',
  Schedule = 'Schedule',
  Search = 'Search',
  StartTrial = 'StartTrial',
  SubmitApplication = 'SubmitApplication',
  Subscribe = 'Subscribe',
  ViewContent = 'ViewContent',
}

export interface FacebookEventProperties {
  content_category?: string;
  content_ids?: (string | number | undefined) [];
  content_name?: string;
  content_type?: 'product' | 'product_group';
  product_catalog_id?: number;
  contents?: FacebookEventContent[];
  currency?: string;
  num_items?: number;
  predicted_ltv?: number;
  search_string?: string;
  status?: boolean;
  value?: number;
}

export interface FacebookEventContent {
  id: string;
  quantity: number;
}
