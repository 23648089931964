<div class="container bg-container p-1">

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="Imprint headline">Privacy policy</h1>
    <p class="jumbotron-lead" i18n="Imprint lead text">Read all about our privacy policy.</p>
  </div>

  <h1 class="paragraph-headline">Ziel dieser Datenschutzerklärung</h1>


  <p>Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) im Rahmen
    der Erbringung unserer Leistungen sowie innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen
    Onlinepräsenzen, wie z.B. unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten
    Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).</p>


  <h2 class="paragraph-headline-2" id="dsg-general-controller">Verantwortlicher</h2>

  <app-responsible-contact-details></app-responsible-contact-details>

  <h2 class="paragraph-headline-2" id="dsg-general-datatype">Arten der verarbeiteten Daten</h2>


  <p>&#8211; Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).<br>&#8211; Kontaktdaten (z.B., E-Mail, Telefonnummern).<br>&#8211; Inhaltsdaten
    (z.B., Texteingaben, Fotografien, Videos).<br>&#8211; Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).<br>&#8211;
    Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).</p>


  <h2 class="paragraph-headline-2" id="dsg-general-datasubjects">Kategorien betroffener Personen</h2>


  <p>Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“).</p>


  <h2 class="paragraph-headline-2" id="dsg-general-purpose">Zweck der Verarbeitung</h2>


  <p>&#8211; Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.<br>&#8211; Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.<br>&#8211;
    Sicherheitsmaßnahmen.<br>&#8211; Reichweitenmessung/Marketing.</p>


  <h2 class="paragraph-headline-2" id="dsg-general-terms">Verwendete Begrifflichkeiten</h2>


  <p>„Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene
    Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie
    einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert
    werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
    natürlichen Person sind.</p>


  <p>„Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
    personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.</p>


  <p>„Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen
    nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und
    technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder
    identifizierbaren natürlichen Person zugewiesen werden.</p>


  <p>„Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um
    bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche
    Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren
    oder vorherzusagen.</p>


  <p>Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die
    Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.</p>


  <p>„Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des
    Verantwortlichen verarbeitet.</p>


  <h2 class="paragraph-headline-2" id="dsg-general-legalbasis">Maßgebliche Rechtsgrundlagen</h2>


  <p>Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich der
    Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, Folgendes:<br>Die
    Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO;<br>Die Rechtsgrundlage für die Verarbeitung zur Erfüllung
    unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;<br>Die Rechtsgrundlage für die
    Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;<br>Für den Fall, dass lebenswichtige Interessen der
    betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
    Rechtsgrundlage.<br>Die Rechtsgrundlage für die erforderliche Verarbeitung zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in
    Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde ist Art. 6 Abs. 1 lit. e DSGVO.<br>Die Rechtsgrundlage für die Verarbeitung
    zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO.<br>Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie ehoben
    wurden, bestimmt sich nach den Vorgaben des Art 6 Abs. 4 DSGVO.<br>Die Verarbeitung von besonderen Kategorien von Daten (entsprechend Art. 9 Abs. 1 DSGVO)
    bestimmt sich nach den Vorgaben des Art. 9 Abs. 2 DSGVO.</p>


  <h2 class="paragraph-headline-2" id="dsg-general-securitymeasures">Sicherheitsmaßnahmen</h2>


  <p>Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der
    Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten
    natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.</p>


  <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu
    den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
    Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der Daten gewährleisten. Ferner
    berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem
    Prinzip des Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>


  <h2 class="paragraph-headline-2" id="dsg-general-coprocessing">Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und Dritten</h2>


  <p>Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern, gemeinsam Verantwortlichen oder Dritten)
    offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn
    eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserfüllung erforderlich ist), Nutzer eingewilligt haben, eine rechtliche
    Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).</p>


  <p>Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe offenbaren, übermitteln oder ihnen sonst den Zugriff gewähren, erfolgt dies insbesondere zu
    administrativen Zwecken als berechtigtes Interesse und darüberhinausgehend auf einer den gesetzlichen Vorgaben entsprechenden Grundlage.</p>


  <h2 class="paragraph-headline-2" id="dsg-general-thirdparty">Übermittlungen in Drittländer</h2>


  <p>Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR) oder der Schweizer
    Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an andere Personen
    oder Unternehmen geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer
    rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse,
    verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der gesetzlichen Voraussetzungen. D.h. die Verarbeitung erfolgt z.B. auf
    Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das
    „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen.</p>


  <h2 class="paragraph-headline-2" id="dsg-general-rightssubject">Rechte der betroffenen Personen</h2>


  <p>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere
    Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.</p>


  <p>Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden
    unrichtigen Daten zu verlangen.</p>


  <p>Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe
    der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.</p>


  <p>Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe der gesetzlichen Vorgaben zu erhalten und
    deren Übermittlung an andere Verantwortliche zu fordern.</p>


  <p>Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.</p>


  <h2 class="paragraph-headline-2" id="dsg-general-revokeconsent">Widerrufsrecht</h2>


  <p>Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die Zukunft zu widerrufen.</p>


  <h2 class="paragraph-headline-2" id="dsg-general-object">Widerspruchsrecht</h2>


  <p><strong>Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe der gesetzlichen Vorgaben jederzeit widersprechen. Der Widerspruch
    kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.</strong></p>


  <h2 class="paragraph-headline-2" id="dsg-general-cookies">Cookies und Widerspruchsrecht bei Direktwerbung</h2>


  <p>Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der Cookies können unterschiedliche Angaben
    gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach
    seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, werden Cookies
    bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser schließt. In einem solchen Cookie kann z.B. der Inhalt
    eines Warenkorbs in einem Onlineshop oder ein Login-Status gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach
    dem Schließen des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen.
    Ebenso können in einem solchen Cookie die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als
    „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt, angeboten werden
    (andernfalls, wenn es nur dessen Cookies sind spricht man von „First-Party Cookies“).</p>


  <p>Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer Datenschutzerklärung auf.</p>


  <p>Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in den Systemeinstellungen
    ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
    Funktionseinschränkungen dieses Onlineangebotes führen.</p>


  <p>Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall
    des Trackings, über die US-amerikanische Seite <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> oder die EU-Seite <a
      href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a> erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren
    Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes
    genutzt werden können.</p>


  <h2 class="paragraph-headline-2" id="dsg-general-erasure">Löschung von Daten</h2>


  <p>Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im Rahmen
    dieser Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr
    erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>


  <p>Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h.
    die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt
    werden müssen.</p>


  <h2 class="paragraph-headline-2" id="dsg-general-changes">Änderungen und Aktualisierungen der Datenschutzerklärung</h2>


  <p>Wir bitten Sie sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen
    der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
    Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>


  <h2 class="paragraph-headline-2" id="dsg-commercialpurpose">Geschäftsbezogene Verarbeitung</h2>


  <p>Zusätzlich verarbeiten wir<br>&#8211; Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie).<br>&#8211; Zahlungsdaten (z.B., Bankverbindung,
    Zahlungshistorie)<br>von unseren Kunden, Interessenten und Geschäftspartner zwecks Erbringung vertraglicher Leistungen, Service und Kundenpflege, Marketing,
    Werbung und Marktforschung.</p>


  <h2 class="paragraph-headline-2" id="dsg-affiliate-general">Teilnahme an Affiliate-Partnerprogrammen</h2>


  <p>Innerhalb unseres Onlineangebotes setzen wir auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem
    Betrieb unseres Onlineangebotes) gem. Art. 6 Abs. 1 lit. f DSGVO branchenübliche Trackingmaßnahmen ein, soweit diese für den Betrieb des Affiliatesystems
    erforderlich sind. Nachfolgend klären wir die Nutzer über die technischen Hintergründe auf.</p>


  <p>Die von unseren Vertragspartnern angebotene Leistungen können auch auf anderen Webseiten beworben und verlinkt werden (sog. Affiliate-Links oder
    After-Buy-Systeme, wenn z.B. Links oder Leistungen Dritter nach einem Vertragsschluss angeboten werden). Die Betreiber der jeweiligen Webseiten erhalten
    eine Provision, wenn Nutzer den Affiliate-Links folgen und anschließend die Angebote wahrnehmen.</p>


  <p>Zusammenfassend, ist es für unser Onlineangebot erforderlich, dass wir nachverfolgen können, ob Nutzer, die sich für Affiliate-Links und/oder die bei uns
    verfügbaren Angebote interessieren, die Angebote anschließend auf die Veranlassung der Affiliate-Links oder unserer Onlineplattform, wahrnehmen. Hierzu
    werden die Affiliate-Links und unsere Angebote um bestimmte Werte ergänzt, die ein Bestandteil des Links oder anderweitig, z.B. in einem Cookie, gesetzt
    werden können. Zu den Werten gehören insbesondere die Ausgangswebseite (Referrer), Zeitpunkt, eine Online-Kennung der Betreiber der Webseite, auf der sich
    der Affiliate-Link befand, eine Online-Kennung des jeweiligen Angebotes, eine Online-Kennung des Nutzers, als auch Tracking-spezifische Werte wie z.B.
    Werbemittel-ID, Partner-ID und Kategorisierungen.</p>


  <p>Bei der von uns verwendeten Online-Kennungen der Nutzer, handelt es sich um pseudonyme Werte. D.h. die Online-Kennungen enthalten selbst keine
    personenbezogenen Daten wie Namen oder E-Mailadressen. Sie helfen uns nur zu bestimmen ob derselbe Nutzer, der auf einen Affiliate-Link geklickt oder sich
    über unser Onlineangebot für ein Angebot interessiert hat, das Angebot wahrgenommen, d.h. z.B. einen Vertrag mit dem Anbieter abgeschlossen hat. Die
    Online-Kennung ist jedoch insoweit personenbezogen, als dem Partnerunternehmen und auch uns, die Online-Kennung zusammen mit anderen Nutzerdaten vorliegen.
    Nur so kann das Partnerunternehmen uns mitteilen, ob derjenige Nutzer das Angebot wahrgenommen hat und wir z.B. den Bonus auszahlen können.</p>


  <h2 class="paragraph-headline-2" id="dsg-affiliate-amazon">Amazon-Partnerprogramm</h2>


  <p>Wir sind auf Grundlage unserer berechtigten Interessen (d.h. Interesse am wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
    f. DSGVO) Teilnehmer des Partnerprogramms von Amazon EU, das zur Bereitstellung eines Mediums für Websites konzipiert wurde, mittels dessen durch die
    Platzierung von Werbeanzeigen und Links zu Amazon.de Werbekostenerstattung verdient werden kann (sog. Affiliate-System). D.h. als Amazon-Partner verdienen
    wir an qualifizierten Käufen.</p>


  <p>Amazon setzt Cookies ein, um die Herkunft der Bestellungen nachvollziehen zu können. Unter anderem kann Amazon erkennen, dass Sie den Partnerlink auf
    dieser Website geklickt und anschließend ein Produkt bei Amazon erworben haben.</p>


  <p>Weitere Informationen zur Datennutzung durch Amazon und Widerspruchsmöglichkeiten erhalten Sie in der Datenschutzerklärung des Unternehmens: <a
    href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010" rel="noreferrer noopener" target="_blank">https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010</a>.
  </p>


  <p>Hinweis: Amazon und das Amazon-Logo sind Warenzeichen von Amazon.com, Inc. oder eines seiner verbundenen Unternehmen.</p>


  <h2 class="paragraph-headline-2" id="dsg-affiliate-bookingcom">Booking.com-Partnerprogramm</h2>


  <p>Wir sind auf Grundlage unserer berechtigten Interessen (d.h. Interesse am wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
    f. DSGVO) Teilnehmer des Partnerprogramms von Booking.com, das zur Bereitstellung eines Mediums für Websites konzipiert wurde, mittels dessen durch die
    Platzierung von Werbeanzeigen und Links zu Booking.com Werbekostenerstattung verdient werden kann (sog. Affiliate-System). Booking.com setzt Cookies ein, um
    die Herkunft der Buchungen nachvollziehen zu können. Unter anderem kann Booking.com erkennen, dass Sie den Partnerlink auf dieser Website geklickt und
    anschließend eine Buchung bei Booking.com getätigt haben.</p>


  <p>Weitere Informationen zur Datennutzung durch Booking.com und Widerspruchsmöglichkeiten erhalten Sie in der Datenschutzerklärung des Unternehmens: <a
    href="https://www.booking.com/content/privacy.de.html" rel="noreferrer noopener" target="_blank">https://www.booking.com/content/privacy.de.html</a>.</p>


  <h2 class="paragraph-headline-2" id="dsg-affiliate-agoda">Agoda.com-Partnerprogramm</h2>


  <p>Wir sind auf Grundlage unserer berechtigten Interessen (d.h. Interesse am wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
    f. DSGVO) Teilnehmer des Partnerprogramms von Agoda.com, das zur Bereitstellung eines Mediums für Websites konzipiert wurde, mittels dessen durch die
    Platzierung von Werbeanzeigen und Links zu Agoda.com Werbekostenerstattung verdient werden kann (sog. Affiliate-System). Agoda.com setzt Cookies ein, um die
    Herkunft der Buchungen nachvollziehen zu können. Unter anderem kann Agoda.com erkennen, dass Sie den Partnerlink auf dieser Website geklickt und
    anschließend einen Vertragsschlusses auf oder über Agoda.com getätigt haben.</p>


  <p>Weitere Informationen zur Datennutzung durch Agoda.com und Widerspruchsmöglichkeiten erhalten Sie in der Datenschutzerklärung des Unternehmens: <a
    href="https://www.agoda.com/de-de/info/agoda-privacy.html" rel="noreferrer noopener" target="_blank">https://www.agoda.com/de-de/info/agoda-privacy.html</a>.
  </p>


  <h2 class="paragraph-headline-2" id="dsg-affiliate-awin">AWIN-Partnerprogramm</h2>


  <p>Wir sind auf Grundlage unserer berechtigten Interessen (d.h. Interesse am wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
    f. DSGVO) Teilnehmer des Partnerprogramms von AWIN AG, Eichhornstraße 3, 10785 Berlin, Deutschland, das zur Bereitstellung eines Mediums für Websites
    konzipiert wurde, mittels dessen durch die Platzierung von Werbeanzeigen und Links zu AWIN Werbekostenerstattung verdient werden kann (sog.
    Affiliate-System). AWIN setzt Cookies ein, um die Herkunft des Vertragsschlusses nachvollziehen zu können. Unter anderem kann AWIN erkennen, dass Sie den
    Partnerlink auf dieser Website geklickt und anschließend einen Vertragsschluss bei oder über AWIN getätigt haben.</p>


  <p>Weitere Informationen zur Datennutzung durch Awin und Widerspruchsmöglichkeiten erhalten Sie in der Datenschutzerklärung des Unternehmens: <a
    href="https://www.awin.com/de/rechtliches" rel="noreferrer noopener" target="_blank">https://www.awin.com/de/rechtliches</a>.</p>


  <h2 class="paragraph-headline-2" id="dsg-affiliate-digistore24">Digistore24 -Partnerprogramm</h2>


  <p>Wir sind auf Grundlage unserer berechtigten Interessen (d.h. Interesse am wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
    f. DSGVO) Teilnehmer des Partnerprogramms von Digistore24 GmbH, St.-Godehard-Straße 32, 31139 Hildesheim, Deutschland, das zur Bereitstellung eines Mediums
    für Websites konzipiert wurde, mittels dessen durch die Platzierung von Werbeanzeigen und Links zu Digistore24 Werbekostenerstattung verdient werden kann
    (sog. Affiliate-System). Digistore24 setzt Cookies ein, um die Herkunft des Vertragsschlusses nachvollziehen zu können. Unter anderem kann Digistore24
    erkennen, dass Sie den Partnerlink auf dieser Website geklickt und anschließend einen Vertragsschluss bei oder über Digistore24 getätigt haben.</p>


  <p>Weitere Informationen zur Datennutzung durch Digistore24 und Widerspruchsmöglichkeiten erhalten Sie in der Datenschutzerklärung des Unternehmens: <a
    href="https://www.digistore24.com/page/privacy" rel="noreferrer noopener" target="_blank">https://www.digistore24.com/page/privacyl</a>.</p>


  <h2 class="paragraph-headline-2" id="dsg-affiliate-affilinet">Affilinet-Partnerprogramm</h2>


  <p>Wir sind auf Grundlage unserer berechtigten Interessen (d.h. Interesse am wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
    f. DSGVO) Teilnehmer des Partnerprogramms von affilinet GmbH, Sapporobogen 6-8, 80637 München, Deutschland, das zur Bereitstellung eines Mediums für
    Websites konzipiert wurde, mittels dessen durch die Platzierung von Werbeanzeigen und Links zu Affilinet Werbekostenerstattung verdient werden kann (sog.
    Affiliate-System). Affilinet setzt Cookies ein, um die Herkunft des Vertragsschlusses nachvollziehen zu können. Unter anderem kann Affilinet erkennen, dass
    Sie den Partnerlink auf dieser Website geklickt und anschließend einen Vertragsschluss bei oder über Affilinet getätigt haben.</p>


  <p>Weitere Informationen zur Datennutzung durch Affilinet und Widerspruchsmöglichkeiten erhalten Sie in der Datenschutzerklärung des Unternehmens: <a
    href="https://www.affili.net/de/footeritem/datenschutz" rel="noreferrer noopener" target="_blank">https://www.affili.net/de/footeritem/datenschutz</a>.</p>


  <h2 class="paragraph-headline-2" id="dsg-comments">Kommentare und Beiträge</h2>


  <p>Wenn Nutzer Kommentare oder sonstige Beiträge hinterlassen, können ihre IP-Adressen auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs.
    1 lit. f. DSGVO für 7 Tage gespeichert werden. Das erfolgt zu unserer Sicherheit, falls jemand in Kommentaren und Beiträgen widerrechtliche Inhalte
    hinterlässt (Beleidigungen, verbotene politische Propaganda, etc.). In diesem Fall können wir selbst für den Kommentar oder Beitrag belangt werden und sind
    daher an der Identität des Verfassers interessiert.</p>


  <p>Des Weiteren behalten wir uns vor, auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO, die Angaben der Nutzer zwecks
    Spamerkennung zu verarbeiten.</p>


  <p>Auf derselben Rechtsgrundlage behalten wir uns vor, im Fall von Umfragen die IP-Adressen der Nutzer für deren Dauer zu speichern und Cookies zu verwenden,
    um Mehrfachabstimmungen zu vermeiden.</p>


  <p>Die im Rahmen der Kommentare und Beiträge mitgeteilte Informationen zur Person, etwaige Kontakt- sowie Websiteinformationen als auch die inhaltlichen
    Angaben, werden von uns bis zum Widerspruch der Nutzer dauerhaft gespeichert.</p>


  <h2 class="paragraph-headline-2" id="dsg-subscribetocomments">Kommentarabonnements</h2>


  <p>Die Nachfolgekommentare können durch Nutzer mit deren Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO abonniert werden. Die Nutzer erhalten eine
    Bestätigungsemail, um zu überprüfen, ob sie der Inhaber der eingegebenen Emailadresse sind. Nutzer können laufende Kommentarabonnements jederzeit
    abbestellen. Die Bestätigungsemail wird Hinweise zu den Widerrufsmöglichkeiten enthalten. Für die Zwecke des Nachweises der Einwilligung der Nutzer,
    speichern wir den Anmeldezeitpunkt nebst der IP-Adresse der Nutzer und löschen diese Informationen, wenn Nutzer sich von dem Abonnement abmelden.</p>


  <p>Sie können den Empfang unseres Abonnements jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen. Wir können die ausgetragenen E-Mailadressen bis zu drei
    Jahren auf Grundlage unserer berechtigten Interessen speichern bevor wir sie löschen, um eine ehemals gegebene Einwilligung nachweisen zu können. Die
    Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich,
    sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird.</p>


  <h2 class="paragraph-headline-2" id="dsg-akismet">Akismet Anti-Spam-Prüfung</h2>


  <p>Unser Onlineangebot nutzt den Dienst „Akismet“, der von der Automattic Inc., 60 29th Street #343, San Francisco, CA 94110, USA, angeboten wird. Die Nutzung
    erfolgt auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f) DSGVO. Mit Hilfe dieses Dienstes werden Kommentare echter Menschen
    von Spam-Kommentaren unterschieden. Dazu werden alle Kommentarangaben an einen Server in den USA verschickt, wo sie analysiert und für Vergleichszwecke vier
    Tage lang gespeichert werden. Ist ein Kommentar als Spam eingestuft worden, werden die Daten über diese Zeit hinaus gespeichert. Zu diesen Angaben gehören
    der eingegebene Name, die Emailadresse, die IP-Adresse, der Kommentarinhalt, der Referrer, Angaben zum verwendeten Browser sowie dem Computersystem und die
    Zeit des Eintrags.</p>


  <p>Nähere Informationen zur Erhebung und Nutzung der Daten durch Akismet finden sich in den Datenschutzhinweisen von Automattic: <a
    href="https://automattic.com/privacy/" rel="noreferrer noopener" target="_blank">https://automattic.com/privacy/</a>.</p>


  <p>Nutzer können gerne Pseudonyme nutzen, oder auf die Eingabe des Namens oder der Emailadresse verzichten. Sie können die Übertragung der Daten komplett
    verhindern, indem Sie unser Kommentarsystem nicht nutzen. Das wäre schade, aber leider sehen wir sonst keine Alternativen, die ebenso effektiv arbeiten.</p>


  <h2 class="paragraph-headline-2" id="dsg-wordpress-emojis">Abruf von Emojis und Smilies</h2>


  <p>Innerhalb unseres WordPress-Blogs werden grafische Emojis (bzw. Smilies), d.h. kleine grafische Dateien, die Gefühle ausdrücken, eingesetzt, die von
    externen Servern bezogen werden. Hierbei erheben die Anbieter der Server, die IP-Adressen der Nutzer. Dies ist notwendig, damit die Emojie-Dateien an die
    Browser der Nutzer übermittelt werden können. Der Emojie-Service wird von der Automattic Inc., 60 29th Street #343, San Francisco, CA 94110, USA, angeboten.
    Datenschutzhinweise von Automattic: <a href="https://automattic.com/privacy/" rel="noreferrer noopener" target="_blank">https://automattic.com/privacy/</a>.
    Die verwendeten Server-Domains sind s.w.org und twemoji.maxcdn.com, wobei es sich unseres Wissens nach um sog. Content-Delivery-Networks handelt, also
    Server, die lediglich einer schnellen und sicheren Übermittlung der Dateien dienen und die personenbezogenen Daten der Nutzer nach der Übermittlung gelöscht
    werden.</p>


  <p>Die Nutzung der Emojis erfolgt auf Grundlage unserer berechtigten Interessen, d.h. Interesse an einer attraktiven Gestaltung unseres Onlineangebotes gem.
    Art. 6 Abs. 1 lit. f. DSGVO.</p>


  <h2 class="paragraph-headline-2" id="dsg-contact">Kontaktaufnahme</h2>


  <p>Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der
    Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b. (im Rahmen vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1 lit. f. (andere
    Anfragen) DSGVO verarbeitet.. Die Angaben der Nutzer können in einem Customer-Relationship-Management System (&#8222;CRM System&#8220;) oder vergleichbarer
    Anfragenorganisation gespeichert werden.</p>


  <p>Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen
    Archivierungspflichten.</p>


  <h2 class="paragraph-headline-2" id="dsg-logfiles">Erhebung von Zugriffsdaten und Logfiles</h2>


  <p>Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf
    den Server, auf dem sich dieser Dienst befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum und
    Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die
    zuvor besuchte Seite), IP-Adresse und der anfragende Provider.</p>


  <p>Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7 Tagen
    gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
    Vorfalls von der Löschung ausgenommen.</p>


  <h2 class="paragraph-headline-2" id="dsg-jetpackstats">Jetpack (WordPress Stats)</h2>


  <p>Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes
    im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) das Plugin Jetpack (hier die Unterfunktion „Wordpress Stats“), welches ein Tool zur statistischen Auswertung der
    Besucherzugriffe einbindet und von Automattic Inc., 60 29th Street #343, San Francisco, CA 94110, USA. Jetpack verwendet sog. „Cookies“, Textdateien, die
    auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen.</p>


  <p>Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieses Onlineangebotes werden auf einem Server in den USA gespeichert. Dabei können aus
    den verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden, wobei diese nur zu Analyse- und nicht zu Werbezwecken eingesetzt werden. Weitere
    Informationen erhalten Sie in den Datenschutzerklärungen von Automattic: <a href="https://automattic.com/privacy/" rel="noreferrer noopener"
                                                                                target="_blank">https://automattic.com/privacy/</a> und Hinweisen zu
    Jetpack-Cookies:&nbsp;<a href="https://jetpack.com/support/cookies/" rel="noreferrer noopener" target="_blank">https://jetpack.com/support/cookies/</a>.</p>


  <h2 class="paragraph-headline-2" id="dsg-thirdparty-einleitung">Einbindung von Diensten und Inhalten Dritter</h2>


  <p>Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem
    Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und
    Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”).</p>


  <p>Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren
    Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren
    jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare
    Grafiken, auch als &#8222;Web Beacons&#8220; bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die &#8222;Pixel-Tags&#8220; können
    Informationen, wie der Besucherverkehr auf den Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem
    Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie
    weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden.</p>


  <h2 class="paragraph-headline-2" id="dsg-thirdparty-googlemaps">Google Maps</h2>


  <p>Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Zu den
    verarbeiteten Daten können insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht ohne deren Einwilligung (im Regelfall im Rahmen
    der Einstellungen ihrer Mobilgeräte vollzogen), erhoben werden. Die Daten können in den USA verarbeitet werden. Datenschutzerklärung: <a
      href="https://www.google.com/policies/privacy/" rel="noreferrer noopener" target="_blank">https://www.google.com/policies/privacy/</a>, Opt-Out: <a
      href="https://adssettings.google.com/authenticated" rel="noreferrer noopener" target="_blank">https://adssettings.google.com/authenticated</a>.</p>


  <h2 class="paragraph-headline-2" style="text-align:start">Facebook-Pixel Datenschutzerklärung</h2>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Wir verwenden auf unserer Website das Facebook-Pixel von Facebook. Dafür haben wir einen Code auf unserer Webseite implementiert. Der Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine Ansammlung von Funktionen lädt, mit denen Facebook Ihre Userhandlungen verfolgen kann, sofern Sie über Facebook-Ads auf unsere Webseite gekommen sind. Wenn Sie beispielsweise ein Produkt auf unserer Webseite erwerben, wird das Facebook-Pixel ausgelöst und speichert Ihre Handlungen auf unserer Webseite in einem oder mehreren Cookies. Diese Cookies ermöglichen es Facebook Ihre Userdaten (Kundendaten wie IP-Adresse, User-ID) mit den Daten Ihres Facebook-Kontos abzugleichen. Dann löscht Facebook diese Daten wieder. Die erhobenen Daten sind für uns anonym und nicht einsehbar und werden nur im Rahmen von Werbeanzeigenschaltungen nutzbar. Wenn Sie selbst Facebook-User sind und angemeldet sind, wird der Besuch unserer Webseite automatisch Ihrem Facebook-Benutzerkonto zugeordnet.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen zeigen, die sich auch wirklich dafür interessieren. Mithilfe von Facebook-Pixel können unsere Werbemaßnahmen besser auf Ihre Wünsche und Interessen abgestimmt werden. So bekommen Facebook-User (sofern sie personalisierte Werbung erlaubt haben) passende Werbung zu sehen. Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und eigenen Werbeanzeigen.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Im Folgenden zeigen wir Ihnen jene Cookies, die durch das Einbinden von Facebook-Pixel auf einer Testseite gesetzt wurden. Bitte beachten Sie, dass dies nur Beispiel-Cookies sind. Je nach Interaktion auf unserer Webseite werden unterschiedliche Cookies gesetzt.</span>
  </p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span
    style="color:rgb(51, 51, 51);"> _fbp<br></span><strong><span style="color:rgb(51, 51, 51);">Wert:</span></strong><span
    style="color:rgb(51, 51, 51);"> </span><a href="fb.1.1568287647279.257405483-6311830295-7" target="_blank"><span style="color:rgb(51, 51, 51);">fb.1.1568287647279.257405483-6311830295-7</span></a><span
    style="color:rgb(51, 51, 51);"><br></span><strong><span style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span
    style="color:rgb(51, 51, 51);"> Dieses Cookie verwendet Facebook, um Werbeprodukte anzuzeigen.<br></span><strong><span style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span
    style="color:rgb(51, 51, 51);"> nach 3 Monaten</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span style="color:rgb(51, 51, 51);">&nbsp;fr<br></span><strong><span
    style="color:rgb(51, 51, 51);">Wert:</span></strong><span style="color:rgb(51, 51, 51);"> 0aPf312HOS5Pboo2r..Bdeiuf…</span><a href="1.0.Bdeiuf."
                                                                                                                                  target="_blank"><span
    style="color:rgb(51, 51, 51);">1.0.Bdeiuf.</span></a><span style="color:rgb(51, 51, 51);"><br></span><strong><span style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span
    style="color:rgb(51, 51, 51);"> Dieses Cookie wird verwendet, damit Facebook-Pixel auch ordentlich funktioniert.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);"> nach 3 Monaten</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span style="color:rgb(51, 51, 51);"> comment_author_50ae8267e2bdf1253ec1a5769f48e062311830295-3<br></span><strong><span
    style="color:rgb(51, 51, 51);">Wert:</span></strong><span style="color:rgb(51, 51, 51);"> Name des Autors<br></span><strong><span
    style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span style="color:rgb(51, 51, 51);"> Dieses Cookie speichert den Text und den Namen eines Users, der beispielsweise einen Kommentar hinterlässt.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);"> nach 12 Monaten</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span style="color:rgb(51, 51, 51);"> comment_author_url_50ae8267e2bdf1253ec1a5769f48e062<br></span><strong><span
    style="color:rgb(51, 51, 51);">Wert:</span></strong><span
    style="color:rgb(51, 51, 51);"> https%3A%2F%2Fwww.testseite…%2F (URL des Autors)<br></span><strong><span
    style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span style="color:rgb(51, 51, 51);"> Dieses Cookie speichert die URL der Website, die der User in einem Textfeld auf unserer Webseite eingibt.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);"> nach 12 Monaten</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span style="color:rgb(51, 51, 51);"> comment_author_email_50ae8267e2bdf1253ec1a5769f48e062<br></span><strong><span
    style="color:rgb(51, 51, 51);">Wert:</span></strong><span style="color:rgb(51, 51, 51);"> E-Mail-Adresse des Autors<br></span><strong><span
    style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span style="color:rgb(51, 51, 51);"> Dieses Cookie speichert die E-Mail-Adresse des Users, sofern er sie auf der Website bekannt gegeben hat.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);"> nach 12 Monaten</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Anmerkung:&nbsp;</span></strong><span style="color:rgb(51, 51, 51);">Die oben genannten Cookies beziehen sich auf ein individuelles Userverhalten. Speziell bei der Verwendung von Cookies sind Veränderungen bei Facebook nie auszuschließen.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter </span><a
    href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank"><u><span style="color:rgb(51, 51, 51);">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</span></u></a><span
    style="color:rgb(51, 51, 51);">&nbsp;selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf </span><a
    href="http://www.youronlinechoices.com/de/praferenzmanagement/" target="_blank"><u><span style="color:rgb(51, 51, 51);">http://www.youronlinechoices.com/de/praferenzmanagement/</span></u></a><span
    style="color:rgb(51, 51, 51);"> grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu aktivieren.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Facebook-Pixel. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus anderen Diensten von Facebook, bei denen Sie ein Nutzerkonto haben, verknüpft werden.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen, empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf </span><a
    href="https://www.facebook.com/policy.php" target="_blank"><u><span style="color:rgb(51, 51, 51);">https://www.facebook.com/policy.php</span></u></a><span
    style="color:rgb(51, 51, 51);">.</span></p>
  <h2 class="paragraph-headline-2" style="text-align:start">Facebook Automatischer erweiterter Abgleich Datenschutzerklärung
  </h2>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Wir haben im Rahmen der Facebook-Pixel-Funktion auch den automatischen erweiterten Abgleich (engl. Automatic Advanced Matching) aktiviert. Diese Funktion des Pixels ermöglicht uns, gehashte E-Mails, Namen, Geschlecht, Stadt, Bundesland, Postleitzahl und Geburtsdatum oder Telefonnummer als zusätzliche Informationen an Facebook zu senden, sofern Sie uns diese Daten zur Verfügung gestellt haben. Diese Aktivierung ermöglicht uns Werbekampagnen auf Facebook noch genauer auf Menschen, die sich für unsere Dienstleistungen oder Produkte interessieren, anzupassen.</span>
  </p>
  <h2 class="paragraph-headline-2" style="text-align:start">Google Analytics Datenschutzerklärung</h2>
  <h3 class="paragraph-headline-3">Google Analytics Datenschutzerklärung Zusammenfassung</h3>
  <p><span style="color:rgb(51, 51, 51);"><span
    style="background-color:transparent;">👥 Betroffene: Besucher der Website<br>🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br>📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br>📅 Speicherdauer: abhängig von den verwendeten Properties<br>⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</span></span>
  </p>
  <h3 class="paragraph-headline-3" style="text-align:start">Was ist Google Analytics?</h3>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere Website und unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking-Tool ein und informieren Sie vor allem darüber, welche Daten gespeichert werden und wie Sie das verhindern können.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen. Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:</span>
  </p>
  <ul>
    <li><p><span style="color:rgb(51, 51, 51);">Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich für unser Service interessiert.</span>
    </p></li>
    <li><p><span
      style="color:rgb(51, 51, 51);">Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren und verbessern.</span></p></li>
    <li><p><span style="color:rgb(51, 51, 51);">Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie wir mehr Menschen für unser Service begeistern können.</span>
    </p></li>
    <li><p><span style="color:rgb(51, 51, 51);">Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.</span>
    </p></li>
    <li><p><span style="color:rgb(51, 51, 51);">Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.</span>
    </p></li>
    <li><p><span style="color:rgb(51, 51, 51);">Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</span>
    </p></li>
  </ul>
  <h3 class="paragraph-headline-3" style="text-align:start">Warum verwenden wir Google Analytics auf unserer Website?</h3>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website. Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und kostengünstiger durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.</span>
  </p>
  <h3 class="paragraph-headline-3" style="text-align:start">Welche Daten werden von Google Analytics gespeichert?</h3>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Um mit Google Analytics unsere Website analysieren zu können, muss eine Property-ID in den Tracking-Code eingefügt werden. Die Daten werden dann in der entsprechenden Property gespeichert. Für jede neu angelegte Property ist die Google Analytics 4-Property standardmäßig. Alternativ kann man aber auch noch die Universal Analytics Property erstellen. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Folgende Cookies werden von Google Analytics verwendet:</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span
    style="color:rgb(51, 51, 51);"> _ga<br></span><strong><span style="color:rgb(51, 51, 51);">Wert: </span></strong><a href="2.1326744211.152311830295-5"
                                                                                                                        target="_blank"><span
    style="color:rgb(51, 51, 51);">2.1326744211.152311830295-5</span></a><span style="color:rgb(51, 51, 51);"><br></span><strong><span
    style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span style="color:rgb(51, 51, 51);"> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);"> nach 2 Jahren</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span
    style="color:rgb(51, 51, 51);"> _gid<br></span><strong><span style="color:rgb(51, 51, 51);">Wert: </span></strong><a href="2.1687193234.152311830295-1"
                                                                                                                         target="_blank"><span
    style="color:rgb(51, 51, 51);">2.1687193234.152311830295-1</span></a><span style="color:rgb(51, 51, 51);"><br></span><strong><span
    style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span style="color:rgb(51, 51, 51);">&nbsp;Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);">&nbsp;nach 24 Stunden</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span style="color:rgb(51, 51, 51);"> _gat_gtag_UA_&lt;property-id&gt;<br></span><strong><span
    style="color:rgb(51, 51, 51);">Wert:</span></strong><span style="color:rgb(51, 51, 51);"> 1<br></span><strong><span style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span
    style="color:rgb(51, 51, 51);"> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_ &lt;property-id&gt;.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum: </span></strong><span style="color:rgb(51, 51, 51);">nach 1 Minute</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span
    style="color:rgb(51, 51, 51);"> AMP_TOKEN<br></span><strong><span style="color:rgb(51, 51, 51);">Wert:</span></strong><span style="color:rgb(51, 51, 51);"> keine Angaben<br></span><strong><span
    style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span style="color:rgb(51, 51, 51);"> Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen Fehler hin.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);">&nbsp;nach 30 Sekunden bis zu einem Jahr</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span
    style="color:rgb(51, 51, 51);"> __utma<br></span><strong><span style="color:rgb(51, 51, 51);">Wert: </span></strong><a
    href="1564498958.1564498958.1564498958.1" target="_blank"><span style="color:rgb(51, 51, 51);">1564498958.1564498958.1564498958.1</span></a><span
    style="color:rgb(51, 51, 51);"><br></span><strong><span style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span
    style="color:rgb(51, 51, 51);"> Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);"> nach 2 Jahren</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span
    style="color:rgb(51, 51, 51);"> __utmt<br></span><strong><span style="color:rgb(51, 51, 51);">Wert:</span></strong><span
    style="color:rgb(51, 51, 51);"> 1<br></span><strong><span style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span
    style="color:rgb(51, 51, 51);"> Das Cookie wird wie _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der Anforderungsrate verwendet.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);">&nbsp;nach 10 Minuten</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span
    style="color:rgb(51, 51, 51);"> __utmb<br></span><strong><span style="color:rgb(51, 51, 51);">Wert: </span></strong><a href="3.10.1564498958"
                                                                                                                           target="_blank"><span
    style="color:rgb(51, 51, 51);">3.10.1564498958</span></a><span style="color:rgb(51, 51, 51);"><br></span><strong><span style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span
    style="color:rgb(51, 51, 51);"> Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);">&nbsp;nach 30 Minuten</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span
    style="color:rgb(51, 51, 51);"> __utmc<br></span><strong><span style="color:rgb(51, 51, 51);">Wert:</span></strong><span style="color:rgb(51, 51, 51);"> 167421564<br></span><strong><span
    style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span style="color:rgb(51, 51, 51);"> Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange gespeichert, bis Sie den Browser wieder schließen.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);"> Nach Schließung des Browsers</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span
    style="color:rgb(51, 51, 51);"> __utmz<br></span><strong><span style="color:rgb(51, 51, 51);">Wert:</span></strong><span style="color:rgb(51, 51, 51);"> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br></span><strong><span
    style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span style="color:rgb(51, 51, 51);"> Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);"> nach 6 Monaten</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Name:</span></strong><span
    style="color:rgb(51, 51, 51);"> __utmv<br></span><strong><span style="color:rgb(51, 51, 51);">Wert:</span></strong><span style="color:rgb(51, 51, 51);"> keine Angabe<br></span><strong><span
    style="color:rgb(51, 51, 51);">Verwendungszweck:</span></strong><span style="color:rgb(51, 51, 51);"> Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br></span><strong><span
    style="color:rgb(51, 51, 51);">Ablaufdatum:</span></strong><span style="color:rgb(51, 51, 51);"> nach 2 Jahren</span></p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Anmerkung:</span></strong><span style="color:rgb(51, 51, 51);">&nbsp;Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:</span>
  </p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Heatmaps:</span></strong><span style="color:rgb(51, 51, 51);"> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.</span>
  </p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Sitzungsdauer:</span></strong><span style="color:rgb(51, 51, 51);"> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.</span>
  </p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Absprungrate</span></strong><span style="color:rgb(51, 51, 51);"> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</span>
  </p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Kontoerstellung:</span></strong><span style="color:rgb(51, 51, 51);"> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.</span>
  </p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">IP-Adresse:</span></strong><span style="color:rgb(51, 51, 51);"> Die IP-Adresse wird nur in gekürzter Form dargestellt, damit keine eindeutige Zuordnung möglich ist.</span>
  </p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Standort:</span></strong><span style="color:rgb(51, 51, 51);"> Über die IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.</span>
  </p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Technische Informationen:</span></strong><span style="color:rgb(51, 51, 51);"> Zu den technischen Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.</span>
  </p>
  <p style="text-align:start"><strong><span style="color:rgb(51, 51, 51);">Herkunftsquelle:</span></strong><span style="color:rgb(51, 51, 51);"> Google Analytics beziehungsweise uns interessiert natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</span>
  </p>
  <h3 class="paragraph-headline-3" style="text-align:start">Wie lange und wo werden die Daten gespeichert?</h3>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: </span><a
    href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank"><u><span style="color:rgb(51, 51, 51);">https://www.google.com/about/datacenters/inside/locations/?hl=de</span></u></a>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. Für andere sogenannte Ereignisdaten haben wir die Möglichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu wählen.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Bei Universal Analytics-Properties ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten standardisiert eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:</span>
  </p>
  <ul>
    <li><p><span style="color:rgb(51, 51, 51);">Löschung nach 14 Monaten</span></p></li>
    <li><p><span style="color:rgb(51, 51, 51);">Löschung nach 26 Monaten</span></p></li>
    <li><p><span style="color:rgb(51, 51, 51);">Löschung nach 38 Monaten</span></p></li>
    <li><p><span style="color:rgb(51, 51, 51);">Löschung nach 50 Monaten</span></p></li>
    <li><p><span style="color:rgb(51, 51, 51);">Keine automatische Löschung</span></p></li>
  </ul>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zurückgesetzt, wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.</span>
  </p>
  <h3 class="paragraph-headline-3" style="text-align:start">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
  </h3>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on können Sie unter </span><a
    href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"><u><span
    style="color:rgb(51, 51, 51);">https://tools.google.com/dlpage/gaoptout?hl=de</span></u></a><span style="color:rgb(51, 51, 51);"> runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics) deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser eine eigene Anleitung:</span>
  </p>
  <p style="text-align:start"><a href="https://support.google.com/chrome/answer/95647?tid=311830295" target="_blank"><u><span style="color:rgb(51, 51, 51);">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</span></u></a>
  </p>
  <p style="text-align:start"><a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311830295" target="_blank"><u><span
    style="color:rgb(51, 51, 51);">Safari: Verwalten von Cookies und Websitedaten mit Safari</span></u></a></p>
  <p style="text-align:start"><a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311830295" target="_blank"><u><span
    style="color:rgb(51, 51, 51);">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</span></u></a></p>
  <p style="text-align:start"><a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311830295"
                                 target="_blank"><u><span style="color:rgb(51, 51, 51);">Internet Explorer: Löschen und Verwalten von Cookies</span></u></a></p>
  <p style="text-align:start"><a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311830295" target="_blank"><u><span
    style="color:rgb(51, 51, 51);">Microsoft Edge: Löschen und Verwalten von Cookies</span></u></a></p>
  <h3 class="paragraph-headline-3" style="text-align:start">Rechtsgrundlage</h3>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut</span><strong><span
    style="color:rgb(51, 51, 51);"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</span></strong><span style="color:rgb(51, 51, 51);"> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Google Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist </span><strong><span
    style="color:rgb(51, 51, 51);">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</span></strong><span style="color:rgb(51, 51, 51);">. Wir setzen Google Analytics gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Google verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</span>
  </p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten Google, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss sowie die Klauseln u.a. hier: </span><a
    href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de" target="_blank"><u><span style="color:rgb(51, 51, 51);">https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de</span></u></a><span
    style="color:rgb(51, 51, 51);">.</span></p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: </span><a
    href="http://www.google.com/analytics/terms/de.html" target="_blank"><u><span
    style="color:rgb(51, 51, 51);">http://www.google.com/analytics/terms/de.html</span></u></a><span style="color:rgb(51, 51, 51);"> und </span><a
    href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank"><u><span style="color:rgb(51, 51, 51);">https://support.google.com/analytics/answer/6004245?hl=de</span></u></a><span
    style="color:rgb(51, 51, 51);">.</span></p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Alle Texte sind urheberrechtlich geschützt.</span></p>
  <p style="text-align:start"><span style="color:rgb(51, 51, 51);">Quelle für Abschnitte Facebook Pixel und Google Analytics: Erstellt mit dem </span><a
    href="https://www.adsimple.de/datenschutz-generator/"
    title="Datenschutz Generator von AdSimple für Deutschland"><u><span
    style="color:rgb(51, 51, 51);">Datenschutz Generator</span></u></a><span style="color:rgb(51, 51, 51);"> von AdSimple</span></p>

</div>
