import {MailTemplate} from './models/mailTemplate.interface';

/**
 * Sets the given category in the given mail template.
 * @param template mail template, in which the category should be set
 * @param categoryId category to be set
 */
export function setReportCategoryInTemplate(template: MailTemplate, categoryId?: string) {
  switch (categoryId) {
    case 'spam': {
      template.data.spam = true;
      break;
    }
    case 'inappropriateLanguage': {
      template.data.inappropriateLanguage = true;
      break;
    }
    case 'offensiveInsulting': {
      template.data.offensiveInsulting = true;
      break;
    }
    case 'racist': {
      template.data.racist = true;
      break;
    }
    case 'terrorPropaganda': {
      template.data.terrorPropaganda = true;
      break;
    }
    case 'other': {
      template.data.other = true;
      break;
    }
    case 'illegalItem': {
      template.data.illegalItem = true;
      break;
    }
    case 'wrongCategory': {
      template.data.wrongCategory = true;
      break;
    }
    case 'fraud': {
      template.data.fraud = true;
      break;
    }
    case 'fakeListing': {
      template.data.fakeListing = true;
      break;
    }
    case 'picture': {
      template.data.picture = true;
      break;
    }
    case 'unfair': {
      template.data.unfair = true;
      break;
    }
    case 'fakeRating': {
      template.data.fakeRating = true;
      break;
    }
    default: {
      template.data.noCategory = true;
      break;
    }
  }

}
