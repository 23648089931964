<img *ngIf="currentLang" [matMenuTriggerFor]="langMenu" aria-label="Select language" class="hover-pointer" i18n-aria-label="Select language menu button"
     i18n-matTooltip="Select language menu button" matTooltip="Select language"
     src="./assets/images/icons/flags/{{currentLang.iconCode}}-{{iconSize}}.webp">
<mat-icon *ngIf="!currentLang" [matMenuTriggerFor]="langMenu" class="hover-pointer">language</mat-icon>
<mat-menu #langMenu="matMenu">
  <button (click)="selectLang(lang)" *ngFor="let lang of langs" [class]="lang.code === currentLang?.code ? 'bg-primary text-white' : ''" mat-menu-item>
    <img class="mr-1" src="./assets/images/icons/flags/{{lang.iconCode}}-{{iconSize}}.webp">
    <span>{{languageService.getLanguageNameByCode(lang.code)}}</span>
  </button>
</mat-menu>
