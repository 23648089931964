<!-- Item card -->
<div [class]="listing?.disabled?'bg-gray':''" class="card w-100 mb-3">
    <div class="row no-gutters">
        <div>
            <a [routerLink]="['/browse/listing',listingUid]">
                <img *ngIf="listingImgUrlThumb !== undefined"
                     alt="Picture of {{listingName}}" class="contained-300h-300w"
                     default="./assets/images/default/default-listing-w300.webp"
                     i18n-alt="Picture alt text in item card"
                     src="{{listingImgUrlThumb}}"></a>
        </div>
        <div class="col">
            <div class="card-body">
                <h2 class="paragraph-headline-2"><a [routerLink]="['/browse/listing',listingUid]">{{listingName}}</a></h2>
                <p>{{listingSummary}}</p>
                <app-listing-disabled-info *ngIf="listing?.disabled"></app-listing-disabled-info>
            </div>
        </div>
    </div>
</div>
