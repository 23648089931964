import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PlaceholderDirective} from '../../shared/directives/placeholder.directive';
import {AuthService} from '../auth.service';
import {AuthProvider} from 'ngx-auth-firebaseui';
import {Router} from '@angular/router';
import {BaseComponent} from '../../shared/components/base/base.component';
import {Store} from '@ngrx/store';
import {LinkMenuItem} from 'ngx-auth-firebaseui/lib/components/ngx-auth-firebaseui-avatar/ngx-auth-firebaseui-avatar.component';
import {MatDialog} from '@angular/material/dialog';
import {LoginDialogComponent, LoginDialogModel} from './login-dialog/login-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {UserService} from '../../shared/services/user.service';
import {AppState} from '../../store/app.reducer';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.css'],
})
export class LoginButtonComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  isLoggedIn = false;
  providers = AuthProvider;
  closeEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('loginButton') loginButtonRef ?: ElementRef;
  @ViewChild(PlaceholderDirective) alertHost: PlaceholderDirective | undefined;

  avatarLinks: LinkMenuItem[] = [];
  nonAdminAvatarLinks: LinkMenuItem[] = [
    {icon: 'account_box', text: $localize`My profile`, callback: this.goToMyUserPage.bind(this)},
    {icon: 'account_circle', text: $localize`My Blitzshare`, callback: this.goToMyBlitzshare.bind(this)},
    {icon: 'person_add', text: $localize`Referral program`, callback: this.goToReferrals.bind(this)},
  ];
  adminAvatarLinks: LinkMenuItem[] = [
    {icon: 'account_box', text: $localize`My profile`, callback: this.goToMyUserPage.bind(this)},
    {icon: 'account_circle', text: $localize`My Blitzshare`, callback: this.goToMyBlitzshare.bind(this)},
    {icon: 'admin_panel_settings', text: $localize`Admin panel`, callback: this.goToAdmin.bind(this)},
    {icon: 'person_add', text: $localize`Referral program`, callback: this.goToReferrals.bind(this)},
  ];

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private authService: AuthService,
              private router: Router,
              public dialog: MatDialog,
              private angularFireAuth: AngularFireAuth) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Subscribe to changes of firebase auth state
    this.angularFireAuth.onAuthStateChanged(user => {
      // This always gets called twice. It's a firebase bug: https://stackoverflow.com/a/40436769/5697925
      if (user) {
        this.authService.onFirebaseSignIn(user);
      } else {
        this.authService.onFirebaseLogout();
      }
    });

    this.user$.subscribe(user => {
      if (user?.admin)
        this.avatarLinks = this.adminAvatarLinks;
      else
        this.avatarLinks = this.nonAdminAvatarLinks;
      this.isLoggedIn = !!user;
    });

    this.authService.openLoginDropdownSubject.pipe(takeUntil(this.destroy$)).subscribe(value => this.showDialog());

    this.closeEventEmitter.pipe(takeUntil(this.destroy$)).subscribe(() => {
      setTimeout(args => this.dialog.closeAll(), 3000);
    });

  }

  ngAfterViewInit(): void {
    // Quickly open and close the dialog. This initialized the login component. Without doing this, the user will not be logged in,
    // until they hover the login button or open the login dialog some other way.
    this.showDialog();
    this.dialog.closeAll();
  }

  logout(): void {
    this.authService.onFirebaseLogout();
  }

  showDialog() {
    if (!this.loginButtonRef)
      return;
    const dialogData = new LoginDialogModel(this.loginButtonRef, this.closeEventEmitter);
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      maxWidth: '350px',
      data: dialogData,
      backdropClass: 'backdropBackground',
      panelClass: 'login-dialog-container',
    });
  }


  private goToMyUserPage(): void {
    if (this.user?.uid)
      this.router.navigate(['social', 'user', this.user.uid]);
  };

  private goToMyBlitzshare(): void {
    this.router.navigate(['account']);
  };

  private goToReferrals(): void {
    this.router.navigate(['account/referral']);
  };

  private goToAdmin(): void {
    this.router.navigate(['admin']);
  };

}
