<div class="container bg-container px-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Admin" routerLink="..">Admin</a>
      <li class="breadcrumb-item">
        <span i18n="tools headline">Tools</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="tools headline">Tools</h1>
    <p class="jumbotron-lead" i18n="tools lead text">Miscellaneous admin tools. Use with cautions</p>
  </div>

  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <div *ngIf="!production">
    <h1 class="paragraph-headline" i18n>Enable / disable all listings</h1>
    <p i18n>Set the disabled flag of all listings to true or false. Note, that per query only up to 10000 listings (each) of up to 10000 users are adjusted.
      Keep clicking the button, until there is no more output. That means, that you're done. </p>
    <app-mat-icon-button [callback]="onEnableAllClicked.bind(this)" color="primary" cssClass="mr-2 mb-3" i18n-label icon="play_arrow"
                         label="Enable all listings"></app-mat-icon-button>
    <app-mat-icon-button [callback]="onDisableAllClicked.bind(this)" color="warn" cssClass="mr-2 mb-3" i18n-label icon="pause"
                         label="Disable all listings"></app-mat-icon-button>
  </div>
</div>
