import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared/services/shared.service';
import {AppState} from '../../store/app.reducer';
import {TitleService} from '../../shared/services/title.service';
import {Store} from '@ngrx/store';
import {SocialService} from '../../social/social.service';
import {Conversation} from '../../shared/models/conversation.interface';
import firebase from 'firebase/app';
import {ElementScrollPercentageService} from '../../shared/services/element-scroll-percentage.service';
import {User} from '../../shared/models/user.interface';
import {environment} from '../../../environments/environment';
import {BaseComponent} from '../../shared/components/base/base.component';
import {UserService} from '../../shared/services/user.service';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

@Component({
  selector: 'app-my-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
})
export class MessagesComponent extends BaseComponent implements OnInit {

  error: string | undefined;
  conversations: Conversation[] = [];
  thereIsMore = false;
  /**
   * The scroll percentage, at which new listings are loaded. For example, the value 50 will reload items after scrolling down half the page.
   */
  infiniteScrollPercentage = 90;

  private lastVisible?: QueryDocumentSnapshot<Conversation>;

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private sharedService: SharedService,
              private elementScrollPercentageService: ElementScrollPercentageService,
              private titleService: TitleService,
              public socialService: SocialService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`My messages`);
    this.enableLoadingSpinner($localize`Loading messages...`);

    this.user$.subscribe(user => {
      if (user?.uid)
        this.loadConversations(user);
    });


    this.elementScrollPercentageService
        .getScrollAsStream() // Defaults to Document if no Element supplied.
        .subscribe((percent: number): void => {
          if (!this.thereIsMore || this.infiniteScrollPercentage >= 100)
            return;
          if (percent > this.infiniteScrollPercentage)
            this.onClickShowMore();
        });

  }

  /**
   * Called, when the Show more button is clicked. Loads more transactions.
   */
  onClickShowMore(): void {
    if (this.user)
      this.loadConversations(this.user);
  }

  private loadConversations(user: User) {
    this.socialService.fetchConversations(user?.uid, this.lastVisible).then(wrapper => {
      this.disableLoadingSpinner();
      if (wrapper.data) {
        this.conversations.push(...wrapper.data);
        this.lastVisible = wrapper.lastVisible;
        this.thereIsMore = wrapper.data.length === environment.defaultLoadConversationsCount;
      }
      this.error = wrapper.errorMessage;
    });
  }
}
