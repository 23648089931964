import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import Util from '../../util';
import {environment} from '../../../../environments/environment';
import {SharedService} from '../../services/shared.service';
import {ImgUrls} from '../../models/imgUrls.interface';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

/**
 * Uses https://github.com/Mawi137/ngx-image-cropper
 */
@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnInit, OnDestroy {

  @Input() uploadPath: string | undefined;
  /**
   * If required, you can specify a filename without extension here, e.g. 'electronics' for the electronics category. The thumbnail will get an appropriate suffix, e.g. '.thumbs'. In any case, an extension, e.g. '.webm' will be added.
   * If no filename is specified, the default filename consisting of the current date and time in the format 'YYYY-MM-DD_kk-mm-ss.sss' + the first 50 characters of the uploded file will be used.
   */
  @Input() filename?: string;
  @Input() remainingUploads: number = environment.maxImagesCount;
  @Input() onUploadCallback: ((downloadUrls: ImgUrls, remainingUploads: number) => void) | undefined;

  // Uploader
  isHovering: boolean = false;
  destroy$: Subject<null> = new Subject();

  images: File[] = [];
  base64Images = new Map<File, string>();


  // images: (string | ArrayBuffer | null)[] = [];
  errors: string[] = [];
  limitReached = false;


  constructor(private sharedService: SharedService) {
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(fileList: FileList) {
    this.clearErrors();

    const onLimitReached = () => {
      this.limitReached = true;
    };

    for (let i = 0; i < fileList.length; i++) {
      if (fileList.item(i) !== null) {
        const file: File = fileList!.item(i) as File;

        if (this.imageAlreadySelected(file)) {
          this.errors.push(file.name + ': ' + $localize`Duplicate file.`);
          continue;
        }

        if (this.images.length >= this.remainingUploads) {
          onLimitReached.call(this);
          return;
        }
        Util.validateImageFile(file,
          file => {
            if (this.images.length >= this.remainingUploads) {
              onLimitReached.call(this);
              return;
            }
            this.images.push(file);
          },
          error => {
            this.errors.push(error);
          });

      }
    }
  }

  ngOnInit(): void {
    this.sharedService.startUploadingAllImagesEmitter.pipe(takeUntil(this.destroy$)).subscribe((state: boolean) => {
      if (state)
        this.uploadAll();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  fileChangeEvent(event: any): void {
    const fileList = event.target.files;
    this.onDrop(fileList);
  }

  deleteImg(img: File) {
    const index = this.images.indexOf(img);
    this.images.splice(index, 1);
    this.limitReached = this.images.length > this.remainingUploads;
    this.base64Images.delete(img);
  }


  /**
   * Start uploading all images
   */
  uploadAll() {
    this.clearErrors();

    if (this.images && this.images.length > 0)
      this.sharedService.emitImageUploadStart();
  }

  onNewCroppedImage(base64Image: string, file: File) {
    this.base64Images.set(file, base64Image);
  }

  /**
   * Called, when a file has been uploaded successfully.
   * @param event JSON Object consisting of uploaded file (before it was edited and converted) and download url
   */
  onUploadFinished(event: { file: File, downloadUrls: ImgUrls }) {
    const index = this.images.indexOf(event.file);
    this.images.splice(index, 1);
    if (this.onUploadCallback) {  // Call the onUploadCallback
      this.onUploadCallback(event.downloadUrls, this.images.length);
    }
  }

  getUploadButtonLabel() {
    if (this.images.length === 0)
      return $localize`:Upload 0 images button label|Upload 0 images button label:Upload images`;
    if (this.images.length === 1)
      return $localize`:Upload 1 image button label|Upload 1 image button label:Upload image`;
    return $localize`:Upload multiple images button label|Upload multiple images button label:Upload ${this.images.length} images`;

  }

  private clearErrors() {
    this.errors.length = 0;
    this.limitReached = false;
  }

  /**
   * Checks, if the given image file is already selected (contained in this.images).
   * @param newImage File to be checked
   * @return true, if already selected, false otherwise
   */
  private imageAlreadySelected(newImage: File) {
    for (const image of this.images) {
      if (image.name === newImage.name && image.size === newImage.size)
        return true;
    }

    return false;
  }
}
