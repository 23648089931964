<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
<div *ngIf="listing">

  <app-mat-icon-button [routerLink]="['/listing','edit',listing.uid]" cssClass="mr-2 mb-3" i18n-label icon="edit"
                       label="Edit this listing"></app-mat-icon-button>
  <app-mat-icon-button *ngIf="listing.disabled" [callback]="onEnableClicked.bind(this)" color="accent" cssClass="mr-2 mb-3" i18n-label icon="play_arrow"
                       label="Enable this listing"></app-mat-icon-button>
  <app-mat-icon-button *ngIf="!listing.disabled" [callback]="onDisableClicked.bind(this)" color="accent" cssClass="mr-2 mb-3" i18n-label icon="pause"
                       label="Disable this listing"></app-mat-icon-button>
  <app-mat-icon-button *ngIf="!showActiveTransactions" [callback]="toggleActiveTransactions.bind(this)" color="accent" cssClass="mr-2 mb-3" i18n-label
                       icon="visibility"
                       label="Show active transactions"></app-mat-icon-button>
  <app-mat-icon-button *ngIf="showActiveTransactions" [callback]="toggleActiveTransactions.bind(this)" color="accent" cssClass="mr-2 mb-3" i18n-label
                       icon="visibility_off"
                       label="Hide active transactions"></app-mat-icon-button>
  <app-mat-icon-button *ngIf="!showPastRentals" [callback]="togglePastRentals.bind(this)" color="accent" cssClass="mr-2 mb-3" i18n-label
                       icon="visibility_off"
                       label="Show past rentals"></app-mat-icon-button>
  <app-mat-icon-button *ngIf="showPastRentals" [callback]="togglePastRentals.bind(this)" color="accent" cssClass="mr-2 mb-3" i18n-label
                       icon="visibility_off"
                       label="Hide past rentals"></app-mat-icon-button>
  <app-mat-icon-button [routerLink]="['/listing','delete',listing.uid]" color="warn" cssClass="mr-2 mb-3" i18n-label icon="delete"
                       label="Delete this listing"></app-mat-icon-button>

  <div *ngIf="showActiveTransactions">
    <app-transactions-for-listing [lending]="true" [listingUid]="listing.uid" [open]="true"></app-transactions-for-listing>
  </div>
  <div *ngIf="showPastRentals">
    <app-transactions-for-listing [lending]="true" [listingUid]="listing.uid" [onlyReturned]="true"></app-transactions-for-listing>
  </div>
</div>
