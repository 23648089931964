import {TERMS_AND_CONDITIONS_DOWNLOAD_LINK} from './files';

export const CANNOT_BE_UNDONE = $localize`This cannot be undone.`;
export const BOOKING_CONFIRMATION_TERMS = $localize`By accepting this booking, you go into a contract with Blitzshare based on our
    <a href="${TERMS_AND_CONDITIONS_DOWNLOAD_LINK}" target="toc">terms and conditions</a>.`;
export const BOOKING_CONFIRMATION_TERMS_PAYMENT = $localize`After you accepted the booking, the borrower will be asked to pay the rent price. Our payment provider charges a non-refundable pay-in fee for every payment.
    If you cancel the booking after payment, you will be charged with that pay-in fee (about 3.5% of the rent price) and an additional handling fee (10 EUR)
    for reimbursing the borrower for his lost pay-in fee.`;
export const CURRENT_BOOKING_PERIOD_WILL_REMAIN_VALID = $localize`The current booking period will remain valid.`;
export const DISABLE_LISTING_CONFIRMATION_TITLE = $localize`Are you sure you want to disable this listing?`;
export const DISABLE_LISTING_CONFIRMATION_MESSAGE = $localize`A disabled listing is no longer visible for other users and can no longer be booked or found using
    the search. Bookings are <b>NOT</b> cancelled, when disabling a listing. A disabled listing can be enabled again at any time.`;
export const ENABLE_LISTING_CONFIRMATION_TITLE = $localize`Are you sure you want to enable this listing?`;
export const ENABLE_LISTING_CONFIRMATION_MESSAGE = $localize`An enabled listing is publicly visible for other users and can be booked and found using the search.`;
