import {MangopayBankAccountType} from '../app/shared/enums/mangopayBankAccountType.enum';
import packageJson from '../../package.json';

export const DOMAIN = 'dreammo.eu';

export const environment = {
    production: false,

    // -------------------------------------------------------------------------------------------------
    // Basic app settings
    // -------------------------------------------------------------------------------------------------

    applicationRootUrl: 'https://' + DOMAIN,
    version: packageJson.version,
    // The suffix to be added to the html title attribute, e.g. resulting in 'Weber Barbecue station | Blitzshare'
    defaultTitleSuffix: ' | Blitzshare dev',
    defaultTitle: 'Blitzshare dev',

    // -------------------------------------------------------------------------------------------------
    // Default fetch counts (firestore, algolia)
    // -------------------------------------------------------------------------------------------------

    // default number of listings to be searched (loaded from the server)
    defaultSearchListingsCount: 15,
    // default number of announcements to be loaded from the server
    defaultLoadAnnouncementsCount: 10,
    // default number of FAQs to be loaded from the server
    defaultLoadFaqsCount: 10,
    // default number of messages to be loaded from the server
    defaultLoadMessagesCount: 10,
    // default number of conversations to be loaded from the server
    defaultLoadConversationsCount: 10,
    // default number of notifications to be loaded from the server
    defaultLoadNotificationsCount: 10,
    // default number of transactions to be loaded from the server
    defaultLoadTransactionsCount: 10,
    // default number of listings of a specific user to be loaded from the server
    defaultLoadUserListingsCount: 3,
    // default number of ratings of a specific user to be loaded from the server
    defaultLoadUserRatingsCount: 3,
    // default number of reports to be loaded from the server
    defaultLoadReportsCount: 10,
    // default number of referrals to be loaded from the server. This is also the maximum count of ref links a user can create
    defaultLoadReferralsCount: 10,
    // default number of public user documents to be loaded from the server
    defaultLoadUserUserPublicCount: 5,

    // -------------------------------------------------------------------------------------------------
    // Default cache durations (time, after which a cached entry should be refetched)
    // -------------------------------------------------------------------------------------------------

    defaultAnnouncementCacheAgeInSec: 30,
    defaultFaqCacheAgeInSec: 30,
    defaultUserCacheAgeInSec: 5,
    defaultUserPublicCacheAgeInSec: 30,
    defaultListingCacheAgeInSec: 30,
    defaultListingPrivateCacheAgeInSec: 30,
    defaultCategoryCacheAgeInSec: 30,
    defaultCurrencyCacheAgeInSec: 30,

    // -------------------------------------------------------------------------------------------------
    // Firebase config
    // -------------------------------------------------------------------------------------------------

    firebaseConfig: {
        apiKey: 'AIzaSyB8khGMadHqvEnkIazQZjTumKBDtfWZ70o',
        authDomain: 'blitzshare-dev.firebaseapp.com',
        databaseURL: 'https://blitzshare-dev-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'blitzshare-dev',
        storageBucket: 'blitzshare-dev.appspot.com',
        publicStorageBucket: 'blitzshare-dev-public',
        messagingSenderId: '575006331646',
        appId: '1:575006331646:web:49dade5dea05f2c05bcbaf',
        measurementId: 'G-MRGJXFE2W1',
        regionName: 'europe-west3',
    },
    // fallback language for the firestore, if the selected firestoreLocale is not available (e.g. for categories and currencies).
    // defaultFirestoreLocale should always be 'en'. The interfaces require 'en'-entries, but don't require any other locales.
    defaultFirestoreLocale: 'en',

    // -------------------------------------------------------------------------------------------------
    // Firestore collection names
    // -------------------------------------------------------------------------------------------------

    firestoreCollectionAnnouncements: 'announcements',
    firestoreCollectionCategories: 'categories',
    firestoreCollectionConversations: 'conversations',
    firestoreCollectionCurrencies: 'currencies',
    firestoreCollectionFaqs: 'faqs',
    firestoreCollectionListings: 'listings',
    firestoreCollectionListingsPrivate: 'listingsPrivate',
    firestoreCollectionMails: 'mail',
    firestoreCollectionMessages: 'messages',
    firestoreCollectionNotifications: 'notifications',
    firestoreCollectionPayments: 'payments',
    firestoreCollectionPayouts: 'payouts',
    firestoreCollectionRatings: 'ratings',
    firestoreCollectionReferrals: 'referrals',
    firestoreCollectionReports: 'reports',
    firestoreCollectionTransactions: 'transactions',
    firestoreCollectionUsers: 'users',
    firestoreCollectionUsersPublic: 'usersPublic',

    // -------------------------------------------------------------------------------------------------
    // Email templates
    // -------------------------------------------------------------------------------------------------

    // Document name of the admin notify template (used, e.g. if an admin deletes a listing)
    mailTemplateAdminNotify: 'adminNotify',
    // Document name of the messages template
    mailTemplateMessage: 'message',
    // Document name of the notification template
    mailTemplateNotification: 'notification',
    // Document name of the reminder template
    mailTemplateReminder: 'reminder',
    // Document name of the validationResult template
    mailTemplateValidationResult: 'validationResult',
    // Document name of the report template
    mailTemplateReport: 'report',
    // Document name of the contentRemovedByAdmin template
    mailTemplateContentRemovedByAdmin: 'contentRemovedByAdmin',

    // User UIDs of all users, that should receive report emails
    reportReceiverUids: ['SiXmJBpn7ghJh6PiGX3DFRZPu922'],

    // -------------------------------------------------------------------------------------------------
    // Storage paths
    // -------------------------------------------------------------------------------------------------

    // Root directory for listing images in fire storage. Each listing gets its own subdirectory, whose name is the listingId
    listingImagesPath: 'img/listings',
    // Root directory for category images in fire storage.
    categoryImagesPath: 'img/categories',
    // Root directory for user images in fire storage.
    userImagesPath: 'img/users',
    // Root directory for user images in fire storage.

    publicImgPath: 'https://storage.googleapis.com/blitzshare-dev-public/img/',

    // -------------------------------------------------------------------------------------------------
    // Misc. API keys
    // -------------------------------------------------------------------------------------------------

    algoliaAppId: 'GG9L47ELVY',
    algoliaSearchKey: '9dfd81a9329a79f6ef2914982ebc6a4f',
    algoliaListingsIndex: 'dev_listings',

    geoCodingApiKey: 'AIzaSyAHLWvRUoU78ItWTICsAjNoqOx4ccVhreU',
    translationApiKey: 'AIzaSyDYF9Mb9Pe9CzwbOn1R90AT4h6WDjJFE6k',

    // -------------------------------------------------------------------------------------------------
    // Referrals
    // -------------------------------------------------------------------------------------------------

    // The cut of Blitzshare's commission, that is paid to the referrer
    defaultReferralProvision: 0.1,
    // The shortcut version of the referral link. After this path, the referral name is added, e.g. 'bitsundso'. If there is a subdomain used here, redirection to the resolved path, needs to be done externally, e.g. in the domain settings at the domain host.
    shortcutReferralPath: 'https://go.' + DOMAIN + '/',
    // The path between the applicationRootUrl and the referral name. No leading slash needed.
    referralPath: 'ref/',
    referralLinkNameMaxLength: 30,
    referralLinkNameMinLength: 4,
    referralTimeToLiveSecs: 86400,

    // -------------------------------------------------------------------------------------------------
    // Payment
    // -------------------------------------------------------------------------------------------------

    activePaymentProviders: [],

    // -------------------------------------------------------------------------------------------------
    // Stripe
    // -------------------------------------------------------------------------------------------------

    stripeEnvironmentMode: 'test',
    stripeLinkAccountRefreshUrl: 'http://localhost:4200/account/payment-account?refresh=true',
    stripeLinkAccountRedirectUrl: 'http://localhost:4200/account/payment-account',

    // -------------------------------------------------------------------------------------------------
    // Mangopay
    // -------------------------------------------------------------------------------------------------

    mangopayEnvironmentMode: 'live',
    mangopayCurrenciesByPaymentMethod: {
        'CB_VISA_MASTERCARD': ['EUR', 'GBP', 'USD', 'CHF', 'PLN', 'NOK', 'SEK', 'DKK', 'CAD', 'AUD'],
        'DINERS': ['EUR'],
        'MAESTRO': ['EUR'],
        'P24': ['PLN'],
        'IDEAL': ['EUR'],
        'BCMC': ['EUR'],
        'PAYLIB': ['EUR'],
        'GIROPAY': ['EUR'],
        'SOFORT': ['EUR'],
        'BANK_WIRE': ['*'],
    },
    mangopayEscrowLegalUser: '111205288',
    mangopayEscrowWallets: {'EUR': '111205517', 'USD': '111205639', 'GBP': '111206114', 'PLN': '111206122'},
    defaultBankAccountType: MangopayBankAccountType.IBAN,
    mangopayFixedFees: {'EUR': 30, 'USD': 30, 'GBP': 30, 'PLN': 120}, // in cents, pennies etc.
    transferFeePercentage: 0.055,

    // -------------------------------------------------------------------------------------------------
    // Facebook
    // -------------------------------------------------------------------------------------------------

    facebookCatalogId: 265639308896209,

    // -------------------------------------------------------------------------------------------------
    // Misc
    // -------------------------------------------------------------------------------------------------

    currencyValuesInEur: {'EUR': 1, 'USD': 0.85, 'GBP': 1.15, 'PLN': 0.20},
    vatPercentage: 0.19,
    // Determines, how big the rectangle on the map is, which is shown for a listing. 7 means:	≤ 153m	×	153m. See https://www.movable-type.co.uk/scripts/geohash.html
    geohashPrecision: 7,
    // The number of seconds before the target item pickup or return time, when the buttons 'mark as picked up' and 'mark as returned' become active
    itemPickupReturnToleranceSec: 21600,
    // Suffix to be put in the filename of generated thumbnails before uploading them
    thumbnailSuffix: 'thumb',
    // This is either the width or the height, whichever is smaller, in which thumbnails are created to be uploaded.
    // Thumbnails will have a minimum width and height of 300px.
    thumbnailSize: 300,
    maxImagesCount: 15,
    // Default latitude for users not sharing their location
    defaultLatitude: 48.1498353,
    // Default longitude for users not sharing their location
    defaultLongitude: 11.5914705,

    // -------------------------------------------------------------------------------------------------
    // Debugging
    // -------------------------------------------------------------------------------------------------

    enableReducerLogging: false,

};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
