import {Component, Input, OnInit} from '@angular/core';
import {Rating} from '../../models/rating.interface';
import {TransactionRole} from '../../enums/transactionRole.enum';
import {RatingCriterion} from '../../enums/ratingCriterion.enum';

@Component({
  selector: 'app-rating-star-table',
  templateUrl: './rating-star-table.component.html',
  styleUrls: ['./rating-star-table.component.css'],
})
export class RatingStarTableComponent implements OnInit {

  @Input() rating?: Rating;
  @Input() receiverName?: string;

  constructor() {
  }

  public get transactionRole(): typeof TransactionRole {
    return TransactionRole;
  }

  public get ratingCriterion(): typeof RatingCriterion {
    return RatingCriterion;
  }

  ngOnInit(): void {
  }

}
