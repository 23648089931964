<div *ngIf="bookForm?.valid && rentParams?.rentPeriod && rentParams?.periodPrice" class="mx-0 px-0">
  <table class="table table-striped table-sm">
    <tbody>
    <tr *ngIf="currentRentPeriod as period">
      <th i18n="current rent period|current rent period">Current rent period:</th>
      <td><span i18n="period in days|period in days">
          {period, plural, =0 {days} =1 {1 day} other {{{period | number:'1.0-2':numberFormatLocale}} days}}</span>
        <app-rent-period-info></app-rent-period-info>
      </td>
    </tr>
    <tr *ngIf="rentParams?.rentPeriod as period">
      <th><span *ngIf="currentRentPeriod" i18n>New rent period:</span><span *ngIf="!currentRentPeriod" i18n>Rent period:</span>
      </th>
      <td><span i18n="period in days|period in days">
          {period, plural, =0 {days} =1 {1 day} other {{{period | number:'1.0-2':numberFormatLocale}} days}}</span>
        <app-rent-period-info></app-rent-period-info>
      </td>
    </tr>
    <tr *ngIf="showPrice">
      <th i18n="item detail component price per day">Price per day:
      </th>
      <td>
        <app-money [currencyId]="currencyId" [value]="rentParams?.price?.pricePerDay"></app-money>
      </td>
    </tr>
    <tr *ngIf="showPrice">
      <th i18n="item detail component total rent price">Total rent price:
      </th>
      <td>
        <app-money [currencyId]="currencyId" [value]="rentParams?.periodPrice"></app-money>
      </td>
    </tr>
    </tbody>
  </table>
</div>
