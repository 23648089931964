import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {DocumentReference} from '@angular/fire/firestore';
import {Mail} from '../models/mail.interface';

import {firestore} from '../../app.module'; // for cloud firestore

@Injectable({
  providedIn: 'root',
})
export class MailService {

  constructor() {
  }

  sendMail(mail: Mail): Promise<DocumentReference> {
    return firestore.collection(environment.firestoreCollectionMails).add(mail);
  }
}
