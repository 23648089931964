<span *ngIf="address">
  <span *ngIf="address.formattedAddress">{{address.formattedAddress}}</span>
  <span *ngIf="!address.formattedAddress">
    <span *ngIf="address.district">{{address.district}}, </span>
    <span *ngIf="address.zipCode">{{address.zipCode}} </span>
    <span *ngIf="address.city">{{address.city}}, </span>
    <span *ngIf="address.state">{{address.state}}, </span>
    <span *ngIf="address.countryCode">{{countryService.getCountryNameByCode(address.countryCode)}}</span>

  </span>
</span>
