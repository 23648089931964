<a [routerLink]="['account', 'messages', conversation?.uid]" class="no-underline">
    <div *ngIf="conversation" [class]="isRead() ? 'read' : 'unread'">
        <div class="row m-0 p-1 conversation-box">
            <div *ngIf="otherUser?.imgUrl" class="m-0 p-0">
                <img [src]="otherUser?.imgUrl" class="contained-60w-60h"
                     default="./assets/images/default/default-user-w300.webp">
            </div>
            <div class="col mx-1 p-0">
                <p *ngIf="otherUser?.displayName" class="sender-name">{{otherUser?.displayName}}</p>
                <p *ngIf="!conversation.embeddedListing?.imgUrlThumb" class="message">{{latestMessage?.message|shorten:140:true}}</p>
                <p *ngIf="conversation.embeddedListing?.imgUrlThumb" class="message">{{latestMessage?.message|shorten:100:true}}</p>
                <p [title]="conversation.latestMessageDate.toDate() | moment:'LLLL'"
                   class="date">{{utilService.createFromNowString(conversation.latestMessageDate.toDate())}} </p>
            </div>
            <div *ngIf="conversation.embeddedListing?.imgUrlThumb" align="right" class=" m-0 p-0">
                <img [src]="conversation.embeddedListing?.imgUrlThumb" class="contained-60w-60h"
                     default="./assets/images/default/default-listing-w300.webp">
            </div>
        </div>
    </div>
</a>
