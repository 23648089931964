import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {Rating} from '../../shared/models/rating.interface';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {BookService} from '../../book/book.service';
import {TitleService} from '../../shared/services/title.service';
import {map, takeUntil} from 'rxjs/operators';
import {UserPublic} from '../../shared/models/userPublic.interface';
import {ListingService} from '../../listing/listing.service';
import {Listing} from '../../shared/models/listing.interface';
import {NgCacheRouteReuseService} from 'ng-cache-route-reuse';
import {TransactionRole} from '../../shared/enums/transactionRole.enum';
import {CANNOT_BE_UNDONE} from '../../shared/constants/strings';
import {UtilService} from '../../shared/util.service';
import {UserService} from '../../shared/services/user.service';

@Component({
  selector: 'app-rating-view',
  templateUrl: './rating-view.component.html',
  styleUrls: ['./rating-view.component.scss'],
})
export class RatingViewComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  receiver?: UserPublic;
  rater?: UserPublic;
  rating ?: Rating;
  listing ?: Listing;

  private readonly spinnerKeyLoadRating = 'loadRating';
  private readonly spinnerKeyLoadReceiver = 'loadReceiver';
  private readonly spinnerKeyLoadRater = 'loadRater';
  private readonly spinnerKeyLoadListing = 'loadListing';

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private activatedRoute: ActivatedRoute,
      private bookService: BookService,
      private listingService: ListingService,
      public utilService: UtilService,
      private cacheRouteReuseService: NgCacheRouteReuseService,
      private cdRef: ChangeDetectorRef,
      private titleService: TitleService) {
    super(store);
  }

  public get transactionRole(): typeof TransactionRole {
    return TransactionRole;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setTitle();
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).pipe(map(params => {
          const ratingUid = params.get('ratingUid');
          if (ratingUid === null || ratingUid === undefined) {
            throw new Error('There is no ratingUid.');
          }
          return ratingUid;
        }),
    )
        .subscribe((ratingUid: string) => {
              this.loadRating(ratingUid);
            },
        );

    this.cacheRouteReuseService
        .onAttach(RatingViewComponent)
        .subscribe((component) => {
          this.setTitle();
          this.clearAlerts();
        });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  userIsRater(): boolean {
    return this.rating?.raterUid === this.user?.uid;
  }

  userIsReceiver(): boolean {
    return this.rating?.receiverUid === this.user?.uid;
  }

  onDeleteRating(): void {
    this.clearAlerts();
    this.utilService.showConfirmDialog($localize`Do you really want to delete this rating?`, CANNOT_BE_UNDONE, this.deleteRating.bind(this),
        undefined, undefined, undefined, undefined, 'no');
  }

  deleteRating(): void {
    if (!this?.rating?.uid)
      return;
    this.bookService.deleteRating(this.rating.uid).then(() => {
          this.addSuccess($localize`The rating was deleted.`);
          // Note: the transaction is updated automatically by a cloud function removing the ratingUid
          this.rating = undefined;
        },
        error => this.addError($localize`Deleting rating failed\: ${error}`));
  }

  private setTitle() {
    this.titleService.setTitle($localize`Rating`);
  }

  private loadRating(ratingUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyLoadRating, $localize`Fetching rating...`);
    this.bookService.fetchRating(ratingUid).then(wrapper => {
          this.removeLoadingSpinnerMessage(this.spinnerKeyLoadRating);
          if (wrapper.data) {
            this.rating = wrapper.data;
            this.loadReceiver(this.rating.receiverUid);
            this.loadRater(this.rating.raterUid);
            this.loadListing(this.rating.embeddedListing.uid);
          }
          if (wrapper.errorMessage)
            this.addError($localize`Error loading rating\: ${wrapper.errorMessage}`);
          if (!wrapper.data && !wrapper.errorMessage)
            this.addError($localize`No rating could be found for ID '${ratingUid}'. Please check your URL.`);
        },
    );
  }

  private loadReceiver(receiverUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyLoadReceiver, $localize`Fetching receiver...`);
    this.userService.fetchUserPublic(receiverUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyLoadReceiver);
      if (wrapper.data) {
        this.receiver = wrapper.data;
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading receiver\: ${wrapper.errorMessage}`);
    });
  }

  private loadRater(raterUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyLoadRater, $localize`Fetching rater...`);
    this.userService.fetchUserPublic(raterUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyLoadRater);
      if (wrapper.data) {
        this.rater = wrapper.data;
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading rater\: ${wrapper.errorMessage}`);
    });
  }

  private loadListing(listingUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyLoadListing, $localize`Fetching listing...`);
    this.listingService.fetchListing(listingUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyLoadListing);
      if (wrapper.data) {
        this.listing = wrapper.data;
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading listing\: ${wrapper.errorMessage}`);
    });
  }
}

