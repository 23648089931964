<span *ngIf="criterion === ratingCriterion.Overall" i18n i18n-matTooltip
      matTooltip="What was your overall impression of {{receiverName}}, the item and the transaction? This value is used to calculate a user's average rating.">
  Overall:</span>

<span *ngIf="criterion === ratingCriterion.ItemAsDescribed" i18n i18n-matTooltip
      matTooltip="Was the item like {{receiverName}} had described it in their listing?">Item
  as described:</span>

<span *ngIf="criterion === ratingCriterion.ItemReturnCondition" i18n i18n-matTooltip matTooltip="Did {{receiverName}} take good care of the item?">Item return
  condition:</span>

<span *ngIf="criterion === ratingCriterion.Punctuality" i18n i18n-matTooltip matTooltip="Was {{receiverName}} punctual?">Punctuality:</span>

<span *ngIf="criterion === ratingCriterion.Friendliness" i18n i18n-matTooltip matTooltip="Was {{receiverName}} friendly?">Friendliness:</span>
