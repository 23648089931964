<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [message]="loadingSpinnerMessage"
                     [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>
<app-alerts [alerts]="alerts" [float]="true" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<div *ngIf="report" class="no-underline">
    <div class="row mx-0 p-0 my-2 p-1 jumbotron bg-report">
        <div *ngIf="reporter?.imgUrlThumb" class="m-0 p-0">
            <a [routerLink]="['/social','user',reporter?.uid]">
                <img [src]="reporter?.imgUrlThumb" class="thumbnail"
                     default="./assets/images/default/default-user-w300.webp">
            </a>
        </div>
        <div class="col mx-2 p-0">

            <a [routerLink]="report.uid" class="no-underline">
                <div class="row">
                    <div *ngIf="reporter?.displayName" class="col-auto data-col">
                        <p class="message">
                            <span class="data-headline" i18n="reporter">Reporter</span>: <br class="d-none d-lg-block">{{reporter?.displayName}}
                        </p>
                    </div>
                    <div *ngIf="reporterRatingSummary && reporterRatingSummary.overallScore  " class="col-auto data-col">
                        <p class="message">
                            <span class="data-headline" i18n="lender rating">Lender rating</span>: <br class="d-none d-lg-block">
                            <app-bs-star-rating [rating]="reporterRatingSummary.overallScore" class="d-inline d-md-none" size="small"></app-bs-star-rating>
                            <app-bs-star-rating [rating]="reporterRatingSummary.overallScore" class="d-none d-md-inline" size="medium"></app-bs-star-rating>
                            <span *ngIf="reporterRatingSummary.overallCount" class="ml-1">({{reporterRatingSummary?.overallCount}})</span>
                        </p>
                    </div>
                    <div *ngIf="report.creationDate" class="col-auto data-col">
                        <p class="message">
                            <span class="data-headline" i18n="creation date">Creation date</span>: <br
                                class="d-none d-lg-block">{{utilService.getDate(report.creationDate) | moment:'LLL'}}
                        </p>
                    </div>
                    <div *ngIf="report.lastEditDate" class="col-auto data-col">
                        <p class="message">
                            <span class="data-headline" i18n="last update">Last update</span>: <br
                                class="d-none d-lg-block">{{utilService.getDate(report.lastEditDate) | moment:'LLL'}}
                        </p>
                    </div>
                    <div class="col-auto data-col">
                        <p class="message">
                            <span class="data-headline" i18n="reporter allows contact">Contact allowed</span>: <br class="d-none d-lg-block">
                            <app-yes-no [value]="report.allowContact"></app-yes-no>
                        </p>
                    </div>
                    <div *ngIf="reportCategory" class="col-auto data-col">
                        <p class="message">
                            <span class="data-headline" i18n="category">Category</span>: <br class="d-none d-lg-block">{{reportCategory.value}}
                        </p>
                    </div>
                </div>

                <div class="row">
                    <!-- hide on screens wider than md -->
                    <div class="col d-md-none">
                        <p *ngIf="!reporter?.imgUrlThumb" class="message">{{report.reason|shorten:140:true}}</p>
                        <p *ngIf="reporter?.imgUrlThumb" class="message">{{report.reason|shorten:100:true}}</p>
                    </div>
                    <!-- hide on screens smaller than md -->
                    <div class="col d-none d-md-block mt-3">
                        <p *ngIf="!reporter?.imgUrlThumb" class="message">{{report.reason|shorten:280:true}}</p>
                        <p *ngIf="reporter?.imgUrlThumb" class="message">{{report.reason|shorten:200:true}}</p>
                    </div>
                </div>
            </a>
        </div>

    </div>
</div>
