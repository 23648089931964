import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public startImageUploadEmitter = new Subject<boolean>();
  /**
   * Emitter, that can and should be used to subscribe to image uploads. It emits the download url of the uploaded image, whenever an upload has finished.
   */
  public finishedImageUploadEmitter = new Subject<string>();
  public readyToCreateThumbnailEmitter = new Subject<{ file: File, uploadPath: string }>();
  public startUploadingAllImagesEmitter = new Subject<boolean>();

  constructor(private store: Store<fromApp.AppState>) {
  }


  emitImageUploadStart() {
    this.startImageUploadEmitter.next(true);
  }

  emitFinishedImageUpload(url: string) {
    this.finishedImageUploadEmitter.next(url);
  }

  emitReadyToCreateThumbnail(event: { file: File, uploadPath: string }) {
    this.readyToCreateThumbnailEmitter.next(event);
  }


}
