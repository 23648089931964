<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<p *ngIf="!showLoadingSpinnerTransactions && transactions.length===0 && open" i18n>There are no active transactions for this listing.</p>
<p *ngIf="!showLoadingSpinnerTransactions && transactions.length===0 && completed" i18n>There are no completed transactions for this listing.</p>
<p *ngIf="!showLoadingSpinnerTransactions && transactions.length===0 && onlyReturned" i18n>There are no past rentals for this listing.</p>


<app-transaction-preview *ngFor="let transaction of transactions" [bgContainer]="true" [transaction]="transaction"></app-transaction-preview>

<app-loading-spinner *ngIf="showLoadingSpinnerTransactions" [centered]="true"></app-loading-spinner>

<div *ngIf="thereAreMoreTransactions">
  <button (click)="loadMoreTransactions()" class="btn btn-primary" i18n type="button">Show more</button>
</div>
