import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {MatAccordion} from '@angular/material/expansion';
import {Currency} from '../../../shared/models/currency.interface';
import {UserService} from '../../../shared/services/user.service';
import {Router} from '@angular/router';
import Util from '../../../shared/util';
import {CurrencyService} from '../../../shared/services/currency.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-currencies',
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.scss'],
})
export class CurrenciesComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  @Input() editMode = false;

  @ViewChild('accordion') matAccordion?: MatAccordion;
  /**
   * All currencies
   */
  allCurrencies: Currency[] = [];

  /**
   * Filtered currencies
   */
  currencies: Currency[] = [];
  step = -1;
  filterTerm?: string;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private cdRef: ChangeDetectorRef,
      public currencyService: CurrencyService,
      private router: Router) {
    super(store);
  }

  /**
   * If currencies are given as an input, no currencies will be fetched on init. Instead, allCurrencies will be set to inputCurrencies.
   */
  _inputCurrencies?: Currency[];

  get inputCurrencies(): Currency[] | undefined {
    return this._inputCurrencies;
  }

  @Input() set inputCurrencies(inputCurrencies: Currency[] | undefined) {
    this._inputCurrencies = inputCurrencies;
    try {
      if (inputCurrencies) {
        this.allCurrencies = [...inputCurrencies];
        this.filter();
        this.selectCurrency(this.selectedCurrencyId);
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * If currencies are given as an input, no currencies will be fetched on init. Instead, allCurrencies will be set to inputCurrencies.
   */
  _selectedCurrencyId?: string;

  get selectedCurrencyId(): string | undefined {
    return this._selectedCurrencyId;
  }

  @Input() set selectedCurrencyId(selectedCurrencyId: string | undefined) {
    this._selectedCurrencyId = selectedCurrencyId;
    this.selectCurrency(this.selectedCurrencyId);
  }

  setStep(index: number) {
    this.step = index;
    if (this.editMode) {
      if (this.step > -1)
        this.router.navigate(['admin', 'currencies', this.currencies[index].id]);
      else
        this.router.navigate(['admin', 'currencies']);
    }
  }

  closed() {
    if (this.editMode) {
      this.router.navigate(['admin', 'currencies']);
    }
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  public filter(filterTerm?: string) {
    this.filterTerm = filterTerm;
    if (filterTerm && this.allCurrencies)
      this.currencies = this.allCurrencies.filter(currency =>
          this.currencyIdContainsFilterTerm(currency, filterTerm) ||
          this.currencyIdentifierContainsFilterTerm(currency, filterTerm) ||
          this.currencySymbolContainsFilterTerm(currency, filterTerm) ||
          this.currencyNameContainsFilterTerm(currency, filterTerm),
      );
    else
      this.currencies = this.allCurrencies;
    this.currencies = this.currencies.sort((a, b) => {
      if (!a.id && !b.id)
        return 0;
      if (!a.id)
        return -1;
      if (!b.id)
        return 1;
      return Util.compare(a.id, b.id, true);
    });
  }


  private currencyIdContainsFilterTerm(currency: Currency, filterTerm: string) {
    if (!currency?.id)
      return false;
    return currency.id.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1;
  }

  private currencyIdentifierContainsFilterTerm(currency: Currency, filterTerm: string) {
    if (!currency?.identifier)
      return false;
    return currency.identifier.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1;
  }

  private currencySymbolContainsFilterTerm(currency: Currency, filterTerm: string) {
    if (!currency?.symbol)
      return false;
    return currency.identifier.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1;
  }

  private currencyNameContainsFilterTerm(currency: Currency, filterTerm: string) {
    if (!currency?.strings)
      return false;
    let currencyLangStrings = this.currencyService.getCurrencyLangStrings(currency);
    if (!currencyLangStrings)
      return false;
    if (currencyLangStrings.name0 && currencyLangStrings.name0.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1)
      return true;
    if (currencyLangStrings.name1 && currencyLangStrings.name1.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1)
      return true;
    if (currencyLangStrings.nameN && currencyLangStrings.nameN.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1)
      return true;
    return false;
  }


  private selectCurrency(selectedCurrencyId: string | undefined) {
    if (selectedCurrencyId) {
      const foundSelectedCurrency = this.currencies.find((currency: Currency) => currency.id === selectedCurrencyId);
      if (!foundSelectedCurrency)
        return;
      const index = this.currencies.indexOf(foundSelectedCurrency);
      this.setStep(index);
      // } else {
      //   this.matAccordion?.closeAll();
    }
  }

  getLocalName1(currency: Currency): string {
    return this.currencyService.getCurrencyNameN(currency);
  }
}
