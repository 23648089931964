<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [message]="loadingSpinnerMessage"
                     [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>
<app-alerts [alerts]="alerts" [float]="true" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<div *ngIf="listing" class="no-underline">
  <div [class]="listing.disabled?'bg-gray':'bg-listing'" class="row mx-0 p-0 my-2 p-1 jumbotron ">
    <div *ngIf="listing.imgUrls && listing.imgUrls[0] && listing.imgUrls[0].thumb" class="m-0 p-0">
      <a [routerLink]="['/browse','listing',listing.uid]">
        <img [src]="listing.imgUrls[0].thumb" class="thumbnail"
             default="./assets/images/default/default-listing-w300.webp">
      </a>
    </div>
    <div class="col mx-2 p-0">

      <div class="row">
        <div *ngIf="listing?.name" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="listing name">Listing name</span>: <br class="d-none d-lg-block">{{listing?.name}}
          </p>
        </div>
        <div *ngIf="listing.creationDate" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="creation date">Creation date</span>: <br
            class="d-none d-lg-block">{{utilService.getDate(listing.creationDate) | moment:'LLL'}}
          </p>
        </div>
        <div *ngIf="listing.lastEditDate" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="last update">Last update</span>: <br
            class="d-none d-lg-block">{{utilService.getDate(listing.lastEditDate) | moment:'LLL'}}
          </p>
        </div>
        <div *ngIf="category" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="category">Category</span>: <br class="d-none d-lg-block">{{categoryService.getCategoryName(category)}}
          </p>
        </div>
        <div class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="disabled">Disabled</span>: <br class="d-none d-lg-block">
            <app-yes-no [value]="!!listing.disabled"></app-yes-no>
          </p>
        </div>
        <div *ngIf="lender" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="lender">Lender</span>: <br class="d-none d-lg-block">{{lender.displayName}}
          </p>
        </div>
        <div *ngIf="lenderRatingSummary && lenderRatingSummary.overallScore" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="lender rating">Lender rating</span>: <br class="d-none d-lg-block">
            <app-bs-star-rating [rating]="lenderRatingSummary.overallScore" class="d-inline d-md-none" size="small"></app-bs-star-rating>
            <app-bs-star-rating [rating]="lenderRatingSummary.overallScore" class="d-none d-md-inline" size="medium"></app-bs-star-rating>
            <span *ngIf="lenderRatingSummary.overallCount" class="ml-1">({{lenderRatingSummary?.overallCount}})</span>
          </p>
        </div>
        <div *ngIf="listing?.summary" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="listing summary">Listing summary</span>: <br class="d-none d-lg-block">{{listing?.summary}}
          </p>
        </div>
      </div>

    </div>
    <div *ngIf="lender?.imgUrlThumb" class="m-0 p-0 right">
      <a [routerLink]="['/social','user',lender?.uid]">
        <img [src]="lender?.imgUrlThumb" class="thumbnail" default="./assets/images/default/default-user-w300.webp">
      </a>
    </div>

  </div>
</div>

