import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChildren} from '@angular/core';

import {CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Category} from '../../../shared/models/category.interface';
import {MatSelectionList} from '@angular/material/list';
import {Subject} from 'rxjs';
import {UserService} from '../../../shared/services/user.service';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {takeUntil} from 'rxjs/operators';
import {CategoryService} from '../../../shared/services/category.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-categories-editor-lists',
  templateUrl: './categories-editor-lists.component.html',
  styleUrls: ['./categories-editor-lists.component.scss'],
})
export class CategoriesEditorListsComponent extends BaseComponent implements OnInit {
  @ViewChildren('list') listRefs: ElementRef<MatSelectionList>[] = [];
  @ViewChildren('cdkDropList') cdkDropListRefs: ElementRef<CdkDropList>[] = [];

  categoriesArray: Category[][] = [];

  @Output() onCategorySelected = new EventEmitter<Category>();
  @Input() resetSubject ?: Subject<boolean>;
  @Input() showTrashBin ?: boolean;

  trash: Category[] = [];

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public categoryService: CategoryService) {
    super(store);
  }

  _categories: Category[] = [];

  get categories(): Category[] {
    return this._categories;
  }

  @Input() set categories(categories: Category[]) {
    this._categories = categories;
    this.categoriesArray[0] = categories;
    this.categoriesArray.splice(1, this.categoriesArray.length - 1);
    this.onCategorySelected.emit(undefined);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.resetSubject?.pipe(takeUntil(this.destroy$)).subscribe((state) => {

    });
  }

  drop(event: CdkDragDrop<(Category)[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
    }
    this._categories = this.categoriesArray[0];
  }

  onSelectionClick(category: Category, level: number) {
    this.onCategorySelected.emit(category);
    this.setSubcategories(category, level);
  }

  addCategory(level: number) {
    const newCategory: Category = {
      id: `newCategory${new Date().getTime()}`,
      level,
      subcategories: [],
      imgUrlFull: '',
      strings: {
        en: {
          name: '',
          elementName: '',
          description: '',
        },
      },
    };
    this.categoriesArray[level].push(newCategory);
  }

  clearTrash(): void {
    this.trash.length = 0;
  }

  private setSubcategories(category: Category, level: number) {
    if (category?.subcategories)
      this.categoriesArray[level + 1] = category.subcategories;
    else
      this.categoriesArray[level + 1] = [];
    // Reset all further levels
    this.categoriesArray.splice(level + 2, this.categoriesArray.length - level - 2);
  }
}
