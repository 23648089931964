import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImgUrls} from '../../models/imgUrls.interface';

@Component({
  selector: 'app-thumbnail-widget',
  templateUrl: './thumbnail-widget.component.html',
  styleUrls: ['./thumbnail-widget.component.scss'],
})
export class ThumbnailWidgetComponent implements OnInit {


  @Input() imgUrls: ImgUrls[] = [];
  @Output() imgClicked = new EventEmitter<ClickedImg>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onClick(index: number, imgUrls: ImgUrls) {
    this.imgClicked.next({index, imgUrls});
  }
}

export interface ClickedImg {
  index: number;
  imgUrls: ImgUrls;
}
