import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared/services/shared.service';
import {AppState} from '../../store/app.reducer';
import {TitleService} from '../../shared/services/title.service';
import {Listing} from '../../shared/models/listing.interface';
import {Store} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import firebase from 'firebase/app';
import {ListingService} from '../../listing/listing.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {UserService} from '../../shared/services/user.service';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.css'],
})
export class ListingsComponent extends BaseComponent implements OnInit {
  thereIsMore = true;
  periodInDays = 1;
  listings: Listing[] = [];
  searching = false;
  lastVisibleListing?: DocumentSnapshot<Listing>;

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private listingService: ListingService,
              private sharedService: SharedService,
              private titleService: TitleService,
  ) {
    super(store);
  }


  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`My listings`);

    this.enableLoadingSpinner($localize`Loading listings...`);

    this.user$.subscribe((user) => {
          if (user?.uid)
            this.loadListings();
        },
    );
  }


  refresh(): void {
    this.clearAlerts();
    if (this.firebaseUser?.uid) {
      this.enableLoadingSpinner($localize`Refreshing listings...`);
      this.lastVisibleListing = undefined;
      this.listings.length = 0;
      this.loadListings();
    }
  }

  /**
   * Requests more search listings
   * @param count requested number of additional listings
   */
  loadMoreListings(count: number): void {
    if (!this.listings || !this.thereIsMore || this.searching)
      return;
    this.loadListings();
  }


  loadListings(): void {
    this.enableLoadingSpinner($localize`Loading listings...`);
    this.searching = true;
    if (!this.firebaseUser)
      return;
    this.listingService.fetchListings(this.firebaseUser.uid, false, undefined, this.lastVisibleListing).then(wrapper => {
      this.disableLoadingSpinner();
      this.searching = false;
      if (wrapper.data) {

        this.listings.push(...wrapper.data);
        // Recreate the listings array in order to trigger a fresh setListings in listings-view component. This is necessary for the table to be initialized
        this.listings = [...this.listings];
      }
      this.thereIsMore = wrapper.data !== undefined && wrapper.data.length === environment.defaultLoadUserListingsCount;
      this.lastVisibleListing = wrapper.lastVisible;
      if (wrapper.errorMessage)
        this.addError($localize`My listings could not be loaded\: ${wrapper.errorMessage}`);
    });
  }

}
