import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {TitleService} from '../../shared/services/title.service';
import {Faq} from '../../shared/models/faq.interface';
import Util from '../../shared/util';
import {LayoutService} from '../../layout/layout.service';
import {takeUntil} from 'rxjs/operators';
import {ImportExportModalContentComponent} from '../../shared/components/import-export-modal-content/import-export-modal-content.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-faqs-editor',
  templateUrl: './faqs-editor.component.html',
  styleUrls: ['./faqs-editor.component.scss'],
})
export class FaqsEditorComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  selectedFaqUid?: string;
  faqs: Faq[] = [];

  constructor(
      protected store: Store<AppState>,
      private cdRef: ChangeDetectorRef,
      private modalService: NgbModal,
      private layoutService: LayoutService,
      private titleService: TitleService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`FAQs editor`);
    this.layoutService.fetchFaqs(100).then(wrapper => {
      if (wrapper.data)
        this.faqs = wrapper.data;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });

    this.layoutService.onFaqDeleted$.pipe(takeUntil(this.destroy$)).subscribe(uid => {
      this.removeFromFaqsList(uid);
    });
    this.layoutService.onFaqSaved$.pipe(takeUntil(this.destroy$)).subscribe(faq => {
      this.reflectUpdateInFaqsList(faq);
    });
    this.layoutService.onFaqSelected$.pipe(takeUntil(this.destroy$)).subscribe(uid => {
      this.selectFaq(uid);
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  onImportExport(): void {
    this.clearAlerts();
    const modalRef = this.modalService.open(ImportExportModalContentComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.exportJson = JSON.stringify(this.faqs);
    modalRef.componentInstance.filename = 'blitzshare_faqs';

    modalRef.componentInstance.importCallback = (faqsJson: string) => {
      if (!faqsJson) {
        this.addError($localize`Nothing was imported. Aborted.`);
        return;
      }
      const importedFaqs: Faq[] = JSON.parse(faqsJson);
      if (!importedFaqs) {
        this.addError($localize`The import did not contain any FAQs. Aborted.`);
        return;
      }
      this.faqs = importedFaqs;
      this.layoutService.deleteAllFaqs().then(() => {
            this.addSuccess($localize`Successfully deleted all existing FAQs.`);

            importedFaqs.forEach(faq => {
              if (!faq?.uid) {
                this.addError($localize`Error importing FAQ <i>"${this.layoutService.getFaqQuestion(faq)}"</i>\: FAQ is undefined or has no uid`);
                return;
              }
              this.layoutService.updateFaq(faq.uid, faq, faq, false, () => {
                    this.addSuccess($localize`Successfully imported FAQ <i>"${this.layoutService.getFaqQuestion(faq)}"</i>.`);
                  },
                  (error) => {
                    this.addError($localize`Error importing FAQ <i>"${this.layoutService.getFaqQuestion(faq)}"</i>\: ${error}`);
                  });
            });
          },
          reason => {
            this.addError($localize`Error deleting all existing FAQs\: ${reason}`);
          });
      console.log(importedFaqs);
    };
  }

  /**
   * Removes the FAQ with the given UID from the FAQs list
   * @param uid UID of the removed FAQ
   */
  private removeFromFaqsList(uid: string) {
    const removedFaq = this.faqs.find((faq: Faq) => faq.uid === uid);
    if (!removedFaq)
      return;
    this.faqs = Util.removeFromArray([...this.faqs], removedFaq);
    this.faqs = [...this.faqs];
  }

  /**
   * Updates the list with the newly saved FAQ. This works both for insertions or updates.
   * @param faq saved FAQ
   */
  private reflectUpdateInFaqsList(faq: Faq) {
    if (!this.faqs)
      this.faqs = [];
    this.faqs = [...this.faqs];
    const updatedFaqIndex = this.faqs.findIndex(element => element.uid === faq.uid);
    if (updatedFaqIndex > -1)
      this.faqs[updatedFaqIndex] = faq;
    else
        // This was an insert
      this.faqs.push(faq);

    this.faqs = [...this.faqs];
    this.selectedFaqUid = faq.uid;
  }

  private selectFaq(uid?: string) {
    this.selectedFaqUid = uid;
  }

}
