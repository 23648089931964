<h1 *ngIf="!embedded" class="paragraph-headline" i18n>My master data</h1>

<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
<div class="alert alert-danger" *ngIf="((fetchUserActionResult$ | async)?.errorMessage) as message">{{message}}</div>

<form (ngSubmit)="save()" [formGroup]="form">

  <div class="row">
    <div class="col-auto width-up-to-250px">
      <mat-form-field class="mat-form-input ">
        <mat-label i18n="firstName">First name</mat-label>
        <input #firstNameRef formControlName="firstName" matInput maxlength="{{firstNameMaxLength}}">
        <mat-error *ngIf="!form.get('firstName')?.valid && form.get('firstName')?.touched" i18n>Please enter a first name.</mat-error>
        <span class="character-counter">{{firstNameRef.value.length}}/{{firstNameMaxLength}}</span>
      </mat-form-field>
    </div>
    <div class="col-auto width-up-to-250px">
      <mat-form-field class="mat-form-input">
        <mat-label i18n="lastName">Last name</mat-label>
        <input #lastNameRef formControlName="lastName" matInput maxlength="{{lastNameMaxLength}}">
        <mat-error *ngIf="!form.get('lastName')?.valid && form.get('lastName')?.touched" i18n>Please enter a last name.</mat-error>
        <span class="character-counter">{{lastNameRef.value.length}}/{{lastNameMaxLength}}</span>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-auto width-up-to-250px">
      <mat-form-field class="mat-form-input">
        <mat-label i18n="birthdate label">Birthday</mat-label>
        <input [matDatepicker]="picker" [max]="maxBirthday" [min]="minBirthday" formControlName="birthdate" matInput>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="form?.get('birthdate')?.hasError('matDatepickerMin')" i18n>Your birth date lies too long in the past.</mat-error>
        <mat-error *ngIf="form?.get('birthdate')?.hasError('matDatepickerMax')" i18n>You must be at least 18 years old to use Blitzshare.</mat-error>
      </mat-form-field>
    </div>

    <div class="col-auto width-up-to-250px">
      <mat-form-field class="mat-form-input">
        <mat-label i18n="gender">Gender</mat-label>
        <mat-select formControlName="gender" id="gender" panelClass="mat-select-panel-500px">
          <mat-option [value]="null" i18n>Please choose your gender</mat-option>
          <mat-option [value]="gender.Female" i18n>Female</mat-option>
          <mat-option [value]="gender.Male" i18n>Male</mat-option>
          <mat-option [value]="gender.Other" i18n="other gender|other gender">Other</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-auto width-up-to-250px">
      <mat-form-field>
        <mat-label i18n="nationality">Nationality</mat-label>
        <mat-select formControlName="nationalityCountryCode" id="nationalityCountryCode" panelClass="mat-select-panel-500px">
          <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let country of countries" [value]="country.code">{{country.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-auto width-up-to-250px">
      <mat-form-field class="mat-form-input ">
        <mat-label i18n="phoneNumber">Phone number</mat-label>
        <input #phoneNumberRef formControlName="telephoneNumber" matInput maxlength="{{phoneNumberMaxLength}}"
               placeholder="+4912312345678" required>
        <mat-error *ngIf="!form.get('telephoneNumber')?.valid && form.get('telephoneNumber')?.touched" i18n="phone number invalid format">Please enter a valid phone
          number in format: +4912312345678.
        </mat-error>
        <span class="character-counter">{{phoneNumberRef.value.length}}/{{phoneNumberMaxLength}}</span>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="form.get('telephoneNumber')?.valid" class="col-auto">
    <mat-checkbox color="primary" formControlName="showPhoneNumberAfterBooking" i18n>Show my phone number to the borrower after I
      accepted their booking.
    </mat-checkbox>
  </div>

  <div *ngIf="addressHint && !form.get('address')?.touched" class="alert alert-warning">{{addressHint}}</div>
  <mat-form-field
          [class]="form.value.address !== address?.formattedAddress || !address?.street || !address?.city || !address?.zipCode ? 'mat-form-field-invalid' : '' "
          class="mat-form-input text-input-100">
    <mat-label i18n="address">Address</mat-label>
    <input #addressBar (keydown.enter)="$event.preventDefault()" [value]="address?.formattedAddress" autocapitalize="off" autocomplete="off"
           autocorrect="off" formControlName="address" i18n-placeholder="search address" id="address" matInput placeholder="Search address" required
           spellcheck="false" type="text">
    <app-address-bar-map-errors [addressBarValue]="form.value.address" [address]="address"></app-address-bar-map-errors>
  </mat-form-field>

  <app-map (coords)="onMapLocationChanged($event)" (geocoderResult)="onMapGeocoderResultChanged($event)" [lat]="mapLat" [lng]="mapLng"></app-map>
  <br>
  <app-address-table [address]="address" [showHint]="false"
                     [showName]="false"></app-address-table>

  <app-mat-icon-button *ngIf="showSaveButton"
                       [disabled]="showLoadingSpinner || !form.valid || form.value.address !== address?.formattedAddress || !address?.street || !address?.city || !address?.zipCode"
                       color="primary" cssClass="my-3" i18n-label="Save master data" icon="save" label="Save master data" type="submit"></app-mat-icon-button>
</form>

<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [message]="loadingSpinnerMessage"></app-loading-spinner>
