import firebase from 'firebase/app';

import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

export class Wrapper<T> {
  data?: T;
  errorMessage?: string;
  lastVisible?: QueryDocumentSnapshot<any>;
  lastVisible2?: QueryDocumentSnapshot<any>;

  constructor(data?: T, errorMessage?: string, lastVisible?: QueryDocumentSnapshot<any>, lastVisible2?: QueryDocumentSnapshot<any>) {
    this.data = data;
    this.errorMessage = errorMessage;
    this.lastVisible = lastVisible;
    this.lastVisible2 = lastVisible2;
  }

}
