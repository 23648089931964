import {Component, Input, OnInit} from '@angular/core';
import {RatingCriterion} from '../../enums/ratingCriterion.enum';

@Component({
  selector: 'app-rating-criterion-headline',
  templateUrl: './rating-criterion-headline.component.html',
  styleUrls: ['./rating-criterion-headline.component.css'],
})
export class RatingCriterionHeadlineComponent implements OnInit {

  @Input() receiverName?: string;
  @Input() criterion?: RatingCriterion;

  constructor() {
  }

  public get ratingCriterion(): typeof RatingCriterion {
    return RatingCriterion;
  }

  ngOnInit(): void {
  }

}
