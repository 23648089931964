import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Category, CatLangStrings} from '../../../shared/models/category.interface';
import {Subject} from 'rxjs';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {Mutex} from 'async-mutex';
import {ImgUrls} from '../../../shared/models/imgUrls.interface';
import {environment} from '../../../../environments/environment';
import {ImageCarouselModalContentComponent} from '../../../shared/components/image-carousel-modal-content/image-carousel-modal-content.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ListingService} from '../../../listing/listing.service';
import {CategoryService} from '../../../shared/services/category.service';
import {TranslateService} from '../../../shared/services/translate.service';
import {mergeMap, take, takeUntil} from 'rxjs/operators';
import Util from '../../../shared/util';
import {StorageService} from '../../../shared/services/storage.service';
import {SharedService} from '../../../shared/services/shared.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-categories-editor-form',
  templateUrl: './categories-editor-form.component.html',
  styleUrls: ['./categories-editor-form.component.css'],
})
export class CategoriesEditorFormComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  form!: FormGroup;
  idMaxLength = 40;
  imgUrlMaxLength = 512;
  langMaxLength = 2;
  nameMaxLength = 64;
  elementNameMaxLength = 64;
  descriptionMaxLength = 10000;

  languages = ['en', 'de', 'fr', 'it', 'es', 'ru'];

  @Input() resetSubject ?: Subject<boolean>;

  // Image upload
  imageUploadPath = environment.categoryImagesPath;
  /**
   * State, whether an image upload is currently in progress. If it is, the user will have to wait before they can finish editing.
   */
  imageUploadInProgress: boolean = false;
  imageMutex = new Mutex();
  remainingImageUploads: number = 1;

  selectedTabIndex = 0;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private modalService: NgbModal,
      private cdRef: ChangeDetectorRef,
      private listingService: ListingService,
      private categoryService: CategoryService,
      private sharedService: SharedService,
      private storageService: StorageService,
      private translateService: TranslateService,
      private formBuilder: FormBuilder) {
    super(store);
  }

  _category?: Category;

  get category(): Category | undefined {
    return this._category;
  }

  @Input() set category(category: Category | undefined) {
    this._category = category;
    if (this.form) {
      this.reloadForm();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.form = this.createForm();

    this.sharedService.startImageUploadEmitter.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (state)
        this.imageUploadInProgress = true;
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  keyup($event: KeyboardEvent) {
    this.writeCategory();
  }

  idChange($event: Event) {
    if (!this.getStringsFormArray().at(0).value?.name && this.form?.value.id)
      this.getStringsFormArray().at(0).patchValue({name: this.form.value.id});
    if (!this.getStringsFormArray().at(0).value?.elementName && this.form?.value.id) {
      let elementName = this.form.value.id.substr(0, this.form.value.id.length - 1);
      elementName = Util.lowerCaseFirstLetter(elementName);
      this.getStringsFormArray().at(0).patchValue({elementName: elementName});
    }
    this.writeCategory();
  }

  getStringsFormArray(): FormArray {
    return this.form?.get('strings') as FormArray;
  }

  deleteStrings(index: number): void {
    this.getStringsFormArray().removeAt(index);
    this.form.markAsTouched();
    this.writeCategory();
  }

  translateStrings(target: string, index: number): void {
    if (!target || target === 'en')
      return;
    const enName = this.category?.strings.en.name;
    if (enName)
      this.translateService.translate(enName, target).pipe(take(1)).subscribe(result => {
        this.getStringsFormArray().at(index).patchValue({name: result});
        this.writeCategory();
      });
    const enElementName = this.category?.strings.en.elementName;
    if (enElementName)
      this.translateService.translate(enElementName, target).pipe(take(1)).subscribe(result => {
        this.getStringsFormArray().at(index).patchValue({elementName: result});
        this.writeCategory();
      });
    const enDescription = this.category?.strings.en.description;
    if (enDescription)
      this.translateService.translate(enDescription, target).pipe(take(1)).subscribe(result => {
        this.getStringsFormArray().at(index).patchValue({description: result});
        this.writeCategory();
      });
  }

  addMissingTranslations(): void {
    // Go through all languages
    this.languages.forEach(lang => {
      // If the current language is 'en', skip it
      if (lang === 'en')
        return;
      ;
      // Check, if the language is missing
      if (!this.shouldDisplayLanguage(lang))
        return;
      // This language is in fact missing
      const enName = this.category?.strings.en.name;
      const enElementName = this.category?.strings.en.elementName;
      const enDescription = this.category?.strings.en.description;

      if (lang && enName && enElementName)
        this.addTranslation(lang, enName, enElementName, enDescription ? enDescription : '');
    });
  }

  addStrings(lang?: string, name?: string, elementName?: string, description?: string, goToTab = true): void {
    this.getStringsFormArray().push(
        this.createStringsFormGroup(lang, name, elementName, description),
    );
    this.form.markAsTouched();
    this.writeCategory();
    if (goToTab)
      setTimeout(() => {
        this.selectedTabIndex = this.getStringsFormArray().length - 1;
      }, 50);

  }

  /**
   * Returns true, if the given language add button should be displayed. False otherwise.
   * @param lang language code
   * @return true, if the given language add button should be displayed. False otherwise.
   */
  shouldDisplayLanguage(lang: string) {
    for (let i = 0; i < this.getStringsFormArray().length; i++) {
      const strings = this.getStringsFormArray().at(i);
      if (strings.value.lang === lang)
        return false;
    }
    return true;
  }

  /**
   * Callback, which is called after a successful picture upload. Writes the download urls into the listing's imgUrls array.
   * @param imgUrls URLs (full and thumbnail) of the uploaded image
   */
  onUploadCallback = (imgUrls: ImgUrls, remainingUploads: number) => {
    this.imageMutex.runExclusive(async () => {
      this.onImageUploadCompleted(imgUrls);
    }).then(() => {
      if (remainingUploads === 0) {
        // All uploads finished.
        this.imageUploadInProgress = false;
        this.updateRemainingImageCount();
      }
    });
  };

  /**
   * Called after a successful picture upload. Writes the download urls into the listing's imgUrls array.
   * @param downloadUrl URL of the uploaded image
   */
  onImageUploadCompleted(downloadUrls: ImgUrls): void {
    if (!this.category || !this.user || !downloadUrls.full)
      return;
    this.category.imgUrlFull = downloadUrls.full;
    this.category.imgUrlThumb = downloadUrls.thumb;
    this.form.patchValue({imgUrlFull: this.category.imgUrlFull, imgUrlThumb: this.category.imgUrlThumb});
    this.updateRemainingImageCount();
  }

  /**
   * Opens the given array of images in a carousel inside a modal.
   * @param selectedId the ID of the image to be selected at the beginning
   * @param imgUrls all images to be shown in the carousel
   */
  openImage(imgUrl?: string): void {
    if (!this.category?.imgUrlFull || !this.category?.imgUrlThumb)
      return;

    const modalRef = this.modalService.open(ImageCarouselModalContentComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.selectedId = 0;
    const imgUrls: ImgUrls = {full: this.category.imgUrlFull, thumb: this.category.imgUrlThumb};
    modalRef.componentInstance.imgUrls = [imgUrls];
    if (this.category)
      modalRef.componentInstance.altName = this.categoryService.getCategoryName(this.category);
    modalRef.componentInstance.deleteImageCallback = (index: number) => {
      if (!this.category?.imgUrlFull || !this.category?.imgUrlThumb)
        return;
      this.category.imgUrlFull = undefined;
      this.category.imgUrlThumb = undefined;
      this.form.patchValue({imgUrlFull: undefined, imgUrlThumb: undefined});
      this.updateRemainingImageCount();
      this.storageService.deleteImageFromStorage(imgUrls);
    };
  }

  /**
   * Writes the changes of the form back to the category object.
   */
  private writeCategory() {
    if (this._category) {
      this._category.id = this.form?.value.id;
      this._category.imgUrlFull = this.form?.value.imgUrlFull;
      this._category.imgUrlThumb = this.form?.value.imgUrlThumb;
      // Reset all lang strings in order to delete no longer existing ones
      this._category.strings = {en: {name: 'Unidentified category', elementName: 'unidentified category item', description: ''}};
      for (let i = 0; i < this.getStringsFormArray().length; i++) {
        const strings = this.getStringsFormArray().at(i);
        const name = strings.value.name;
        const elementName = strings.value.elementName;
        const description = strings.value.description;

        // If the form is empty, skip this language
        if (!name && !elementName && !description)
          continue;

        const catLangStrings: CatLangStrings = {name, elementName, description};
        this._category.strings[strings.value.lang] = catLangStrings;
      }
    }
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      id: ['', [Validators.required, Validators.maxLength(this.idMaxLength)]],
      imgUrlFull: ['', [Validators.maxLength(this.imgUrlMaxLength)]],
      imgUrlThumb: ['', [Validators.maxLength(this.imgUrlMaxLength)]],
      strings: this.formBuilder.array([],
      ),
    });
  }

  private createStringsFormGroup(lang?: string, name?: string, elementName?: string, description?: string): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
          // If lang is 'en', the lang field should be disabled.
          lang: [lang, [Validators.maxLength(this.langMaxLength), Validators.required]],
          name: [name, [Validators.maxLength(this.nameMaxLength), Validators.required]],
          elementName: [elementName, [Validators.maxLength(this.elementNameMaxLength), Validators.required]],
          description: [description, [Validators.maxLength(this.descriptionMaxLength)]],
        },
    );
  }

  /**
   * Updates the count of images, that can be uploaded.
   */
  private updateRemainingImageCount() {
    if (this.category && this.category.imgUrlFull)
      this.remainingImageUploads = 0;
    else
      this.remainingImageUploads = 1;
  }

  private addTranslation(lang: string, enName: string, enElementName: string, enDescription: string) {
    if (!this.category)
      return;

    const nameTranslation$ = this.translateService.translate(enName, lang).pipe(take(1));
    const elementNameTranslation$ = this.translateService.translate(enElementName, lang).pipe(take(1));
    const descriptionTranslation$ = this.translateService.translate(enDescription, lang).pipe(take(1));

    nameTranslation$.pipe(
        mergeMap(() => elementNameTranslation$, (nameResult: string, elementNameResult: string) => {
          return {nameResult, elementNameResult};
        }),
        mergeMap(() => descriptionTranslation$, (namesResult, descriptionResult: string) => {
          return {namesResult, descriptionResult};
        }),
        takeUntil(this.destroy$))
        .subscribe((result) => {
              console.log(result);
              if (this.category) {
                const catLangStrings: CatLangStrings = {
                  name: result.namesResult.nameResult,
                  elementName: result.namesResult.elementNameResult,
                  description: result.descriptionResult,
                };
                this.category.strings[lang] = catLangStrings;
                this.reloadForm();
              }
            },
        );
  }

  private reloadForm() {
    this.form.reset();
    this.getStringsFormArray().clear();
    if (this.category) {
      this.form.patchValue({id: this.category.id, imgUrlFull: this.category.imgUrlFull, imgUrlThumb: this.category.imgUrlThumb});
      Object.entries(this.category.strings).forEach(entry => {
        const langStrings = entry[1];
        this.addStrings(entry[0], langStrings.name, langStrings.elementName, langStrings.description, false);
      });
      this.updateRemainingImageCount();
    }
  }
}
