import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {environment} from '../../../environments/environment';
import {FunctionsService} from '../../shared/services/functions.service';
import {Router} from '@angular/router';
import {TitleService} from '../../shared/services/title.service';
import {UtilService} from '../../shared/util.service';
import {ConnectedPaymentAccount, User} from '../../shared/models/user.interface';
import {take} from 'rxjs/operators';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ValidatorService} from 'angular-iban';
import {Currency} from '../../shared/models/currency.interface';
import {CurrencyService} from '../../shared/services/currency.service';
import {SharedService} from '../../shared/services/shared.service';
import * as MangoPay from '../../shared/models/mangopay/typings';
import {CurrencyISO} from '../../shared/models/mangopay/typings/types';
import {CountryService} from '../../shared/services/country.service';
import {CANNOT_BE_UNDONE} from '../../shared/constants/strings';
import {Country} from '../../shared/models/country.interface';
import {IdName} from '../../shared/models/IdName.interface';
import {MangopayDocumentType} from '../../shared/enums/mangopayDocumentType.enum';
import {MangopayBankAccountType} from '../../shared/enums/mangopayBankAccountType.enum';
import {
  MANGOPAY_KYC_DOCUMENT_DEFAULT_MAX_BYTES,
  MANGOPAY_KYC_DOCUMENT_DEFAULT_MIN_BYTES,
  MANGOPAY_KYC_DOCUMENT_IDENTITY_PROOF_MIN_BYTES,
} from '../../shared/constants/numbers';
import Util from '../../shared/util';
import {BankAccountCreationRequest} from '../../shared/models/mangopay/bankAccountCreationRequest.interface';
import {WalletCreationRequest} from '../../shared/models/mangopay/walletCreationRequest.interface';
import {KycDocumentCreationRequest} from '../../shared/models/mangopay/kycDocumentCreationRequest.interface';
import {DeactivateBankAccountRequest} from '../../shared/models/mangopay/deactivateBankAccountRequest.interface';
import {WalletFetchingRequest} from '../../shared/models/mangopay/walletFetchingRequest.interface';
import {KycDocumentFetchingRequest} from '../../shared/models/mangopay/kycDocumentFetchingRequest.interface';
import {BankAccountFetchingRequest} from '../../shared/models/mangopay/bankAccountFetchingRequest.interface';
import {MangopayService} from '../../pay/mangopay.service';
import Locale from '../../shared/services/locale';
import {convertAmount} from '../../pay/mangopayStatic';
import {PayOutRequest} from '../../shared/models/mangopay/payOutRequest.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';
import {UserService} from '../../shared/services/user.service';
import {resetUpdateUserState, updateUserMerge} from '../../auth/store/auth.actions';
import WalletData = MangoPay.wallet.WalletData;
import BankAccount = MangoPay.models.BankAccount;
import AddressData = MangoPay.address.AddressData;
import KycDocumentData = MangoPay.kycDocument.KycDocumentData;
import UserNaturalData = MangoPay.user.UserNaturalData;
import PayOutData = MangoPay.PayOut.PayOutData;

const deepEqual = require('deep-equal');

@Component({
  selector: 'app-payment-account',
  templateUrl: './payment-account.component.html',
  styleUrls: ['./payment-account.component.scss'],
})
export class PaymentAccountComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  @Input() embedded = false;

  bankAccountTypes: IdName[] = [{name: 'IBAN', id: MangopayBankAccountType.IBAN}, {name: 'US', id: MangopayBankAccountType.US},
    {name: 'CA', id: MangopayBankAccountType.CA}, {name: 'GB', id: MangopayBankAccountType.GB}, {name: $localize`Other`, id: MangopayBankAccountType.OTHER}];

  documentTypes: IdName[] = [{name: $localize`Identity proof`, id: MangopayDocumentType.IdentityProof}, {
    name: $localize`Address proof`,
    id: MangopayDocumentType.AddressProof,
  }];

  /**
   * Wallet selected in the payout form.
   */
  selectedPayoutWallet?: WalletData;
  /**
   * Bank account selected in the payout form.
   */
  selectedPayoutBankAccount?: BankAccount;

  isMasterDataComplete?: boolean;
  amountMaxLength = 8;
  amountMinValue = 1;
  amountMaxValue = 99999999;
  ibanMaxLength = 42;
  bicMaxLength = 11;
  abaMaxLength = 9;
  branchCodeMaxLength = 5;
  institutionNumberMaxLength = 3;
  accountNumberUsMaxLength = 20;
  accountNumberCaMaxLength = 35;
  accountNumberGbMaxLength = 8;
  accountNumberOtherMaxLength = 35;
  sortCodeMaxLength = 6;
  bankNameMaxLength = 50;
  ownerNameMaxLength = 100;
  addressLine1MaxLength = 100;
  addressLine2MaxLength = 100;
  cityMaxLength = 100;
  regionMaxLength = 100;
  postalCodeMaxLength = 20;
  countries: Country[] = [];
  currencies: Currency[] = [];
  remainingWalletCurrencies: Currency[] = [];
  currenciesById = new Map<string, Currency>();
  walletForm!: FormGroup;
  payOutForm!: FormGroup;
  bankAccountForm!: FormGroup;
  public iban!: FormControl;
  kycForm!: FormGroup;
  showAddWalletCard = false;
  showAddBankAccountCard = false;
  showAddKycDocumentCard = false;
  showPayoutCard = false;
  selectedFile = null;
  minSize?: number = MANGOPAY_KYC_DOCUMENT_DEFAULT_MIN_BYTES;
  maxSize?: number = MANGOPAY_KYC_DOCUMENT_DEFAULT_MAX_BYTES;


  numberFormatLocale = Locale.numberFormatLocale();

  private readonly spinnerKeyCreatePayOut = 'createPayOut';
  private readonly spinnerKeyCreateWallet = 'createWallet';
  private readonly spinnerKeyCreateBankAccount = 'createBankAccount';
  private readonly spinnerKeyCreateValidationDocument = 'CreateValidationDocument';
  private readonly spinnerKeyDeactivateBankAccount = 'deactivateBankAccount';
  private readonly spinnerKeyLoadWallets = 'loadingWallets';
  private readonly spinnerKeyLoadValidationDocuments = 'loadValidationDocuments';
  private readonly spinnerKeyLoadBankAccounts = 'loadBankAccounts';

  constructor(
      protected store: Store<AppState>,
      private router: Router,
      private titleService: TitleService,
      private cdRef: ChangeDetectorRef,
      private functionsService: FunctionsService,
      public currencyService: CurrencyService,
      private formBuilder: FormBuilder,
      private userService: UserService,
      public mangopayService: MangopayService,
      public sharedService: SharedService,
      public countryService: CountryService,
      private utilService: UtilService) {
    super(store);
  }

  public get mangopayBankAccountType(): typeof MangopayBankAccountType {
    return MangopayBankAccountType;
  }

  public get mangopayDocumentType(): typeof MangopayDocumentType {
    return MangopayDocumentType;
  }

  getCurrencySuffixOrPrefix() {
    return Locale.currencySuffixOrPrefix();
  }


  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`My payment account`);
    this.user$.subscribe(user => {
      this.onUserLoaded(user);
    });
    this.walletForm = this.createWalletForm();
    this.payOutForm = this.createPayoutForm();
    this.bankAccountForm = this.createBankAccountForm();
    this.kycForm = this.createKycForm();


    this.currencyService.getCurrencies().then(wrapper => {
      if (wrapper.data) {
        this.currencies = wrapper.data;
        this.currencies.forEach(currency => this.currenciesById.set(currency.id, currency));
        this.determineRemainingWalletCurrencies();
      }
    });


    this.countries = this.countryService.countries;
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  onAddWalletClick() {
    this.clearAlerts();
    if (!this.user) {
      this.addError($localize`You need to login, before you can add a wallet.`);
      return;
    }
    this.showAddWalletCard = !this.showAddWalletCard;
  }

  onCreateWalletClick() {
    this.clearAlerts();
    if (!this.user) {
      this.addError($localize`You need to login, before you can add a wallet.`);
      return;
    }
    if (!this.user.connectedPaymentAccount?.userData) {
      this.addError($localize`You haven't got a payment account yet.`);
      return;
    }
    this.createMangopayWalletFromAddWalletForm(this.user.connectedPaymentAccount.userData);
  }

  onCreateKycDocumentClick(files: File[]) {
    this.clearAlerts();
    if (!this.user) {
      this.addError($localize`You need to login, before you can add a validation document.`);
      return;
    }
    if (!this.user.connectedPaymentAccount?.userData) {
      this.addError($localize`You haven't got a payment account yet.`);
      return;
    }
    if (!this.kycForm.value.documentType) {
      this.addError($localize`Please select a document type.`);
      return;
    }
    this.createMangopayKycDocument(this.user.connectedPaymentAccount.userData, files);
  }

  onAddBankAccountClick() {
    this.clearAlerts();
    if (!this.user) {
      this.addError($localize`You need to login, before you can connect a bank account.`);
      return;
    }
    this.showAddBankAccountCard = true;
    this.utilService.scrollToId('add-bank-account-form');
  }

  onAddKycDocumentClick() {
    this.clearAlerts();
    if (!this.user) {
      this.addError($localize`You need to login, before you can upload a validation document.`);
      return;
    }
    this.showAddKycDocumentCard = true;
    this.utilService.scrollToId('add-kyc-document-form');
  }

  onCreateBankAccountClick() {
    this.clearAlerts();
    if (!this.user) {
      this.addError($localize`You need to login, before you can connect a bank account.`);
      return;
    }
    if (!this.user.connectedPaymentAccount?.userData) {
      this.addError($localize`You haven't got a payment account yet.`);
      return;
    }
    this.createMangopayBankAccount(this.user.connectedPaymentAccount.userData);
  }

  /**
   * Creates a request to be sent to the backend. The backend then creates a wallet on Mangopay.
   * @param userData Mangopay user
   */
  createMangopayWalletFromAddWalletForm(userData: MangoPay.user.UserNaturalData) {

    this.addLoadingSpinnerMessage(this.spinnerKeyCreateWallet, $localize`Creating your wallet...`);

    const currencyIdentifier = this.currenciesById.get(this.walletForm.value.currencyId)?.identifier;

    this.createMangoPayWalletForCurrency(userData, currencyIdentifier);
  }

  /**
   * Creates a request to be sent to the backend. The backend then creates a bank account on Mangopay.
   * @param userData Mangopay user
   */
  createMangopayBankAccount(userData: MangoPay.user.UserNaturalData) {

    this.addLoadingSpinnerMessage(this.spinnerKeyCreateBankAccount, $localize`Connecting your bank account...`);

    const ownerAddress: AddressData = {
      AddressLine1: this.bankAccountForm.value.addressLine1,
      AddressLine2: this.bankAccountForm.value.addressLine2,
      PostalCode: this.bankAccountForm.value.postalCode,
      City: this.bankAccountForm.value.city,
      Region: this.bankAccountForm.value.region,
      Country: this.bankAccountForm.value.countryCode,
    };

    const request: BankAccountCreationRequest = {
      mangopayUserId: userData.Id,
      currencyIdentifier: this.currenciesById.get(this.bankAccountForm.value.currencyId)?.identifier,
      bankAccountType: this.bankAccountForm.value.bankAccountType,
      ownerName: this.bankAccountForm.value.ownerName,
      ownerAddress: ownerAddress,
    };

    switch (request.bankAccountType) {
      case MangopayBankAccountType.IBAN: {
        request.bic = this.bankAccountForm.value.bic;
        request.iban = this.bankAccountForm.value.iban;
        break;
      }
      case 'US': {
        request.accountNumber = this.bankAccountForm.value.accountNumberUs;
        request.aba = this.bankAccountForm.value.aba;
        break;
      }
      case MangopayBankAccountType.CA: {
        request.accountNumber = this.bankAccountForm.value.accountNumberCa;
        request.branchCode = this.bankAccountForm.value.branchCode;
        request.institutionNumber = this.bankAccountForm.value.institutionNumber;
        request.bankName = this.bankAccountForm.value.bankName;
        break;
      }
      case MangopayBankAccountType.GB: {
        request.accountNumber = this.bankAccountForm.value.accountNumberGb;
        request.sortCode = this.bankAccountForm.value.sortCode;
        break;
      }
      case MangopayBankAccountType.OTHER: {
        request.accountNumber = this.bankAccountForm.value.accountNumberOther;
        request.bic = this.bankAccountForm.value.bic;
        break;
      }
    }

    this.functionsService.createMangopayBankAccount(request, (bankAccount: BankAccount) => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyCreateBankAccount);
      if (bankAccount) {
        this.saveMangopayBankAccount(bankAccount);
        this.showAddBankAccountCard = false;
        this.bankAccountForm.reset();
      }
    }, errorMessage => {
      this.addError($localize`Error connecting your bank account\: ${errorMessage}`);
      this.removeLoadingSpinnerMessage(this.spinnerKeyCreateBankAccount);
    });
  }

  /**
   * Creates a request to be sent to the backend. The backend then creates a KYC Document on Mangopay.
   * @param userData Mangopay user
   * @param userData KYC files to be sent to Mangopay
   */
  createMangopayKycDocument(userData: MangoPay.user.UserNaturalData, files: File[]) {
    this.addLoadingSpinnerMessage(this.spinnerKeyCreateValidationDocument, $localize`Creating your Validation document...`);

    Util.convertFilesToBase64(files, [], 0, true, base64 => {
      const request: KycDocumentCreationRequest = {
        mangopayUserId: userData.Id,
        type: this.kycForm.value.documentType,
        files: base64,
      };
      this.functionsService.createMangopayKycDocument(request, (kycDocument: KycDocumentData) => {
        this.removeLoadingSpinnerMessage(this.spinnerKeyCreateValidationDocument);
        if (kycDocument) {
          this.saveKycDocument(kycDocument);
          this.showAddKycDocumentCard = false;
          this.kycForm.reset();
        }
      }, errorMessage => {
        this.addError($localize`Error creating your validation document\: ${errorMessage}`);
        this.removeLoadingSpinnerMessage(this.spinnerKeyCreateValidationDocument);
      });
    }, (error, incompleteBase64) => {
      this.addError($localize`Error encoding your files\: ${error}`);
    });

  }

  onMasterDataSave(user: User) {
    this.onUserLoaded(user);
  }

  getAddressData(address: any) {
    return address as AddressData;
  }

  onDeactivateBankAccount(bankAccountId: string): void {
    this.clearAlerts();
    if (!this.user?.connectedPaymentAccount?.userData?.Id) {
      this.addError($localize`You need to login, before you can deactivate a bank account.`);
      return;
    }
    const title = $localize`Are you sure you want to deactivate this bank account?`;
    this.utilService.showConfirmDialog(title, CANNOT_BE_UNDONE, this.deactivateBankAccount.bind(this), [bankAccountId], undefined, undefined, undefined, 'no');
  }

  deactivateBankAccount(bankAccountId: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyDeactivateBankAccount, $localize`Deactivating your bank account...`);
    const req: DeactivateBankAccountRequest = {
      bankAccountId: bankAccountId,
      mangopayUserId: this.user!.connectedPaymentAccount!.userData!.Id,
    };
    this.functionsService.deactivateMangopayBankAccount(req, (success: boolean) => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyDeactivateBankAccount);
      if (success) {
        this.refreshBankAccounts();
      }
    }, errorMessage => {
      this.addError($localize`Error deactivating your bank account\: ${errorMessage}`);
      this.removeLoadingSpinnerMessage(this.spinnerKeyDeactivateBankAccount);
    });
  }

  countrySelected() {
    this.bankAccountForm.get('region')?.updateValueAndValidity();
  }

  getAccountTypeName(Type: 'IBAN' | 'GB' | 'US' | 'CA' | 'OTHER' | ('US' & 'US') | ('IBAN' & 'IBAN') | ('GB' & 'GB') | ('CA' & 'CA') | ('OTHER' & 'OTHER')): string {
    for (let type of this.bankAccountTypes) {
      if (type.id === Type)
        return type.name;
    }
    return this.getAccountTypeName(MangopayBankAccountType.OTHER);
  }

  onDocumentTypeSelected() {
    switch (this.kycForm.value.documentType) {
      case MangopayDocumentType.IdentityProof: {
        this.minSize = MANGOPAY_KYC_DOCUMENT_IDENTITY_PROOF_MIN_BYTES;
        break;
      }
      case MangopayDocumentType.AddressProof: {
        this.minSize = MANGOPAY_KYC_DOCUMENT_DEFAULT_MIN_BYTES;
        break;
      }
    }
  }

  getDocumentTypeName(Type: 'IDENTITY_PROOF' | 'REGISTRATION_PROOF' | 'ARTICLES_OF_ASSOCIATION' | 'SHAREHOLDER_DECLARATION' | 'ADDRESS_PROOF'): string {
    for (let type of this.documentTypes) {
      if (type.id === Type)
        return type.name;
    }
    return $localize`Document`;
  }

  onShowPayOutClick(wallet: MangoPay.wallet.WalletData) {
    this.onWalletSelected(wallet.Id);
  }

  /**
   * Called, when a wallet was selected - either by clicking on the wallet or using the select field of the payout form
   * @param walletId Mangopay wallet ID of the selected wallet
   */
  onWalletSelected(walletId: string) {
    this.utilService.scrollToId('payout-form');
    this.showPayoutCard = true;
    const wallet = this.getWallet(walletId);
    this.selectedPayoutWallet = wallet;

    let amount = 0;
    if (wallet?.Balance?.Amount)
      amount = convertAmount(wallet.Balance.Amount, wallet.Balance.Currency, true);
    this.amountMaxValue = amount;
    this.payOutForm.patchValue({amount, wallet: walletId});

    // Auto-select matching bank account for the given currency
    if (wallet?.Balance?.Currency && (!this.selectedPayoutBankAccount || this.selectedPayoutBankAccount.Tag !== wallet.Balance.Currency)) {
      // The first found bank account with the wallet currency is selected
      const matchingBankAccount = this.user?.connectedPaymentAccount?.bankAccounts?.find(bankAccount => bankAccount.Tag === wallet.Balance.Currency);

      this.selectedPayoutBankAccount = matchingBankAccount;
      this.payOutForm.patchValue({bankAccount: matchingBankAccount?.Id});
    }
    this.showPayoutCard = false;
    setTimeout(args => {
      this.showPayoutCard = true;
    }, 10);
  }

  /**
   * Called, when a bank account was selected - either by clicking on the bankAccountId or using the select field of the payout form
   * @param bankAccountId Mangopay bankAccountId ID of the selected bankAccountId
   */
  onBankAccountSelected(bankAccountId: string) {
    this.showPayoutCard = true;
    this.utilService.scrollToId('payout-form');
    const bankAccount = this.getBankAccount(bankAccountId);
    this.selectedPayoutBankAccount = bankAccount;
    this.payOutForm.patchValue({bankAccount: bankAccountId});

    // Auto-select matching wallet for the given currency
    if (bankAccount?.Tag && (!this.selectedPayoutWallet || this.selectedPayoutWallet?.Balance.Currency !== bankAccount.Tag)) {
      // The first found wallet with the bank account currency is selected
      const matchingWallet = this.user?.connectedPaymentAccount?.wallets?.find(wallet => wallet.Balance.Currency === bankAccount.Tag);
      if (matchingWallet) {
        this.onWalletSelected(matchingWallet.Id);
      }
    }
    this.reloadPayOutForm();
  }

  onPayOutClick() {
    this.clearAlerts();
    if (!this.user) {
      this.addError($localize`You need to login, before you can pay out money.`);
      return;
    }
    if (!this.user.connectedPaymentAccount?.userData) {
      this.addError($localize`You haven't got a payment account yet.`);
      return;
    }
    const bankAccountId = this.payOutForm.value.bankAccount;
    if (!bankAccountId) {
      this.addError($localize`You haven't selected a bank account for the payout.`);
      return;
    }
    const bankAccount = this.getBankAccount(bankAccountId);
    if (!bankAccount) {
      this.addError($localize`We could not find the selected bank account.`);
      return;
    }

    const walletId = this.payOutForm.value.wallet;
    if (!walletId) {
      this.addError($localize`You haven't selected a wallet for the payout.`);
      return;
    }
    const wallet = this.getWallet(walletId);
    if (!wallet) {
      this.addError($localize`We could not find the selected wallet.`);
      return;
    }
    const amount = this.payOutForm.value.amount;
    if (amount < 1) {
      this.addError($localize`The minimum payout amount is 1 ${wallet.Currency}`);
      return;
    }
    if (amount > wallet.Balance.Amount) {
      this.addError($localize`You don't have enough balance on this wallet.`);
      return;
    }

    this.createPayOut(this.user.connectedPaymentAccount.userData.Id, wallet.Id, bankAccount.Id, amount, wallet.Currency);
  }

  createPayOut(mangopayUserId: string, walletId: string, bankAccountId: string, amount: number, currencyIdentifier: CurrencyISO) {

    this.addLoadingSpinnerMessage(this.spinnerKeyCreatePayOut, $localize`Initiating payout...`);
    const request: PayOutRequest = {mangopayUserId, currencyIdentifier, bankAccountId, walletId, amount};
    this.functionsService.createMangopayPayOut(request, (payOutData: PayOutData) => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyCreatePayOut);
      if (payOutData) {
        this.addSuccess($localize`Successfully initiated your payout. You should receive your money in a few days`);
        // this.savePayOutData(payOutData);
        this.showPayoutCard = false;
        this.payOutForm.reset();
        this.refreshWallets();
      }
    }, errorMessage => {
      this.addError($localize`Error paying out your money: ${errorMessage}`);
      this.removeLoadingSpinnerMessage(this.spinnerKeyCreatePayOut);
    });
  }

  private getWallet(walletId: string) {
    return this.user?.connectedPaymentAccount?.wallets?.find(wallet => wallet.Id === walletId);
  }

  private getBankAccount(bankAccountId: string) {
    return this.user?.connectedPaymentAccount?.bankAccounts?.find(bankAccount => bankAccount.Id === bankAccountId);
  }

  /**
   * Reloads the payout form to update its validators.
   */
  private reloadPayOutForm() {
    this.showPayoutCard = false;
    setTimeout(args => {
      this.showPayoutCard = true;
    }, 1);
  }

  private createMangoPayWalletForCurrency(userData: MangoPay.user.UserNaturalData, currencyIdentifier: string | undefined) {
    const request: WalletCreationRequest = {
      mangopayUserId: userData.Id,
      currencyIdentifier: currencyIdentifier,
    };
    this.functionsService.createMangopayWallet(request, (wallet: WalletData) => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyCreateWallet);
      if (wallet) {
        this.saveMangopayWallet(wallet);
        this.showAddWalletCard = false;
        this.walletForm.reset();
      }
    }, errorMessage => {
      this.addError($localize`Error creating your wallet\: ${errorMessage}`);
      this.removeLoadingSpinnerMessage(this.spinnerKeyCreateWallet);
    });
  }

  private createWalletForm(): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
      currencyId: [null, Validators.required],
    });

  }

  private createPayoutForm(): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
      amount: [0, Validators.required],
      wallet: [null, Validators.required],
      bankAccount: [null, Validators.required],
    });

  }

  private createBankAccountForm(): FormGroup {
    const builder = this.formBuilder;

    this.iban = new FormControl(
        null,
        [
          Validators.required,
          ValidatorService.validateIban,
        ],
    );
    return builder.group({
      bankAccountType: [environment.defaultBankAccountType, Validators.required],
      iban: this.iban,
      bic: [null, [Validators.required, Validators.pattern('[0-9A-Za-z]{1,11}')]],
      aba: [null, [Validators.required, Validators.pattern('^[0-9]{9}$')]],
      branchCode: [null, [Validators.required, Validators.pattern('[0-9]{5}')]],
      institutionNumber: [null, [Validators.required, Validators.pattern('[0-9]{3}')]],
      bankName: [null, [Validators.required, Validators.pattern('[0-9A-Za-z ]{1,50}')]],
      accountNumberUs: [null, [Validators.required, Validators.pattern('[0-9]*')]],
      accountNumberCa: [null, [Validators.required, Validators.pattern('[0-9]{7,35}')]],
      accountNumberOther: [null, [Validators.required, Validators.pattern('[0-9]{7,35}')]],
      accountNumberGb: [null, [Validators.required, Validators.pattern('[0-9]{8}')]],
      sortCode: [null, [Validators.required, Validators.pattern('[0-9]{6}')]],
      currencyId: [null, Validators.required],
      ownerName: [null, Validators.required],
      addressLine1: [null, Validators.required],
      addressLine2: [null],
      city: [null, Validators.required],
      region: [null, regionValidator()],
      postalCode: [null, Validators.required],
      countryCode: [null, Validators.required],
    }, {validators: bankAccountFormValidator});

  }

  private createKycForm(): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
      documentType: [null, Validators.required],
    });

  }

  private onUserLoaded(user: User | undefined) {
    if (!user?.uid)
      return;
    user = {...user};
    this.isMasterDataComplete = !(!user.address || !user.birthdate || !user.firstName || !user.lastName || !user.nationalityCountryCode);
    this.refreshWallets();
    this.refreshBankAccounts();
    this.refreshKycDocuments();
    this.bankAccountForm.patchValue({
      ownerName: `${user.firstName} ${user.lastName}`,
      addressLine1: `${user.address?.street} ${user.address?.houseNumber}`,
      addressLine2: user.address?.street2,
      city: user.address?.city,
      region: user.address?.state,
      postalCode: user.address?.zipCode,
      countryCode: user.address?.countryCode,
    });
  }

  private refreshWallets() {
    if (this.user?.connectedPaymentAccount?.userData?.Id) {

      this.addLoadingSpinnerMessage(this.spinnerKeyLoadWallets, $localize`Loading your wallets...`);
      const req: WalletFetchingRequest = {
        mangopayUserId: this.user?.connectedPaymentAccount?.userData?.Id,
        page: 1,
        per_page: 100,
      };
      let errorIntroMessage = $localize`Error refreshing your wallets.`;
      this.functionsService.fetchMangopayWallets(req, wallets => {
        this.determineRemainingWalletCurrencies();
        if (this.user?.connectedPaymentAccount) {
          wallets.sort((a, b) => b.Balance.Amount - a.Balance.Amount);
          const connectedPaymentAccount: ConnectedPaymentAccount = {...this.user.connectedPaymentAccount, wallets};
          const userBeforeRefreshing = {...this.user};
          this.user = {...this.user, connectedPaymentAccount};
          this.autoCreateMissingWallets();
          this.saveConnectedPaymentAccountInToUserIfSomethingHasChanged(connectedPaymentAccount, errorIntroMessage, this.spinnerKeyLoadWallets, userBeforeRefreshing);
        }
      }, errorMessage => {
        this.addError(errorIntroMessage + ' ' + errorMessage);
        this.removeLoadingSpinnerMessage(this.spinnerKeyLoadWallets);
      });
    }
  }

  private listenForResult(errorIntroMessage: string, spinnerKey: string) {
    this.updateUserActionResult$.pipe(take(1)).subscribe(result => {
      this.removeLoadingSpinnerMessage(spinnerKey);
      this.store.dispatch(resetUpdateUserState());
      if (result.errorMessage)
        this.addError(errorIntroMessage + ' ' + result.errorMessage);
    });
  }

  private refreshKycDocuments() {
    if (this.user?.connectedPaymentAccount?.userData?.Id) {

      this.addLoadingSpinnerMessage(this.spinnerKeyLoadValidationDocuments, $localize`Loading your validation documents...`);
      const req: KycDocumentFetchingRequest = {
        mangopayUserId: this.user?.connectedPaymentAccount?.userData?.Id,
        page: 1,
        per_page: 100,
      };
      const errorIntroMessage = $localize`Error refreshing your validation documents.`;
      this.functionsService.fetchMangopayKycDocuments(req, kycDocuments => {
        if (this.user?.connectedPaymentAccount) {
          const userBeforeRefreshing = {...this.user, connectedPaymentAccount: {...this.user.connectedPaymentAccount}};
          kycDocuments.sort((a, b) => a.CreationDate - b.CreationDate);
          const connectedPaymentAccount: ConnectedPaymentAccount | undefined = this.updateProofsOfIdentityAndAddress(this.user, kycDocuments);
          this.saveConnectedPaymentAccountInToUserIfSomethingHasChanged(connectedPaymentAccount, errorIntroMessage, this.spinnerKeyLoadValidationDocuments);
        }
      }, errorMessage => {
        this.addError(errorIntroMessage + ' ' + errorMessage);
        this.removeLoadingSpinnerMessage(this.spinnerKeyLoadValidationDocuments);
      });
    }
  }

  private refreshBankAccounts() {
    if (this.user?.connectedPaymentAccount?.userData?.Id) {
      this.addLoadingSpinnerMessage(this.spinnerKeyLoadBankAccounts, $localize`Loading your bank accounts...`);
      const req: BankAccountFetchingRequest = {
        mangopayUserId: this.user?.connectedPaymentAccount?.userData?.Id,
        page: 1,
        per_page: 100,
        active: true,
      };
      let errorIntroMessage = $localize`Error refreshing your bank accounts.`;
      this.functionsService.fetchMangopayBankAccounts(req, bankAccounts => {
        this.removeLoadingSpinnerMessage(this.spinnerKeyLoadBankAccounts);
        if (this.user?.connectedPaymentAccount) {
          const connectedPaymentAccount: ConnectedPaymentAccount = {...this.user.connectedPaymentAccount, bankAccounts};
          this.saveConnectedPaymentAccountInToUserIfSomethingHasChanged(connectedPaymentAccount, errorIntroMessage, this.spinnerKeyLoadBankAccounts);
        }
      }, errorMessage => {
        this.removeLoadingSpinnerMessage(this.spinnerKeyLoadBankAccounts);
        this.addError(errorIntroMessage + ' ' + errorMessage);
      });
    }
  }

  private saveConnectedPaymentAccountInToUserIfSomethingHasChanged(connectedPaymentAccount: ConnectedPaymentAccount | undefined, errorIntroMessage: string, spinnerKey: string, userBeforeRefreshing?: User) {
    if (!this.user?.uid) {
      this.addError($localize`You are not logged in.`);
      return;
    }
    // If no comparison user is given, create it here
    if (!userBeforeRefreshing)
      userBeforeRefreshing = {...this.user};
    this.user = {...this.user, connectedPaymentAccount};
    if (!deepEqual(userBeforeRefreshing, this.user)) {
      const userUpdate: User = {uid: this.user.uid, connectedPaymentAccount};
      this.store.dispatch(updateUserMerge({userUpdate}));
      this.listenForResult(errorIntroMessage, spinnerKey);
    } else
      this.removeLoadingSpinnerMessage(spinnerKey);
  }

  private saveMangopayWallet(walletData: WalletData) {
    if (!this.user)
      return;

    let connectedPaymentAccount: ConnectedPaymentAccount | undefined = this.user.connectedPaymentAccount;

    if (!this.user?.connectedPaymentAccount)
      connectedPaymentAccount = {wallets: [walletData]};
    else {
      let wallets: WalletData[] = this.user.connectedPaymentAccount.wallets ? [...this.user.connectedPaymentAccount.wallets] : [];
      wallets.push(walletData);
      connectedPaymentAccount = {...this.user.connectedPaymentAccount, wallets};
    }
    let successMessage = $localize`Successfully created your ${walletData.Currency} wallet.`;
    let errorIntroMessage = $localize`Error connecting your wallet to your Blitzshare account.`;
    this.saveConnectedPaymentAccountIntoUser(connectedPaymentAccount, successMessage, errorIntroMessage);
    this.determineRemainingWalletCurrencies();
  }

  private saveKycDocument(kycDocument: KycDocumentData) {
    if (!this.user)
      return;

    let connectedPaymentAccount: ConnectedPaymentAccount | undefined = this.user.connectedPaymentAccount;

    if (!this.user?.connectedPaymentAccount)
      connectedPaymentAccount = {kycDocuments: [kycDocument]};
    else {
      let kycDocuments: KycDocumentData[] = this.user.connectedPaymentAccount.kycDocuments ? [...this.user.connectedPaymentAccount.kycDocuments] : [];
      kycDocuments.push(kycDocument);
      connectedPaymentAccount = {...this.user.connectedPaymentAccount, kycDocuments};
    }
    let successMessage = $localize`Your validation document was uploaded successfully. We will send you an email, when the validation is complete.`;
    let errorIntroMessage = $localize`Error linking your validation document with your Blitzshare account.`;
    this.saveConnectedPaymentAccountIntoUser(connectedPaymentAccount, successMessage, errorIntroMessage);

  }

  private saveConnectedPaymentAccountIntoUser(connectedPaymentAccount: ConnectedPaymentAccount, successMessage: string, errorIntroMessage: string) {
    if (!this.user?.uid) {
      this.addError($localize`You are not logged in.`);
      return;
    }
    this.user = {...this.user, connectedPaymentAccount};
    const userUpdate: User = {uid: this.user.uid, connectedPaymentAccount};
    this.store.dispatch(updateUserMerge({userUpdate}));

    this.updateUserActionResult$.pipe(take(1)).subscribe(result => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyLoadBankAccounts);
      if (result.success)
        this.addSuccess(successMessage);
      if (result.errorMessage)
        this.addError(errorIntroMessage + ' ' + result.errorMessage);
      this.store.dispatch(resetUpdateUserState());
    });
  }

  private saveMangopayBankAccount(bankAccount: BankAccount) {
    if (!this.user)
      return;

    let connectedPaymentAccount: ConnectedPaymentAccount | undefined = this.user.connectedPaymentAccount;

    if (!this.user?.connectedPaymentAccount)
      connectedPaymentAccount = {bankAccounts: [bankAccount]};
    else {
      let bankAccounts: BankAccount[] = this.user.connectedPaymentAccount.bankAccounts ? [...this.user.connectedPaymentAccount.bankAccounts] : [];
      bankAccounts.push(bankAccount);
      connectedPaymentAccount = {...this.user.connectedPaymentAccount, bankAccounts};
    }

    let successMessage = $localize`Successfully created your bank account.`;
    let errorIntroMessage = $localize`Error connecting your bank account to your Blitzshare account.`;
    this.saveConnectedPaymentAccountIntoUser(connectedPaymentAccount, successMessage, errorIntroMessage);
  }

  private determineRemainingWalletCurrencies() {
    this.remainingWalletCurrencies = [...this.currencies];
    if (this.currencies && this.user?.connectedPaymentAccount?.wallets) {
      this.user.connectedPaymentAccount.wallets.forEach(wallet => {
        const index: number = this.findCurrencyIndex(this.remainingWalletCurrencies, wallet.Currency);
        if (index > -1)
          this.remainingWalletCurrencies.splice(index, 1);
      });
    }

    if (this.findCurrencyIndex(this.remainingWalletCurrencies, Locale.defaultCurrencyId()) > -1)
      this.walletForm.patchValue({currencyId: Locale.defaultCurrencyId()});
    else
      this.walletForm.patchValue({currencyId: null});
  }

  /**
   * Finds the index of the given currency inside the given array.
   * @param currencies currencies array
   * @param currency given currency
   * @return index of the currency or -1, if not contained.
   */
  private findCurrencyIndex(currencies: Currency[], currency: CurrencyISO | string) {
    for (let i = 0; i < currencies.length; i++) {
      const cur = currencies[i];
      if (cur.identifier === currency)
        return i;
    }
    return -1;
  }

  private updateProofsOfIdentityAndAddress(firestoreUser: User, kycDocuments: MangoPay.kycDocument.KycDocumentData[]): ConnectedPaymentAccount | undefined {
    let proofOfIdentity: null | string = null;
    let proofOfAddress: null | string = null;
    kycDocuments.forEach(doc => {
      if (doc.Type === 'IDENTITY_PROOF' && doc.Status === 'VALIDATED' && proofOfIdentity === null)
        proofOfIdentity = doc.Id;
      if (doc.Type === 'ADDRESS_PROOF' && doc.Status === 'VALIDATED' && proofOfAddress === null)
        proofOfAddress = doc.Id;
    });

    if (firestoreUser?.connectedPaymentAccount?.userData) {
      const userData: UserNaturalData = {...firestoreUser.connectedPaymentAccount.userData, ProofOfIdentity: proofOfIdentity, ProofOfAddress: proofOfAddress};
      const connectedPaymentAccount: ConnectedPaymentAccount = {...firestoreUser.connectedPaymentAccount, userData, kycDocuments};
      return connectedPaymentAccount;
    }
    return;
  }

  /**
   * Automatically create missing wallets.
   */
  private autoCreateMissingWallets() {
    if (!this.user) {
      return;
    }
    this.remainingWalletCurrencies.forEach(cur => {
      if (!this.user?.connectedPaymentAccount?.userData)
        return;
      this.createMangoPayWalletForCurrency(this.user.connectedPaymentAccount.userData, cur.identifier);
    });

  }
}

/**
 * Validates, that all visible (=relevant) parts of the form are valid.
 */
export const bankAccountFormValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const bankAccountTypeValue = control.get('bankAccountType')?.value;
  const bankAccountType = control.get('bankAccountType')?.valid;
  const iban = control.get('iban')?.valid;
  const bic = control.get('bic')?.valid;
  const aba = control.get('aba')?.valid;
  const branchCode = control.get('branchCode')?.valid;
  const institutionNumber = control.get('institutionNumber')?.valid;
  const bankName = control.get('bankName')?.valid;
  const accountNumberUs = control.get('accountNumberUs')?.valid;
  const accountNumberCa = control.get('accountNumberCa')?.valid;
  const accountNumberOther = control.get('accountNumberOther')?.valid;
  const accountNumberGb = control.get('accountNumberGb')?.valid;
  const sortCode = control.get('sortCode')?.valid;
  const currencyId = control.get('currencyId')?.valid;
  const ownerName = control.get('ownerName')?.valid;
  const addressLine1 = control.get('addressLine1')?.valid;
  const city = control.get('city')?.valid;
  const regionValue = control.get('region')?.value;
  const postalCode = control.get('postalCode')?.valid;
  const countryCodeValue = control.get('countryCode')?.value;

  const bankAccountTypeMissing = !bankAccountType;
  const regionIsMissing = ((countryCodeValue === 'US' || countryCodeValue === 'CA' || countryCodeValue === 'MX') && !regionValue);
  const incompleteDataIban = (bankAccountTypeValue === MangopayBankAccountType.IBAN && (!iban || !bic));
  const incompleteDataUs = (bankAccountTypeValue === MangopayBankAccountType.US && (!aba || !accountNumberUs));
  const incompleteDataCa = (bankAccountTypeValue === MangopayBankAccountType.CA && (!branchCode || !accountNumberCa || !institutionNumber || !bankName));
  const incompleteDataGb = (bankAccountTypeValue === MangopayBankAccountType.GB && (!sortCode || !accountNumberGb));
  const incompleteDataOther = (bankAccountTypeValue === MangopayBankAccountType.OTHER && (!bic || !accountNumberOther));
  const addressIncomplete = (!ownerName || !addressLine1 || !city || !postalCode || !countryCodeValue);
  const currencyMissing = !currencyId;

  return (regionIsMissing || bankAccountTypeMissing || incompleteDataIban || incompleteDataUs || incompleteDataCa || incompleteDataGb || incompleteDataOther || addressIncomplete || currencyMissing) ? {
    somethingWrong: true,
    bankAccountTypeMissing,
    regionIsMissing,
    incompleteDataIban,
    incompleteDataUs,
    incompleteDataCa,
    incompleteDataGb,
    incompleteDataOther,
    addressIncomplete,
    currencyMissing,
  } : null;
};

/**
 * Validates, that the region is filled, if the country is US, CA or MX.
 */
export function regionValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const countryCodeValue = control.parent?.value.countryCode;
    const regionValue = control.value;
    const regionIsMissing = (countryCodeValue === 'US' || countryCodeValue === 'CA' || countryCodeValue === 'MX') && !regionValue;
    return regionIsMissing ? {regionIsMissing} : null;
  };
}
