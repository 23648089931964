<!-- Breadcrumb-->
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <a class="breadcrumb-item" i18n="Account" routerLink="../..">Account</a>
    <a class="breadcrumb-item" i18n="Account" routerLink="..">Messages</a>
    <li class="breadcrumb-item">
      <span *ngIf="receiver?.displayName && listing?.name" i18n="messages headline">Conversation with <i>{{receiver?.displayName}}</i>
        about <a [routerLink]="['/browse/listing',listingUid]">{{listing?.name}}</a></span>
      <span *ngIf="receiver?.displayName && !listing?.name" i18n="messages headline">Conversation with <i>{{receiver?.displayName}}</i></span>
    </li>
  </ol>
</nav>

<div *ngIf="user" class="messaging-container p-2">
  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <div #messagesRef #scrollMe [scrollTop]="scrollMe.scrollHeight" class="messages">
    <a (click)="loadPreviousMessages()" *ngIf="thereArePreviousMessages" class="load-more-messages-link mb-3" i18n="load previous messages">Load previous
      messages</a>
    <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true"></app-loading-spinner>
    <div *ngFor="let message of messages">
      <div *ngIf="message.senderUid !== user.uid;
                  then incomingMessage;
                  else outgoingMessage"></div>
      <ng-template #incomingMessage>
        <div class="user-img-container">
          <app-user-thumb [circle]="true" [size]="42" [userPublic]="receiver" cssClass="mr-2"></app-user-thumb>
        </div>
        <div class="received-message message mb-2">{{message.message}}
          <p *ngIf="message && message.date" [title]="message.date.toDate() | moment:'LLLL'"
             class="date date-incoming">{{utilService.createFromNowString(message.date.toDate())}}</p>
        </div>
      </ng-template>
      <ng-template #outgoingMessage>
        <div class="outgoing-message mb-2">
          <div class="sent-message message bg-primary">{{message.message}}
            <p *ngIf="message && message.date" [title]="message.date.toDate() | moment:'LLLL'"
               class="date date-outgoing">{{utilService.createFromNowString(message.date.toDate())}}</p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="type-message-container">
    <form (ngSubmit)="sendMessage()">
      <input [(ngModel)]="newMessage" class="message-input" i18n-placeholder name="newMessage" placeholder="Type a message" type="text"/>
      <button aria-label="Send message" class="send-button" color="primary" i18n-aria-label="Send messages" mat-mini-fab type="submit">
        <mat-icon>send</mat-icon>
      </button>
    </form>
  </div>
</div>
