<!-- Breadcrumb-->
<nav *ngIf="!embedded" aria-label="breadcrumb">
  <ol class="breadcrumb">
    <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
    <li class="breadcrumb-item">
      <span i18n="payment-account headline">My payment account</span>
    </li>
  </ol>
</nav>

<div *ngIf="!embedded" class="jumbotron mb-3">
  <h1 class="jumbotron-headline" i18n="payment-account headline">My payment account</h1>
  <p class="jumbotron-lead" i18n="payment-account lead text">Manage your payment account, which is used for payouts. You need to have a payment account
    in order to list items for rent.</p>
</div>

<div class="mb-3">
  <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
</div>

<div *ngIf="user && !isMasterDataComplete">
  <div class="alert alert-info" i18n>To create a payment account, you need to enter your master data (name, address, birthdate and nationality) first.
  </div>
  <app-master-data (onSave)="onMasterDataSave($event)" [embedded]="true"></app-master-data>
</div>

<div *ngIf="user && isMasterDataComplete">

  <h1 class="paragraph-headline" i18n="balance">Wallets</h1>
  <p i18n>Whenever you lend something, your payment goes to your wallet. From there, you can make a payout to your bank account.</p>
  <p i18n>To pay out money, click the button
    <mat-icon class="mx-1 vertical-align-middle" color="primary">account_balance</mat-icon>
    inside your wallet (only visible for wallets with money).
  </p>

  <div *ngIf="showPayoutCard" class="mb-3">
    <div class="component-container">
      <button (click)="showPayoutCard=false" aria-label="Cancel" class="cancel-button" i18n-aria-label="Cancel" i18n-matTooltip="Cancel"
              id="payout-form" mat-icon-button matTooltip="Cancel">
        <mat-icon>close</mat-icon>
      </button>
      <mat-card>
        <h2 class="paragraph-headline-2" i18n="Pay out to bank account">Pay out to your bank account</h2>
        <app-info-card class="mb-2" i18n>Click on a wallet or bank account to select it for the payout.</app-info-card>
        <br>
        <form (ngSubmit)="onPayOutClick()" [formGroup]="payOutForm">
          <div class="row">
            <div class="col-auto width-up-to-200px">
              <mat-form-field class="mat-form-input select-without-bottom-padding">
                <mat-label i18n="wallet">Wallet</mat-label>
                <mat-select #walletRef (selectionChange)="onWalletSelected($event.value)" formControlName="wallet" id="wallet"
                            panelClass="mat-select-panel-500px">
                  <mat-option *ngFor="let wallet of user.connectedPaymentAccount?.wallets" [value]="wallet.Id" i18n>{{wallet.Currency}} wallet</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-auto width-up-to-350px">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="bank account">Bank account</mat-label>
                <mat-select #bankAccountRef formControlName="bankAccount" id="bankAccount"
                            panelClass="mat-select-panel-500px">
                  <mat-option *ngFor="let bankAccount of user.connectedPaymentAccount?.bankAccounts" [value]="bankAccount.Id">{{bankAccount.IBAN | ibanFormatter}}{{bankAccount.ABA}}{{bankAccount.AccountNumber}}</mat-option>
                </mat-select>
                <mat-error i18n>Please select a bank account.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div *ngIf="selectedPayoutWallet && selectedPayoutWallet.Balance && selectedPayoutWallet.Currency" class="col-auto width-up-to-200px">
              <mat-form-field (click)="payOutForm.patchValue({amount: availableBalanceRef.value})" class="mat-form-input price-input">
                <mat-label i18n="amount">Available balance</mat-label>
                <input #availableBalanceRef [value]="mangopayService.convertAmount(selectedPayoutWallet.Balance.Amount, selectedPayoutWallet.Balance.Currency, true)" class="text-align-right" disabled matInput required type="number">
                <span *ngIf="getCurrencySuffixOrPrefix() === 'suffix' && selectedPayoutWallet && selectedPayoutWallet.Currency" matSuffix
                      style="position: relative; bottom: 4px;">&nbsp; {{currenciesById?.get(selectedPayoutWallet.Currency)?.symbol}}</span>
                <span *ngIf="getCurrencySuffixOrPrefix() === 'prefix' && selectedPayoutWallet && selectedPayoutWallet.Currency" matPrefix
                      style="position: relative; bottom: 4px;">{{currenciesById?.get(selectedPayoutWallet.Currency)?.symbol}} &nbsp;</span>
              </mat-form-field>
            </div>
            <div class="col-auto width-up-to-200px">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="amount">Amount</mat-label>
                <input #amountRef [appRange]="{min: amountMinValue, max: amountMaxValue}"
                       [maxlength]="amountMaxLength"
                       class="text-align-right" formControlName="amount" matInput required type="number">
                <span *ngIf="getCurrencySuffixOrPrefix() === 'suffix' && selectedPayoutWallet && selectedPayoutWallet.Currency" matSuffix
                      style="position: relative; bottom: 4px;">&nbsp; {{currenciesById?.get(selectedPayoutWallet.Currency)?.symbol}}</span>
                <span *ngIf="getCurrencySuffixOrPrefix() === 'prefix' && selectedPayoutWallet && selectedPayoutWallet.Currency" matPrefix
                      style="position: relative; bottom: 4px;">{{currenciesById?.get(selectedPayoutWallet.Currency)?.symbol}} &nbsp;</span>
                <mat-error *ngIf="payOutForm?.get('amount')?.hasError('required')" i18n="error message missing amount">Amount is missing.
                </mat-error>
                <mat-error *ngIf="payOutForm?.get('amount')?.hasError('pattern')" i18n="error message invalid characters amount">Amount is invalid
                </mat-error>
                <mat-error *ngIf="payOutForm?.get('amount')?.hasError('appRange')" i18n="error message not in range amount">Amount must be 1 - {{amountMaxValue | number:'1.0-2':numberFormatLocale}}.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <app-mat-icon-button
                      [disabled]="!payOutForm?.valid" color="primary" cssClass="mr-2 mb-3" i18n-label="Pay out now" icon="account_balance"
                      label="Pay out" type="submit"></app-mat-icon-button>
            </div>
          </div>
        </form>

      </mat-card>
    </div>
  </div>

  <div class="row">
    <div *ngFor="let wallet of user.connectedPaymentAccount?.wallets" class="col">
      <mat-card (click)="onWalletSelected(wallet.Id)" class="wallet-card hover-pointer">
        <h2 class="paragraph-headline-2" i18n="wallet headline">{{wallet.Currency}} Wallet</h2>
        <div i18n>Balance:
          <app-money [currencyId]="wallet.Balance.Currency"
                     [value]="mangopayService.convertAmount(wallet.Balance.Amount, wallet.Balance.Currency, true)"></app-money>
        </div>
        <div *ngIf="wallet.Balance.Amount>0" class="bottom-right-fab">
          <button (click)="onShowPayOutClick(wallet)" aria-label="Pay out money to bank account" color="primary" i18n-aria-label i18n-matTooltip="pay out button in wallet" mat-mini-fab matTooltip="Pay out">
            <mat-icon>account_balance</mat-icon>
          </button>
        </div>
      </mat-card>
    </div>
  </div>
  <p *ngIf="user.connectedPaymentAccount?.wallets?.length===0" i18n>You haven't got any wallets yet.</p>
  <app-mat-icon-button *ngIf="remainingWalletCurrencies.length>0" [callback]="onAddWalletClick.bind(this)" [params]="[]"
                       color="primary" cssClass="mr-2 mb-3" i18n-label="Add wallet" icon="add"
                       label="Add wallet"></app-mat-icon-button>

  <div *ngIf="showAddWalletCard && remainingWalletCurrencies.length>0" class="mb-3">
    <mat-card>
      <h2 class="paragraph-headline-2" i18n="Add wallet">Add wallet</h2>
      <form (ngSubmit)="onCreateWalletClick()" [formGroup]="walletForm">
        <mat-form-field class="mat-form-input">
          <mat-label i18n>Wallet currency</mat-label>
          <mat-select #currencyRef formControlName="currencyId" id="currencyId" panelClass="mat-select-panel-500px">
            <mat-option *ngFor="let currency of remainingWalletCurrencies" [value]="currency.id">{{currencyService.getCurrencyName1(currency)}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="row">
          <div class="col-auto">
            <app-info-card i18n>The wallet currency is the currency, in which your rent money is collected. Please choose the currency of your bank account
              to avoid conversion fees.
            </app-info-card>
          </div>
        </div>
        <app-mat-icon-button
                [disabled]="!walletForm?.valid" color="primary" cssClass="mt-4 mb-3" i18n-label="Create wallet" icon="account_balance_wallet"
                label="Create wallet" type="submit"></app-mat-icon-button>
      </form>
    </mat-card>
  </div>

  <h1 class="paragraph-headline" i18n>Connected bank accounts</h1>
  <p i18n>If you want to pay out money, you need a connected physical bank account to send the money to.</p>

  <div class="row">
    <div *ngFor="let bankAccount of user.connectedPaymentAccount?.bankAccounts" class="col">
      <mat-card (click)="onBankAccountSelected(bankAccount.Id)" class="wallet-card hover-pointer">
        <h2 class="paragraph-headline-2" i18n="bank account headline">{{getAccountTypeName(bankAccount.Type)}} account ({{bankAccount.Tag}})</h2>
        <div *ngIf="bankAccount.IBAN" i18n>IBAN: {{bankAccount.IBAN | ibanFormatter}}</div>
        <div *ngIf="bankAccount.BIC" i18n>BIC: {{bankAccount.BIC}}</div>
        <div *ngIf="bankAccount.AccountNumber" i18n>Account number: {{bankAccount.AccountNumber}}</div>
        <div *ngIf="bankAccount.ABA" i18n>ABA: {{bankAccount.ABA}}</div>
        <div *ngIf="bankAccount.BranchCode" i18n>Branch code: {{bankAccount.BranchCode}}</div>
        <div *ngIf="bankAccount.InstitutionNumber" i18n>Institution number: {{bankAccount.InstitutionNumber}}</div>
        <div *ngIf="bankAccount.BankName" i18n>Bank name: {{bankAccount.BankName}}</div>
        <div *ngIf="bankAccount.SortCode" i18n>Sort code: {{bankAccount.SortCode}}</div>

        <h3 class="paragraph-headline-3 mt-2 mb-1" i18n>Owner:</h3>
        <div *ngIf="bankAccount.OwnerName">{{bankAccount.OwnerName}}</div>
        <div *ngIf="bankAccount.OwnerAddress">
          <div *ngIf="getAddressData(bankAccount.OwnerAddress).AddressLine1">{{getAddressData(bankAccount.OwnerAddress).AddressLine1}}</div>
          <div *ngIf="getAddressData(bankAccount.OwnerAddress).AddressLine2">{{getAddressData(bankAccount.OwnerAddress).AddressLine2}}</div>
          <div><span *ngIf="getAddressData(bankAccount.OwnerAddress).PostalCode">{{getAddressData(bankAccount.OwnerAddress).PostalCode}} </span> <span
                  *ngIf="getAddressData(bankAccount.OwnerAddress).City">{{getAddressData(bankAccount.OwnerAddress).City}}</span></div>
          <div *ngIf="getAddressData(bankAccount.OwnerAddress).Region">{{getAddressData(bankAccount.OwnerAddress).Region}}</div>
          <div
                  *ngIf="getAddressData(bankAccount.OwnerAddress).Country">{{countryService.getCountryNameByCode(getAddressData(bankAccount.OwnerAddress).Country)}}</div>
        </div>
        <div class="bottom-right-fab">
          <button (click)="onDeactivateBankAccount(bankAccount.Id)" aria-label="deactivate bank account" color="warn" mat-mini-fab>
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </mat-card>
    </div>
  </div>
  <p *ngIf="user.connectedPaymentAccount?.bankAccounts?.length===0" i18n>You haven't got any connected bank accounts yet.</p>
  <app-mat-icon-button *ngIf="!showAddBankAccountCard" [callback]="onAddBankAccountClick.bind(this)" [params]="[]"
                       color="primary" cssClass="mr-2 mb-3" i18n-label="Add bank account" icon="add"
                       label="Add bank account"></app-mat-icon-button>

  <div *ngIf="showAddBankAccountCard" class="mb-3">
    <div class="component-container">
      <button (click)="showAddBankAccountCard=false" aria-label="Cancel" class="cancel-button" i18n-aria-label="Cancel" i18n-matTooltip="Cancel"
              id="add-bank-account-form" mat-icon-button matTooltip="Cancel">
        <mat-icon>close</mat-icon>
      </button>
      <mat-card>
        <h2 class="paragraph-headline-2" i18n="Add bank account">Add bank account</h2>
        <form (ngSubmit)="onCreateBankAccountClick()" [formGroup]="bankAccountForm">
          <div class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n>Bank account type</mat-label>
                <mat-select #bankAccountTypeRef formControlName="bankAccountType" id="bankAccountType" panelClass="mat-select-panel-500px">
                  <mat-option [value]="null"></mat-option>
                  <mat-option *ngFor="let type of bankAccountTypes" [value]="type.id">{{type.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="bankAccountTypeRef.value === mangopayBankAccountType.IBAN" class="row">
            <div class="col-auto width-up-to-350px">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="iban">IBAN</mat-label>
                <input #ibanRef [maxlength]="ibanMaxLength" [value]="ibanRef.value | ibanFormatter" formControlName="iban" matInput>
                <mat-error *ngIf="iban?.errors?.required && bankAccountForm.get('iban')?.touched" i18n>Please enter your IBAN.</mat-error>
                <mat-error *ngIf="iban?.errors?.iban && bankAccountForm.get('iban')?.touched" i18n>The IBAN you entered is invalid.</mat-error>
                <span class="character-counter">{{ibanRef.value.length}}/{{ibanMaxLength}}</span>
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="bic">BIC</mat-label>
                <input #bicRef [maxlength]="bicMaxLength" formControlName="bic"
                       matInput>
                <mat-error *ngIf="!bankAccountForm.get('bic')?.valid && bankAccountForm.get('bic')?.touched" i18n>Please enter your BIC.</mat-error>
                <span class="character-counter">{{bicRef.value.length}}/{{bicMaxLength}}</span>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="bankAccountTypeRef.value === mangopayBankAccountType.US" class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="accountNumberUs">Account number</mat-label>
                <input #accountNumberUsRef [maxlength]="accountNumberUsMaxLength" formControlName="accountNumberUs"
                       matInput>
                <mat-error *ngIf="!bankAccountForm.get('accountNumberUs')?.valid && bankAccountForm.get('accountNumberUs')?.touched" i18n>Please enter your
                  account number.
                </mat-error>
                <span class="character-counter">{{accountNumberUsRef.value.length}}/{{accountNumberUsMaxLength}}</span>
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="aba">ABA</mat-label>
                <input #abaRef [maxlength]="abaMaxLength" formControlName="aba"
                       matInput>
                <mat-error *ngIf="!bankAccountForm.get('aba')?.valid && bankAccountForm.get('aba')?.touched" i18n>Please enter your ABA.
                  It must be numbers only and 9 digits long.
                </mat-error>
                <span class="character-counter">{{abaRef.value.length}}/{{abaMaxLength}}</span>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="bankAccountTypeRef.value === mangopayBankAccountType.CA" class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="accountNumberCa">Account number</mat-label>
                <input #accountNumberCaRef [maxlength]="accountNumberCaMaxLength" formControlName="accountNumberCa"
                       matInput>
                <mat-error *ngIf="!bankAccountForm.get('accountNumberCa')?.valid && bankAccountForm.get('accountNumberCa')?.touched" i18n>Please enter your
                  account number. It must be numbers only and 7 to 35 digits long.
                </mat-error>
                <span class="character-counter">{{accountNumberCaRef.value.length}}/{{accountNumberCaMaxLength}}</span>
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="branchCode">Branch code</mat-label>
                <input #branchCodeRef [maxlength]="branchCodeMaxLength" formControlName="branchCode" matInput
                       minlength="branchCodeMinLength">
                <mat-error *ngIf="!bankAccountForm.get('branchCode')?.valid && bankAccountForm.get('branchCode')?.touched" i18n>Please enter your branch code.
                  It must be numbers only and 5 digits long.
                </mat-error>
                <span class="character-counter">{{branchCodeRef.value.length}}/{{branchCodeMaxLength}}</span>
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="institutionNumber">Institution number</mat-label>
                <input #institutionNumberRef [maxlength]="institutionNumberMaxLength" formControlName="institutionNumber" matInput
                >
                <mat-error *ngIf="!bankAccountForm.get('institutionNumber')?.valid && bankAccountForm.get('institutionNumber')?.touched" i18n>Please enter your
                  institution number. It must be numbers only and 3 digits long.
                </mat-error>
                <span class="character-counter">{{institutionNumberRef.value.length}}/{{institutionNumberMaxLength}}</span>
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="bankName">Bank name</mat-label>
                <input #bankNameRef [maxlength]="bankNameMaxLength" formControlName="bankName"
                       matInput>
                <mat-error *ngIf="!bankAccountForm.get('bankName')?.valid && bankAccountForm.get('bankName')?.touched" i18n>Please enter your bank name.
                  It must be letters and numbers only and maximum 50 characters long.
                </mat-error>
                <span class="character-counter">{{bankNameRef.value.length}}/{{bankNameMaxLength}}</span>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="bankAccountTypeRef.value === mangopayBankAccountType.GB" class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="accountNumberGb">Account number</mat-label>
                <input #accountNumberGbRef [maxlength]="accountNumberGbMaxLength" formControlName="accountNumberGb"
                       matInput>
                <mat-error *ngIf="!bankAccountForm.get('accountNumberGb')?.valid && bankAccountForm.get('accountNumberGb')?.touched" i18n>Please enter your
                  account number. It must be numbers only and 8 digits long.
                </mat-error>
                <span class="character-counter">{{accountNumberGbRef.value.length}}/{{accountNumberGbMaxLength}}</span>
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="sortCode">Sort code</mat-label>
                <input #sortCodeRef [maxlength]="sortCodeMaxLength" formControlName="sortCode"
                       matInput>
                <mat-error *ngIf="!bankAccountForm.get('sortCode')?.valid && bankAccountForm.get('sortCode')?.touched" i18n>Please enter your sort code.
                  It must be numbers only and 6 digits long.
                </mat-error>
                <span class="character-counter">{{sortCodeRef.value.length}}/{{sortCodeMaxLength}}</span>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="bankAccountTypeRef.value === mangopayBankAccountType.OTHER" class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="accountNumberOther">Account number</mat-label>
                <input #accountNumberOtherRef [maxlength]="accountNumberOtherMaxLength" formControlName="accountNumberOther"
                       matInput>
                <mat-error *ngIf="!bankAccountForm.get('accountNumberOther')?.valid && bankAccountForm.get('accountNumberOther')?.touched" i18n>Please enter
                  your account number. It must be numbers only and 7 to 35 digits long.
                </mat-error>
                <span class="character-counter">{{accountNumberOtherRef.value.length}}/{{accountNumberOtherMaxLength}}</span>
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="bic">BIC</mat-label>
                <input #bicRef [maxlength]="bicMaxLength" formControlName="bic"
                       matInput>
                <mat-error *ngIf="!bankAccountForm.get('bic')?.valid && bankAccountForm.get('bic')?.touched" i18n>Please enter your BIC.</mat-error>
                <span class="character-counter">{{bicRef.value.length}}/{{bicMaxLength}}</span>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n>Bank account currency</mat-label>
                <mat-select #bankAccountCurrencyRef formControlName="currencyId" id="bankAccountCurrencyId" panelClass="mat-select-panel-500px">
                  <mat-option *ngFor="let currency of currencies" [value]="currency.id">{{currencyService.getCurrencyName1(currency)}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <h3 class="paragraph-headline-3 mb-3" i18n>Owner:</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="ownerName">Name</mat-label>
                <input #ownerNameRef [maxlength]="ownerNameMaxLength" formControlName="ownerName" matInput>
                <mat-error *ngIf="!bankAccountForm.get('ownerName')?.valid && bankAccountForm.get('ownerName')?.touched" i18n>Please enter the name of the bank
                  account owner.
                </mat-error>
                <span class="character-counter">{{ownerNameRef.value.length}}/{{ownerNameMaxLength}}</span>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="address line 1">Address line 1</mat-label>
                <input #addressLine1Ref [maxlength]="addressLine1MaxLength" formControlName="addressLine1" matInput>
                <mat-error *ngIf="!bankAccountForm.get('addressLine1')?.valid && bankAccountForm.get('addressLine1')?.touched" i18n>Please enter the address
                  line 1 of
                  the bank account owner.
                </mat-error>
                <span class="character-counter">{{addressLine1Ref.value.length}}/{{addressLine1MaxLength}}</span>
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="address line 2">Address line 2</mat-label>
                <input #addressLine2Ref [maxlength]="addressLine2MaxLength" formControlName="addressLine2" matInput>
                <span class="character-counter">{{addressLine2Ref.value.length}}/{{addressLine2MaxLength}}</span>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="postalCode">Postal code</mat-label>
                <input #postalCodeRef [maxlength]="postalCodeMaxLength" formControlName="postalCode" matInput>
                <mat-error *ngIf="!bankAccountForm.get('postalCode')?.valid && bankAccountForm.get('postalCode')?.touched" i18n>Please enter the postal code of
                  the bank account owner.
                </mat-error>
                <span class="character-counter">{{postalCodeRef.value.length}}/{{postalCodeMaxLength}}</span>
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="city">City</mat-label>
                <input #cityRef [maxlength]="cityMaxLength" formControlName="city" matInput>
                <mat-error *ngIf="!bankAccountForm.get('city')?.valid && bankAccountForm.get('city')?.touched" i18n>Please enter the city of the bank account
                  owner.
                </mat-error>
                <span class="character-counter">{{cityRef.value.length}}/{{cityMaxLength}}</span>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="region">Region/State</mat-label>
                <input #regionRef [maxlength]="regionMaxLength" formControlName="region" matInput>
                <mat-error *ngIf="!bankAccountForm.get('region')?.valid && bankAccountForm.get('region')?.touched" i18n>Please enter the region or state of the
                  bank account owner.
                </mat-error>
                <span class="character-counter">{{regionRef.value.length}}/{{regionMaxLength}}</span>
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n="country">Country</mat-label>
                <mat-select (selectionChange)="countrySelected()" formControlName="countryCode" id="countryCode" panelClass="mat-select-panel-500px">
                  <mat-option [value]="null"></mat-option>
                  <mat-option *ngFor="let country of countries" [value]="country.code">{{country.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <app-mat-icon-button
                  [disabled]="bankAccountForm?.errors?.somethingWrong" color="primary" cssClass="mt-4 mb-3" i18n-label="Create wallet" icon="account_balance"
                  label="Connect bank account" type="submit"></app-mat-icon-button>
        </form>
      </mat-card>
    </div>
  </div>


  <h1 class="paragraph-headline" i18n>Validation</h1>
  <p i18n>Before you can pay out money, you need to be validated. To be validated, please upload an identity proof (e.g. ID
    card) and an address proof.<br>
    Blitzshare does not read or save any uploaded documents. They are instead sent to our payment provider <i>Mangopay</i> for
    validation.</p>

  <div *ngIf="user.connectedPaymentAccount?.userData?.ProofOfIdentity" class="info-success" i18n>Your identity has been validated.</div>
  <div *ngIf="user.connectedPaymentAccount?.userData?.ProofOfAddress" class="info-success" i18n>Your address has been validated.</div>

  <div *ngIf="!user.connectedPaymentAccount?.userData?.ProofOfIdentity" class="info-danger" i18n>Your identity has not yet been validated.</div>
  <div *ngIf="!user.connectedPaymentAccount?.userData?.ProofOfAddress" class="info-danger" i18n>Your address has not yet been validated.</div>

  <div class="row">
    <div *ngFor="let kycDocument of user.connectedPaymentAccount?.kycDocuments" class="col">
      <mat-card class="wallet-card">
        <h2 class="paragraph-headline-2">{{getDocumentTypeName(kycDocument.Type)}}</h2>
        <div *ngIf="kycDocument.Status" i18n>Status: {{kycDocument.Status | mangopayKycDocumentStatus}}</div>
        <div *ngIf="kycDocument.RefusedReasonType">{{kycDocument.RefusedReasonType | mangopayRefusedReasonType}}</div>
        <div *ngIf="kycDocument.RefusedReasonMessage">{{kycDocument.RefusedReasonMessage}}</div>
        <div *ngIf="kycDocument.CreationDate" i18n>Uploaded: {{kycDocument.CreationDate * 1000 | moment:'LLLL'}}</div>
        <div *ngIf="kycDocument.ProcessedDate" i18n>Processed: {{kycDocument.ProcessedDate * 1000 | moment:'LLLL'}}</div>
        <div *ngIf="kycDocument.Id" class="small" i18n>DocumentId: {{kycDocument.Id}}</div>
        <div *ngIf="kycDocument.UserId" class="small" i18n>UserId: {{kycDocument.UserId}}</div>
      </mat-card>
    </div>
  </div>

  <app-mat-icon-button *ngIf="!showAddKycDocumentCard" [callback]="onAddKycDocumentClick.bind(this)" [params]="[]"
                       color="primary" cssClass="mr-2 mb-3" i18n-label="Add validation document" icon="add"
                       label="Add validation document"></app-mat-icon-button>

  <div *ngIf="showAddKycDocumentCard" class="mb-3">
    <div class="component-container">
      <button (click)="showAddKycDocumentCard=false" aria-label="Cancel" class="cancel-button" i18n-aria-label="Cancel" i18n-matTooltip="Cancel"
              id="add-kyc-document-form" mat-icon-button matTooltip="Cancel">
        <mat-icon>close</mat-icon>
      </button>
      <mat-card>
        <h2 class="paragraph-headline-2" i18n="Add validation document">Add validation document</h2>
        <form [formGroup]="kycForm">
          <div class="row">
            <div class="col-auto">
              <mat-form-field class="mat-form-input">
                <mat-label i18n>Document Type</mat-label>
                <mat-select #documentTypeRef (selectionChange)="onDocumentTypeSelected()" formControlName="documentType" id="documentType"
                            panelClass="mat-select-panel-500px">
                  <mat-option [value]="null"></mat-option>
                  <mat-option *ngFor="let docType of documentTypes" [value]="docType.id">{{docType.name}}</mat-option>
                </mat-select>
                <mat-error i18n>Please select a document type.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="documentTypeRef.value === mangopayDocumentType.IdentityProof">
            <div *ngIf="user.connectedPaymentAccount?.userData?.ProofOfIdentity" class="alert alert-warning" i18n>You already successfully validated
              your identity. Are you sure you want to validate it again?
            </div>
            <p i18n>Please upload both sides of your ID card, passport or similar
              to prove your identity.</p>
          </div>
          <div *ngIf="documentTypeRef.value === mangopayDocumentType.AddressProof">
            <div *ngIf="user.connectedPaymentAccount?.userData?.ProofOfAddress" class="alert alert-warning" i18n>You already successfully validated
              your address. Are you sure you want to validate it again?
            </div>
            <p i18n>Please upload a document, that proofs your address, e.g. a utility bill. If
              your identity proof already contains your address, you can skip this step.</p>
            <p i18n>Your address proof has to match this <a [routerLink]="['..','personalInfo']">address</a>: {{user?.address?.formattedAddress}}</p>
          </div>
          <div *ngIf="documentTypeRef.value">
            <app-file-selector (onUploadClicked)="onCreateKycDocumentClick($event)" [fileTypes]="['pdf', 'jpeg', 'jpg', 'png']" [maxSize]="maxSize"
                               [minSize]="minSize" [remainingUploads]="5" [uploadEnabled]="kycForm?.valid"></app-file-selector>
          </div>
        </form>

      </mat-card>
    </div>

  </div>
  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [message]="loadingSpinnerMessage"
                       [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>
</div>
