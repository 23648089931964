import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private API_KEY = environment.translationApiKey;
  private translateUrl = 'https://translation.googleapis.com/language/translate/v2?key=' + this.API_KEY;

  constructor(private http: HttpClient) {
  }

  translate(textToTranslate: string, target: string): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    const body = {
      'q': textToTranslate,
      'source': 'en',
      'target': target,
      'format': 'text',
    };

    return this.http.post<any>(this.translateUrl, body, {headers: headers}).pipe(
      tap(result => console.log('Result: ' + JSON.stringify(result))),
      map(result => result.data.translations[0].translatedText),
      catchError(this.handleError),
    );
  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
