import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {User} from '../../shared/models/user.interface';
import firebase from 'firebase/app';
import {Transaction} from '../../shared/models/transaction.interface';
import {environment} from '../../../environments/environment';
import {ElementScrollPercentageService} from '../../shared/services/element-scroll-percentage.service';
import {BookService} from '../../book/book.service';
import {UserService} from '../../shared/services/user.service';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.css'],
})
export class TransactionListComponent extends BaseComponent implements OnInit {

  @Input() borrowing = false;
  @Input() lending = false;
  @Input() open = false;
  @Input() completed = false;
  lastVisible?: QueryDocumentSnapshot<Transaction>;
  transactions: Transaction[] = [];

  thereIsMore = false;
  /**
   * The scroll percentage, at which new listings are loaded. For example, the value 50 will reload items after scrolling down half the page.
   */
  @Input() infiniteScrollPercentage = 101;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private elementScrollPercentageService: ElementScrollPercentageService,
      private bookService: BookService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.user$.subscribe(user => {
      if (user)
        this.fetchTransactions(user);
    });

    this.elementScrollPercentageService
        .getScrollAsStream() // Defaults to Document if no Element supplied.
        .subscribe((percent: number): void => {
          if (!this.thereIsMore || this.infiniteScrollPercentage >= 100)
            return;
          if (percent > this.infiniteScrollPercentage)
            this.onClickShowMore();
        });
  }

  /**
   * Called, when the Show more button is clicked. Loads more transaction.
   */
  onClickShowMore(): void {
    if (this.user)
      this.fetchTransactions(this.user);
  }

  private fetchTransactions(user: User) {
    this.enableLoadingSpinner($localize`Loading transactions...`);
    this.bookService.fetchTransactions(user.uid, this.borrowing, this.lending, this.open, this.completed, undefined, undefined, undefined, undefined, this.lastVisible).then(wrapper => {
      this.disableLoadingSpinner();
      this.clearAlerts();
      if (wrapper.data) {
        this.transactions.push(...wrapper.data);
        this.thereIsMore = wrapper.data.length === environment.defaultLoadTransactionsCount;
      }
      this.lastVisible = wrapper.lastVisible;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }
}
