import {Pipe, PipeTransform} from '@angular/core';

/**
 * Transforms zero to null.
 */
@Pipe({
  name: 'zeroToNull',
})
export class ZeroToNullPipe implements PipeTransform {
  transform(value: unknown): any {
    if (Number(value) === 0)
      return null;

    return value;
  }

}

