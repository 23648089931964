<mat-tab-group (selectedTabChange)="onTabChange($event)" *ngIf="listings && listings.length>0" [selectedIndex]="selectedIndex">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="mr-1" color="primary">grid_view</mat-icon>
    </ng-template>

    <div [ngClass]="{'justify-content-center': centered, 'justify-content-start': !centered}" class="row  mx-0 px-0">
      <div class="card-group">
        <div *ngFor="let listing of listings" class="card listing-card ml-0 mr-1 mb-1">
          <a [routerLink]="['/browse/listing',listing.uid]">
            <app-img-with-blurry-background *ngIf="listing.imgUrls && listing.imgUrls[0] !== undefined"
                                            alt="Picture of {{listing.name}}" i18n-alt="Picture alt text in item card"
                                            [src]="listing.imgUrls[0].thumb" [height]="300"
                                            default="./assets/images/default/default-listing-w300.webp"></app-img-with-blurry-background>
          </a>
          <div [class]="listing.disabled?'bg-gray':''" class="card-body">
            <h2 class="paragraph-headline-2"><a [innerHTML]="listing.name" [routerLink]="['/browse/listing',listing.uid]"></a></h2>
            <p [innerHTML]="listing.summary"></p>
            <span *ngIf="listing.address" class="small">{{listing.address.zipCode}} {{listing.address.city}}<span
                    *ngIf="listing.address.district"> - {{listing.address.district}}</span>
            </span>
            <span *ngIf="listing.geohashCoords?.lat && listing.geohashCoords?.lng && coords?.lat && coords?.lng" class="small"
                  i18n="distance in km with formula">
            ({{getDistanceInKm(listing.geohashCoords!, coords!) | number:'1.0-1': numberFormatLocale}} km)</span>
            <div *ngIf="showMyListingMarker && listing.lenderUid === user?.uid" class="mt-3">
              <mat-chip-list aria-label="your listing marker">
                <mat-chip color="primary" i18n selected>Your listing</mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="card-footer align-middle">
            <ng-container *ngIf="browserService.getPeriodPrice(listing, periodInDays) as periodPrice">
              <div i18n="item total price for period">
                <app-money [currencyId]="listing?.currencyId"
                           [value]="periodPrice"></app-money>
                for {periodInDays, plural, =0 {days} =1 {1 day} other {{{periodInDays}} days}}</div>
            </ng-container>
            <ng-container *ngIf="browserService.getMinRentPeriod(listing) as minRentPeriod">
              <ng-container *ngIf="minRentPeriod > periodInDays">
                <span class="minimum-rent-warning" i18n="minimum rent period and price"
                      i18n-matTooltip="minimum rent period and price tooltip" matTooltip="The rent period is too short. The lender expects a minimum rent
                period of {{minRentPeriod}} day(s). But the selected period is only {{periodInDays}} day(s).">
                  Minimum rent:
                  <app-money [currencyId]="listing?.currencyId" [value]="browserService.getPeriodPrice(listing, minRentPeriod)"></app-money>
                  for {minRentPeriod, plural, =0 {days} =1 {1 day} other {{{minRentPeriod}} days}}</span>
                <div *ngIf="listing?.lowestPricePerDay === listing?.highestPricePerDay && periodInDays === 1" i18n="item unique price">
                  <app-money [currencyId]="listing?.currencyId" [value]="listing?.lowestPricePerDay"></app-money>
                  per day
                </div>
              </ng-container>
            </ng-container>
            <div *ngIf="listing?.lowestPricePerDay !== listing?.highestPricePerDay"
                 i18n="item price range lowest - highest" i18n-matTooltip="tooltip item price range lowest - highest"
                 matTooltip="A longer rental period results in a lower price per day.">
              <app-money [currencyId]="listing?.currencyId" [value]="listing?.lowestPricePerDay"></app-money>
              -
              <app-money [currencyId]="listing?.currencyId" [value]="listing?.highestPricePerDay"></app-money>
              per day
            </div>
            <div *ngIf="listing?.lowestPricePerDay === listing?.highestPricePerDay && periodInDays>1" i18n="item unique price">
              <app-money [currencyId]="listing?.currencyId" [value]="listing?.lowestPricePerDay"></app-money>
              per day
            </div>

          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="mr-1" color="primary">table_rows</mat-icon>
    </ng-template>
    <table (matSortChange)="sortData($event)" *ngIf="dataSource" [dataSource]="dataSource" [matSortActive]="matSortActive"
           [matSortDirection]="matSortDirection"
           class="mat-elevation-z8 table-responsive" mat-table matSort>

      <!-- Picture Column -->
      <ng-container matColumnDef="picture">
        <th *matHeaderCellDef i18n="picture heading" mat-header-cell>Picture</th>
        <td *matCellDef="let listing" [class]="listing.disabled?'bg-gray':''" mat-cell>
          <a [routerLink]="['/browse/listing',listing.uid]">
            <div class="d-block d-sm-none">
              <app-img-with-blurry-background *ngIf="listing.imgUrls && listing.imgUrls[0] !== undefined"
                                              alt="Picture of {{listing.name}}"
                                              default="./assets/images/default/default-listing-w300.webp"
                                              i18n-alt="Picture alt text in item card"
                                              [height]="150" [width]="75"
                                              src="{{listing.imgUrls[0].thumb}}">
              </app-img-with-blurry-background>
            </div>
            <div class="d-sm-block d-none">
              <app-img-with-blurry-background *ngIf="listing.imgUrls && listing.imgUrls[0] !== undefined"
                                              alt="Picture of {{listing.name}}"
                                              default="./assets/images/default/default-listing-w300.webp"
                                              i18n-alt="Picture alt text in item card"
                                              [height]="150" [width]="150"
                                              src="{{listing.imgUrls[0].thumb}}">
              </app-img-with-blurry-background>
            </div>
          </a>
        </td>
      </ng-container>

      <!-- Name / Summary Column -->
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef i18n="name and summary heading" mat-header-cell>Name and summary</th>
        <td *matCellDef="let listing" [class]="listing.disabled?'bg-gray':''" mat-cell>
          <a [routerLink]="['/browse/listing',listing.uid]">
            <h2 [innerHTML]="listing.name" class="paragraph-headline-2"></h2></a>
          <p [innerHTML]="listing.summary"></p>
          <ng-container *ngIf="browserService.getMinRentPeriod(listing) as minRentPeriod">
            <span *ngIf="minRentPeriod > periodInDays" class="minimum-rent-warning" i18n="minimum rent period and price"
                  i18n-matTooltip="minimum rent period and price tooltip" matTooltip="The rent period is too short. The lender expects a minimum rent
                period of {{minRentPeriod}} day(s). But the selected period is only {{periodInDays}} day(s).">
              Minimum rent:
              <app-money [currencyId]="listing?.currencyId" [value]="browserService.getPeriodPrice(listing, minRentPeriod)"></app-money>
              for {minRentPeriod, plural, =0 {days} =1 {1 day} other {{{minRentPeriod}} days}}</span>
          </ng-container>
          <div *ngIf="showMyListingMarker && listing.lenderUid === user?.uid" class="my-3">
            <mat-chip-list aria-label="your listing marker">
              <mat-chip color="primary" i18n selected>Your listing</mat-chip>
            </mat-chip-list>

          </div>
        </td>
      </ng-container>

      <!-- Period price -->
      <ng-container matColumnDef="periodPrice">
        <th *matHeaderCellDef i18n="periodPrice heading" mat-header-cell>Price
          for {periodInDays, plural, =0 {days} =1 {1 day} other {{{periodInDays}} days}}</th>
        <td *matCellDef="let listing" [class]="listing.disabled?'bg-gray':''" mat-cell>
          <span *ngIf="browserService.getPeriodPrice(listing, periodInDays) as periodPrice"
                i18n="item total price for period">
            <app-money [currencyId]="listing?.currencyId"
                       [value]="periodPrice"></app-money></span>
          <ng-container *ngIf="browserService.getMinRentPeriod(listing) as minRentPeriod">
            <span *ngIf="minRentPeriod > periodInDays" class="minimum-rent-warning" i18n-matTooltip=""
                  matTooltip="This is the price per day for a minimum rental period of {{minRentPeriod}} day(s).">
              <ng-container *ngIf="browserService.getPeriodPrice(listing, minRentPeriod) as periodPrice">
                <app-money [currencyId]="listing?.currencyId" [value]="periodPrice/minRentPeriod"></app-money>
              </ng-container>
            </span>
          </ng-container>
        </td>
      </ng-container>

      <!-- Lowest price -->
      <ng-container matColumnDef="lowestPrice">
        <th *matHeaderCellDef i18n="lowestPrice heading" mat-header-cell>Lowest price per day</th>
        <td *matCellDef="let listing" [class]="listing.disabled?'bg-gray':''" mat-cell>
          <app-money [currencyId]="listing?.currencyId" [value]="listing?.lowestPricePerDay"></app-money>
        </td>
      </ng-container>

      <!-- Highest price -->
      <ng-container matColumnDef="highestPrice">
        <th *matHeaderCellDef i18n="highestPrice heading" mat-header-cell>Highest price per day</th>
        <td *matCellDef="let listing" [class]="listing.disabled?'bg-gray':''" mat-cell>
          <app-money [currencyId]="listing?.currencyId" [value]="listing?.highestPricePerDay"></app-money>
        </td>
      </ng-container>

      <!-- Address Column -->
      <ng-container matColumnDef="address">
        <th *matHeaderCellDef i18n="address heading" mat-header-cell>Address</th>
        <td *matCellDef="let listing" [class]="listing.disabled?'bg-gray':''" mat-cell>
          <span *ngIf="listing.address">{{listing.address.zipCode}} {{listing.address.city}} {{listing.address.district}}</span>
        </td>
      </ng-container>

      <!-- Distance Column -->
      <ng-container matColumnDef="distance">
        <th *matHeaderCellDef i18n="distance heading" mat-header-cell>Distance</th>
        <td *matCellDef="let listing" [class]="listing.disabled?'bg-gray':''" mat-cell>
          <span *ngIf="listing.geohashCoords?.lat && listing.geohashCoords?.lng && coords?.lat && coords?.lng"
                i18n="distance in km with formula">
            {{getDistanceInKm(listing.geohashCoords, coords!) | number:'1.0-1': numberFormatLocale}} km</span>
        </td>
      </ng-container>

      <!-- Category -->
      <ng-container matColumnDef="category">
        <th *matHeaderCellDef i18n="category" mat-header-cell>Category</th>
        <td *matCellDef="let listing" [class]="listing.disabled?'bg-gray':''" mat-cell>
          <a *ngIf="categoriesById && listing.categoryId && categoriesById.get(listing.categoryId) as category"
             [routerLink]="['/browse','category',category.id]">{{categoryService.getCategoryName(category)}}</a>
        </td>
      </ng-container>

      <!-- Creation Date Column -->
      <ng-container matColumnDef="creationDate">
        <th *matHeaderCellDef i18n="creationDate heading" mat-header-cell>Creation Date</th>
        <td *matCellDef="let listing" [class]="listing.disabled?'bg-gray':''" mat-cell>
          <p *ngIf="listing.creationDate" [title]="utilService.getDate(listing.creationDate) | moment:'LLLL'">
            {{utilService.createFromNowString(utilService.getDate(listing.creationDate))}}</p>
          <p *ngIf="listing.lastEditDate" [title]="utilService.getDate(listing.lastEditDate) | moment:'LLLL'" class="small" i18n="last edit date">
            Last edit {{utilService.createFromNowString(utilService.getDate(listing.lastEditDate))}}.</p>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

  </mat-tab>

</mat-tab-group>

<div *ngIf="thereIsMore">
  <button (click)="onClickShowMore()" class="btn btn-primary my-1" i18n="button in item-card-group, which is used to load more listings" type="button">Show more</button>
</div>

<p *ngIf="listings && listings.length>0 && totalCount>0" class="mx-0 mt-2" i18n="message shown listings count and total listings count">
  Showing {{listings.length}} of {{totalCount}} listings.</p>

<p *ngIf="!listings || listings.length===0" class="mx-0 mt-3 alert alert-secondary" i18n="no listings found">We could not find any listings.</p>
