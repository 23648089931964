import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-safety-tips',
  templateUrl: './safety-tips.component.html',
  styleUrls: ['./safety-tips.component.css'],
})
export class SafetyTipsComponent implements OnInit {

  @Input() borrowerName?: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
