<div *ngIf="errorMessage" class="alert alert-danger" i18n="user info error message" role="alert">Could not load user: {{errorMessage}}</div>
<ng-container *ngIf="userPublic">
    <div class="row">
        <div *ngIf="userPublic.imgUrl !== undefined" class="col img-col pr-0">
            <a *ngIf="userPublic?.uid" [routerLink]="['/social','user',userPublic?.uid]">
                <img alt="User image" class="user-img" default="./assets/images/default/default-user-w300.webp" i18n-alt="User info image alt text"
                     src="{{userPublic.imgUrl}}">
            </a>
        </div>
        <div class="w-100 col pt-0">
            <h2 class="paragraph-headline-2"><a *ngIf="userPublic?.uid" [routerLink]="['/social','user',userPublic?.uid]">{{userPublic.displayName}}</a></h2>
            <app-user-info-internal [userPublic]="userPublic"></app-user-info-internal>
        </div>
    </div>
    <ng-container *ngIf="user && user?.uid !== userPublic?.uid">
        <app-mat-icon-button *ngIf="!showWriteMessageComponent" [callback]="onSendMessage.bind(this)" [params]="[]"
                             color="accent" cssClass="my-2" i18n-label="Send message to borrower" icon="message"
                             label="Send message to {{userPublic?.displayName}}"
                             type="button"></app-mat-icon-button>


        <mat-card *ngIf="showWriteMessageComponent" class="mb-3">
            <app-write-message (onClose)="showWriteMessageComponent=false" *ngIf="showWriteMessageComponent"
                               [listingUid]="listingUid"
                               [receiverUid]="userPublicUid"></app-write-message>
        </mat-card>
    </ng-container>
</ng-container>
