<div class="container bg-container p-1">
  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
      <li class="breadcrumb-item">
        <span i18n="my listings headline">Settings</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="settings headline">Settings</h1>
    <p class="jumbotron-lead" i18n="settings lead text">Change my Blitzshare settings.</p>
  </div>

  <mat-tab-group (selectedTabChange)="onTabSwitch($event)" mat-align-tabs="start">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">web</mat-icon>
        <span i18n="website">Website</span>
      </ng-template>

      <h1 class="paragraph-headline" i18n>Website settings</h1>

      <!-- Language -->
      <div class="row m-0 p-0">
        <div class="col-md-2">
          <div class="setting-title mb-2" i18n="setting title Language">Language</div>
        </div>
        <div class="col">
          <p i18n="setting language hint">Choose your language for the website and emails sent to you.</p>

          <mat-form-field class="mat-form-input">
            <mat-label i18n>Language</mat-label>
            <mat-select #langRef [(ngModel)]="langCode" id="lang" required>
              <mat-option [value]="undefined" i18n>Please select a language</mat-option>
              <mat-option *ngFor="let lang of languageService.languages" [value]="lang.code">{{languageService.getLanguageNameByCode(lang.code)}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Time zone -->
      <div class="row m-0 p-0">
        <div class="col-md-2">
          <div class="setting-title mb-2" i18n="setting time zone">Time zone</div>
        </div>
        <div class="col">
          <p i18n="setting time zone hint">Choose your time zone for the website and emails sent to you.</p>

          <mat-form-field class="mat-form-input">
            <mat-label i18n>Time zone</mat-label>
            <mat-select #timeZoneRef [(ngModel)]="timeZone" id="timeZone" required>
              <mat-option [value]="undefined" i18n>Please select a time zone</mat-option>
              <mat-option *ngFor="let timeZone of getTimeZones()" [value]="timeZone.id">{{timeZone.translatedName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row m-0 p-0">
        <div class="col-md-2">
          <div class="setting-title mb-2" i18n="setting title preferred listing view mode">Listing view mode</div>
        </div>
        <div class="col">
          <p i18n="setting hint preferred listing view mode">Choose, if listings in search, browse and category views should be shown as grids or tables.</p>

          <mat-radio-group [(ngModel)]="listingsViewMode" aria-label="Select an option" color="primary"
                           i18n-aria-label="select an option" name="listingsViewMode">
            <mat-radio-button value="grid">
              <div class="icon-with-text">
                <mat-icon class="mr-1">grid_view</mat-icon>
                <span i18n="grid">Grid</span>
              </div>
            </mat-radio-button>
            <mat-radio-button value="table">
              <div class="icon-with-text">
                <mat-icon class="mr-1">table_rows</mat-icon>
                <span i18n="table">Table</span>
              </div>
            </mat-radio-button>
          </mat-radio-group>

        </div>
      </div>


      <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="false" [message]="loadingSpinnerMessage"></app-loading-spinner>

      <div class="my-3">
        <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
      </div>

      <app-mat-icon-button [callback]="onSaveWebsiteSettings.bind(this)" [params]="[]" color="primary" cssClass="mr-1"
                           i18n-label="button label save" icon="save"
                           label="Save"></app-mat-icon-button>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">email</mat-icon>
        <span i18n="notifications">Notifications</span>
      </ng-template>

      <h1 class="paragraph-headline" i18n>Email notification settings</h1>
      <p i18n>Control for which events you want to receive email notifications:</p>

      <table class="table table-striped table-sm table-responsive">
        <thead>
        <th>
          <mat-checkbox (change)="toggleAllNotifications($event.checked)"
                        [checked]="allNotificationsChecked"
                        [indeterminate]="someNotificationsChecked"
                        color="primary"><span class="setting-title" i18n>All notifications</span>
          </mat-checkbox>
        </th>
        </thead>
        <tbody>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.message" color="primary"
                          i18n="notification setting message">I've got a new message
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.bookingCreated" color="primary"
                          i18n="notification setting bookingCreated">I've got a new booking
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.bookingAccepted" color="primary"
                          i18n="notification setting bookingAccepted">Booking was accepted
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.bookingDenied" color="primary"
                          i18n="notification setting bookingDenied">Booking was denied
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.bookingCancelled" color="primary"
                          i18n="notification setting bookingCancelled">Booking was cancelled
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.bookingPaid" color="primary"
                          i18n="notification setting bookingPaid">Booking was paid
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.markedAsPickedUp" color="primary"
                          i18n="notification setting markedAsPickedUp">Item was marked as picked up
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.revertedPickedUp" color="primary"
                          i18n="notification setting revertedPickedUp">Marking the item as <i>picked up</i> was reverted
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.pickupConfirmed" color="primary"
                          i18n="notification setting pickupConfirmed">Item pickup was confirmed
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.revertedReturned" color="primary"
                          i18n="notification setting revertedReturned">Marking the item as <i>returned</i> was reverted
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.paymentTransferred" color="primary"
                          i18n="notification setting paymentTransferred">Payment received
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.paymentRefunded" color="primary"
                          i18n="notification setting paymentRefunded">Payment refunded
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.markedAsReturned" color="primary"
                          i18n="notification setting markedAsReturned">Item was marked as returned
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.returnConfirmed" color="primary"
                          i18n="notification setting returnConfirmed">Item return was confirmed
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.newBookingDateSuggested" color="primary"
                          i18n="notification setting newBookingDateSuggested">A new booking period was suggested
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.newBookingDateSuggestionAccepted"
                          color="primary"
                          i18n="notification setting newBookingDateSuggestionAccepted">New booking period suggestion was accepted
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.newBookingDateSuggestionDenied" color="primary"
                          i18n="notification setting newBookingDateSuggestionDenied">New booking period suggestion was denied
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.newBookingDateSuggestionRetracted"
                          color="primary"
                          i18n="notification setting newBookingDateSuggestionRetracted">New booking period suggestion was retracted
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.mangoPayDocumentTypeIdentity" color="primary"
                          i18n="notification setting mangoPayDocumentTypeIdentity">Identity verifcation validation
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.mangoPayDocumentTypeAddress" color="primary"
                          i18n="notification setting mangoPayDocumentTypeAddress">Address verifcation validation
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.pickupReminder" color="primary"
                          i18n="notification setting pickupReminder">Reminder about an upcoming item pickup
            </mat-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox (change)="updateNotificationsMetaCheckbox()" [(ngModel)]="emailNotifications.returnReminder" color="primary"
                          i18n="notification setting returnReminder">Reminder about an upcoming item return
            </mat-checkbox>
          </td>
        </tr>
        </tbody>
      </table>
      <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="false" [message]="loadingSpinnerMessage"></app-loading-spinner>

      <br>
      <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
      <app-mat-icon-button [callback]="onSaveNotificationSettings.bind(this)" [params]="[]" color="primary" cssClass="mr-1"
                           i18n-label="button label save" icon="save"
                           label="Save"></app-mat-icon-button>
    </mat-tab>
  </mat-tab-group>
</div>
