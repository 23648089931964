<div class="container bg-container p-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li *ngIf="listing" class="breadcrumb-item"><a [routerLink]="['/browse','listing',listing.uid]">{{listing.name}}</a></li>
      <li class="breadcrumb-item" i18n="confirm booking">Confirm booking</li>
    </ol>
  </nav>

  <app-not-logged-in *ngIf="!user" i18n-message="not-logged-in message book item"
                     message="You need to login, before you can book an item."></app-not-logged-in>

  <div *ngIf="user">

    <div *ngIf="!listing" class="row">
      <div class="col">
        <div class="jumbotron mb-3">
          <h1 class="jumbotron-headline" i18n="Book item error headline">Booking page</h1>
          <p class="jumbotron-lead" i18n="Book item slogan no listing">You came here without choosing an item to book. Or something went wrong. Please
            go back, choose an item and click the <i>Book</i> button.</p>
        </div>
      </div>
    </div>

    <div *ngIf="listing && listing.disabled" class="row">
      <div class="col">
        <div class="jumbotron mb-3">
          <h1 class="jumbotron-headline" i18n="Book item error headline">Booking page</h1>
          <p class="jumbotron-lead" i18n="Book item slogan listing disabled">The item you were going to book is disabled. Booking is not possible.
            Please go back, choose another item and click the <i>Book</i> button.</p>
        </div>
      </div>
    </div>

    <div *ngIf="listing && !listing.disabled">
      <!-- Jumbotron -->
      <div class="row">
        <div class="col">
          <div class="jumbotron mb-3">
            <h1 class="jumbotron-headline" i18n="Book item headline">Confirm booking {{removeEmTags(listing.name)}}</h1>
            <p class="jumbotron-lead" i18n="Book item slogan">You're just a few steps away from borrowing this item.</p>
          </div>
        </div>
      </div>

      <!-- Item card -->
      <div class="row mb-3">
        <div class="col">
          <div class="card">
            <div class="row no-gutters">
              <div>
                <a [routerLink]="['/browse/listing',listing.uid]">
                  <app-img-with-blurry-background *ngIf="listing.imgUrls && listing.imgUrls[0] !== undefined"
                                                  alt="Picture of {{listing.name}}" [maxHeight]="300"
                                                  default="./assets/images/default/default-listing-w300.webp"
                                                  i18n-alt="Picture alt text in item card"
                                                  src="{{listing.imgUrls[0].thumb}}"></app-img-with-blurry-background>
                </a>
              </div>
              <div class="col">
                <div class="card-body">
                  <h2 class="paragraph-headline-2"><a [routerLink]="['/browse/listing',listing.uid]">{{removeEmTags(listing.name)}}</a></h2>
                  <p>{{removeEmTags(listing.summary)}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Address headline -->
      <div class="row mb-3">
        <div class="col">
          <h1 class="paragraph-headline" i18n="address headline">Address</h1>
        </div>
      </div>

      <!-- Address and map -->
      <div class="row mb-3">
        <div class="col min-w-350px">

          <table *ngIf="listing.address as address" class="table table-striped table-sm">

            <tbody>
            <tr *ngIf="address.district">
              <th i18n="district">District</th>
              <td>{{address.district}}</td>
            </tr>
            <tr *ngIf="address.zipCode">
              <th i18n="zip code">Zip code</th>
              <td>{{address.zipCode}}</td>
            </tr>
            <tr *ngIf="address.city">
              <th i18n="city">City</th>
              <td>{{address.city}}</td>
            </tr>
            <tr *ngIf="address.state">
              <th i18n="state">State</th>
              <td>{{address.state}}</td>
            </tr>
            <tr *ngIf="address.countryCode">
              <th i18n="Country">Country</th>
              <td>{{countryService.getCountryNameByCode(address.countryCode)}}</td>
            </tr>
            </tbody>
          </table>

          <app-info-card i18n="exact address hint">You can see the exact address after booking.</app-info-card>

        </div>
        <div class="col min-w-350px">
          <app-map *ngIf="listing && listing.geohash" [disabled]="true" [geohash]="listing.geohash" [showMarker]="false"></app-map>
        </div>
      </div>

      <!--Lender-->
      <div class="row mb-3 justify-content-start">
        <div class="col-auto">
          <h1 class="paragraph-headline" i18n="lender headline">Lender</h1>
          <app-user-info [userPublicUid]="listing.lenderUid"></app-user-info>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">

          <form (ngSubmit)="onBook()" *ngIf="bookForm !== undefined" [appDateRangePicker]="{rentParams: rentParams, listing: listing,
          availableDayTimestamps: availableDayTimestamps, dateRangeInvalidDates: dateRangeInvalidDates}"
                [formGroup]="bookForm">
            <!-- Price table-->
            <app-listing-price-table [currencyId]="currencyId" [listing]="listing"></app-listing-price-table>

            <!-- Book form -->
            <h1 class="paragraph-headline" i18n="borrowing headline on booking view">Borrowing period</h1>
            <div class="container m-0 p-0">
              <div class="row m-0 p-0">
                <div class="col m-0 p-0">
                  <mat-form-field>
                    <mat-label i18n="rent date range picker placeholder">Rent period</mat-label>
                    <mat-date-range-input [dateFilter]="availabilityFilter" [max]="maxDate" [min]="minDate" [rangePicker]="picker">
                      <input formControlName="dateFrom"
                             i18n-placeholder="rent date range picker rent from placeholder" id="dateFrom" matStartDate
                             placeholder="Rent from">
                      <input formControlName="dateUntil"
                             i18n-placeholder="rent date range picker rent until placeholder" id="dateUntil" matEndDate
                             placeholder="Rent until">
                    </mat-date-range-input>
                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                </div>
                <div class="col m-0 p-0">
                  <div class="material-time-picker">
                    <mat-form-field>
                      <mat-label i18n="pick-up time">Pick-up time</mat-label>
                      <input [format]="timePickerFormat" [ngxTimepicker]="timeFromPicker" formControlName="timeFrom" matInput readonly>
                      <ngx-material-timepicker #timeFromPicker [minutesGap]="5"></ngx-material-timepicker>
                      <ngx-material-timepicker-toggle [for]="timeFromPicker" matSuffix><span class="material-icons">schedule</span>
                      </ngx-material-timepicker-toggle>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col m-0 p-0">
                  <div class="material-time-picker">
                    <mat-form-field>
                      <mat-label i18n="return time">Return time</mat-label>
                      <input [format]="timePickerFormat" [ngxTimepicker]="timeUntilPicker" formControlName="timeUntil" matInput readonly>
                      <ngx-material-timepicker #timeUntilPicker [minutesGap]="5"></ngx-material-timepicker>
                      <ngx-material-timepicker-toggle [for]="timeUntilPicker" matSuffix><span class="material-icons">schedule</span>
                      </ngx-material-timepicker-toggle>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <app-listing-date-range-validation-messages [bookForm]="bookForm" [dateRangeInvalidDates]="dateRangeInvalidDates" [listing]="listing"
                                                        [rentParams]="rentParams"></app-listing-date-range-validation-messages>


            <app-booking-price-table [bookForm]="bookForm" [currencyId]="currencyId" [rentParams]="rentParams"></app-booking-price-table>

            <mat-form-field class="mat-form-textarea-w-100">
              <mat-label i18n="message">Message</mat-label>
              <textarea #messageRef
                        (keyup)="onMessageKeyUp($event)" cdkAutosizeMaxRows="25" cdkAutosizeMinRows="2" cdkTextareaAutosize
                        formControlName="bookingMessage" i18n-placeholder="message placeholder" matInput
                        maxlength="{{messageMaxLength}}"
                        placeholder="Add a message to the lender." tabindex="3"></textarea>
              <span class="character-counter">{{messageRef.value.length}}/{{messageMaxLength}}</span>
            </mat-form-field>

            <br>
            <a class="mb-3" href="{{tocDownloadLink}}" i18n-href="terms and conditions download link" mat-raised-button target="toc">
              <mat-icon aria-hidden="false" class="mr-1">download</mat-icon>
              <span class="breaking-text" i18n="Download terms and conditions">Download terms and conditions</span>
            </a>

            <br>
            <mat-checkbox #tocAccepted color="primary" i18n required>I have read and accept the terms and conditions.</mat-checkbox>
            <br>

            <!-- Error message - booking own listing -->
            <div *ngIf="listing && listing.lenderUid === user.uid" class="alert alert-danger" i18n="booking own listing error message"
                 role="alert">
              You cannot book your own listing.
            </div>

            <!-- Other error messages -->
            <div *ngFor="let error of errors" class="alert alert-danger" role="alert">{{error}}</div>

            <app-loading-spinner *ngIf="bookingInProgress" [centered]="true"></app-loading-spinner>

            <button (click)="onBook()"
                    [disabled]="bookingInProgress || !bookForm?.valid || listing.lenderUid === user.uid|| !tocAccepted.checked"
                    class="btn btn-primary w-100" i18n="confirm booking button">Confirm booking
            </button>
          </form>
        </div>
      </div>

    </div>
  </div>
</div>
