import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {UserService} from '../../services/user.service';
import {ReportType} from '../../models/reportType.type';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-report-button',
  templateUrl: './report-button.component.html',
  styleUrls: ['./report-button.component.scss'],
})
export class ReportButtonComponent extends BaseComponent implements OnInit {

  @Input() type?: ReportType;
  @Input() uid?: string;
  @Input() linkText: string = $localize`:report verb:Report`;
  @Input() appearance: 'button' | 'link' = 'link';

  constructor(
      protected store: Store<AppState>,
      userService: UserService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


}
