import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-currency-shown',
  templateUrl: './no-currency-shown.component.html',
  styleUrls: ['./no-currency-shown.component.scss']
})
export class NoCurrencyShownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
