import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import {CurLangStrings, Currency} from '../models/currency.interface';
import {environment} from '../../../environments/environment';
import Locale from './locale';
import {Wrapper} from '../models/wrapper.model';
import {takeUntil} from 'rxjs/operators';
import {firestore} from '../../app.module';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {convertToCurrency} from '../converters/modelConverters';
import {addCurrenciesToCache, addCurrencyToCache, clearCurrenciesCache, setCurrencies} from '../store/shared.actions';
import {Mutex} from 'async-mutex';
import {CurrenciesWrapper, selectCurrencies} from '../store/shared.selectors';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService implements OnDestroy {
  destroy$: Subject<null> = new Subject();

  currencyMutex = new Mutex();
  currencies: Currency[] = [];
  currenciesById: Map<string, Currency> = new Map<string, Currency>();
  onCurrencySaved$ = new Subject<Currency>();
  onCurrencyDeleted$ = new Subject<string>();
  onCurrencySelected$ = new Subject<string | undefined>();
  private lastFetch?: Date;

  currencies$ = this.store.select(selectCurrencies).pipe(takeUntil(this.destroy$));

  constructor(private store: Store<fromApp.AppState>) {
    this.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }


  /**
   * Delivers all currencies (as a tree) either from the cache, if there is an up-to-date cache, or from the firestore.
   */
  async getCurrencies(): Promise<Wrapper<Currency[]>> {
    return this.currencyMutex.runExclusive(async () => {
      if (!this.currencies || this.currencies.length === 0 || this.cacheIsOutdated()) {
        // Fetch live currencies
        const wrapper = await this.fetchCurrencies();
        if (wrapper.errorMessage) {
          console.log(`Error loading currencies: ${wrapper.errorMessage}`);
        }
        if (wrapper.data) {
          this.currencies = wrapper.data;
          this.store.dispatch(setCurrencies({currencies: this.currencies, currenciesFetchDate: new Date()}));
        }
        return wrapper;
      }

      // Return currencies from cache
      const cacheWrapper: Wrapper<Currency[]> = {data: this.currencies};
      return cacheWrapper;
    });
  }

  /**
   * Delivers all currencies as a linear map either from the cache, if there is an up-to-date cache, or from the firestore.
   */
  async getCurrenciesById(): Promise<Wrapper<Map<string, Currency>>> {
    return this.currencyMutex.runExclusive(async () => {
      if (!this.currenciesById || this.currenciesById.size === 0 || this.cacheIsOutdated()) {
        // Fetch live currencies
        const wrapper = await this.getCurrencies();
        if (wrapper.data) {
          this.currenciesById = this.createCurrenciesMap(wrapper.data);
          return {data: this.currenciesById};
        } else if (wrapper.errorMessage)
          return {errorMessage: wrapper.errorMessage};
      }

      // Return currencies from cache
      const cacheWrapper: Wrapper<Map<string, Currency>> = {data: this.currenciesById};
      return cacheWrapper;
    });
  }

  /**
   * Delivers the currency lang string name0 from the given currency. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param currency currency, from and for which the lang strings should be delivered
   * @return language string name0
   */
  getCurrencyName0(currency: Currency): string {
    const strings = this.getCurrencyLangStrings(currency);
    return strings.name0;
  }

  /**
   * Delivers the currency lang string name1 from the given currency. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param currency currency, from and for which the lang strings should be delivered
   * @return language string name1
   */
  getCurrencyName1(currency: Currency): string {
    const strings = this.getCurrencyLangStrings(currency);
    return strings.name1;
  }

  /**
   * Delivers the currency lang string nameN from the given currency. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param currency currency, from and for which the lang strings should be delivered
   * @return language string nameN
   */
  getCurrencyNameN(currency: Currency): string {
    const strings = this.getCurrencyLangStrings(currency);
    return strings.nameN;
  }

  /**
   * Delivers the currency lang strings (name0, name1, nameN) from the given currency. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param currency currency, from and for which the lang strings should be delivered
   * @return language strings
   */
  getCurrencyLangStrings(currency: Currency): CurLangStrings {
    const strings = currency.strings[Locale.firestoreLocale()];
    return strings ? strings : currency.strings[environment.defaultFirestoreLocale];
  }


  init(): void {
    this.currencies$.subscribe((currenciesWrapper: CurrenciesWrapper) => {
      if (currenciesWrapper.currencies) {
        this.currencies = currenciesWrapper.currencies;
      }
      if (currenciesWrapper.currenciesFetchDate)
        this.lastFetch = currenciesWrapper.currenciesFetchDate;
    });
  }

  /**
   * Fetches currencies directly from the firestore
   */
  async fetchCurrencies(): Promise<Wrapper<Currency[]>> {
    try {
      const query = firestore.collection(environment.firestoreCollectionCurrencies).withConverter(currencyConverter);
      const currencyQuerySnapshot = await query.get();
      const currencies: Currency[] = [];
      currencyQuerySnapshot.forEach(currencyDocSnapshot => {
        const currency: Currency = convertToCurrency(currencyDocSnapshot.data());
        currencies.push(currency);
      });

      const lastVisible = currencyQuerySnapshot.docs[currencyQuerySnapshot.docs.length - 1];

      return new Wrapper<Currency[]>(currencies, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Currency[]>(undefined, $localize`You are not allowed to view currencies.`);
      return new Wrapper<Currency[]>(undefined, e.message);
    }
  }

  resetCache() {
    this.currenciesById.clear();
    this.store.dispatch(clearCurrenciesCache());
  }

  setCurrencies(currencies: Currency[]) {
    this.currencies = currencies;
    this.currenciesById = this.createCurrenciesMap(currencies);
    this.store.dispatch(setCurrencies({currencies: this.currencies, currenciesFetchDate: new Date()}));
  }

  private cacheIsOutdated() {
    return !this.lastFetch || (new Date().getTime() - this.lastFetch.getTime() > environment.defaultCurrencyCacheAgeInSec * 1000);
  }

  /**
   * Creates a map and adds the given currencies to it.
   * @param currencies currencies to be added to the map.
   * @param currenciesById map of currencies by ID. Key = currencyId, value = currency.
   */
  private createCurrenciesMap(currencies: Currency[]): Map<string, Currency> {
    const currenciesById = new Map<string, Currency>();

    if (currencies)
      for (const currency of currencies) {
        if (currency.id !== undefined)
          currenciesById.set(currency.id, currency);
      }
    return currenciesById;
  }


  deleteCurrency(currencyId: string): Promise<void> {
    return firestore.collection(environment.firestoreCollectionCurrencies).doc(currencyId).delete();
  }

  deleteAllCurrencies(): Promise<void> {
    return this.fetchCurrencies().then(wrapper => {
      return new Promise<void>((resolve, reject) => {
        if (wrapper.errorMessage) {
          reject(wrapper.errorMessage);
        }
        const currencies = wrapper.data;
        if (currencies) {
          if (currencies.length === 0)
            resolve();
          resolve(this.deleteAllCurrenciesRecursively(currencies, 0));
        }
      });
    });
  }

  /**
   * Deletes all the given Currencies starting with the given index. Does it by calling the function over and over again until they're all gone.
   * @param currencies list of Currencies
   * @param index starting index for the currencies array
   */
  private deleteAllCurrenciesRecursively(currencies: Currency[], index: number): Promise<void> {
    const currencyId = currencies[index].id;
    if (!currencyId)
      throw new Error(`currency with index ${index} doesn't have an id.`);
    return this.deleteCurrency(currencyId).then(() => {
          console.log(`Successfully deleted currency with ID ${currencyId}.`);
          if (index < currencies.length - 1)
            return this.deleteAllCurrenciesRecursively(currencies, ++index);
          // We're done
          return new Promise<void>(resolve => resolve());
        },
        reason => {
          return new Promise<void>((resolve, reject) => reject(reason));
        });
  }

  /**
   * Creates a new currency in the backend database.
   */
  insertCurrency(currency: Currency, onSuccessCallback: (currency: Currency) => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionCurrencies).add(currency).then(docRef => {
          currency.id = docRef.id;
          onSuccessCallback(currency);
          this.addCurrencyToCache(currency);
        },
        (error) => onErrorCallback($localize`The currency could not be created\: ${error}`),
    );
  }

  /**
   * Sends the given currency to the backend server. If it has an ID, an existing currency is updated, otherwise a new one is added.
   * @param currencyId ID of the currency to be sent
   * @param currency currency to be sent. Need to be provided, even if a fullCurrency is given
   * @param fullCurrency currency to be written to the cache. This currency is not sent to the firestore
   * @param merge if true, only the fields given in the currency object will be updated. Otherwise, the whole currency object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateCurrency(currencyId: string, currency: Currency, fullCurrency: Currency | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionCurrencies).doc(currencyId).set(currency, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The currency could not be updated\: ${error}`),
    );
    if (merge && !fullCurrency) {
      console.error('updateCurrency called with a merge job without providing a fullCurrency.');
      return;
    }
    this.addCurrencyToCache(merge && fullCurrency ? fullCurrency : currency);
  }

  /**
   * Fetches the currency with the given currencyId from the firestore database.
   */
  async fetchCurrency(id: string, maxAgeInSec: number = environment.defaultCurrencyCacheAgeInSec): Promise<Wrapper<Currency>> {

    return this.currencyMutex.runExclusive(async () => {
      const currencyFromCache = this.currenciesById.get(id);
      if (currencyFromCache !== undefined && this.isCurrencyUpToDate(currencyFromCache, maxAgeInSec))
        return new Wrapper<Currency>(currencyFromCache);

      try {
        const currencyDocSnapshot = await firestore.collection(environment.firestoreCollectionCurrencies).doc(id).withConverter(currencyConverter).get();
        const currency = currencyDocSnapshot.data();
        if (currency) {
          const currencyWithIdAndCacheDate: Currency = {...currency, id: currencyDocSnapshot.id, cacheDate: new Date()};
          this.addCurrencyToCache(currencyWithIdAndCacheDate);
          return new Wrapper<Currency>(currencyWithIdAndCacheDate);
        }
      } catch (e: any) {
        return new Wrapper<Currency>(undefined, e.message);
      }

      // If no currency was found
      return new Wrapper<Currency>(undefined);
    });
  }

  /**
   * Checks, if the given currency is newer then the given max age.
   * @param currency currency  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isCurrencyUpToDate(currency: Currency, maxAgeInSec: any): boolean {
    if (!currency.cacheDate)
      return false;
    const cacheTime = currency.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  private addCurrencyToCache(currency: Currency) {
    if (currency.id)
      this.currenciesById.set(currency.id, currency);
    this.store.dispatch(addCurrencyToCache({currency}));
  }

  private addCurrenciesToCache(currencies: Currency[]) {
    this.currenciesById.clear();
    currencies.forEach(currency => {
      if (currency?.id)
        this.currenciesById.set(currency.id, currency);
    });
    this.store.dispatch(addCurrenciesToCache({currencies}));
  }
}

// Firestore data converter
export const currencyConverter = {
  toFirestore(currency: Currency): Currency {
    return currency;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Currency {
    return convertToCurrency(snapshot.data(options));
  },
};
