<div class="container-fluid bg-container p-0 pt-1">
  <!-- Breadcrumb-->
  <nav *ngIf="path !== undefined" aria-label="breadcrumb" class="mx-1">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="All categories link in breadcrumb" routerLink="..">All categories</a>
      <li *ngFor="let category of path, let i = index" class="breadcrumb-item">
        <a *ngIf="i!==path.length-1" [routerLink]="['..',category.id]" aria-current="page">{{categoryService.getCategoryName(category)}}</a>
        <span *ngIf="i===path.length-1"><app-category-selector (onCategorySelected)="onCategorySelected($event)" [narrowButton]="true"
                                                               [selectedCategory]="category"></app-category-selector></span>
      </li>
    </ol>
  </nav>

  <!-- Jumbotron with category image, name and description-->
  <div *ngIf="category !== undefined">

    <!-- On small screens, show vertical layout (text and button below image) -->
    <div class="jumbotron mb-3 mx-1 d-block d-md-none">

      <app-img-with-blurry-background *ngIf="category.imgUrlFull"
                                      [src]="category.imgUrlFull"
                                      alt="{{categoryService.getCategoryName(category)}} image"
                                      i18n-alt="Category image alternative text"
                                      default="./assets/images/default/default-category-w300.webp"
                                      [maxHeight]="300"
      ></app-img-with-blurry-background>
      <ng-container *ngTemplateOutlet="jumbotronContent"></ng-container>
    </div>


    <!-- On larger screens, show horizontal layout (text and button next to image) -->
    <div class="card mb-3 mx-1 d-md-block d-none">
      <div class="row no-gutters">
        <div>
          <app-img-with-blurry-background *ngIf="category.imgUrlFull"
                                          [src]="category.imgUrlFull"
                                          alt="{{categoryService.getCategoryName(category)}} image"
                                          i18n-alt="Category image alternative text"
                                          default="./assets/images/default/default-category-w300.webp"
                                          [height]="300"
          ></app-img-with-blurry-background>
        </div>
        <div class="col">
          <div class="card-body">
            <ng-container *ngTemplateOutlet="jumbotronContent"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <ng-template #jumbotronContent>
      <h1 class="jumbotron-headline">{{categoryService.getCategoryName(category)}}</h1>
      <p class="jumbotron-lead">{{categoryService.getCategoryDescription(category)}}</p>
      <p class="jumbotron-lead">
        <a [queryParams]="{categoryId: categoryId}"
           class="btn btn-primary btn-lg"
           i18n="button to list item of specific category|This is a button, which is shown on the overview page for an item of a specific category to list such a button"
           role="button" routerLink="/listing/create">List your own {{categoryService.getCategoryElementName(category)}} for rent!</a>
      </p>
      <div *ngIf="user?.admin" class="row justify-content-start">
        <div class="col-md-4 mb-3 mx-0">
          <div class="alert alert-primary"><a [routerLink]="['/admin','categories']"
                                              i18n="Edit categories">
            Edit categories</a></div>
        </div>
      </div>

      <div *ngIf="categoryService.getSubcategories(category) as subcategories">
        <mat-expansion-panel (closed)="subcategoriesShown = false" (opened)="subcategoriesShown=true" *ngIf="subcategories.length > 0"
                             [expanded]="subcategoriesShown"
                             class="mat-expansion-panel-without-padding">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span *ngIf="!subcategoriesShown" class="btn toggle-subcategories-button" i18n>Show subcategories</span>
              <span *ngIf="subcategoriesShown" class="btn toggle-subcategories-button" i18n>Hide subcategories</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="d-xs-block d-none">
            <!-- On screens wider than 432px width -->
            <app-all-categories-inner-card-group [categories]="subcategories"></app-all-categories-inner-card-group>
          </div>

          <div class="d-block d-xs-none">
            <!-- On very small screens up to 432px width -->
            <app-category-list-item [category]="category" [skipParent]="true"></app-category-list-item>
          </div>

        </mat-expansion-panel>
      </div>
    </ng-template>
  </div>

  <div class="d-none d-lg-block">
    <app-search-panel [category]="category" [navigateToCategoryOnCategorySelection]="true" [navigateToSearchPage]="false"
                      styleClass="row jumbotron px-0 m-1 py-2"></app-search-panel>
  </div>

  <!-- Show error, if no listings could be loaded for this category -->
  <div class="px-1 pb-3">

    <div *ngIf="error" class="alert alert-danger" i18n="category listings error message" role="alert">
      No listings could be loaded for this category: {{error}}
    </div>

    <!-- Show listings for this category -->
    <app-listings-view (loadMoreListings)="loadMoreListings($event)" [infiniteScrollPercentage]="90" [listings]="listings" [periodInDays]="periodInDays"
                       [showMyListingMarker]="true" [thereIsMore]="thereIsMore" [totalCount]="totalCount"></app-listings-view>
    <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true"></app-loading-spinner>

  </div>

</div>
