import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {LayoutService} from '../../layout.service';
import {AppState} from '../../../store/app.reducer';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
})
export class AnnouncementsComponent extends BaseComponent implements OnInit {

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private layoutService: LayoutService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.layoutService.fetchCachedAnnouncements(announcements => {
      announcements.forEach((announcement) => {
        this.addInfo(announcement.message);
      });
    }, error => console.log(`Error loading announcements: ${error}`))

    ;
  }
}
