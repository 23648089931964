<div class="container bg-container p-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Admin" routerLink="..">Admin</a>
      <li class="breadcrumb-item">
        <span i18n="reports headline">Reports</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="reports headline">Reports</h1>
    <p class="jumbotron-lead" i18n="reports lead text">View and manage your reports.</p>
  </div>


  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [message]="loadingSpinnerMessage"
                       [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>
  <app-alerts [alerts]="alerts" [float]="true" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">dashboard</mat-icon>
        <span i18n>Listings</span>
      </ng-template>

      <app-mat-icon-button [callback]="refreshListing.bind(this)" [params]="['listing']" color="primary" cssClass="my-3"
                           i18n-label="button label refresh" icon="refresh"
                           label="Refresh"></app-mat-icon-button>

      <app-reports-view (loadMoreReports)="loadMoreReports($event, 'listing')" [infiniteScrollPercentage]="90" [reports]="reportsListing"
                        [thereIsMore]="thereIsMoreListing"
                        [type]="'listing'"></app-reports-view>

      <p *ngIf="reportsListing" class="mx-1" i18n="message shown reports count and total reports count">Showing {{reportsListing.length}} reports.</p>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">star</mat-icon>
        <span i18n>Ratings</span>
      </ng-template>

      <app-mat-icon-button [callback]="refreshRating.bind(this)" [params]="['rating']" color="primary" cssClass="my-3"
                           i18n-label="button label refresh" icon="refresh"
                           label="Refresh"></app-mat-icon-button>

      <app-reports-view (loadMoreReports)="loadMoreReports($event, 'rating')" [infiniteScrollPercentage]="90" [reports]="reportsRating"
                        [thereIsMore]="thereIsMoreRating"
                        [type]="'rating'"></app-reports-view>

      <p *ngIf="reportsRating" class="mx-1" i18n="message shown reports count and total reports count">Showing {{reportsRating.length}} reports.</p>

    </mat-tab>
  </mat-tab-group>
</div>
