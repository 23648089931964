import {BehaviorSubject, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import {
  fetchLatestListingsFromAlgolia,
  fetchMoreSearchResultsFromAlgolia,
  fetchSearchResultsFromAlgolia,
  resetSearch,
  runCurrentSearch,
  setDateAndTimePeriod,
  setSearchCategory,
  setSearchSortIndex,
  updateSearchTerm,
} from './store/browser.actions';
import {Router} from '@angular/router';
import {Search} from '../shared/models/search.interface';
import {Injectable} from '@angular/core';
import {Category} from '../shared/models/category.interface';
import {Listing} from '../shared/models/listing.interface';
import Util from '../shared/util';
import {Coordinates} from '../shared/models/coordinates.interface';


@Injectable({
  providedIn: 'root',
})
export class BrowserService {

  selectedCategorySubject = new Subject<Category | undefined>();
  /**
   * Subject, which emits a new value (the book period), whenever a book period is selected in the search panel.
   */
  public periodInDaysSubject = new BehaviorSubject<number>(1);
  /**
   * Subject, which emits a new value (the coords), whenever a new location is selected in the search panel.
   */
  public coordsSubject = new BehaviorSubject<Coordinates | undefined>(undefined);

  constructor(private store: Store<fromApp.AppState>, private router: Router) {
  }

  /**
   * Creates a prefix consisting of depth times '<' with a leading and ending space.
   * @param char character to be used in the prefix
   * @param depth number of '<'
   */
  private static createPrefix(char: string, depth: number): string {
    let prefix = ' ';
    for (let i = 0; i < depth; i++) {
      prefix += char;
    }
    prefix += ' ';
    return prefix;
  }


  /**
   * Resets the search. Clearing all previous search results
   */
  resetSearch(): void {
    this.store.dispatch(resetSearch());
  }

  /**
   * Updates the searchTerm in the store.
   * @param searchTerm searchTerm to be written into the store
   */
  updateSearchTerm(searchTerm?: string): void {
    this.store.dispatch(updateSearchTerm({searchTerm}));
  }

  /**
   * Starts the search using the store.
   * @param search search to be sent to the server
   */
  fetchSearchResults(search: Search): void {
    this.store.dispatch(fetchSearchResultsFromAlgolia({search}));
  }

  /**
   * Continues the search using the store.
   * @param search search to be sent to the server
   */
  fetchMoreSearchResults(): void {
    this.store.dispatch(fetchMoreSearchResultsFromAlgolia());
  }

  /**
   * Navigates to the search page
   */
  navigateToSearch(): void {
    this.router.navigateByUrl('/search');
  }


  fetchLatestListings(categoryId?: string): void {
    this.store.dispatch(fetchLatestListingsFromAlgolia({categoryId}));
  }

  setSearchCategory(categoryId?: string): void {
    this.store.dispatch(setSearchCategory({categoryId}));

  }

  runCurrentSearch(): void {
    this.store.dispatch(runCurrentSearch());
  }


  getPeriodPrice(listing: Listing, periodInDays: number): number | undefined {
    const bestPrice = Util.findBestRentPrice(listing?.prices, periodInDays);
    return bestPrice ? bestPrice.pricePerDay * periodInDays : undefined;
  }

  getMinRentPeriod(listing: Listing): number | undefined {
    let minRentPeriod = undefined;
    if (listing.prices?.length) {
      minRentPeriod = listing.prices[0].minDays;
      for (let i = 1; i < listing.prices.length; i++) {
        const price = listing.prices[i];
        if (minRentPeriod > price.minDays) {
          minRentPeriod = price.minDays;
          break;
        }
      }
    }
    return minRentPeriod;
  }

  /**
   * Sets the search sort index name (for Algolia)
   * @param sortIndex algolia index name for a sort replica. At time of writing this, there are the following:
   * dev_listings_lastEditDate_desc
   * dev_listings_creationDate_desc
   * dev_listings_price_asc
   * dev_listings_price_desc
   * prod_listings_lastEditDate_desc
   * prod_listings_creationDate_desc
   * prod_listings_price_asc
   * prod_listings_price_desc
   */
  setSearchSortIndex(sortIndex: string) {
    this.store.dispatch(setSearchSortIndex({sortIndex}));
  }

  /**
   * Sets the given rent period in the search state
   * @param dateFrom start date
   * @param dateUntil end date
   */
  setDateAndTimePeriod(dateFrom: Date, dateUntil: Date, timeFrom?: string, timeUntil?: string) {
    this.store.dispatch(setDateAndTimePeriod({dateFrom, dateUntil, timeFrom, timeUntil}));
  }

  /**
   * Translates the given order term into an Algolia sortIndex suffix.
   * @param order order term string, e.g. price_asc, lastEditDate_desc
   * @return suffix for an algolia search index, e.g. lastEditDate_desc
   */
  translateOrder(order: string): string {
    if (order === 'bestMatch')
      return '';
    return order;
  }

  /**
   * Unsets the search category in the store
   */
  unsetSearchCategory() {
    this.store.dispatch(setSearchCategory({categoryId: undefined}));
  }
}
