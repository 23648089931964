import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {RentParams} from '../../models/rentParams.interface';
import {Listing} from '../../models/listing.interface';
import {BookingPeriod} from '../../models/bookingPeriodMap.interface';
import Util from '../../util';
import firebase from 'firebase/app';
import {UtilService} from '../../util.service';
import Locale from '../../services/locale';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-listing-date-range-validation-messages',
  templateUrl: './listing-date-range-validation-messages.component.html',
  styleUrls: ['./listing-date-range-validation-messages.component.css'],
})
export class ListingDateRangeValidationMessagesComponent implements OnInit {

  @Input() bookForm?: FormGroup;
  @Input() dateRangeInvalidDates: Date[] = [];
  @Input() rentParams: RentParams = {};
  @Input() listing?: Listing;
  @Input() transactionUid?: string;

  numberFormatLocale = Locale.numberFormatLocale();

  constructor(public utilService: UtilService) {
  }

  ngOnInit(): void {
  }

  /**
   * Creates a list of conflicting booking periods for the given rent params (i.e. dateFrom and aateUntil)
   * @param rentParams rent params containing dateFrom and dateUntil. If either one is missing, an empty array is returned.
   * @param listing listing containing a bookingPeriodMap. If it's missing, an empty array is returned.
   * @return list of conflicting booking periods for the given rent params or empty array
   */
  getConflictingBookingRanges(rentParams: RentParams, listing: Listing): BookingPeriod[] {
    const conflictingBookingPeriods: BookingPeriod[] = [];
    if (!listing.bookingPeriodMap || !rentParams.dateFrom || !rentParams.dateUntil)
      return conflictingBookingPeriods;

    for (let entry of Object.entries(listing.bookingPeriodMap)) {
      if (entry[0] === this.transactionUid)
        // Skip the booking range of the current transaction
        continue;
      const bookingFrom = Util.getDate(entry[1].dateFrom);
      const bookingUntil = Util.getDate(entry[1].dateUntil);

      if (Util.isBetweenOrEquals(rentParams.dateFrom!, bookingFrom, bookingUntil) || Util.isBetweenOrEquals(rentParams.dateUntil!, bookingFrom, bookingUntil)) {
        conflictingBookingPeriods.push({dateFrom: Timestamp.fromDate(bookingFrom), dateUntil: Timestamp.fromDate(bookingUntil)});
      }
    }
    return conflictingBookingPeriods;
  }
}
