<app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
<form (ngSubmit)="onSubmit()" *ngIf="searchForm" [formGroup]="searchForm">
  <div [ngClass]="styleClass">
    <!-- Search term -->
    <div class="col-auto">
      <mat-form-field appearance="fill">
        <mat-label i18n="search">Search</mat-label>
        <input (change)="syncSearchTerm()" (keyup)="syncSearchTerm()" (paste)="syncSearchTerm()"
               (search)="syncSearchTerm()" formControlName="searchTerm" id="searchTerm" matInput
               placeholder="bike, drill, wedding dress, ..."
               type="search">
      </mat-form-field>
    </div>

    <!-- Location -->
    <div class="col-auto">
      <mat-form-field
        [class]="location.value && location.value !== formattedAddress && searchForm.controls['location'].touched ? 'mat-form-field-invalid' : '' "
        appearance="fill"
        class="min-w-350px">
        <mat-label i18n="location">Location</mat-label>
        <input #location [value]="address" autocapitalize="off" autocorrect="off" formControlName="location" i18n-placeholder="search address" id="location"
               matInput placeholder="Search address" spellcheck="off" type="text">
        <mat-icon (click)="searchForm.patchValue({location: ''})" *ngIf="location.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear location" type="button">close
        </mat-icon>
        <mat-icon (click)="loadCurrentLocation()" *ngIf="!location.value" aria-label="Clear"
                  i18n-matTooltip="use current location button in search panel's location field"
                  matSuffix matTooltip="Use current location" type="button">
          gps_fixed
        </mat-icon>
        <div *ngIf="location.value && location.value !== formattedAddress && searchForm.controls['location'].touched" class="m-0">
          <br>
          <small class="text-red mt-0 mb-0" i18n="error address not matching 3">Enter an address and select an auto-complete option.</small>
        </div>
      </mat-form-field>
    </div>

    <!-- Radius -->
    <div *ngIf="location.value" class="col-auto">
      <mat-form-field appearance="fill" class="w-130px">
        <mat-label i18n="radius">Radius</mat-label>
        <input [appRange]="{min: 1, max: rangeMaxValue}" class="min-w-75px" formControlName="radius" id="radius" matInput max="{{rangeMaxValue}}" min="1"
               placeholder="5"
               type="number">
        <mat-error *ngIf="searchForm?.get('radius')?.hasError('appRange')" i18n="error message not in range radius">Radius must
          be 1 - {{rangeMaxValue}}.
        </mat-error>
        <span matSuffix>&nbsp;km</span>
      </mat-form-field>
    </div>

    <!-- Rent period -->
    <div class="col-auto">
      <mat-form-field appearance="fill">
        <mat-label i18n="rent date range picker placeholder">Rent period</mat-label>
        <mat-date-range-input [min]="minDate" [rangePicker]="picker">
          <input (dateChange)="onDateRangeChanged($event.value)" formControlName="dateFrom" i18n-placeholder="rent date range picker rent from placeholder"
                 id="dateFrom"
                 matStartDate placeholder="Rent from">
          <input (dateChange)="onDateRangeChanged($event.value)" formControlName="dateUntil" i18n-placeholder="rent date range picker rent until placeholder"
                 id="dateUntil"
                 matEndDate placeholder="Rent until">
        </mat-date-range-input>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

    </div>

    <!--Order by -->
    <div class="col-auto">
      <mat-form-field appearance="fill">
        <mat-label i18n>Order by</mat-label>
        <mat-select (selectionChange)="onOrderChange($event)" formControlName="order" id="order" panelClass="mat-select-panel-search">
          <mat-option i18n="search order best match" value="default">Best match</mat-option>
          <mat-option i18n="search order lowestPrice-asc" value="price_asc">Lowest price first</mat-option>
          <mat-option i18n="search order highestPrice-desc" value="price_desc">Highest price first</mat-option>
          <mat-option i18n="search order creationDate-desc" value="creationDate_desc">Recently created</mat-option>
          <mat-option i18n="search order lastEditDate-desc" value="lastEditDate_desc">Recently updated</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Category selector -->
    <div class="col-auto">
      <app-category-selector (onCategorySelected)="onCategoryChange($event)" [bigButton]="true" [selectedCategory]="category" [showAllCategories]="true"
                             buttonColor="default"></app-category-selector>
    </div>

    <!-- Search button -->
    <div class="col-auto">
      <button aria-label="Example icon button with a delete icon" class="btn btn-primary" color="primary"
              id="searchButton" mat-fab type="submit">
        <svg class="bi bi-search" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>
      </button>
    </div>

  </div>
</form>
