<div class="container bg-container p-1">
  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="admin headline">Admin</h1>
    <p class="jumbotron-lead" i18n="admin lead text">Manage Blitzshare: Edit categories, ban users, etc.</p>
  </div>

  <div class="card-deck">

    <div class="card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['..','reports']" class="stretched-link">
            <span class="material-icons card-icon text-primary">report</span>
          </a>
        </div>
        <div class="col">
          <div class="card-body">
            <a [routerLink]="['..','reports']" class="stretched-link">
              <h2 class="paragraph-headline-2" i18n="categories headline">Reports</h2>
              <p class="card-text" i18n="categories edit lead text">View and manage reports about inappropriate user content.</p>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['..','categories']" class="stretched-link">
            <span class="material-icons card-icon text-primary">category</span>
          </a>
        </div>
        <div class="col">
          <div class="card-body">
            <a [routerLink]="['..','categories']" class="stretched-link">
              <h2 class="paragraph-headline-2" i18n="categories headline">Categories</h2>
              <p class="card-text" i18n="categories edit lead text">View and manage categories.</p>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['..','announcements']" class="stretched-link">
            <span class="material-icons card-icon text-primary">campaign</span>
          </a>
        </div>
        <div class="col">
          <div class="card-body">
            <a [routerLink]="['..','announcements']" class="stretched-link">
              <h2 class="paragraph-headline-2" i18n="announcements headline">Announcements</h2>
              <p class="card-text" i18n="announcements lead text">View and manage announcements.</p>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['..','faqs']" class="stretched-link">
            <span class="material-icons card-icon text-primary">contact_support</span>
          </a>
        </div>
        <div class="col">
          <div class="card-body">
            <a [routerLink]="['..','faqs']" class="stretched-link">
              <h2 class="paragraph-headline-2" i18n="announcements headline">FAQs</h2>
              <p class="card-text" i18n="announcements lead text">View and manage FAQs.</p>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['..','users']" class="stretched-link">
            <span class="material-icons card-icon text-primary">people</span>
          </a>
        </div>
        <div class="col">
          <div class="card-body">
            <a [routerLink]="['..','users']" class="stretched-link">
              <h2 class="paragraph-headline-2" i18n="users headline">Users</h2>
              <p class="card-text" i18n="users lead text">View and manage users.</p>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['..','tools']" class="stretched-link">
            <span class="material-icons card-icon text-primary">carpenter</span>
          </a>
        </div>
        <div class="col">
          <div class="card-body">
            <a [routerLink]="['..','tools']" class="stretched-link">
              <h2 class="paragraph-headline-2" i18n="tools headline">Tools</h2>
              <p class="card-text" i18n="tools lead text">Miscellaneous admin tools. Use with cautions</p>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['..','currencies']" class="stretched-link">
            <span class="material-icons card-icon text-primary">euro</span>
          </a>
        </div>
        <div class="col">
          <div class="card-body">
            <a [routerLink]="['..','currencies']" class="stretched-link">
              <h2 class="paragraph-headline-2" i18n="Currencies headline">Currencies</h2>
              <p class="card-text" i18n="Currencies lead text">View and manage currencies.</p>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
