import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {TitleService} from '../../shared/services/title.service';
import {User} from '../../shared/models/user.interface';
import {AccountService} from '../account.service';
import {Referral} from '../../shared/models/referral.interface';
import {environment} from '../../../environments/environment';
import Locale from '../../shared/services/locale';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UtilService} from '../../shared/util.service';
import Util from '../../shared/util';
import {UserService} from '../../shared/services/user.service';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss'],
})
export class ReferralComponent extends BaseComponent implements OnInit {
  maxReferralsCount = environment.defaultLoadReferralsCount;
  referrals: Referral[] = [];
  referralLinkNameMaxLength = environment.referralLinkNameMaxLength;

  referralForm!: FormGroup;

  numberFormatLocale = Locale.numberFormatLocale();
  referreesCountByReferralUid: Map<string, number> = new Map<string, number>();
  editReferral?: Referral;
  private readonly spinnerKeyCreateReferral = 'createReferral';
  private readonly spinnerKeyUpdateReferral = 'updateReferral';
  private readonly spinnerKeyDeleteReferral = 'deleteReferral';

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private titleService: TitleService,
      public utilService: UtilService,
      private formBuilder: FormBuilder,
      private accountService: AccountService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Referral program`);

    this.referralForm = this.createReferralForm();

    this.user$.subscribe(user => {
      if (!user)
        return;
      this.user = user;
      this.loadReferrals(user);
    });
  }

  onCreateReferralClick(name: string): void {
    this.clearAlerts();

    if (!this.referralNameIsValid(name))
      return;

    const referrerUid = this.user?.uid;
    if (!referrerUid) {
      this.addError($localize`You need to login first.`);
      return;
    }

    const referral: Referral = {name: name, provisionPercentage: environment.defaultReferralProvision, referrerUid};
    this.addLoadingSpinnerMessage(this.spinnerKeyCreateReferral, $localize`Creating referral link...`);
    this.accountService.createReferral(referral, insertedReferral => {
      this.addSuccess($localize`Successfully created referral link ${this.getReferralLink(name)}`);
      this.referrals.push(insertedReferral);
      this.removeLoadingSpinnerMessage(this.spinnerKeyCreateReferral);
      this.referreesCountByReferralUid.set(insertedReferral.uid!, 0);
    }, error => {
      this.addError($localize`Error creating the referral link: ${error}`);
      this.removeLoadingSpinnerMessage(this.spinnerKeyCreateReferral);
    });
  }

  onEditReferralClick(referralUid: string): void {
    this.editReferral = this.referrals.find(referral => referral.uid === referralUid);
    if (!referralUid)
      return;
    this.utilService.scrollToId('edit-referral');
  }

  /**
   * Creates a ref link for the given ref name.
   * @param name ref name
   */
  getReferralLink(name: string) {
    return Util.getReferralLink(name);
  }

  /**
   * Called, when a referral was edited by edit-referral-component. Saved the changes into the database.
   * @param editedReferral
   */
  onEditReferral(editedReferral: Referral) {
    this.clearAlerts();

    if (!this.referralNameIsValid((editedReferral.name)))
      return;

    const referrerUid = this.user?.uid;
    if (!referrerUid) {
      this.addError($localize`You need to login first.`);
      return;
    }

    if (referrerUid !== editedReferral.referrerUid) {
      this.addError($localize`You can only edit your own referral links.`);
      return;
    }

    this.addLoadingSpinnerMessage(this.spinnerKeyUpdateReferral, $localize`Updating referral link...`);
    this.accountService.updateReferral(editedReferral, updatedReferral => {
      this.addSuccess($localize`Successfully updated referral link ${this.getReferralLink(updatedReferral.name)}`);
      this.referrals.push(updatedReferral);
      this.removeLoadingSpinnerMessage(this.spinnerKeyUpdateReferral);
      this.referreesCountByReferralUid.set(updatedReferral.uid!, 0);

      this.editReferral = undefined;
      const otherReferrals = this.referrals.filter(referral => referral.uid !== updatedReferral.uid);
      otherReferrals.push(updatedReferral);
      this.referrals = otherReferrals;
      this.utilService.scrollToId('my-referral');
      this.editReferral = undefined;
    }, error => {
      this.addError($localize`Error updating the referral link: ${error}`);
      this.removeLoadingSpinnerMessage(this.spinnerKeyUpdateReferral);
    });


  }

  onDeleteReferral(referralToDelete: Referral): void {
    this.clearAlerts();
    if (!referralToDelete?.uid)
      return;
    9;

    this.addLoadingSpinnerMessage(this.spinnerKeyDeleteReferral, $localize`Deleting referral link...`);
    this.accountService.deleteReferral(referralToDelete.uid).then(() => {
          this.addSuccess($localize`The referral link ${referralToDelete.name} was deleted successfully.`);
          this.removeLoadingSpinnerMessage(this.spinnerKeyDeleteReferral);
          this.editReferral = undefined;
          const otherReferrals = this.referrals.filter(referral => referral.uid !== referralToDelete.uid);
          this.referrals = otherReferrals;
        },
        reason => {
          this.addError($localize`The referral link ${referralToDelete.name} could not be deleted. ${reason}`);
          this.removeLoadingSpinnerMessage(this.spinnerKeyDeleteReferral);
        });

  }

  getDate(date: any): Date {
    return Util.getDate(date);
  }

  /**
   * Loads referrals for the given user
   * @param user user, for whom referrals should be loaded
   */
  private loadReferrals(user: User) {
    this.accountService.fetchReferrals(user.uid).then(wrapper => {
      if (wrapper.data) {
        this.referrals = wrapper.data;
        this.loadReferreeCounts();
      }
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  private createReferralForm(): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
      referralName: [null],
    });

  }

  private loadReferreeCounts() {
    this.referreesCountByReferralUid.clear();

    if (!this.referrals || this.referrals.length === 0)
      return;

    this.referrals.forEach(referral => {
      if (!referral?.uid)
        return;
      this.accountService.getReferreesCount(referral.uid,
          count => this.referreesCountByReferralUid.set(referral.uid!, count),
          error => this.addError($localize`Error loading the number of recruited users for referral name ${referral.name}: Error: ${error}`));
    });

  }

  /**
   * Checks, if the given name is a valid referral name. This function does not check, if the name is already taken.
   * @param name name to be checked
   * @return true, if valid. false if invalid
   */
  private referralNameIsValid(name: string): boolean {
    if (name.trim().length < environment.referralLinkNameMinLength) {
      this.addError($localize`Your referral link is too short. Please enter at least ${environment.referralLinkNameMinLength} characters.`);
      return false;
    }
    if (name.trim().length > this.referralLinkNameMaxLength) {
      this.addError($localize`Your referral link is too long. Only ${this.referralLinkNameMaxLength} characters are allowed.`);
      return false;
    }
    // check alphanumeric
    if (!/^[a-zA-Z0-9]+$/.test(name)) {
      this.addError($localize`Your referral link contains invalid characters. Only letters a-z and digits are allowed.`);
      return false;
    }
    return true;
  }
}
