import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Wrapper} from '../shared/models/wrapper.model';
import {Announcement} from '../shared/models/announcement.interface';
import firebase from 'firebase/app';

import {Subject} from 'rxjs';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Store} from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import {take, takeUntil} from 'rxjs/operators';
import {convertToAnnouncement, convertToFaq} from '../shared/converters/modelConverters';
import {
  addAnnouncementsToCache,
  addAnnouncementToCache,
  addFaqsToCache,
  addFaqToCache,
  clearAnnouncementsCache,
  clearFaqsCache,
  setAnnouncementsLastFetch,
  setFaqsLastFetch,
  setRefName,
} from './store/layout.actions';
import {Faq, FaqLangStrings} from '../shared/models/faq.interface';

import {Mutex} from 'async-mutex';
import {firestore} from '../app.module';
import Locale from '../shared/services/locale';
import {AnnouncementsWrapper, FaqsWrapper, selectAnnouncements, selectFaqs} from './store/layout.selectors';
import Timestamp = firebase.firestore.Timestamp;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

function announcementsComparator() {
  return (a: Announcement, b: Announcement) => a.priority - b.priority;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {

  announcementMutex = new Mutex();
  faqMutex = new Mutex();

  destroy$: Subject<null> = new Subject();
  announcementsById: Map<string, Announcement> = new Map<string, Announcement>();
  faqsById: Map<string, Faq> = new Map<string, Faq>();
  onFaqSaved$ = new Subject<Faq>();
  onFaqDeleted$ = new Subject<string>();
  onFaqSelected$ = new Subject<string | undefined>();
  private announcementsLastFetch?: Date;
  private faqsLastFetch?: Date;
  announcements$ = this.store.select(selectAnnouncements).pipe(takeUntil(this.destroy$));
  faqs$ = this.store.select(selectFaqs).pipe(takeUntil(this.destroy$));

  constructor(private store: Store<fromApp.AppState>) {
  }


  fetchCachedAnnouncements(onSuccessCallback: (announcements: Announcement[]) => void, onErrorCallback: (error: string) => void): void {
    this.announcements$.pipe(take(1)).subscribe(async (announcementsWrapper: AnnouncementsWrapper) => {

      if (announcementsWrapper.announcements) {
        this.announcementsById = new Map<string, Announcement>();
        announcementsWrapper.announcements.forEach(announcement => {
          if (announcement.uid)
            this.announcementsById.set(announcement.uid, announcement);
        });
      }
      if (announcementsWrapper.announcementsFetchDate)
        this.announcementsLastFetch = announcementsWrapper.announcementsFetchDate;

      if (!this.announcementsLastFetch || (new Date().getTime() - this.announcementsLastFetch.getTime() > environment.defaultAnnouncementCacheAgeInSec * 1000)) {
        // Fetch live announcements
        const now = Timestamp.now();
        const wrapper = await this.fetchAnnouncements(false, undefined, now, now);
        if (wrapper.errorMessage)
          onErrorCallback(wrapper.errorMessage);
        if (wrapper.data)
          onSuccessCallback(wrapper.data);
        return;
      }
      // Return announcements from cache
      const announcements: Announcement[] = [...this.announcementsById.values()].sort(announcementsComparator());
      onSuccessCallback(announcements);
    });
  }

  async fetchAnnouncements(bypassEnvironment?: boolean, limit: number = environment.defaultLoadAnnouncementsCount, validFrom?: Timestamp, validUntil?: Timestamp, startAfter?: DocumentSnapshot): Promise<Wrapper<Announcement[]>> {
    try {
      let query;
      if (!bypassEnvironment) {
        if (environment.production)
          query = firestore.collection(environment.firestoreCollectionAnnouncements).where('environment', 'in', ['all', 'prod_only']);
        else
          query = firestore.collection(environment.firestoreCollectionAnnouncements).where('environment', 'in', ['all', 'non_prod_only']);
      } else
        query = firestore.collection(environment.firestoreCollectionAnnouncements);

      // If this is not called from the admin panel, we probably want to filter by environment

      query = query.withConverter(announcementConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const announcementQuerySnapshot = await query.get();
      const announcements: Announcement[] = [];
      announcementQuerySnapshot.forEach(announcementDocSnapshot => {
        const announcement: Announcement = convertToAnnouncement(announcementDocSnapshot.data());
        if (announcement) {
          // Firestore does not support two different inequality filters, i.e. we can only filter on validFrom or validUntil, but not on both at the same time
          // Filter by validFrom on client side
          if (validFrom !== undefined && announcement.validFrom !== undefined && announcement.validFrom.toMillis() >= validFrom.toMillis())
            return;
          // Filter by validUntil on client side
          if (validUntil !== undefined && announcement.validUntil !== undefined && announcement.validUntil.toMillis() <= validUntil.toMillis())
            return;

          const announcementWithId = {...announcement, uid: announcementDocSnapshot.id};
          announcements.push(announcementWithId);
          this.addAnnouncementToCache(announcementWithId);
        }
      });
      announcements.sort(announcementsComparator());

      this.addAnnouncementsToCache(announcements);
      const lastVisible = announcementQuerySnapshot.docs[announcementQuerySnapshot.docs.length - 1];

      return new Wrapper<Announcement[]>(announcements, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Announcement[]>(undefined, $localize`You are not allowed to view announcements.`);
      return new Wrapper<Announcement[]>(undefined, e.message);
    }
  }

  deleteAnnouncement(announcementUid: string): Promise<void> {
    return firestore.collection(environment.firestoreCollectionAnnouncements).doc(announcementUid).delete();
  }

  /**
   * Sends the given announcement to the backend server. If it has an ID, an existing announcement is updated, otherwise a new one is added.
   * Subscribe to this.announcementObservable to get the new ID or error message.
   * You can find it at state / announcementId or state / error.
   * @param announcementId ID of the announcement to be sent
   * @param announcement announcement to be sent. Need to be provided, even if a fullAnnouncement is given
   * @param fullAnnouncement announcement to be written to the cache. This announcement is not sent to the firestore
   * @param merge if true, only the fields given in the announcement object will be updated. Otherwise, the whole announcement object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateAnnouncement(announcementId: string, announcement: Announcement, fullAnnouncement: Announcement | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionAnnouncements).doc(announcementId).set(announcement, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The announcement could not be updated\: ${error}`),
    );
    if (merge && !fullAnnouncement) {
      console.error('updateAnnouncement called with a merge job without providing a fullAnnouncement.');
      return;
    }
    this.addAnnouncementToCache(merge && fullAnnouncement ? fullAnnouncement : announcement);
  }

  /**
   * Creates a new announcement in the backend database.
   * Subscribe to this.announcementObservable to get the new ID or error message.
   * You can find it at state / announcementId or state / error.
   */
  insertAnnouncement(announcement: Announcement, onSuccessCallback: (announcement: Announcement) => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionAnnouncements).add(announcement).then(docRef => {
          announcement.uid = docRef.id;
          onSuccessCallback(announcement);
          this.addAnnouncementToCache(announcement);
        },
        (error) => onErrorCallback($localize`The announcement could not be created\: ${error}`),
    );
  }

  /**
   * Fetches the announcement with the given announcementId from the firestore database.
   */
  async fetchAnnouncement(uid: string, maxAgeInSec: number = environment.defaultAnnouncementCacheAgeInSec): Promise<Wrapper<Announcement>> {

    return this.announcementMutex.runExclusive(async () => {
      const announcementFromCache = this.announcementsById.get(uid);
      if (announcementFromCache !== undefined && this.isAnnouncementUpToDate(announcementFromCache, maxAgeInSec))
        return new Wrapper<Announcement>(announcementFromCache);

      try {
        const announcementDocSnapshot = await firestore.collection(environment.firestoreCollectionAnnouncements).doc(uid).withConverter(announcementConverter).get();
        const announcement = announcementDocSnapshot.data();
        if (announcement) {
          const announcementWithIdAndCacheDate: Announcement = {...announcement, uid: announcementDocSnapshot.id, cacheDate: new Date()};
          this.addAnnouncementToCache(announcementWithIdAndCacheDate);
          return new Wrapper<Announcement>(announcementWithIdAndCacheDate);
        }
      } catch (e: any) {
        return new Wrapper<Announcement>(undefined, e.message);
      }

      // If no announcement was found
      return new Wrapper<Announcement>(undefined);
    });
  }

  resetAnnouncementCache() {
    this.announcementsById.clear();
    this.store.dispatch(clearAnnouncementsCache());
  }

  fetchCachedFaqs(onSuccessCallback: (faqs: Faq[]) => void, onErrorCallback: (error: string) => void): void {

    this.faqs$.pipe(take(1)).subscribe(async (faqsWrapper: FaqsWrapper) => {
      if (faqsWrapper.faqs) {
        this.faqsById = new Map<string, Faq>();
        faqsWrapper.faqs.forEach(faq => {
          if (faq.uid)
            this.faqsById.set(faq.uid, faq);
        });
      }
      if (faqsWrapper.faqsFetchDate)
        this.faqsLastFetch = faqsWrapper.faqsFetchDate;

      if (!this.faqsLastFetch || (new Date().getTime() - this.faqsLastFetch.getTime() > environment.defaultFaqCacheAgeInSec * 1000)) {
        // Fetch live faqs
        const now = Timestamp.now();
        const wrapper = await this.fetchFaqs(100);
        if (wrapper.errorMessage)
          onErrorCallback(wrapper.errorMessage);
        if (wrapper.data)
          onSuccessCallback(wrapper.data);
        return;
      }
      // Return faqs from cache
      const faqs: Faq[] = [...this.faqsById.values()];
      onSuccessCallback(faqs);
    });


  }

  async fetchFaqs(limit: number = environment.defaultLoadFaqsCount, startAfter?: DocumentSnapshot): Promise<Wrapper<Faq[]>> {
    try {
      let query = firestore.collection(environment.firestoreCollectionFaqs).orderBy('priority', 'desc');
      const faqQuerySnapshot = await query.get();
      const faqs: Faq[] = [];
      faqQuerySnapshot.forEach(faqDocSnapshot => {
        const faq: Faq = convertToFaq(faqDocSnapshot.data());
        if (faq) {
          const faqWithId = {...faq, uid: faqDocSnapshot.id};
          faqs.push(faqWithId);
        }
      });

      this.addFaqsToCache(faqs);
      const lastVisible = faqQuerySnapshot.docs[faqQuerySnapshot.docs.length - 1];

      return new Wrapper<Faq[]>(faqs, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Faq[]>(undefined, $localize`You are not allowed to view faqs.`);
      return new Wrapper<Faq[]>(undefined, e.message);
    }
  }

  deleteFaq(faqUid: string): Promise<void> {
    return firestore.collection(environment.firestoreCollectionFaqs).doc(faqUid).delete();
  }

  deleteAllFaqs(): Promise<void> {
    return this.fetchFaqs(1000).then(wrapper => {
      return new Promise<void>((resolve, reject) => {
        if (wrapper.errorMessage) {
          reject(wrapper.errorMessage);
        }
        const faqs = wrapper.data;
        if (faqs) {
          if (faqs.length === 0)
            resolve();

          resolve(this.deleteAllFaqsRecursively(faqs, 0));

        }


      });


    });
  }

  /**
   * Sends the given faq to the backend server. If it has an ID, an existing faq is updated, otherwise a new one is added.
   * @param faqId ID of the faq to be sent
   * @param faq faq to be sent. Need to be provided, even if a fullFaq is given
   * @param fullFaq faq to be written to the cache. This faq is not sent to the firestore
   * @param merge if true, only the fields given in the faq object will be updated. Otherwise, the whole faq object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateFaq(faqId: string, faq: Faq, fullFaq: Faq | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionFaqs).doc(faqId).set(faq, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The faq could not be updated\: ${error}`),
    );
    if (merge && !fullFaq) {
      console.error('updateFaq called with a merge job without providing a fullFaq.');
      return;
    }
    this.addFaqToCache(merge && fullFaq ? fullFaq : faq);
  }

  /**
   * Creates a new faq in the backend database.
   */
  insertFaq(faq: Faq, onSuccessCallback: (faq: Faq) => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionFaqs).add(faq).then(docRef => {
          faq.uid = docRef.id;
          onSuccessCallback(faq);
          this.addFaqToCache(faq);
        },
        (error) => onErrorCallback($localize`The faq could not be created\: ${error}`),
    );
  }

  /**
   * Fetches the faq with the given faqId from the firestore database.
   */
  async fetchFaq(uid: string, maxAgeInSec: number = environment.defaultFaqCacheAgeInSec): Promise<Wrapper<Faq>> {

    return this.faqMutex.runExclusive(async () => {
      const faqFromCache = this.faqsById.get(uid);
      if (faqFromCache !== undefined && this.isFaqUpToDate(faqFromCache, maxAgeInSec))
        return new Wrapper<Faq>(faqFromCache);

      try {
        const faqDocSnapshot = await firestore.collection(environment.firestoreCollectionFaqs).doc(uid).withConverter(faqConverter).get();
        const faq = faqDocSnapshot.data();
        if (faq) {
          const faqWithIdAndCacheDate: Faq = {...faq, uid: faqDocSnapshot.id, cacheDate: new Date()};
          this.addFaqToCache(faqWithIdAndCacheDate);
          return new Wrapper<Faq>(faqWithIdAndCacheDate);
        }
      } catch (e: any) {
        return new Wrapper<Faq>(undefined, e.message);
      }

// If no faq was found
      return new Wrapper<Faq>(undefined);
    });
  }

  resetFaqCache() {
    this.faqsById.clear();
    this.store.dispatch(clearFaqsCache());
  }

  getFaqQuestion(faq: Faq) {
    return this.getFaqLangStrings(faq).question;
  }

  getFaqAnswer(faq: Faq) {
    return this.getFaqLangStrings(faq).answer;
  }

  getFaqLangStrings(faq: Faq): FaqLangStrings {
    const strings = faq.strings[Locale.firestoreLocale()];
    return strings ? strings : faq.strings[environment.defaultFirestoreLocale];
  }

  setRefName(refName: string) {
    this.store.dispatch(setRefName({refName}));
  }

  /**
   * Checks, if the given announcement is newer then the given max age.
   * @param announcement announcement  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isAnnouncementUpToDate(announcement: Announcement, maxAgeInSec: any): boolean {
    if (!announcement.cacheDate)
      return false;
    const cacheTime = announcement.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  private addAnnouncementToCache(announcement: Announcement) {
    if (announcement.uid)
      this.announcementsById.set(announcement.uid, announcement);
    this.store.dispatch(addAnnouncementToCache({announcement}));
  }

  private addAnnouncementsToCache(announcements: Announcement[]) {
    this.announcementsById.clear();
    announcements.forEach(announcement => {
      if (announcement?.uid)
        this.announcementsById.set(announcement.uid, announcement);
    });
    this.store.dispatch(addAnnouncementsToCache({announcements}));
  }

  private setAnnouncementsLastFetchDate(announcementsLastFetch: Date) {
    this.store.dispatch(setAnnouncementsLastFetch({announcementsLastFetch: announcementsLastFetch}));
  }

  /**
   * Checks, if the given faq is newer then the given max age.
   * @param faq faq  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isFaqUpToDate(faq: Faq, maxAgeInSec: any): boolean {
    if (!faq.cacheDate)
      return false;
    const cacheTime = faq.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  private addFaqToCache(faq: Faq) {
    if (faq.uid)
      this.faqsById.set(faq.uid, faq);
    this.store.dispatch(addFaqToCache({faq}));
  }

  private addFaqsToCache(faqs: Faq[]) {
    this.faqsById.clear();
    faqs.forEach(faq => {
      if (faq?.uid)
        this.faqsById.set(faq.uid, faq);
    });
    this.store.dispatch(addFaqsToCache({faqs}));
  }

  private setFaqsLastFetchDate(faqsLastFetch: Date) {
    this.store.dispatch(setFaqsLastFetch({faqsLastFetch: faqsLastFetch}));
  }

  /**
   * Deletes all the given FAQs starting with the given index. Does it by calling the function over and over again until they're all gone.
   * @param faqs list of FAQs
   * @param index starting index for the faqs array
   */
  private deleteAllFaqsRecursively(faqs: Faq[], index: number): Promise<void> {
    const faqUid = faqs[index].uid;
    if (!faqUid)
      throw new Error(`faq with index ${index} doesn't have a uid.`);
    return this.deleteFaq(faqUid).then(() => {
          console.log(`Successfully deleted FAQ with UID ${faqUid}.`);
          if (index < faqs.length - 1)
            return this.deleteAllFaqsRecursively(faqs, ++index);
          // We're done
          return new Promise<void>(resolve => resolve());
        },
        reason => {
          return new Promise<void>((resolve, reject) => reject(reason));
        });
  }
}

// Firestore data converter
export const announcementConverter = {
  toFirestore(announcement: Announcement): Announcement {
    return announcement;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Announcement {
    return convertToAnnouncement(snapshot.data(options));
  },
};


// Firestore data converter
export const faqConverter = {
  toFirestore(faq: Faq): Faq {
    return faq;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Faq {
    return convertToFaq(snapshot.data(options));
  },
};
