import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {TitleService} from '../../shared/services/title.service';
import {Report} from '../../shared/models/report.interface';
import firebase from 'firebase/app';
import {environment} from '../../../environments/environment';
import {SocialService} from '../../social/social.service';
import {ReportType} from '../../shared/models/reportType.type';
import {NgCacheRouteReuseService} from 'ng-cache-route-reuse';
import {UserService} from '../../shared/services/user.service';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  thereIsMoreListing = true;
  thereIsMoreRating = true;
  reportsListing: Report[] = [];
  reportsRating: Report[] = [];
  searchingListing = false;
  searchingRating = false;
  lastVisibleReportListing?: DocumentSnapshot<Report>;
  lastVisibleReportRating?: DocumentSnapshot<Report>;

  private readonly spinnerKeyFetchingReportsListing = 'fetchingReportsListing';
  private readonly spinnerKeyFetchingReportsRating = 'fetchingReportsRating';

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private titleService: TitleService,
      private cdRef: ChangeDetectorRef,
      private cacheRouteReuseService: NgCacheRouteReuseService,
      private socialService: SocialService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setTitle();


    this.user$.subscribe((user) => {
          if (user?.uid) {
            this.loadReports('listing');
            this.loadReports('rating');
          }
        },
    );

    this.cacheRouteReuseService
        .onAttach(ReportsComponent)
        .subscribe((component) => {
          this.setTitle();
        });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  refreshListing(): void {
    this.clearAlerts();
    if (this.firebaseUser?.uid) {
      this.lastVisibleReportListing = undefined;
      this.reportsListing.length = 0;
      this.loadReports('listing');
    }
  }

  refreshRating(): void {
    this.clearAlerts();
    if (this.firebaseUser?.uid) {
      this.lastVisibleReportRating = undefined;
      this.reportsRating.length = 0;
      this.loadReports('rating');
    }
  }

  /**
   * Requests more reports
   * @param count requested number of additional reports
   * @param type type of reports to be loaded
   */
  loadMoreReports(count: number, type: ReportType): void {
    switch (type) {
      case 'listing': {
        if (!this.reportsListing || !this.thereIsMoreListing || this.searchingListing)
          return;
        this.loadReports(type);
        break;
      }
      case 'rating': {
        if (!this.reportsRating || !this.thereIsMoreRating || this.searchingRating)
          return;
        this.loadReports(type);
        break;
      }
    }

  }

  /**
   * Loads (more) reports
   * @param type type of reports to be loaded
   */
  loadReports(type: ReportType): void {
    switch (type) {
      case 'listing': {

        this.addLoadingSpinnerMessage(this.spinnerKeyFetchingReportsListing, $localize`Fetching listing reports...`);
        this.searchingListing = true;
        if (!this.firebaseUser)
          return;
        this.socialService.fetchReports(type, undefined, this.lastVisibleReportListing).then(wrapper => {
          this.removeLoadingSpinnerMessage(this.spinnerKeyFetchingReportsListing);
          this.disableLoadingSpinner();
          this.searchingListing = false;
          if (wrapper.data) {
            this.reportsListing.push(...wrapper.data);
            // Recreate the reports array in order to trigger a fresh setReports in reports-view component. This is necessary for the table to be initialized
            this.reportsListing = [...this.reportsListing];
          }
          this.thereIsMoreListing = wrapper.data !== undefined && wrapper.data.length === environment.defaultLoadReportsCount;
          this.lastVisibleReportListing = wrapper.lastVisible;
          if (wrapper.errorMessage)
            this.addError($localize`Listing reports could not be loaded\: ${wrapper.errorMessage}`);
        });
        break;
      }
      case 'rating': {

        this.addLoadingSpinnerMessage(this.spinnerKeyFetchingReportsRating, $localize`Fetching rating reports...`);
        this.searchingRating = true;
        if (!this.firebaseUser)
          return;
        this.socialService.fetchReports(type, undefined, this.lastVisibleReportRating).then(wrapper => {
          this.removeLoadingSpinnerMessage(this.spinnerKeyFetchingReportsRating);
          this.disableLoadingSpinner();
          this.searchingRating = false;
          if (wrapper.data) {

            this.reportsRating.push(...wrapper.data);
            // Recreate the reports array in order to trigger a fresh setReports in reports-view component. This is necessary for the table to be initialized
            this.reportsRating = [...this.reportsRating];
          }
          this.thereIsMoreRating = wrapper.data !== undefined && wrapper.data.length === environment.defaultLoadReportsCount;
          this.lastVisibleReportRating = wrapper.lastVisible;
          if (wrapper.errorMessage)
            this.addError($localize`Rating reports could not be loaded\: ${wrapper.errorMessage}`);
        });
        break;
      }
    }

  }

  private setTitle() {
    this.titleService.setTitle($localize`:Reports from users about inappropriate content:Reports`);
  }

}
