import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Dimensions, ImageCroppedEvent, ImageCropperComponent, ImageTransform, LoadedImage} from 'ngx-image-cropper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PreviewModalComponent} from './preview-modal/preview-modal.component';
import {SharedService} from '../../../services/shared.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';
// for cloud firestore

const THUMBNAIL_WIDTH = 300;

@Component({
    selector: 'app-cropper',
    templateUrl: './cropper.component.html',
    styleUrls: ['./cropper.component.css'],
})
export class CropperComponent implements OnInit, OnDestroy {

    @Input() img: File | undefined;
    croppedImage: string = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    transform: ImageTransform = {};
    showSpinner: boolean = true;

    maxWidth = 1600;
    maxHeight = 1600;
    disabled = false;

    /**
     * Path and filename of the uploaded image. Used to create the suffixed path and filename of the thumbnail
     */
    uploadPath: string | undefined;
    /**
     * Called, when the delete button is clicked.
     */
    @Output() delete = new EventEmitter<File>();
    @Output() croppedImageEmitter = new EventEmitter<string>();

    destroy$: Subject<null> = new Subject();

    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent | undefined;

    constructor(
        private modalService: NgbModal,
        private sharedService: SharedService,
        private storage: AngularFireStorage,
    ) {


    }

    ngOnInit(): void {
        this.sharedService.readyToCreateThumbnailEmitter.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event.file !== this.img || !event.uploadPath)
                return;
            this.maxWidth = THUMBNAIL_WIDTH;
            this.maxHeight = THUMBNAIL_WIDTH;
            this.disabled = true;
            this.uploadPath = event.uploadPath;
            this.imageCropper?.crop();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
    }

    imageCropped(event: ImageCroppedEvent) {
        if (event.base64)
            this.croppedImage = event.base64;
        this.croppedImageEmitter.next(this.croppedImage);
        // if (this.disabled && this.maxWidth === THUMBNAIL_WIDTH) {
        //   this.uploadThumbnail();
        // }
    }

    imageLoaded(image: LoadedImage) {
        this.showCropper = true;
        this.transform = image.exifTransform;
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        this.showSpinner = false;
    }

    loadImageFailed() {
        console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH,
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV,
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation,
        };
    }

    /**
     * Opens the given array of images in a carousel inside a modal.
     * @param selectedId the ID of the image to be selected at the beginning
     * @param imgUrls all images to be shown in the carousel
     */
    openPreview(): void {
        const modalRef = this.modalService.open(PreviewModalComponent, {
            centered: true,
            size: 'xl',
        });

        modalRef.componentInstance.img = this.croppedImage;
    }

    /**
     * Emits an event to deletes this img.
     */
    deleteImage() {
        this.delete.emit(this.img);
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH,
        };
    }
}
