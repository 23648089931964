import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Rating} from '../../models/rating.interface';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import {SettingsService} from '../../services/settings.service';
import {BaseComponent} from '../base/base.component';
import {UserService} from '../../services/user.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Subject} from 'rxjs';
import {ElementScrollPercentageService} from '../../services/element-scroll-percentage.service';
import {UtilService} from '../../util.service';
import {TransactionRole} from '../../enums/transactionRole.enum';
import {UserPublic} from '../../models/userPublic.interface';
import Locale from '../../services/locale';
import {AppState} from '../../../store/app.reducer';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-ratings-view',
  templateUrl: './ratings-view.component.html',
  styleUrls: ['./ratings-view.component.scss'],
})
export class RatingsViewComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  @Input() thereIsMore = false;
  /**
   * The scroll percentage, at which new ratings are loaded. For example, the value 50 will reload items after scrolling down half the page.
   */
  @Input() infiniteScrollPercentage = 101;
  @Output() loadMoreRatings = new EventEmitter<number>();
  @Input() moreRatingsCount = environment.defaultLoadUserRatingsCount;
  /**
   * Initial view mode. Can be switched using the tabs on the top. Possible values: 'grid' (default) and 'table'
   */
  @Input() viewMode = 'grid';
  /**
   * If set to true, instead of the raters' names, the receivers' names will be shown
   */
  @Input() givenRatingsMode = false;

  numberFormatLocale = Locale.numberFormatLocale();
  // Tab management
  selectedIndex = 0;
  tabViewModes = ['grid', 'table'];
  tabSelectionSubject = new Subject<string>();
  // Table management
  @ViewChild(MatSort) sort: MatSort | undefined;
  dataSource?: MatTableDataSource<Rating>;
  displayedColumns: string[] = ['raterImgThumb', 'raterDisplayName', 'role', 'stars', 'comment', 'creationDate', 'listingName', 'listingImgThumb'];
  @Input() matSortActive: string = '';
  @Input() matSortDirection: SortDirection = '';

  usersPublicByUid = new Map<string, UserPublic>();

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private cdRef: ChangeDetectorRef,
              private settingsService: SettingsService,
              private elementScrollPercentageService: ElementScrollPercentageService,
              public utilService: UtilService) {
    super(store);

  }

  public get transactionRole(): typeof TransactionRole {
    return TransactionRole;
  }


  _ratings: Rating[] = [];

  get ratings(): Rating[] {
    return this._ratings;
  }

  @Input() set ratings(ratings: Rating[]) {

    this._ratings = ratings;
    this.initTable();
    if (this.givenRatingsMode)
      this.loadUsersPublic();

  }

  ngOnInit(): void {
    super.ngOnInit();

    this.user$.subscribe(user => {
      if (user?.settings?.listingsViewMode) {
        this.viewMode = user.settings.listingsViewMode;
        this.selectedIndex = this.tabViewModes.indexOf(this.viewMode);
      }
    });

    // Synchronize setting between different components using ratings-view (latest-ratings, components...)
    this.tabSelectionSubject.subscribe((viewMode) => {
      this.viewMode = viewMode;
      this.selectedIndex = this.tabViewModes.indexOf(this.viewMode);
    });

    this.selectedIndex = this.tabViewModes.indexOf(this.viewMode);

    this.elementScrollPercentageService
        .getScrollAsStream() // Defaults to Document if no Element supplied.
        .subscribe((percent: number): void => {
          if (!this.thereIsMore || this.infiniteScrollPercentage >= 100)
            return;
          if (percent > this.infiniteScrollPercentage)
            this.onClickShowMore();
        });


    this.sort?.sort({disableClear: false, id: 'address', start: 'desc'});
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  initTable(): void {
    this.dataSource = new MatTableDataSource(this._ratings);
    if (this.sort)
      this.dataSource.sort = this.sort;
  }

  /**
   * Called, when the Show more button is clicked. Emits an event to the parent component to request more ratings.
   * Those will - if available - be added to the field ratings.
   */
  onClickShowMore(): void {
    this.loadMoreRatings.emit(this.moreRatingsCount);
  }


  onTabChange(event: MatTabChangeEvent): void {
    const value = this.tabViewModes[event.index];
    this.viewMode = value;
    this.tabSelectionSubject.next(value);
    if (this.firebaseUser?.uid)
      this.settingsService.setStringValue('listingsViewMode', value, this.firebaseUser.uid);
  }

  /**
   * Called, when a sortable table header is clicked
   * @param sort
   */
  sortData(sort: Sort) {
    this.matSortActive = sort.active;
    this.matSortDirection = sort.direction;
  }

  private loadUsersPublic() {
    this.ratings.forEach(rating => {
      if (!this.usersPublicByUid.get(rating.receiverUid))
        this.userService.fetchUserPublic(rating.receiverUid).then(wrapper => {
          if (wrapper.data)
            this.usersPublicByUid.set(rating.receiverUid, wrapper.data);
          if (wrapper.errorMessage)
            this.addError(wrapper.errorMessage);
        });
    });
  }
}
