import Util from '../util';
import {SuffixPrefix} from '../models/suffixPrefix.type';

export default class Locale {

  private static defaultNationalityCountryCodeCache: string;
  private static numberFormatLocaleCache: string;
  private static defaultCurrencyIdCache: string;
  private static dateAdapterLocaleCache: string;
  private static momentLocaleCache: string;
  private static timePickerLocaleCache: string;
  private static timePickerFormatCache: number;
  private static defaultLocaleDateFormatCache: string;
  private static defaultTimeZoneCache: string;
  private static countryLocaleCache: string;
  private static languageLocaleCache: string;
  private static firstDayOfWeekCache: number;

  private static firestoreLocaleCache: string;
  private static currencySuffixOrPrefixCache: SuffixPrefix;


  /**
   * Delivers the language from the given path.
   * @param path a path (without domain), e.g. '/en/account/listings'
   * @return language, e.g. 'en'
   */
  static getLangFromPath(path: string): string | undefined {
    const parts = path.split('/');
    if (parts.length > 1)
      return parts[1];
    return undefined;
  }

  /**
   * Delivers the current lang. If you already know the path, use getLangFromPath instead.
   * @return language, e.g. 'en'
   */
  static getCurrentLang(): string | undefined {
    const path = Util.getPath();
    let currentLang = this.getLangFromPath(path);
    if (!currentLang) {
      currentLang = navigator.language.substr(0, 2);
    }
    return currentLang;
  }


  /**
   * Default nationality country code
   */
  static defaultNationalityCountryCode(): string {
    if (!this.defaultNationalityCountryCodeCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.defaultNationalityCountryCodeCache = 'DE';
          break;
        default:
          this.defaultNationalityCountryCodeCache = 'US';
      }
    }
    return this.defaultNationalityCountryCodeCache;
  }


  /**
   * Number format locale
   */
  static numberFormatLocale(): string {
    if (!this.numberFormatLocaleCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.numberFormatLocaleCache = 'de';
          break;
        default:
          this.numberFormatLocaleCache = 'en';
      }
    }
    return this.numberFormatLocaleCache;
  }


  /**
   * default currency ID (e.g. EUR)
   */
  static defaultCurrencyId(): string {
    if (!this.defaultCurrencyIdCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.defaultCurrencyIdCache = 'EUR';
          break;
        default:
          this.defaultCurrencyIdCache = 'USD';
      }
    }
    return this.defaultCurrencyIdCache;
  }


  /**
   * Used for material date pickers
   */
  static dateAdapterLocale(): string {
    if (!this.dateAdapterLocaleCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.dateAdapterLocaleCache = 'de-DE';
          break;
        default:
          this.dateAdapterLocaleCache = 'en-US';
      }
    }
    return this.dateAdapterLocaleCache;
  }


  /**
   * Locale for moment.js. list at https://www.ge.com/digital/documentation/predix-services/c_custom_locale_support.html
   */
  static momentLocale(): string {
    if (!this.momentLocaleCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.momentLocaleCache = 'de';
          break;
        default:
          this.momentLocaleCache = 'en';
      }
    }
    return this.momentLocaleCache;
  }


  /**
   * https://www.npmjs.com/package/ngx-material-timepicker
   */
  static timePickerLocale(): string {
    if (!this.timePickerLocaleCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.timePickerLocaleCache = 'de-DE';
          break;
        default:
          this.timePickerLocaleCache = 'en-US';
      }
    }
    return this.timePickerLocaleCache;
  }


  /**
   * 12 or 24 hours format for time pickers
   */
  static timePickerFormat(): number {
    if (!this.timePickerFormatCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.timePickerFormatCache = 24;
          break;
        default:
          this.timePickerFormatCache = 12;
      }
    }
    return this.timePickerFormatCache;
  }


  /**
   * Default locale for Date.toLocaleDateString()
   */
  static defaultLocaleDateFormat(): string {
    if (!this.defaultLocaleDateFormatCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.defaultLocaleDateFormatCache = 'de-DE';
          break;
        default:
          this.defaultLocaleDateFormatCache = 'en-US';
      }
    }
    return this.defaultLocaleDateFormatCache;
  }


  /**
   * Default locale for Date.toLocaleDateString()
   */
  static defaultTimeZone(): string {
    if (!this.defaultTimeZoneCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.defaultTimeZoneCache = 'Europe/Berlin';
          break;
        default:
          this.defaultTimeZoneCache = 'Europe/Berlin';
      }
    }
    return this.defaultTimeZoneCache;
  }


  /**
   * Default locale used for country translation (https://www.npmjs.com/package/i18n-iso-countries)
   */
  static countryLocale(): string {
    if (!this.countryLocaleCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.countryLocaleCache = 'de';
          break;
        default:
          this.countryLocaleCache = 'en';
      }
    }
    return this.countryLocaleCache;
  }


  /**
   * Default locale used for translation of language names and also for the user setting 'lang'. This is the default language for all user facing texts
   */
  static languageLocale(): string {
    if (!this.languageLocaleCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.languageLocaleCache = 'de';
          break;
        default:
          this.languageLocaleCache = 'en';
      }
    }
    return this.languageLocaleCache;
  }


  /**
   * Used for material date pickers
   */
  static firstDayOfWeek(): number {
    if (!this.firstDayOfWeekCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.firstDayOfWeekCache = 1;
          break;
        default:
          this.firstDayOfWeekCache = 7;
      }
    }
    return this.firstDayOfWeekCache;
  }

  /**
   * Used to select the correct locale strings from the firestore (e.g. for categories and currencies)
   */
  static firestoreLocale(): string {
    if (!this.firestoreLocaleCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.firestoreLocaleCache = 'de';
          break;
        default:
          this.firestoreLocaleCache = 'en';
      }
    }
    return this.firestoreLocaleCache;
  }


  /**
   * Used to select the correct locale strings from the firestore (e.g. for categories and currencies)
   */
  static currencySuffixOrPrefix(): SuffixPrefix {
    if (!this.currencySuffixOrPrefixCache) {
      const currentLang = Locale.getCurrentLang();
      switch (currentLang) {
        case 'de':
          this.currencySuffixOrPrefixCache = 'suffix';
          break;
        default:
          this.currencySuffixOrPrefixCache = 'prefix';
      }
    }
    return this.currencySuffixOrPrefixCache;
  }


}
