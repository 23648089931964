import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../shared/services/user.service';
import {TranslateService} from '../../../shared/services/translate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilService} from '../../../shared/util.service';
import {mergeMap, take, takeUntil} from 'rxjs/operators';
import {CANNOT_BE_UNDONE} from '../../../shared/constants/strings';
import {CurLangStrings, CurLangStringsMap, Currency} from '../../../shared/models/currency.interface';
import {CurrencyService} from '../../../shared/services/currency.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-currencies-editor-form',
  templateUrl: './currencies-editor-form.component.html',
  styleUrls: ['./currencies-editor-form.component.scss'],
})
export class CurrenciesEditorFormComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  idMaxLength = 10;
  identifierMaxLength = 50;
  symbolMaxLength = 3;
  nameMaxLength = 30;
  langMaxLength = 2;
  form: FormGroup = this.createForm();
  id?: string;
  new = false;
  selectedTabIndex = 0;
  languages = ['en', 'de', 'fr', 'it', 'es', 'ru'];
  private readonly spinnerKeyDeleteCurrency = 'deleteCurrency';
  private readonly spinnerKeyInsertCurrency = 'insertCurrency';
  private readonly spinnerKeyUpdateCurrency = 'updateCurrency';

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private formBuilder: FormBuilder,
      private cdRef: ChangeDetectorRef,
      private translateService: TranslateService,
      private activatedRoute: ActivatedRoute,
      public utilService: UtilService,
      public currencyService: CurrencyService,
      private router: Router) {
    super(store);
  }

  _currency?: Currency;

  get currency(): Currency | undefined {
    return this._currency;
  }

  @Input() set currency(currency: Currency | undefined) {
    this._currency = currency ? {
      ...currency,
      id: currency?.id,
      identifier: currency?.identifier,
      symbol: currency?.symbol,
      strings: currency.strings,
      cacheDate: currency?.cacheDate,
    } : undefined;
    this.reloadForm();

  }

  ngOnInit(): void {
    super.ngOnInit();

    // Retrieve id from route params
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(
        (params) => {
          this.clearAlerts();

          this.id = params.uid;
          if (this.id)
              // Fetch currency
            this.currencyService.fetchCurrency(this.id, 0).then(wrapper => {
              if (wrapper.data)
                this.currency = wrapper.data;
              if (wrapper.errorMessage)
                this.addError(wrapper.errorMessage);
            });
          this.new = this.id === undefined;
          this.currencyService.onCurrencySelected$.next(this.id);
        });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  save(): void {
    const originalId = this.currency?.id;

    this.clearAlerts();

    if (this.form.value.id === 'new') {
      this.addError($localize`The ID <i>new</i> is reserved. Please use another ID.`);
      return;
    }
    if (this.getStringsFormArray().length === 0) {
      this.addError($localize`Please add English strings.`);
      return;
    }

    const currency: Currency = {
      id: this.form.value.id,
      identifier: this.form.value.identifier,
      symbol: this.form.value.symbol,
      // strings cannot be undefined, so we initiate it with empty strings
      strings: {en: {name0: '', name1: '', nameN: ''}},
    };

    for (let i = 0; i < this.getStringsFormArray().length; i++) {
      const strings = this.getStringsFormArray().at(i);
      const name0 = strings.value.name0;
      const name1 = strings.value.name1;
      const nameN = strings.value.nameN;

      // If the form is empty, skip this language
      if (!name0 && !name1 && !nameN)
        continue;

      const curLangStrings: CurLangStrings = {name0, name1, nameN};
      currency.strings[strings.value.lang] = curLangStrings;
    }

    if (currency.strings.en.name0 === '' || currency.strings.en.name1 === '' || currency.strings.en.nameN === '') {
      this.addError($localize`The English name strings are incomplete.`);
      return;
    }

    // Differentiate between (new currency), (updated currency with same ID) and (updated currency with changed ID)
    if (!originalId || originalId === currency.id)
      return this.updateCurrency(currency);
    // ID was changed
    return this.updateAndRenameCurrency(originalId, currency);

  }

  getStringsFormArray(): FormArray {
    return this.form?.get('strings') as FormArray;
  }

  deleteStrings(index: number): void {
    this.getStringsFormArray().removeAt(index);
    this.form.markAsTouched();
  }

  translateStrings(target: string, index: number): void {
    if (!target || target === 'en')
      return;
    const enName0 = this.getEnName0();
    if (enName0)
      this.translateService.translate(enName0, target).pipe(take(1)).subscribe(result => {
        this.getStringsFormArray().at(index).patchValue({name0: result});
      });
    const enName1 = this.getEnName1();
    if (enName1)
      this.translateService.translate(enName1, target).pipe(take(1)).subscribe(result => {
        this.getStringsFormArray().at(index).patchValue({name1: result});
      });
    const enNameN = this.getEnNameN();
    if (enName1)
      this.translateService.translate(enName1, target).pipe(take(1)).subscribe(result => {
        this.getStringsFormArray().at(index).patchValue({nameN: result});
      });
  }

  addMissingTranslations(): void {
    // Go through all languages
    this.languages.forEach(lang => {
      // If the current language is 'en', skip it
      if (lang === 'en')
        return;

      // Check, if the language is missing
      if (!this.shouldDisplayLanguage(lang))
        return;
      // This language is in fact missing
      const name0 = this.getEnName0();
      const name1 = this.getEnName1();
      const nameN = this.getEnNameN();

      if (lang && name0 && name1 && nameN)
        this.addTranslation(lang, name0, name1, nameN);
    });
  }

  addStrings(lang?: string, name0?: string, name1?: string, nameN?: string, goToTab = true): void {
    this.getStringsFormArray().push(
        this.createStringsFormGroup(lang, name0, name1, nameN),
    );
    this.form.markAsTouched();
    // this.writeCategory();
    if (goToTab)
      this.selectedTabIndex = this.getStringsFormArray().length - 1;
  }

  /**
   * Returns true, if the given language add button should be displayed. False otherwise.
   * @param lang language code
   * @return true, if the given language add button should be displayed. False otherwise.
   */
  shouldDisplayLanguage(lang: string) {
    for (let i = 0; i < this.getStringsFormArray().length; i++) {
      const strings = this.getStringsFormArray().at(i);
      if (strings.value.lang === lang)
        return false;
    }
    return true;
  }

  onDelete(currency: Currency) {
    this.clearAlerts();
    if (!currency?.id) {
      this.addError($localize`This currency has not yet been saved. It cannot be deleted.`);
      return;
    }
    const title = currency?.id ? $localize`Are you sure you want to delete the currency '${currency.id}'?` :
        $localize`Are you sure you want to delete this unnamed currency?`;
    this.utilService.showConfirmDialog(title, CANNOT_BE_UNDONE, this.deleteCurrency.bind(this), [currency.id!, true], undefined, undefined, undefined, 'no');
  }

  getCreateOrSaveLabel() {
    if (this.new)
      return $localize`Create`;
    return $localize`Save`;
  }

  private getLangStrings(lang: string): CurLangStrings | undefined {
    for (let i = 0; i < this.getStringsFormArray().length; i++) {
      const strings = this.getStringsFormArray().at(i);
      if (strings.value.lang === lang)
        return strings.value;
    }
    return undefined;
  }

  private getEnName0(): string | undefined {
    return this.getLangStrings('en')?.name0;
  }

  private getEnName1(): string | undefined {
    return this.getLangStrings('en')?.name1;
  }

  private getEnNameN(): string | undefined {
    return this.getLangStrings('en')?.nameN;
  }


  private createForm(): FormGroup {

    return this.formBuilder.group({
      id: ['', [Validators.required, Validators.maxLength(this.idMaxLength)]],
      identifier: ['', [Validators.required, Validators.maxLength(this.identifierMaxLength)]],
      symbol: ['', [Validators.required, Validators.maxLength(this.symbolMaxLength)]],
      strings: this.formBuilder.array([this.createStringsFormGroup('en', '', '', '')],
      ),
    });
  }

  private createStringsFormGroup(lang?: string, name0?: string, name1?: string, nameN?: string): FormGroup {
    return this.formBuilder.group({
          // If lang is 'en', the lang field should be disabled.
          lang: [lang, [Validators.maxLength(this.langMaxLength), Validators.required]],
          name0: [name0, [Validators.maxLength(this.nameMaxLength), Validators.required]],
          name1: [name1, [Validators.maxLength(this.nameMaxLength), Validators.required]],
          nameN: [nameN, [Validators.maxLength(this.nameMaxLength), Validators.required]],
        },
    );
  }

  private reloadForm() {

    this.form.reset();
    this.getStringsFormArray().clear();
    if (this.currency) {
      this.form.patchValue({id: this.currency.id, identifier: this.currency.identifier, symbol: this.currency.symbol});
      Object.entries(this.currency.strings).forEach(entry => {
        const langStrings = entry[1];
        this.addStrings(entry[0], langStrings.name0, langStrings.name1, langStrings.nameN);
      });
    }
  }

  private insertCurrency(currency: Currency) {
    this.addLoadingSpinnerMessage(this.spinnerKeyInsertCurrency, $localize`Inserting currency...`);
    this.currencyService.insertCurrency(currency, savedCurrency => {
          this.currency = currency;
          this.currencyService.onCurrencySaved$.next(currency);
          this.removeLoadingSpinnerMessage(this.spinnerKeyInsertCurrency);
        },
        error => {
          this.addError($localize`Error inserting the currency\: ${error}`);
          this.removeLoadingSpinnerMessage(this.spinnerKeyInsertCurrency);
        });
  }

  private updateCurrency(currency: Currency) {
    this.addLoadingSpinnerMessage(this.spinnerKeyUpdateCurrency, $localize`Updating currency...`);
    this.currencyService.updateCurrency(currency.id!, currency, currency, false, () => {
          this.currencyService.onCurrencySaved$.next(currency);
          this.removeLoadingSpinnerMessage(this.spinnerKeyUpdateCurrency);
          this.router.navigate(['admin', 'currencies', currency.id]);
          this.addSuccess($localize`Successfully saved currency <i>${currency.id}</i>.`);
          this.currencyService.onCurrencySelected$.next(currency.id);

        },
        error => {
          this.addError($localize`Error updating the currency\: ${error}`);
          this.removeLoadingSpinnerMessage(this.spinnerKeyUpdateCurrency);
        });
  }

  private updateAndRenameCurrency(originalId: string, currency: Currency) {
    this.deleteCurrency(originalId, false);
    this.updateCurrency(currency);
  }

  private deleteCurrency(id: string, forward = true) {
    this.addLoadingSpinnerMessage(this.spinnerKeyDeleteCurrency, $localize`Deleting currency ${id}...`);
    this.currencyService.deleteCurrency(id).then(() => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyDeleteCurrency);
      this.currencyService.onCurrencyDeleted$.next(id);
      this.addSuccess($localize`Successfully deleted currency <i>${id}</i>.`);
      if (forward)
        this.router.navigate(['admin', 'currencies']);
    });
  }

  private addTranslation(lang: string, enName0: string, enName1: string, enNameN: string) {
    if (!this.currency)
      return;

    const name0Translation$ = this.translateService.translate(enName0, lang).pipe(take(1));
    const name1Translation$ = this.translateService.translate(enName1, lang).pipe(take(1));
    const nameNTranslation$ = this.translateService.translate(enNameN, lang).pipe(take(1));

    name0Translation$.pipe(
        mergeMap(() => name1Translation$, (name0Result: string, name1Result: string) => {
          return {name0Result: name0Result, name1Result: name1Result};
        }),
        mergeMap((result) => nameNTranslation$, (result, nameNResult: string) => {
          return {...result, nameNResult: nameNResult};
        }),
        takeUntil(this.destroy$))
        .subscribe((result) => {
              console.log(result);
              if (this.currency) {
                const currencyLangStrings: CurLangStrings = {
                  name0: result.name0Result,
                  name1: result.name1Result,
                  nameN: result.nameNResult,
                };
                // Make the currency.strings extensible
                this.currency.strings = this.getExtensibleStrings(this.currency.strings);
                this.currency.strings[lang] = currencyLangStrings;
                this.reloadForm();
              }
            },
        );
  }

  private getExtensibleStrings(strings: CurLangStringsMap) {
    const extensibleStrings: CurLangStringsMap = {en: {name0: '', name1: '', nameN: ''}};
    Object.entries(strings).forEach(entry => {
      extensibleStrings[entry[0]] = entry[1];
    });
    return extensibleStrings;
  }

  getLocalName1(currency: Currency): string {
    return this.currencyService.getCurrencyNameN(currency);
  }
}
