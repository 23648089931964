import {Listing} from '../models/listing.interface';
import firebase from 'firebase/app';
// for cloud firestore
import Timestamp = firebase.firestore.Timestamp;

export default class AlgoliaConverter {

  static convertAlgoliaHitToListing(hit: any): Listing {
    const listing: Listing = hit as Listing;
    if (hit?._highlightResult?.name?.value)
      listing.name = hit._highlightResult.name.value;
    if (hit?._highlightResult?.summary?.value)
      listing.summary = hit._highlightResult.summary.value;
    if (hit?._highlightResult?.description?.value)
      listing.description = hit._highlightResult.description.value;
    listing.uid = hit.objectID;
    listing.cacheDate = new Date();
    // Make sure that all timestamps really are Timestamps
    listing.availabilities?.forEach((availability) => {
      if (availability.dateFrom?.seconds)
        availability.dateFrom = Timestamp.fromMillis(availability.dateFrom.seconds * 1000);
      if (availability.dateUntil?.seconds)
        availability.dateUntil = Timestamp.fromMillis(availability.dateUntil.seconds * 1000);
      const newExcludedDates: Timestamp[] = [];
      if (availability.excludedDates) {
        availability.excludedDates.forEach(excludedDate => {
          if (excludedDate.seconds)
            newExcludedDates.push(Timestamp.fromMillis(excludedDate.seconds * 1000));
        });
        availability.excludedDates = newExcludedDates;
      }
    });
    // Note: geohashCoords are saved under the name _geoloc in Algolia. This is an internal name for location based filtering.
    if (hit._geoloc)
      listing.geohashCoords = hit._geoloc;
    return listing;
  }
}
