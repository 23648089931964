import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {UtilService} from '../../../shared/util.service';
import {Notification} from '../../../shared/models/notification.interface';
import {AccountService} from '../../account.service';
import {TransactionLog} from '../../../shared/models/transactionLog.interface';
import {TransactionState} from '../../../shared/enums/transactionState.enum';
import {User} from '../../../shared/models/user.interface';
import {UserPublic} from '../../../shared/models/userPublic.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends BaseComponent implements OnInit {

  @Input() notification?: Notification;

  borrower?: UserPublic;
  lender?: UserPublic;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private accountService: AccountService,
      public utilService: UtilService) {
    super(store);
  }

  public get transactionState(): typeof TransactionState {
    return TransactionState;
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.notification?.embeddedTransaction?.borrowerUid)
      this.userService.fetchUserPublic(this.notification.embeddedTransaction.borrowerUid).then(wrapper => {
        this.borrower = wrapper.data;
      });
    if (this.notification?.embeddedTransaction?.lenderUid)
      this.userService.fetchUserPublic(this.notification.embeddedTransaction.lenderUid).then(wrapper => {
        this.lender = wrapper.data;
      });
  }


  onNotificationClicked() {
    if (this.notification)
      this.accountService.markNotificationAsRead(this.notification, () => {
      }, (error) => {
        console.log(`Error marking notification ${this.notification!.uid} as read: ${error}`);
      });
  }

  createLog(): TransactionLog | undefined {
    if (!this.notification?.embeddedTransaction?.state || !this.notification.otherUser?.uid)
      return undefined;
    const log: TransactionLog = {
      state: this.notification?.embeddedTransaction?.state,
      previousState: this.notification?.embeddedTransaction?.previousState,
      date: this.notification.creationDate,
      actingUserUid: this.notification.otherUser?.uid,
      newPeriodSuggestion: this.notification.embeddedTransaction.newPeriodSuggestion,
      paidAmount: this.notification.embeddedTransaction.paidAmount,
      periodSuggestionState: this.notification.embeddedTransaction.periodSuggestionState,
      paymentState: this.notification.embeddedTransaction.paymentState,
      targetPickupDate: this.notification.embeddedTransaction.targetPickupDate,
      targetReturnDate: this.notification.embeddedTransaction.targetReturnDate,
    };
    if (this.notification?.embeddedTransaction?.previousState)
      log.previousState = this.notification.embeddedTransaction.previousState;
    return log;
  }

  getActingUserName(user: User) {
    // If this is a refund, the logged in user's name should be used
    if (this.notification?.embeddedTransaction?.paymentState === 'REFUNDED')
      return user.displayName;

    return this.notification?.otherUser?.displayName ? this.notification.otherUser?.displayName : undefined;
  }

  getBorrower(): UserPublic | undefined {
    return this.borrower;
  }

  getLender() {
    return this.lender;
  }
}
