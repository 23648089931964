import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Rating} from '../../shared/models/rating.interface';
import firebase from 'firebase/app';
import {TitleService} from '../../shared/services/title.service';
import {environment} from '../../../environments/environment';
import {BookService} from '../../book/book.service';
import {Transaction} from '../../shared/models/transaction.interface';
import {UserService} from '../../shared/services/user.service';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.css'],
})
export class RatingsComponent extends BaseComponent implements OnInit {

  ratingsReceivedInitialized = false;
  showLoadingSpinnerRatingsReceived = true;
  thereAreMoreRatingsReceived = true;
  ratingsReceived: Rating[] = [];
  lastVisibleRatingReceived?: DocumentSnapshot<Rating>;
  searchingRatingsReceived = false;

  ratingsGivenInitialized = false;
  showLoadingSpinnerRatingsGiven = true;
  thereAreMoreRatingsGiven = true;
  ratingsGiven: Rating[] = [];
  lastVisibleRatingGiven?: DocumentSnapshot<Rating>;
  searchingRatingsGiven = false;

  transactionsUnratedByMeInitialized = false;
  showLoadingSpinnerTransactionsUnratedByMe = true;
  thereAreMoreTransactionsUnratedByMe = true;
  transactionsUnratedByMe: Transaction[] = [];
  lastVisibleTransactionUnratedByMe1?: DocumentSnapshot<Transaction>;
  lastVisibleTransactionUnratedByMe2?: DocumentSnapshot<Transaction>;
  searchingTransactionsUnratedByMe = false;

  transactionsUnratedByOtherInitialized = false;
  showLoadingSpinnerTransactionsUnratedByOther = true;
  thereAreMoreTransactionsUnratedByOther = true;
  transactionsUnratedByOther: Transaction[] = [];
  lastVisibleTransactionUnratedByOther1?: DocumentSnapshot<Transaction>;
  lastVisibleTransactionUnratedByOther2?: DocumentSnapshot<Transaction>;
  searchingTransactionsUnratedByOther = false;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private bookService: BookService,
      private titleService: TitleService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`My ratings`);

    this.user$.subscribe(user => {
      if (!user)
        return;
      this.user = user;
      this.initRatingsReceived();
    });
  }


  onTabChange(event: MatTabChangeEvent): void {
    switch (event.index) {
      case 0:
        return this.initRatingsReceived();
      case 1:
        return this.initRatingsGiven();
      case 2:
        return this.initTransactionsUnratedByMe();
      case 3:
        return this.initTransactionsUnratedByOther();
    }

  }

  loadRatingsReceived(): void {
    this.showLoadingSpinnerRatingsReceived = true;
    if (!this.user)
      return;
    this.bookService.fetchRatings(this.user.uid, undefined, this.lastVisibleRatingReceived).then(wrapper => {
      this.showLoadingSpinnerRatingsReceived = false;
      if (wrapper.data) {
        this.ratingsReceived.push(...wrapper.data);
        // Recreate the ratings array in order to trigger a fresh setRatings in ratings-view component.
        this.ratingsReceived = [...this.ratingsReceived];
      }
      this.thereAreMoreRatingsReceived = wrapper.data !== undefined && wrapper.data.length === environment.defaultLoadUserRatingsCount;
      this.lastVisibleRatingReceived = wrapper.lastVisible;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  loadRatingsGiven(): void {
    this.showLoadingSpinnerRatingsGiven = true;
    if (!this.user)
      return;
    this.bookService.fetchRatings(undefined, this.user.uid, this.lastVisibleRatingGiven).then(wrapper => {
      this.showLoadingSpinnerRatingsGiven = false;
      if (wrapper.data) {
        this.ratingsGiven.push(...wrapper.data);
        // Recreate the ratings array in order to trigger a fresh setRatings in ratings-view component.
        this.ratingsGiven = [...this.ratingsGiven];
      }
      this.thereAreMoreRatingsGiven = wrapper.data !== undefined && wrapper.data.length === environment.defaultLoadUserRatingsCount;
      this.lastVisibleRatingGiven = wrapper.lastVisible;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  loadTransactionsUnratedByMe(): void {
    this.showLoadingSpinnerTransactionsUnratedByMe = true;
    if (!this.user)
      return;
    this.bookService.fetchTransactionsUnratedByMe(this.user.uid, this.lastVisibleTransactionUnratedByMe1, this.lastVisibleTransactionUnratedByMe2).then(wrapper => {
      this.showLoadingSpinnerTransactionsUnratedByMe = false;
      if (wrapper.data) {
        this.transactionsUnratedByMe.push(...wrapper.data);
        // Recreate the ratings array in order to trigger a fresh setRatings in ratings-view component.
        this.transactionsUnratedByMe = [...this.transactionsUnratedByMe];
      }
      this.thereAreMoreTransactionsUnratedByMe = wrapper.data !== undefined && wrapper.data.length === environment.defaultLoadTransactionsCount;
      this.lastVisibleTransactionUnratedByMe1 = wrapper.lastVisible;
      this.lastVisibleTransactionUnratedByMe2 = wrapper.lastVisible2;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  loadTransactionsUnratedByOther(): void {
    this.showLoadingSpinnerTransactionsUnratedByOther = true;
    if (!this.user)
      return;
    this.bookService.fetchTransactionsUnratedByOther(this.user.uid, this.lastVisibleTransactionUnratedByOther1, this.lastVisibleTransactionUnratedByOther2).then(wrapper => {
      this.showLoadingSpinnerTransactionsUnratedByOther = false;
      if (wrapper.data) {
        this.transactionsUnratedByOther.push(...wrapper.data);
        // Recreate the ratings array in order to trigger a fresh setRatings in ratings-view component.
        this.transactionsUnratedByOther = [...this.transactionsUnratedByOther];
      }
      this.thereAreMoreTransactionsUnratedByOther = wrapper.data !== undefined && wrapper.data.length === environment.defaultLoadTransactionsCount;
      this.lastVisibleTransactionUnratedByOther1 = wrapper.lastVisible;
      this.lastVisibleTransactionUnratedByOther2 = wrapper.lastVisible2;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  /**
   * Requests more search ratings
   * @param count requested number of additional ratings
   */
  loadMoreRatingsReceived(count: number): void {
    if (!this.ratingsReceived || !this.thereAreMoreRatingsReceived || this.searchingRatingsReceived)
      return;
    this.loadRatingsReceived();
  }

  /**
   * Requests more search ratings
   * @param count requested number of additional ratings
   */
  loadMoreRatingsGiven(count: number): void {
    if (!this.ratingsGiven || !this.thereAreMoreRatingsGiven || this.searchingRatingsGiven)
      return;
    this.loadRatingsGiven();
  }

  /**
   * Requests more search ratings
   * @param count requested number of additional ratings
   */
  loadMoreTransactionsUnratedByMe(): void {
    if (!this.transactionsUnratedByMe || !this.thereAreMoreTransactionsUnratedByMe || this.searchingTransactionsUnratedByMe)
      return;
    this.loadTransactionsUnratedByMe();
  }

  /**
   * Requests more search ratings
   * @param count requested number of additional ratings
   */
  loadMoreTransactionsUnratedByOther(): void {
    if (!this.transactionsUnratedByOther || !this.thereAreMoreTransactionsUnratedByOther || this.searchingTransactionsUnratedByOther)
      return;
    this.loadTransactionsUnratedByOther();
  }

  private initRatingsReceived() {
    if (this.ratingsReceivedInitialized)
      return;
    this.lastVisibleRatingReceived = undefined;
    this.ratingsReceived = [];
    this.loadRatingsReceived();
    this.ratingsReceivedInitialized = true;
  }

  private initRatingsGiven() {
    if (this.ratingsGivenInitialized)
      return;
    this.lastVisibleRatingGiven = undefined;
    this.ratingsGiven = [];
    this.loadRatingsGiven();
    this.ratingsGivenInitialized = true;

  }

  private initTransactionsUnratedByMe() {
    if (this.transactionsUnratedByMeInitialized)
      return;
    this.lastVisibleTransactionUnratedByMe1 = undefined;
    this.transactionsUnratedByMe = [];
    this.loadTransactionsUnratedByMe();
    this.transactionsUnratedByMeInitialized = true;

  }

  private initTransactionsUnratedByOther() {
    if (this.transactionsUnratedByOtherInitialized)
      return;
    this.lastVisibleTransactionUnratedByOther1 = undefined;
    this.transactionsUnratedByOther = [];
    this.loadTransactionsUnratedByOther();
    this.transactionsUnratedByOtherInitialized = true;

  }

}
