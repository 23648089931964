import {Component, ElementRef, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../../shared/services/user.service';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {ConversationsDialogComponent, ConversationsDialogModel} from '../../../social/conversations-dialog/conversations-dialog.component';
import {SocialService} from '../../../social/social.service';
import {takeUntil} from 'rxjs/operators';
import {Conversation} from '../../../shared/models/conversation.interface';
import {AppState} from '../../../store/app.reducer';
import {Store} from '@ngrx/store';


@Component({
  selector: 'app-conversations-button',
  templateUrl: './conversations-button.component.html',
  styleUrls: ['./conversations-button.component.css'],
})
export class ConversationsButtonComponent extends BaseComponent implements OnInit, OnDestroy {

  unreadConversationsCount = 0;
  conversations: Conversation[] = [];
  error?: string;
  closeEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  private conversationsStreamUnsubscribe?: () => void;
  private conversations1: Conversation[] = [];
  private conversations2: Conversation[] = [];

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public dialog: MatDialog,
      private socialService: SocialService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadConversations();

    this.closeEventEmitter.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialog.closeAll();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.conversationsStreamUnsubscribe)
      this.conversationsStreamUnsubscribe();
  }

  showDialog(event: MouseEvent) {
    const target = new ElementRef(event.currentTarget);

    const dialogData = new ConversationsDialogModel(this.conversations, target, this.error, this.closeEventEmitter);
    const dialogRef = this.dialog.open(ConversationsDialogComponent, {
      maxWidth: '350px',
      data: dialogData,
      backdropClass: 'backdropBackground',
      panelClass: 'conversations-dialog-container',
    });
  }

  private loadConversations() {
    this.firebaseUser$.pipe(takeUntil(this.destroy$)).subscribe(user => {
      if (user?.uid)
        this.streamConversations(user.uid);
      else {
        if (this.conversationsStreamUnsubscribe)
          this.conversationsStreamUnsubscribe();
        this.conversations = [];
      }
    });
  }

  private streamConversations(userUid: string) {

    this.conversationsStreamUnsubscribe = this.socialService.streamConversations(userUid,
        (conversations => {
          this.conversations1 = conversations;
          this.mergeConversations(userUid);
        }), (conversations => {
          this.conversations2 = conversations;
          this.mergeConversations(userUid);
        }));

  }

  private mergeConversations(userUid: string) {
    this.conversations.length = 0;
    this.conversations.push(...this.conversations1);
    this.conversations.push(...this.conversations2);
    this.conversations.sort((a, b) => b.latestMessageDate.toMillis() - a.latestMessageDate.toMillis());
    this.countUnreadConversations(userUid);
  }

  private countUnreadConversations(userUid: string) {
    this.unreadConversationsCount = 0;
    this.conversations.forEach(conversation => {
      if (userUid === conversation.user1Uid && !conversation.user1Read)
        this.unreadConversationsCount++;
      if (userUid === conversation.user2Uid && !conversation.user2Read)
        this.unreadConversationsCount++;
    });
  }
}
