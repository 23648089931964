import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {environment} from '../../../../environments/environment';
import {ListingService} from '../../../listing/listing.service';
import {BookService} from '../../../book/book.service';
import {MatDialog} from '@angular/material/dialog';
import {SocialService} from '../../../social/social.service';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {
  CANNOT_BE_UNDONE,
  DISABLE_LISTING_CONFIRMATION_MESSAGE,
  DISABLE_LISTING_CONFIRMATION_TITLE,
  ENABLE_LISTING_CONFIRMATION_MESSAGE,
  ENABLE_LISTING_CONFIRMATION_TITLE,
} from '../../../shared/constants/strings';
import {UserService} from '../../../shared/services/user.service';
import {Rating} from '../../../shared/models/rating.interface';
import {UtilService} from '../../../shared/util.service';
import {ElementScrollPercentageService} from '../../../shared/services/element-scroll-percentage.service';
import {UserPublic} from '../../../shared/models/userPublic.interface';
import {Listing} from '../../../shared/models/listing.interface';
import {SettingsService} from '../../../shared/services/settings.service';
import {ReportType} from '../../../shared/models/reportType.type';
import {Report} from '../../../shared/models/report.interface';
import Util from '../../../shared/util';
import {
  ActionType,
  ConfirmSendMessageDialogComponent,
  ConfirmSendMessageDialogModel,
} from '../confirm-send-message-dialog/confirm-send-message-dialog.component';
import Locale from '../../../shared/services/locale';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-reports-view',
  templateUrl: './reports-view.component.html',
  styleUrls: ['./reports-view.component.scss'],
})
export class ReportsViewComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  @Input() thereIsMore = false;
  /**
   * The scroll percentage, at which new reports are loaded. For example, the value 50 will reload items after scrolling down half the page.
   */
  @Input() infiniteScrollPercentage = 101;
  @Output() loadMoreReports = new EventEmitter<number>();
  @Input() moreReportsCount = environment.defaultLoadReportsCount;
  @Input() type?: ReportType;
  numberFormatLocale = Locale.numberFormatLocale();
  /**
   * A map of report arrays grouped by reference ID (listingUid or ratingUid)
   */
  reportsByRefUid: Map<string, Report[]> = new Map<string, Report[]>();
  listingsByUid: Map<string, Listing> = new Map<string, Listing>();
  ratingsByUid: Map<string, Rating> = new Map<string, Rating>();
  reporteesByUid: Map<string, UserPublic> = new Map<string, UserPublic>();

  // Checkboxes for selecting multiple reports
  metaCheckboxValueByRefUid: Map<string, boolean> = new Map<string, boolean>();
  checkboxValuesMapsByRefUid: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();

  private readonly spinnerKeyFetchingListing = 'fetchingListing';
  private readonly spinnerKeyFetchingRating = 'fetchingRating';
  private readonly spinnerKeyFetchingReportee = 'fetchingReportee';

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private cdRef: ChangeDetectorRef,
              private settingsService: SettingsService,
              private listingService: ListingService,
              public dialog: MatDialog,
              private socialService: SocialService,
              private bookService: BookService,
              private elementScrollPercentageService: ElementScrollPercentageService,
              public utilService: UtilService) {
    super(store);

  }

  _reports: Report[] = [];

  get reports(): Report[] {
    return this._reports;
  }

  @Input() set reports(reports: Report[]) {
    this._reports = reports;
    this.reportsByRefUid.clear();
    this.groupReports();
    this.initializeCheckboxes();
    this.cleanUpCheckboxesAndUpdateMetaCheckboxes();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.elementScrollPercentageService
        .getScrollAsStream() // Defaults to Document if no Element supplied.
        .subscribe((percent: number): void => {
          if (!this.thereIsMore || this.infiniteScrollPercentage >= 100)
            return;
          if (percent > this.infiniteScrollPercentage)
            this.onClickShowMore();
        });

    // Subjects emitted from the report-view after deletion of a listing, rating, report or all reports

    this.socialService.onListingDeletedSubject$.pipe(takeUntil(this.destroy$)).subscribe(listingUid => {
      this.reportsByRefUid.delete(listingUid);
      this.cleanUpCheckboxesAndUpdateMetaCheckboxes();
    });
    this.socialService.onRatingDeletedSubject$.pipe(takeUntil(this.destroy$)).subscribe(ratingUid => {
      this.reportsByRefUid.delete(ratingUid);
      this.cleanUpCheckboxesAndUpdateMetaCheckboxes();
    });
    this.socialService.onReportDeletedSubject$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      const reports = this.reportsByRefUid.get(value.refUid);
      if (!reports)
        return;
      const deletedReport = reports.find(rep => rep.uid === value.reportUid);
      Util.removeFromArray(reports, deletedReport);
      if (reports.length === 0)
        this.reportsByRefUid.delete(value.refUid);
      this.cleanUpCheckboxesAndUpdateMetaCheckboxes();
    });
    this.socialService.onAllReportsDeletedSubject$.pipe(takeUntil(this.destroy$)).subscribe(refUid => {
      this.reportsByRefUid.delete(refUid);
      this.cleanUpCheckboxesAndUpdateMetaCheckboxes();
    });

  }


  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  /**
   * Called, when the Show more button is clicked. Emits an event to the parent component to request more reports.
   * Those will - if available - be added to the field reports.
   */
  onClickShowMore(): void {
    this.loadMoreReports.emit(this.moreReportsCount);
  }


  // Checkboxes for selecting multiple reports

  checkAllCheckboxes(checked: boolean, refUid: string) {
    this.metaCheckboxValueByRefUid.set(refUid, checked);
    let innerMap = this.checkboxValuesMapsByRefUid.get(refUid);
    // If the inner map does not exist, initialize it
    if (!innerMap) {
      console.error(`Inner map (this.checkboxValuesMapsByRefUid.get(refUid)) does not exist for refUid ${refUid}`);
      return;
    }
    [...innerMap.keys()].forEach(key => innerMap!.set(key, checked));
  }

  isCheckboxIndeterminate(refUid: string): boolean {
    const indeterminate = this.isAnyCheckboxChecked(refUid) && !this.metaCheckboxValueByRefUid.get(refUid);
    return indeterminate;
  }

  isAnyCheckboxChecked(refUid: string): boolean {
    if (!this.checkboxValuesMapsByRefUid?.get(refUid)) {
      // If the map of checkboxes for this reference does not exist, the meta checkbox is not checked or indeterminate
      return false;
    }
    const innerMap: Map<string, boolean> = this.checkboxValuesMapsByRefUid.get(refUid)!;
    const checkboxValues: boolean[] = [...innerMap.values()];
    return checkboxValues.filter(value => value).length > 0;
  }

  updateMetaCheckbox(refUid: string) {
    if (!this.checkboxValuesMapsByRefUid?.get(refUid)) {
      // If the map of checkboxes for this reference does not exist, the meta checkbox is not set
      this.metaCheckboxValueByRefUid.set(refUid, false);
      return;
    }
    const innerMap: Map<string, boolean> = this.checkboxValuesMapsByRefUid.get(refUid)!;
    const checkboxValues: boolean[] = [...innerMap.values()];
    const allChecked = checkboxValues.every(value => value);
    this.metaCheckboxValueByRefUid.set(refUid, allChecked);
  }

  /**
   * Updates all meta checkboxes - those are the checkboxes located next to a listing or rating.
   */
  updateAllMetaCheckboxes() {
    [...this.reportsByRefUid.keys()].forEach(refUid => this.updateMetaCheckbox(refUid));
  }

  cleanUpCheckboxesAndUpdateMetaCheckboxes() {
    this.cleanUpCheckboxes();
    this.updateAllMetaCheckboxes();
  }

  updateCheckbox(refUid: string, reportUid: string, checked: boolean) {
    let innerMap = this.checkboxValuesMapsByRefUid.get(refUid);
    // If the inner map does not exist, initialize it
    if (!innerMap) {
      innerMap = new Map<string, boolean>();
      this.checkboxValuesMapsByRefUid.set(refUid, innerMap);
    }
    innerMap.set(reportUid, checked);
    this.updateMetaCheckbox(refUid);
  }

  /**
   * Initializes all checkboxes, that are not yet initialized
   */
  initializeCheckboxes() {
    [...this.reportsByRefUid.entries()].forEach(entry => {
      const refUid = entry[0];
      const reports: Report[] = entry[1];

      let innerMap = this.checkboxValuesMapsByRefUid.get(refUid);
      // If the inner map does not exist, initialize it
      if (!innerMap) {
        innerMap = new Map<string, boolean>();
        this.checkboxValuesMapsByRefUid.set(refUid, innerMap);
      }

      // Initialize all missing report checkboxes with false
      reports.forEach(report => {
        const reportUid = report.uid;
        if (!reportUid)
          return;

        if (!innerMap!.get(reportUid))
          innerMap!.set(reportUid, false);
      });
    });
  }

  /**
   *  Removes all checkboxes, that no longer exist
   */
  cleanUpCheckboxes() {
    [...this.checkboxValuesMapsByRefUid.entries()].forEach(entry => {
      const refUid = entry[0];
      const innerMap: Map<string, boolean> = entry[1];

      // If the reference does no longer exist
      const reports = this.reportsByRefUid.get(refUid);
      if (!reports) {
        // Delete the whole entry and be done with it
        this.checkboxValuesMapsByRefUid.delete(refUid);
        return;
      }

      // The reference does exist. Check the innerMap
      [...innerMap.keys()].forEach(reportUid => {
        // Check, if there is a report for this reportUid
        const foundReport = reports.find(report => report?.uid === reportUid);
        // If no report was found for the reportUid
        if (!foundReport)
            // Delete the checkbox entry from the inner map
          innerMap.delete(reportUid);
      });
    });
  }

  // End: Checkboxes for selecting multiple reports


  // Button handling

  onEnableListing(refUid: string): void {
    this.clearAlerts();
    this.showMessageDialog(ENABLE_LISTING_CONFIRMATION_TITLE, ENABLE_LISTING_CONFIRMATION_MESSAGE, 'enableListing', this.enableListing.bind(this), refUid);
  }

  onDisableListing(refUid: string): void {
    this.clearAlerts();
    this.showMessageDialog(DISABLE_LISTING_CONFIRMATION_TITLE, DISABLE_LISTING_CONFIRMATION_MESSAGE, 'disableListing', this.disableListing.bind(this), refUid);
  }

  onDeleteListing(refUid: string): void {
    this.clearAlerts();
    this.showMessageDialog($localize`Do you really want to delete this listing?`, CANNOT_BE_UNDONE, 'deleteListing', this.deleteListing.bind(this), refUid);
  }

  onDeleteSelectedReports(refUid: string): void {
    this.clearAlerts();
    if (!this.checkboxValuesMapsByRefUid?.get(refUid)) {
      // If the map of checkboxes for this reference does not exist, there certainly are no reports selected
      return;
    }
    const innerMap: Map<string, boolean> = this.checkboxValuesMapsByRefUid.get(refUid)!;
    let count = 0;
    [...innerMap.entries()].forEach(entry => {
      const selected: boolean = entry[1];
      if (selected)
        count++;
    });

    this.utilService.showConfirmDialog($localize`Do you really want to delete ${count} selected report?`, CANNOT_BE_UNDONE, this.deleteSelectedReports.bind(this), [refUid], undefined, undefined, undefined, 'no');
  }

  onDeleteAllReportsForReference(refUid: string): void {
    this.clearAlerts();
    this.utilService.showConfirmDialog($localize`Do you really want to delete all reports for this reference?`, CANNOT_BE_UNDONE, this.deleteAllReportsForReference.bind(this), [refUid], undefined, undefined, undefined, 'no');
  }

  onDeleteRating(refUid: string): void {
    this.clearAlerts();
    this.showMessageDialog($localize`Do you really want to delete this rating?`, CANNOT_BE_UNDONE, 'deleteRating', this.deleteRating.bind(this), refUid);
  }

  deleteListing(refUid: string): void {
    const listing = this.listingsByUid.get(refUid);
    if (!listing?.uid)
      return;
    this.listingService.deleteListing(listing.uid).then(() => {
          this.addSuccess($localize`The listing ${refUid} was deleted.`);
          this.listingsByUid.delete(refUid);
          this.utilService.showConfirmDialog($localize`Do you want to delete all reports for this listing?`, CANNOT_BE_UNDONE, this.deleteAllReportsForReference.bind(this), [refUid], undefined, undefined, undefined, 'no');
        },
        error => this.addError($localize`Deleting listing failed\: ${error}`));
  }

  enableListing(refUid: string): void {
    let listing = this.listingsByUid.get(refUid);
    if (!listing?.uid)
      return;
    this.listingService.enableOrDisableListing(listing, false, () => {
          this.addSuccess($localize`The listing was enabled.`);
          listing = {...listing, disabled: false};
          this.listingsByUid.set(refUid, listing);
        },
        error => this.addError($localize`Enabling listing failed\: ${error}`));
  }

  disableListing(refUid: string): void {
    let listing = this.listingsByUid.get(refUid);
    if (!listing?.uid)
      return;
    this.listingService.enableOrDisableListing(listing, true, () => {
          this.addSuccess($localize`The listing was disabled.`);
          listing = {...listing, disabled: true};
          this.listingsByUid.set(refUid, listing);
        },
        error => this.addError($localize`Disabling listing failed\: ${error}`));
  }


  deleteSelectedReports(refUid: string): void {
    if (!this.checkboxValuesMapsByRefUid?.get(refUid)) {
      // If the map of checkboxes for this reference does not exist, there certainly are no reports selected
      return;
    }
    const innerMap: Map<string, boolean> = this.checkboxValuesMapsByRefUid.get(refUid)!;
    [...innerMap.entries()].forEach(entry => {
      const selected: boolean = entry[1];
      if (!selected)
        return;

      const reportUid = entry[0];

      this.socialService.deleteReport(reportUid).then(() => {
            this.addSuccess($localize`The report ${reportUid} was deleted.`);
            const reportsForRefUid: Report[] | undefined = this.reportsByRefUid.get(refUid);
            if (reportsForRefUid) {
              const deletedReport = reportsForRefUid.find(rep => rep.uid === reportUid);
              Util.removeFromArray(reportsForRefUid, deletedReport);
            }
            this.cleanUpCheckboxesAndUpdateMetaCheckboxes();

          },
          error => this.addError($localize`Deleting report failed\: ${error}`));
    });

    this.utilService.showConfirmDialog($localize`Do you want to delete all reports for this listing?`, CANNOT_BE_UNDONE, this.deleteAllReportsForReference.bind(this), [refUid], undefined, undefined, undefined, 'no');
  }

  deleteAllReportsForReference(refUid: string): void {
    const reportsForRefUid = this.reportsByRefUid.get(refUid);
    if (!reportsForRefUid) {
      this.addError($localize`No report were found for reference UID ${refUid}. Deletion not possible.`);
      return;
    }

    this.socialService.deleteAllReports(count => {
      this.addSuccess($localize`${count} reports were deleted.`);
      // Remove all reports for refUid
      this.reportsByRefUid.delete(refUid);
      this.cleanUpCheckboxesAndUpdateMetaCheckboxes();
    }, error => {
      this.addError($localize`Report could not be deleted\: ${error}`);
    }, this.type, refUid);

  }

  deleteRating(refUid: string): void {
    let rating = this.ratingsByUid.get(refUid);
    if (!rating?.uid)
      return;
    this.bookService.deleteRating(rating.uid).then(() => {
          this.addSuccess($localize`The rating ${refUid} was deleted.`);
          // Note: the transaction is updated automatically by a cloud function removing the ratingUid
          this.ratingsByUid.delete(refUid);
          this.utilService.showConfirmDialog($localize`Do you want to delete all reports for this rating?`, CANNOT_BE_UNDONE, this.deleteAllReportsForReference.bind(this), [refUid], undefined, undefined, undefined, 'no');
        },
        error => this.addError($localize`Deleting rating failed\: ${error}`));
  }

  showMessageDialog(title: string, message: string, actionType: ActionType, callback: (refUid: string) => void, refUid: string): void {
    this.clearAlerts();
    const listing = this.listingsByUid.get(refUid);
    const rating = this.ratingsByUid.get(refUid);
    const report = this.reportsByRefUid.get(refUid);
    const receiverUid = listing ? listing.lenderUid : rating?.raterUid;
    const type: ReportType = listing ? 'listing' : 'rating';
    const dialogData = new ConfirmSendMessageDialogModel(title, message, listing, rating, undefined, receiverUid, actionType, type, rating?.transactionUid);
    const dialogRef = this.dialog.open(ConfirmSendMessageDialogComponent, {minWidth: '300px', width: '600px', maxWidth: '1000px', data: dialogData});
    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult)
        callback(refUid);
    });
  }

  // End: Button handling

  areThereReportsForReference(refUid: string): boolean {
    const reportsForRefUid = this.reportsByRefUid.get(refUid);
    if (!reportsForRefUid)
      return false;
    return [...reportsForRefUid].length > 0;
  }

  private groupReports() {
    if (!this.type)
      return;

    switch (this.type) {
      case 'listing': {
        this.reports.forEach(rep => {
          if (rep.listingUid) {
            this.fetchListing(rep.listingUid);
            this.addToMap(rep, rep.listingUid);
          }
        });
        break;
      }
      case 'rating': {
        this.reports.forEach(rep => {
          if (rep.ratingUid) {
            this.fetchRating(rep.ratingUid);
            this.addToMap(rep, rep.ratingUid);
          }
        });
        break;
      }
    }
  }

  private addToMap(rep: Report, refUid: string) {
    let array: Report[] | undefined = this.reportsByRefUid.get(refUid);
    if (!array)
      array = [];
    array.push(rep);
    this.reportsByRefUid.set(refUid, array);
  }

  private fetchListing(listingUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyFetchingListing, $localize`Fetching listing ${listingUid}...`);
    this.listingService.fetchListing(listingUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyFetchingListing);
      if (wrapper.data) {
        this.listingsByUid.set(listingUid, wrapper.data);
        if (wrapper.data.lenderUid)
          this.fetchReportee(wrapper.data.lenderUid);
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading listing ${listingUid}\: ${wrapper.errorMessage}`);
    });
  }

  private fetchRating(ratingUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyFetchingRating, $localize`Fetching rating ${ratingUid}...`);
    this.bookService.fetchRating(ratingUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyFetchingRating);
      if (wrapper.data) {
        this.ratingsByUid.set(ratingUid, wrapper.data);
        this.fetchReportee(wrapper.data.raterUid);
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading rating ${ratingUid}\: ${wrapper.errorMessage}`);
    });
  }

  private fetchReportee(reporteeUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyFetchingReportee, $localize`Fetching reportee ${reporteeUid}...`);
    this.userService.fetchUserPublic(reporteeUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyFetchingReportee);
      if (wrapper.data)
        this.reporteesByUid.set(reporteeUid, wrapper.data);
      if (wrapper.errorMessage)
        this.addError($localize`Error loading reportee ${reporteeUid}\: ${wrapper.errorMessage}`);
    });
  }
}
