import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {setPaymentParams} from './pay.actions';
import {UserPublic} from '../../shared/models/userPublic.interface';
import {Transaction} from '../../shared/models/transaction.interface';
import {TransactionListing} from '../../shared/models/transactionListing.interface';


export interface PayState {
  transaction?: Transaction,
  listing?: TransactionListing,
  amount?: number,
  currencyId?: string,
  receiver?: UserPublic
}

export const initialState: PayState = {
  transaction: undefined,
  listing: undefined,
  amount: undefined,
  currencyId: undefined,
  receiver: undefined,
};

function logDebugMessages(actionName: string, state: PayState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

const reducer: ActionReducer<PayState> = createReducer(
    initialState,

    on(setPaymentParams, (state, {amount, currencyId, transaction, listing, receiver}) => {

      const newState = {...state, currencyId, transaction, listing, amount, receiver};
      logDebugMessages('setPaymentParams', state, newState);
      return newState;
    }),
);

export function payReducer(state: PayState | undefined, action: Action): PayState {
  return reducer(state, action);
}

