import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {TitleService} from '../../shared/services/title.service';
import {MetadataService} from '../../shared/services/metadata.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent extends BaseComponent implements OnInit {

  constructor(
      protected store: Store<AppState>,
      private metadataService: MetadataService,
      private titleService: TitleService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Privacy policy`);
    this.metadataService.updateTags($localize`Privacy policy`,
        $localize`Read all about our privacy policy.`,
        $localize`privacy policy, data protection, GDPR, DSGVO`,
    );
  }

}
