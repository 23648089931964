import {createSelector} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';

export const selectAuth = (state: AppState) => state.auth;

export const selectUser = createSelector(
    selectAuth,
    (authState) => authState.user,
);

export const selectFirebaseUser = createSelector(
    selectAuth,
    (authState) => authState.firebaseUser,
);

export const selectFetchUserActionResult = createSelector(
    selectAuth,
    (authState) => ({
      user: authState.user,
      success: authState.fetchUserResult.success,
      errorMessage: authState.fetchUserResult.errorMessage,
    }),
);

export const selectUpdateUserActionResult = createSelector(
    selectAuth,
    (authState) => ({
      user: authState.user,
      success: authState.updateUserResult.success,
      errorMessage: authState.updateUserResult.errorMessage,
    }),
);

