import {Component, Input, OnInit} from '@angular/core';
import {Address} from '../../models/address.interface';
import {CountryService} from '../../services/country.service';

@Component({
  selector: 'app-public-address',
  templateUrl: './public-address.component.html',
  styleUrls: ['./public-address.component.css'],
})
export class PublicAddressComponent implements OnInit {

  @Input() address?: Address;

  constructor(public countryService: CountryService) {
  }

  ngOnInit(): void {
  }

}
