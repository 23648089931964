import {Injectable} from '@angular/core';
import {Language} from '../models/language.interface';
import {Subject} from 'rxjs';
import Locale from './locale';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  public onLanguageChanged: Subject<string> = new Subject<string>();
  public changeAndSaveLanguage: Subject<Language> = new Subject<Language>();

  languages: Language[] = [
    {code: 'de', strings: {en: 'German', de: 'Deutsch'}, iconCode: 'DE'},
    {code: 'en', strings: {en: 'English', de: 'Englisch'}, iconCode: 'US'},
  ];

  languageNames: string[] = [];
  /**
   * Map of language names by language code. Key = Code, Value = name
   */
  languageNamesByCode = new Map<string, string>();


  constructor() {
    this.languages.forEach(lang => {
      const name = lang.strings[Locale.languageLocale()];
      this.languageNames.push(name);
      this.languageNamesByCode.set(lang.code, name);
    });
  }

  /**
   * Delivers the language name for the given alpha 2 language code.
   * @param code alpha 2 language code, e.g. DE, US, CN...
   * @return full language name in the language set in environment.countryLocale
   */
  public getLanguageNameByCode(code: string): string | undefined {
    return this.languageNamesByCode.get(code);
  }

}
