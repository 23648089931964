<div class="container bg-container p-1">

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [message]="loadingSpinnerMessage"
                       [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li *ngIf="!rating && !receiver" class="breadcrumb-item" i18n="User">User</li>
      <a *ngIf="rating && !receiver" [routerLink]="['/social','user',rating.receiverUid]" class="breadcrumb-item" i18n="User">User</a>
      <a *ngIf="receiver" [routerLink]="['/social','user',receiver.uid]" class="breadcrumb-item" i18n="User">{{receiver.displayName}}</a>
      <li class="breadcrumb-item" i18n="rating">Rating</li>
    </ol>
  </nav>

  <!-- Jumbotron -->
  <div class="row">
    <div class="col">
      <div class="jumbotron mb-3">
        <h1 *ngIf="!receiver" class="jumbotron-headline" i18n="rating">Rating</h1>
        <h1 *ngIf="receiver" class="jumbotron-headline" i18n>Rating for {{receiver.displayName}}</h1>
        <app-user-info [userPublicUid]="rating?.receiverUid"></app-user-info>
        <p *ngIf="rating && rating.transactionUid" class="jumbotron-lead" i18n>For <a [routerLink]="['/account','transactions',rating.transactionUid]">this
          transaction</a>.</p>
        <p *ngIf="rating && rating.receiverRole === transactionRole.Borrower" class="jumbotron-lead" i18n>The rater was the lender.</p>
        <p *ngIf="rating && rating.receiverRole === transactionRole.Lender" class="jumbotron-lead" i18n>The rater was the borrower.</p>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
  </div>

  <ng-container *ngIf="!listing && rating && rating.embeddedListing">
    <app-item-card [listingImgUrlThumb]="rating.embeddedListing.imgUrlThumb" [listingName]="rating.embeddedListing.name"
                   [listingUid]="rating.embeddedListing.uid"></app-item-card>
  </ng-container>
  <ng-container *ngIf="listing">
    <app-item-card [listing]="listing"></app-item-card>
  </ng-container>

  <div class="card-deck">

    <!-- Rater -->
    <div *ngIf="rater" class="info-card card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['/social','user',rater.uid]">
            <img alt="Rater image" class="contained-200h-150w" default="./assets/images/default/default-user-w300.webp"
                 i18n-alt="Rater image alt text"
                 src="{{rater.imgUrl}}"></a>
        </div>
        <div class="col">
          <div class="card-body">
            <h2 class="paragraph-headline-2" i18n="rater headline">Rater</h2>
            <p *ngIf="userIsRater()" i18n="you are the rater">You are the rater.</p>
            <p *ngIf="!userIsRater()">{{rater.displayName}}</p>
            <app-user-info-internal [userPublic]="rater"></app-user-info-internal>
          </div>
        </div>
      </div>
    </div>

    <!-- Receiver -->
    <div *ngIf="receiver" class="info-card card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['/social','user',receiver.uid]">
            <img alt="Receiver image" class="contained-200h-150w" default="./assets/images/default/default-user-w300.webp"
                 i18n-alt="Receiver image alt text"
                 src="{{receiver.imgUrl}}"></a>
        </div>
        <div class="col">
          <div class="card-body">
            <h2 class="paragraph-headline-2" i18n="receiver headline">Receiver</h2>
            <p *ngIf="userIsReceiver()" i18n="you are the receiver">You are the receiver.</p>
            <p *ngIf="!userIsReceiver()">{{receiver.displayName}}</p>
            <app-user-info-internal [userPublic]="receiver"></app-user-info-internal>
          </div>
        </div>
      </div>
    </div>

  </div>

  <ng-container *ngIf="rating">
    <app-rating [rating]="rating" [showListing]="false"></app-rating>


    <!-- Report rating -->
    <h1 class="paragraph-headline mt-3" i18n="report listing headline">Report rating</h1>
    <p i18n>In case, you find this rating inappropriate, offensive or illegal, please report it:</p>
    <app-report-button [uid]="rating.uid" appearance="button" type="rating"></app-report-button>

    <div *ngIf="user?.admin || rating.raterUid === user?.uid">

      <!-- Delete rating -->
      <h1 class="paragraph-headline mt-3" i18n="delete listing headline">Delete rating</h1>
      <p i18n>In case, want to delete this rating, click here:</p>
      <app-mat-icon-button *ngIf="rating" [callback]="onDeleteRating.bind(this)" color="warn" cssClass="mr-2 mb-3"
                           i18n-label="delete rating"
                           icon="delete" label="Delete rating"></app-mat-icon-button>

    </div>
  </ng-container>

</div>
