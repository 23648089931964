import {Component, OnInit} from '@angular/core';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {CategoryService, createMutableCategories} from '../../shared/services/category.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {Category, CatLangStrings} from '../../shared/models/category.interface';
import {CANNOT_BE_UNDONE} from '../../shared/constants/strings';
import {Subject} from 'rxjs';
import firebase from 'firebase/app';
import {environment} from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImportExportModalContentComponent} from '../../shared/components/import-export-modal-content/import-export-modal-content.component';
import {TitleService} from '../../shared/services/title.service';

import {firestore} from '../../app.module';
import {UtilService} from '../../shared/util.service';
import FirebaseError = firebase.FirebaseError;

@Component({
  selector: 'app-categories-editor',
  templateUrl: './categories-editor.component.html',
  styleUrls: ['./categories-editor.component.css'],
})
export class CategoriesEditorComponent extends BaseComponent implements OnInit {

  categories: Category[] = [];
  originalCategories: Category[] = [];
  selectedCategory?: Category;


  resetSubject = new Subject<boolean>();
  showTrashBin = false;

  constructor(
      protected store: Store<AppState>,
      public utilService: UtilService,
      private titleService: TitleService,
      private modalService: NgbModal,
      private categoryService: CategoryService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Categories editor`);
    this.loadCategories();
  }

  onCategorySelected(category: Category) {
    this.clearAlerts();
    this.selectedCategory = category;
  }


  loadCategories(): void {
    this.categoryService.fetchCategories().then(wrapper => {
      if (wrapper.errorMessage)
        this.addError($localize`Error loading categories\: ${wrapper.errorMessage}`);
      this.categories = wrapper.data ? createMutableCategories(wrapper.data) : [];
      this.originalCategories = this.createClone(this.categories);
    });
  }

  onReset(): void {
    this.clearAlerts();
    this.utilService.showConfirmDialog($localize`Are you sure you want to reset all categories?`, CANNOT_BE_UNDONE, this.loadCategories.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  onSave(): void {
    this.clearAlerts();
    this.utilService.showConfirmDialog($localize`Are you sure you want to save all categories?`, CANNOT_BE_UNDONE, this.save.bind(this), undefined, undefined, undefined, undefined, 'yes');
  }

  onImportExport(): void {
    this.clearAlerts();
    const modalRef = this.modalService.open(ImportExportModalContentComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.filename = 'blitzshare_categories';
    modalRef.componentInstance.exportJson = JSON.stringify(this.categories);
    modalRef.componentInstance.importCallback = (categoriesJson: string) => {
      const importedCategories: Category[] = JSON.parse(categoriesJson);
      this.categories = importedCategories ? createMutableCategories(importedCategories) : [];
    };
  }

  onUpdateStore(): void {
    this.categoryService.setCategories(this.categories);
    this.loadCategories();
  }


  /**
   * Finds the category IDs, which existed in original categories, but do no longer exist in newCategories.
   * @param newCategories new categories (after editing)
   * @param originalCategories original categories (before editing)
   * @return array of category IDs of all categories removed during editing
   */
  private findDeletedDocUids(newCategories: Category[], originalCategories: Category[]): string[] {
    const deletedDocUids: string[] = [];
    originalCategories.forEach((originalCat) => {
      let found = false;
      // Search the original category in the newCategories list
      for (let newCat of newCategories) {
        if (newCat.id === originalCat.id) {
          found = true;
          break;
        }
      }
      if (!found)
        deletedDocUids.push(originalCat.id);
    });
    return deletedDocUids;
  }

  private save() {

    this.setLevels(this.categories, 0);
    this.handleUndefinedFields(this.categories);

    // Delete deleted categories
    const deletedDocUids: string[] = this.findDeletedDocUids(this.categories, this.originalCategories);
    deletedDocUids.forEach((deletedDocUid) => {
      try {
        firestore.collection(environment.firestoreCollectionCategories).doc(deletedDocUid).delete().then(() => {
              this.addSuccess($localize`Successfully deleted category tree ${deletedDocUid}.`);
            },
            (reason: FirebaseError) => {
              this.addError($localize`Error deleting category tree ${deletedDocUid}. Reason\: ${reason.message}`);
            });
      } catch (reason) {
        this.addError($localize`Error deleting category tree ${deletedDocUid}. Reason\: ${reason.message}`);
      }
    });
    this.categories.forEach(cat => {

      // Validate
      if (!this.areCategoriesValid([cat]))
        return;

      const originalCat = this.findOriginalCategoryTree(cat, this.originalCategories);
      if (originalCat && this.areCategoryEqual(originalCat, cat)) {
        this.addInfo($localize`Category tree ${this.categoryService.getCategoryName(cat)} was skipped, because it has not been changed.`);
        return;
      }

      try {
        firestore.collection(environment.firestoreCollectionCategories).doc(cat.id).set(cat).then(() => {
              this.addSuccess($localize`Successfully saved category tree\: ${this.categoryService.getCategoryName(cat)}.`);
            },
            (reason: FirebaseError) => {
              this.addError($localize`Category ${this.categoryService.getCategoryName(cat)} could not be saved\: ${reason.message}`);
            });
      } catch (reason) {
        this.addError($localize`Category ${this.categoryService.getCategoryName(cat)} could not be saved\: ${reason.message}`);
      }
    });

    if (this.getErrors().length === 0) {
      this.originalCategories = this.createClone(this.categories);
    }
  }

  /**
   * Goes through all category trees and sets level according to the depths inside the trees. Calls itself recursively for nested categories.
   * @param categories categories to be set to the given level.
   * @param level level to be set in the root categories of the given array. the next nested level will be set to level+1 and so on
   */
  private setLevels(categories: Category[], level: number) {
    categories.forEach(cat => {
      cat.level = level;
      if (cat.subcategories)
        this.setLevels(cat.subcategories, level + 1);
    });
  }

  private areCategoriesValid(categories?: Category[]): boolean {
    let valid = true;
    if (!categories)
      return true;

    categories.forEach(cat => {
      valid = this.isFieldValid(cat, cat.id, 'ID', valid);
      valid = this.isFieldValid(cat, cat.strings, 'strings', valid);
      if (cat.strings)
        Object.entries(cat.strings).forEach(entry => {
          const lang: string = entry[0];
          const catLangStrings: CatLangStrings = entry[1];
          valid = this.isFieldValid(cat, catLangStrings.name, `${lang}-name`, valid);
          valid = this.isFieldValid(cat, catLangStrings.elementName, `${lang}-elementName`, valid);
        });
      this.areCategoriesValid(cat.subcategories);
    });
    return valid;
  }

  private isFieldValid(category: Category, fieldValue: any, fieldName: string, wasValidBefore: boolean): boolean {
    if (!fieldValue) {
      this.addError($localize`Invalid category` + ` ${this.categoryService.getCategoryName(category)}\: ` + $localize`Missing value` + `\: ${fieldName}`);
      return false;
    }
    return wasValidBefore;
  }

  /**
   * Checks, if the two given categories are equal. Also checks all nested string values and all subcategories.
   * @param a first category
   * @param b second category
   * @return true, if equal, false if not
   */
  private areCategoryEqual(a: Category, b: Category) {
    if (a === undefined && b === undefined)
      return true;
    // If only one is undefined
    if (a === undefined || b === undefined)
      return false;
    if (a.id !== b.id)
      return false;
    if (a.level !== b.level)
      return false;
    if (a.imgUrlFull !== b.imgUrlFull)
      return false;
    if (a.imgUrlThumb !== b.imgUrlThumb)
      return false;
    if ((a.strings !== undefined && b.strings === undefined) || (a.strings === undefined && b.strings !== undefined))
      return false;
    const stringsEntries = Object.entries(a.strings);
    if (stringsEntries.length != Object.entries(b.strings).length)
      return false;
    for (let i = 0; i < stringsEntries.length; i++) {
      const stringsEntry = stringsEntries[i];
      const lang = stringsEntry[0];
      const aLangStrings = stringsEntry[1];
      const bLangStrings = b.strings[lang];
      if (aLangStrings.name !== bLangStrings.name)
        return false;
      if (aLangStrings.elementName !== bLangStrings.elementName)
        return false;
      if (aLangStrings.description !== bLangStrings.description)
        return false;
    }
    if ((a.subcategories !== undefined && b.subcategories === undefined) || (a.subcategories === undefined && b.subcategories !== undefined))
      return false;
    if (a.subcategories?.length !== b.subcategories?.length)
      return false;

    if (a.subcategories && a.subcategories.length > 0 && b.subcategories && b.subcategories.length > 0)
      for (let i = 0; i < a.subcategories.length; i++) {
        if (!this.areCategoryEqual(a.subcategories[i], b.subcategories[i]))
          return false;
      }

    return true;
  }

  private createClone(cats: Category[]): Category[] {
    // const clonedCats : Category[]=[];
    // cats.forEach(cat =>{
    //
    // })
    // return clonedCats;
    return createMutableCategories(cats);
  }

  private findOriginalCategoryTree(category: Category, originalCategories: Category[]): Category | undefined {
    for (let originalCategory of originalCategories) {
      if (category.id === originalCategory.id)
        return originalCategory;
    }
    return undefined;
  }

  /**
   * Handles undefined imgUrls and descriptions: If an imgUrl or description is undefined, it will be deleted from the category.
   * @param categories categories to be handled
   */
  private handleUndefinedFields(categories: Category[]) {
    categories.forEach(cat => {
      if (!cat.imgUrlFull)
        delete cat.imgUrlFull;
      if (!cat.imgUrlThumb)
        delete cat.imgUrlThumb;
      if (cat.strings)
        Object.entries(cat.strings).forEach(entry => {
          const lang: string = entry[0];
          const catLangStrings: CatLangStrings = entry[1];
          if (!catLangStrings.description)
            delete catLangStrings.description;
        });
      if (cat.subcategories)
        this.handleUndefinedFields(cat.subcategories);
    });
  }
}
