import {Pipe, PipeTransform} from '@angular/core';

type DocumentStatus =
  | 'CREATED'
  | 'VALIDATION_ASKED'
  | 'VALIDATED'
  | 'REFUSED';

@Pipe({
  name: 'mangopayKycDocumentStatus',
})
export class MangopayKycDocumentStatusPipe implements PipeTransform {

  transform(value: DocumentStatus, ...args: unknown[]): unknown {
    switch (value) {
      case 'CREATED':
        return $localize`Document created`;
      case 'VALIDATED':
        return $localize`Validation successful`;
      case 'REFUSED':
        return $localize`Document refused`;
      case 'VALIDATION_ASKED':
        return $localize`Waiting for validation`;
    }
    return $localize`Unknown`;
  }

}
