<div #catSelectorDropdown="ngbDropdown" class="d-inline-block always-on-top" container="body" ngbDropdown>
  <button [ngClass]="{ 'narrow-button': narrowButton, 'big-button': bigButton}" color="{{buttonColor}}" id="categoriesDropdown" mat-raised-button ngbDropdownToggle
          type="button">
    <span *ngIf="!selectedCategory && showAllCategories" i18n>All categories</span>
    <span *ngIf="!selectedCategory && !showAllCategories" i18n>Select category</span>
    <span *ngIf="selectedCategory">{{categoryService.getCategoryName(selectedCategory)}}</span>
  </button>
  <div aria-labelledby="categoriesDropdown" ngbDropdownMenu>
    <div class="dropdown-content">
      <input #search (cancel)="filter(search.value)" (emptied)="filter(search.value)"
             (keyup)="filter(search.value)" (paste)="filter(search.value)"
             (reset)="filter(search.value)"
             (search)="filter(search.value)"
             aria-label="Search"
             class="form-control  search-input"
             i18n-placeholder="category selector search input field placeholder text|The placeholder text of the search field in the category selector"
             id="searchTermCategory"
             placeholder="Search" type="search">

      <div class="dropdown-divider"></div>

      <!-- All categories button -->
      <div *ngIf="showAllCategories" [ngClass]="{ 'selected': !selectedCategory }" class="mat-tree-node">
        <button (click)="onSelectCategory(undefined)" class="nested-node-button all-categories-button">
          <span i18n>All categories</span>
        </button>
      </div>

      <!-- Categories tree -->
      <div class="tree">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="categories-tree">
          <!-- This is the tree node template for leaf nodes -->
          <!-- There is inline padding applied to this node using styles.
            This padding value depends on the mat-icon-button width. -->
          <mat-tree-node *matTreeNodeDef="let node" [ngClass]="{ 'selected': selectedCategory?.id === node?.id }"
                         matTreeNodeToggle>
            <button (click)="onSelectCategory(node)" class="nested-node-button">
              {{categoryService.getCategoryName(node)}}
            </button>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div [ngClass]="{ 'selected': selectedCategory?.id === node?.id }" class="mat-tree-node">
              <button [attr.aria-label]="'Toggle ' + categoryService.getCategoryName(node)" mat-icon-button
                      matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <button (click)="onSelectCategory(node)" class="nested-node-button bold">
                {{categoryService.getCategoryName(node)}}
              </button>
            </div>
            <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
            <div [class.categories-tree-invisible]="!treeControl.isExpanded(node)"
                 role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </div>

    </div>
  </div>
</div>
