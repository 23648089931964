import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './admin.component';
import {AdminOverviewComponent} from './admin-overview/admin-overview.component';
import {CategoriesEditorComponent} from './categories-editor/categories-editor.component';
import {ToolsComponent} from './tools/tools.component';
import {AnnouncementsEditorComponent} from './announcements-editor/announcements-editor.component';
import {FaqsEditorComponent} from './faqs-editor/faqs-editor.component';
import {FaqsEditorFormComponent} from './faqs-editor/faqs-editor-form/faqs-editor-form.component';
import {NoFaqShownComponentComponent} from './faqs-editor/no-faq-shown-component/no-faq-shown-component.component';
import {ReportsComponent} from './reports/reports.component';
import {ReportViewComponent} from './reports/report-view/report-view.component';
import {CurrenciesEditorComponent} from './currencies-editor/currencies-editor.component';
import {CurrenciesEditorFormComponent} from './currencies-editor/currencies-editor-form/currencies-editor-form.component';
import {NoCurrencyShownComponent} from './currencies-editor/no-currency-shown/no-currency-shown.component';

const adminRoutes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {path: '', redirectTo: 'overview', pathMatch: 'full'},
      {path: 'overview', component: AdminOverviewComponent},
      {path: 'reports', component: ReportsComponent, data: {reuse: true}},
      {path: 'reports/:reportUid', component: ReportViewComponent, data: {reuse: true}},
      {path: 'categories', component: CategoriesEditorComponent},
      {
        path: 'faqs', component: FaqsEditorComponent, children: [
          {path: '', component: NoFaqShownComponentComponent},
          {path: 'new', component: FaqsEditorFormComponent},
          {path: ':uid', component: FaqsEditorFormComponent},
        ],
      },
      {
        path: 'currencies', component: CurrenciesEditorComponent, children: [
          {path: '', component: NoCurrencyShownComponent},
          {path: 'new', component: CurrenciesEditorFormComponent},
          {path: ':uid', component: CurrenciesEditorFormComponent},
        ],
      },
      {path: 'announcements', component: AnnouncementsEditorComponent},
      {path: 'tools', component: ToolsComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {
}
