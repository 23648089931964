import {Currency} from '../../models/currency.interface';
import {Component, Input, OnInit} from '@angular/core';
import {CurrencyService} from '../../services/currency.service';
import Locale from '../../services/locale';

@Component({
  selector: 'app-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.css'],
})
export class MoneyComponent implements OnInit {

  @Input() value: number | undefined;
  @Input() currency: Currency | undefined;
  locale = Locale.numberFormatLocale();
  allCurrencies?: Currency[];

  constructor(public currencyService: CurrencyService) {
  }

  _currencyId?: string;

  get currencyId(): string | undefined {
    return this._currencyId;
  }

  @Input() set currencyId(currencyId: string | undefined) {
    this._currencyId = currencyId?.toUpperCase();
    this.setCurrency();
  }

  ngOnInit(): void {
    this.currencyService.getCurrencies().then(wrapper => {
      if (wrapper.data) {
        this.allCurrencies = wrapper.data;
        this.setCurrency();
      }
    });
  }

  private setCurrency() {
    if (this.allCurrencies && this.currencyId) {
      const foundCurrency = this.allCurrencies.find(currency => currency.id === this.currencyId);
      if (foundCurrency)
        this.currency = foundCurrency;
    }
  }
}
