import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UtilService} from '../../../../shared/util.service';
import {RatingSummary} from '../../../../shared/models/ratingSummary.interface';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {UserPublic} from '../../../../shared/models/userPublic.interface';
import {UserService} from '../../../../shared/services/user.service';
import {Rating} from '../../../../shared/models/rating.interface';
import {TransactionRole} from '../../../../shared/enums/transactionRole.enum';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.reducer';

@Component({
  selector: 'app-report-rating-preview',
  templateUrl: './report-rating-preview.component.html',
  styleUrls: ['./report-rating-preview.component.scss'],
})
export class ReportRatingPreviewComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  rater?: UserPublic;
  receiver?: UserPublic;
  raterRatingSummary?: RatingSummary;
  receiverRatingSummary?: RatingSummary;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public utilService: UtilService,
      private cdRef: ChangeDetectorRef,
  ) {
    super(store);
  }

  public get transactionRole(): typeof TransactionRole {
    return TransactionRole;
  }

  _rating: Rating | undefined;

  get rating(): Rating | undefined {
    return this._rating;
  }

  @Input() set rating(rating: Rating | undefined) {
    this._rating = rating;
    if (!rating)
      return;

    // Load rater
    if (rating.raterUid)
      this.userService.fetchUserPublic(rating.raterUid).then(wrapper => {
        if (wrapper.data) {
          this.rater = wrapper.data;
          this.raterRatingSummary = this.userService.calculateRatingSummary(this.rater);
        }
        if (wrapper.errorMessage)
          this.addError($localize`Error loading rater\: ${wrapper.errorMessage}`);
      });

    // Load receiver
    if (rating.receiverUid)
      this.userService.fetchUserPublic(rating.receiverUid).then(wrapper => {
        if (wrapper.data) {
          this.receiver = wrapper.data;
          this.receiverRatingSummary = this.userService.calculateRatingSummary(this.receiver);
        }
        if (wrapper.errorMessage)
          this.addError($localize`Error loading receiver\: ${wrapper.errorMessage}`);
      });
  }

  ngOnInit(): void {
    super.ngOnInit();

  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }
}

