import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {LayoutService} from '../../layout.service';
import {Faq} from '../../../shared/models/faq.interface';
import {Router} from '@angular/router';
import Util from '../../../shared/util';
import {MatAccordion} from '@angular/material/expansion';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  @Input() editMode = false;

  @ViewChild('accordion') matAccordion?: MatAccordion;
  /**
   * All FAQs
   */
  allFaqs: Faq[] = [];

  /**
   * Filtered FAQs
   */
  faqs: Faq[] = [];
  step = -1;
  filterTerm?: string;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private cdRef: ChangeDetectorRef,
      public layoutService: LayoutService,
      private router: Router) {
    super(store);
  }

  /**
   * If FAQs are given as an input, no FAQs will be fetched on init. Instead, allFaqs will be set to inputFaqs.
   */
  _inputFaqs?: Faq[];

  get inputFaqs(): Faq[] | undefined {
    return this._inputFaqs;
  }

  @Input() set inputFaqs(inputFaqs: Faq[] | undefined) {
    this._inputFaqs = inputFaqs;
    try {
      if (inputFaqs) {
        this.allFaqs = [...inputFaqs];
        this.filter();
        this.selectFaq(this.selectedFaqUid);
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * If FAQs are given as an input, no FAQs will be fetched on init. Instead, allFaqs will be set to inputFaqs.
   */
  _selectedFaqUid?: string;

  get selectedFaqUid(): string | undefined {
    return this._selectedFaqUid;
  }

  @Input() set selectedFaqUid(selectedFaqUid: string | undefined) {
    this._selectedFaqUid = selectedFaqUid;
    this.selectFaq(this.selectedFaqUid);
  }

  setStep(index: number) {
    this.step = index;
    if (this.editMode) {
      if (this.step > -1)
        this.router.navigate(['admin', 'faqs', this.faqs[index].uid]);
      else
        this.router.navigate(['admin', 'faqs']);
    }
  }

  closed() {
    if (this.editMode) {
      this.router.navigate(['admin', 'faqs']);
    }
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.inputFaqs)
      this.layoutService.fetchCachedFaqs(faqs => {
            this.allFaqs = [...faqs];
            this.filter();
          }, error => this.addError($localize`Error loading FAQs\: ${error}`),
      );
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  public filter(filterTerm?: string) {
    this.filterTerm = filterTerm;
    if (filterTerm && this.allFaqs)
      this.faqs = this.allFaqs.filter(faq => this.layoutService.getFaqQuestion(faq).toLowerCase().indexOf(filterTerm.toLowerCase()) > -1 ||
          this.layoutService.getFaqAnswer(faq).toLowerCase().indexOf(filterTerm.toLowerCase()) > -1);
    else
      this.faqs = this.allFaqs;
    this.faqs = this.faqs.sort((a, b) => {
      if (a.priority === b.priority)
        return Util.compare(this.layoutService.getFaqQuestion(a), this.layoutService.getFaqQuestion(b), true);
      return b.priority - a.priority;
    });
  }

  private selectFaq(selectedFaqUid: string | undefined) {
    if (selectedFaqUid) {
      const foundSelectedFaq = this.faqs.find((faq: Faq) => faq.uid === selectedFaqUid);
      if (!foundSelectedFaq)
        return;
      const index = this.faqs.indexOf(foundSelectedFaq);
      this.setStep(index);
      // } else {
      //   this.matAccordion?.closeAll();
    }
  }
}
