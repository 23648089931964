import {Component, Input, OnInit} from '@angular/core';
import {Address} from '../../models/address.interface';
import {CountryService} from '../../services/country.service';

@Component({
  selector: 'app-address-table',
  templateUrl: './address-table.component.html',
  styleUrls: ['./address-table.component.scss'],
})
export class AddressTableComponent implements OnInit {

  @Input() address?: Address;
  @Input() phone?: string | null;
  @Input() showName = true;
  @Input() showHint = true;

  constructor(public countryService: CountryService) {
  }

  ngOnInit(): void {
  }

}
