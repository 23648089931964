<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<p *ngIf="announcements.length===0" i18n>There are no announcements.</p>
<p *ngIf="announcements.length===100" i18n>There might be more announcements, that cannot be shown. Only 100 announcements are visible.</p>

<div class="list-container">
  <div (click)="select(announcement)" *ngFor="let announcement of announcements"
       class="alert alert-info flex-left hover-pointer"><p [innerHTML]="announcement.message"></p>
    <span class="small">
      <span *ngIf="announcement.priority" i18n>Priority {{announcement.priority}}. </span>
      <span i18n>Valid </span>
      <span *ngIf="announcement.validFrom"><span i18n>from </span>{{utilService.getDate(announcement.validFrom) | moment:'LLL'}} </span>
      <span *ngIf="announcement.validUntil"><span i18n>until </span>{{utilService.getDate(announcement.validUntil) | moment:'LLL'}} </span>
      <span i18n="on which environment">on
        {{announcement.environment === 'all' ? 'all environments' :
          (announcement.environment === 'non_prod_only' ? 'non-prod environments' :
            (announcement.environment === 'prod_only' ? 'prod environments' : 'unknown environments'))}}</span>
    </span>
    <div class="alert-box-actions">
      <button (click)="onDelete(announcement)" aria-label="Close announcement" i18n-aria-label="close announcement" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
