<form (ngSubmit)="save()" *ngIf="faq||new" [formGroup]="form">
  <h1 *ngIf="new" class="paragraph-headline" i18n="new faq">New FAQ</h1>
  <h1 *ngIf="!new && faq" class="paragraph-headline ellipsis" i18n="edit faq">Edit FAQ <i>"{{layoutService.getFaqQuestion(faq)}}"</i></h1>
  <div class="row mb-3">
    <div class="col">
      <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field class="mat-form-input text-input-100">
        <mat-label i18n="uid">ID</mat-label>
        <input #uidRef formControlName="uid"
               i18n-placeholder matInput maxlength="{{uidMaxLength}}" placeholder="FAQ ID"
               required tabindex="0">
        <span class="character-counter">{{uidRef.value.length}}/{{uidMaxLength}}</span>
      </mat-form-field>
      <h2 class="paragraph-headline-2" i18n>Material icon code</h2>
      <p i18n>Enter an icon code, e.g. <i>account_circle</i>. See <a href="https://fonts.google.com/icons" target="icons">list of Material icons</a>.
      </p>
      <mat-form-field class="mat-form-input text-input-100">
        <mat-label i18n="matIcon">Material icon code</mat-label>
        <input #matIconRef formControlName="matIcon"
               matInput maxlength="{{matIconMaxLength}}"
               placeholder="account_circle, search, paid, ...">
        <mat-icon matSuffix>{{matIconRef.value}}</mat-icon>
        <span class="character-counter">{{matIconRef.value.length}}/{{matIconMaxLength}}</span>
      </mat-form-field>
      <h2 class="paragraph-headline-2" i18n>Priority</h2>
      <p i18n>Use the slider or enter a value. The higher the priority, the more important is the FAQ.</p>
      0
      <mat-slider
        (change)="onPrioritySliderChange($event)"
        [displayWith]="formatPrioritySliderLabel" [value]="priorityRef.value" aria-label="units" color="primary" max="1000" min="0" step="1" thumbLabel
        tickInterval="1000"></mat-slider>
      1000
      <mat-form-field class="mat-form-input text-input-100">
        <mat-label i18n="priority">Priority</mat-label>
        <input #priorityRef formControlName="priority" i18n-placeholder="faq priority input placeholder"
               matInput maxlength="{{priorityMaxLength}}"
               placeholder="0-1000; the higher, the more important">
        <span class="character-counter">{{priorityRef.value.length}}/{{priorityMaxLength}}</span>
      </mat-form-field>

    </div>
    <div class="col mb-3">
      <div formArrayName="strings">
        <mat-tab-group [disablePagination]="true" [selectedIndex]="selectedTabIndex" class="no-paging" mat-align-tabs="start">
          <mat-tab *ngFor="let stringsFormGroup of getStringsFormArray().controls; index as stringsIndex"
                   [formGroupName]="stringsIndex">
            <ng-template *ngIf="langRef.value" mat-tab-label>{{langRef.value}}</ng-template>
            <ng-template *ngIf="!langRef.value" i18n="unspecified language" mat-tab-label>Unspecified language</ng-template>

            <div>

              <mat-divider class="py-2"></mat-divider>
              <div class="row ml-3">
                <div class="col-auto">

                  <mat-form-field class="mat-form-input text-input-100">
                    <mat-label i18n="language code">Language code</mat-label>
                    <input #langRef formControlName="lang"
                           matInput maxlength="{{langMaxLength}}"
                           placeholder="Language code" required>
                    <span class="character-counter">{{langRef.value.length}}/{{langMaxLength}}</span>
                  </mat-form-field>

                  <mat-form-field class="mat-form-textarea-w-100">
                    <mat-label i18n="question">Question</mat-label>
                    <textarea #questionRef
                              cdkAutosizeMaxRows="25" cdkAutosizeMinRows="2" cdkTextareaAutosize
                              formControlName="question" i18n-placeholder="faq question input placeholder" matInput
                              maxlength="{{questionMaxLength}}"
                              placeholder="The frequently asked question." tabindex="3"></textarea>
                    <span class="character-counter">{{questionRef.value.length}}/{{questionMaxLength}}</span>
                  </mat-form-field>

                  <mat-form-field class="mat-form-textarea-w-100">
                    <mat-label i18n="answer">Answer</mat-label>
                    <textarea #answerRef
                              cdkAutosizeMaxRows="25" cdkAutosizeMinRows="2" cdkTextareaAutosize
                              formControlName="answer" i18n-placeholder="faq answer input placeholder" matInput
                              maxlength="{{answerMaxLength}}"
                              placeholder="The answer of the faq." tabindex="3"></textarea>
                    <span class="character-counter">{{answerRef.value.length}}/{{answerMaxLength}}</span>
                  </mat-form-field>

                </div>
                <div class="col">
                  <app-mat-icon-button *ngIf="langRef.value !== 'en'" [callback]="translateStrings.bind(this)" [params]="[langRef.value, stringsIndex]"
                                       color="primary" cssClass="mr-2 mb-3" i18n-label="Button label translate strings" icon="translate"
                                       label="Translate en to {{langRef.value}}"
                                       type="button"></app-mat-icon-button>
                  <app-mat-icon-button *ngIf="langRef.value !== 'en'" [breakLabel]="false" [callback]="deleteStrings.bind(this)" [params]="[stringsIndex]"
                                       color="warn" cssClass="mb-3" i18n-label="Button label delete strings" icon="delete" label="Delete {{langRef.value}}"
                                       type="button"></app-mat-icon-button>
                  <app-mat-icon-button *ngIf="langRef.value === 'en' && questionRef.value && answerRef.value "
                                       [callback]="addMissingTranslations.bind(this)" color="primary" cssClass="mb-3"
                                       i18n-label="Button label add missing translations" icon="translate" label="Add missing translations" tabindex="4"
                                       type="button"></app-mat-icon-button>
                </div>
              </div>
            </div>

          </mat-tab>
        </mat-tab-group>

      </div>

      <span *ngFor="let lang of languages">
        <app-mat-icon-button *ngIf="shouldDisplayLanguage(lang)" [callback]="addStrings.bind(this)" [label]="lang"
                             [params]="[lang]" color="primary" cssClass="mr-2" icon="add"
                             type="button"></app-mat-icon-button>
      </span>
      <app-mat-icon-button [callback]="addStrings.bind(this)" color="primary" cssClass="m-1"
                           i18n-label="other" icon="add" label="other"
                           type="button"></app-mat-icon-button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-mat-icon-button [disabled]="!this.form.valid" [label]="getCreateOrSaveLabel()" cssClass="mb-3 mr-2" icon="save"
                           type="submit"></app-mat-icon-button>

      <app-mat-icon-button [callback]="onDelete.bind(this)" [disabled]="new" [params]="[faq]" color="warn" cssClass="mb-3"
                           i18n-label="delete" icon="delete" label="Delete" type="button"></app-mat-icon-button>
    </div>
  </div>
</form>
