<div class="modal-body">
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
    <br>
    <app-mat-icon-button *ngIf="deleteImageCallback" [callback]="onDeleteImage.bind(this)" [params]="[]"
                         color="warn" cssClass="mb-3" i18n-label="Button label delete image" icon="delete"
                         label="Delete this image"></app-mat-icon-button>

    <ngb-carousel #imageCarousel="ngbCarousel" [activeId]="selectedId+''">
        <ng-template (slid)="selectedId = i" *ngFor="let imgUrl of imgUrls; index as i" id="{{i}}" ngbSlide>
            <div class="wrapper">
                <img
                        *ngIf="isImageActive(i)" [src]="imgUrl.full" alt="Image {{i+1}} of {{altName}}"
                        default="./assets/images/default/default-image-w300.webp" i18n-alt="Alt text of image in carousel modal">
            </div>
        </ng-template>
    </ngb-carousel>

</div>
