import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

import {BookService} from '../book.service';

@Injectable()
export class BookEffects {


  constructor(private actions: Actions,
              private http: HttpClient,
              private router: Router,
              private bookService: BookService) {
  }

}

