<app-redirect></app-redirect>

<div class="flex-container">
  <app-header></app-header>
  <div class="main p-0">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>

