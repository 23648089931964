<ngx-auth-firebaseui
  (onError)="handleError($event)" (onSuccess)="onSuccessfulLogin($event)"
  [guestEnabled]="false" [providers]="[providers.Google, providers.Facebook, providers.Twitter]"
  emailConfirmationText="We're sending you a confirmation e-mail. Check your inbox and click on the confirmation link to confirm your e-mail address."
  emailConfirmationTitle="Confirm email address"
  emailErrorPatternText="Invalid email address." emailErrorRequiredText="Email is required."
  emailText="Email" forgotPasswordButtonText="Forgot password"
  goBackURL="browse" guestButtonText="Continue as a guest" i18n-emailConfirmationText="emailConfirmationText"
  i18n-emailConfirmationTitle="emailConfirmationTitle" i18n-emailErrorPatternText="emailErrorPatternText"
  i18n-emailErrorRequiredText="emailErrorRequiredText"
  i18n-emailText="emailText" i18n-forgotPasswordButtonText="forgotPasswordButtonText"
  i18n-guestButtonText="guestButtonText" i18n-loginButtonText="loginButtonText"
  i18n-messageOnAuthError="messageOnAuthError" i18n-messageOnAuthSuccess="messageOnAuthSuccess"
  i18n-nameErrorMaxLengthText="nameErrorMaxLengthText" i18n-nameErrorMinLengthText="nameErrorMinLengthText"
  i18n-nameErrorRequiredText="nameErrorRequiredText" i18n-nameText="nameText"
  i18n-passwordErrorMaxLengthText="passwordErrorMaxLengthText" i18n-passwordErrorMinLengthText="passwordErrorMinLengthText"
  i18n-passwordErrorRequiredText="passwordErrorRequiredText" i18n-passwordText="passwordText"
  i18n-registerButtonText="registerButtonText" i18n-registerCardTitleText="registerCardTitleText"
  i18n-registerTabText="registerTabText" i18n-resetPasswordActionButtonText="resetPasswordActionButtonText"
  i18n-resetPasswordErrorPatternText="resetPasswordErrorPatternText" i18n-resetPasswordErrorRequiredText="resetPasswordErrorRequiredText"
  i18n-resetPasswordInputText="resetPasswordInputText" i18n-resetPasswordInstructionsText="resetPasswordInstructionsText"
  i18n-resetPasswordTabText="resetPasswordTabText" i18n-sendNewVerificationEmailText="sendNewVerificationEmailText"
  i18n-signInCardTitleText="signInCardTitleText" i18n-signInTabText="signInTabText"
  i18n-signOutText="signOutText" i18n-verifyEmailConfirmationText="verifyEmailConfirmationText"
  loginButtonText="Log in"
  messageOnAuthError="Something went wrong. Please try again."
  messageOnAuthSuccess="Welcome to Blitzshare!"
  nameErrorMaxLengthText="ngxauthfirebaseui.nameErrorMaxLengthText" nameErrorMinLengthText="Name is too short."
  nameErrorRequiredText="Name is required."
  nameText="Name"
  passwordErrorMaxLengthText="Password is too long."
  passwordErrorMinLengthText="Password is too short."
  passwordErrorRequiredText="Password is required."
  passwordText="Password"
  registerButtonText="Register"
  registerCardTitleText="Register"
  registerTabText="Register"
  resetPasswordActionButtonText="Reset password"
  resetPasswordErrorPatternText="Invalid email address."
  resetPasswordErrorRequiredText="Email is required."
  resetPasswordInputText="Enter your email address to reset your password"
  resetPasswordInstructionsText="We're checking, if we recognize this email address. If we do, we'll send you an email containing a password reset link. Please also check your spam folder, if you don't find it in your inbox."
  resetPasswordTabText="Reset password"
  sendNewVerificationEmailText="Send confirmation email again"
  signInCardTitleText="Log in"
  signInTabText="Log in" signOutText="Log out"
  verifyEmailConfirmationText="We're sending you a confirmation e-mail. Check your inbox and click on the confirmation link to confirm your e-mail address."></ngx-auth-firebaseui>
