import {Component, Input, OnInit} from '@angular/core';
import {Address} from '../../models/address.interface';

@Component({
  selector: 'app-address-bar-map-errors',
  templateUrl: './address-bar-map-errors.component.html',
  styleUrls: ['./address-bar-map-errors.component.scss'],
})
export class AddressBarMapErrorsComponent implements OnInit {
  @Input() address ?: Address;
  @Input() addressBarValue ?: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
