import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BrowserService} from '../../browser.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Listing} from '../../../shared/models/listing.interface';
import {CategoryService} from '../../../shared/services/category.service';
import {CurrencyService} from '../../../shared/services/currency.service';
import {takeUntil} from 'rxjs/operators';
import {TitleService} from '../../../shared/services/title.service';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {NgCacheRouteReuseService} from 'ng-cache-route-reuse';
import {AnalyticsEventName, AnalyticsService} from '../../../shared/services/analytics.service';
import {MetadataService} from '../../../shared/services/metadata.service';
import {AppState} from '../../../store/app.reducer';
import {Store} from '@ngrx/store';
import {selectSearchListings} from '../../store/browser.selectors';

@Component({
  selector: 'app-latest-listings',
  templateUrl: './latest-listings.component.html',
  styleUrls: ['./latest-listings.component.css'],
})
export class LatestListingsComponent extends BaseComponent implements OnInit, OnDestroy {

  listings: Listing[] = [];
  error: string | undefined;
  totalCount = 0;
  thereIsMore = true;
  searching = false;
  periodInDays = 1;
  searchListings$ = this.store.select(selectSearchListings).pipe(takeUntil(this.destroy$));


  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private browserService: BrowserService,
              private cacheRouteReuseService: NgCacheRouteReuseService,
              private activatedRoute: ActivatedRoute,
              public currencyService: CurrencyService,
              public categoryService: CategoryService,
              private metadataService: MetadataService,
              private cdRef: ChangeDetectorRef,
              private router: Router,
              private titleService: TitleService,
              private analyticsService: AnalyticsService) {
    super(store);
  }

  ngOnInit(): void {
    this.setTitle();

    this.analyticsService.logEvent(AnalyticsEventName.LATEST_LISTINGS_VIEW);
    this.browserService.selectedCategorySubject.next(undefined);
    this.browserService.updateSearchTerm('');
    this.browserService.unsetSearchCategory();
    this.browserService.fetchLatestListings();
    this.browserService.runCurrentSearch();

    this.searchListings$.subscribe((searchListings) => {
      this.showLoadingSpinner = searchListings.searching;
      this.error = searchListings.searchResult?.errorMessage;
      this.listings = searchListings.searchResult ? searchListings.searchResult.listings : [];
      this.thereIsMore = searchListings.searchResult ? searchListings.searchResult?.thereIsMore : false;
      this.totalCount = searchListings?.searchResult?.totalCount !== undefined ? searchListings.searchResult.totalCount : 0;
      this.searching = searchListings.searching;
      this.periodInDays = searchListings.searchResult?.periodInDays ? searchListings.searchResult?.periodInDays : 1;
    });

    this.browserService.periodInDaysSubject.pipe(takeUntil(this.destroy$)).subscribe(periodInDays => {
      this.periodInDays = periodInDays;
    });

    this.cacheRouteReuseService
        .onAttach(LatestListingsComponent)
        .subscribe((component) => {
          this.setTitle();
          this.browserService.unsetSearchCategory();
          this.browserService.selectedCategorySubject.next(undefined);
          this.analyticsService.logEvent(AnalyticsEventName.LATEST_LISTINGS_VIEW);
        });
    this.metadataService.updateTags($localize`Latest listings`,
        $localize`These are the latest listings in your area on Blitzshare.`,
        $localize`Blitzshare, sharing community, private rentals, borrow almost everything, rent out all the things`,
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  /**
   * Requests more search listings
   * @param count requested number of additional listings
   */
  loadMoreListings(count: number): void {
    if (!this.listings || !this.thereIsMore || this.searching)
      return;
    this.browserService.fetchMoreSearchResults();
  }

  private setTitle() {
    this.titleService.setTitle($localize`Latest listings`);
  }
}
