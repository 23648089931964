<div *ngIf="log">
  <div *ngIf="log.paymentState">
    <span *ngIf="log.paymentState === 'PAID'"><span i18n="log message paid">{{actingUserName}} paid for the rent: </span>
      <app-money *ngIf="transaction && transaction.currencyId && log.paidAmount" [currencyId]="transaction.currencyId"
                 [value]="mangopayService.convertAmount(log.paidAmount, transaction.currencyId, true)"></app-money>
    </span>
    <span *ngIf="log.paymentState === 'TRANSFERRED'"><span i18n="log message transferred">The payment from {{borrower?.displayName}} minus the payment fee and Blitzshare's commission
      was transferred to {{lender?.displayName}}: </span>
      <app-money *ngIf="transaction && transaction.currencyId && transaction.receiverAmount" [currencyId]="transaction.currencyId"
                 [value]="mangopayService.convertAmount(transaction.receiverAmount, transaction.currencyId, true)"></app-money>
    </span>
    <span *ngIf="log.paymentState === 'REFUNDED'"><span i18n="log message refunded">{{borrower?.displayName}} was refunded the rent price minus the payment fee: </span>
      <app-money *ngIf="transaction && transaction.currencyId && transaction.refundedAmount" [currencyId]="transaction.currencyId"
                 [value]="mangopayService.convertAmount(transaction.refundedAmount, transaction.currencyId, true)"></app-money>
    </span>
  </div>

  <div *ngIf="log.periodSuggestionState">
    <span *ngIf="log.periodSuggestionState === 'SUGGESTED'"><span i18n="log message periodSuggestionState SUGGESTED">{{actingUserName}}
      suggested a new rent period:</span><br>
      <span
        *ngIf="log.newPeriodSuggestion && log.newPeriodSuggestion.pickupDate && log.newPeriodSuggestion.returnDate">{{log.newPeriodSuggestion.pickupDate.toDate()|moment:'LLLL'}}
        -
        <br>{{log.newPeriodSuggestion.returnDate.toDate()|moment:'LLLL'}}</span>
    </span>
    <span *ngIf="log.periodSuggestionState === 'SUGGESTION_ACCEPTED'" i18n="log message periodSuggestionState SUGGESTION_ACCEPTED">{{actingUserName}}
      accepted the new rent period.</span>
    <span *ngIf="log.periodSuggestionState === 'SUGGESTION_DENIED'" i18n="log message periodSuggestionState SUGGESTION_DENIED">{{actingUserName}}
      denied the new rent period.</span>
    <span *ngIf="log.periodSuggestionState === 'SUGGESTION_RETRACTED'" i18n="log message periodSuggestionState SUGGESTION_RETRACTED">{{actingUserName}}
      retracted their suggestion for a new rent period.</span>
  </div>

  <div *ngIf="!log.paymentState && !log.periodSuggestionState">
  <span
    *ngIf="log.state === transactionState.BookingAccepted && (log.previousState === transactionState.ItemPickUpRequestedByBorrower || log.previousState === transactionState.ItemPickUpRequestedByLender)"
    i18n="log message marking as picked up undone or declined">{{actingUserName}} undid or declined marking the item as <i>picked up</i>.</span>
    <span
      *ngIf="log.state === transactionState.BookingAccepted && !(log.previousState === transactionState.ItemPickUpRequestedByBorrower || log.previousState === transactionState.ItemPickUpRequestedByLender)"
      i18n="log message BookingAccepted">{{actingUserName}} accepted the booking request.</span>
    <span *ngIf="log.state === transactionState.BookingDenied" i18n="log message BookingDenied">{{actingUserName}} denied the booking request.</span>
    <span *ngIf="log.state === transactionState.ItemPickUpRequestedByLender || log.state === transactionState.ItemPickUpRequestedByBorrower"
          i18n="log message ItemPickUpRequested">{{actingUserName}} marked the item as <i>picked up</i>.</span>
    <span
      *ngIf="log.state === transactionState.ItemPickedUp && (log.previousState === transactionState.ItemReturnRequestedByBorrower || log.previousState === transactionState.ItemReturnRequestedByLender)"
      i18n="log message marking as picked up undone or declined">{{actingUserName}} undid or declined marking the item as <i>returned</i>.</span>
    <span
      *ngIf="log.state === transactionState.ItemPickedUp && !(log.previousState === transactionState.ItemReturnRequestedByBorrower || log.previousState === transactionState.ItemReturnRequestedByLender)"
      i18n="log message BookingAccepted">{{actingUserName}} confirmed the item pickup.</span>
    <span *ngIf="log.state === transactionState.ItemReturnRequestedByLender || log.state === transactionState.ItemReturnRequestedByBorrower"
          i18n="log message ItemReturnRequested">{{actingUserName}} marked the item as <i>returned</i>.</span>
    <span *ngIf="log.state === transactionState.ItemReturned" i18n="log message ItemReturned">{{actingUserName}} confirmed the item return.</span>
    <span *ngIf="log.state === transactionState.BookingCancelled" i18n="log message BookingCancelled">{{actingUserName}} cancelled the booking.</span>
    <span *ngIf="log.periodSuggestionState === 'SUGGESTED'"
          i18n="log message BookingPeriodSuggested">{{actingUserName}} suggested a new date and time for the booking.</span>
    <span *ngIf="log.periodSuggestionState === 'SUGGESTION_ACCEPTED'"
          i18n="log message BookingPeriodSuggested">{{actingUserName}} accepted the new date and time for the booking.</span>
    <span *ngIf="log.periodSuggestionState === 'SUGGESTION_DENIED'"
          i18n="log message BookingPeriodSuggested">{{actingUserName}} denied the new date and time for the booking.</span>
    <span *ngIf="log.periodSuggestionState === 'SUGGESTION_RETRACTED'"
          i18n="log message BookingPeriodSuggested">{{actingUserName}} retracted their new date and time suggestion.</span>
    <span *ngIf="log.state === transactionState.BookingRequested" i18n="log message BookingRequested">{{actingUserName}} booked the item.</span>
  </div>
</div>
