import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export type ConfirmButton = 'yes' | 'no';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  imageUrl?: string;
  primaryButton: ConfirmButton = 'yes';

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.imageUrl = data.imageUrl;
    if (data.primaryButton)
      this.primaryButton = data.primaryButton;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  getYesButtonColor() {
    switch (this.primaryButton) {
      case 'yes':
        return 'primary';
      case 'no':
        return '';
    }
  }

  getNoButtonColor() {
    switch (this.primaryButton) {
      case 'yes':
        return '';
      case 'no':
        return 'primary';
    }
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

  constructor(public title: string, public message: string, public imageUrl?: string, public primaryButton?: ConfirmButton) {
  }
}
