import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountComponent} from './account.component';
import {AccountOverviewComponent} from './account-overview/account-overview.component';
import {ListingsComponent} from './listings/listings.component';
import {MessagesComponent} from './messages/messages.component';
import {SettingsComponent} from './settings/settings.component';
import {DraftsComponent} from './drafts/drafts.component';
import {TransactionComponent} from './transaction/transaction.component';
import {ConversationComponent} from './messages/conversation/conversation.component';
import {LendingComponent} from './lending/lending.component';
import {BorrowingComponent} from './borrowing/borrowing.component';
import {RatingsComponent} from './ratings/ratings.component';
import {PaymentAccountComponent} from './payment-account/payment-account.component';
import {PersonalInfoComponent} from './personal-info/personal-info.component';
import {FirstStepsComponent} from './first-steps/first-steps.component';
import {ReferralComponent} from './referral/referral.component';

const accountRoutes: Routes = [
  {
    path: '',
    component: AccountComponent,
    children: [
      {path: '', redirectTo: 'overview', pathMatch: 'full'},
      {path: 'firstSteps', component: FirstStepsComponent, data: {reuse: false}},
      {path: 'overview', component: AccountOverviewComponent, data: {reuse: false}},
      {path: 'listings', component: ListingsComponent, data: {reuse: true}},
      {path: 'drafts', component: DraftsComponent, data: {reuse: true}},
      {path: 'payment-account', component: PaymentAccountComponent},
      {path: 'messages', component: MessagesComponent, data: {reuse: true}},
      {path: 'messages/:conversationUid', component: ConversationComponent},
      {path: 'settings', component: SettingsComponent, data: {reuse: false}},
      {path: 'lending', component: LendingComponent, data: {reuse: true}},
      {path: 'borrowing', component: BorrowingComponent, data: {reuse: true}},
      {path: 'transactions/:transactionUid', component: TransactionComponent, data: {reuse: true}},
      {path: 'personalInfo', component: PersonalInfoComponent},
      {path: 'ratings', component: RatingsComponent, data: {reuse: true}},
      {path: 'referral', component: ReferralComponent, data: {reuse: true}},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(accountRoutes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {
}
