<div *ngIf="listing">
  <div [class]="showActiveTransactions ? '' : 'invisible'" class="alert alert-info">
    <span *ngIf="activeTransactions.length>0" i18n>You have active bookings for this listing:</span>
    <app-transactions-for-listing (transactionsLoaded)="onActiveTransactionsLoaded($event)" [borrowing]="true" [count]="2"
                                  [listingUid]="listing.uid"
                                  [open]="true"></app-transactions-for-listing>
  </div>

  <div class="invisible">
    <app-transactions-for-listing (transactionsLoaded)="onPastRentalsLoaded($event)" [borrowing]="true" [count]="1" [listingUid]="listing.uid"
                                  [onlyReturned]="true"></app-transactions-for-listing>
  </div>
  <div *ngIf="pastRentals && pastRentals[0] && pastRentals[0].actualPickupDate as pickupDate" class="alert alert-info">
    <span i18n="last borrowed info">You last borrowed this item on {{pickupDate.toDate() | moment:'LL'}}. </span>
    <a [routerLink]="['/account','transactions', pastRentals[0].uid]" i18n>Show transaction</a>
  </div>
</div>
