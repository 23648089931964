import {BrowserService} from '../browser.service';
import {ActivatedRoute, Router} from '@angular/router';
import {map, takeUntil} from 'rxjs/operators';
import {NgbCarousel, NgbCarouselConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageCarouselModalContentComponent} from '../../shared/components/image-carousel-modal-content/image-carousel-modal-content.component';
import {ImageModalContentComponent} from '../../shared/components/image-modal-content/image-modal-content.component';
import {UtilService} from '../../shared/util.service';
import Util from '../../shared/util';
import {FormControl, FormGroup} from '@angular/forms';
import {CountryService} from '../../shared/services/country.service';
import {Listing} from '../../shared/models/listing.interface';
import {AppState} from '../../store/app.reducer';
import firebase from 'firebase/app';
import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  SecurityContext,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {Category} from '../../shared/models/category.interface';
import {RentParams} from '../../shared/models/rentParams.interface';
import {SharedService} from '../../shared/services/shared.service';
import {CurrencyService} from '../../shared/services/currency.service';
import {CategoryService} from '../../shared/services/category.service';
import {ImgUrls} from '../../shared/models/imgUrls.interface';
import {TitleService} from '../../shared/services/title.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {Store} from '@ngrx/store';
import {BookService} from '../../book/book.service';
import {ListingService} from '../../listing/listing.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {MILLIS_PER_DAY} from '../../shared/constants/numbers';
import * as moment from 'moment';
import Locale from '../../shared/services/locale';
import {Search} from '../../shared/models/search.interface';
import {AnalyticsEventName, AnalyticsService} from '../../shared/services/analytics.service';
import {FacebookEventName, FacebookService} from '../../shared/services/facebook.service';
import {environment} from '../../../environments/environment';
import {MetadataService} from '../../shared/services/metadata.service';
import {UserService} from '../../shared/services/user.service';
import {selectSearch} from '../store/browser.selectors';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
  providers: [NgbCarouselConfig],  // add NgbCarouselConfig to the component providers

})
export class ListingComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked, AfterContentChecked {

  /**
   * Determines, if this component is in preview mode. If it is, certain things like setting the title will be omitted.
   */
  @Input() previewMode = false;
  @Input() categoryId: string | undefined;
  bookForm: FormGroup | undefined;
  timePickerFormat = Locale.timePickerFormat();
  currencyId: string | undefined;
  category: Category | undefined;
  categories: Category[] = [];
  path: Category[] = [];
  /* Date range picker */
  minDate = new Date();
  maxDate = new Date();
  availableDayTimestamps: number[] = [];
  dateRangeInvalidDates: Date[] = [];
  rentParams: RentParams = {};
  activeImageIndex: number = 0;
  @ViewChild('imageCarousel') imageCarousel: NgbCarousel | undefined;
  @ViewChildren(ListingComponent) childrenComponent: QueryList<ListingComponent> | undefined;
  search$ = this.store.select(selectSearch).pipe(takeUntil(this.destroy$));

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private bookService: BookService,
              private browserService: BrowserService,
              private listingService: ListingService,
              private domSanitizer: DomSanitizer,
              private activatedRoute: ActivatedRoute,
              private metadataService: MetadataService,
              private router: Router,
              private carouselConfig: NgbCarouselConfig,
              private modalService: NgbModal,
              public sharedService: SharedService,
              public utilService: UtilService,
              private cdRef: ChangeDetectorRef,
              private countryService: CountryService,
              public currencyService: CurrencyService,
              public categoryService: CategoryService,
              private titleService: TitleService,
              private facebookService: FacebookService,
              private analyticsService: AnalyticsService) {
    super(store);
    // customize default values of carousels used by this component tree
    carouselConfig.interval = 0;
    carouselConfig.keyboard = true;
    carouselConfig.pauseOnHover = true;
  }

  _listing?: Listing;

  get listing(): Listing | undefined {
    return this._listing;
  }

  @Input() set listing(listing: Listing | undefined) {
    this._listing = listing;
    this.initializeListing();
  }

  /**
   * Determines the min and max dates for the date picker by iterating the given listings availabilities and finding the earliest and latest one.
   * @param listing listing containing availabilities
   */
  public static setMinAndMaxDate(listing: Listing): { minDate: Date, maxDate: Date } {
    let minDate = new Date();
    let maxDate = new Date();

    if (!listing?.availabilities)
      return {minDate, maxDate};

    for (const availability of listing.availabilities) {
      if (!availability)
        continue;
      const now = new Date().getTime();
      const dateFrom = Timestamp.fromMillis(availability.dateFrom.seconds * 1000);
      const dateUntil = Timestamp.fromMillis(availability.dateUntil.seconds * 1000);
      if (dateUntil.toMillis() > maxDate.getTime() && dateUntil.toMillis() >= now)
        maxDate = dateUntil.toDate();
      if (dateFrom.toMillis() < minDate.getTime() && dateFrom.toMillis() >= now)
        minDate = dateFrom.toDate();
    }
    return {minDate, maxDate};
  }

  /**
   * Determines the available days for the date picker by iterating the given listings availabilities.
   * @param listing listing containing availabilities
   */
  public static setAvailableDays(listing: Listing, transactionUid?: string): number[] {
    const availableDayTimestamps: number[] = [];
    if (!listing?.availabilities)
      return availableDayTimestamps;

    // Iterate the list to add all availability ranges
    for (const availability of listing.availabilities) {
      const dateFrom = Timestamp.fromMillis(availability.dateFrom.seconds * 1000);
      const dateUntil = Timestamp.fromMillis(availability.dateUntil.seconds * 1000);
      for (const d = dateFrom.toDate(); d <= dateUntil.toDate(); d.setDate(d.getDate() + 1)) {
        // If the day is not yet contained in the list
        if (availableDayTimestamps.indexOf(d.getTime()) === -1) {
          // Add it, but get rid of the time
          const availableDayTimestamp = Util.getTimestampWithoutTime(d);
          availableDayTimestamps.push(availableDayTimestamp);
        }
      }
    }
    // Iterate the list again to remove excluded dates
    for (const availability of listing.availabilities) {
      for (const excludedDateTimestamp of availability.excludedDates) {
        const excludedDayTimestamp = Util.removeTimeFromFirebaseTimestamp(excludedDateTimestamp);
        Util.removeFromArray(availableDayTimestamps, excludedDayTimestamp);
      }
    }

    // Iterate the list of bookings to remove all fully booked dates
    if (listing.bookingPeriodMap)
      for (let entry of Object.entries(listing.bookingPeriodMap)) {
        if (entry[0] === transactionUid)
          continue;
        const bookingFrom = Util.getDate(entry[1].dateFrom);
        const bookingUntil = Util.getDate(entry[1].dateUntil);
        // Determine all full days
        const fullyBookedDays: number[] = Util.getFullDaysBetween(bookingFrom, bookingUntil);
        // Remove the full days from the available days list
        fullyBookedDays.forEach((fullyBookedDay => {
          Util.removeFromArray(availableDayTimestamps, fullyBookedDay);
        }));
      }

    return availableDayTimestamps;
  }

  availabilityFilter = (d: Date | null): boolean => {
    if (d === null)
      return false;
    const checkDay = d.setHours(0, 0, 0, 0);
    return this.availableDayTimestamps.indexOf(checkDay) > -1;
  };

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.bookForm = this.createForm();

    this.enableLoadingSpinner($localize`Loading listing...`);

    if (this.listing) {
      // If there is a listing input, there's no loading
      this.disableLoadingSpinner();
      this.initializeListing();
    } else
      this.loadListingByPath();

    this.loadCategories();

    this.search$.subscribe((search) => {
      if (search)
        this.onSearchLoadedFromStore(search);
    });
  }

  initializeListing(): void {
    this.activeImageIndex = 0;

    if (this.listing) {
      this.analyticsService.logEvent(AnalyticsEventName.LISTING_VIEW, {listingUid: this.listing.uid});

      this.facebookService.logEvent(FacebookEventName.ViewContent, {
        content_name: this.listing.name,
        content_category: this.listing.categoryId,
        content_ids: [this.listing.uid],
        content_type: 'product',
        product_catalog_id: environment.facebookCatalogId,
      });
      this.categoryId = this.listing.categoryId;
      this.path = [];
      this.setCategory();
      const minAndMaxDate = ListingComponent.setMinAndMaxDate(this.listing);
      this.minDate = minAndMaxDate.minDate;
      this.maxDate = minAndMaxDate.maxDate;
      this.availableDayTimestamps = ListingComponent.setAvailableDays(this.listing);
      // this.sortExcludedDates(this.listing);
      this.currencyId = this.listing?.currencyId;
      if (!this.previewMode && this.listing.name) {
        this.titleService.setTitle(Util.removeEmTags(this.listing.name));
      }

      let date = new Date();
      if (this.listing.lastEditDate)
        date = this.utilService.getDate(this.listing.lastEditDate);
      else if (this.listing.creationDate)
        date = this.utilService.getDate(this.listing.creationDate);
      const momentDate = moment(date);
      this.metadataService.updateTags(this.listing.name, this.listing.summary,
          $localize`rent item blitzshare`,
          this.listingService.getThumbnails(this.listing), undefined, undefined, undefined, momentDate,
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.imageCarousel) {
      this.imageCarousel.focus();
    }
    if (this.childrenComponent) this.childrenComponent.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.imageCarousel) {
        this.imageCarousel.focus();
      }
    });

  }

  createForm(): FormGroup {
    return new FormGroup({
      dateFrom: new FormControl(),
      dateUntil: new FormControl(),
      timeFrom: new FormControl('12:00'),
      timeUntil: new FormControl('18:00'),
    });
  }

  onBook(): void {
    this.bookForm?.markAsTouched();
    if (!this.bookForm?.valid)
      return;
    this.browserService.setDateAndTimePeriod(this.bookForm?.value.dateFrom, this.bookForm?.value.dateUntil, this.bookForm?.value.timeFrom, this.bookForm?.value.timeUntil);
    this.bookService.setBookingParams(this.listing);
    this.router.navigate(['book', this.listing?.uid]);
  }

  /**
   * Opens the given array of images in a carousel inside a modal.
   * @param selectedId the ID of the image to be selected at the beginning
   * @param imgUrls all images to be shown in the carousel
   */
  openImage(selectedId: number, imgUrls: ImgUrls[]): void {
    const modalRef = this.modalService.open(ImageCarouselModalContentComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.selectedId = selectedId;
    modalRef.componentInstance.imgUrls = imgUrls;
    modalRef.componentInstance.altName = this.listing?.name;
  }

  /**
   * Opens the given image in a modal.
   * @param imgUrl the image to be shown in the modal
   */
  openSingleImage(imgUrl: string): void {
    const modalRef = this.modalService.open(ImageModalContentComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.imgUrl = imgUrl;
  }

  getCountryFromCode(countryCode: string | undefined): string | undefined {
    if (!countryCode)
      return undefined;
    return this.countryService.getCountryNameByCode(countryCode);
  }

  removeEmTags(name?: string) {
    if (!name)
      return '';
    return Util.removeEmTags(name);
  }

  /**
   * Called, when the listing is changed in the manage listing component (e.g. disabled or enabled)
   * @param listing new listing
   */
  onListingChanged(listing: Listing) {
    this.listing = listing;
  }

  bypassSecurityTrustHtml(value: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(<string> this.domSanitizer.sanitize(SecurityContext.STYLE, value));
  }

  isImageActive(i: number) {
    return Util.isSlideActive(i, this.activeImageIndex, this.listing!.imgUrls!.length);
  }

  selectImage(index: number) {
    if (!this.imageCarousel?.activeId)
      return;
    this.imageCarousel.select('' + index);
  }

  private loadCategories() {
    this.categoryService.getCategories().then(wrapper => {
      if (wrapper.data) {
        this.categories = wrapper.data;
        this.setCategory();
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading category\: ${wrapper.errorMessage}`);
    });
  }

  /**
   * Called, when a search object was successfully loaded from the store. Prefills the bookFrom and bookUntil dates.
   * @param search search object, not undefined or null
   */
  private onSearchLoadedFromStore(search: Search) {
    let dateFrom = search.dateFrom;
    let dateUntil = search.dateUntil;

    // Set the date range to today until tomorrow, if the current value is in the past
    const now = new Date();
    if (dateFrom && dateFrom.getTime() < now.getTime())
      dateFrom = now;
    if (dateUntil && dateUntil.getTime() < now.getTime())
      dateUntil = new Date(now.getTime() + MILLIS_PER_DAY);

    if (dateFrom && (!this.bookForm?.value.dateFrom || !Util.isSameDay(this.bookForm?.value.dateFrom, dateFrom)))
      this.bookForm?.patchValue({dateFrom});
    if (dateUntil && (!this.bookForm?.value.dateUntil || !Util.isSameDay(this.bookForm?.value.dateUntil, dateUntil)))
      this.bookForm?.patchValue({dateUntil});
  }

  private setCategory(): void {
    if (!this.categories || this.categories.length === 0 || !this.categoryId)
      return;

    this.path = [];
    this.category = this.categoryId ?
        this.categoryService.getCategoriesPathRecursive(this.categoryId, this.categories, this.path) : undefined;

  }

  /**
   * Detects the listing ID from the activated route and loads the listing from the firestore
   */
  private loadListingByPath() {
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).pipe(
        map(params => {
          const listingId = params.get('listingId');
          if (listingId === null || listingId === undefined)
            throw new Error('There is no listingId.');
          return listingId;
        }),
    ).subscribe(
        (listingId: string) => {

          // Only fetch the listing from the firestore, if there is no input listing
          if (this.listing)
              // There is an input listing. Initialize the component with it
            this.initializeListing();
          else
            this.listingService.fetchListing(listingId).then(wrapper => {
                  this.disableLoadingSpinner();
                  if (wrapper.data) {
                    this.listing = wrapper.data;
                    // Don't call initializeListing() here, because the setter set listing(...) already does that.
                  }
                  if (wrapper.errorMessage)
                    this.addError($localize`The listing could not be loaded\: ${wrapper.errorMessage}`);
                },
            );
          Util.scrollToTop();
        },
        (error) => console.error(error),
    );
  }
}





