import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserComponent} from './user/user.component';
import {ReportComponent} from './report/report.component';
import {RatingViewComponent} from './rating-view/rating-view.component';

const socialRoutes: Routes = [
  {path: 'user/:userUid', component: UserComponent, data: {reuse: true}},
  {path: 'rating/:ratingUid', component: RatingViewComponent, data: {reuse: true}},
  {path: 'report/:type/:uid', component: ReportComponent, data: {reuse: false}},
];

@NgModule({
  imports: [RouterModule.forChild(socialRoutes)],
  exports: [RouterModule],
})
export class SocialRoutingModule {
}
