<div class="container bg-container p-1">
  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [message]="loadingSpinnerMessage"
                       [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>
  <app-alerts [alerts]="alerts" [float]="true" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li *ngIf="listing" class="breadcrumb-item"><a [routerLink]="['/browse','listing',listing.uid]">{{listing.name}}</a></li>
      <li *ngIf="rating" class="breadcrumb-item"><a [routerLink]="['/social','user',rating.receiverUid]" i18n>Rating from {{rating.rater.displayName}}</a></li>
      <li *ngIf="type === 'listing'" class="breadcrumb-item" i18n="Report listing">Report listing</li>
      <li *ngIf="type === 'rating'" class="breadcrumb-item" i18n="Report rating">Report rating</li>
      <li *ngIf="type === undefined" class="breadcrumb-item" i18n="Report rating">Report</li>
    </ol>
  </nav>

  <app-not-logged-in *ngIf="!user" i18n-message="not-logged-in message report"
                     message="You need to login, before you can report anything."></app-not-logged-in>

  <div *ngIf="user">

    <mat-card *ngIf="reportSent">
      <app-report-sent [allowContact]="allowContact" [category]="category" [reason]="reason"></app-report-sent>
    </mat-card>
    <ng-container *ngIf="!reportSent">

      <!-- Jumbotron with headline -->
      <div class="row">
        <div class="col">
          <div *ngIf="type === 'listing'" class="jumbotron mb-3">
            <h1 class="jumbotron-headline" i18n="Report listing">Report listing</h1>
            <p class="jumbotron-lead" i18n="Report listing lead">On this page, you can report the selected listing as inappropriate, offensive or illegal.
              Please
              add a short explanation.</p>
          </div>
          <div *ngIf="type === 'rating'" class="jumbotron mb-3">
            <h1 class="jumbotron-headline" i18n="Report rating">Report rating</h1>
            <p class="jumbotron-lead" i18n="Report rating lead">On this page, you can report the selected rating as inappropriate, offensive or illegal. Please
              add a short explanation.</p>
          </div>
        </div>
      </div>

      <!-- If this is a listing report -->
      <div *ngIf="listing">
        <h1 class="paragraph-headline" i18n="listing">Listing</h1>
        <!-- Item card -->
        <div class="card w-100 mb-3">
          <div class="row no-gutters">
            <div *ngIf="listing.imgUrls && listing.imgUrls[0] && listing.imgUrls[0].thumb !== undefined">
              <a [routerLink]="['/browse/listing',listing.uid]">
                <img
                        alt="Picture of {{listing.name}}" class="contained-300h-300w"
                        default="./assets/images/default/default-listing-w300.webp"
                        i18n-alt="Picture alt text in item card"
                        src="{{listing.imgUrls[0].thumb}}"></a>
            </div>
            <div class="col">
              <div class="card-body">
                <h2 class="paragraph-headline-2"><a [routerLink]="['/browse/listing',listing.uid]">{{listing.name}}</a></h2>
                <p>{{listing.summary}}</p>
              </div>
            </div>
          </div>
        </div>


        <mat-expansion-panel class="my-3" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title i18n>Show listing</mat-panel-title>
            <mat-panel-description i18n="preview listing hint">
              Click to toggle the listing you're going to report.
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-listing [categoryId]="listing.categoryId" [listing]="listing" [previewMode]="true"></app-listing>
        </mat-expansion-panel>

      </div>

      <!-- If this is a rating report -->
      <div *ngIf="rating">

        <h1 class="paragraph-headline" i18n="rating">Rating</h1>
        <mat-card class="mb-3">
          <app-rating [rating]="rating" [showListing]="true"></app-rating>
        </mat-card>
        <h1 class="paragraph-headline" i18n="rater">Rater</h1>
        <mat-card class="mb-3">
          <app-user-info [userPublicUid]="rating.raterUid"></app-user-info>
        </mat-card>
      </div>


      <h1 class="paragraph-headline" i18n="report form">Report form</h1>
      <form (ngSubmit)="onSendReport()" [formGroup]="reportForm">
        <mat-form-field class="mat-form-input width-up-to-350px">
          <mat-label i18n>Report category</mat-label>
          <mat-select #categoryRef formControlName="categoryId" id="category" panelClass="mat-select-panel-500px">
            <mat-option [value]="undefined" i18n>Please select a category</mat-option>
            <mat-option *ngFor="let repCat of reportCategories" [value]="repCat.id">{{repCat.value}}</mat-option>
          </mat-select>
          <mat-error *ngIf="!reportForm.get('category')?.valid && reportForm.get('category')?.touched" i18n>Please select a category.</mat-error>
        </mat-form-field>
        <mat-form-field class="mat-form-textarea-w-100">
          <mat-label i18n="message">Reason</mat-label>
          <textarea #reasonRef [maxLength]="reasonMaxLength" cdkAutosizeMaxRows="25" cdkAutosizeMinRows="2"
                    cdkTextareaAutosize formControlName="reason"
                    i18n-placeholder="report input placeholder" matInput
                    placeholder="Please explain your report." tabindex="3"></textarea>
          <span class="character-counter">{{reasonRef.value.length}}/{{reasonMaxLength}}</span>
          <mat-error *ngIf="reportForm?.get('reason')?.hasError('required')" i18n>Please explain your report.</mat-error>
          <mat-error *ngIf="reportForm?.get('reason')?.hasError('minlength')" i18n>Please enter at least {{reasonMinLength}} characters.</mat-error>
          <mat-error *ngIf="reportForm?.get('reason')?.hasError('maxlength')" i18n>Please enter a maximum of {{reasonMaxLength}} characters.</mat-error>
        </mat-form-field>

        <mat-checkbox color="primary" formControlName="allowContact" i18n>May we contact you, if we have further questions about this report?</mat-checkbox>
        <br>
        <app-mat-icon-button
                [disabled]="!reportForm?.valid" color="primary" cssClass="mb-3 mt-2" i18n-label="Send report" icon="report" label="Send report"
                type="submit"></app-mat-icon-button>
      </form>

    </ng-container>
  </div>
</div>
