import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from '../../models/message.interface';
import {BaseComponent} from '../base/base.component';
import {UserService} from '../../services/user.service';
import {SocialService} from '../../../social/social.service';
import {BookService} from '../../../book/book.service';
import firebase from 'firebase/app';
import {Listing} from '../../models/listing.interface';
import {EmbeddedListing} from '../../models/embeddedListing.interface';
import {ListingService} from '../../../listing/listing.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-write-message',
  templateUrl: './write-message.component.html',
  styleUrls: ['./write-message.component.css'],
})
export class WriteMessageComponent extends BaseComponent implements OnInit {

  @Input() receiverUid?: string;
  @Output() onMessageSent = new EventEmitter<Message>();
  @Output() onClose = new EventEmitter<boolean>();

  messageMaxLength = 2000;
  messageWasSent = false;
  private listing?: Listing;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private listingService: ListingService,
      private socialService: SocialService,
      private bookService: BookService) {
    super(store);
  }

  _listingUid?: string;

  get listingUid(): string | undefined {
    return this._listingUid;
  }

  @Input() set listingUid(listingUid: string | undefined) {
    this._listingUid = listingUid;
    if (listingUid)
      this.listingService.fetchListing(listingUid).then(wrapper => {
        if (wrapper.data)
          this.listing = wrapper.data;
        if (wrapper.errorMessage)
          this.addError(wrapper.errorMessage);
      });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  sendMessage(messageRef: HTMLTextAreaElement) {
    this.clearAlerts();

    if (!this.receiverUid || !this.user?.uid) {
      this.addError($localize`Message receiver or sender is unknown. Message cannot be sent.`);
      return;
    }

    const messageContent = messageRef.value.trim();
    if (!messageContent)
      return;

    const message: Message = {
      receiverUid: this.receiverUid,
      message: messageContent,
      date: Timestamp.now(),
      senderUid: this.user?.uid,
    };
    let embeddedListing: EmbeddedListing | undefined;
    if (this.listing && this.listing.imgUrls && this.listing.imgUrls[0].thumb && this.listingUid)
      embeddedListing = {name: this.listing.name, imgUrlThumb: this.listing.imgUrls[0].thumb, uid: this.listingUid};
    if (this.listingUid)
      message.listingUid = this.listingUid;
    this.bookService.insertMessage(message, (messageUid => {
      message.uid = messageUid;
      this.onMessageSent.emit(message);
      this.messageWasSent = true;
      messageRef.value = '';
      setTimeout(() => {
        this.messageWasSent = false;
        this.onClose.emit(true);
      }, 5000);
    }), reason => this.addError(reason), embeddedListing);

  }

  onCancel(): void {
    this.onClose.emit(true);
  }
}
