import {Component, OnInit} from '@angular/core';
import {TERMS_AND_CONDITIONS_DOWNLOAD_LINK} from '../../shared/constants/files';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {

  tocDownloadLink = TERMS_AND_CONDITIONS_DOWNLOAD_LINK;
  year: number = new Date().getFullYear();
  version = environment.version;
  siteTitle = environment.defaultTitle;

  constructor() {
  }

  ngOnInit(): void {
  }
}
