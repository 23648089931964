<div class="container bg-container p-1">
  <!-- Loading spinner -->
  <div *ngIf="showLoadingSpinnerListings" class="row">
    <div class="col center-all">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>

  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
  <div *ngIf="!userPublic">

    <!-- Breadcrumb-->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" i18n="Users">Users</li>
        <li class="breadcrumb-item" i18n="Unknown user">Unknown user</li>
      </ol>
    </nav>

    <!-- Jumbotron with displayName and summary -->
    <div class="row">
      <div class="col">
        <div class="jumbotron mb-3">
          <h1 class="jumbotron-headline" i18n="Unknown user">Unknown user</h1>
          <p class="jumbotron-lead" i18n="user was not found|user was not found">The user was not found.</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="userPublic">

    <!-- Breadcrumb-->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" i18n="Users">Users</li>
        <li class="breadcrumb-item">{{userPublic.displayName}}</li>
      </ol>
    </nav>

    <!-- Jumbotron with displayName and summary -->
    <div class="row">
      <div class="col">
        <div class="jumbotron mb-3">
          <h1 class="jumbotron-headline">{{userPublic.displayName}}</h1>
          <p class="jumbotron-lead" i18n>User on Blitzshare</p>
        </div>
      </div>
    </div>

    <!-- Image and <Ratings, creation date, write message block> -->
    <div class="row">
      <!-- Image -->
      <div *ngIf="userPublic.imgUrlThumb && userPublic.imgUrl" class="col-auto mb-3">
        <img (click)="openImage(userPublic.imgUrl)"
             alt="user {{userPublic.displayName}} image"
             class="contained-300h-300w hover-pointer"
             default="./assets/images/default/default-user-w300.webp" i18n-alt="current user image alt"
             src="{{userPublic?.imgUrl}}">
      </div>

      <!-- Ratings summary, creation date, write message block-->
      <div class="col-lg-6 col-sm-12 mb-3">

        <table *ngIf="ratingSummary" class="table-responsive">
          <tr *ngIf="ratingSummary.overallScore">
            <th>
              <app-rating-criterion-headline [criterion]="ratingCriterion.Overall" [receiverName]="userPublic?.displayName"></app-rating-criterion-headline>
            </th>
            <td>
              <span *ngIf="ratingSummary && ratingSummary.overallScore && ratingSummary.overallCount>0">
                <app-bs-star-rating [rating]="ratingSummary.overallScore" size="medium"></app-bs-star-rating>
                <span *ngIf="ratingSummary.overallCount" class="ml-2" i18n="number of overall ratings">
                  ({ratingSummary.overallCount, plural, =0 {no ratings} =1 {1 rating} other {{{ratingSummary.overallCount}} ratings}})</span>
              </span>
            </td>
          </tr>
          <tr *ngIf="ratingSummary.itemAsDescribedScore">
            <th>
              <app-rating-criterion-headline [criterion]="ratingCriterion.ItemAsDescribed"
                                             [receiverName]="userPublic?.displayName"></app-rating-criterion-headline>
            </th>
            <td>
              <span *ngIf="ratingSummary && ratingSummary.itemAsDescribedScore && ratingSummary.itemAsDescribedCount>0">
                <app-bs-star-rating [rating]="ratingSummary.itemAsDescribedScore" size="medium"></app-bs-star-rating>
                <span *ngIf="ratingSummary.itemAsDescribedCount" class="ml-2" i18n="number of itemAsDescribed ratings">
                  ({ratingSummary.itemAsDescribedCount, plural, =0 {no ratings} =1 {1 rating} other {{{ratingSummary.itemAsDescribedCount}} ratings}})</span>
              </span>
            </td>
          </tr>
          <tr *ngIf="ratingSummary.itemReturnConditionScore">
            <th>
              <app-rating-criterion-headline [criterion]="ratingCriterion.ItemReturnCondition"
                                             [receiverName]="userPublic?.displayName"></app-rating-criterion-headline>
            </th>
            <td>
              <span *ngIf="ratingSummary && ratingSummary.itemReturnConditionScore && ratingSummary.itemReturnConditionCount>0">
                <app-bs-star-rating [rating]="ratingSummary.itemReturnConditionScore" size="medium"></app-bs-star-rating>
                <span *ngIf="ratingSummary.itemReturnConditionCount" class="ml-2" i18n="number of itemReturnCondition ratings">
                  ({ratingSummary.itemReturnConditionCount, plural, =0 {no ratings} =1 {1 rating}
                        other {{{ratingSummary.itemReturnConditionCount}} ratings}})</span>
              </span>
            </td>
          </tr>
          <tr *ngIf="ratingSummary.punctualityScore">
            <th>
              <app-rating-criterion-headline [criterion]="ratingCriterion.Punctuality"
                                             [receiverName]="userPublic?.displayName"></app-rating-criterion-headline>
            </th>
            <td>
              <span *ngIf="ratingSummary && ratingSummary.punctualityScore && ratingSummary.punctualityCount>0">
                <app-bs-star-rating [rating]="ratingSummary.punctualityScore" size="medium"></app-bs-star-rating>
                <span *ngIf="ratingSummary.punctualityCount" class="ml-2" i18n="number of punctuality ratings">
                  ({ratingSummary.punctualityCount, plural, =0 {no ratings} =1 {1 rating} other {{{ratingSummary.punctualityCount}} ratings}})</span>
              </span>
            </td>
          </tr>
          <tr *ngIf="ratingSummary.friendlinessScore">
            <th>
              <app-rating-criterion-headline [criterion]="ratingCriterion.Friendliness"
                                             [receiverName]="userPublic?.displayName"></app-rating-criterion-headline>
            </th>
            <td>
              <span *ngIf="ratingSummary && ratingSummary.friendlinessScore && ratingSummary.friendlinessCount>0">
                <app-bs-star-rating [rating]="ratingSummary.friendlinessScore" size="medium"></app-bs-star-rating>
                <span *ngIf="ratingSummary.friendlinessCount" class="ml-2" i18n="number of friendliness ratings">
                  ({ratingSummary.friendlinessCount, plural, =0 {no ratings} =1 {1 rating} other {{{ratingSummary.friendlinessCount}} ratings}})</span>
              </span>
            </td>
          </tr>


        </table>

        <span *ngIf="!ratingSummary || !ratingSummary.overallScore" i18n="No ratings yet">No ratings yet</span>

        <p *ngIf="userPublic.creationDate" class="mt-1" i18n="User card registration date text">
          Registered since {{ utilService.getDate(userPublic.creationDate) | moment:'LL'}}</p>
        <app-mat-icon-button *ngIf="user?.uid !== userPublic.uid && !showWriteMessageComponent" [callback]="onSendMessage.bind(this)" [params]="[]"
                             color="accent" cssClass="mr-2 mb-3" i18n-label="Send message to borrower" icon="message"
                             label="Send message to {{userPublic?.displayName}}"></app-mat-icon-button>

        <mat-card *ngIf="showWriteMessageComponent" class="mb-3">
          <app-write-message (onClose)="showWriteMessageComponent=false" *ngIf="showWriteMessageComponent" [receiverUid]="userPublic.uid"></app-write-message>
        </mat-card>
      </div>
    </div>

    <div *ngIf="listings.length>0" class="row mb-3">
      <div class="col">
        <h1 class="paragraph-headline" i18n>{{userPublic.displayName}}'s listings</h1>
        <app-listings-view (loadMoreListings)="loadMoreListings($event)" [listings]="listings"
                           [thereIsMore]="thereAreMoreListings"></app-listings-view>

        <app-loading-spinner *ngIf="showLoadingSpinnerListings" [centered]="true"></app-loading-spinner>

      </div>
    </div>

    <span *ngIf="listings.length===0" i18n="No listings yet">{{userPublic.displayName}} does not have any listings.</span>

    <div *ngIf="ratings.length>0" class="row mb-3">
      <div class="col">
        <h1 class="paragraph-headline" i18n>{{userPublic.displayName}}'s ratings</h1>
        <app-ratings-view (loadMoreRatings)="loadMoreRatings($event)" [ratings]="ratings"
                          [thereIsMore]="thereAreMoreRatings"></app-ratings-view>

        <app-loading-spinner *ngIf="showLoadingSpinnerRatings" [centered]="true"></app-loading-spinner>

      </div>
    </div>

  </div>
</div>

