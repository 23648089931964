import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ReportViewComponent} from '../../../../admin/reports/report-view/report-view.component';
import {UtilService} from '../../../../shared/util.service';
import {RatingSummary} from '../../../../shared/models/ratingSummary.interface';
import {ListingService} from '../../../../listing/listing.service';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {ReportCategory} from '../../../../shared/models/reportCategory.interface';
import {UserPublic} from '../../../../shared/models/userPublic.interface';
import {Listing} from '../../../../shared/models/listing.interface';
import {UserService} from '../../../../shared/services/user.service';
import {Rating} from '../../../../shared/models/rating.interface';
import {BookService} from '../../../../book/book.service';
import {Report} from '../../../../shared/models/report.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.reducer';

@Component({
  selector: 'app-report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.scss'],
})
export class ReportPreviewComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  reporter?: UserPublic;
  reportee?: UserPublic;
  listing?: Listing;
  rating?: Rating;
  reportCategory?: ReportCategory;
  reporterRatingSummary?: RatingSummary;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public utilService: UtilService,
      private cdRef: ChangeDetectorRef,
      private listingService: ListingService,
      private bookService: BookService,
  ) {
    super(store);
  }

  _report: Report | undefined;

  get report(): Report | undefined {
    return this._report;
  }

  @Input() set report(report: Report | undefined) {
    this._report = report;
    if (!report)
      return;

    if (report.reporterUid)
      this.loadReporter(report.reporterUid);

    switch (report.type) {
      case 'listing': {
        this.loadListing(report.listingUid);
        break;
      }
      case 'rating': {
        this.loadRating(report.ratingUid);
        break;
      }
    }

    this.reportCategory = ReportViewComponent.determineReportCategory(report.categoryId);
  }

  ngOnInit(): void {
    super.ngOnInit();

  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }


  private loadReporter(reporterUid: string) {
    this.userService.fetchUserPublic(reporterUid).then(wrapper => {
      if (wrapper.data) {
        this.reporter = wrapper.data;
        this.reporterRatingSummary = this.userService.calculateRatingSummary(this.reporter);
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading reporter\: ${wrapper.errorMessage}`);
    });
  }

  private loadListing(listingUid?: string) {
    if (!listingUid) {
      this.addError($localize`The listingUid is missing in the report.`);
      return;
    }
    this.listingService.fetchListing(listingUid).then(wrapper => {
      if (wrapper.data) {
        this.listing = wrapper.data;
        this.loadReportee(this.listing.lenderUid);
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading listing\: ${wrapper.errorMessage}`);
    });
  }

  private loadRating(ratingUid?: string) {
    if (!ratingUid) {
      this.addError($localize`The ratingUid is missing in the report.`);
      return;
    }
    this.bookService.fetchRating(ratingUid).then(wrapper => {
      if (wrapper.data) {
        this.rating = wrapper.data;
        this.loadReportee(this.rating.raterUid);
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading rating\: ${wrapper.errorMessage}`);
    });
  }

  private loadReportee(reporteeUid?: string) {
    if (!reporteeUid) {
      this.addError($localize`The reporteeUid cannot be determined.`);
      return;
    }
    this.userService.fetchUserPublic(reporteeUid).then(wrapper => {
      if (wrapper.data)
        this.reportee = wrapper.data;
      if (wrapper.errorMessage)
        this.addError($localize`Error loading reportee\: ${wrapper.errorMessage}`);
    });
  }

}
