import {Component, Input, OnInit} from '@angular/core';
import {Listing} from '../../models/listing.interface';

@Component({
  selector: 'app-listing-price-table',
  templateUrl: './listing-price-table.component.html',
  styleUrls: ['./listing-price-table.component.css'],
})
export class ListingPriceTableComponent implements OnInit {

  @Input() currencyId?: string;
  @Input() listing?: Listing;

  constructor() {
  }

  ngOnInit(): void {
  }

}
