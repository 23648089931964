import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

@Directive({
  selector: '[appGte]',
  providers: [{provide: NG_VALIDATORS, useExisting: GteDirective, multi: true}],
})
export class GteDirective implements Validator {
  @Input() appGte = 0;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.appGte ? gteValidator(this.appGte)(control)
      : null;
  }
}

export function gteValidator(appGte: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const gte = control.value < appGte;
    return gte ? {appGte: {value: control.value}} : null;
  };
}
