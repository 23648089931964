import {createSelector} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';
import {Faq} from '../../shared/models/faq.interface';
import {Announcement} from '../../shared/models/announcement.interface';

export const selectLayout = (state: AppState) => state.layout;

export const selectReferral = createSelector(
    selectLayout,
    (layoutState) => layoutState.referral,
);

export const selectFaqs = createSelector(
    selectLayout,
    (layoutState) => ({
      faqs: layoutState.faqs,
      faqsFetchDate: layoutState.faqsLastFetch,
    }),
);

export const selectAnnouncements = createSelector(
    selectLayout,
    (layoutState) => ({
      announcements: layoutState.announcements,
      announcementsFetchDate: layoutState.announcementsLastFetch,
    }),
);

export interface FaqsWrapper {
  faqs: Faq[];
  faqsFetchDate?: Date;
}

export interface AnnouncementsWrapper {
  announcements: Announcement[];
  announcementsFetchDate?: Date;
}
