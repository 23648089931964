<div class="container-fluid bg-container p-0 pt-1">

  <div class="jumbotron mb-3 mx-1 mt-0">
    <h1 class="jumbotron-headline" i18n="All categories headline on all categories page">All categories</h1>
    <p class="jumbotron-lead" i18n="All categories lead text on all categories page">Browse through all categories.</p>
    <div *ngIf="user?.admin" class="row justify-content-start">
      <div class="col-md-4 mb-3 mx-0">
        <div class="alert alert-primary"><a [routerLink]="['/admin','categories']" i18n="Edit categories"> Edit categories</a></div>
      </div>
    </div>
  </div>

  <!-- Show error, if no categories could be loaded -->
  <div *ngIf="error" class="px-1">
    <div class="row">
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{error}}
        </div>
      </div>
    </div>
  </div>

  <div class="card-group">
    <div *ngFor="let category of categories" class="card outer-card m-1">
      <a *ngIf="category.imgUrlFull" routerLink="{{category.id}}"><img [src]="category.imgUrlFull" alt="{{categoryService.getCategoryName(category)}} image"
                                                                       class="card-img-top cropped-300h"
                                                                       default="./assets/images/default/default-category-w300.webp"
                                                                       i18n-alt="Category image alternative text"></a>
      <div class="card-body">
        <a routerLink="{{category.id}}"><h2 class="paragraph-headline-2">{{categoryService.getCategoryName(category)}}</h2></a>

        <div class="d-xs-block d-none">
          <!-- On screens wider than 432px width -->
          <p class="card-text">{{categoryService.getCategoryDescription(category)}}</p>
        </div>
        <div class="d-block d-xs-none">
          <!-- On very small screens up to 432px width -->
          <p class="card-text">{{categoryService.getCategoryDescription(category)}}</p>
        </div>

      </div>
      <div class="card-footer">

        <div *ngIf="categoryService.getSubcategories(category) as subcategories">
          <mat-expansion-panel (closed)="subcategoriesShownByCategory.set(category,false)" (opened)="subcategoriesShownByCategory.set(category,true)"
                               *ngIf="subcategories.length > 0" class="mat-expansion-panel-without-padding">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span *ngIf="!subcategoriesShownByCategory?.get(category)" class="btn toggle-subcategories-button" i18n>Show subcategories</span>
                <span *ngIf="subcategoriesShownByCategory?.get(category)" class="btn toggle-subcategories-button" i18n>Hide subcategories</span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="d-xs-block d-none">
              <!-- On screens wider than 432px width -->
              <app-all-categories-inner-card-group [categories]="subcategories"></app-all-categories-inner-card-group>
            </div>

            <div class="d-block d-xs-none">
              <!-- On very small screens up to 432px width -->
              <app-category-list-item [category]="category" [skipParent]="true"></app-category-list-item>
            </div>

          </mat-expansion-panel>

        </div>
      </div>
    </div>
  </div>
</div>
