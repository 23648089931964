import {Injectable} from '@angular/core';


@Injectable()
export class SharedEffects {
  constructor() {
  }
}


