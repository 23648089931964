<a *ngIf="conversations.length > 0" class="no-underline" routerLink="/account/messages">

  <h1 class="paragraph-headline" mat-dialog-title>
    <mat-icon class="align-middle mr-2">message</mat-icon>
    <span i18n="messages">Messages</span>
  </h1>
</a>

<mat-dialog-content>
  <div *ngIf="error" class="alert alert-danger">{{error}}</div>
  <div *ngIf="conversations.length > 0">
    <div *ngFor="let conversation of conversations" class="list-item">
      <app-conversation-preview (click)="closeDialog()" [conversation]="conversation"></app-conversation-preview>
    </div>
  </div>
  <p *ngIf="conversations.length===0" class="mt-3" i18n="no messages">You haven't got any messages yet.</p>
</mat-dialog-content>



