import {Injectable} from '@angular/core';
import {TimeZone} from '../models/timeZone.interface';

@Injectable({
  providedIn: 'root',
})
export class TimeZoneService {

  timeZones: TimeZone[] = [
    {id: 'Europe/Andorra', translatedName: $localize`Europe/Andorra`},
    {id: 'Asia/Dubai', translatedName: $localize`Asia/Dubai`},
    {id: 'Asia/Kabul', translatedName: $localize`Asia/Kabul`},
    {id: 'Europe/Tirane', translatedName: $localize`Europe/Tirane`},
    {id: 'Asia/Yerevan', translatedName: $localize`Asia/Yerevan`},
    {id: 'Antarctica/Casey', translatedName: $localize`Antarctica/Casey`},
    {id: 'Antarctica/Davis', translatedName: $localize`Antarctica/Davis`},
    {id: 'Antarctica/DumontDUrville', translatedName: $localize`Antarctica/DumontDUrville`},
    {id: 'Antarctica/Mawson', translatedName: $localize`Antarctica/Mawson`},
    {id: 'Antarctica/Palmer', translatedName: $localize`Antarctica/Palmer`},
    {id: 'Antarctica/Rothera', translatedName: $localize`Antarctica/Rothera`},
    {id: 'Antarctica/Syowa', translatedName: $localize`Antarctica/Syowa`},
    {id: 'Antarctica/Troll', translatedName: $localize`Antarctica/Troll`},
    {id: 'Antarctica/Vostok', translatedName: $localize`Antarctica/Vostok`},
    {id: 'America/Argentina/Buenos_Aires', translatedName: $localize`America/Argentina/Buenos Aires`},
    {id: 'America/Argentina/Cordoba', translatedName: $localize`America/Argentina/Cordoba`},
    {id: 'America/Argentina/Salta', translatedName: $localize`America/Argentina/Salta`},
    {id: 'America/Argentina/Jujuy', translatedName: $localize`America/Argentina/Jujuy`},
    {id: 'America/Argentina/Tucuman', translatedName: $localize`America/Argentina/Tucuman`},
    {id: 'America/Argentina/Catamarca', translatedName: $localize`America/Argentina/Catamarca`},
    {id: 'America/Argentina/La_Rioja', translatedName: $localize`America/Argentina/La Rioja`},
    {id: 'America/Argentina/San_Juan', translatedName: $localize`America/Argentina/San Juan`},
    {id: 'America/Argentina/Mendoza', translatedName: $localize`America/Argentina/Mendoza`},
    {id: 'America/Argentina/San_Luis', translatedName: $localize`America/Argentina/San Luis`},
    {id: 'America/Argentina/Rio_Gallegos', translatedName: $localize`America/Argentina/Rio Gallegos`},
    {id: 'America/Argentina/Ushuaia', translatedName: $localize`America/Argentina/Ushuaia`},
    {id: 'Pacific/Pago_Pago', translatedName: $localize`Pacific/Pago Pago`},
    {id: 'Europe/Vienna', translatedName: $localize`Europe/Vienna`},
    {id: 'Australia/Lord_Howe', translatedName: $localize`Australia/Lord Howe`},
    {id: 'Antarctica/Macquarie', translatedName: $localize`Antarctica/Macquarie`},
    {id: 'Australia/Hobart', translatedName: $localize`Australia/Hobart`},
    {id: 'Australia/Currie', translatedName: $localize`Australia/Currie`},
    {id: 'Australia/Melbourne', translatedName: $localize`Australia/Melbourne`},
    {id: 'Australia/Sydney', translatedName: $localize`Australia/Sydney`},
    {id: 'Australia/Broken_Hill', translatedName: $localize`Australia/Broken Hill`},
    {id: 'Australia/Brisbane', translatedName: $localize`Australia/Brisbane`},
    {id: 'Australia/Lindeman', translatedName: $localize`Australia/Lindeman`},
    {id: 'Australia/Adelaide', translatedName: $localize`Australia/Adelaide`},
    {id: 'Australia/Darwin', translatedName: $localize`Australia/Darwin`},
    {id: 'Australia/Perth', translatedName: $localize`Australia/Perth`},
    {id: 'Australia/Eucla', translatedName: $localize`Australia/Eucla`},
    {id: 'Asia/Baku', translatedName: $localize`Asia/Baku`},
    {id: 'America/Barbados', translatedName: $localize`America/Barbados`},
    {id: 'Asia/Dhaka', translatedName: $localize`Asia/Dhaka`},
    {id: 'Europe/Brussels', translatedName: $localize`Europe/Brussels`},
    {id: 'Europe/Sofia', translatedName: $localize`Europe/Sofia`},
    {id: 'Atlantic/Bermuda', translatedName: $localize`Atlantic/Bermuda`},
    {id: 'Asia/Brunei', translatedName: $localize`Asia/Brunei`},
    {id: 'America/La_Paz', translatedName: $localize`America/La Paz`},
    {id: 'America/Noronha', translatedName: $localize`America/Noronha`},
    {id: 'America/Belem', translatedName: $localize`America/Belem`},
    {id: 'America/Fortaleza', translatedName: $localize`America/Fortaleza`},
    {id: 'America/Recife', translatedName: $localize`America/Recife`},
    {id: 'America/Araguaina', translatedName: $localize`America/Araguaina`},
    {id: 'America/Maceio', translatedName: $localize`America/Maceio`},
    {id: 'America/Bahia', translatedName: $localize`America/Bahia`},
    {id: 'America/Sao_Paulo', translatedName: $localize`America/Sao Paulo`},
    {id: 'America/Campo_Grande', translatedName: $localize`America/Campo Grande`},
    {id: 'America/Cuiaba', translatedName: $localize`America/Cuiaba`},
    {id: 'America/Santarem', translatedName: $localize`America/Santarem`},
    {id: 'America/Porto_Velho', translatedName: $localize`America/Porto_Velho`},
    {id: 'America/Boa_Vista', translatedName: $localize`America/Boa_Vista`},
    {id: 'America/Manaus', translatedName: $localize`America/Manaus`},
    {id: 'America/Eirunepe', translatedName: $localize`America/Eirunepe`},
    {id: 'America/Rio_Branco', translatedName: $localize`America/Rio Branco`},
    {id: 'America/Nassau', translatedName: $localize`America/Nassau`},
    {id: 'Asia/Thimphu', translatedName: $localize`Asia/Thimphu`},
    {id: 'Europe/Minsk', translatedName: $localize`Europe/Minsk`},
    {id: 'America/Belize', translatedName: $localize`America/Belize`},
    {id: 'America/St_Johns', translatedName: $localize`America/St Johns`},
    {id: 'America/Halifax', translatedName: $localize`America/Halifax`},
    {id: 'America/Glace_Bay', translatedName: $localize`America/Glace Bay`},
    {id: 'America/Moncton', translatedName: $localize`America/Moncton`},
    {id: 'America/Goose_Bay', translatedName: $localize`America/Goose Bay`},
    {id: 'America/Blanc-Sablon', translatedName: $localize`America/Blanc-Sablon`},
    {id: 'America/Toronto', translatedName: $localize`America/Toronto`},
    {id: 'America/Nipigon', translatedName: $localize`America/Nipigon`},
    {id: 'America/Thunder_Bay', translatedName: $localize`America/Thunder Bay`},
    {id: 'America/Iqaluit', translatedName: $localize`America/Iqaluit`},
    {id: 'America/Pangnirtung', translatedName: $localize`America/Pangnirtung`},
    {id: 'America/Atikokan', translatedName: $localize`America/Atikokan`},
    {id: 'America/Winnipeg', translatedName: $localize`America/Winnipeg`},
    {id: 'America/Rainy_River', translatedName: $localize`America/Rainy River`},
    {id: 'America/Resolute', translatedName: $localize`America/Resolute`},
    {id: 'America/Rankin_Inlet', translatedName: $localize`America/Rankin Inlet`},
    {id: 'America/Regina', translatedName: $localize`America/Regina`},
    {id: 'America/Swift_Current', translatedName: $localize`America/Swift Current`},
    {id: 'America/Edmonton', translatedName: $localize`America/Edmonton`},
    {id: 'America/Cambridge_Bay', translatedName: $localize`America/Cambridge Bay`},
    {id: 'America/Yellowknife', translatedName: $localize`America/Yellowknife`},
    {id: 'America/Inuvik', translatedName: $localize`America/Inuvik`},
    {id: 'America/Creston', translatedName: $localize`America/Creston`},
    {id: 'America/Dawson_Creek', translatedName: $localize`America/Dawson Creek`},
    {id: 'America/Fort_Nelson', translatedName: $localize`America/Fort Nelson`},
    {id: 'America/Vancouver', translatedName: $localize`America/Vancouver`},
    {id: 'America/Whitehorse', translatedName: $localize`America/Whitehorse`},
    {id: 'America/Dawson', translatedName: $localize`America/Dawson`},
    {id: 'Indian/Cocos', translatedName: $localize`Indian/Cocos`},
    {id: 'Europe/Zurich', translatedName: $localize`Europe/Zurich`},
    {id: 'Africa/Abidjan', translatedName: $localize`Africa/Abidjan`},
    {id: 'Pacific/Rarotonga', translatedName: $localize`Pacific/Rarotonga`},
    {id: 'America/Santiago', translatedName: $localize`America/Santiago`},
    {id: 'America/Punta_Arenas', translatedName: $localize`America/Punta Arenas`},
    {id: 'Pacific/Easter', translatedName: $localize`Pacific/Easter`},
    {id: 'Asia/Shanghai', translatedName: $localize`Asia/Shanghai`},
    {id: 'Asia/Urumqi', translatedName: $localize`Asia/Urumqi`},
    {id: 'America/Bogota', translatedName: $localize`America/Bogota`},
    {id: 'America/Costa_Rica', translatedName: $localize`America/Costa Rica`},
    {id: 'America/Havana', translatedName: $localize`America/Havana`},
    {id: 'Atlantic/Cape_Verde', translatedName: $localize`Atlantic/Cape Verde`},
    {id: 'America/Curacao', translatedName: $localize`America/Curacao`},
    {id: 'Indian/Christmas', translatedName: $localize`Indian/Christmas`},
    {id: 'Asia/Nicosia', translatedName: $localize`Asia/Nicosia`},
    {id: 'Asia/Famagusta', translatedName: $localize`Asia/Famagusta`},
    {id: 'Europe/Prague', translatedName: $localize`Europe/Prague`},
    {id: 'Europe/Berlin', translatedName: $localize`Europe/Berlin`},
    {id: 'Europe/Copenhagen', translatedName: $localize`Europe/Copenhagen`},
    {id: 'America/Santo_Domingo', translatedName: $localize`America/Santo Domingo`},
    {id: 'Africa/Algiers', translatedName: $localize`Africa/Algiers`},
    {id: 'America/Guayaquil', translatedName: $localize`America/Guayaquil`},
    {id: 'Pacific/Galapagos', translatedName: $localize`Pacific/Galapagos`},
    {id: 'Europe/Tallinn', translatedName: $localize`Europe/Tallinn`},
    {id: 'Africa/Cairo', translatedName: $localize`Africa/Cairo`},
    {id: 'Africa/El_Aaiun', translatedName: $localize`Africa/El Aaiun`},
    {id: 'Europe/Madrid', translatedName: $localize`Europe/Madrid`},
    {id: 'Africa/Ceuta', translatedName: $localize`Africa/Ceuta`},
    {id: 'Atlantic/Canary', translatedName: $localize`Atlantic/Canary`},
    {id: 'Europe/Helsinki', translatedName: $localize`Europe/Helsinki`},
    {id: 'Pacific/Fiji', translatedName: $localize`Pacific/Fiji`},
    {id: 'Atlantic/Stanley', translatedName: $localize`Atlantic/Stanley`},
    {id: 'Pacific/Chuuk', translatedName: $localize`Pacific/Chuuk`},
    {id: 'Pacific/Pohnpei', translatedName: $localize`Pacific/Pohnpei`},
    {id: 'Pacific/Kosrae', translatedName: $localize`Pacific/Kosrae`},
    {id: 'Atlantic/Faroe', translatedName: $localize`Atlantic/Faroe`},
    {id: 'Europe/Paris', translatedName: $localize`Europe/Paris`},
    {id: 'Europe/London', translatedName: $localize`Europe/London`},
    {id: 'Asia/Tbilisi', translatedName: $localize`Asia/Tbilisi`},
    {id: 'America/Cayenne', translatedName: $localize`America/Cayenne`},
    {id: 'Africa/Accra', translatedName: $localize`Africa/Accra`},
    {id: 'Europe/Gibraltar', translatedName: $localize`Europe/Gibraltar`},
    {id: 'America/Godthab', translatedName: $localize`America/Godthab`},
    {id: 'America/Danmarkshavn', translatedName: $localize`America/Danmarkshavn`},
    {id: 'America/Scoresbysund', translatedName: $localize`America/Scoresbysund`},
    {id: 'America/Thule', translatedName: $localize`America/Thule`},
    {id: 'Europe/Athens', translatedName: $localize`Europe/Athens`},
    {id: 'Atlantic/South_Georgia', translatedName: $localize`Atlantic/South Georgia`},
    {id: 'America/Guatemala', translatedName: $localize`America/Guatemala`},
    {id: 'Pacific/Guam', translatedName: $localize`Pacific/Guam`},
    {id: 'Africa/Bissau', translatedName: $localize`Africa/Bissau`},
    {id: 'America/Guyana', translatedName: $localize`America/Guyana`},
    {id: 'Asia/Hong_Kong', translatedName: $localize`Asia/Hong Kong`},
    {id: 'America/Tegucigalpa', translatedName: $localize`America/Tegucigalpa`},
    {id: 'America/Port-au-Prince', translatedName: $localize`America/Port-au-Prince`},
    {id: 'Europe/Budapest', translatedName: $localize`Europe/Budapest`},
    {id: 'Asia/Jakarta', translatedName: $localize`Asia/Jakarta`},
    {id: 'Asia/Pontianak', translatedName: $localize`Asia/Pontianak`},
    {id: 'Asia/Makassar', translatedName: $localize`Asia/Makassar`},
    {id: 'Asia/Jayapura', translatedName: $localize`Asia/Jayapura`},
    {id: 'Europe/Dublin', translatedName: $localize`Europe/Dublin`},
    {id: 'Asia/Jerusalem', translatedName: $localize`Asia/Jerusalem`},
    {id: 'Asia/Kolkata', translatedName: $localize`Asia/Kolkata`},
    {id: 'Indian/Chagos', translatedName: $localize`Indian/Chagos`},
    {id: 'Asia/Baghdad', translatedName: $localize`Asia/Baghdad`},
    {id: 'Asia/Tehran', translatedName: $localize`Asia/Tehran`},
    {id: 'Atlantic/Reykjavik', translatedName: $localize`Atlantic/Reykjavik`},
    {id: 'Europe/Rome', translatedName: $localize`Europe/Rome`},
    {id: 'America/Jamaica', translatedName: $localize`America/Jamaica`},
    {id: 'Asia/Amman', translatedName: $localize`Asia/Amman`},
    {id: 'Asia/Tokyo', translatedName: $localize`Asia/Tokyo`},
    {id: 'Africa/Nairobi', translatedName: $localize`Africa/Nairobi`},
    {id: 'Asia/Bishkek', translatedName: $localize`Asia/Bishkek`},
    {id: 'Pacific/Tarawa', translatedName: $localize`Pacific/Tarawa`},
    {id: 'Pacific/Enderbury', translatedName: $localize`Pacific/Enderbury`},
    {id: 'Pacific/Kiritimati', translatedName: $localize`Pacific/Kiritimati`},
    {id: 'Asia/Pyongyang', translatedName: $localize`Asia/Pyongyang`},
    {id: 'Asia/Seoul', translatedName: $localize`Asia/Seoul`},
    {id: 'Asia/Almaty', translatedName: $localize`Asia/Almaty`},
    {id: 'Asia/Qyzylorda', translatedName: $localize`Asia/Qyzylorda`},
    {id: 'Asia/Qostanay', translatedName: $localize`Asia/Qostanay`},
    {id: 'Asia/Aqtobe', translatedName: $localize`Asia/Aqtobe`},
    {id: 'Asia/Aqtau', translatedName: $localize`Asia/Aqtau`},
    {id: 'Asia/Atyrau', translatedName: $localize`Asia/Atyrau`},
    {id: 'Asia/Oral', translatedName: $localize`Asia/Oral`},
    {id: 'Asia/Beirut', translatedName: $localize`Asia/Beirut`},
    {id: 'Asia/Colombo', translatedName: $localize`Asia/Colombo`},
    {id: 'Africa/Monrovia', translatedName: $localize`Africa/Monrovia`},
    {id: 'Europe/Vilnius', translatedName: $localize`Europe/Vilnius`},
    {id: 'Europe/Luxembourg', translatedName: $localize`Europe/Luxembourg`},
    {id: 'Europe/Riga', translatedName: $localize`Europe/Riga`},
    {id: 'Africa/Tripoli', translatedName: $localize`Africa/Tripoli`},
    {id: 'Africa/Casablanca', translatedName: $localize`Africa/Casablanca`},
    {id: 'Europe/Monaco', translatedName: $localize`Europe/Monaco`},
    {id: 'Europe/Chisinau', translatedName: $localize`Europe/Chisinau`},
    {id: 'Pacific/Majuro', translatedName: $localize`Pacific/Majuro`},
    {id: 'Pacific/Kwajalein', translatedName: $localize`Pacific/Kwajalein`},
    {id: 'Asia/Yangon', translatedName: $localize`Asia/Yangon`},
    {id: 'Asia/Ulaanbaatar', translatedName: $localize`Asia/Ulaanbaatar`},
    {id: 'Asia/Hovd', translatedName: $localize`Asia/Hovd`},
    {id: 'Asia/Choibalsan', translatedName: $localize`Asia/Choibalsan`},
    {id: 'Asia/Macau', translatedName: $localize`Asia/Macau`},
    {id: 'America/Martinique', translatedName: $localize`America/Martinique`},
    {id: 'Europe/Malta', translatedName: $localize`Europe/Malta`},
    {id: 'Indian/Mauritius', translatedName: $localize`Indian/Mauritius`},
    {id: 'Indian/Maldives', translatedName: $localize`Indian/Maldives`},
    {id: 'America/Mexico_City', translatedName: $localize`America/Mexico City`},
    {id: 'America/Cancun', translatedName: $localize`America/Cancun`},
    {id: 'America/Merida', translatedName: $localize`America/Merida`},
    {id: 'America/Monterrey', translatedName: $localize`America/Monterrey`},
    {id: 'America/Matamoros', translatedName: $localize`America/Matamoros`},
    {id: 'America/Mazatlan', translatedName: $localize`America/Mazatlan`},
    {id: 'America/Chihuahua', translatedName: $localize`America/Chihuahua`},
    {id: 'America/Ojinaga', translatedName: $localize`America/Ojinaga`},
    {id: 'America/Hermosillo', translatedName: $localize`America/Hermosillo`},
    {id: 'America/Tijuana', translatedName: $localize`America/Tijuana`},
    {id: 'America/Bahia_Banderas', translatedName: $localize`America/Bahia Banderas`},
    {id: 'Asia/Kuala_Lumpur', translatedName: $localize`Asia/Kuala Lumpur`},
    {id: 'Asia/Kuching', translatedName: $localize`Asia/Kuching`},
    {id: 'Africa/Maputo', translatedName: $localize`Africa/Maputo`},
    {id: 'Africa/Windhoek', translatedName: $localize`Africa/Windhoek`},
    {id: 'Pacific/Noumea', translatedName: $localize`Pacific/Noumea`},
    {id: 'Pacific/Norfolk', translatedName: $localize`Pacific/Norfolk`},
    {id: 'Africa/Lagos', translatedName: $localize`Africa/Lagos`},
    {id: 'America/Managua', translatedName: $localize`America/Managua`},
    {id: 'Europe/Amsterdam', translatedName: $localize`Europe/Amsterdam`},
    {id: 'Europe/Oslo', translatedName: $localize`Europe/Oslo`},
    {id: 'Asia/Kathmandu', translatedName: $localize`Asia/Kathmandu`},
    {id: 'Pacific/Nauru', translatedName: $localize`Pacific/Nauru`},
    {id: 'Pacific/Niue', translatedName: $localize`Pacific/Niue`},
    {id: 'Pacific/Auckland', translatedName: $localize`Pacific/Auckland`},
    {id: 'Pacific/Chatham', translatedName: $localize`Pacific/Chatham`},
    {id: 'America/Panama', translatedName: $localize`America/Panama`},
    {id: 'America/Lima', translatedName: $localize`America/Lima`},
    {id: 'Pacific/Tahiti', translatedName: $localize`Pacific/Tahiti`},
    {id: 'Pacific/Marquesas', translatedName: $localize`Pacific/Marquesas`},
    {id: 'Pacific/Gambier', translatedName: $localize`Pacific/Gambier`},
    {id: 'Pacific/Port_Moresby', translatedName: $localize`Pacific/Port Moresby`},
    {id: 'Pacific/Bougainville', translatedName: $localize`Pacific/Bougainville`},
    {id: 'Asia/Manila', translatedName: $localize`Asia/Manila`},
    {id: 'Asia/Karachi', translatedName: $localize`Asia/Karachi`},
    {id: 'Europe/Warsaw', translatedName: $localize`Europe/Warsaw`},
    {id: 'America/Miquelon', translatedName: $localize`America/Miquelon`},
    {id: 'Pacific/Pitcairn', translatedName: $localize`Pacific/Pitcairn`},
    {id: 'America/Puerto_Rico', translatedName: $localize`America/Puerto Rico`},
    {id: 'Asia/Gaza', translatedName: $localize`Asia/Gaza`},
    {id: 'Asia/Hebron', translatedName: $localize`Asia/Hebron`},
    {id: 'Europe/Lisbon', translatedName: $localize`Europe/Lisbon`},
    {id: 'Atlantic/Madeira', translatedName: $localize`Atlantic/Madeira`},
    {id: 'Atlantic/Azores', translatedName: $localize`Atlantic/Azores`},
    {id: 'Pacific/Palau', translatedName: $localize`Pacific/Palau`},
    {id: 'America/Asuncion', translatedName: $localize`America/Asuncion`},
    {id: 'Asia/Qatar', translatedName: $localize`Asia/Qatar`},
    {id: 'Indian/Reunion', translatedName: $localize`Indian/Reunion`},
    {id: 'Europe/Bucharest', translatedName: $localize`Europe/Bucharest`},
    {id: 'Europe/Belgrade', translatedName: $localize`Europe/Belgrade`},
    {id: 'Europe/Kaliningrad', translatedName: $localize`Europe/Kaliningrad`},
    {id: 'Europe/Moscow', translatedName: $localize`Europe/Moscow`},
    {id: 'Europe/Simferopol', translatedName: $localize`Europe/Simferopol`},
    {id: 'Europe/Kirov', translatedName: $localize`Europe/Kirov`},
    {id: 'Europe/Astrakhan', translatedName: $localize`Europe/Astrakhan`},
    {id: 'Europe/Volgograd', translatedName: $localize`Europe/Volgograd`},
    {id: 'Europe/Saratov', translatedName: $localize`Europe/Saratov`},
    {id: 'Europe/Ulyanovsk', translatedName: $localize`Europe/Ulyanovsk`},
    {id: 'Europe/Samara', translatedName: $localize`Europe/Samara`},
    {id: 'Asia/Yekaterinburg', translatedName: $localize`Asia/Yekaterinburg`},
    {id: 'Asia/Omsk', translatedName: $localize`Asia/Omsk`},
    {id: 'Asia/Novosibirsk', translatedName: $localize`Asia/Novosibirsk`},
    {id: 'Asia/Barnaul', translatedName: $localize`Asia/Barnaul`},
    {id: 'Asia/Tomsk', translatedName: $localize`Asia/Tomsk`},
    {id: 'Asia/Novokuznetsk', translatedName: $localize`Asia/Novokuznetsk`},
    {id: 'Asia/Krasnoyarsk', translatedName: $localize`Asia/Krasnoyarsk`},
    {id: 'Asia/Irkutsk', translatedName: $localize`Asia/Irkutsk`},
    {id: 'Asia/Chita', translatedName: $localize`Asia/Chita`},
    {id: 'Asia/Yakutsk', translatedName: $localize`Asia/Yakutsk`},
    {id: 'Asia/Khandyga', translatedName: $localize`Asia/Khandyga`},
    {id: 'Asia/Vladivostok', translatedName: $localize`Asia/Vladivostok`},
    {id: 'Asia/Ust-Nera', translatedName: $localize`Asia/Ust-Nera`},
    {id: 'Asia/Magadan', translatedName: $localize`Asia/Magadan`},
    {id: 'Asia/Sakhalin', translatedName: $localize`Asia/Sakhalin`},
    {id: 'Asia/Srednekolymsk', translatedName: $localize`Asia/Srednekolymsk`},
    {id: 'Asia/Kamchatka', translatedName: $localize`Asia/Kamchatka`},
    {id: 'Asia/Anadyr', translatedName: $localize`Asia/Anadyr`},
    {id: 'Asia/Riyadh', translatedName: $localize`Asia/Riyadh`},
    {id: 'Pacific/Guadalcanal', translatedName: $localize`Pacific/Guadalcanal`},
    {id: 'Indian/Mahe', translatedName: $localize`Indian/Mahe`},
    {id: 'Africa/Khartoum', translatedName: $localize`Africa/Khartoum`},
    {id: 'Europe/Stockholm', translatedName: $localize`Europe/Stockholm`},
    {id: 'Asia/Singapore', translatedName: $localize`Asia/Singapore`},
    {id: 'America/Paramaribo', translatedName: $localize`America/Paramaribo`},
    {id: 'Africa/Juba', translatedName: $localize`Africa/Juba`},
    {id: 'Africa/Sao_Tome', translatedName: $localize`Africa/Sao Tome`},
    {id: 'America/El_Salvador', translatedName: $localize`America/El Salvador`},
    {id: 'Asia/Damascus', translatedName: $localize`Asia/Damascus`},
    {id: 'America/Grand_Turk', translatedName: $localize`America/Grand Turk`},
    {id: 'Africa/Ndjamena', translatedName: $localize`Africa/Ndjamena`},
    {id: 'Indian/Kerguelen', translatedName: $localize`Indian/Kerguelen`},
    {id: 'Asia/Bangkok', translatedName: $localize`Asia/Bangkok`},
    {id: 'Asia/Dushanbe', translatedName: $localize`Asia/Dushanbe`},
    {id: 'Pacific/Fakaofo', translatedName: $localize`Pacific/Fakaofo`},
    {id: 'Asia/Dili', translatedName: $localize`Asia/Dili`},
    {id: 'Asia/Ashgabat', translatedName: $localize`Asia/Ashgabat`},
    {id: 'Africa/Tunis', translatedName: $localize`Africa/Tunis`},
    {id: 'Pacific/Tongatapu', translatedName: $localize`Pacific/Tongatapu`},
    {id: 'Europe/Istanbul', translatedName: $localize`Europe/Istanbul`},
    {id: 'America/Port_of_Spain', translatedName: $localize`America/Port of Spain`},
    {id: 'Pacific/Funafuti', translatedName: $localize`Pacific/Funafuti`},
    {id: 'Asia/Taipei', translatedName: $localize`Asia/Taipei`},
    {id: 'Europe/Kiev', translatedName: $localize`Europe/Kiev`},
    {id: 'Europe/Uzhgorod', translatedName: $localize`Europe/Uzhgorod`},
    {id: 'Europe/Zaporozhye', translatedName: $localize`Europe/Zaporozhye`},
    {id: 'Pacific/Wake', translatedName: $localize`Pacific/Wake`},
    {id: 'America/New_York', translatedName: $localize`America/New York`},
    {id: 'America/Detroit', translatedName: $localize`America/Detroit`},
    {id: 'America/Kentucky/Louisville', translatedName: $localize`America/Kentucky/Louisville`},
    {id: 'America/Kentucky/Monticello', translatedName: $localize`America/Kentucky/Monticello`},
    {id: 'America/Indiana/Indianapolis', translatedName: $localize`America/Indiana/Indianapolis`},
    {id: 'America/Indiana/Vincennes', translatedName: $localize`America/Indiana/Vincennes`},
    {id: 'America/Indiana/Winamac', translatedName: $localize`America/Indiana/Winamac`},
    {id: 'America/Indiana/Marengo', translatedName: $localize`America/Indiana/Marengo`},
    {id: 'America/Indiana/Petersburg', translatedName: $localize`America/Indiana/Petersburg`},
    {id: 'America/Indiana/Vevay', translatedName: $localize`America/Indiana/Vevay`},
    {id: 'America/Chicago', translatedName: $localize`America/Chicago`},
    {id: 'America/Indiana/Tell_City', translatedName: $localize`America/Indiana/Tell City`},
    {id: 'America/Indiana/Knox', translatedName: $localize`America/Indiana/Knox`},
    {id: 'America/Menominee', translatedName: $localize`America/Menominee`},
    {id: 'America/North_Dakota/Center', translatedName: $localize`America/North Dakota/Center`},
    {id: 'America/North_Dakota/New_Salem', translatedName: $localize`America/North Dakota/New Salem`},
    {id: 'America/North_Dakota/Beulah', translatedName: $localize`America/North Dakota/Beulah`},
    {id: 'America/Denver', translatedName: $localize`America/Denver`},
    {id: 'America/Boise', translatedName: $localize`America/Boise`},
    {id: 'America/Phoenix', translatedName: $localize`America/Phoenix`},
    {id: 'America/Los_Angeles', translatedName: $localize`America/Los Angeles`},
    {id: 'America/Anchorage', translatedName: $localize`America/Anchorage`},
    {id: 'America/Juneau', translatedName: $localize`America/Juneau`},
    {id: 'America/Sitka', translatedName: $localize`America/Sitka`},
    {id: 'America/Metlakatla', translatedName: $localize`America/Metlakatla`},
    {id: 'America/Yakutat', translatedName: $localize`America/Yakutat`},
    {id: 'America/Nome', translatedName: $localize`America/Nome`},
    {id: 'America/Adak', translatedName: $localize`America/Adak`},
    {id: 'Pacific/Honolulu', translatedName: $localize`Pacific/Honolulu`},
    {id: 'America/Montevideo', translatedName: $localize`America/Montevideo`},
    {id: 'Asia/Samarkand', translatedName: $localize`Asia/Samarkand`},
    {id: 'Asia/Tashkent', translatedName: $localize`Asia/Tashkent`},
    {id: 'America/Caracas', translatedName: $localize`America/Caracas`},
    {id: 'Asia/Ho_Chi_Minh', translatedName: $localize`Asia/Ho Chi Minh`},
    {id: 'Pacific/Efate', translatedName: $localize`Pacific/Efate`},
    {id: 'Pacific/Wallis', translatedName: $localize`Pacific/Wallis`},
    {id: 'Pacific/Apia', translatedName: $localize`Pacific/Apia`},
    {id: 'Africa/Johannesburg', translatedName: $localize`Africa/Johannesburg`},
  ];

  orderedTimeZones ?: TimeZone[];

  constructor() {
  }

  getTimeZones(): TimeZone[] {
    return this.timeZones;
  }

  getOrderedTimeZones(): TimeZone[] {
    if (!this.orderedTimeZones) {
      this.orderedTimeZones = this.timeZones.sort((a, b) => {
        if (a.translatedName < b.translatedName)
          return -1;
        if (a.translatedName > b.translatedName)
          return 1;
        return 0;
      });
    }
    return this.orderedTimeZones;
  }
}
