import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilService} from '../../shared/util.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {

  constructor(
    private router: Router,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const subdomain = this.getSubdomain();
    switch (subdomain) {
      case 'go':
        return this.redirectToReferralLink();
    }

  }

  /**
   * Delivers the current subdomain. If the subdomain is 'www', an empty string is returned.
   * @return current subdomain
   */
  getSubdomain(): string {
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 || domain.split('.')[0] === 'www')
      return '';
    return domain.split('.')[0];

  }

  /**
   * Redirects to the referral link, if the referral name can be determined
   */
  private redirectToReferralLink() {
    // Determine referral name
    const href = window.location.href;
    console.log(`href: ${href}`);
    if (href.indexOf('/') < 0)
      return;

    const parts = href.split('/');
    const refName = parts[parts.length - 1];
    console.log(`refName: ${refName}`);
    if (refName === 'ref' || refName === '')
      return;

    const fullRefLink = `${environment.applicationRootUrl}/ref/${refName}`;
    console.log(`Redirecting to ${fullRefLink}...`);
    this.utilService.redirectExternal(fullRefLink);
  }
}
