import {Injectable} from '@angular/core';
import firebase from 'firebase/app';
// for cloud firestore
import {environment} from '../../../environments/environment';
import {StripeLoginLinkRequest} from '../models/stripe/stripeLoginLinkRequest.interface';
import {StripeAccountLink} from '../models/stripe/stripeAccountLink.interface';
import {StripeAccountLinkRequest} from '../models/stripe/stripeAccountLinkRequest.interface';
import * as MangoPay from '../models/mangopay/typings';
import {StripeAccountCreationRequest} from '../models/stripe/stripeAccountCreationRequest.interface';
import {StripeAccountCreationResult} from '../models/stripe/stripeAccountCreationResult.interface';
import {PayInRequest} from '../models/mangopay/payInRequest.interface';
import {DeactivateBankAccountRequest} from '../models/mangopay/deactivateBankAccountRequest.interface';
import {KycDocumentFetchingRequest} from '../models/mangopay/kycDocumentFetchingRequest.interface';
import {UserRequest} from '../models/mangopay/userCreationRequest.interface';
import {WalletCreationRequest} from '../models/mangopay/walletCreationRequest.interface';
import {BankAccountCreationRequest} from '../models/mangopay/bankAccountCreationRequest.interface';
import {KycDocumentCreationRequest} from '../models/mangopay/kycDocumentCreationRequest.interface';
import {WalletFetchingRequest} from '../models/mangopay/walletFetchingRequest.interface';
import {BankAccountFetchingRequest} from '../models/mangopay/bankAccountFetchingRequest.interface';
import {Payment} from '../models/payment.interface';
import {ValidatePayInRequest} from '../models/mangopay/validatePayInRequest.interface';
import {Address} from '../models/address.interface';
import {PayOutRequest} from '../models/mangopay/payOutRequest.interface';
import {Referral} from '../models/referral.interface';
import Functions = firebase.functions.Functions;
import HttpsError = firebase.functions.HttpsError;
import BankAccount = MangoPay.models.BankAccount;
import WalletData = MangoPay.wallet.WalletData;
import UserNaturalData = MangoPay.user.UserNaturalData;
import KycDocumentData = MangoPay.kycDocument.KycDocumentData;
import CardWebPayInData = MangoPay.payIn.CardWebPayInData;
import PayOutData = MangoPay.PayOut.PayOutData;

// Required for side-effects
require('firebase/functions');

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {

  functions: Functions;

  constructor() {
    this.functions = firebase.app().functions(environment.firebaseConfig.regionName);
  }

  createMangopayUser(params: UserRequest, onSuccessCallback: (userData: UserNaturalData) => void,
                     onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayOnboarding-createUser', params, onSuccessCallback, onErrorCallback);
  }

  updateMangopayUser(params: UserRequest, onSuccessCallback: (userData: UserNaturalData) => void,
                     onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayOnboarding-updateUser', params, onSuccessCallback, onErrorCallback);
  }

  createMangopayWallet(params: WalletCreationRequest, onSuccessCallback: (account: WalletData) => void,
                       onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayOnboarding-createWallet', params, onSuccessCallback, onErrorCallback);
  }

  createMangopayBankAccount(params: BankAccountCreationRequest, onSuccessCallback: (account: BankAccount) => void,
                            onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayOnboarding-createBankAccount', params, onSuccessCallback, onErrorCallback);

  }

  createMangopayKycDocument(params: KycDocumentCreationRequest, onSuccessCallback: (kycDocument: KycDocumentData) => void,
                            onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayOnboarding-createKycDocument', params, onSuccessCallback, onErrorCallback);

  }

  fetchMangopayWallets(params: WalletFetchingRequest, onSuccessCallback: (wallets: WalletData[]) => void,
                       onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayOnboarding-fetchWallets', params, onSuccessCallback, onErrorCallback);

  }

  createMangopayPayOut(params: PayOutRequest, onSuccessCallback: (payOutData: PayOutData) => void,
                       onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayPayment-createPayOut', params, onSuccessCallback, onErrorCallback);
  }

  fetchMangopayBankAccounts(params: BankAccountFetchingRequest, onSuccessCallback: (bankAccounts: BankAccount[]) => void,
                            onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayOnboarding-fetchBankAccounts', params, onSuccessCallback, onErrorCallback);
  }

  fetchMangopayKycDocuments(params: KycDocumentFetchingRequest, onSuccessCallback: (kycDocuments: KycDocumentData[]) => void,
                            onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayOnboarding-fetchKycDocuments', params, onSuccessCallback, onErrorCallback);
  }

  deactivateMangopayBankAccount(params: DeactivateBankAccountRequest, onSuccessCallback: (success: boolean) => void,
                                onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayOnboarding-deactivateBankAccount', params, onSuccessCallback, onErrorCallback);
  }

  createMangopayPayIn(params: PayInRequest, onSuccessCallback: (cardWebPayInData: CardWebPayInData) => void,
                      onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayPayment-createPayIn', params, onSuccessCallback, onErrorCallback);
  }

  validateMangopayPayment(params: ValidatePayInRequest, onSuccessCallback: (payment: Payment) => void, onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('mangopayPayment-validatePayIn', params, onSuccessCallback, onErrorCallback);
  }

  loadAddress(params: { userUid: string, transactionUid: string }, onSuccessCallback: (response: { address: Address, phone: string | null }) => void, onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('user-loadAddress', params, onSuccessCallback, onErrorCallback);
  }

  createStripeAccount(params: StripeAccountCreationRequest, onSuccessCallback: (account: StripeAccountCreationResult) => void,
                      onErrorCallback: (errorMessage: string) => void) {
    const createAccount = this.functions.httpsCallable('stripe-createAccount');
    createAccount(params)
      .then((result: any) => {
        onSuccessCallback(result.data);
      })
      .catch((error: HttpsError) => {
        onErrorCallback(error.message);
      });
  }

  createLoginLink(params: StripeLoginLinkRequest, onSuccessCallback: (accountLink: StripeAccountLink) => void,
                  onErrorCallback: (errorMessage: string) => void) {
    const createLoginLink = this.functions.httpsCallable('stripe-createLoginLink');
    createLoginLink(params)
      .then((result: any) => {
        onSuccessCallback(result.data);
      })
      .catch((error: HttpsError) => {
        onErrorCallback(error.message);
      });
  }

  createAccountLink(params: StripeAccountLinkRequest, onSuccessCallback: (accountLink: StripeAccountLink) => void,
                    onErrorCallback: (errorMessage: string) => void) {
    const createAccountLink = this.functions.httpsCallable('stripe-createAccountLink');
    createAccountLink(params)
      .then((result: any) => {
        onSuccessCallback(result.data);
      })
      .catch((error: HttpsError) => {
        onErrorCallback(error.message);
      });
  }

  createReferral(params: Referral, onSuccessCallback: (referral: Referral) => void,
                 onErrorCallback: (errorMessage: string) => void) {
    const createReferral = this.functions.httpsCallable('referral-createReferral');
    createReferral(params)
      .then((result: any) => {
        onSuccessCallback(result.data);
      })
      .catch((error: HttpsError) => {
        if (error.code === 'already-exists')
          onErrorCallback($localize`The name ${params.name} is already in use. Please use another name.`);
        else
          onErrorCallback(error.message);
      });
  }

  updateReferral(params: Referral, onSuccessCallback: (referral: Referral) => void,
                 onErrorCallback: (errorMessage: string) => void) {
    const updateReferral = this.functions.httpsCallable('referral-updateReferral');
    updateReferral(params)
      .then((result: any) => {
        onSuccessCallback(result.data);
      })
      .catch((error: HttpsError) => {
        onErrorCallback(error.message);
      });
  }

  getReferreesCount(referralUid: string, onSuccessCallback: (count: number) => void,
                    onErrorCallback: (errorMessage: string) => void) {
    const getReferreesCount = this.functions.httpsCallable('referral-getReferreesCount');
    getReferreesCount(referralUid)
      .then((result: any) => {
        onSuccessCallback(result.data);
      })
      .catch((error: HttpsError) => {
        onErrorCallback(error.message);
      });
  }

  setReferralForNewUser(refName: string, onSuccessCallback: (referralUid: string) => void,
                        onErrorCallback: (errorMessage: string) => void) {
    const setReferralForNewUser = this.functions.httpsCallable('referral-setReferralForNewUser');
    setReferralForNewUser(refName)
      .then((result: any) => {
        onSuccessCallback(result.data);
      })
      .catch((error: HttpsError) => {
        onErrorCallback(error.message);
      });
  }

  private runFunction(name: string, params: any, onSuccessCallback: (result: any) => void, onErrorCallback: (errorMessage: string) => void) {
    const fun = this.functions.httpsCallable(name);
    fun(params)
      .then((result: any) => {
        onSuccessCallback(result.data);
      })
      .catch((error: HttpsError) => {
        onErrorCallback(error.message);
      });
  }

}
