import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BrowserService} from '../../browser/browser.service';
import {Router} from '@angular/router';
import {Search} from '../../shared/models/search.interface';
import {Language} from '../../shared/models/language.interface';
import {LanguageService} from '../../shared/services/language.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {selectSearch} from '../../browser/store/browser.selectors';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  collapsed = true;

  searchForm: FormGroup | undefined;
  search: Search = {};


  langs: Language[] = [];
  iconSize = 32;
  currentLang ?: Language;

  search$ = this.store.select(selectSearch).pipe(takeUntil(this.destroy$));

  constructor(
      protected store: Store<AppState>,
      private browserService: BrowserService,
      public languageService: LanguageService,
      private router: Router) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.searchForm = this.createForm();
    this.search$.subscribe((search) => {
      this.search = search;
      if (this.searchForm)
        this.searchForm.patchValue({searchTerm: search?.searchTerm});
    });

    this.langs = this.languageService.languages;
    // Load and set user's lang
    this.user$.subscribe(user => {
      if (!user)
        return;
      const userLangCode = user.settings?.lang;
      this.currentLang = this.langs.find(lang => lang.code === userLangCode);
    });
  }


  createForm(): FormGroup {
    return new FormGroup({
      searchTerm: new FormControl(null),
    });
  }

  onSubmit(): void {
    const searchTerm = this.searchForm?.value.searchTerm;
    this.browserService.navigateToSearch();
    this.browserService.updateSearchTerm(searchTerm);
    this.browserService.fetchSearchResults({...this.search, lenderUid: undefined});
    this.collapseNavbar();
  }

  syncSearchTerm(): void {
    const searchTerm = this.searchForm?.value.searchTerm;
    this.browserService.navigateToSearch();
    this.browserService.updateSearchTerm(searchTerm);
  }

  resetSearchAndSyncSearchTerm(): void {
    this.browserService.resetSearch();
    this.syncSearchTerm();
  }

  collapseNavbar() {
    this.collapsed = true;
  }


  selectLang(lang: Language) {
    this.currentLang = lang;
    if (lang)
      this.languageService.changeAndSaveLanguage.next(lang);
  }
}
