<div (dropped)="onDrop($event)"
     (hovered)="toggleHover($event)"
     [class.hovering]="isHovering"
     appDropzone
     class="dropzone mb-2">


  <h3 class="paragraph-headline-3" i18n="upload files">Upload {remainingUploads, plural, =0 {files} =1 {file} other {files}}</h3>
  <p
    i18n="drag and drop files">{remainingUploads, plural, =0 {Image limit reached. You cannot upload more files.} =1 {Drag and drop a file.} other {Drag and drop up to {{remainingUploads}} files.}}</p>
  <input (change)="fileChangeEvent($event)" accept=".png, .jpg, .jpeg, .gif, .bpg" color="primary" multiple type="file"/>
</div>

<div *ngIf="limitReached" class="alert alert-danger" i18n="Too many files error">Too many files. You can only
  upload {remainingUploads, plural, =0 {0 files} =1 {one file} other {{{remainingUploads}} files}}.
</div>

<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<div *ngIf="files.length>0">
  <h3 class="paragraph-headline-3" i18n>Selected files</h3>

  <app-info-card i18n="file cropper hints" icon="error_outline">These files have not yet been uploaded!</app-info-card>
  <br>
  <div (cdkDropListDropped)="drop($event)" cdkDropList>
    <mat-card *ngFor="let file of files" [cdkDragStartDelay]="100" cdkDrag class="file-card">
      <div class="row">
        <div class="col w-100">{{file.name}}</div>
        <div class="col text-right float-right file-size mx-0 px-0">
          {{ utilService.humanFileSize(file.size, true)}}
          <button (click)="onDelete(file)" aria-label="Delete file" i18n-aria-label="Delete file" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
  </div>

  <app-mat-icon-button [callback]="uploadAll.bind(this)" [disabled]="!uploadEnabled" [params]="[]"
                       color="primary" i18n-label="button label upload" icon="cloud_upload"
                       label="Upload"></app-mat-icon-button>
</div>
