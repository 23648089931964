import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {ListingService} from '../../../listing/listing.service';
import {SocialService} from '../../../social/social.service';
import {BookService} from '../../../book/book.service';
import {Rating} from '../../../shared/models/rating.interface';
import firebase from 'firebase/app';
import {FormBuilder} from '@angular/forms';
import {Transaction} from '../../../shared/models/transaction.interface';
import {UserPublic} from '../../../shared/models/userPublic.interface';
import {TransactionRole} from '../../../shared/enums/transactionRole.enum';
import {RatingCriterion} from '../../../shared/enums/ratingCriterion.enum';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.css'],
})
export class RateComponent extends BaseComponent implements OnInit {

  @Input() receiverUid?: string;
  @Input() receiverName?: string;
  @Input() receiverRole?: TransactionRole;
  @Input() receiverImgUrlThumb?: string;
  @Input() listingImgUrlThumb?: string;
  @Input() listingName?: string;
  @Input() listingUid?: string;
  @Input() transactionUid?: string;
  @Output() onRatingSent = new EventEmitter<{ rating: Rating, transaction: Transaction }>();
  @Output() onClose = new EventEmitter<boolean>();

  commentMaxLength = 500;
  ratingWasSent = false;
  overall?: number;
  punctuality?: number;
  friendliness?: number;
  itemAsDescribed?: number;
  itemReturnCondition?: number;

  sendingInProgress = false;

  rater?: UserPublic;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private listingService: ListingService,
      private socialService: SocialService,
      private formBuilder: FormBuilder,
      private bookService: BookService) {
    super(store);
  }

  public get transactionRole(): typeof TransactionRole {
    return TransactionRole;
  }

  public get ratingCriterion(): typeof RatingCriterion {
    return RatingCriterion;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.user$.subscribe((user) => {
      if (user)
        this.userService.fetchUserPublic(user.uid).then(wrapper => {
          this.rater = wrapper.data;
          if (wrapper.errorMessage)
            this.addError($localize`We could not load your user info\: ${wrapper.errorMessage}`);
        });
    });
  }

  sendRating(commentRef: HTMLTextAreaElement) {
    this.clearAlerts();
    if (this.sendingInProgress)
      return;


    if (!this.receiverUid || !this.user?.uid || !this.rater) {
      this.addError($localize`Rating receiver or sender is unknown. Rating cannot be sent.`);
      return;
    }

    if (!this.transactionUid) {
      this.addError($localize`Transaction is unknown. Rating cannot be sent.`);
      return;
    }

    if (!this.listingUid || !this.listingName || !this.listingImgUrlThumb) {
      this.addError($localize`Listing data is unknown. Rating cannot be sent.`);
      return;
    }

    if (!this.receiverRole)
      this.addError($localize`The role of the person you're trying to rate is unknown.`);

    if (!this.overall)
      this.addError($localize`Please rate ${this.receiverName}'s overall performance.`);

    if (!this.itemReturnCondition && this.receiverRole === TransactionRole.Borrower)
      this.addError($localize`Please rate, whether ${this.receiverName} returned the item in a good condition.`);

    if (!this.itemAsDescribed && this.receiverRole === TransactionRole.Lender)
      this.addError($localize`Please rate, whether ${this.receiverName}'s item was as described.`);

    const fiveStarRating = (!this.friendliness || this.friendliness === 5) && (!this.punctuality || this.punctuality === 5) && (!this.overall || this.overall === 5) &&
        (!this.itemAsDescribed || this.itemAsDescribed === 5) && (!this.itemReturnCondition || this.itemReturnCondition === 5);

    if (!fiveStarRating && (!commentRef.value || commentRef.value.trim().length === 0))
      this.addError($localize`Please add a comment explaining, why you were not fully satisfied.`);
    const comment = commentRef.value.trim();
    if (this.getErrors().length > 0) {
      return;
    }

    const rating: Rating = {
      receiverUid: this.receiverUid,
      receiverRole: this.receiverRole!,
      date: Timestamp.now(),
      raterUid: this.user?.uid,
      rater: {
        displayName: this.rater.displayName,
        uid: this.rater.uid,
      },
      transactionUid: this.transactionUid,
      embeddedListing: {
        uid: this.listingUid,
        name: this.listingName,
        imgUrlThumb: this.listingImgUrlThumb,
      },
      overall: this.overall!,
    };

    if (this.rater.imgUrlThumb)
      rating.rater.imgUrlThumb = this.rater.imgUrlThumb;

    if (this.itemAsDescribed)
      rating.itemAsDescribed = this.itemAsDescribed;
    if (this.itemReturnCondition)
      rating.itemReturnCondition = this.itemReturnCondition;
    if (this.punctuality)
      rating.punctuality = this.punctuality;
    if (this.friendliness)
      rating.friendliness = this.friendliness;
    if (comment)
      rating.comment = comment;

    this.sendingInProgress = true;
    this.bookService.insertRating(rating, ((ratingUid, transaction) => {
      rating.uid = ratingUid;
      this.onRatingSent.emit({rating, transaction});
      this.ratingWasSent = true;
      this.sendingInProgress = false;
      this.overall = undefined;
      this.itemReturnCondition = undefined;
      this.itemAsDescribed = undefined;
      this.punctuality = undefined;
      this.friendliness = undefined;
      commentRef.value = '';
      setTimeout(() => {
        this.onClose.emit(true);
      }, 5000);
    }), reason => {
      this.sendingInProgress = false;
      this.addError(reason);
    });

  }

  onCancel(): void {
    this.onClose.emit(true);
  }
}
