import {Component, Input, OnInit} from '@angular/core';
import {UserPublic} from '../../../shared/models/userPublic.interface';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {UtilService} from '../../../shared/util.service';
import {BookService} from '../../../book/book.service';
import {Conversation} from '../../../shared/models/conversation.interface';
import {Message} from '../../../shared/models/message.interface';
import {SocialService} from '../../social.service';
import {User} from '../../../shared/models/user.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-conversation-preview',
  templateUrl: './conversation-preview.component.html',
  styleUrls: ['./conversation-preview.component.scss'],
})
export class ConversationPreviewComponent extends BaseComponent implements OnInit {

  @Input() conversation?: Conversation;
  otherUser?: UserPublic;
  otherUserUid?: string;
  myUserUid?: string;

  latestMessage?: Message;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private socialService: SocialService,
      private bookService: BookService,
      public utilService: UtilService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.user$.subscribe(user => {
      this.onUserLoaded(user);
    });

  }


  isRead() {
    if (!this.user)
      return false;
    if (this.user.uid === this.conversation?.user1Uid)
      return this.conversation?.user1Read;
    return this.conversation?.user2Read;
  }

  private onUserLoaded(user?: User) {

    if (!this.conversation || !user)
      return;

    this.myUserUid = user.uid;
    if (user.uid === this.conversation?.user1Uid) {
      this.otherUserUid = this.conversation.user2Uid;
    } else {
      this.otherUserUid = this.conversation.user1Uid;
    }
    if (!this.otherUserUid)
      return;

    this.userService.fetchUserPublic(this.otherUserUid).then(wrapper => {
      this.otherUser = wrapper.data;
    });

    if (this.conversation?.uid)
      this.socialService.fetchMessagesFromConversation(this.conversation.uid, 1).then(wrapper => {
        if (wrapper?.data)
          this.latestMessage = wrapper.data[0];
        if (wrapper.errorMessage)
          console.error(`Error loading the latest message: ${wrapper.errorMessage}`);
      });
  }
}
