import {Component, Input, OnInit} from '@angular/core';
import {Transaction} from '../../models/transaction.interface';
import {BaseComponent} from '../base/base.component';
import {UserService} from '../../services/user.service';
import {AccountService} from '../../../account/account.service';
import {UtilService} from '../../util.service';
import {UserPublic} from '../../models/userPublic.interface';
import {TransactionRole} from '../../enums/transactionRole.enum';
import {MangopayService} from '../../../pay/mangopay.service';
import {PayService} from '../../../pay/pay.service';
import Locale from '../../services/locale';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-transaction-preview',
  templateUrl: './transaction-preview.component.html',
  styleUrls: ['./transaction-preview.component.scss'],
})
export class TransactionPreviewComponent extends BaseComponent implements OnInit {

  @Input() transaction?: Transaction;
  /**
   * If true, the bg-container class will be set. Otherwise, it gets a jumbotron-bg.
   */
  @Input() bgContainer = false;
  otherUser?: UserPublic;
  lending = false;
  borrowing = false;
  locale = Locale.numberFormatLocale();

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public utilService: UtilService,
      public mangopayService: MangopayService,
      public payService: PayService,
      private accountService: AccountService) {
    super(store);
  }

  public get transactionRole(): typeof TransactionRole {
    return TransactionRole;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.user$.subscribe(user => {
      if (this.transaction && user) {
        this.userService.fetchUserPublic(user.uid === this.transaction.borrowerUid ? this.transaction.lenderUid : this.transaction.borrowerUid).then(wrapper => {
          this.otherUser = wrapper.data;
          if (wrapper.errorMessage)
            this.addError(wrapper.errorMessage);
        });
        this.lending = this.transaction.lenderUid === user.uid;
        this.borrowing = this.transaction.borrowerUid === user.uid;
      }
    });

  }


  getNameByRole(role: TransactionRole | undefined) {
    switch (role) {
      case TransactionRole.Borrower:
        if (this.borrowing)
          return this.user?.displayName;
        else
          return this.otherUser?.displayName;
      case TransactionRole.Lender:
        if (this.lending)
          return this.user?.displayName;
        else
          return this.otherUser?.displayName;
    }
    return '';
  }
}
