<!-- Breadcrumb-->
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
        <li class="breadcrumb-item">
            <span i18n="drafts headline">My drafts</span>
        </li>
    </ol>
</nav>

<div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="drafts headline">My drafts</h1>
    <p class="jumbotron-lead" i18n="drafts lead text">View and manage your listing drafts.</p>
</div>


<!-- Show error, if no drafts could be loaded -->
<app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<div class="alert alert-warning" i18n>Unpublished drafts are deleted 3 days after creation.</div>

<app-mat-icon-button [callback]="refresh.bind(this)" [params]="[]" color="primary" cssClass="mb-3"
                     i18n-label="button label refresh" icon="refresh"
                     label="Refresh"></app-mat-icon-button>

<app-mat-icon-button *ngIf="drafts.length > 0" [callback]="onDeleteAll.bind(this)" [params]="[]" color="warn" cssClass="ml-1 mb-3"
                     i18n-label="button label delete all" icon="delete_forever"
                     label="Delete all"></app-mat-icon-button>

<p *ngIf="!showLoadingSpinner && (!drafts || drafts.length === 0)" i18n="no drafts message">You haven't got any drafts.</p>
<p *ngIf="drafts && drafts.length > 0" i18n="drafts count message">You
    have {drafts.length, plural, =0 {no drafts} =1 {1 draft} other {{{drafts.length}} drafts}}.</p>

<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true"></app-loading-spinner>

<mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="mr-1" color="primary">grid_view</mat-icon>
        </ng-template>
        <div class="card-group">
            <div class="row justify-content-start mx-0 px-0">
                <div *ngFor="let listing of drafts" class="card listing-card ml-0 mr-1 mb-1">
                    <a [routerLink]="['/listing','edit',listing.uid]">
                        <app-img-with-blurry-background *ngIf="listing.imgUrls && listing.imgUrls[0] !== undefined"
                                                        alt="Picture of {{listing.name}}" [height]="300"
                                                        default="./assets/images/default/default-listing-w300.webp"
                                                        i18n-alt="Picture alt text in item card"
                                                        src="{{listing.imgUrls[0].thumb}}"></app-img-with-blurry-background>
                        <app-img-with-blurry-background *ngIf="!listing?.imgUrls || (listing.imgUrls && listing.imgUrls[0] === undefined)" alt="continue editing listing icon"
                                                        [height]="300"
                                                        i18n-alt="Picture alt text continue editing listing icon"
                                                        src="./assets/images/buttons/button-edit-transparent-w300.webp"></app-img-with-blurry-background>
                    </a>
                    <div class="card-body">
                        <h2 class="paragraph-headline-2">
                            <a [routerLink]="['/listing','edit',listing.uid]">
                                <span *ngIf="listing?.name">{{listing.name}}</span>
                                <span *ngIf="!listing?.name" i18n="unnamed listing">Unnamed listing</span>
                            </a>
                        </h2>
                        <p *ngIf="listing.summary" [innerHTML]="listing.summary"></p>
                        <p *ngIf="listing.address" class="small">{{listing.address.zipCode}} {{listing.address.city}}, {{listing.address.district}}</p>
                        <span *ngIf="listing.creationDate" [title]="listing.creationDate.toDate() | moment:'LLLL'" class="small"
                              i18n="creation date">Created {{utilService.createFromNowString(listing.creationDate.toDate())}}.</span>
                        <span *ngIf="listing.lastEditDate" [title]="utilService.getDate(listing.lastEditDate) | moment:'LLLL'" class="small" i18n="last edit date">
              Last edit {{utilService.createFromNowString(utilService.getDate(listing.lastEditDate))}}.</span>

                    </div>
                    <div class="card-footer align-middle center-all">
                        <app-mat-icon-button [routerLink]="['/listing','edit',listing.uid]"
                                             color="primary" i18n-label="button continue editing" icon="edit"
                                             label="Continue"></app-mat-icon-button>
                        <app-mat-icon-button [callback]="onDelete.bind(this)" [params]="[listing]"
                                             color="warn" cssClass="ml-1" i18n-label="button delete" icon="delete"
                                             label="Delete"></app-mat-icon-button>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="mr-1" color="primary">table_rows</mat-icon>
        </ng-template>

        <table (matSortChange)="sortData($event)" *ngIf="dataSource" [dataSource]="dataSource" [matSortActive]="matSortActive"
               [matSortDirection]="matSortDirection" class="mat-elevation-z8" mat-table
               matSort>

            <!-- Picture Column -->
            <ng-container matColumnDef="picture">
                <th *matHeaderCellDef i18n="picture heading" mat-header-cell mat-sort-header>Picture</th>
                <td *matCellDef="let listing" mat-cell>
                    <a [routerLink]="['/listing','edit',listing.uid]">
                        <div class="d-block d-sm-none">
                            <app-img-with-blurry-background *ngIf="listing.imgUrls && listing.imgUrls[0] !== undefined"
                                                            alt="Picture of {{listing.name}}"
                                                            default="./assets/images/default/default-listing-w300.webp"
                                                            i18n-alt="Picture alt text in item card"
                                                            [height]="150" [width]="75"
                                                            src="{{listing.imgUrls[0].thumb}}">
                            </app-img-with-blurry-background>
                            <app-img-with-blurry-background *ngIf="!listing?.imgUrls || (listing.imgUrls && listing.imgUrls[0] === undefined)"
                                                            alt="continue editing listing icon"
                                                            i18n-alt="Picture alt text continue editing listing icon"
                                                            default="./assets/images/default/default-listing-w300.webp"
                                                            [height]="150" [width]="75"
                                                            src="./assets/images/buttons/button-edit-transparent-w300.webp">
                            </app-img-with-blurry-background>
                        </div>
                        <div class="d-sm-block d-none">
                            <app-img-with-blurry-background *ngIf="listing.imgUrls && listing.imgUrls[0] !== undefined"
                                                            alt="Picture of {{listing.name}}"
                                                            default="./assets/images/default/default-listing-w300.webp"
                                                            i18n-alt="Picture alt text in item card"
                                                            [height]="150" [width]="150"
                                                            src="{{listing.imgUrls[0].thumb}}">
                            </app-img-with-blurry-background>
                            <app-img-with-blurry-background *ngIf="!listing?.imgUrls || (listing.imgUrls && listing.imgUrls[0] === undefined)"
                                                            alt="continue editing listing icon"
                                                            i18n-alt="Picture alt text continue editing listing icon"
                                                            default="./assets/images/default/default-listing-w300.webp"
                                                            [height]="150" [width]="150"
                                                            src="./assets/images/buttons/button-edit-transparent-w300.webp">
                            </app-img-with-blurry-background>
                        </div>
                    </a>
                </td>
            </ng-container>

            <!-- Name / Summary Column -->
            <ng-container matColumnDef="name">
                <th *matHeaderCellDef i18n="name and summary heading" mat-header-cell mat-sort-header>Name and summary</th>
                <td *matCellDef="let listing" mat-cell>
                    <a [routerLink]="['/listing','edit',listing.uid]">
                        <h3 *ngIf="listing?.name" class="paragraph-headline-3">{{listing.name}}</h3>
                        <h3 *ngIf="!listing?.name" class="paragraph-headline-3" i18n="unnamed listing"> Unnamed listing</h3>
                        <span [innerHTML]="listing.summary"></span>
                    </a>
                </td>
            </ng-container>

            <!-- Address Column -->
            <ng-container matColumnDef="address">
                <th *matHeaderCellDef i18n="address heading" mat-header-cell mat-sort-header>Address</th>
                <td *matCellDef="let listing" mat-cell>
                    <span *ngIf="listing?.address?.formattedAddress">{{listing.address.formattedAddress}}</span>
                </td>
            </ng-container>

            <!-- Creation Date Column -->
            <ng-container matColumnDef="creationDate">
                <th *matHeaderCellDef i18n="creationDate heading" mat-header-cell mat-sort-header>Creation Date</th>
                <td *matCellDef="let listing" mat-cell>
                    <p *ngIf="listing.creationDate" [title]="utilService.getDate(listing.creationDate) | moment:'LLLL'">
                        {{utilService.createFromNowString(utilService.getDate(listing.creationDate))}}</p>
                    <p *ngIf="listing.lastEditDate" [title]="utilService.getDate(listing.lastEditDate) | moment:'LLLL'" class="small" i18n="last edit date">
                        Last edit {{utilService.createFromNowString(utilService.getDate(listing.lastEditDate))}}.</p>
                </td>
            </ng-container>


            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <th *matHeaderCellDef i18n="actions heading" mat-header-cell>Actions</th>
                <td *matCellDef="let listing" mat-cell>
                    <app-mat-icon-button [routerLink]="['/listing','edit',listing.uid]"
                                         color="primary" icon="edit"></app-mat-icon-button>
                    <app-mat-icon-button [callback]="onDelete.bind(this)" [params]="[listing]"
                                         color="warn" cssClass="ml-1" icon="delete"></app-mat-icon-button>
                </td>
            </ng-container>


            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>

    </mat-tab>

</mat-tab-group>
