import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminComponent} from './admin.component';
import {AdminOverviewComponent} from './admin-overview/admin-overview.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {AdminRoutingModule} from './admin-routing.module';
import {CategoriesEditorComponent} from './categories-editor/categories-editor.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ToolsComponent} from './tools/tools.component';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {CategoriesEditorFormComponent} from './categories-editor/categories-editor-form/categories-editor-form.component';
import {CategoriesEditorListsComponent} from './categories-editor/categories-editor-lists/categories-editor-lists.component';
import {AnnouncementsEditorListComponent} from './announcements-editor/announcements-editor-list/announcements-editor-list.component';
import {AnnouncementsEditorFormComponent} from './announcements-editor/announcements-editor-form/announcements-editor-form.component';
import {AnnouncementsEditorComponent} from './announcements-editor/announcements-editor.component';
import {FaqsEditorComponent} from './faqs-editor/faqs-editor.component';
import {FaqsEditorFormComponent} from './faqs-editor/faqs-editor-form/faqs-editor-form.component';
import {LayoutModule} from '../layout/layout.module';
import {NoFaqShownComponentComponent} from './faqs-editor/no-faq-shown-component/no-faq-shown-component.component';
import {MatSliderModule} from '@angular/material/slider';
import {ReportsComponent} from './reports/reports.component';
import {ReportViewComponent} from './reports/report-view/report-view.component';
import {BrowserModule} from '../browser/browser.module';
import {ConfirmSendMessageDialogComponent} from './reports/confirm-send-message-dialog/confirm-send-message-dialog.component';
import {ReportsViewComponent} from './reports/reports-view/reports-view.component';
import {ReportListingPreviewComponent} from './reports/reports-view/report-listing-preview/report-listing-preview.component';
import {ReportRatingPreviewComponent} from './reports/reports-view/report-rating-preview/report-rating-preview.component';
import {ReportPreviewComponent} from './reports/reports-view/report-preview/report-preview.component';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxEditorModule} from 'ngx-editor';
import {CurrenciesEditorComponent} from './currencies-editor/currencies-editor.component';
import {NoCurrencyShownComponent} from './currencies-editor/no-currency-shown/no-currency-shown.component';
import {CurrenciesEditorFormComponent} from './currencies-editor/currencies-editor-form/currencies-editor-form.component';
import {CurrenciesComponent} from './currencies-editor/currencies/currencies.component';
import {MatExpansionModule} from '@angular/material/expansion';


@NgModule({
  declarations: [
    AdminComponent,
    AdminOverviewComponent,
    CategoriesEditorComponent,
    CategoriesEditorFormComponent,
    CategoriesEditorListsComponent,
    ToolsComponent,
    AnnouncementsEditorComponent,
    AnnouncementsEditorListComponent,
    AnnouncementsEditorFormComponent,
    FaqsEditorComponent,
    FaqsEditorFormComponent,
    NoFaqShownComponentComponent,
    ReportsComponent,
    ReportsViewComponent,
    ReportViewComponent,
    ReportPreviewComponent,
    ReportListingPreviewComponent,
    ReportRatingPreviewComponent,
    ConfirmSendMessageDialogComponent,
    CurrenciesEditorComponent,
    NoCurrencyShownComponent,
    CurrenciesEditorFormComponent,
    CurrenciesComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    RouterModule,
    AdminRoutingModule,
    DragDropModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    LayoutModule,
    MatSliderModule,
    BrowserModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    NgxEditorModule,
    MatExpansionModule,
  ],
})
export class AdminModule {
}
