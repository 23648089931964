import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {interval} from 'rxjs';

const ONE_HOUR_IN_MILLIS = 3600000;

@Injectable({
    providedIn: 'root',
})
export class UpdateService {

    constructor(public swUpdate: SwUpdate) {
        if (swUpdate.isEnabled) {
            interval(ONE_HOUR_IN_MILLIS).subscribe(() => swUpdate.checkForUpdate()
                .then(() => console.log('Checking for site updates')));
        }
    }

    public checkForUpdates(): void {
        this.swUpdate.available.subscribe(event => this.reloadPage());
    }

    private reloadPage(): void {
        console.log('Updating to new version');
        this.swUpdate.activateUpdate().then(() => document.location.reload());
    }
}
