import {Component, Input, OnInit} from '@angular/core';
import {Category} from '../../../../shared/models/category.interface';
import {CurrencyService} from '../../../../shared/services/currency.service';
import {CategoryService} from '../../../../shared/services/category.service';

@Component({
  selector: 'app-category-list-item',
  templateUrl: './category-list-item.component.html',
  styleUrls: ['./category-list-item.component.css'],
})
export class CategoryListItemComponent implements OnInit {

  @Input() category: Category | undefined;
  @Input() skipParent = false;

  constructor(
    public currencyService: CurrencyService,
    public categoryService: CategoryService) {
  }

  ngOnInit(): void {
  }

}
