import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {Announcement} from '../../shared/models/announcement.interface';
import {
  addAnnouncementsToCache,
  addAnnouncementToCache,
  addFaqsToCache,
  addFaqToCache,
  clearAnnouncementsCache,
  clearFaqsCache,
  setAnnouncementsLastFetch,
  setFaqsLastFetch,
  setRefName,
} from './layout.actions';
import {Faq} from '../../shared/models/faq.interface';


export interface LayoutState {
  announcements: Announcement[];
  announcementsLastFetch?: Date;
  faqs: Faq[];
  faqsLastFetch?: Date;
  referral?: StoreReferral;

}

export interface StoreReferral {
  name: string;
  date: Date;
}

export const initialState: LayoutState = {
  announcements: [],
  faqs: [],
};

function logDebugMessages(actionName: string, state: LayoutState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

const reducer: ActionReducer<LayoutState> = createReducer(
    initialState,

    on(addAnnouncementToCache, (state, {announcement}) => {
      const newAnnouncements: Announcement[] = [];
      const nowUtc = new Date().getTime();
      for (const announcementFromState of state.announcements) {
        // Skip the announcement with the ID of the given announcement, because we will add the given announcement object later
        if (announcement.uid === announcementFromState.uid)
          continue;
        // Also skip, if the cached announcement is too old (or has no cache date)
        if (!announcementFromState.cacheDate || (nowUtc - announcementFromState.cacheDate.getTime() > environment.defaultAnnouncementCacheAgeInSec * 1000))
          continue;
        const announcementCopy: Announcement = {...announcementFromState};
        newAnnouncements.push(announcementCopy);
      }
      newAnnouncements.push(announcement);
      const newState = {...state, announcements: newAnnouncements};
      logDebugMessages('addAnnouncementToCache', state, newState);
      return newState;
    }),
    on(addAnnouncementsToCache, (state, {announcements}) => {
      const announcementsLastFetch = new Date();
      const newState = {...state, announcements, announcementsLastFetch};
      logDebugMessages('addAnnouncementsToCache', state, newState);
      return newState;
    }),
    on(setAnnouncementsLastFetch, (state, {announcementsLastFetch}) => {
      const newState = {...state, announcementsLastFetch};
      logDebugMessages('setAnnouncementsLastFetch', state, newState);
      return newState;
    }),
    on(clearAnnouncementsCache, (state) => {
      const newState = {...state, announcements: [], announcementsLastFetch: undefined};
      logDebugMessages('clearAnnouncementsCache', state, newState);
      return newState;
    }),


    on(addFaqToCache, (state, {faq}) => {
      const newFaqs: Faq[] = [];
      const nowUtc = new Date().getTime();
      for (const faqFromState of state.faqs) {
        // Skip the faq with the ID of the given faq, because we will add the given faq object later
        if (faq.uid === faqFromState.uid)
          continue;
        // Also skip, if the cached faq is too old (or has no cache date)
        if (!faqFromState.cacheDate || (nowUtc - faqFromState.cacheDate.getTime() > environment.defaultFaqCacheAgeInSec * 1000))
          continue;
        const faqCopy: Faq = {...faqFromState};
        newFaqs.push(faqCopy);
      }
      newFaqs.push(faq);
      const newState = {...state, faqs: newFaqs};
      logDebugMessages('addFaqToCache', state, newState);
      return newState;
    }),
    on(addFaqsToCache, (state, {faqs}) => {
      const faqsLastFetch = new Date();
      const newState = {...state, faqs, faqsLastFetch};
      logDebugMessages('addFaqsToCache', state, newState);
      return newState;
    }),
    on(setFaqsLastFetch, (state, {faqsLastFetch}) => {
      const newState = {...state, faqsLastFetch};
      logDebugMessages('setFaqsLastFetch', state, newState);
      return newState;
    }),
    on(clearFaqsCache, (state) => {
      const newState = {...state, faqs: [], faqsLastFetch: undefined};
      logDebugMessages('clearFaqsCache', state, newState);
      return newState;
    }),


    on(setRefName, (state, {refName}) => {
      const newState = {...state, referral: {name: refName, date: new Date()}};
      logDebugMessages('setRefName', state, newState);
      return newState;
    }),
);

export function layoutReducer(state: LayoutState | undefined, action: Action): LayoutState {
  return reducer(state, action);
}

