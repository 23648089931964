<div (dropped)="onDrop($event)"
     (hovered)="toggleHover($event)"
     [class.hovering]="isHovering"
     appDropzone
     class="dropzone mb-2">


  <h3 class="paragraph-headline-3" i18n="upload pictures">Upload {remainingUploads, plural, =0 {images} =1 {image} other {images}}</h3>
  <p
    i18n="drag and drop pictures">{remainingUploads, plural, =0 {Image limit reached. You cannot upload more images.} =1 {Drag and drop an image file.} other {Drag and drop up to {{remainingUploads}} image files.}}</p>
  <input (change)="fileChangeEvent($event)" accept=".png, .jpg, .jpeg, .gif, .bpg" color="primary" multiple type="file"/>
</div>

<ul *ngIf="errors.length > 0 ||limitReached">
  <div *ngIf="limitReached" class="alert alert-danger" i18n="Too many images error">Too many images. You can
    upload {remainingUploads, plural, =0 {no more images} =1 {one more image} other {{{remainingUploads}} more images}}.
  </div>
  <div *ngFor="let error of errors" class="alert alert-danger">{{error}}</div>
</ul>

<div *ngIf="images.length>0">
  <h3 class="paragraph-headline-3" i18n>Selected images</h3>

  <app-info-card i18n="image cropper hints">
    You can rotate and flip each image using the tool buttons below. To crop an image, drag and drop the rectangle around it. Click the preview button,
    to see a large preview of the rotated, flipped and cropped image.
  </app-info-card>
  <br>
  <div class="card-group">
    <div class="row justify-content-start mx-0 px-0">
      <div *ngFor="let img of images" class="card ml-0 mr-1 mb-1 cdk-box">
        <app-cropper (croppedImageEmitter)="onNewCroppedImage($event, img)" (delete)="deleteImg($event)" [img]="img"></app-cropper>
      </div>
    </div>
  </div>
  <br>
  <app-info-card *ngIf="images.length > 1" i18n="images not yet uploaded" icon="error_outline" level="danger">These images have not yet been uploaded!
  </app-info-card>
  <app-info-card *ngIf="images.length === 1" i18n="image not yet uploaded" icon="error_outline" level="danger">This image has not yet been uploaded!
  </app-info-card>
  <br>
  <app-mat-icon-button [callback]="uploadAll.bind(this)" [label]="getUploadButtonLabel()"
                       [params]="[]" color="primary" i18n-label="button label upload all images"
                       icon="cloud_upload"></app-mat-icon-button>

</div>

<div *ngFor="let file of images" class="pt-2">
  <app-upload-task (onUploadFinished)="onUploadFinished($event)" [base64Img]="base64Images.get(file)" [file]="file"
                   [filename]="filename" [uploadPath]="uploadPath"></app-upload-task>
</div>
