<agm-map (mapReady)="mapReadyHandler($event)"
         *ngIf="showMap"
         [latitude]="lat" [longitude]="lng" [zoom]="zoom">
  <agm-map-type-control>
    <button mat-raised-button>Satellite</button>
    <button mat-raised-button>RoadMap</button>
  </agm-map-type-control>
  <agm-polygon *ngIf="polygon" [draggable]="false" [editable]="false" [paths]="polygon" [polyDraggable]="false"
               [strokeWeight]="1" fillColor="blue">
  </agm-polygon>
  <agm-marker (dragEnd)="onMarkerDragEnd($event)" *ngIf="showMarker" [latitude]="lat"
              [longitude]="lng" [markerDraggable]="markerDraggable">
    <agm-info-window *ngIf="address">{{address}}<br>
      <small i18n="Latitude">Latitude: {{lat}}</small><br>
      <small i18n="Longitude">Longitude: {{lng}}</small></agm-info-window>
  </agm-marker>
</agm-map>

