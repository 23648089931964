<div class="container bg-container px-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
      <li class="breadcrumb-item">
        <span i18n="borrowing headline">Borrowing</span>
      </li>
    </ol>
  </nav>


  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="borrowing headline">Borrowing</h1>
    <p class="jumbotron-lead" i18n="borrowing lead text">See, what you are borrowing from others, including past and future transactions.</p>
    <p><a [routerLink]="['/account','lending']" i18n="go to lending">Go to lending</a></p>
  </div>

  <!-- Show errors -->
  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">login</mat-icon>
        <span i18n="open transactions">Open transactions</span>
      </ng-template>

      <app-transaction-list [borrowing]="true" [infiniteScrollPercentage]="90" [open]="true"></app-transaction-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">history</mat-icon>
        <span i18n="completed transactions">Completed transactions</span>
      </ng-template>

      <app-transaction-list [borrowing]="true" [completed]="true" [infiniteScrollPercentage]="90"></app-transaction-list>
    </mat-tab>
  </mat-tab-group>
</div>
