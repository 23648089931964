import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {CategoryService} from '../../../shared/services/category.service';
import {Category} from '../../../shared/models/category.interface';
import Util from '../../util';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-categories-carousel',
  templateUrl: './categories-carousel.component.html',
  styleUrls: ['./categories-carousel.component.scss'],
})
export class CategoriesCarouselComponent extends BaseComponent implements OnInit {

  /**
   * Filtered categories - only categories with image.
   */
  categories: Category[] = [];
  allCategories: Category[] = [];
  activeIndex: number = 0;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public categoryService: CategoryService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.categoryService.getCategoriesById().then(wrapper => {
          if (wrapper.errorMessage)
            console.log('Error loading categories: ' + wrapper.errorMessage);

          if (!wrapper.data || wrapper.data.size === 0) {
            console.log(`No categories found.`);
            return;
          }
          this.allCategories = [...wrapper.data.values()];
          this.allCategories.forEach(category => {
            if (category.imgUrlThumb)
              this.categories.push(category);
          });
          Util.shuffle(this.categories);
        },
        reason => {
          console.log(`Error loading categories: ${reason}`);
        });
  }

  getCategoryId(category: Category) {
    return category.id;
  }


  isImageActive(i: number) {
    return Util.isSlideActive(i, this.activeIndex, this.categories.length);
  }
}
