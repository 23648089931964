import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
})
export class AlertComponent implements OnInit, AfterViewInit {
  @Input() message = '';
  @Output() close = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    document.onclick = (args: any): void => {
      this.close.emit();
    };
  }

  onClose(): void {
    this.close.emit();

  }
}
