import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {Faq, FaqLangStrings, FaqStringsMap} from '../../../shared/models/faq.interface';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import firebase from 'firebase/app';
import {LayoutService} from '../../../layout/layout.service';
import {ActivatedRoute, Router} from '@angular/router';
import {mergeMap, take, takeUntil} from 'rxjs/operators';
import {TranslateService} from '../../../shared/services/translate.service';
import {MatSliderChange} from '@angular/material/slider';
import {CANNOT_BE_UNDONE} from '../../../shared/constants/strings';
import {UtilService} from '../../../shared/util.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-faqs-editor-form',
  templateUrl: './faqs-editor-form.component.html',
  styleUrls: ['./faqs-editor-form.component.scss'],
})
export class FaqsEditorFormComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  uidMaxLength = 100;
  questionMaxLength = 1000;
  answerMaxLength = 10000;
  matIconMaxLength = 10000;
  priorityMaxLength = 4;
  langMaxLength = 2;
  form: FormGroup = this.createForm();
  uid?: string;
  new = false;
  selectedTabIndex = 0;
  languages = ['en', 'de', 'fr', 'it', 'es', 'ru'];
  private readonly spinnerKeyDeleteFaq = 'deleteFaq';
  private readonly spinnerKeyInsertFaq = 'insertFaq';
  private readonly spinnerKeyUpdateFaq = 'updateFaq';

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private formBuilder: FormBuilder,
      private cdRef: ChangeDetectorRef,
      private translateService: TranslateService,
      private activatedRoute: ActivatedRoute,
      public utilService: UtilService,
      public layoutService: LayoutService,
      private router: Router) {
    super(store);
  }

  _faq?: Faq;

  get faq(): Faq | undefined {
    return this._faq;
  }

  @Input() set faq(faq: Faq | undefined) {
    this._faq = faq ? {
      ...faq,
      cacheDate: faq?.cacheDate,
      creationDate: faq?.creationDate ? faq.creationDate : Timestamp.now(),
      lastEditDate: faq?.lastEditDate,
      matIcon: faq.matIcon,
      priority: faq.priority,
      strings: faq.strings,
      uid: faq?.uid,
    } : undefined;
    this.reloadForm();

  }

  ngOnInit(): void {
    super.ngOnInit();

    // Retrieve uid from route params
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(
        (params) => {
          this.clearAlerts();

          this.uid = params.uid;
          if (this.uid)
              // Fetch FAQ
            this.layoutService.fetchFaq(this.uid, 0).then(wrapper => {
              if (wrapper.data)
                this.faq = wrapper.data;
              if (wrapper.errorMessage)
                this.addError(wrapper.errorMessage);
            });
          this.new = this.uid === undefined;
          this.layoutService.onFaqSelected$.next(this.uid);
        });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  save(): void {
    const now = Timestamp.now();
    const originalUid = this.faq?.uid;

    this.clearAlerts();

    if (this.form.value.uid === 'new') {
      this.addError($localize`The UID <i>new</i> is reserved. Please use another UID.`);
      return;
    }
    if (this.getStringsFormArray().length === 0) {
      this.addError($localize`Please add English strings.`);
      return;
    }

    const faq: Faq = {
      lastEditDate: now,
      matIcon: this.form.value.matIcon,
      uid: this.form.value.uid,
      creationDate: this.faq?.creationDate ? this.faq.creationDate : now,
      priority: this.form?.value.priority,
      // strings cannot be undefined, so we initiate it with empty strings
      strings: {en: {question: '', answer: ''}},
    };

    for (let i = 0; i < this.getStringsFormArray().length; i++) {
      const strings = this.getStringsFormArray().at(i);
      const question = strings.value.question;
      const answer = strings.value.answer;

      // If the form is empty, skip this language
      if (!question && !answer)
        continue;

      const faqLangStrings: FaqLangStrings = {question, answer};
      faq.strings[strings.value.lang] = faqLangStrings;
    }

    if (faq.strings.en.question === '' || faq.strings.en.answer === '') {
      this.addError($localize`The English question and answer are incomplete.`);
      return;
    }

    // Differentiate between (new FAQ), (updated FAQ with same UID) and (updated FAQ with changed UID)
    if (!originalUid || originalUid === faq.uid)
      return this.updateFaq(faq);
    // UID was changed
    return this.updateAndRenameFaq(originalUid, faq);

  }

  getStringsFormArray(): FormArray {
    return this.form?.get('strings') as FormArray;
  }

  deleteStrings(index: number): void {
    this.getStringsFormArray().removeAt(index);
    this.form.markAsTouched();
  }

  translateStrings(target: string, index: number): void {
    if (!target || target === 'en')
      return;
    const enQuestion = this.getEnQuestion();
    if (enQuestion)
      this.translateService.translate(enQuestion, target).pipe(take(1)).subscribe(result => {
        this.getStringsFormArray().at(index).patchValue({question: result});
      });
    const enAnswer = this.getEnAnswer();
    if (enAnswer)
      this.translateService.translate(enAnswer, target).pipe(take(1)).subscribe(result => {
        this.getStringsFormArray().at(index).patchValue({answer: result});
      });
  }

  addMissingTranslations(): void {
    // Go through all languages
    this.languages.forEach(lang => {
      // If the current language is 'en', skip it
      if (lang === 'en')
        return;
      ;
      // Check, if the language is missing
      if (!this.shouldDisplayLanguage(lang))
        return;
      // This language is in fact missing
      const question = this.getEnQuestion();
      const answer = this.getEnAnswer();

      if (lang && question && answer)
        this.addTranslation(lang, question, answer);
    });
  }

  addStrings(lang?: string, question?: string, answer?: string, goToTab = true): void {
    this.getStringsFormArray().push(
        this.createStringsFormGroup(lang, question, answer),
    );
    this.form.markAsTouched();
    // this.writeCategory();
    if (goToTab)
      this.selectedTabIndex = this.getStringsFormArray().length - 1;
  }

  /**
   * Returns true, if the given language add button should be displayed. False otherwise.
   * @param lang language code
   * @return true, if the given language add button should be displayed. False otherwise.
   */
  shouldDisplayLanguage(lang: string) {
    for (let i = 0; i < this.getStringsFormArray().length; i++) {
      const strings = this.getStringsFormArray().at(i);
      if (strings.value.lang === lang)
        return false;
    }
    return true;
  }

  formatPrioritySliderLabel(value: number) {
    return value;
  }

  onPrioritySliderChange(matSliderChange?: MatSliderChange) {
    if (matSliderChange?.value)
      this.form.patchValue({priority: matSliderChange.value});
  }

  onDelete(faq: Faq) {
    this.clearAlerts();
    if (!faq?.uid) {
      this.addError($localize`This FAQ has not yet been saved. It cannot be deleted.`);
      return;
    }
    const title = faq?.uid ? $localize`Are you sure you want to delete the FAQ '${faq.uid}'?` :
        $localize`Are you sure you want to delete this unnamed FAQ?`;
    this.utilService.showConfirmDialog(title, CANNOT_BE_UNDONE, this.deleteFaq.bind(this), [faq.uid!, true], undefined, undefined, undefined, 'no');
  }

  getCreateOrSaveLabel() {
    if (this.new)
      return $localize`Create`;
    return $localize`Save`;
  }

  private getLangStrings(lang: string): FaqLangStrings | undefined {
    for (let i = 0; i < this.getStringsFormArray().length; i++) {
      const strings = this.getStringsFormArray().at(i);
      if (strings.value.lang === lang)
        return strings.value;
    }
    return undefined;
  }

  private getEnAnswer(): string | undefined {
    return this.getLangStrings('en')?.answer;
  }

  private getEnQuestion(): string | undefined {
    return this.getLangStrings('en')?.question;
  }

  private createForm(): FormGroup {

    return this.formBuilder.group({
      uid: ['', [Validators.required, Validators.maxLength(this.uidMaxLength)]],
      matIcon: ['help_outline', [Validators.required, Validators.maxLength(this.matIconMaxLength)]],
      priority: [500],
      strings: this.formBuilder.array([this.createStringsFormGroup('en', '', '')],
      ),
    });
  }

  private createStringsFormGroup(lang?: string, question?: string, answer?: string, description?: string): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
          // If lang is 'en', the lang field should be disabled.
          lang: [lang, [Validators.maxLength(this.langMaxLength), Validators.required]],
          question: [question, [Validators.maxLength(this.questionMaxLength), Validators.required]],
          answer: [answer, [Validators.maxLength(this.answerMaxLength), Validators.required]],
        },
    );
  }

  private reloadForm() {

    this.form.reset();
    this.getStringsFormArray().clear();
    if (this.faq) {
      this.form.patchValue({uid: this.faq.uid, matIcon: this.faq.matIcon, priority: this.faq.priority});
      Object.entries(this.faq.strings).forEach(entry => {
        const langStrings = entry[1];
        this.addStrings(entry[0], langStrings.question, langStrings.answer, false);
      });
    }
  }

  private insertFaq(faq: Faq) {
    this.addLoadingSpinnerMessage(this.spinnerKeyInsertFaq, $localize`Inserting FAQ...`);
    this.layoutService.insertFaq(faq, savedFaq => {
          this.faq = faq;
          this.layoutService.onFaqSaved$.next(faq);
          this.removeLoadingSpinnerMessage(this.spinnerKeyInsertFaq);
        },
        error => {
          this.addError($localize`Error inserting the FAQ\: ${error}`);
          this.removeLoadingSpinnerMessage(this.spinnerKeyInsertFaq);
        });
  }

  private updateFaq(faq: Faq) {
    this.addLoadingSpinnerMessage(this.spinnerKeyUpdateFaq, $localize`Updating FAQ...`);
    this.layoutService.updateFaq(faq.uid!, faq, faq, false, () => {
          this.layoutService.onFaqSaved$.next(faq);
          this.removeLoadingSpinnerMessage(this.spinnerKeyUpdateFaq);
          this.router.navigate(['admin', 'faqs', faq.uid]);
          this.addSuccess($localize`Successfully saved FAQ <i>${faq.uid}</i>.`);
          this.layoutService.onFaqSelected$.next(faq.uid);

        },
        error => {
          this.addError($localize`Error updating the FAQ\: ${error}`);
          this.removeLoadingSpinnerMessage(this.spinnerKeyUpdateFaq);
        });
  }

  private updateAndRenameFaq(originalUid: string, faq: Faq) {
    this.deleteFaq(originalUid, false);
    this.updateFaq(faq);
  }

  private deleteFaq(uid: string, forward = true) {
    this.addLoadingSpinnerMessage(this.spinnerKeyDeleteFaq, $localize`Deleting FAQ ${uid}...`);
    this.layoutService.deleteFaq(uid).then(() => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyDeleteFaq);
      this.layoutService.onFaqDeleted$.next(uid);
      this.addSuccess($localize`Successfully deleted FAQ <i>${uid}</i>.`);
      if (forward)
        this.router.navigate(['admin', 'faqs']);
    });
  }

  private addTranslation(lang: string, enQuestion: string, enAnswer: string) {
    if (!this.faq)
      return;

    const questionTranslation$ = this.translateService.translate(enQuestion, lang).pipe(take(1));
    const answerTranslation$ = this.translateService.translate(enAnswer, lang).pipe(take(1));

    questionTranslation$.pipe(
        mergeMap(() => answerTranslation$, (questionResult: string, answerResult: string) => {
          return {questionResult, answerResult};
        }),
        takeUntil(this.destroy$))
        .subscribe((result) => {
              console.log(result);
              if (this.faq) {
                const faqLangStrings: FaqLangStrings = {
                  question: result.questionResult,
                  answer: result.answerResult,
                };
                // Make the faq.strings extensible
                this.faq.strings = this.getExtensibleStrings(this.faq.strings);
                this.faq.strings[lang] = faqLangStrings;
                this.reloadForm();
              }
            },
        );
  }

  private getExtensibleStrings(strings: FaqStringsMap) {
    const extensibleStrings: FaqStringsMap = {en: {question: '', answer: ''}};
    Object.entries(strings).forEach(entry => {
      extensibleStrings[entry[0]] = entry[1];
    });
    return extensibleStrings;
  }
}
