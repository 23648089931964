import {createSelector} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';

export const selectBrowser = (state: AppState) => state.browser;

export const selectSearchListings = createSelector(
    selectBrowser,
    (browserState) => browserState.searchListings,
);

export const selectSearch = createSelector(
    selectSearchListings,
    searchListings => searchListings.search,
);

export const selectSortIndex = createSelector(
    selectSearch,
    search => search.sortIndex,
);

export const selectFetchListing = createSelector(
    selectBrowser,
    (browserState) => browserState.fetchListing,
);
export const selectListingsByIdCache = createSelector(
    selectBrowser,
    (browserState) => browserState.listingsByIdCache,
);

