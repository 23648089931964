<ngx-auth-firebaseui-avatar (onSignOut)="logout()" *ngIf="firebaseUser" [canDeleteAccount]="false" [canEditAccount]="false" [canViewAccount]="false"
                            [links]="this.avatarLinks" i18n-textProfile="logout button in ngx-auth dropdown" layout="simple"
                            i18n-textSignOut="profile button in ngx-auth dropdown"
                            textProfile="Profile" textSignOut="Log out"></ngx-auth-firebaseui-avatar>
<button #loginButton (click)="showDialog()" *ngIf="!firebaseUser"
        class="btn btn-accent btn-outline-primary my-2" id="dropdownButtonLoginForm2">
  <span i18n="login dropdown text for login|The text written on the login button in the header, when no user is logged in">Log in</span>
</button>

<ng-template appPlaceholder></ng-template>
