import {createAction, props} from '@ngrx/store';
import {Currency} from '../models/currency.interface';
import {Category} from '../models/category.interface';
import {UserPublic} from '../models/userPublic.interface';


export const clearCategoriesCache = createAction('[Category] clearing category cache');
export const addCategoryToCache = createAction('[Category] adding category to cache', props<{ category: Category }>());
export const addCurrenciesToCache = createAction('[Currency] adding currencies to cache', props<{ currencies: Currency[] }>());
export const setCategories = createAction('[Shared] Setting categories', props<{ categories: Category[], categoriesFetchDate: Date }>());
export const clearCurrenciesCache = createAction('[Currency] clearing currency cache');
export const addCurrencyToCache = createAction('[Currency] adding currency to cache', props<{ currency: Currency }>());
export const setCurrencies = createAction('[Shared] Setting currencies', props<{ currencies: Currency[], currenciesFetchDate: Date }>());
export const addUserPublicToCache = createAction('[Shared] Adding userPublic to cache', props<{ userPublic: UserPublic }>());






