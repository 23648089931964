import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {UserService} from '../../services/user.service';
import {environment} from '../../../../environments/environment';
import {Transaction} from '../../models/transaction.interface';
import firebase from 'firebase/app';
import {BookService} from '../../../book/book.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Component({
  selector: 'app-transactions-for-listing',
  templateUrl: './transactions-for-listing.component.html',
  styleUrls: ['./transactions-for-listing.component.css'],
})
export class TransactionsForListingComponent extends BaseComponent implements OnInit {

  @Input() listingUid?: string;
  @Input() borrowing: boolean = false;
  @Input() lending: boolean = false;
  /**
   * Flag, that determines, if only open transactions should be shown. By default, transactions are not filtered by state.
   */
  @Input() open?: boolean;
  /**
   * Flag, that determines, if only completed transactions should be shown. By default, transactions are not filtered by state.
   */
  @Input() completed?: boolean;
  /**
   * Flag, that determines, if only transactiosn with the state itemReturned should be shown. By default, transactions are not filtered by state.
   */
  @Input() onlyReturned?: boolean;
  /**
   * Count, how many transactions should be loaded at once
   */
  @Input() count?: number;

  @Output() transactionsLoaded = new EventEmitter<Transaction[]>();


  transactionsInitialized = false;
  showLoadingSpinnerTransactions = true;
  thereAreMoreTransactions = false;
  transactions: Transaction[] = [];
  lastVisibleTransaction?: DocumentSnapshot<Transaction>;
  searchingTransactions = false;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private bookService: BookService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.listingUid)
      return;

    if (this.open && this.completed)
      this.addError('Open and completed are both set to <i>true</i>. This will always result in an empty result set.');

    this.user$.subscribe(user => {
      if (!user)
        return;
      this.user = user;
      this.loadTransactions();
    });
  }

  /**
   * Requests more search ratings
   * @param count requested number of additional ratings
   */
  loadMoreTransactions(): void {
    if (!this.transactions || !this.thereAreMoreTransactions || this.searchingTransactions)
      return;
    this.loadTransactions();
  }


  loadTransactions(): void {
    this.showLoadingSpinnerTransactions = true;
    if (!this.user)
      return;
    this.bookService.fetchTransactions(this.user.uid, this.borrowing, this.lending, this.open, this.completed, this.onlyReturned, undefined, undefined, this.listingUid, this.lastVisibleTransaction, this.count).then(wrapper => {
      this.showLoadingSpinnerTransactions = false;
      if (wrapper.data) {
        this.transactions.push(...wrapper.data);
        // Recreate the ratings array in order to trigger a fresh setRatings in ratings-view component.
        this.transactions = [...this.transactions];
        this.transactionsLoaded.emit(this.transactions);
      }
      const count = this.count ? this.count : environment.defaultLoadTransactionsCount;
      this.thereAreMoreTransactions = wrapper.data !== undefined && wrapper.data.length === count;
      this.lastVisibleTransaction = wrapper.lastVisible;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }


}
