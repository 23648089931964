<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<a *ngIf="conversation" [routerLink]="conversation.uid" class="no-underline">
  <div class="row mx-0 p-0 my-2 p-1 jumbotron">
    <div *ngIf="other?.imgUrlThumb" class="m-0 p-0">
      <img [src]="other?.imgUrlThumb" class="thumbnail"
           default="./assets/images/default/default-user-w300.webp">
    </div>
    <div class="col mx-2 p-0 ">
      <p *ngIf="other?.displayName" class="other-name">{{other?.displayName}}</p>
      <p *ngIf="conversation?.embeddedListing?.name" class="listing-name">{{conversation?.embeddedListing?.name}}</p>
      <!-- hide on screens wider than md -->
      <div class="d-md-none">
        <p *ngIf="!conversation?.embeddedListing?.imgUrlThumb" class="message">{{latestMessage?.message|shorten:140:true}}</p>
        <p *ngIf="conversation?.embeddedListing?.imgUrlThumb" class="message">{{latestMessage?.message|shorten:100:true}}</p>
      </div>
      <!-- hide on screens smaller than md -->
      <div class="d-none d-md-block">
        <p *ngIf="!conversation?.embeddedListing?.imgUrlThumb" class="message">{{latestMessage?.message|shorten:280:true}}</p>
        <p *ngIf="conversation?.embeddedListing?.imgUrlThumb" class="message">{{latestMessage?.message|shorten:200:true}}</p>
      </div>
      <p *ngIf="latestMessage && latestMessage.date" [title]="latestMessage.date.toDate() | moment:'LLLL'"
         class="message-date">{{utilService.createFromNowString(latestMessage.date.toDate())}}</p>
    </div>
    <div *ngIf="conversation?.embeddedListing?.imgUrlThumb" align="right" class=" m-0 p-0">
      <img [src]="conversation?.embeddedListing?.imgUrlThumb" class="thumbnail"
           default="./assets/images/default/default-listing-w300.webp">
    </div>
  </div>
</a>
