import {Injectable} from '@angular/core';
import {Wrapper} from '../shared/models/wrapper.model';
import {environment} from '../../environments/environment';
import firebase from 'firebase/app';
import {Message} from '../shared/models/message.interface';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Conversation} from '../shared/models/conversation.interface';
import {convertToConversation, convertToMessage, convertToReport} from '../shared/converters/modelConverters';
import {Report} from '../shared/models/report.interface';
import {ReportType} from '../shared/models/reportType.type';
import {Mutex} from 'async-mutex';
import {Subject} from 'rxjs';

import {firestore} from '../app.module';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import Timestamp = firebase.firestore.Timestamp;

@Injectable({
  providedIn: 'root',
})
export class SocialService {

  reportMutex = new Mutex();
  messageMutex = new Mutex();

  /**
   * Subject to emit information from report-view component to reports-view component. Should be emitted after deleting a listing. The data is the listingUid.
   */
  onListingDeletedSubject$ = new Subject<string>();
  /**
   * Subject to emit information from report-view component to reports-view component. Should be emitted after deleting a rating. The data is the ratingUid.
   */
  onRatingDeletedSubject$ = new Subject<string>();
  /**
   * Subject to emit information from report-view component to reports-view component. Should be emitted after deleting a report. The data are the reportUid and refUid.
   */
  onReportDeletedSubject$ = new Subject<{ reportUid: string, refUid: string }>();
  /**
   * Subject to emit information from report-view component to reports-view component. Should be emitted after deleting all report. The data is the referenceUid (ratingUid or reportUid).
   */
  onAllReportsDeletedSubject$ = new Subject<string>();

  constructor() {
  }


  async fetchConversation(conversationUid: string): Promise<Wrapper<Conversation>> {
    try {
      const conversationDocSnapshot = await firestore.collection(environment.firestoreCollectionConversations).doc(conversationUid).withConverter(conversationConverter).get();
      const conversation: Conversation | undefined = conversationDocSnapshot.data();
      if (conversation) {
        const conversationWithId = {...conversation, uid: conversationDocSnapshot.id};
        return new Wrapper<Conversation>(conversationWithId);
      }
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Conversation>(undefined, $localize`You can only view conversations, of which you are either sender or receiver.`);
      return new Wrapper<Conversation>(undefined, e.conversation);
    }

    // If no conversation was found
    return new Wrapper<Conversation>(undefined);
  }

  async fetchMessage(messageUid: string, conversationUid: string): Promise<Wrapper<Message>> {

    return this.messageMutex.runExclusive(async () => {
      try {
        const messageDocSnapshot = await firestore.collection(environment.firestoreCollectionConversations).doc(conversationUid).collection(environment.firestoreCollectionMessages).doc(messageUid).withConverter(messageConverter).get();
        const message: Message | undefined = messageDocSnapshot.data();
        if (message) {
          const messageWithId = {...message, uid: messageDocSnapshot.id};
          return new Wrapper<Message>(messageWithId);
        }
      } catch (e: any) {
        if (e.code === 'permission-denied')
          return new Wrapper<Message>(undefined, $localize`You can only view messages, of which you are either sender or receiver.`);
        return new Wrapper<Message>(undefined, e.message);
      }

      // If no message was found
      return new Wrapper<Message>(undefined);
    });
  }

  async fetchMessagesFromConversation(conversationUid: string, limit: number = environment.defaultLoadMessagesCount, startAfter?: DocumentSnapshot): Promise<Wrapper<Message[]>> {
    try {
      let query = firestore.collection(environment.firestoreCollectionConversations).doc(conversationUid).collection(environment.firestoreCollectionMessages)
          .orderBy('date', 'desc')
          .withConverter(messageConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const messageQuerySnapshot = await query.get();
      const messages: Message[] = [];
      messageQuerySnapshot.forEach(messageDocSnapshot => {
        const message: Message | undefined = messageDocSnapshot.data();
        if (message) {
          const messageWithId = {...message, uid: messageDocSnapshot.id};
          messages.push(messageWithId);
        }
      });
      const lastVisible = messageQuerySnapshot.docs[messageQuerySnapshot.docs.length - 1];

      return new Wrapper<Message[]>(messages, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Message[]>(undefined, $localize`You can only view messages, of which you are either sender or receiver.`);
      return new Wrapper<Message[]>(undefined, e.message);
    }

    // If no messages were found
    return new Wrapper<Message[]>(undefined);
  }

  async fetchMessages(receiverUid: string, limit: number = environment.defaultLoadMessagesCount, startAfter?: DocumentSnapshot): Promise<Wrapper<Message[]>> {
    try {
      let query = firestore.collectionGroup(environment.firestoreCollectionMessages)
          .where('receiverUid', '==', receiverUid)
          .orderBy('date', 'desc')
          .withConverter(messageConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const messageQuerySnapshot = await query.get();
      const messages: Message[] = [];
      messageQuerySnapshot.forEach(messageDocSnapshot => {
        const message: Message | undefined = messageDocSnapshot.data();
        if (message) {
          const messageWithId = {...message, uid: messageDocSnapshot.id};
          messages.push(messageWithId);
        }
      });
      const lastVisible = messageQuerySnapshot.docs[messageQuerySnapshot.docs.length - 1];

      return new Wrapper<Message[]>(messages, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Message[]>(undefined, $localize`You can only view messages, of which you are either sender or receiver.`);
      return new Wrapper<Message[]>(undefined, e.message);
    }

    // If no messages were found
    return new Wrapper<Message[]>(undefined);
  }

  /**
   * Creates a listener for conversations for the given user. Since every conversation has two users, (user1Uid and user2Uid), actually two listeners get
   * created - one listening for conversations filtered by each user. Whenever the latest conversations change, either of the 2 callbacks is called with
   * the current conversations.
   * @param userUid ID of the user, whose conversations should be loaded
   * @param conversations1Callback success callback for user1Uid
   * @param conversations2Callbacksuccess callback for user2Uid
   * @param limit number of conversations per listener. default: 10 (or whatever is set in environment.defaultLoadConversationsCount)
   */
  streamConversations(userUid: string,
                      conversations1Callback: ((conversations: Conversation[]) => void),
                      conversations2Callback: ((conversations: Conversation[]) => void),
                      limit: number = environment.defaultLoadConversationsCount): (() => void) {
    const stream1Unsubscribe = this.streamConversationsHelper(userUid, conversations1Callback, 'user1Uid', limit);
    const stream2Unsubscribe = this.streamConversationsHelper(userUid, conversations2Callback, 'user2Uid', limit);
    const masterUnsubscribe: (() => void) = () => {
      stream1Unsubscribe();
      stream2Unsubscribe();
    };
    return masterUnsubscribe;
  }

  async fetchConversations(userUid: string, startAfter?: DocumentSnapshot, limit: number = environment.defaultLoadConversationsCount): Promise<Wrapper<Conversation[]>> {
    try {
      // First query: user1Uid == userUid
      let queryReceiver = firestore.collection(environment.firestoreCollectionConversations)
          .where('user1Uid', '==', userUid)
          .orderBy('latestMessageDate', 'desc')
          .withConverter(conversationConverter);
      if (limit)
        queryReceiver = queryReceiver.limit(limit);
      if (startAfter)
        queryReceiver = queryReceiver.startAfter(startAfter);
      const conversationQueryReceiverSnapshot = await queryReceiver.get();
      const conversationsReceiver: Conversation[] = [];
      conversationQueryReceiverSnapshot.forEach(conversationDocSnapshot => {
        const conversation: Conversation | undefined = conversationDocSnapshot.data();
        if (conversation) {
          const conversationWithId = {...conversation, uid: conversationDocSnapshot.id};
          conversationsReceiver.push(conversationWithId);
        }
      });
      const lastVisibleReceiver = conversationQueryReceiverSnapshot.docs[conversationQueryReceiverSnapshot.docs.length - 1];

      // Second query: user2Uid == userUid

      let querySender = firestore.collection(environment.firestoreCollectionConversations)
          .where('user2Uid', '==', userUid)
          .orderBy('latestMessageDate', 'desc')
          .withConverter(conversationConverter);
      if (limit)
        querySender = querySender.limit(limit);
      if (startAfter)
        querySender = querySender.startAfter(startAfter);
      const conversationQuerySenderSnapshot = await querySender.get();
      const conversationsSender: Conversation[] = [];
      conversationQuerySenderSnapshot.forEach(conversationDocSnapshot => {
        const conversation: Conversation | undefined = conversationDocSnapshot.data();
        if (conversation) {
          const conversationWithId = {...conversation, uid: conversationDocSnapshot.id};
          conversationsSender.push(conversationWithId);
        }
      });
      const lastVisibleSender = conversationQueryReceiverSnapshot.docs[conversationQueryReceiverSnapshot.docs.length - 1];

      const allConversations = [...conversationsSender, ...conversationsReceiver].sort((a, b) => {
        return b.latestMessageDate.toMillis() - a.latestMessageDate.toMillis();
      });

      return new Wrapper<Conversation[]>(allConversations, undefined, lastVisibleReceiver, lastVisibleSender);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Conversation[]>(undefined, $localize`You can only view conversations, of which you are either sender or receiver.`);
      return new Wrapper<Conversation[]>(undefined, e.message);
    }

    // If no conversations were found
    return new Wrapper<Conversation[]>(undefined);
  }

  /**
   * Marks the given conversation as read. First, fetches the current conversation from the firestore, to make sure, that no old version is rewritten to it.
   * Then, checks, if the read flag for the given user needs to be changed. If not, the onSuccessCallback is called.
   * Otherwise, it's changed and written to the database. Then, the onSuccessCallback is called.
   * @param userUid user, who read the message
   * @param conversationUid Uid of the conversation to be marked
   * @param onSuccessCallback callback in case of success (or if there's nothing to do)
   * @param onErrorCallback callback in case of error
   */
  markConversationAsRead(userUid: string, conversationUid: string, onSuccessCallback: (conversation: Conversation) => void, onErrorCallback: (error: string) => void) {
    // First, fetch conversation, to make sure, we're updating the latest version
    this.fetchConversation(conversationUid).then((wrapper) => {
      if (!wrapper.data)
        return;
      const conversation = wrapper.data;

      if (userUid === conversation.user1Uid) {
        if (conversation.user1Read)
            // Nothing to do
          return onSuccessCallback(conversation);
        ;
        conversation.user1Read = true;
      }
      if (userUid === conversation.user2Uid) {
        if (conversation.user2Read)
            // Nothing to do
          return onSuccessCallback(conversation);
        ;
        conversation.user2Read = true;
      }
      firestore.collection(environment.firestoreCollectionConversations).doc(conversation.uid).set(conversation).then(
          () =>
              onSuccessCallback(conversation),
          (error) =>
              onErrorCallback($localize`The conversation could not be marked as read\: ${error}`),
      );
    });
  }

  /**
   * Fetches the report with the given reportId from the firestore database.
   */
  async fetchReport(uid: string): Promise<Wrapper<Report>> {

    return this.reportMutex.runExclusive(async () => {
      try {
        const reportDocSnapshot = await firestore.collection(environment.firestoreCollectionReports).doc(uid).withConverter(reportConverter).get();
        const report = reportDocSnapshot.data();
        if (report) {
          const reportWithId: Report = {...report, uid: reportDocSnapshot.id};
          return new Wrapper<Report>(reportWithId);
        }
      } catch (e: any) {
        return new Wrapper<Report>(undefined, e.message);
      }

      // If no report was found
      return new Wrapper<Report>(undefined);
    });
  }

  async fetchReports(type?: ReportType, refUid?: string, startAfter?: DocumentSnapshot, limit: number = environment.defaultLoadReportsCount): Promise<Wrapper<Report[]>> {
    try {
      let query;
      if (type === 'listing')
        query = firestore.collection(environment.firestoreCollectionReports)
            .where('type', '==', 'listing');
      else if (type === 'rating')
        query = firestore.collection(environment.firestoreCollectionReports)
            .where('type', '==', 'rating');
      else
        query = firestore.collection(environment.firestoreCollectionReports);

      if (refUid) {
        switch (type) {
          case 'listing':
            query = query.where('listingUid', '==', refUid);
            break;
          case 'rating':
            query = query.where('ratingUid', '==', refUid);
            break;
        }
      }

      // Only order, if not filtered by refUid ("Order by clause cannot contain a field with an equality filter listingUid")
      if (type === 'listing' && !refUid)
        query = query.orderBy('listingUid', 'asc');
      if (type === 'rating' && !refUid)
        query = query.orderBy('ratingUid', 'asc');
      if (!type)
        query = query.orderBy('creationDate', 'desc')
            .withConverter(reportConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const reportQuerySnapshot = await query.get();
      const reports: Report[] = [];
      reportQuerySnapshot.forEach(reportDocSnapshot => {
        const report: Report | undefined = convertToReport(reportDocSnapshot.data());
        if (report) {
          const reportWithId = {...report, uid: reportDocSnapshot.id};
          reports.push(reportWithId);
        }
      });
      const lastVisible = reportQuerySnapshot.docs[reportQuerySnapshot.docs.length - 1];

      return new Wrapper<Report[]>(reports, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Report[]>(undefined, $localize`You are not allowed to view these reports.`);
      return new Wrapper<Report[]>(undefined, e.message);
    }
  }

  /**
   * Creates a new report in the backend database.
   */
  insertReport(reporterUid: string, categoryId: string, reason: string, allowContact: boolean, type: ReportType, onSuccessCallback: (report: Report) => void, onErrorCallback: (error: string) => void, listingUid?: string, ratingUid?: string): void {
    const report: Report = {
      reason, reporterUid, categoryId, allowContact, type, creationDate: Timestamp.now(),
    };
    if (listingUid)
      report.listingUid = listingUid;
    if (ratingUid)
      report.ratingUid = ratingUid;

    firestore.collection(environment.firestoreCollectionReports).add(report).then(docRef => {
          report.uid = docRef.id;
          onSuccessCallback(report);
        },
        (error) => onErrorCallback($localize`The report could not be created\: ${error}`),
    );
  }

  /**
   * Sends the given report to the backend server.
   * You can find it at state / reportId or state / error.
   * @param reportId ID of the report to be sent
   * @param report report to be sent. Need to be provided, even if a fullReport is given
   * @param fullReport report to be written to the cache. This report is not sent to the firestore
   * @param merge if true, only the fields given in the report object will be updated. Otherwise, the whole report object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateReport(reportId: string, report: Report, fullReport: Report | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    report.lastEditDate = Timestamp.now();
    firestore.collection(environment.firestoreCollectionReports).doc(reportId).set(report, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The report could not be updated\: ${error}`),
    );
    if (merge && !fullReport) {
      console.error('updateReport called with a merge job without providing a fullReport.');
      return;
    }
  }

  deleteReport(reportUid: string): Promise<void> {
    return firestore.collection(environment.firestoreCollectionReports).doc(reportUid).delete();
  }

  /**
   * Delete all reports of the given type and refUid. Both parameters are optional.
   * @param onSuccessCallback
   * @param onErrorCallback
   * @param type type filter. 'listing' or 'rating'
   * @param refUid reference UID filter. listingUid or ratingUid.
   */
  deleteAllReports(onSuccessCallback: (count: number) => void, onErrorCallback: (error: string) => void, type?: ReportType, refUid?: string) {
    this.fetchReports(type, refUid, undefined, 999).then(async wrapper => {
      const reports = wrapper.data;
      if (reports) {
        let count = 0;
        for (let i = 0; i < reports.length; i++) {
          let report = reports[i];
          if (report?.uid) {
            await this.deleteReport(report.uid).then(value => {
              ++count;
            }, error => onErrorCallback(error));
          }
        }
        onSuccessCallback(count);
      }
      if (wrapper.errorMessage)
        onErrorCallback(wrapper.errorMessage);
    });
  }

  private streamConversationsHelper(userUid: string, conversationsCallback: (conversations: Conversation[]) => void, fieldPath: string,
                                    limit: number = environment.defaultLoadConversationsCount) {
    return firestore.collection(environment.firestoreCollectionConversations)
        .where(fieldPath, '==', userUid)
        .orderBy('latestMessageDate', 'desc')
        .limit(limit)
        .withConverter(conversationConverter)
        .onSnapshot((querySnapshot) => {
          const conversations: Conversation[] = [];
          querySnapshot.forEach((doc) => {
            const conversation: Conversation | undefined = doc.data();
            if (conversation) {
              const conversationWithId = {...conversation, uid: doc.id};
              conversations.push(conversationWithId);
            }
          });
          if (conversations.length > 0) {
            conversationsCallback(conversations);
          }

        });
  }
}

// Firestore data converter
export const messageConverter = {
  toFirestore(message: Message): Message {
    return message;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Message {
    return convertToMessage(snapshot.data(options));
  },
};

// Firestore data converter
export const conversationConverter = {
  toFirestore(conversation: Conversation): Conversation {
    return conversation;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Conversation {
    return convertToConversation(snapshot.data(options));
  },
};

// Firestore data converter
export const reportConverter = {
  toFirestore(report: Report): Report {
    return report;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Report {
    return convertToReport(snapshot.data(options));
  },
};
