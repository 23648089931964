<div [ngClass]="{'float': float}">
  <div [className]="centered? 'center-all' : ''">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div *ngIf="message" [className]="centered? 'alert alert-info center-all mt-2' : 'alert alert-info mt-2'">{{message}}</div>
  <div *ngIf="messagesByKey">
    <div *ngFor="let message of messagesByKey.values()" [className]="centered? 'alert alert-info center-all mt-2' : 'alert alert-info mt-2'">{{message}}</div>
  </div>
</div>
