import {Component, Input, OnInit} from '@angular/core';
import {UserPublic} from '../../models/userPublic.interface';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-user-thumb',
  templateUrl: './user-thumb.component.html',
  styleUrls: ['./user-thumb.component.css'],
})
export class UserThumbComponent implements OnInit {

  @Input() userPublic?: UserPublic;
  @Input() width?: number;
  @Input() height?: number;
  @Input() size?: number = 42;
  @Input() cssClass: string | undefined;
  @Input() circle = false;
  @Input() linkToProfile = true;


  constructor(private userService: UserService) {
  }

  _userUid?: string;

  get userUid(): string | undefined {
    return this._userUid;
  }

  @Input() set userUid(userUid: string | undefined) {
    this._userUid = userUid;
    if (!this.userPublic && this._userUid)
      this.userService.fetchUserPublic(this._userUid).then(wrapper => {
        this.userPublic = wrapper.data;

        if (wrapper.errorMessage)
          console.error(`Error loading user: ${wrapper.errorMessage}`);
      });
  }

  ngOnInit(): void {

  }

}
