<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [message]="loadingSpinnerMessage"
                     [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>
<app-alerts [alerts]="alerts" [float]="true" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<mat-card *ngFor="let refUid of reportsByRefUid.keys()" class="report-group mb-3">
  <mat-checkbox (change)="checkAllCheckboxes($event.checked, refUid)"
                [checked]="!!metaCheckboxValueByRefUid.get(refUid)"
                [indeterminate]="isCheckboxIndeterminate(refUid)"
                color="primary">
    <div *ngIf="type==='listing' && listingsByUid.get(refUid) as listing">
      <app-report-listing-preview [listing]="listing"></app-report-listing-preview>
    </div>
    <div *ngIf="type==='listing' && !listingsByUid.get(refUid) as listing">
      <a [routerLink]="['/browse','listing', refUid]">Invalid listing</a>
    </div>
    <div *ngIf="type==='rating' && ratingsByUid.get(refUid) as rating">
      <app-report-rating-preview [rating]="rating"></app-report-rating-preview>
    </div>
    <div *ngIf="type==='rating' && !ratingsByUid.get(refUid) as rating">
      <a [routerLink]="['/social','rating', refUid]">Invalid rating</a>
    </div>
  </mat-checkbox>

  <div *ngFor="let report of reportsByRefUid.get(refUid)">
    <mat-checkbox (ngModelChange)="updateCheckbox(refUid, report.uid, $event)" *ngIf="report.uid"
                  [ngModel]="!!checkboxValuesMapsByRefUid.get(refUid)?.get(report.uid)"
                  color="primary">
      <app-report-preview [report]="report"></app-report-preview>
    </mat-checkbox>
  </div>

  <app-mat-icon-button *ngIf="isAnyCheckboxChecked(refUid)" [callback]="onDeleteSelectedReports.bind(this)" [params]="[refUid]" color="accent"
                       cssClass="mr-2 mb-3" i18n-label="delete selected reports" icon="report" label="Delete selected reports"></app-mat-icon-button>

  <app-mat-icon-button *ngIf="areThereReportsForReference(refUid)" [callback]="onDeleteAllReportsForReference.bind(this)" [params]="[refUid]" color="warn"
                       cssClass="mr-2 mb-3" i18n-label="delete all reports for reference" icon="report"
                       label="Delete all reports for reference"></app-mat-icon-button>

  <app-mat-icon-button *ngIf="type === 'listing' && listingsByUid.get(refUid) as listing" [params]="[refUid]" [routerLink]="['/listing','edit',listing?.uid]"
                       cssClass="mr-2 mb-3" i18n-label icon="edit" label="Edit this listing"></app-mat-icon-button>

  <ng-container *ngIf="listingsByUid.get(refUid) as listing">
    <app-mat-icon-button *ngIf="type === 'listing' && listing && listing.disabled === true" [callback]="onEnableListing.bind(this)" [params]="[refUid]"
                         color="primary" cssClass="mr-2 mb-3" i18n-label="enable listing" icon="play_arrow" label="Enable listing"></app-mat-icon-button>

    <app-mat-icon-button *ngIf="type === 'listing' && listing && listing.disabled === false" [callback]="onDisableListing.bind(this)" [params]="[refUid]"
                         color="accent" cssClass="mr-2 mb-3" i18n-label="disable listing" icon="pause" label="Disable listing"></app-mat-icon-button>

    <app-mat-icon-button *ngIf="type === 'listing' && listing" [callback]="onDeleteListing.bind(this)" [params]="[refUid]" color="warn"
                         cssClass="mr-2 mb-3" i18n-label="delete listing" icon="delete" label="Delete listing"></app-mat-icon-button>
  </ng-container>

  <ng-container *ngIf="ratingsByUid.get(refUid) as rating">
    <app-mat-icon-button *ngIf="type === 'rating' && rating" [callback]="onDeleteRating.bind(this)" [params]="[refUid]" color="warn"
                         cssClass="mr-2 mb-3" i18n-label="delete rating" icon="delete" label="Delete rating"></app-mat-icon-button>
  </ng-container>
</mat-card>

<div *ngIf="thereIsMore">
  <button (click)="onClickShowMore()" class="btn btn-primary" i18n="button in item-card-group, which is used to load more reports" type="button">Show more</button>
</div>
