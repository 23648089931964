export interface ReportCategory {
  id: string;
  value: string;
}

export const GENERAL_REPORT_CATEGORIES: ReportCategory[] = [
  {id: 'spam', value: $localize`Spam`},
  {id: 'inappropriateLanguage', value: $localize`Inappropriate language`},
  {id: 'offensiveInsulting', value: $localize`Offensive language or insulting`},
  {id: 'racist', value: $localize`Racist`},
  {id: 'terrorPropaganda', value: $localize`Terror propaganda`},
  {id: 'other', value: $localize`Other`},
];

export const LISTING_REPORT_CATEGORIES: ReportCategory[] = [
  {id: 'illegalItem', value: $localize`Illegal item`},
  {id: 'wrongCategory', value: $localize`Wrong item category`},
  {id: 'fraud', value: $localize`Presumably fraud`},
  {id: 'fakeListing', value: $localize`Fake listing`},
  {id: 'picture', value: $localize`Inappropriate picture`},

];
export const RATING_REPORT_CATEGORIES: ReportCategory[] = [
  {id: 'unfair', value: $localize`Unfair rating`},
  {id: 'fakeRating', value: $localize`Fake rating`},
];
