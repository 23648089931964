import {PaymentMethod} from '../shared/models/mangopay/paymentMethod.type';
import {environment} from '../../environments/environment';
import {CurrencyISO} from '../shared/models/mangopay/typings/types';
import {NetVatMoney} from '../shared/models/netVatGross.interface';

/**
 * Determines the fixed payment fee for the given currency.
 * @param currencyId ID of the currency of the pay in (same as fee currency)
 * @param paymentMethod payment method - currently not used.
 * @return fee fixed payment fee in given currency
 */
export function getFixedFee(currencyId: string, paymentMethod?: PaymentMethod): number | undefined {
  // Keep in sync with MangopayService.getFixedFee(...)
  const mangopayFixedFeesByCurrency: { [currencyIdentifier: string]: number } = environment.mangopayFixedFees;
  return mangopayFixedFeesByCurrency[currencyId];
}

/**
 * Converts the amount from Mangopay notation to Blitzshare notation or the other way round (depending on param
 * @param amount money amount
 * @param currency currency of the amount
 * @param mangopayToBlitzshare if true, conversion is done from Mangopay notation to Blitzshare notation. If false, conversion is done from Blitzshare notation to Mangopay notation
 */
export function convertAmount(amount: number, currency: CurrencyISO | string, mangopayToBlitzshare: boolean): number {
  // Keep in sync with functions/mangopayPayments.convertAmount(...)
  switch (currency) {
    case 'USD':
    case 'GBP':
    case 'PLN':
    case 'EUR': {
      amount = mangopayToBlitzshare ? amount / 100 : amount * 100;
      break;
    }
  }
  return amount;
}

/**
 * Determines the fees for a payIn in the given currency. Mango pay charges variable fees of up to 2,5% + fixed fees of up to 0,30€ (billed in corresponding currency).
 * The fee currency has to be the same as the payment currency.
 * @param amount amount of the payIn
 * @param currencyId ID of the currency of the pay in (same as fee currency)
 * @param paymentMethod payment method - currently not used.
 * @return fee fee in given currency
 */
export function calcPayInFee(amount: number, currencyId: string, paymentMethod?: PaymentMethod): NetVatMoney {
  // Keep in sync with functions/mangopayPayments.calcPayInFee(...)
  const fixedFee = getFixedFee(currencyId);
  if (fixedFee === undefined)
    throw new Error(`Fixed payment fee for ${currencyId} is unknown.`);
  const variableFee = getVariableFee(amount);
  const net = fixedFee + variableFee;
  const vat = Math.round(environment.vatPercentage * net);
  const fee: NetVatMoney = {net, vat};
  return fee;
}

/**
 * Determines the fees for a transfer in the given currency.
 * The fee currency has to be the same as the transfer currency.
 * @param amount amount of the transfer
 * @param currencyId ID of the currency of the transfer (same as fee currency)
 * @param paymentMethod payment method used for payment
 * @return fee fee in given currency
 */
export function calcTransferFee(amount: number, currencyId: string, paymentMethod?: PaymentMethod): NetVatMoney {
  const net = Math.round(amount * environment.transferFeePercentage);
  const vat = Math.round(environment.vatPercentage * net);
  const fee: NetVatMoney = {net, vat};
  return fee;
}

/**
 * Determines the variable payment fee for the given currency.
 * @param amount amount of the payIn
 * @param currencyId ID of the currency of the pay in (same as fee currency)
 * @param paymentMethod payment method - currently not used.
 * @return fee variable fee in given currency
 */
export function getVariableFee(amount: number, currencyId?: string, paymentMethod?: PaymentMethod) {
  // Keep in sync with functions/mangopayPayments.getVariableFee(...)
  return Math.round(amount * 0.025);
}
