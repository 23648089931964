import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {of} from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-import-export-modal-content',
  templateUrl: './import-export-modal-content.component.html',
  styleUrls: ['./import-export-modal-content.component.css'],
})
export class ImportExportModalContentComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  @Input() exportJson?: string;
  @Input() filename?: string;
  @Input() importCallback?: ((json: string) => void);

  private setting = {
    element: {
      dynamicDownload: null as unknown as HTMLElement,
    },
  };

  constructor(public activeModal: NgbActiveModal,
              private cdRef: ChangeDetectorRef) {
  }


  ngOnInit(): void {
  }


  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ESC') {
      this.activeModal.dismiss('ESC press');
    }
  }


  import(json: string): any {
    this.activeModal.dismiss('ESC press');
    if (this.importCallback)
      this.importCallback(json);
  }


  getExportJsonObervable() {
    return of(this.exportJson);
  }

  downloadJson(): any {

    this.getExportJsonObervable().subscribe((res) => {
      if (!res)
        return;
      const now = moment(new Date());
      this.dynamicDownloadByHtmlTag({
        fileName: `${now.format('YYYY-MM-DD_kk-mm-ss')}_${this.filename}.json`,
        text: res,
      });
    });
  }

  private dynamicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent('click');
    element.dispatchEvent(event);
  }
}
