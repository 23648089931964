import {NgModule} from '@angular/core';
import {DropdownDirective} from './directives/dropdown.directive';
import {PlaceholderDirective} from './directives/placeholder.directive';
import {AlertComponent} from './components/alert/alert.component';
import {BsStarRatingComponent} from './components/bs-star-rating/bs-star-rating.component';
import {StarRatingModule} from 'angular-star-rating';
import {ShortenPipe} from './pipes/shorten.pipe';
import {ImageModalContentComponent} from './components/image-modal-content/image-modal-content.component';
import {ImageCarouselModalContentComponent} from './components/image-carousel-modal-content/image-carousel-modal-content.component';
import {NgbCarouselModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {MoneyComponent} from './components/money/money.component';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {MomentPipe} from './pipes/moment.pipe';
import {NotLoggedInComponent} from './components/not-logged-in/not-logged-in.component';
import {MatButtonModule} from '@angular/material/button';
import {RentPeriodInfoComponent} from './components/rent-period-info/rent-period-info.component';
import {ZeroToNullPipe} from './pipes/zeroToNull.pipe';
import {HttpClientModule} from '@angular/common/http';
import {LteDirective} from './directives/validation/lte.directive';
import {GteDirective} from './directives/validation/gte.directive';
import {RangeDirective} from './directives/validation/range.directive';
import {MatIconModule} from '@angular/material/icon';
import {InfoCardComponent} from './components/info-card/info-card.component';
import {MatIconButtonComponent} from './components/mat-icon-button/mat-icon-button.component';
import {MapComponent} from './components/map/map.component';
import {AgmCoreModule} from '@agm/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ImageUploaderComponent} from './components/image-uploader/image-uploader.component';
import {FormsModule} from '@angular/forms';
import {DropzoneDirective} from './directives/dropzone.directive';
import {PreviewModalComponent} from './components/image-uploader/cropper/preview-modal/preview-modal.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CropperComponent} from './components/image-uploader/cropper/cropper.component';
import {UploadTaskComponent} from './components/image-uploader/upload-task/upload-task.component';
import {FilesizePipe} from './pipes/filesize.pipe';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {BaseComponent} from './components/base/base.component';
import {FocusAndSelectDirective} from './directives/focus-and-select.directive';
import {ElementScrollPercentageDirective} from './directives/element-scroll-percentage.directive';
import {DateRangePickerDirective} from './directives/validation/date-range-picker.directive';
import {NotEmptyDirective} from './directives/validation/not-empty.directive';
import {
  ListingDateRangeValidationMessagesComponent,
} from './components/listing-date-range-validation-messages/listing-date-range-validation-messages.component';
import {BookingPriceTableComponent} from './components/booking-price-table/booking-price-table.component';
import {ListingPriceTableComponent} from './components/listing-price-table/listing-price-table.component';
import {UserThumbComponent} from './components/user-thumb/user-thumb.component';
import {RouterModule} from '@angular/router';
import {AlertsComponent} from './components/alerts/alerts.component';
import {WriteMessageComponent} from './components/write-message/write-message.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {RatingComponent} from './components/rating/rating.component';
import {RatingsViewComponent} from './components/ratings-view/ratings-view.component';
import {ListingsViewComponent} from './components/listings-view/listings-view.component';
import {RatingStarTableComponent} from './components/rating-star-table/rating-star-table.component';
import {RatingCriterionHeadlineComponent} from './components/rating-criterion-headline/rating-criterion-headline.component';
import {TransactionsForListingComponent} from './components/transactions-for-listing/transactions-for-listing.component';
import {TransactionPreviewComponent} from './components/transaction-preview/transaction-preview.component';
import {TransactionStateComponent} from './components/transaction-state/transaction-state.component';
import {DefaultImageDirective} from './directives/default-image.directive';
import {PublicAddressComponent} from './components/public-address/public-address.component';
import {FileSelectorComponent} from './components/file-selector/file-selector.component';
import {MatCardModule} from '@angular/material/card';
import {MangopayRefusedReasonTypePipe} from './pipes/mangopay-refused-reason-type.pipe';
import {MangopayKycDocumentStatusPipe} from './pipes/mangopay-kyc-document-status.pipe';
import {CategorySelectorComponent} from './components/category-selector/category-selector.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatChipsModule} from '@angular/material/chips';
import {CategoriesCarouselComponent} from './components/categories-carousel/categories-carousel.component';
import {HighlightSearchPipe} from './pipes/highlight-search.pipe';
import {ReportButtonComponent} from './components/report-button/report-button.component';
import {YesNoComponent} from './components/yes-no/yes-no.component';
import {ItemCardComponent} from './components/item-card/item-card.component';
import {ListingDisabledInfoComponent} from './components/listing-disabled-info/listing-disabled-info.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AddressTableComponent} from './components/address-table/address-table.component';
import {AddressBarMapErrorsComponent} from './components/address-bar-map-errors/address-bar-map-errors.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RoundPipe} from './pipes/round.pipe';
import {ThumbnailWidgetComponent} from './components/thumbnail-widget/thumbnail-widget.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ImgWithBlurryBackgroundComponent} from './components/img-with-blurry-background/img-with-blurry-background.component';
import {ImportExportModalContentComponent} from './components/import-export-modal-content/import-export-modal-content.component';

@NgModule({
  declarations: [
    DropdownDirective,
    PlaceholderDirective,
    AlertComponent,
    BsStarRatingComponent,
    ShortenPipe,
    ZeroToNullPipe,
    MomentPipe,
    ImageModalContentComponent,
    ImageCarouselModalContentComponent,
    MoneyComponent,
    LoadingSpinnerComponent,
    NotLoggedInComponent,
    RentPeriodInfoComponent,
    LteDirective,
    GteDirective,
    RangeDirective,
    InfoCardComponent,
    MatIconButtonComponent,
    MapComponent,
    ImageUploaderComponent,
    DropzoneDirective,
    UploadTaskComponent,
    CropperComponent,
    PreviewModalComponent,
    FilesizePipe,
    ConfirmDialogComponent,
    BaseComponent,
    FocusAndSelectDirective,
    ElementScrollPercentageDirective,
    DateRangePickerDirective,
    NotEmptyDirective,
    ListingDateRangeValidationMessagesComponent,
    BookingPriceTableComponent,
    ListingPriceTableComponent,
    UserThumbComponent,
    AlertsComponent,
    WriteMessageComponent,
    ListingsViewComponent,
    RatingComponent,
    RatingsViewComponent,
    RatingStarTableComponent,
    RatingCriterionHeadlineComponent,
    TransactionsForListingComponent,
    TransactionPreviewComponent,
    TransactionStateComponent,
    DefaultImageDirective,
    PublicAddressComponent,
    FileSelectorComponent,
    MangopayRefusedReasonTypePipe,
    MangopayKycDocumentStatusPipe,
    CategorySelectorComponent,
    CategoriesCarouselComponent,
    HighlightSearchPipe,
    ReportButtonComponent,
    YesNoComponent,
    ItemCardComponent,
    ListingDisabledInfoComponent,
    AddressTableComponent,
    AddressBarMapErrorsComponent,
    RoundPipe,
    ThumbnailWidgetComponent,
    ImgWithBlurryBackgroundComponent,
    ImportExportModalContentComponent,
  ],
  imports: [
    HttpClientModule,
    StarRatingModule,
    NgbCarouselModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    AgmCoreModule,
    MatDialogModule,
    ImageCropperModule,
    FormsModule,
    DragDropModule,
    RouterModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatCardModule,
    NgbDropdownModule,
    MatTreeModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule,
  ],
  exports: [DropdownDirective,
    PlaceholderDirective,
    AlertComponent,
    BsStarRatingComponent,
    ZeroToNullPipe,
    ShortenPipe,
    MomentPipe,
    ImageModalContentComponent,
    ImageCarouselModalContentComponent,
    MoneyComponent,
    LoadingSpinnerComponent,
    NotLoggedInComponent,
    RentPeriodInfoComponent,
    LteDirective,
    GteDirective,
    RangeDirective,
    DateRangePickerDirective,
    InfoCardComponent,
    MatIconButtonComponent,
    MapComponent,
    ImageUploaderComponent,
    ImageCropperModule,
    FocusAndSelectDirective,
    NotEmptyDirective,
    ListingDateRangeValidationMessagesComponent,
    BookingPriceTableComponent,
    ListingPriceTableComponent,
    UserThumbComponent,
    AlertsComponent,
    WriteMessageComponent,
    ListingsViewComponent,
    RatingComponent,
    RatingsViewComponent,
    RatingCriterionHeadlineComponent,
    TransactionPreviewComponent,
    TransactionsForListingComponent,
    TransactionStateComponent,
    DefaultImageDirective,
    PublicAddressComponent,
    FileSelectorComponent,
    MangopayKycDocumentStatusPipe,
    MangopayRefusedReasonTypePipe,
    CategorySelectorComponent,
    CategoriesCarouselComponent,
    HighlightSearchPipe,
    ReportButtonComponent,
    ItemCardComponent,
    YesNoComponent,
    ListingDisabledInfoComponent,
    AddressTableComponent,
    AddressBarMapErrorsComponent,
    RoundPipe,
    ThumbnailWidgetComponent,
    ImgWithBlurryBackgroundComponent,
    ImportExportModalContentComponent,
  ],
})
export class SharedModule {
}
