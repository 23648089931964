import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Category} from '../../models/category.interface';
import {CategoryService} from '../../services/category.service';
import {BrowserService} from '../../../browser/browser.service';
import {SharedService} from '../../services/shared.service';
import {BaseComponent} from '../base/base.component';
import {UserService} from '../../services/user.service';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {AppState} from '../../../store/app.reducer';
import {Store} from '@ngrx/store';


@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss'],
})
export class CategorySelectorComponent extends BaseComponent implements OnInit {

  @Input() narrowButton? = false;
  @Input() bigButton? = false;
  @Input() showAllCategories? = false;
  @Input() buttonColor? = 'primary';
  @Output() onCategorySelected = new EventEmitter<Category>();
  @ViewChild('catSelectorDropdown')
  public catSelectorDropdown?: NgbDropdown;
  treeControl = new NestedTreeControl<Category>(node => node.subcategories);
  dataSource = new MatTreeNestedDataSource<Category>();
  private selectedCategoryId?: string;
  private allCategories?: Category[];
  private filteredCategories?: Category[];

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              public categoryService: CategoryService,
              private browserService: BrowserService,
              public sharedService: SharedService) {
    super(store);

  }

  _selectedCategory?: Category;

  get selectedCategory(): Category | undefined {
    return this._selectedCategory;
  }

  @Input() set selectedCategory(selectedCategory: Category | undefined) {
    this._selectedCategory = selectedCategory;
    this.filter();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.enableLoadingSpinner($localize`Loading categories`);
    this.categoryService.getCategories().then(wrapper => {
      this.disableLoadingSpinner();
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
      if (wrapper.data) {
        this.allCategories = [...wrapper.data];
        this.filter();
      }
    });
  }


  public filter(filterTerm?: string) {
    if (filterTerm && this.allCategories) {
      // this.filteredCategories = this.allCategories.filter(cat => this.nodeContainsFilterTerm(cat, filterTerm));
      this.filteredCategories = this.filterCategories(this.allCategories, filterTerm);
      this.expandAll(this.filteredCategories);
    } else {
      this.filteredCategories = this.allCategories;
      if (this.filteredCategories)
        this.expandNodeWithCategory(this.filteredCategories, this.selectedCategory);
    }
    if (this.dataSource && this.filteredCategories)
      this.dataSource.data = this.filteredCategories;
  }

  hasChild = (_: number, node: Category) => !!node.subcategories && node.subcategories.length > 0;

  onSelectCategory(node?: Category) {
    this.catSelectorDropdown?.close();
    this.selectedCategory = node;
    this.onCategorySelected.emit(node);
  }

  private filterCategories(cats: Category[], filterTerm: string): Category[] {
    let filteredCats: Category[] = [];
    let catsContainingFilterTermSomewhere = cats.filter(cat => this.nodeContainsFilterTerm(cat, filterTerm));
    for (let cat of catsContainingFilterTermSomewhere) {
      cat = {...cat};
      if (cat.subcategories)
        cat.subcategories = this.filterCategories([...cat.subcategories], filterTerm);
      filteredCats.push(cat);
    }
    return filteredCats;
  }

  private nodeContainsFilterTerm(cat: Category, filterText: string): boolean {
    if (this.stringContains(this.categoryService.getCategoryName(cat), filterText))
      return true;
    if (this.stringContains(this.categoryService.getCategoryElementName(cat), filterText))
      return true;
    if (cat.subcategories) {
      const subcats = cat.subcategories;
      for (let subcat of subcats)
        if (this.nodeContainsFilterTerm(subcat, filterText))
          return true;
    }

    return false;
  }

  private stringContains(fullString: string, searchedTerm: string): boolean {
    return fullString.toLocaleLowerCase().indexOf(searchedTerm.toLocaleLowerCase()) > -1;
  }

  private expandAll(cats: Category[]) {
    if (!cats || cats.length === 0)
      return;
    for (let cat of cats)
      if (cat.subcategories && cat.subcategories.length > 0) {
        this.treeControl.expand(cat);
        this.expandAll(cat.subcategories);
      }
  }

  private collapseAll(cats: Category[]) {
    if (!cats || cats.length === 0)
      return;
    for (let cat of cats)
      if (cat.subcategories && cat.subcategories.length > 0) {
        this.treeControl.collapse(cat);
        this.collapseAll(cat.subcategories);
      }
  }

  private expandNodeWithCategory(categories: Category[], searchedCat?: Category) {
    this.collapseAll(categories);
    if (!searchedCat || !categories)
      return;

    for (let i = 0; i < categories.length; i++) {
      let cat = categories[i];
      if (this.nodeContainsCategory(cat, searchedCat)) {
        this.treeControl.expand(cat);
        if (cat.subcategories)
          this.expandNodeWithCategory(cat.subcategories, searchedCat);
      }
    }
  }

  private nodeContainsCategory(node: Category, searchedCat: Category) {
    if (node.id === searchedCat.id)
      return true;
    if (node.subcategories) {
      const subcats = node.subcategories;
      for (let subcat of subcats)
        if (this.nodeContainsCategory(subcat, searchedCat))
          return true;
    }

    return false;
  }
}
