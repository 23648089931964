import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {BrowserService} from '../../../browser/browser.service';
import {takeUntil} from 'rxjs/operators';
import {Listing} from '../../../shared/models/listing.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {selectSearchListings} from '../../../browser/store/browser.selectors';

@Component({
  selector: 'app-start-page-latest-listings',
  templateUrl: './start-page-latest-listings.component.html',
  styleUrls: ['./start-page-latest-listings.component.scss'],
})
export class StartPageLatestListingsComponent extends BaseComponent implements OnInit {

  listings: Listing[] = [];
  error: string | undefined;
  totalCount = 0;
  thereIsMore = true;
  searching = false;
  periodInDays = 1;
  searchListings$ = this.store.select(selectSearchListings).pipe(takeUntil(this.destroy$));

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private browserService: BrowserService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.browserService.fetchSearchResults({searchTerm: '', categoryId: '', limit: 3});
    this.searchListings$.subscribe((searchListings) => {
      this.showLoadingSpinner = searchListings.searching;
      this.error = searchListings.searchResult?.errorMessage;
      this.listings = searchListings.searchResult ? searchListings.searchResult.listings : [];
      this.thereIsMore = false;
      this.totalCount = searchListings?.searchResult?.totalCount !== undefined ? searchListings.searchResult.totalCount : 0;
      this.searching = searchListings.searching;
      this.periodInDays = searchListings.searchResult?.periodInDays ? searchListings.searchResult?.periodInDays : 1;
    });

    this.browserService.periodInDaysSubject.pipe(takeUntil(this.destroy$)).subscribe(periodInDays => {
      this.periodInDays = periodInDays;
    });

  }


}
