<span *ngIf="periodSuggestionState">
  <span *ngIf="periodSuggestionState === 'SUGGESTED'" i18n="transaction periodSuggestionState SUGGESTED">New rent period suggested</span>
  <span *ngIf="periodSuggestionState === 'SUGGESTION_ACCEPTED'" i18n="transaction periodSuggestionState SUGGESTION_ACCEPTED">Suggestion accepted</span>
  <span *ngIf="periodSuggestionState === 'SUGGESTION_DENIED'" i18n="transaction periodSuggestionState SUGGESTION_DENIED">Suggestion denied</span>
  <span *ngIf="periodSuggestionState === 'SUGGESTION_RETRACTED'" i18n="transaction periodSuggestionState SUGGESTION_RETRACTED">Suggestion retracted</span>
</span>
<span *ngIf="!periodSuggestionState">
  <span *ngIf="!state">None</span>
  <span *ngIf="state === transactionState.BookingRequested" i18n="transaction state BookingRequested">Booking requested</span>
  <span *ngIf="state === transactionState.BookingAccepted" i18n="transaction state BookingAccepted">Booking accepted</span>
  <span *ngIf="state === transactionState.ItemPickUpRequestedByBorrower"
        i18n="transaction state ItemPickUpRequestedByBorrower">Borrower marked item as picked up</span>
  <span *ngIf="state === transactionState.ItemPickUpRequestedByLender"
        i18n="transaction state ItemPickUpRequestedByLender">Lender marked item as picked up</span>
  <span *ngIf="state === transactionState.ItemReturned" i18n="transaction state ItemReturned">Item returned</span>
  <span *ngIf="state === transactionState.ItemReturnRequestedByBorrower"
        i18n="transaction state ItemReturnRequestedByBorrower">Borrower marked item as returned</span>
  <span *ngIf="state === transactionState.ItemReturnRequestedByLender"
        i18n="transaction state ItemReturnRequestedByLender">Lender marked item as returned</span>
</span>
