import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoggedOutComponent} from './logged-out/logged-out.component';

const authRoutes: Routes = [
  {
    path: 'loggedOut',
    component: LoggedOutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
}
