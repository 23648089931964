import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {ImageCarouselModalContentComponent} from '../../../shared/components/image-carousel-modal-content/image-carousel-modal-content.component';
import {ImgUrls} from '../../../shared/models/imgUrls.interface';
import {UserPublic} from '../../../shared/models/userPublic.interface';
import {environment} from '../../../../environments/environment';
import {Mutex} from 'async-mutex';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '../../../shared/services/storage.service';
import {SharedService} from '../../../shared/services/shared.service';
import {takeUntil} from 'rxjs/operators';
import {TitleService} from '../../../shared/services/title.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css'],
})
export class ImageComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  @Input() embedded = false;

  userPublic?: UserPublic;

  // Image upload
  imageUploadPath = environment.userImagesPath;
  /**
   * State, whether an image upload is currently in progress. If it is, the user will have to wait before they can finish editing.
   */
  imageUploadInProgress: boolean = false;
  imageMutex = new Mutex();
  remainingImageUploads: number = 1;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private modalService: NgbModal,
      private cdRef: ChangeDetectorRef,
      private sharedService: SharedService,
      private storageService: StorageService,
      private titleService: TitleService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.embedded)
      this.titleService.setTitle($localize`My image`);

    this.user$.subscribe((user) => {
      if (user)
        this.userService.fetchUserPublic(user.uid).then(wrapper => {
          if (wrapper.errorMessage)
            this.addError($localize`Error loading your user account\: ${wrapper.errorMessage}`);
          if (wrapper.data)
            this.userPublic = wrapper.data;
        });
    });

    this.sharedService.startImageUploadEmitter.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (state)
        this.imageUploadInProgress = true;
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }


  /**
   * Opens the given array of images in a carousel inside a modal.
   * @param selectedId the ID of the image to be selected at the beginning
   * @param imgUrls all images to be shown in the carousel
   */
  openImage(imgUrl?: string): void {
    if (!this.userPublic?.imgUrl || !this.userPublic?.imgUrlThumb)
      return;

    const modalRef = this.modalService.open(ImageCarouselModalContentComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.selectedId = 0;
    const imgUrls: ImgUrls = {full: this.userPublic.imgUrl, thumb: this.userPublic.imgUrlThumb};
    modalRef.componentInstance.imgUrls = [imgUrls];
    if (this.userPublic)
      modalRef.componentInstance.altName = this.userPublic.displayName;
    modalRef.componentInstance.deleteImageCallback = (index: number) => {
      this.clearAlerts();
      if (!this.userPublic?.imgUrl || !this.userPublic?.imgUrlThumb)
        return;
      this.storageService.deleteImageFromStorage(imgUrls);
      this.updateUser(undefined, $localize`Successfully deleted the image.`, $localize`Error deleting the image:`);
    };
  }

  /**
   * Callback, which is called after a successful picture upload. Writes the download urls into the listing's imgUrls array.
   * @param imgUrls URLs (full and thumbnail) of the uploaded image
   */
  onUploadCallback = (imgUrls: ImgUrls, remainingUploads: number) => {
    this.clearAlerts();
    if (!this.userPublic)
      return;
    this.imageMutex.runExclusive(async () => {
      this.onImageUploadCompleted(imgUrls);
    }).then(() => {
      if (remainingUploads === 0) {
        // All uploads finished.
        this.imageUploadInProgress = false;
      }
    });
  };


  private onImageUploadCompleted(imgUrls: ImgUrls) {
    if (!this.userPublic)
      return;
    this.updateUser(imgUrls, $localize`Successfully saved the image.`, $localize`Error saving the image:`);

  }

  private updateUser(imgUrls: ImgUrls | undefined, messageSuccess: string, messageError: string) {
    if (!this.userPublic)
      return;
    const userPublic: UserPublic = {...this.userPublic, displayName: this.userPublic.displayName, uid: this.userPublic.uid};
    delete userPublic.imgUrl;
    delete userPublic.imgUrlThumb;
    if (imgUrls) {
      userPublic.imgUrl = imgUrls.full;
      userPublic.imgUrlThumb = imgUrls.thumb;
    }
    this.userPublic = userPublic;
    this.userService.updateUserPublic(userPublic, userPublic, false,
        () => this.addSuccess(messageSuccess),
        error => this.addError(messageError + ` ${error}`));
  }

}
