import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Referral} from '../../../shared/models/referral.interface';
import {FormControl, FormGroup} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import Util from '../../../shared/util';
import {UtilService} from '../../../shared/util.service';
import {CANNOT_BE_UNDONE} from '../../../shared/constants/strings';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-edit-referral',
  templateUrl: './edit-referral.component.html',
  styleUrls: ['./edit-referral.component.scss'],
})
export class EditReferralComponent extends BaseComponent implements OnInit {
  @Output() onReferralEdited = new EventEmitter<Referral>();
  @Output() onCancel = new EventEmitter();
  @Output() onDelete = new EventEmitter<Referral>();

  editReferralForm: FormGroup | undefined;
  maxRefLinks = environment.defaultLoadReferralsCount;

  referralLinkNameMaxLength = environment.referralLinkNameMaxLength;

  constructor(
      protected store: Store<AppState>,
      public utilService: UtilService,
      userService: UserService) {
    super(store);
  }

  _referral?: Referral;

  get referral(): Referral | undefined {
    return this._referral;
  }

  @Input() set referral(referral: Referral | undefined) {
    this._referral = referral;
    this.editReferralForm = this.createForm();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  createForm(): FormGroup {
    return new FormGroup({
      referralName: new FormControl(this.referral?.name),
    });
  }

  onCancelClick(): void {
    this.onCancel.emit();
  }

  onDeleteClick(): void {
    const message = '<p>' + $localize`If you delete this referral link, you will lose all recruited users forever. You will no longer receive commissions for their revenue.` + `</p><p>${CANNOT_BE_UNDONE}</p>`;

    this.utilService.showConfirmDialog($localize`Are you sure you want to delete the referral link <i>${this.referral?.name}</i>?`, message, this.delete.bind(this));


  }

  delete(): void {
    this.onDelete.emit(this.referral);
  }

  onSave(): void {
    if (!this.referral) {
      this.onCancel.emit();
      return;
    }
    const editedReferral: Referral = {...this.referral, name: this.editReferralForm?.value.referralName};
    this.onReferralEdited.emit(editedReferral);
  }


  /**
   * Creates a ref link for the given ref name.
   * @param name ref name
   */
  getReferralLink(name: string) {
    return Util.getReferralLink(name);
  }
}
