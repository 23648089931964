<div *ngIf="categories" cdkDropListGroup>

  <div *ngFor="let categories of categoriesArray; index as i" class="list-container">
    <h1 class="paragraph-headline" i18n>Level {{i}}</h1>

    <app-mat-icon-button [callback]="addCategory.bind(this)" [params]="[i]" color="primary" cssClass="mb-3"
                         i18n-label="button label add" icon="add"
                         label="Add"></app-mat-icon-button>

    <mat-selection-list #list (cdkDropListDropped)="drop($event)"
                        [cdkDropListData]="categories"
                        [multiple]="false"
                        cdkDropList
                        class="category-list"
                        id="level{{i}}">
      <mat-list-option (click)="onSelectionClick(category, i)" *ngFor="let category of categories" [value]="category" cdkDrag class="category-box">
        <mat-list-option *cdkDragPlaceholder class="category-placeholder"></mat-list-option>
        <p>{{categoryService.getCategoryName(category)}}</p>
        <p><small>{{category.id}}</small></p>
      </mat-list-option>
    </mat-selection-list>

  </div>

  <div *ngIf="showTrashBin" class="list-container">
    <h1 class="paragraph-headline" i18n="trash bin">Trash bin</h1>

    <app-mat-icon-button [callback]="clearTrash.bind(this)" color="warn" cssClass="mb-3"
                         i18n-label="button label clear trash" icon="delete"
                         label="Clear trash"></app-mat-icon-button>

    <mat-selection-list #list (cdkDropListDropped)="drop($event)"
                        [cdkDropListData]="trash"
                        [multiple]="false"
                        cdkDropList
                        class="category-list trash"
                        id="trash">
      <mat-list-option *ngFor="let category of trash" [value]="category" cdkDrag class="category-box trash">
        <mat-list-option *cdkDragPlaceholder class="category-placeholder"></mat-list-option>
        <p>{{categoryService.getCategoryName(category)}}</p>
        <p><small>{{category.id}}</small></p>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
