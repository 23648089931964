<div *ngIf="bookForm && listing !== undefined">
  <div *ngIf="!bookForm?.valid && bookForm.hasError('incompleteDateRange')" class="alert alert-warning"
       i18n="availability date range picker error message incomplete range">Please select the rent period.
  </div>
  <div *ngIf="!bookForm?.get('dateFrom')?.valid" class="alert alert-danger"
       i18n="availability date range picker error message invalid start date">Invalid start date: The item is
    not available for rent on this date.
  </div>
  <div *ngIf="!bookForm?.get('dateUntil')?.valid" class="alert alert-danger"
       i18n="availability date range picker error message invalid end date">Invalid end date: The item is
    not available for rent on this date.
  </div>

  <div *ngIf="!bookForm?.valid && bookForm.hasError('dateRangeContainsUnavailableDays')" class="alert alert-danger">
    <span
      i18n="availability date range picker error message contains unavailable days">Invalid date range: The item is not available for rent on the
    following {dateRangeInvalidDates.length, plural, =0 {days} =1 {day} other {{{dateRangeInvalidDates.length}} days}}:</span>
    <ul>
      <li *ngFor="let invalidDate of dateRangeInvalidDates">{{invalidDate | moment:'LL'}}</li>
    </ul>
  </div>

  <div *ngIf="!bookForm?.valid && bookForm.hasError('dateRangeContainsBookings')" class="alert alert-danger">
    <span
      i18n="availability date range picker error message contains bookings">Conflicting booking: There already is another booking within your selected
      date and time range:</span>
    <ul>
      <li *ngFor="let bookingRange of getConflictingBookingRanges(rentParams, listing)">{{bookingRange.dateFrom.toDate() | moment:'LLLL'}}
        - {{bookingRange.dateUntil.toDate() | moment:'LLLL'}}</li>
    </ul>
  </div>

  <div *ngIf="!bookForm?.valid && bookForm.hasError('dateTimeFromAfterDateTimeUntil')" class="alert alert-danger"
       i18n="availability date range picker error message from after until">Invalid rent period: The pickup has to be before the return.
  </div>

  <div *ngIf="!bookForm?.valid && bookForm.hasError('dateRangeTooShort')" class="alert alert-danger"
       i18n="availability date range picker error message rent period too short">The rent period is too short. The lender expects a minimum rent
    period
    of {rentParams?.minimumRentPeriod, plural, =0 {days} =1 {1 day} other {{{rentParams?.minimumRentPeriod}} days}}. But the selected period is
    only {rentParams?.rentPeriod, plural, =0 {0 days} =1 {1 day} other {{{rentParams?.rentPeriod | number:'1.0-2':numberFormatLocale}} days}}.
  </div>
  <div
    *ngIf="listing.availabilities && !bookForm?.valid && (bookForm.hasError('dateRangeContainsUnavailableDays') ||bookForm.hasError('dateRangeTooShort'))"
    class="alert alert-info">
    <h3 class="paragraph-headline-3"
        i18n="info block available dates">The item is available for rent in the
      following {listing.availabilities.length, plural, =0 {periods} =1 {period} other {{{listing.availabilities.length}} periods}}:
    </h3>
    <ul *ngIf="listing.availabilities && listing.availabilities.length > 1">
      <li *ngFor="let availability of listing.availabilities">{{utilService.getDate(availability.dateFrom) | moment:'LL'}}
        - {{utilService.getDate(availability.dateUntil) | moment:'LL'}}
        <div *ngIf="availability.excludedDates.length>0">
          <span
            i18n="number of excluded dates">{availability.excludedDates.length, plural, =0 {Excluded dates} =1 {Excluded date:} other {{{availability.excludedDates.length}} excluded dates:}}</span>
          <ul>
            <li *ngFor="let excludedDate of availability.excludedDates">{{utilService.getDate(excludedDate) | moment:'LL' }} </li>
          </ul>
        </div>
      </li>
    </ul>
    <div *ngIf="listing.availabilities && listing.availabilities.length === 1 && listing.availabilities[0] as availability">
      {{utilService.getDate(availability.dateFrom) | moment:'LL'}}
      - {{utilService.getDate(availability.dateUntil) | moment:'LL'}}
      <div *ngIf="availability.excludedDates.length > 0">
        <span
          i18n="number of excluded dates">{availability.excludedDates.length, plural, =0 {Excluded dates} =1 {Excluded date:} other {{{availability.excludedDates.length}} excluded dates:}}</span>
        <ul>
          <li *ngFor="let excludedDate of availability.excludedDates">{{utilService.getDate(excludedDate) | moment:'LL' }} </li>
        </ul>
      </div>
    </div>
  </div>
</div>
