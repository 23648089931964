import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {UserPublic} from '../../../../shared/models/userPublic.interface';
import {UserService} from '../../../../shared/services/user.service';
import {UtilService} from '../../../../shared/util.service';
import {RatingSummary} from '../../../../shared/models/ratingSummary.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.reducer';

@Component({
  selector: 'app-user-info-internal',
  templateUrl: './user-info-internal.component.html',
  styleUrls: ['./user-info-internal.component.css'],
})
export class UserInfoInternalComponent extends BaseComponent implements OnInit, OnDestroy {

  ratingSummary?: RatingSummary;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public utilService: UtilService) {
    super(store);
  }


  _userPublic?: UserPublic;

  get userPublic(): UserPublic | undefined {
    return this._userPublic;
  }

  @Input() set userPublic(userPublic: UserPublic | undefined) {
    this._userPublic = userPublic;
    if (userPublic)
      this.ratingSummary = this.userService.calculateRatingSummary(userPublic);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
