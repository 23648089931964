import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {BookService} from '../book.service';
import {map, takeUntil} from 'rxjs/operators';
import {Listing} from '../../shared/models/listing.interface';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UtilService} from '../../shared/util.service';
import {RentParams} from '../../shared/models/rentParams.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {ListingComponent} from '../../browser/listing/listing.component';
import {TitleService} from '../../shared/services/title.service';
import Util from '../../shared/util';
import {CountryService} from '../../shared/services/country.service';
import {Transaction} from '../../shared/models/transaction.interface';
import {TransactionListing} from '../../shared/models/transactionListing.interface';
import {TransactionState} from '../../shared/enums/transactionState.enum';
import firebase from 'firebase/app';
import {TransactionLog} from '../../shared/models/transactionLog.interface';
import {Message} from '../../shared/models/message.interface';
import {EmbeddedListing} from '../../shared/models/embeddedListing.interface';
import {ListingService} from '../../listing/listing.service';
import {TERMS_AND_CONDITIONS_DOWNLOAD_LINK} from '../../shared/constants/files';
import Locale from '../../shared/services/locale';
import {User} from '../../shared/models/user.interface';
import {BrowserService} from '../../browser/browser.service';
import {AnalyticsEventName, AnalyticsService} from '../../shared/services/analytics.service';
import {FacebookEventName, FacebookService} from '../../shared/services/facebook.service';
import {environment} from '../../../environments/environment';
import {UserService} from '../../shared/services/user.service';
import {selectSearch} from '../../browser/store/browser.selectors';
import {Search} from '../../shared/models/search.interface';
import {selectBookingMessage, selectListing} from '../store/book.selectors';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.css'],
})
export class BookComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  tocDownloadLink = TERMS_AND_CONDITIONS_DOWNLOAD_LINK;

  listing?: Listing;
  availableDayTimestamps: number[] = [];
  dateRangeInvalidDates: Date[] = [];
  rentParams: RentParams = {};
  minDate = new Date();
  maxDate = new Date();
  bookForm: FormGroup | undefined;

  currencyId: string | undefined;

  timePickerFormat = Locale.timePickerFormat();

  messageMaxLength = 2000;
  errors: string[] = [];
  bookingInProgress = false;

  lender?: User;
  search$ = this.store.select(selectSearch).pipe(takeUntil(this.destroy$));
  listing$ = this.store.select(selectListing).pipe(takeUntil(this.destroy$));
  bookingMessage$ = this.store.select(selectBookingMessage).pipe(takeUntil(this.destroy$));

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public utilService: UtilService,
      public listingService: ListingService,
      public countryService: CountryService,
      private router: Router,
      private formBuilder: FormBuilder,
      private activatedRoute: ActivatedRoute,
      private cdRef: ChangeDetectorRef,
      private titleService: TitleService,
      private bookService: BookService,
      private browserService: BrowserService,
      private facebookService: FacebookService,
      private analyticsService: AnalyticsService,
  ) {
    super(store);
  }

  public static validateRentParams(rentParams: RentParams): string | undefined {

    if (!rentParams.dateFrom) {
      return $localize`Please select a rent start date and time.`;
    }
    if (!rentParams.dateUntil) {
      return $localize`Please select a rent end date and time.`;
    }
    if (rentParams.dateFrom.getTime() < (new Date()).getTime()) {
      return $localize`The rent start date and time are in the past.`;
    }
    if (rentParams.dateFrom.getTime() > rentParams.dateUntil.getTime()) {
      return $localize`The end date and time are before the start date and time.`;
    }
    if (rentParams.dateFrom.getTime() === rentParams.dateUntil.getTime()) {
      return $localize`The start and end date and time are identical.`;
    }
    if (!rentParams.rentPeriod) {
      return $localize`The rent period cannot be determined. Please try selecting another date or time.`;
    }
    if (!rentParams.price?.pricePerDay) {
      return $localize`The rent price per day cannot be determined. Please try selecting another date or time.`;
    }
    return undefined;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.bookForm = this.createForm();

    this.search$.subscribe(search => this.onSearchLoadedFromStore(search));
    this.listing$.subscribe(listing => this.onListingLoadedFromStore(listing));
    this.bookingMessage$.subscribe(bookingMessage => {
      if (bookingMessage && !this.bookForm?.value.bookingMessage && !this.bookForm?.controls['bookingMessage']?.touched) {
        this.bookForm?.patchValue({bookingMessage});
        this.bookForm?.controls['bookingMessage']?.markAsTouched();
      }
    });

    // Fetch listing id from route
    const listingId$ = this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$),
        map(paramMap => paramMap.get('listingId')),
    );
    listingId$.subscribe(listingId => {
      if (listingId) {
        this.listingService.fetchListing(listingId).then(wrapper => {
          this.listing = wrapper.data;
          if (this.listing) {
            this.analyticsService.logEvent(AnalyticsEventName.BOOK_VIEW, {listingUid: this.listing.uid});
            this.facebookService.logEvent(FacebookEventName.Lead, {
              content_name: this.listing.name,
              content_ids: [this.listing.uid],
              content_category: this.listing.categoryId,
              content_type: 'product',
              product_catalog_id: environment.facebookCatalogId,
            });
          }

          if (wrapper.errorMessage)
            this.errors.push(wrapper.errorMessage);
        });
      }
    });

  }

  createForm(): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
      dateFrom: [null],
      dateUntil: [null],
      timeFrom: ['8:00'],
      timeUntil: ['20:00'],
      bookingMessage: [null],
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  onBook(): void {
    this.errors.length = 0;

    const rentParamsError = BookComponent.validateRentParams(this.rentParams);
    if (rentParamsError) {
      this.errors.push(rentParamsError);
      return;
    }

    if (!this.listing) {
      this.errors.push($localize`Please select a listing to book.`);
      return;
    }
    if (this.listing.disabled) {
      this.errors.push($localize`This listing cannot be booked, because it is disabled.`);
      return;
    }
    if (!this.user?.uid) {
      this.errors.push($localize`You need to login before booking.`);
      return;
    }
    if (this.listing.lenderUid === this.user.uid) {
      // No message. There is a dedicated message for that in the template
      return;
    }
    if (!this.listing.uid) {
      this.errors.push($localize`This listing cannot be booked, because it is invalid. Its identification number is missing.`);
      return;
    }
    if (!this.listing.lenderUid || !this.lender) {
      this.errors.push($localize`This listing cannot be booked, because it is invalid. The lender is unknown.`);
      return;
    }
    this.book(this.listing, this.rentParams.dateFrom!, this.rentParams.dateUntil!, this.user.uid, this.bookForm?.value?.bookingMessage);

  }

  availabilityFilter = (d: Date | null): boolean => {
    if (d === null)
      return false;
    const checkDay = d.setHours(0, 0, 0, 0);
    return this.availableDayTimestamps.indexOf(checkDay) > -1;
  };

  removeEmTags(input?: string) {
    return Util.removeEmTags(input);
  }

  onMessageKeyUp($event: KeyboardEvent) {
    const bookingMessage = this.bookForm?.value.bookingMessage;
    this.bookService.setBookingMessage(bookingMessage);
  }

  /**
   * Called, when a book state was loaded from the store, which contains the listing and booking message
   * @param state browser state
   */
  private onListingLoadedFromStore(listing?: Listing) {
    this.listing = listing;
    if (this.listing) {
      const minAndMaxDate = ListingComponent.setMinAndMaxDate(this.listing);
      this.minDate = minAndMaxDate.minDate;
      this.maxDate = minAndMaxDate.maxDate;
      this.availableDayTimestamps = ListingComponent.setAvailableDays(this.listing);
      // this.sortExcludedDates(this.listing);
      this.currencyId = this.listing?.currencyId;
      if (this.listing.name != null) {
        this.titleService.setTitle($localize`Booking` + ' ' + Util.removeEmTags(this.listing.name));
      }
      if (this.listing.lenderUid) {
        this.userService.fetchUserPublic(this.listing.lenderUid).then(wrapper => {
          if (wrapper.data)
            this.lender = wrapper.data;
        });
      }
    }
  }

  /**
   * Called, when a browser state was loaded from the store, which contains the booking date (=same as search date).
   * @param state browser state
   */
  private onSearchLoadedFromStore(search: Search) {
    const dateFrom = search.dateFrom;
    const dateUntil = search.dateUntil;
    const timeFrom = search.timeFrom;
    const timeUntil = search.timeUntil;
    if (dateFrom && (!this.bookForm?.value.dateFrom || !Util.isSameDay(this.bookForm?.value.dateFrom, dateFrom)))
      this.bookForm?.patchValue({dateFrom});
    if (dateUntil && (!this.bookForm?.value.dateUntil || !Util.isSameDay(this.bookForm?.value.dateUntil, dateUntil)))
      this.bookForm?.patchValue({dateUntil});
    if (timeFrom && this.bookForm?.value.timeFrom !== timeFrom)
      this.bookForm?.patchValue({timeFrom});
    if (timeUntil && this.bookForm?.value.timeUntil !== timeUntil)
      this.bookForm?.patchValue({timeUntil});
  }

  private book(listing: Listing, dateFrom: Date, dateUntil: Date, borrowerUid: string, bookingMessage?: string) {
    // Assert, that some values are given. We know, that they all are, because onBook() validates that before calling book(...).
    if (!this.rentParams?.price?.pricePerDay || !this.rentParams?.rentPeriod || !listing.lenderUid || !listing.uid)
      return;

    this.bookingInProgress = true;
    console.log(`Booking listing ${listing.uid} from ${dateFrom} until ${dateUntil} for user ${borrowerUid}`);

    // Create a transaction and insert it together with a notification
    const now = Timestamp.now();
    const imgUrlThumb = listing?.imgUrls && listing.imgUrls.length > 0 ? listing.imgUrls[0].thumb : '';
    const transactionListing: TransactionListing = {name: Util.removeEmTags(listing.name), summary: Util.removeEmTags(listing.summary), imgUrlThumb};
    const logs: TransactionLog[] = [];
    const transactionLog: TransactionLog = {
      state: TransactionState.BookingRequested,
      date: now,
      actingUserUid: borrowerUid,
      targetPickupDate: Timestamp.fromDate(dateFrom),
      targetReturnDate: Timestamp.fromDate(dateUntil),
    };
    logs.push(transactionLog);
    const transaction: Transaction = {
      transactionListing,
      state: TransactionState.BookingRequested,
      bookingDate: now,
      listingUid: listing.uid,
      borrowerUid,
      currencyId: listing.currencyId,
      lenderUid: listing.lenderUid,
      logs,
      numberOfDays: this.rentParams.rentPeriod,
      pricePerDay: this.rentParams.price.pricePerDay,
      targetPickupDate: Timestamp.fromDate(dateFrom),
      targetReturnDate: Timestamp.fromDate(dateUntil),
      ratingByLenderUid: null,
      ratingByBorrowerUid: null,
      lastUpdate: now,
      paymentState: 'UNPAID',
    };
    this.bookService.createTransactionAndNotification(transaction, ((transactionUid: string, notificationUid: string) => {
      console.log(`Created transaction with Uid ${transactionUid} and notification with Uid ${notificationUid}.`);
      this.analyticsService.logEvent(AnalyticsEventName.BOOKING_SUCCESSFUL, {listingUid: transaction.listingUid, transactionUid: transactionUid});
      this.facebookService.logEvent(FacebookEventName.Purchase, {
        currency: this.listing?.currencyId,
        value: transaction.pricePerDay * transaction.numberOfDays,
        content_name: 'page_transaction',
        content_ids: [transactionUid],
      });

      // Create and send the message (and message notification
      if (!listing.lenderUid || !listing.uid)
        return;

      if (bookingMessage) {
        const message: Message = {
          message: bookingMessage,
          date: now,
          receiverUid: listing.lenderUid,
          senderUid: borrowerUid,
          listingUid: listing.uid,
        };
        // Embedded listing for the conversation
        const embeddedListing: EmbeddedListing = {
          name: transaction.transactionListing.name,
          imgUrlThumb: transaction.transactionListing.imgUrlThumb,
          uid: listing.uid,
        };
        this.bookService.createMessage(message, ((messageUid: string) => {
          // Message was sent successfully
          console.log(`Created message with Uid ${messageUid}.`);
          this.bookingInProgress = false;
          this.router.navigate(['account', 'transactions', transactionUid]);
        }), ((errorMessage: string) => {
          this.errors.push($localize`Sending message failed\: ` + errorMessage);
          this.bookingInProgress = false;
        }), embeddedListing);
      }
      // no booking message
      else {
        this.bookingInProgress = false;
        this.router.navigate(['account', 'transactions', transactionUid]);
      }
    }), ((errorMessage: string) => {
      this.errors.push($localize`Booking failed\: ` + errorMessage);
      this.bookingInProgress = false;
    }));

  }
}
