<h1 *ngIf="!embedded" class="paragraph-headline" i18n="user image headline">My image</h1>

<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<div *ngIf="userPublic">
    <!-- Image -->
    <h1 *ngIf="userPublic?.imgUrlThumb && userPublic.imgUrl" class="paragraph-headline-2" i18n="current image">Current image</h1>
    <div *ngIf="userPublic?.imgUrlThumb && userPublic.imgUrl" class="container">
        <div class="row">
            <div class="col">
                <p i18n="explaination rectangular and round image">This is what your image looks like in rectangular and in round representation:</p>
            </div>
        </div>
        <div class="row">
            <div class="col img-col mr-3 mb-1 center-all"><img (click)="openImage(userPublic.imgUrl)"
                                                               alt="current user image"
                                                               class="contained-300h-300w hover-pointer" default="./assets/images/default/default-user-w300.webp"
                                                               i18n-alt="current user image alt"
                                                               src="{{userPublic?.imgUrlThumb}}"></div>
            <div class="col bg-light img-col mb-1 center-all"><img (click)="openImage(userPublic.imgUrl)"
                                                                   alt="current user image"
                                                                   class="contained-300h-300w hover-pointer rounded-circle"
                                                                   default="./assets/images/default/default-user-w300.webp"
                                                                   i18n-alt="current user image alt"
                                                                   src="{{userPublic?.imgUrlThumb}}"></div>
        </div>

        <!-- hide on screens smaller than md -->
        <div class="d-none d-md-block">
            <div class="row mb-3 ">
                <div class="col img-col mr-3 center-all" i18n="rectangular">Rectangular</div>
                <div class="col img-col center-all" i18n="circular">Circular</div>
            </div>
        </div>

        <app-info-card i18n="enlarge and delete image hint">To show a large version of your image, click on it. From there, you can also delete it.
        </app-info-card>
        <app-info-card i18n="overwrite image hint">You can only upload one image. If you upload a new one, the current one will be overwritten.</app-info-card>
        <br>
    </div>
    <app-image-uploader *ngIf="imageUploadPath" [filename]="userPublic.uid" [onUploadCallback]="onUploadCallback"
                        [remainingUploads]="remainingImageUploads" [uploadPath]="imageUploadPath"></app-image-uploader>

</div>
