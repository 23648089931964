import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.css'],
})
export class InfoCardComponent implements OnInit {

  /**
   * Mat-icon code to be used in the info card.
   */
  @Input() icon = 'help_outline';

  /**
   * If level is 'info', 'warning' or 'danger', the info card will be shown inside a bootstrap alert. If level is 'normal' or not set, no alert will be shown
   * around the info card.
   */
  @Input() level: 'normal' | 'info' | 'warning' | 'danger' = 'normal';

  constructor() {
  }

  ngOnInit(): void {
  }

}
