import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {Listing} from '../../shared/models/listing.interface';
import {ListingService} from '../../listing/listing.service';
import {environment} from '../../../environments/environment';
import {TitleService} from '../../shared/services/title.service';
import {UtilService} from '../../shared/util.service';
import {UserService} from '../../shared/services/user.service';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css'],
})
export class ToolsComponent extends BaseComponent implements OnInit {
  production: boolean;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private titleService: TitleService,
      public utilService: UtilService,
      private listingService: ListingService) {
    super(store);
    this.production = environment.production;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Admin tools`);
  }

  onEnableAllClicked(): void {
    const title = $localize`Are you sure you want to enable all listings?`;
    const message = $localize`This creates lots of database read and write load and changes lots of user data. Never do this on a production environment!`;
    this.utilService.showConfirmDialog(title, message, this.enableAll.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  enableAll(): void {
    this.clearAlerts();
    this.userService.fetchUsersPublic(undefined, 10000).then(wrapper => {
      if (wrapper.data)
        wrapper.data.forEach(userPublic => {
          this.listingService.fetchListings(userPublic.uid, undefined, true, undefined, 10000).then(wrapper => {
            if (wrapper.data) {
              wrapper.data.forEach(listing => {
                if (listing.disabled !== false) {
                  const fullListing: Listing = {...listing, disabled: false};
                  this.listingService.updateListing(fullListing.uid!, {disabled: false}, fullListing, true, () => {
                        this.addSuccess($localize`Listing ${fullListing?.name} (UID ${fullListing?.uid}) was enabled.`);
                      },
                      error => {
                        this.addError($localize`Enabling listing failed\: ${error}`);
                      });
                }
              });
            }
            if (wrapper.errorMessage)
              this.addError(`Error fetching listings for user ${userPublic.uid}\: ${wrapper.errorMessage}`);
          });
        });
      if (wrapper.errorMessage)
        this.addError($localize`Error fetching public users\: ${wrapper.errorMessage}`);
    });
  }

  onDisableAllClicked(): void {
    const title = $localize`Are you sure you want to disable all listings?`;
    const message = $localize`This creates lots of database read and write load and changes lots of user data. Never do this on a production environment!`;
    this.utilService.showConfirmDialog(title, message, this.disableAll.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  disableAll(): void {
    this.clearAlerts();
    this.userService.fetchUsersPublic(undefined, 10000).then((wrapper) => {
      if (wrapper.data)
        wrapper.data.forEach((userPublic) => {
          this.listingService.fetchListings(userPublic.uid, undefined, false, undefined, 10000).then(wrapper => {
            if (wrapper.data) {
              wrapper.data.forEach(listing => {
                if (listing.disabled !== true) {
                  const fullListing: Listing = {...listing, disabled: true};
                  this.listingService.updateListing(fullListing.uid!, {disabled: true}, fullListing, true, () => {
                        this.addSuccess($localize`Listing ${fullListing?.name} (UID ${fullListing?.uid}) was disabled.`);
                      },
                      error => {
                        this.addError($localize`Disabling listing failed\: ${error}`);
                      });
                }
              });
            }
            if (wrapper.errorMessage)
              this.addError($localize`Error fetching listings for user ${userPublic.uid}\: ${wrapper.errorMessage}`);
          });
        });
      if (wrapper.errorMessage)
        this.addError($localize`Error fetching public users\: ${wrapper.errorMessage}`);
    });
  }

}
