import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {Router} from '@angular/router';
import {UtilService} from '../../../shared/util.service';
import {AppState} from '../../../store/app.reducer';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-account-setup-warning',
  templateUrl: './account-setup-warning.component.html',
  styleUrls: ['./account-setup-warning.component.scss'],
})
export class AccountSetupWarningComponent extends BaseComponent implements OnInit {

  showAccountWarning = false;
  url?: string;
  window: any;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private utilService: UtilService,
      private router: Router) {
    super(store);
  }


  ngOnInit(): void {
    super.ngOnInit();

    this.window = window;
  }

  shouldShowFirstSteps(): boolean {
    return this.utilService.isAccountSetupIncomplete(this.user);
  }

  /**
   * Returns true, if the given location contains 'account/firstSteps'.
   * @param location URL (absolute or relative) to be checked
   */
  locationContainsFirstSteps(location: string): boolean {
    return location.indexOf('account/firstSteps') > -1;
  }

}
