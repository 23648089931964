import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {ConversationsDialogComponent} from './conversations-dialog/conversations-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {ConversationPreviewComponent} from './conversations-dialog/conversation-preview/conversation-preview.component';
import {SharedModule} from '../shared/shared.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {RouterModule} from '@angular/router';
import {UserComponent} from './user/user.component';
import {SocialRoutingModule} from './social-routing.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {StarRatingModule} from 'angular-star-rating';
import {ReportComponent} from './report/report.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {BrowserModule} from '../browser/browser.module';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ReportSentComponent } from './report/report-sent/report-sent.component';
import { RatingViewComponent } from './rating-view/rating-view.component';


@NgModule({
  declarations: [ConversationsDialogComponent, ConversationPreviewComponent, UserComponent, ReportComponent, ReportSentComponent, RatingViewComponent],
  imports: [
    CommonModule,
    SocialRoutingModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    SharedModule,
    DragDropModule,
    RouterModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    FormsModule,
    NgbCarouselModule,
    StarRatingModule,
    MatExpansionModule,
    BrowserModule,
    MatCardModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  exports: [],
})
export class SocialModule {
}
