<!-- Show errors -->
<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<app-transaction-preview *ngFor="let transaction of transactions" [transaction]="transaction"></app-transaction-preview>

<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true"></app-loading-spinner>

<div *ngIf="thereIsMore">
  <button (click)="onClickShowMore()" class="btn btn-primary" i18n="show more" type="button">Show more</button>
</div>

