import {Component, OnInit} from '@angular/core';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {BaseComponent} from '../../shared/components/base/base.component';
import {UserPublic} from '../../shared/models/userPublic.interface';
import {TitleService} from '../../shared/services/title.service';
import {map, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ImageCarouselModalContentComponent} from '../../shared/components/image-carousel-modal-content/image-carousel-modal-content.component';
import {ImgUrls} from '../../shared/models/imgUrls.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '../../shared/services/storage.service';
import {UtilService} from '../../shared/util.service';
import {Listing} from '../../shared/models/listing.interface';
import {ListingService} from '../../listing/listing.service';
import firebase from 'firebase/app';
import {environment} from '../../../environments/environment';
import {Rating} from '../../shared/models/rating.interface';
import {BookService} from '../../book/book.service';
import {RatingSummary} from '../../shared/models/ratingSummary.interface';
import {RatingCriterion} from '../../shared/enums/ratingCriterion.enum';
import {TransactionRole} from '../../shared/enums/transactionRole.enum';
import {AnalyticsEventName, AnalyticsService} from '../../shared/services/analytics.service';
import {UserService} from '../../shared/services/user.service';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent extends BaseComponent implements OnInit {

  userPublic ?: UserPublic;
  showLoadingSpinnerListings = true;
  thereAreMoreListings = true;
  listings: Listing[] = [];
  lastVisibleListing?: DocumentSnapshot<Listing>;
  searchingListings = false;
  showLoadingSpinnerRatings = true;
  thereAreMoreRatings = true;
  ratings: Rating[] = [];
  lastVisibleRating?: DocumentSnapshot<Rating>;
  searchingRatings = false;
  showWriteMessageComponent = false;

  ratingSummary?: RatingSummary;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private modalService: NgbModal,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      public utilService: UtilService,
      private storageService: StorageService,
      private listingService: ListingService,
      private bookService: BookService,
      private analyticsService: AnalyticsService,
      private titleService: TitleService) {
    super(store);
  }

  public get ratingCriterion(): typeof RatingCriterion {
    return RatingCriterion;
  }

  public get transactionRole(): typeof TransactionRole {
    return TransactionRole;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`User`);
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).pipe(map(params => {
          const userUid = params.get('userUid');
          if (userUid === null || userUid === undefined) {
            this.showLoadingSpinnerListings = false;
            throw new Error('There is no userUid.');
          }
          return userUid;
        }),
    )
        .subscribe((userUid: string) => {
              if (!userUid) {
                this.clearUser();
                return;
              }
              this.userService.fetchUserPublic(userUid).then(wrapper => {
                    this.showLoadingSpinnerListings = false;
                    if (wrapper.data) {

                      this.analyticsService.logEvent(AnalyticsEventName.USER_VIEW, {userUid});
                      this.userPublic = wrapper.data;
                      this.ratingSummary = this.userService.calculateRatingSummary(this.userPublic);
                      this.titleService.setTitle(wrapper.data.displayName);
                      this.lastVisibleListing = undefined;
                      this.listings = [];
                      this.loadListings();
                      this.lastVisibleRating = undefined;
                      this.ratings = [];
                      this.loadRatings();
                    } else
                      this.clearUser();
                    if (wrapper.errorMessage)
                      this.addError(wrapper.errorMessage);
                  },
              );
            },
        );
  }

  /**
   * Opens the given array of images in a carousel inside a modal.
   * @param selectedId the ID of the image to be selected at the beginning
   * @param imgUrls all images to be shown in the carousel
   */
  openImage(imgUrl?: string): void {
    if (!this.userPublic?.imgUrl || !this.userPublic?.imgUrlThumb)
      return;

    const modalRef = this.modalService.open(ImageCarouselModalContentComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.selectedId = 0;
    const imgUrls: ImgUrls = {full: this.userPublic.imgUrl, thumb: this.userPublic.imgUrlThumb};
    modalRef.componentInstance.imgUrls = [imgUrls];
    if (this.userPublic)
      modalRef.componentInstance.altName = this.userPublic.displayName;

  }

  onSendMessage(receiverUid: string): void {
    this.clearAlerts();
    this.showWriteMessageComponent = true;
    this.utilService.scrollToId('write-message');
  }

  /**
   * Requests more search listings
   * @param count requested number of additional listings
   */
  loadMoreListings(count: number): void {
    if (!this.listings || !this.thereAreMoreListings || this.searchingListings)
      return;
    this.loadListings();
  }

  loadListings(): void {
    this.showLoadingSpinnerListings = true;
    this.searchingListings = true;
    if (!this.userPublic)
      return;
    this.listingService.fetchListings(this.userPublic.uid, false, false, this.lastVisibleListing).then(wrapper => {
      this.showLoadingSpinnerListings = false;
      this.searchingListings = false;
      if (wrapper.data) {
        this.listings.push(...wrapper.data);
        // Recreate the listings array in order to trigger a fresh setListings in listings-view component. This is necessary for the table to be initialized
        this.listings = [...this.listings];
      }
      this.thereAreMoreListings = wrapper.data !== undefined && wrapper.data.length === environment.defaultLoadUserListingsCount;
      this.lastVisibleListing = wrapper.lastVisible;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  /**
   * Requests more search ratings
   * @param count requested number of additional ratings
   */
  loadMoreRatings(count: number): void {
    if (!this.ratings || !this.thereAreMoreRatings || this.searchingRatings)
      return;
    this.loadRatings();
  }

  loadRatings(): void {
    this.showLoadingSpinnerRatings = true;
    this.searchingRatings = true;
    if (!this.userPublic)
      return;
    this.bookService.fetchRatings(this.userPublic.uid, undefined, this.lastVisibleRating).then(wrapper => {
      this.showLoadingSpinnerRatings = false;
      this.searchingRatings = false;
      if (wrapper.data) {
        this.ratings.push(...wrapper.data);
        // Recreate the ratings array in order to trigger a fresh setRatings in ratings-view component.
        this.ratings = [...this.ratings];
      }
      this.thereAreMoreRatings = wrapper.data !== undefined && wrapper.data.length === environment.defaultLoadUserRatingsCount;
      this.lastVisibleRating = wrapper.lastVisible;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  private clearUser() {
    this.userPublic = undefined;
    this.ratingSummary = undefined;
    this.titleService.setTitle($localize`Unknown user`);
    this.lastVisibleListing = undefined;
    this.listings = [];
    this.lastVisibleRating = undefined;
    this.ratings = [];
  }

}
