import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import {Router} from '@angular/router';

import {Listing} from '../shared/models/listing.interface';
import {addListingPrivateToCache, addListingToCache} from './store/listing.actions';
import {AuthService} from '../auth/auth.service';
import firebase from 'firebase/app';
import {environment} from '../../environments/environment';
import {Wrapper} from '../shared/models/wrapper.model';
import {takeUntil} from 'rxjs/operators';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {ListingPrivate} from '../shared/models/listingPrivate.interface';
import {convertToListing, convertToListingPrivate} from '../shared/converters/modelConverters';
import {Mutex} from 'async-mutex';
import {firestore} from '../app.module';
import {selectListings, selectListingsPrivate} from './store/layout.selectors';
import Timestamp = firebase.firestore.Timestamp;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Injectable({
  providedIn: 'root',
})
export class ListingService {

  listingMutex = new Mutex();

  destroy$: Subject<null> = new Subject();
  listingsById: Map<string, Listing> = new Map<string, Listing>();
  listingsPrivateById: Map<string, ListingPrivate> = new Map<string, ListingPrivate>();

  listings$ = this.store.select(selectListings).pipe(takeUntil(this.destroy$));
  listingsPrivate$ = this.store.select(selectListingsPrivate).pipe(takeUntil(this.destroy$));


  constructor(private store: Store<fromApp.AppState>,
              private router: Router,
              private authService: AuthService) {
    // Note: ngOnInit is not called in a service class
    this.init();
  }

  init(): void {
    this.listings$.subscribe(listings => {
      if (listings) {
        this.listingsById = new Map<string, Listing>();
        listings.forEach(listing => {
          if (listing.uid)
            this.listingsById.set(listing.uid, listing);
        });
      }
    });
    
    this.listingsPrivate$.subscribe(listingsPrivate => {
      if (listingsPrivate) {
        this.listingsPrivateById = new Map<string, ListingPrivate>();
        listingsPrivate.forEach(listingPrivate => {
          if (listingPrivate.uid)
            this.listingsPrivateById.set(listingPrivate.uid, listingPrivate);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }


  /**
   * Fetches the listing with the given listingId from the firestore database.
   */
  async fetchListing(uid: string, maxAgeInSec: number = environment.defaultListingCacheAgeInSec): Promise<Wrapper<Listing>> {
    return this.listingMutex.runExclusive(async () => {
      const listingFromCache = this.listingsById.get(uid);
      if (listingFromCache !== undefined && this.isListingUpToDate(listingFromCache, maxAgeInSec))
        return new Wrapper<Listing>(listingFromCache);

      try {
        const listingDocSnapshot = await firestore.collection(environment.firestoreCollectionListings).doc(uid).withConverter(listingConverter).get();
        const listing = listingDocSnapshot.data();
        if (listing) {
          const listingWithIdAndCacheDate: Listing = {...listing, uid: listingDocSnapshot.id, cacheDate: new Date()};
          this.addListingToCache(listingWithIdAndCacheDate);
          return new Wrapper<Listing>(listingWithIdAndCacheDate);
        }
      } catch (e: any) {
        return new Wrapper<Listing>(undefined, e.message);
      }

      // If no listing was found
      return new Wrapper<Listing>(undefined);
    });
  }

  /**
   * Fetches the listingPrivate with the given listingPrivateId from the firestore database.
   */
  async fetchListingPrivate(uid: string, maxAgeInSec: number = environment.defaultListingPrivateCacheAgeInSec): Promise<Wrapper<ListingPrivate>> {
    const listingPrivateFromCache = this.listingsPrivateById.get(uid);
    if (listingPrivateFromCache !== undefined && this.isListingPrivateUpToDate(listingPrivateFromCache, maxAgeInSec))
      return new Wrapper<ListingPrivate>(listingPrivateFromCache);

    try {
      const listingPrivateDocSnapshot = await firestore.collection(environment.firestoreCollectionListingsPrivate).doc(uid).withConverter(listingPrivateConverter).get();
      const listingPrivate = listingPrivateDocSnapshot.data();
      if (listingPrivate) {
        const listingPrivateWithIdAndCacheDate: ListingPrivate = {...listingPrivate, uid: listingPrivateDocSnapshot.id, cacheDate: new Date()};
        this.addListingPrivateToCache(listingPrivateWithIdAndCacheDate);
        return new Wrapper<ListingPrivate>(listingPrivateWithIdAndCacheDate);
      }
    } catch (e: any) {
      return new Wrapper<ListingPrivate>(undefined, e.message);
    }

    // If no listingPrivate was found
    return new Wrapper<ListingPrivate>(undefined);
  }

  async fetchListings(userUid: string, draft?: boolean, disabled?: boolean, startAfter?: DocumentSnapshot, limit: number = environment.defaultLoadUserListingsCount): Promise<Wrapper<Listing[]>> {
    try {
      let query = firestore.collection(environment.firestoreCollectionListings)
          .where('lenderUid', '==', userUid);
      if (draft !== undefined)
        query = query.where('draft', '==', draft);
      if (disabled !== undefined)
        query = query.where('disabled', '==', disabled);
      query = query.orderBy('creationDate', 'desc')
          .withConverter(listingConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const listingQuerySnapshot = await query.get();
      const listings: Listing[] = [];
      listingQuerySnapshot.forEach(listingDocSnapshot => {
        const listing: Listing | undefined = listingDocSnapshot.data();
        if (listing) {
          const listingWithId = {...listing, uid: listingDocSnapshot.id};
          listings.push(listingWithId);
        }
      });
      const lastVisible = listingQuerySnapshot.docs[listingQuerySnapshot.docs.length - 1];

      return new Wrapper<Listing[]>(listings, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Listing[]>(undefined, $localize`You are not allowed to view these listings.`);
      return new Wrapper<Listing[]>(undefined, e.message);
    }
  }


  /**
   * Creates a new listing in the backend database.
   */
  insertListing(userId: string, categoryId: string | undefined, onSuccessCallback: (listing: Listing) => void, onErrorCallback: (error: string) => void): void {
    const listing: Listing = {lenderUid: userId, draft: true, creationDate: Timestamp.now(), disabled: false};
    if (categoryId)
      listing.categoryId = categoryId;
    firestore.collection(environment.firestoreCollectionListings).add(listing).then(docRef => {
          listing.uid = docRef.id;
          onSuccessCallback(listing);
          this.addListingToCache(listing);
        },
        (error) => onErrorCallback($localize`The listing could not be created\: ${error}`),
    );
  }

  /**
   * Sends the given listing to the backend server. If it has an ID, an existing listing is updated, otherwise a new one is added.
   * @param listingId ID of the listing to be sent
   * @param listing listing to be sent. Need to be provided, even if a fullListing is given
   * @param fullListing listing to be written to the cache. This listing is not sent to the firestore
   * @param merge if true, only the fields given in the listing object will be updated. Otherwise, the whole listing object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateListing(listingId: string, listing: Listing, fullListing: Listing | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionListings).doc(listingId).set(listing, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The listing could not be updated\: ${error}`),
    );
    if (merge && !fullListing) {
      console.error('updateListing called with a merge job without providing a fullListing.');
      return;
    }
    this.addListingToCache(merge && fullListing ? fullListing : listing);
  }

  /**
   * Sends the given listingPrivate to the backend server. If it has an ID, an existing listingPrivate is updated, otherwise a new one is added.
   * @param listingPrivateId ID of the listingPrivate to be sent
   * @param listingPrivate listingPrivate to be sent. Need to be provided, even if a fullListingPrivate is given
   * @param fullListingPrivate listingPrivate to be written to the cache. This listingPrivate is not sent to the firestore
   * @param merge if true, only the fields given in the listingPrivate object will be updated. Otherwise, the whole listingPrivate object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateListingPrivate(listingPrivateId: string, listingPrivate: ListingPrivate, fullListingPrivate: ListingPrivate | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionListingsPrivate).doc(listingPrivateId).set(listingPrivate, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The listingPrivate could not be updated\: ${error}`),
    );
    if (merge && !fullListingPrivate) {
      console.error('updateListingPrivate called with a merge job without providing a fullListingPrivate.');
      return;
    }
    this.addListingPrivateToCache(merge && fullListingPrivate ? fullListingPrivate : listingPrivate);
  }

  deleteListing(listingUid: string): Promise<void> {
    return firestore.collection(environment.firestoreCollectionListings).doc(listingUid).delete();
  }

  /**
   * Enables or disables the given listing.
   * @param listing listing to be enabled or disabled
   * @param disabled if true, the listing will be disabled. If false, it will be enabled
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  enableOrDisableListing(listing: Listing, disabled: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    if (listing && listing.uid) {
      listing = {...listing, disabled};
      this.updateListing(listing.uid!, {disabled}, listing, true, onSuccessCallback, onErrorCallback);
    }
  }

  getThumbnails(listing: Listing): string[] {
    if (!listing?.imgUrls || listing.imgUrls.length === 0)
      return [];
    const thumbnails: string[] = [];
    for (let imgUrl of listing.imgUrls) {
      thumbnails.push(imgUrl.thumb);
    }
    return thumbnails;
  }

  /**
   * Checks, if the given listing is newer then the given max age.
   * @param listing listing  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isListingUpToDate(listing: Listing, maxAgeInSec: any): boolean {
    if (!listing.cacheDate)
      return false;
    const cacheTime = listing.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  /**
   * Checks, if the given listingPrivate is newer then the given max age.
   * @param listingPrivate listingPrivate  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isListingPrivateUpToDate(listingPrivate: ListingPrivate, maxAgeInSec: any): boolean {
    if (!listingPrivate.cacheDate)
      return false;
    const cacheTime = listingPrivate.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  private addListingToCache(listing: Listing) {
    if (listing.uid)
      this.listingsById.set(listing.uid, listing);
    this.store.dispatch(addListingToCache({listing}));
  }

  private addListingPrivateToCache(listingPrivate: ListingPrivate) {
    if (listingPrivate.uid)
      this.listingsPrivateById.set(listingPrivate.uid, listingPrivate);
    this.store.dispatch(addListingPrivateToCache({listingPrivate}));
  }
}

// Firestore data converter
export const listingConverter = {
  toFirestore(listing: Listing): Listing {
    return listing;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Listing {
    return convertToListing(snapshot.data(options));
  },
};
// Firestore data converter
export const listingPrivateConverter = {
  toFirestore(listingPrivate: ListingPrivate): ListingPrivate {
    return listingPrivate;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): ListingPrivate {
    return convertToListingPrivate(snapshot.data(options));
  },
};

