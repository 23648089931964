import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-listing-disabled-info',
  templateUrl: './listing-disabled-info.component.html',
  styleUrls: ['./listing-disabled-info.component.css'],
})
export class ListingDisabledInfoComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
