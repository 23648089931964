import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {User} from '../../shared/models/user.interface';
import {
  clearFirebaseUser,
  clearUser,
  fetchUserFailed,
  fetchUserSucceeded,
  resetFetchUserState,
  resetUpdateUserState,
  setFirebaseUser,
  updateUserFailed,
  updateUserSucceeded,
} from './auth.actions';
import {FirebaseUser} from '../../shared/models/firebaseUser.interface';
import {ActionResult} from '../../shared/models/actionResult.interface';


export interface AuthState {
  user?: User;
  firebaseUser?: FirebaseUser;
  fetchUserResult: ActionResult;
  updateUserResult: ActionResult;
}

export const initialState: AuthState = {
  user: undefined,
  firebaseUser: undefined,
  fetchUserResult: {},
  updateUserResult: {},
};

function logDebugMessages(actionName: string, state: AuthState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

const reducer: ActionReducer<AuthState, Action> = createReducer(
    initialState,

    on(clearUser, (state) => {
      const fetchUserResult: ActionResult = {};
      const newState = {...state, user: undefined, fetchUserResult};
      logDebugMessages('clearUser', state, newState);
      return newState;
    }),

    // Fetch user

    on(fetchUserSucceeded, (state, {user}) => {
      const fetchUserResult: ActionResult = {...state.fetchUserResult, success: true, errorMessage: undefined};
      const newState = {...state, user, fetchUserResult};
      logDebugMessages('fetchUserSucceeded', state, newState);
      return newState;
    }),

    on(fetchUserFailed, (state, {fetchUserErrorMessage}) => {
      const fetchUserResult: ActionResult = {...state.fetchUserResult, success: false, errorMessage: fetchUserErrorMessage};
      const newState = {...state, user: undefined, fetchUserResult};
      logDebugMessages('fetchUserFailed', state, newState);
      return newState;
    }),

    on(resetFetchUserState, (state) => {
      const fetchUserResult: ActionResult = {...state.fetchUserResult, success: undefined, errorMessage: undefined};
      const newState = {...state, fetchUserResult};
      logDebugMessages('resetFetchUserState', state, newState);
      return newState;
    }),

    // Update user

    on(updateUserSucceeded, (state, {user}) => {
      const updateUserResult: ActionResult = {...state.updateUserResult, success: true, errorMessage: undefined};
      const newState = {...state, user, updateUserResult};
      logDebugMessages('updateUserSucceeded', state, newState);
      return newState;
    }),

    on(updateUserFailed, (state, {updateUserErrorMessage}) => {
      const updateUserResult: ActionResult = {...state.updateUserResult, success: false, errorMessage: updateUserErrorMessage};
      const newState = {...state, updateUserResult};
      logDebugMessages('updateUserFailed', state, newState);
      return newState;
    }),

    on(resetUpdateUserState, (state) => {
      const updateUserResult: ActionResult = {...state.updateUserResult, success: undefined, errorMessage: undefined};
      const newState = {...state, updateUserResult};
      logDebugMessages('resetUpdateUserState', state, newState);
      return newState;
    }),

    // Firebase user

    on(setFirebaseUser, (state, {firebaseUser}) => {
      const newState = {...state, firebaseUser};
      logDebugMessages('setFirebaseUser', state, newState);
      return newState;
    }),

    on(clearFirebaseUser, (state) => {
      const newState = {...state, firebaseUser: undefined};
      logDebugMessages('clearFirebaseUser', state, newState);
      return newState;
    }),
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}





