<div class="component-container">
  <button (click)="onCancel()" aria-label="Cancel" class="cancel-button" i18n-aria-label="Cancel" i18n-matTooltip="Cancel"
          id="rate-user" mat-icon-button matTooltip="Cancel">
    <mat-icon>close</mat-icon>
  </button>
  <form (ngSubmit)="sendRating(commentRef)" *ngIf="!ratingWasSent" class="mb-3"      >
  <div class="row">
    <div class="col">
      <h2 class="paragraph-headline-2" i18n="rate receiverName">Rate {{receiverName}}</h2>

      <!-- Show errors -->
      <app-alerts [scrollToSubject]="scrollToAlertsSubject" [alerts]="alerts"></app-alerts>
    </div>
  </div>
  <div class="row">
    <div class="col-auto">
      <app-user-thumb [circle]="false" [height]="130" [userUid]="receiverUid" [width]="100"></app-user-thumb>
    </div>

    <div class="col">
      <table class="table-responsive">
        <tr>
          <th>
            <app-rating-criterion-headline [criterion]="ratingCriterion.Overall" [receiverName]="receiverName"></app-rating-criterion-headline>
          </th>
          <td>
            <star-rating (ratingChange)="overall = $event.rating" [rating]="overall" [readOnly]="false" [showHalfStars]="true" [starType]="'svg'"
                         i18n-labelText labelPosition="left" labelText="" size="medium" style="display: inline-flex"></star-rating>
          </td>
        </tr>
        <tr *ngIf="receiverRole === transactionRole.Lender">
          <th>
            <app-rating-criterion-headline [criterion]="ratingCriterion.ItemAsDescribed" [receiverName]="receiverName"></app-rating-criterion-headline>
          </th>
          <td>
            <star-rating (ratingChange)="itemAsDescribed = $event.rating" [rating]="itemAsDescribed" [readOnly]="false" [showHalfStars]="true"
                         [starType]="'svg'" size="medium" style="display: inline-flex;"></star-rating>
          </td>
        </tr>
        <tr *ngIf="receiverRole === transactionRole.Borrower">
          <th>
            <app-rating-criterion-headline [criterion]="ratingCriterion.ItemReturnCondition" [receiverName]="receiverName"></app-rating-criterion-headline>
          </th>
          <td>
            <star-rating (ratingChange)="itemReturnCondition = $event.rating" [rating]="itemReturnCondition" [readOnly]="false" [showHalfStars]="true"
                         [starType]="'svg'" size="medium" style="display: inline-flex;"></star-rating>
          </td>
        </tr>
        <tr>
          <th>
            <app-rating-criterion-headline [criterion]="ratingCriterion.Punctuality" [receiverName]="receiverName"></app-rating-criterion-headline>
          </th>
          <td><span class="icon-with-text">
            <star-rating (ratingChange)="punctuality = $event.rating" [rating]="punctuality" [readOnly]="false" [showHalfStars]="true" [starType]="'svg'"
                         size="medium" style="display: inline-flex;"></star-rating>
            <mat-icon (click)="punctuality = undefined" i18n-matTooltip matTooltip="Clear rating">clear</mat-icon>
          </span>
          </td>
        </tr>
        <tr>
          <th>
            <app-rating-criterion-headline [criterion]="ratingCriterion.Friendliness" [receiverName]="receiverName"></app-rating-criterion-headline>
          </th>
          <td><span class="icon-with-text">
            <star-rating (ratingChange)="friendliness = $event.rating" [rating]="friendliness" [readOnly]="false" [showHalfStars]="true" [starType]="'svg'"
                         size="medium" style="display: inline-flex;"></star-rating>
            <mat-icon (click)="friendliness = undefined" i18n-matTooltip matTooltip="Clear rating">clear</mat-icon>
          </span>
          </td>
        </tr>

      </table>
    </div>
  </div>
  <div *ngIf="(friendliness && friendliness < 5) || (punctuality && punctuality < 5) || (overall && overall < 5) || (itemAsDescribed && itemAsDescribed <5)
  || (itemReturnCondition && itemReturnCondition <5)"
       class="row">
    <div class="col">
      <app-info-card i18n>Users expect good ratings. If everything was okay, please rate five stars.</app-info-card>
      <app-info-card i18n icon="sentiment_dissatisfied">If you rate less than 5 stars, please explain, why you were unhappy.</app-info-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field class="mat-form-textarea-w-100">
        <mat-label i18n="comment">Comment</mat-label>
        <textarea #commentRef
                  [maxLength]="commentMaxLength"
                  cdkAutosizeMaxRows="25"
                  cdkAutosizeMinRows="2" cdkTextareaAutosize
                  i18n-placeholder="rating comment input placeholder" matInput
                  placeholder="Type here to comment your rating." tabindex="3"></textarea>
        <span class="character-counter">{{commentRef.value.length}}/{{commentMaxLength}}</span>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-mat-icon-button [callback]="sendRating.bind(this)" [disabled]="sendingInProgress" [params]="[commentRef]"
                           color="primary" i18n-label="Send rating" icon="star"
                           label="Send rating"></app-mat-icon-button>

      <app-loading-spinner *ngIf="sendingInProgress" [centered]="true"></app-loading-spinner>

    </div>
  </div>

</form>

<div *ngIf="ratingWasSent" class="alert alert-success" i18n>Your rating was successfully sent.</div>
