import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {Listing} from '../../../shared/models/listing.interface';
import {ListingService} from '../../../listing/listing.service';
import {DISABLE_LISTING_CONFIRMATION_MESSAGE, DISABLE_LISTING_CONFIRMATION_TITLE} from '../../../shared/constants/strings';
import {UtilService} from '../../../shared/util.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css'],
})
export class ManageComponent extends BaseComponent implements OnInit {

  @Input() listing ?: Listing;
  @Output() listingChanged = new EventEmitter<Listing>();

  showActiveTransactions = false;
  showPastRentals = false;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public utilService: UtilService,
      private listingService: ListingService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  onEnableClicked(): void {
    this.clearAlerts();
    const message = $localize`An enabled listing is visible for other users and can be booked or found using the search.
    An enabled listing can be disabled again at any time.`;
    this.utilService.showConfirmDialog($localize`Are you sure you want to enable this listing?`, message, this.enableListing.bind(this),
        undefined, undefined, undefined, undefined, 'yes');
  }

  enableListing(): void {
    if (this.listing)
      this.listingService.enableOrDisableListing(this.listing, false, () => {
        console.log(`Listing ${this.listing?.name} (UID ${this.listing?.uid}) was enabled.`);
        this.listing = {...this.listing, disabled: false};
        this.listingChanged.emit(this.listing);
      }, error =>
          this.addError($localize`Enabling listing failed\: ${error}`));
  }


  onDisableClicked(): void {
    this.clearAlerts();
    this.utilService.showConfirmDialog(DISABLE_LISTING_CONFIRMATION_TITLE, DISABLE_LISTING_CONFIRMATION_MESSAGE, this.disableListing.bind(this),
        undefined, undefined, undefined, undefined, 'no');
  }

  disableListing(): void {
    if (this.listing)
      this.listingService.enableOrDisableListing(this.listing, true, () => {
        console.log(`Listing ${this.listing?.name} (UID ${this.listing?.uid}) was disabled.`);
        this.listing = {...this.listing, disabled: true};
        this.listingChanged.emit(this.listing);
      }, error =>
          this.addError($localize`Disabling listing failed\: ${error}`));
  }


  toggleActiveTransactions(): void {
    this.showActiveTransactions = !this.showActiveTransactions;
  }

  togglePastRentals(): void {
    this.showPastRentals = !this.showPastRentals;
  }
}
