import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {ErrorMessageConverterPipe} from './pipes/error-message-converter.pipe';
import {AuthRoutingModule} from './auth-routing.module';
import {SharedModule} from '../shared/shared.module';
import {HttpClientModule} from '@angular/common/http';
import {LoggedOutComponent} from './logged-out/logged-out.component';
import {NgxAuthFirebaseUIModule} from 'ngx-auth-firebaseui';
import {LoginDialogComponent} from './login-button/login-dialog/login-dialog.component';
import {LoginButtonComponent} from './login-button/login-button.component';


@NgModule({
  declarations: [
    LoginButtonComponent,
    ErrorMessageConverterPipe,
    LoggedOutComponent,
    LoginDialogComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    SharedModule,
    NgxAuthFirebaseUIModule,
  ],
  exports: [
    LoginButtonComponent,
  ],
})
export class AuthModule {
}
