import {environment} from '../../../../environments/environment';
import {Listing} from '../../models/listing.interface';
import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CountryService} from '../../services/country.service';
import {BrowserService} from '../../../browser/browser.service';
import {takeUntil} from 'rxjs/operators';
import {Coordinates} from '../../models/coordinates.interface';
import Util from '../../util';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import {SettingsService} from '../../services/settings.service';
import {BaseComponent} from '../base/base.component';
import {UserService} from '../../services/user.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Subject} from 'rxjs';
import {ElementScrollPercentageService} from '../../services/element-scroll-percentage.service';
import {UtilService} from '../../util.service';
import {CategoryService} from '../../services/category.service';
import {Category} from '../../models/category.interface';
import Locale from '../../services/locale';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {selectSortIndex} from '../../../browser/store/browser.selectors';


/**
 * Component, that shows listing in a grid or table view.
 * Note, that there is no sorting functionality in this component. The table does provide matSortHeaders, but the orderBy terms are just output to the parent
 * component in order to create an appropriate search request. Sorting inside this component does only make sense after all results have been loaded. Otherwise
 * reloading will add unsorted results to the list.
 */
@Component({
  selector: 'app-listings-view',
  templateUrl: './listings-view.component.html',
  styleUrls: ['./listings-view.component.scss'],
})
export class ListingsViewComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  @Input() thereIsMore = false;
  /**
   * The scroll percentage, at which new listings are loaded. For example, the value 50 will reload items after scrolling down half the page.
   */
  @Input() infiniteScrollPercentage = 101;
  @Output() loadMoreListings = new EventEmitter<number>();
  @Input() moreListingsCount = environment.defaultSearchListingsCount;
  @Input() periodInDays = 1;
  @Input() totalCount = 0;
  @Input() centered = false;
  /**
   * Initial view mode. Can be switched using the tabs on the top. Possible values: 'grid' (default) and 'table'
   */
  @Input() viewMode = 'grid';
  coords: Coordinates | undefined;
  numberFormatLocale = Locale.numberFormatLocale();
  // Tab management
  selectedIndex = 0;
  tabViewModes = ['grid', 'table'];
  tabSelectionSubject = new Subject<string>();
  // Table management
  @ViewChild(MatSort) sort: MatSort | undefined;
  dataSource?: MatTableDataSource<Listing>;
  displayedColumns: string[] = ['picture', 'name', 'periodPrice', 'lowestPrice', 'highestPrice', 'address', 'distance', 'category', 'creationDate'];
  @Input() matSortActive: string = '';
  @Input() matSortDirection: SortDirection = '';
  @Input() showMyListingMarker = false;
  public categoriesById: Map<string, Category> | undefined;

  sortIndex$ = this.store.select(selectSortIndex).pipe(takeUntil(this.destroy$));

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private cdRef: ChangeDetectorRef,
              private countryService: CountryService,
              public browserService: BrowserService,
              private settingsService: SettingsService,
              public categoryService: CategoryService,
              private elementScrollPercentageService: ElementScrollPercentageService,
              public utilService: UtilService) {
    super(store);

  }

  _listings: Listing[] = [];

  get listings(): Listing[] {
    return this._listings;
  }

  @Input() set listings(listings: Listing[]) {
    this._listings = listings;
    this.initTable();
  }

  /**
   * Translates the given sort criterion and direction into an Algolia sort index name
   * @param criterion sort criterion, e.g. name
   * @param direction sort direction: 'asc' or 'desc'
   * @return Algolia index name, e.g 'prod_listings_lastEditDate_desc'. Note: the first part ('prod_listings' in the previous example) is taken from
   * environment.algoliaListingsIndex)
   */
  private static determineSortIndex(criterion: string, direction: SortDirection) {

    if (criterion === 'lowestPrice' && direction === 'asc')
      return 'price_asc';
    if (criterion === 'highestPrice' && direction === 'desc')
      return 'price_desc';
    if (criterion === 'creationDate' && direction === 'desc')
      return 'creationDate_desc';
    if (criterion === 'lastEditDate' && direction === 'asc')
      return 'lastEditDate_desc';
    // If none of the above, return the default index
    return environment.algoliaListingsIndex;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.browserService.coordsSubject.pipe(takeUntil(this.destroy$)).subscribe(coords => {
      this.coords = coords;
    });

    this.user$.subscribe(user => {
      if (user?.settings?.listingsViewMode) {
        this.viewMode = user.settings.listingsViewMode;
        this.selectedIndex = this.tabViewModes.indexOf(this.viewMode);
      }
    });

    // Synchronize setting between different components using listings-view (latest-listings, components...)
    this.tabSelectionSubject.subscribe((viewMode) => {
      this.viewMode = viewMode;
      this.selectedIndex = this.tabViewModes.indexOf(this.viewMode);
    });

    this.selectedIndex = this.tabViewModes.indexOf(this.viewMode);

    this.elementScrollPercentageService
        .getScrollAsStream() // Defaults to Document if no Element supplied.
        .subscribe((percent: number): void => {
          if (!this.thereIsMore || this.infiniteScrollPercentage >= 100)
            return;
          if (percent > this.infiniteScrollPercentage)
            this.onClickShowMore();
        });

    this.sortIndex$.subscribe(sortIndex => {
      switch (sortIndex) {
        case 'price_asc':
          this.matSortActive = 'lowestPrice';
          this.matSortDirection = 'asc';
          break;
        case'price_desc':
          this.matSortActive = 'highestPrice';
          this.matSortDirection = 'desc';
          break;
        case'creationDate_desc':
          this.matSortActive = 'creationDate';
          this.matSortDirection = 'desc';
          break;
        case'lastEditDate_desc':
          this.matSortActive = 'lastEditDate';
          this.matSortDirection = 'desc';
          break;
        default:
          this.matSortActive = '';
          this.matSortDirection = 'asc';
      }
    });

    this.categoryService.getCategoriesById().then(wrapper => {
      if (wrapper.data)
        this.categoriesById = wrapper.data;
    });

    this.sort?.sort({disableClear: false, id: 'address', start: 'desc'});
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  initTable(): void {
    this.dataSource = new MatTableDataSource(this._listings);
    if (this.sort)
      this.dataSource.sort = this.sort;
  }

  /**
   * Called, when the Show more button is clicked. Emits an event to the parent component to request more listings.
   * Those will - if available - be added to the field listings.
   */
  onClickShowMore(): void {
    this.loadMoreListings.emit(this.moreListingsCount);
  }

  getCountryFromCode(countryCode: string | undefined): string {
    if (!countryCode)
      return '';
    const countryName = this.countryService.getCountryNameByCode(countryCode);
    return countryName ? countryName : countryCode;
  }

  getDistanceInKm(listingCoords: Coordinates, searchCoords: Coordinates): number {
    return Util.getDistanceInKm(listingCoords, searchCoords);
  }

  onTabChange(event: MatTabChangeEvent): void {
    const value = this.tabViewModes[event.index];
    this.viewMode = value;
    this.tabSelectionSubject.next(value);
    if (this.firebaseUser?.uid)
      this.settingsService.setStringValue('listingsViewMode', value, this.firebaseUser.uid);
  }

  /**
   * Called, when a sortable table header is clicked
   * @param sort
   */
  sortData(sort: Sort) {
    this.matSortActive = sort.active;
    this.matSortDirection = sort.direction;
    // Limit the possibilities to lastEditDate_desc, creationDate_desc, price_asc, price_desc
    this.limitSortOptions();
    this.browserService.setSearchSortIndex(ListingsViewComponent.determineSortIndex(this.matSortActive, this.matSortDirection));
  }

  /**
   * Ascertains that a valid sort option is active. If this is not the case, the closest valid one will be selected.
   */
  private limitSortOptions() {

    if (this.matSortActive === 'lowestPrice') {
      this.matSortDirection = 'asc';
      return;
    }
    if (this.matSortActive === 'highestPrice') {
      this.matSortDirection = 'desc';
      return;
    }
    if (this.matSortActive === 'creationDate') {
      this.matSortDirection = 'desc';
      return;
    }
    if (this.matSortActive === 'lastEditDate') {
      this.matSortDirection = 'desc';
      return;
    }

    this.matSortActive = '';
    this.matSortDirection = '';
  }
}
