import {marks, nodes as basicNodes} from 'ngx-editor';
import {MarkSpec, Schema} from 'prosemirror-model';

/**
 * ngx-editor schema (used in the listings edit component).
 * Replaces the italic mark's default <em>-tag with <i>-tags. This is necessary, because <em> is printed with a yellow background
 */
const italic: MarkSpec = {
  content: 'text*',
  marks: '',
  group: 'inline',
  code: true,
  defining: true,
  isolating: true,
  inline: true,
  toDOM() {
    return ['i', 0];
  },
  parseDOM: [{tag: 'i'}],
};

const myMarks = Object.assign({}, marks, {
  em: italic,
});

const schema = new Schema({
  nodes: basicNodes,
  marks: myMarks,
});

export default schema;
