import {Directive, Input} from '@angular/core';
import {RentParams} from '../../models/rentParams.interface';
import {Listing} from '../../models/listing.interface';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
import Util from '../../util';
import {Price} from '../../models/price.interface';
import {BookService} from '../../../book/book.service';
import {BrowserService} from '../../../browser/browser.service';
import {BookingPeriodMap} from '../../models/bookingPeriodMap.interface';
import {MILLIS_PER_DAY} from '../../constants/numbers';

/**
 * This directive is not just a plain validator. It also has plenty of side effects. It determines the rent period, the available days and the invalid dates.
 * These are all stored within the given rent params object. It even sets the booking params (date and time (from and until)) in the book store.
 */
@Directive({
  selector: '[appDateRangePicker]',
  providers: [{provide: NG_VALIDATORS, useExisting: DateRangePickerDirective, multi: true}],
})
export class DateRangePickerDirective implements Validator {
  @Input() appDateRangePicker: {
    rentParams: RentParams, listing?: Listing, availableDayTimestamps: number[], dateRangeInvalidDates: Date[], transactionUid?: string
  } = {rentParams: {}, listing: undefined, availableDayTimestamps: [], dateRangeInvalidDates: []};

  constructor(
    private bookService: BookService,
    private browserService: BrowserService,
  ) {
  }

  /**
   * Calculates the book period from the given date range.
   * @param dateFrom intended book start date
   * @param dateUntil intended book end date
   * @return rentPeriod or undefined, if calculation failed
   */
  public static calcRentPeriod(dateFrom: Date | undefined, dateUntil: Date | undefined): number | undefined {
    if (!(dateFrom instanceof Date && dateUntil instanceof Date))
      return undefined;

    const dateFromTimestamp = dateFrom.getTime();
    const dateUntilTimestamp = dateUntil.getTime();

    const periodInDays = (dateUntilTimestamp - dateFromTimestamp) / MILLIS_PER_DAY;
    if (periodInDays < 1)
      return Math.ceil(periodInDays);
    return Util.ceil(periodInDays, 5);
  }

  /**
   * Finds the minimum book period from the given prices array.
   * @param prices array of prices
   * @return minimum book period or undefined, if it could not be determined
   */
  public static findMinRentPeriod(prices: Price[] | undefined): number | undefined {
    if (!prices || prices.length === 0)
      return 0;
    // Clone the prices and convert them into an array
    let sortedPrices: Price[] = [].slice.call(prices);

    // Determine the minimum book period. Sort the list by minDays ascending
    sortedPrices = sortedPrices?.sort((a, b) => a.minDays - b.minDays);

    return sortedPrices[0].minDays;
  }

  /**
   * Validates the given date range. Checks, if it contains unavailable days. If so, returns true. Otherwise false. All unavailable days are written into
   * this.dateRangeInvalidDates, while validating.
   * If not both dates are set, false is returned (no unavailable days without a real date period).
   * @param dateFrom intended book start date
   * @param dateUntil intended book end date
   * @return true, if date range is invalid, false otherwise
   */
  public static containsUnavailableDays(dateFrom: Date, dateUntil: Date, dateRangeInvalidDates: Date[], availableDayTimestamps: number[]): boolean {
    if (dateFrom === undefined || dateUntil === undefined || dateFrom === null || dateUntil === null || !(dateFrom instanceof Date) ||
      !(dateUntil instanceof Date))
      return false;

    // Clear the invalid dates array
    dateRangeInvalidDates.length = 0;

    // Iterate all dates from start to end
    for (const d = new Date(dateFrom.getTime()); d <= dateUntil; d.setDate(d.getDate() + 1)) {
      const timestamp = Util.getTimestampWithoutTime(d);
      // If the current date is not contained in the list of available days
      if (availableDayTimestamps.indexOf(timestamp) === -1)
        // Add it to the list of invalid dates
        dateRangeInvalidDates.push(new Date(timestamp));
    }
    // If no invalid dates were found, the date range is valid
    return dateRangeInvalidDates.length > 0;
  }

  containsBookings(dateFrom: Date, dateUntil: Date, bookingPeriodMap?: BookingPeriodMap): boolean {
    if (!bookingPeriodMap || !dateFrom || !dateUntil)
      return false;

    for (let entry of Object.entries(bookingPeriodMap)) {
      if (entry[0] === this.appDateRangePicker.transactionUid)
        // Skip the booking range of the current transaction
        continue;
      const bookingFrom = Util.getDate(entry[1].dateFrom);
      const bookingUntil = Util.getDate(entry[1].dateUntil);
      if (Util.isBetweenOrEquals(dateFrom, bookingFrom, bookingUntil) || Util.isBetweenOrEquals(dateUntil, bookingFrom, bookingUntil)) {
        return true;
      }
    }

    return false;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.appDateRangePicker.listing ? this.datePickerValidator(this.appDateRangePicker.rentParams, this.appDateRangePicker.listing)(control)
      : null;
  }

  datePickerValidator(rentParams: RentParams, listing?: Listing): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        let dateFrom = control?.value.dateFrom;
        let dateUntil = control?.value.dateUntil;

        const timeFromString = control.value.timeFrom;
        const timeUntilString = control.value.timeUntil;
        // If there are time inputs
        if (timeFromString && timeUntilString && dateFrom && dateUntil) {
          const timeFromParts = Util.parseTime(timeFromString);
          const timeUntilParts = Util.parseTime(timeUntilString);
          // Set the times in the date objects
          const dateTimeFromUtc: number = dateFrom.setHours(timeFromParts.hours, timeFromParts.minutes, 0, 0);
          const dateTimeFrom = new Date(dateTimeFromUtc);
          const dateTimeUntilUtc: number = dateUntil.setHours(timeUntilParts.hours, timeUntilParts.minutes, 0, 0);
          const dateTimeUntil = new Date(dateTimeUntilUtc);

          rentParams.dateFrom = dateTimeFrom;
          rentParams.dateUntil = dateTimeUntil;
        }
        // console.log('After:', dateFrom, dateUntil);
        const dateTimeFromAfterDateTimeUntil = (dateFrom && dateUntil && dateFrom.getTime() >= dateUntil.getTime());

        // Validation result incompleteDateRange:
        const incompleteDateRange = (dateFrom === undefined || dateUntil === undefined || dateFrom === null || dateUntil === null);
        // Validation result dateRangeContainsUnavailableDays:
        const dateRangeContainsUnavailableDays = DateRangePickerDirective.containsUnavailableDays(dateFrom, dateUntil, this.appDateRangePicker.dateRangeInvalidDates, this.appDateRangePicker.availableDayTimestamps);
        const dateRangeContainsBookings = this.containsBookings(dateFrom, dateUntil, this.appDateRangePicker.listing?.bookingPeriodMap);

        const rentPeriod = DateRangePickerDirective.calcRentPeriod(dateFrom, dateUntil);
        const minimumRentPeriod = DateRangePickerDirective.findMinRentPeriod(listing?.prices);
        // Validation result dateRangeTooShort:
        const dateRangeTooShort = minimumRentPeriod !== undefined && rentPeriod !== undefined && rentPeriod < minimumRentPeriod;

        const bestPrice = Util.findBestRentPrice(listing?.prices, rentPeriod);
        rentParams.price = bestPrice;
        rentParams.rentPeriod = rentPeriod;
        rentParams.minimumRentPeriod = minimumRentPeriod;
        rentParams.periodPrice = rentPeriod && bestPrice ? bestPrice.pricePerDay * rentPeriod : 0;

        return (dateRangeTooShort || dateRangeContainsUnavailableDays || incompleteDateRange || dateTimeFromAfterDateTimeUntil || dateRangeContainsBookings) ? {
          dateRangeTooShort,
          dateRangeContainsUnavailableDays,
          incompleteDateRange,
          dateTimeFromAfterDateTimeUntil,
          dateRangeContainsBookings,
        } : null;
      }
      return null;
    };
  }
}
