import {BrowserService} from '../../browser.service';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Search} from '../../../shared/models/search.interface';
import {environment} from '../../../../environments/environment';
import {Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {Category} from '../../../shared/models/category.interface';
import {SharedService} from '../../../shared/services/shared.service';
import {takeUntil} from 'rxjs/operators';
import {CurrencyService} from '../../../shared/services/currency.service';
import {CategoryService} from '../../../shared/services/category.service';
import {MatSelectChange} from '@angular/material/select';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import {User} from '../../../shared/models/user.interface';
import {Coordinates} from '../../../shared/models/coordinates.interface';
import {CountryService} from '../../../shared/services/country.service';
import {MapsAPILoader} from '@agm/core';
import {GeoService} from '../../../shared/services/geo.service';
import {BaseComponent} from '../../../shared/components/base/base.component';
import Util from '../../../shared/util';
import firebase from 'firebase/app';
import {MILLIS_PER_DAY} from '../../../shared/constants/numbers';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {selectSearch} from '../../store/browser.selectors';
import GeocoderResult = google.maps.GeocoderResult;
import GeocoderStatus = google.maps.GeocoderStatus;
import Timestamp = firebase.firestore.Timestamp;


@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.css'],
})
export class SearchPanelComponent extends BaseComponent implements OnInit {

  @Input() navigateToCategoryOnCategorySelection = false;
  @Input() navigateToSearchPage = true;

  searchForm?: FormGroup;
  @Input() category?: Category;

  address?: string;
  formattedAddress?: string;
  /* Date range picker */
  minDate = new Date();
  rangeMaxValue = 150;
  @Input() styleClass = 'row jumbotron px-0 mx-1 mt-1 py-2';

  map?: google.maps.Map;
  @ViewChild('location') public locationElementRef?: ElementRef;
  @Input() sortIndex?: string;
  private coords?: Coordinates;
  private notYetSearched = true;

  search$ = this.store.select(selectSearch).pipe(takeUntil(this.destroy$));

  constructor(private userService: UserService,
              protected store: Store<AppState>,
              private zone: NgZone,
              private mapsAPILoader: MapsAPILoader,
              private geoService: GeoService,
              private browserService: BrowserService,
              public sharedService: SharedService,
              public currencyService: CurrencyService,
              public categoryService: CategoryService,
              private countryService: CountryService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.searchForm = this.createForm();

    this.searchForm?.patchValue({order: this.sortIndex});

    this.search$.subscribe((search) => this.onSearchLoadedFromStore(search),
        (error) => console.error(error));

    // Load the logged in user's address
    this.user$.subscribe((user) => {
      this.onFirestoreUserLoaded(user);
    });

    this.initializeAddressBarAutoComplete();

    this.browserService.selectedCategorySubject.pipe(takeUntil(this.destroy$)).subscribe(category => {
      if (this.category?.id !== category?.id) {
        this.category = category;
        this.onSubmit();
      }
    });
  }

  createForm(): FormGroup {
    return new FormGroup({
          searchTerm: new FormControl(null),
          location: new FormControl(null),
          radius: new FormControl('20', [Validators.min(1)]),
          dateFrom: new FormControl(null),
          dateUntil: new FormControl(null),
          order: new FormControl('bestMatch'),
        },
        this.datePickerValidator().bind(this));
  }

  datePickerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const dateFrom = control?.value.dateFrom;
        const dateUntil = control?.value.dateUntil;
        const dateRangeNotComplete = !dateFrom || !dateUntil;

        let reversePeriod = false;
        let dateInThePast = false;
        if (!dateRangeNotComplete) {
          const todayUtc = Util.getDateWithoutTime(new Date()).getTime();

          const dateFromUtc = dateFrom.getTime();
          const dateUntilUtc = dateUntil.getTime();
          reversePeriod = dateFromUtc > dateUntilUtc;
          dateInThePast = dateFromUtc < todayUtc || dateUntilUtc < todayUtc;

          // Minimum rent period is one day
          const periodInDays = Math.max(1, Math.round((dateUntilUtc - dateFromUtc) / MILLIS_PER_DAY));
          this.browserService.periodInDaysSubject.next(periodInDays);
        }
        // dateRangeNotComplete is not treated as an error. It should be possible to search without a date. Otherwise, it would just be annoying
        return (reversePeriod || dateInThePast) ? {
          reversePeriod,
          dateInThePast,
        } : null;
      }
      return null;
    };
  }

  onSubmit(): void {
    const searchTerm = this.searchForm?.value.searchTerm;
    const location = this.searchForm?.value.location;
    const radius = this.searchForm?.value.radius;
    const dateFrom = this.searchForm?.value.dateFrom;
    const dateUntil = this.searchForm?.value.dateUntil;
    const sortIndex: string = this.browserService.translateOrder(this.searchForm?.value.order);
    this.sortIndex = sortIndex;
    const search: Search = {
      searchTerm,
      dateFrom,
      dateUntil,
      categoryId: this.category?.id,
      sortIndex,
      limit: environment.defaultSearchListingsCount,
      lenderUid: undefined,
    };
    if (location) {
      search.coords = this.coords;
      search.radius = radius;
      search.location = location;
    }
    this.browserService.fetchSearchResults(search);
  }


  syncSearchTerm(): void {
    const searchTerm = this.searchForm?.value.searchTerm;
    if (this.navigateToSearchPage)
      this.browserService.navigateToSearch();
    this.browserService.updateSearchTerm(searchTerm);
  }

  resetSearchAndSyncSearchTerm(): void {
    // this.browserService.resetSearch();
    this.syncSearchTerm();
  }

  onCategoryChange(cat: Category): void {
    this.category = cat;
    this.browserService.setSearchCategory(cat?.id);

    if (!cat) {
      if (this.navigateToCategoryOnCategorySelection)
        this.browserService.navigateToSearch();
      return this.browserService.selectedCategorySubject.next(undefined);
    }

    if (this.navigateToCategoryOnCategorySelection)
      this.router.navigate(['browse', 'category', cat.id]);
    this.browserService.runCurrentSearch();
    return this.browserService.selectedCategorySubject.next(cat);

  }

  onOrderChange($event: MatSelectChange) {
    this.onSubmit();
  }

  loadCurrentLocation(): void {
    console.log('Loading current position...');

    // If possible (if the user gives permission), use the current location
    this.geoService.getCurrentLocation(
        (position) => {
          this.coords = {lat: position.coords.latitude, lng: position.coords.longitude};
          this.browserService.coordsSubject.next(this.coords);

          // Also determine the address based on those coordinates
          this.geoService.getAddress(this.coords.lat, this.coords.lng, (results: GeocoderResult[], status: GeocoderStatus) => {
            if (results && results[0]) {
              this.searchForm?.patchValue({location: results[0].formatted_address});
              this.formattedAddress = results[0].formatted_address;
            }
          });
        },
        positionError => {
          console.log(positionError);
          if (positionError.code === 1) {
            // User denied GeoLocation
            this.addError($localize`We could not determine your current location, because you didn't give us permission to access it.`);
          }
          // As a fallback, use the user account's location
          this.coords = this.user?.address?.coords;
          this.browserService.coordsSubject.next(this.coords);
          this.address = this.user?.address?.formattedAddress;
          this.formattedAddress = this.address;
          this.searchForm?.patchValue({location: this.formattedAddress});
        },
    );
  }

  onDateRangeChanged(date: Date) {
    // Note: Don't call browserService.setDateAndTimePeriod(...) here, because it will cause the date picker to be closed
  }

  /**
   * Called, when the firestore user has been loaded.
   *
   * @param user
   * @private
   */
  private onFirestoreUserLoaded(user?: User) {
    this.user = user;
  }

  /**
   * Called, when a browser state was loaded from the store. Prefills the search panel.
   * @param state state from browser reducer
   */
  private onSearchLoadedFromStore(search: Search) {

    if (search) {
      // Search term
      const searchTerm = search.searchTerm;
      this.searchForm?.patchValue({searchTerm});

      // Location
      const location = search.location;
      if (search.coords)
        this.coords = search.coords;
      this.browserService.coordsSubject.next(this.coords);
      if (!this.searchForm?.value?.location)
        this.searchForm?.patchValue({location});

      // Radius
      const radius = search.radius;
      if (!this.searchForm?.value?.radius)
        this.searchForm?.patchValue({radius});

      // Date from and date until
      const nowUtc = Timestamp.now().toMillis();
      let dateFrom = search.dateFrom;
      dateFrom = dateFrom && dateFrom.getTime() < nowUtc ? new Date(nowUtc) : dateFrom;
      this.searchForm?.patchValue({dateFrom});
      let dateUntil = search.dateUntil;
      dateUntil = dateUntil && dateUntil.getTime() < nowUtc ? new Date(nowUtc + 86400000) : dateUntil;
      this.searchForm?.patchValue({dateUntil});

      // Category
      const categoryId = search.categoryId;
      if (!this.category && categoryId)
        this.categoryService.getCategoriesById().then(wrapper => {
          if (wrapper.errorMessage)
            console.log($localize`Error loading category\: ${wrapper.errorMessage}`);
          this.category = wrapper.data?.get(categoryId);
        });

      // Sort index
      const sortIndex = search.sortIndex;
      if (!this.searchForm?.value?.order)
        this.searchForm?.patchValue({order: sortIndex});
      // Compare with old value. If it has been changed, trigger a new search
      if (this.sortIndex !== sortIndex) {
        // Trigger the search
        this.onSubmit();
        // And also save the new value for further comparisons
        this.sortIndex = sortIndex;
      }
    }
  }

  /**
   * Initializes the address bar auto complete. Note: This must not be done too early. At the time of calling this function, the address bar must be visible on
   * the DOM. This is not the case, if firebaseUser is false or fetchListing is true.
   */
  private initializeAddressBarAutoComplete(): void {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

      if (!this.locationElementRef)
        return;

      const autocomplete = new google.maps.places.Autocomplete(this.locationElementRef.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.zone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.coords = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
          this.browserService.coordsSubject.next(this.coords);
          this.address = place.formatted_address;
          if (place.formatted_address) {
            this.searchForm?.patchValue({location: place.formatted_address});
            this.formattedAddress = place.formatted_address;
          }
        });
      });
    });
  }
}
