import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {Category} from '../../../../shared/models/category.interface';
import {UserService} from '../../../../shared/services/user.service';
import {CategoryService} from '../../../../shared/services/category.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.reducer';

@Component({
  selector: 'app-all-categories-inner-card-group',
  templateUrl: './all-categories-inner-card-group.component.html',
  styleUrls: ['./all-categories-inner-card-group.component.scss'],
})
export class AllCategoriesInnerCardGroupComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() categories: Category[] = [];

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              public categoryService: CategoryService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


}
