import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss'],
})
export class YesNoComponent implements OnInit {

  @Input() value?: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
