import {Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {GeoService} from '../../services/geo.service';
import {Coordinates} from '../../models/coordinates.interface';
import {Address} from '../../models/address.interface';
import {Subject} from 'rxjs';
import Geohash from 'latlon-geohash';
import MapsEventListener = google.maps.MapsEventListener;
import GeocoderStatus = google.maps.GeocoderStatus;
import GeocoderResult = google.maps.GeocoderResult;

/**
 * Map component to show a google map with a marker and / or a polygon. To show a simple rectangle, use:
 *
 * // Reset the map to paint new polygon
 * this.showMap = false;
 * this.mapPolygon.push({lat: 48.128, lng: 11.682});
 * this.mapPolygon.push({lat: 48.128, lng: 11.686});
 * this.mapPolygon.push({lat: 48.133, lng: 11.686});
 * this.mapPolygon.push({lat: 48.133, lng: 11.682});
 * this.mapPolygon.push({lat: 48.128, lng: 11.682}); // last coord equals first one
 * this.showMap = true;
 */
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnInit, OnDestroy {
  destroy$: Subject<null> = new Subject();

  @Input() lat: number = 0;
  @Input() lng: number = 0;
  @Input() zoom = 16;
  @Output() coords = new EventEmitter<Coordinates>();
  @Input() disabled = false;
  map: google.maps.Map | undefined;
  mapClickListener: MapsEventListener | undefined;
  @Output() geocoderResult = new EventEmitter<Address>();
  address: string | undefined;
  @ViewChild('search')
  public searchElementRef: ElementRef | undefined;
  markerDraggable: boolean = true;
  @Input() showMap = true;
  @Input() showMarker = true;

  constructor(private zone: NgZone,
              private mapsAPILoader: MapsAPILoader,
              private geoService: GeoService) {
  }

  _geohash?: string;

  get geohash(): string | undefined {
    return this._geohash;
  }

  @Input() set geohash(geohash: string | undefined) {
    this._geohash = geohash;
    if (!geohash)
      return;
    const center = Geohash.decode(geohash);
    this.lat = center.lat;
    this.lng = center.lon;
    const bounds = Geohash.bounds(geohash);
    const newPolygon: Coordinates[] = [];
    newPolygon.push({lat: bounds.sw.lat, lng: bounds.sw.lon});
    newPolygon.push({lat: bounds.sw.lat, lng: bounds.ne.lon});
    newPolygon.push({lat: bounds.ne.lat, lng: bounds.ne.lon});
    newPolygon.push({lat: bounds.ne.lat, lng: bounds.sw.lon});
    newPolygon.push({lat: bounds.sw.lat, lng: bounds.sw.lon}); // last coord equals first one
    this.polygon = newPolygon;
  }

  _polygon?: Coordinates[];

  get polygon(): Coordinates[] | undefined {
    return this._polygon;
  }

  @Input() set polygon(polygon: Coordinates[] | undefined) {
    // Disable and re-enable the map to redraw the polygon
    this.showMap = false;
    this._polygon = polygon;
    this.showMap = true;
  }

  ngOnInit() {
    this.markerDraggable = !this.disabled;
    //load Places Autocomplete
    this.mapsAPILoader.load().then(r => {
    });
  }

  public mapReadyHandler(map: google.maps.Map): void {
    this.map = map;
    if (!this.disabled)
      this.mapClickListener = this.map?.addListener('click', (e: google.maps.MouseEvent) => {
        this.zone.run(() => {
          this.onSetNewLocation({lat: e.latLng.lat(), lng: e.latLng.lng()});
          // console.log(new LatLng(50, 50));
        });
      });
  }

  public ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
    this.destroy$.next(null);
  }

  onMarkerDragEnd(e: google.maps.MouseEvent) {
    if (!this.disabled)
      this.onSetNewLocation({lat: e.latLng.lat(), lng: e.latLng.lng()});
  }

  onSetNewLocation(coords: Coordinates, getAddress: boolean = true) {
    this.coords.emit(coords);
    this.map?.panTo(coords);
    // Use set timeout to set the new lat/lng. Otherwise, the animation from panTo will be cancelled
    setTimeout(() => {
      this.lat = coords.lat;
      this.lng = coords.lng;
    }, 500);

    if (getAddress)
      this.geoService.getAddress(coords.lat, coords.lng, (results: GeocoderResult[], status: GeocoderStatus) => {
        if (status === 'OK') {
          this.address = results[0].formatted_address;
          if (results[0].address_components) {
            const address: Address = {
              ...this.geoService.convertGeocoderAddressComponents(results[0].address_components),
              formattedAddress: results[0].formatted_address,
              coords,
            };
            this.geocoderResult.emit(address);
          }

        }
        // results.
        else
          throw new Error(`Geocoder failed due to: ${status}`);
      });
  }


}
