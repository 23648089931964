import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserPublic} from '../../../shared/models/userPublic.interface';
import {Report} from '../../../shared/models/report.interface';
import {Listing} from '../../../shared/models/listing.interface';
import {UserService} from '../../../shared/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BookService} from '../../../book/book.service';
import {ListingService} from '../../../listing/listing.service';
import {TitleService} from '../../../shared/services/title.service';
import {map, takeUntil} from 'rxjs/operators';
import {Rating} from '../../../shared/models/rating.interface';
import {SocialService} from '../../../social/social.service';
import {GENERAL_REPORT_CATEGORIES, LISTING_REPORT_CATEGORIES, RATING_REPORT_CATEGORIES, ReportCategory} from '../../../shared/models/reportCategory.interface';
import {UtilService} from '../../../shared/util.service';
import {NgCacheRouteReuseService} from 'ng-cache-route-reuse';
import {
  CANNOT_BE_UNDONE,
  DISABLE_LISTING_CONFIRMATION_MESSAGE,
  DISABLE_LISTING_CONFIRMATION_TITLE,
  ENABLE_LISTING_CONFIRMATION_MESSAGE,
  ENABLE_LISTING_CONFIRMATION_TITLE,
} from '../../../shared/constants/strings';
import {
  ActionType,
  ConfirmSendMessageDialogComponent,
  ConfirmSendMessageDialogModel,
} from '../confirm-send-message-dialog/confirm-send-message-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss'],
})
export class ReportViewComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  receiver?: UserPublic;
  reporter?: UserPublic;
  report ?: Report;
  listing ?: Listing;
  rating ?: Rating;
  reportCategory?: ReportCategory;

  showWriteMessageComponent = false;

  private readonly spinnerKeyLoadReport = 'loadReport';
  private readonly spinnerKeyLoadReceiver = 'loadReceiver';
  private readonly spinnerKeyLoadReporter = 'loadReporter';
  private readonly spinnerKeyLoadListing = 'loadListing';
  private readonly spinnerKeyLoadRating = 'loadRating';

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private bookService: BookService,
      private socialService: SocialService,
      public utilService: UtilService,
      private dialog: MatDialog,
      private cdRef: ChangeDetectorRef,
      private cacheRouteReuseService: NgCacheRouteReuseService,
      private listingService: ListingService,
      private titleService: TitleService) {
    super(store);
  }

  public static determineReportCategory(categoryId: string): ReportCategory | undefined {
    const allCategories: ReportCategory[] = [...GENERAL_REPORT_CATEGORIES, ...LISTING_REPORT_CATEGORIES, ...RATING_REPORT_CATEGORIES];
    return allCategories.find(repCat => repCat.id === categoryId);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setTitle();
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).pipe(map(params => {
          const reportUid = params.get('reportUid');
          if (reportUid === null || reportUid === undefined) {
            throw new Error('There is no reportUid.');
          }
          return reportUid;
        }),
    )
        .subscribe((reportUid: string) => {
              this.loadReport(reportUid);
            },
        );


    this.cacheRouteReuseService
        .onAttach(ReportViewComponent)
        .subscribe((component) => {
          this.setTitle();
          this.clearAlerts();
        });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  userIsReporter(): boolean {
    return this.report?.reporterUid === this.user?.uid;
  }

  userIsReceiver(): boolean {
    switch (this.report?.type) {
      case 'listing': {
        return this.listing?.lenderUid === this.user?.uid;
      }
      case 'rating': {
        return this.rating?.receiverUid === this.user?.uid;
      }
    }
    return this.receiver?.uid === this.user?.uid;
  }

  onSendMessage(): void {
    this.showWriteMessageComponent = true;
    this.utilService.scrollToId('write-message');
  }

  onEnableListing(): void {
    this.clearAlerts();
    this.showMessageDialog(ENABLE_LISTING_CONFIRMATION_TITLE, ENABLE_LISTING_CONFIRMATION_MESSAGE, 'enableListing', this.enableListing.bind(this));
  }

  onDisableListing(): void {
    this.clearAlerts();
    this.showMessageDialog(DISABLE_LISTING_CONFIRMATION_TITLE, DISABLE_LISTING_CONFIRMATION_MESSAGE, 'disableListing', this.disableListing.bind(this));
  }

  onDeleteListing(): void {
    this.clearAlerts();
    this.showMessageDialog($localize`Do you really want to delete this listing?`, CANNOT_BE_UNDONE, 'deleteListing', this.deleteListing.bind(this));
  }

  onDeleteReport(): void {
    this.clearAlerts();
    this.utilService.showConfirmDialog($localize`Do you really want to delete this report?`, CANNOT_BE_UNDONE, this.deleteReport.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  onDeleteAllReportsForReference(): void {
    this.clearAlerts();
    this.utilService.showConfirmDialog($localize`Do you really want to delete all reports for this reference?`, CANNOT_BE_UNDONE, this.deleteAllReportsForReference.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  onDeleteRating(): void {
    this.clearAlerts();
    this.showMessageDialog($localize`Do you really want to delete this rating?`, CANNOT_BE_UNDONE, 'deleteRating', this.deleteRating.bind(this));
  }

  enableListing(): void {
    if (!this?.listing?.uid)
      return;
    this.listingService.enableOrDisableListing(this.listing, false, () => {
          this.addSuccess($localize`The listing was enabled.`);
          this.listing = {...this.listing, disabled: false};
        },
        error => this.addError($localize`Enabling listing failed\: ${error}`));
  }

  disableListing(): void {
    if (!this?.listing?.uid)
      return;
    this.listingService.enableOrDisableListing(this.listing, true, () => {
          this.addSuccess($localize`The listing was disabled.`);
          this.listing = {...this.listing, disabled: true};
        },
        error => this.addError($localize`Disabling listing failed\: ${error}`));
  }

  deleteListing(): void {
    if (!this?.listing?.uid)
      return;
    this.listingService.deleteListing(this.listing.uid).then(() => {
          this.addSuccess($localize`The listing was deleted.`);
          if (this.listing!.uid)
            this.socialService.onListingDeletedSubject$.next(this.listing!.uid);
          this.listing = undefined;
          this.utilService.showConfirmDialog($localize`Do you want to delete all reports for this listing?`, CANNOT_BE_UNDONE, this.deleteAllReportsForReference.bind(this), undefined, undefined, undefined, undefined, 'no');
        },
        error => this.addError($localize`Deleting listing failed\: ${error}`));
  }


  deleteReport(): void {
    if (!this.report?.uid)
      return;
    this.socialService.deleteReport(this.report.uid).then(() => {
          this.addSuccess($localize`The report was deleted.`);
          if (this.getRefUid())
            this.socialService.onReportDeletedSubject$.next({reportUid: this.report!.uid!, refUid: this.getRefUid()!});
          this.report = undefined;
          this.router.navigate(['..'], {relativeTo: this.activatedRoute});
        },
        error => this.addError($localize`Deleting report failed\: ${error}`));
  }

  deleteAllReportsForReference(): void {
    if (!this.report) {
      this.addError($localize`This report does no longer exist. Deletion not possible.`);
      return;
    }

    const refUid = this.getRefUid();
    this.socialService.deleteAllReports(count => {
      this.addSuccess($localize`${count} reports were deleted.`);

      if (refUid)
        this.socialService.onAllReportsDeletedSubject$.next(refUid);
      this.router.navigate(['..'], {relativeTo: this.activatedRoute});
    }, error => {
      this.addError($localize`Report could not be deleted\: ${error}`);
    }, this.report.type, refUid);
  }

  deleteRating(): void {
    if (!this?.rating?.uid)
      return;
    this.bookService.deleteRating(this.rating.uid).then(() => {
          this.addSuccess($localize`The rating was deleted.`);
          // Note: the transaction is updated automatically by a cloud function removing the ratingUid
          if (this.rating!.uid)
            this.socialService.onRatingDeletedSubject$.next(this.rating!.uid);
          this.rating = undefined;
          this.utilService.showConfirmDialog($localize`Do you want to delete all reports for this rating?`, CANNOT_BE_UNDONE, this.deleteAllReportsForReference.bind(this), undefined, undefined, undefined, undefined, 'no');
        },
        error => this.addError($localize`Deleting rating failed\: ${error}`));
  }

  showMessageDialog(title: string, message: string, actionType: ActionType, callback: () => void): void {
    this.clearAlerts();
    const dialogData = new ConfirmSendMessageDialogModel(title, message, this.listing, this.rating, this.report?.categoryId, this.receiver?.uid, actionType, this.report?.type, this.rating?.transactionUid);
    const dialogRef = this.dialog.open(ConfirmSendMessageDialogComponent, {minWidth: '300px', width: '600px', maxWidth: '1000px', data: dialogData});
    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult)
        callback();
    });
  }

  private setTitle() {
    this.titleService.setTitle($localize`Report`);
  }

  private loadReport(reportUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyLoadReport, $localize`Fetching report...`);
    this.socialService.fetchReport(reportUid).then(wrapper => {
          this.removeLoadingSpinnerMessage(this.spinnerKeyLoadReport);
          if (wrapper.data) {
            this.report = wrapper.data;
            switch (this.report.type) {
              case 'listing':
                if (this.report.listingUid)
                  this.loadListing(this.report.listingUid);
                break;
              case 'rating':
                if (this.report.ratingUid)
                  this.loadRating(this.report.ratingUid);
            }
            this.loadReporter(this.report.reporterUid);
            this.reportCategory = ReportViewComponent.determineReportCategory(this.report.categoryId);
          }
          if (wrapper.errorMessage)
            this.addError($localize`Error loading report\: ${wrapper.errorMessage}`);
          if (!wrapper.data && !wrapper.errorMessage)
            this.addError($localize`No report could be found for ID '${reportUid}'. Please check your URL.`);
        },
    );
  }

  private loadReceiver(receiverUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyLoadReceiver, $localize`Fetching receiver...`);
    this.userService.fetchUserPublic(receiverUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyLoadReceiver);
      if (wrapper.data) {
        this.receiver = wrapper.data;
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading receiver\: ${wrapper.errorMessage}`);
    });
  }

  private loadReporter(reporterUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyLoadReporter, $localize`Fetching reporter...`);
    this.userService.fetchUserPublic(reporterUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyLoadReporter);
      if (wrapper.data) {
        this.reporter = wrapper.data;
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading reporter\: ${wrapper.errorMessage}`);
    });
  }

  private loadListing(listingUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyLoadListing, $localize`Fetching listing...`);
    this.listingService.fetchListing(listingUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyLoadListing);
      if (wrapper.data) {
        this.listing = wrapper.data;
        if (this.listing.lenderUid)
          this.loadReceiver(this.listing.lenderUid);
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading listing\: ${wrapper.errorMessage}`);
    });
  }

  private loadRating(ratingUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyLoadRating, $localize`Fetching rating...`);
    this.bookService.fetchRating(ratingUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyLoadRating);
      if (wrapper.data) {
        this.rating = wrapper.data;
        if (this.rating.receiverUid)
          this.loadReceiver(this.rating.receiverUid);
      }
      if (wrapper.errorMessage)
        this.addError($localize`Error loading rating\: ${wrapper.errorMessage}`);
    });
  }

  private getRefUid(): string | undefined {
    if (!this.report)
      return undefined;

    switch (this.report.type) {
      case 'listing':
        return this.report.listingUid;
        break;
      case 'rating':
        return this.report.ratingUid;
      default:
        return undefined;
    }
  }

}

