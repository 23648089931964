<app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<form [formGroup]="form">

  <mat-form-field class="mat-form-input">
    <mat-label>ID</mat-label>
    <input [value]="announcement?.uid" disabled i18n-placeholder="UID" matInput placeholder="UID">
  </mat-form-field>
  <br>
  <mat-form-field class="mat-form-textarea-w-100">
    <mat-label i18n="message">Message</mat-label>
    <textarea #messageRef
              [maxLength]="messageMaxLength"
              cdkAutosizeMaxRows="25" cdkAutosizeMinRows="2"
              cdkTextareaAutosize formControlName="message"
              i18n-placeholder="placeholder text in announcements editor" matInput
              placeholder="Type here to enter the announcement text." tabindex="3"></textarea>
    <span class="character-counter">{{messageRef.value.length}}/{{messageMaxLength}}</span>
  </mat-form-field>

  <mat-form-field>
    <mat-label i18n="environment">Environment</mat-label>
    <mat-select #environmentRef formControlName="environment" id="environment" panelClass="mat-select-panel-500px">
      <mat-option value="all">All</mat-option>
      <mat-option value="prod_only">Only on production</mat-option>
      <mat-option value="non_prod_only">Only on non-production</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label i18n="priority">Priority</mat-label>
    <mat-select #priorityRef formControlName="priority" id="priority" panelClass="mat-select-panel-500px">
      <mat-option *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" [value]="i">{{i}}</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field>
    <mat-label i18n>Valid from</mat-label>
    <input [matDatepicker]="validFrom" formControlName="validFrom" matInput>
    <mat-datepicker-toggle [for]="validFrom" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #validFrom></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-label i18n>Valid until</mat-label>
    <input [matDatepicker]="validUntil" formControlName="validUntil" matInput>
    <mat-datepicker-toggle [for]="validUntil" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #validUntil></mat-datepicker>
  </mat-form-field>
  <br>
  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="false" [message]="loadingSpinnerMessage"
                       [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>
  <app-mat-icon-button [callback]="save.bind(this)" [disabled]="!form.valid" [label]="announcement?.uid? 'Save' : 'Create'" color="primary" i18n-label="save"
                       icon="save"></app-mat-icon-button>
</form>
