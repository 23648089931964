import {Injectable, OnDestroy} from '@angular/core';
import {Category, CatLangStrings, CatLangStringsMap} from '../models/category.interface';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import {takeUntil} from 'rxjs/operators';

import {Wrapper} from '../models/wrapper.model';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {convertToCategory} from '../converters/modelConverters';
import {clearCategoriesCache, setCategories} from '../store/shared.actions';
import {firestore} from '../../app.module';
import Locale from './locale';
import {CategoriesWrapper, selectCategories} from '../store/shared.selectors';


@Injectable({
  providedIn: 'root',
})
export class CategoryService implements OnDestroy {
  destroy$: Subject<null> = new Subject();

  categories: Category[] = [];
  categoriesById: Map<string, Category> = new Map<string, Category>();
  private lastFetch?: Date;

  categories$ = this.store.select(selectCategories).pipe(takeUntil(this.destroy$));

  constructor(private store: Store<fromApp.AppState>) {
    // Note: ngOnInit is not called in a service class
    this.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }


  /**
   * Delivers all categories (as a tree) either from the cache, if there is an up-to-date cache, or from the firestore.
   */
  async getCategories(): Promise<Wrapper<Category[]>> {
    if (!this.categories || this.categories.length === 0 || this.cacheIsOutdated()) {
      // Fetch live categories
      const wrapper = await this.fetchCategories();
      if (wrapper.errorMessage) {
        console.log(`Error loading categories: ${wrapper.errorMessage}`);
      }
      if (wrapper.data) {
        this.categories = wrapper.data;
        this.store.dispatch(setCategories({categories: this.categories, categoriesFetchDate: new Date()}));
      }
      return wrapper;
    }

    // Return categories from cache
    const cacheWrapper: Wrapper<Category[]> = {data: this.categories};
    return cacheWrapper;
  }

  /**
   * Delivers all categories as a linear map either from the cache, if there is an up-to-date cache, or from the firestore.
   */
  async getCategoriesById(): Promise<Wrapper<Map<string, Category>>> {
    if (!this.categoriesById || this.categoriesById.size === 0 || this.cacheIsOutdated()) {
      // Fetch live categories
      const wrapper = await this.getCategories();
      if (wrapper.data) {
        this.categoriesById = this.createCategoriesMap(this.getCategoriesLinear(wrapper.data));
        return {data: this.categoriesById};
      } else if (wrapper.errorMessage)
        return {errorMessage: wrapper.errorMessage};
    }

    // Return categories from cache
    const cacheWrapper: Wrapper<Map<string, Category>> = {data: this.categoriesById};
    return cacheWrapper;
  }

  /**
   * Delivers the category lang string name from the given category. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param category category, from and for which the lang strings should be delivered
   * @param indentationChar character(s) to be added before the category name as a prefix (category.level) times
   * @return language string name
   */
  getCategoryName(category: Category, indentationChar?: string): string {
    let indentation = '';
    if (indentationChar)
      for (let i = 0; i < category.level; i++)
        indentation += indentationChar;
    const strings = this.getCategoryLangStrings(category);
    return indentation + strings.name;
  }

  /**
   * Delivers the category lang string elementName from the given category. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param category category, from and for which the lang strings should be delivered
   * @return language string elementName
   */
  getCategoryElementName(category: Category): string {
    const strings = this.getCategoryLangStrings(category);
    return strings.elementName;
  }

  /**
   * Delivers the category lang string description from the given category. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param category category, from and for which the lang strings should be delivered
   * @return language string description. If there is none, an empty string is returned
   */
  getCategoryDescription(category: Category): string {
    const strings = this.getCategoryLangStrings(category);
    return strings.description ? strings.description : '';
  }

  /**
   * Delivers the category lang strings (name, elementName, description) from the given category. If the globally set locale, environment.firestoreLocale,
   * is not available, the fallback environment.defaultFirestoreLocale is used.
   * @param category category, from and for which the lang strings should be delivered
   * @return language strings
   */
  getCategoryLangStrings(category: Category): CatLangStrings {
    const strings = category.strings[Locale.firestoreLocale()];
    return strings ? strings : category.strings[environment.defaultFirestoreLocale];
  }

  /**
   * Delivers an array of subcategories for the given category
   * @param category category, whose subcategories should be returned
   * @return subcategories array
   */
  getSubcategories(category: Category): Category[] {
    if (category?.subcategories)
      return Object.values(category.subcategories);
    return [];
  }

  /**
   * Converts the given array of categories into a linear array, which contains all categories and all of their nested subcategories.
   * @param categories list of categories, which can contain multiple levels of nested subcategories.
   * @return array of linear categories. The categories in this array can still contain subcategories, but those will also be on the root level,
   * so the subcategories can be neglected.
   */
  getCategoriesLinear(categories: Category[]): Category[] {
    const categoriesLinear: Category[] = [];
    this.getCategoriesLinearRecursive(categories, categoriesLinear, 0);

    return categoriesLinear;
  }

  /**
   * Delivers the category with the given ID from the given array of categories. The array can contain nested arrays.
   * @param id category ID
   * @param categories array of categories to be searched
   * @return wanted category or undefined, if not found
   */
  getCategoryRecursive(id: string, categories: Category[]): Category | undefined {
    for (const category of categories) {
      if (category.id === id)
        return category;
      if (category.subcategories !== undefined && Object.values(category.subcategories).length > 0) {
        const categoryRecursive = this.getCategoryRecursive(id, Object.values(category.subcategories));
        if (categoryRecursive !== undefined)
          return categoryRecursive;
      }
    }

    return undefined;
  }

  /**
   * Delivers the category path leading to the category with the given ID from the given array of categories. The array can contain nested arrays.
   * Note: The path is not returned, but instead the path param gets altered.
   *
   * @param id category ID
   * @param categories array of categories to be searched
   * @param path the path, which lead up to this point in the recursive function callstack. This value gets altered by this function.
   * In the end, the last element
   * of the path is the wanted category with the given ID.
   * @return  the wanted category. If the given ID is not found, undefined is returned.
   */
  getCategoriesPathRecursive(id: string, categories: Category[], path: Category[]): Category | undefined {
    for (const category of categories) {
      // Add the current category to the path
      path.push(category);
      if (category.id === id)
        return category;
      if (category.subcategories !== undefined && Object.values(category.subcategories).length > 0) {
        const categoryRecursive = this.getCategoriesPathRecursive(id, Object.values(category.subcategories), path);
        if (categoryRecursive !== undefined)
          return categoryRecursive;
      }
      // Since this was the wrong path, remove the last element
      path.pop();
    }

    return undefined;
  }

  init(): void {
    this.categories$.subscribe((wrapper: CategoriesWrapper) => {
      if (wrapper.categories) {
        this.categories = wrapper.categories;
      }
      if (wrapper.categoriesFetchDate)
        this.lastFetch = wrapper.categoriesFetchDate;
    });
  }

  /**
   * Fetches categories directly from the firestore
   */
  async fetchCategories(): Promise<Wrapper<Category[]>> {
    try {
      const query = firestore.collection(environment.firestoreCollectionCategories).withConverter(categoryConverter);
      const categoryQuerySnapshot = await query.get();
      const categories: Category[] = [];
      categoryQuerySnapshot.forEach(categoryDocSnapshot => {
        const category: Category = convertToCategory(categoryDocSnapshot.data());
        categories.push(category);
      });

      const lastVisible = categoryQuerySnapshot.docs[categoryQuerySnapshot.docs.length - 1];

      return new Wrapper<Category[]>(categories, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Category[]>(undefined, $localize`You are not allowed to view categories.`);
      return new Wrapper<Category[]>(undefined, e.message);
    }
  }

  resetCache() {
    this.categoriesById.clear();
    this.store.dispatch(clearCategoriesCache());
  }

  setCategories(categories: Category[]) {
    this.categories = categories;
    this.categoriesById = this.createCategoriesMap(this.getCategoriesLinear(categories));
    this.store.dispatch(setCategories({categories: this.categories, categoriesFetchDate: new Date()}));
  }

  private cacheIsOutdated() {
    return !this.lastFetch || (new Date().getTime() - this.lastFetch.getTime() > environment.defaultCategoryCacheAgeInSec * 1000);
  }

  /**
   * Creates a map and adds the given categories to it.
   * @param categories categories to be added to the map.
   * @param categoriesById map of categories by ID. Key = categoryId, value = category.
   */
  private createCategoriesMap(categories: Category[]): Map<string, Category> {
    const categoriesById = new Map<string, Category>();

    if (categories)
      for (const category of categories) {
        if (category.id !== undefined)
          categoriesById.set(category.id, category);
      }
    return categoriesById;
  }

  /**
   * Recursive call to create a linear array of categories.
   * @param categories The categories to be put inside categoriesLinear. Subcategories of categories are also put inside.
   * @param categoriesLinear Target for linear categories. This parameter is changed as a side-effect.
   * @param depth depth within categories tree
   */
  private getCategoriesLinearRecursive(categories: Category[], categoriesLinear: Category[], depth: number): void {
    for (const category of categories) {
      categoriesLinear.push(category);
      if (category.subcategories)
        this.getCategoriesLinearRecursive(Object.values(category.subcategories), categoriesLinear, depth + 1);
    }
  }
}

/**
 * Creates an array of mutable categories from the given (likely immutable) categories.
 * @param categories [immutable] categories
 * @return an array of mutable categories
 */
export const createMutableCategories = (categories: Category[]) => {
  const mutableCategories: Category[] = [];
  categories.forEach(cat => {
    const mutCat: Category = createMutableCategory(cat);
    mutableCategories.push(mutCat);
  });
  return mutableCategories;
};

/**
 * Creates a mutable category from the given (likely immutable) category.
 * @param category [immutable] category
 * @return a mutable category
 */
export const createMutableCategory = (cat: Category) => {
  const strings: CatLangStringsMap = {en: {name: cat.strings.en.name, elementName: cat.strings.en.elementName, description: cat.strings.en.description}};
  Object.entries(cat.strings).forEach((entry) => {
    const catLangStrings: CatLangStrings = {name: entry[1].name, description: entry[1].description, elementName: entry[1].elementName};
    strings[entry[0]] = catLangStrings;
  });
  const subcategories: Category[] = [];

  if (cat.subcategories) {
    Object.entries(cat.subcategories).forEach((entry) => {
      subcategories.push(createMutableCategory(entry[1]));
    });
  }
  const mutCat: Category = {id: cat.id, level: cat.level, imgUrlFull: cat.imgUrlFull, imgUrlThumb: cat.imgUrlThumb, strings, subcategories};

  return mutCat;
};

// Firestore data converter
export const categoryConverter = {
  toFirestore(category: Category): Category {
    return category;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Category {
    return convertToCategory(snapshot.data(options));
  },
};
