<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="mr-1" color="primary">grid_view</mat-icon>
    </ng-template>

    <div class="card-group">
      <div class="row justify-content-start mx-0 px-0">
        <div *ngFor="let rating of _ratings" class="card listing-card ml-0 mr-1 mb-1">
          <span *ngIf="!givenRatingsMode">
          <a [routerLink]="['/social/user',rating.raterUid]">
              <app-img-with-blurry-background *ngIf="rating.rater.imgUrlThumb"
                                              [src]="rating.rater.imgUrlThumb"
                                              alt="Picture of {{rating.rater.displayName}}"
                                              class="card-img-top contained-300h" default="./assets/images/default/default-user-w300.webp"
                                              i18n-alt="Picture alt text in rating card" [height]="300"></app-img-with-blurry-background>
              </a>
          </span>
          <span *ngIf="givenRatingsMode">
          <a [routerLink]="['/social','user',rating.receiverUid]">
            <img *ngIf="usersPublicByUid?.get(rating.receiverUid)?.imgUrlThumb"
                 [src]="usersPublicByUid.get(rating.receiverUid)?.imgUrlThumb" alt="Picture of {{usersPublicByUid?.get(rating.receiverUid)?.displayName}}"
                 class="card-img-top contained-300h" default="./assets/images/default/default-user-w300.webp"
                 i18n-alt="Picture alt text in rating card"></a>
          </span>
          <div class="card-body">
            <h2 *ngIf="!givenRatingsMode" class="paragraph-headline-2" i18n><a [routerLink]="['/social','rating',rating.uid]">Rating</a> from
              <a
                      [routerLink]="['/social/user',rating.raterUid]">{{rating.rater.displayName}}</a>
            </h2>
            <h2 *ngIf="givenRatingsMode" class="paragraph-headline-2" i18n><a [routerLink]="['/social','rating',rating.uid]">Rating</a> for <a
                    [routerLink]="['/social/user',rating.receiverUid]">{{usersPublicByUid?.get(rating.receiverUid)?.displayName}}</a></h2>

            <app-rating-star-table *ngIf="!givenRatingsMode" [rating]="rating" [receiverName]="user?.displayName"></app-rating-star-table>
            <app-rating-star-table *ngIf="givenRatingsMode" [rating]="rating"
                                   [receiverName]="usersPublicByUid?.get(rating.receiverUid)?.displayName"></app-rating-star-table>
            <p *ngIf="rating.comment" class="mt-2">{{rating.comment}}</p>

            <div class="small right">
              <app-report-button [uid]="rating.uid" type="rating"></app-report-button>
            </div>

          </div>
          <div class="card-footer align-middle">
            <div class="row">
              <div class="col-auto mr-0 pr-0">
                <a [routerLink]="['/browse/listing/',rating.embeddedListing.uid]">
                  <img *ngIf="rating.embeddedListing.imgUrlThumb"
                       [src]="rating.embeddedListing.imgUrlThumb"
                       alt="Picture of {{rating.embeddedListing.name}}"
                       class="contained-60w-60h" default="./assets/images/default/default-listing-w300.webp"
                       i18n-alt="Picture alt text in rating card"></a>
              </div>
              <div class="col">
                <a [routerLink]="['/browse','listing',rating.embeddedListing.uid]" [title]="rating.embeddedListing.name">{{rating.embeddedListing.name}}</a>
                <br>
                <span *ngIf="!givenRatingsMode">
                <span *ngIf="rating.receiverRole === transactionRole.Borrower" i18n>{{rating.rater.displayName}} was lender.</span>
                <span *ngIf="rating.receiverRole === transactionRole.Lender" i18n>{{rating.rater.displayName}} was borrower.</span>
                </span>
                <span *ngIf="givenRatingsMode">
                <span *ngIf="rating.receiverRole === transactionRole.Lender" i18n>{{usersPublicByUid.get(rating.receiverUid)?.displayName}} was lender.</span>
                <span *ngIf="rating.receiverRole === transactionRole.Borrower" i18n>{{usersPublicByUid.get(rating.receiverUid)?.displayName}}
                  was borrower.</span>
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="mr-1" color="primary">table_rows</mat-icon>
    </ng-template>
    <table (matSortChange)="sortData($event)" *ngIf="dataSource" [dataSource]="dataSource" [matSortActive]="matSortActive"
           [matSortDirection]="matSortDirection"
           class="mat-elevation-z8"
           mat-table
           matSort>

      <!-- Rater image Column -->
      <ng-container matColumnDef="raterImgThumb">
        <th *matHeaderCellDef mat-header-cell>
          <span *ngIf="!givenRatingsMode" i18n>Rater's image</span>
          <span *ngIf="givenRatingsMode" i18n>Receiver's image</span></th>
        <td *matCellDef="let rating" mat-cell>
          <div class="d-block d-sm-none">
            <app-user-thumb *ngIf="!givenRatingsMode" [size]="75"
                            [userPublic]="{imgUrlThumb: rating.rater.imgUrlThumb, uid: rating.raterUid, displayName: rating.rater.displayName ? rating.rater.displayName : ''}"></app-user-thumb>
            <app-user-thumb *ngIf="givenRatingsMode" [size]="75"
                            [userUid]="rating.receiverUid"></app-user-thumb>
          </div>
          <div class="d-none d-sm-block">
            <app-user-thumb *ngIf="!givenRatingsMode" [size]="150"
                            [userPublic]="{imgUrlThumb: rating.rater.imgUrlThumb, uid: rating.raterUid, displayName: rating.rater.displayName ? rating.rater.displayName : ''}"></app-user-thumb>
            <app-user-thumb *ngIf="givenRatingsMode" [size]="150"
                            [userUid]="rating.receiverUid"></app-user-thumb>
          </div>
        </td>
      </ng-container>

      <!-- Rater name Column-->
      <ng-container matColumnDef="raterDisplayName">
        <th *matHeaderCellDef mat-header-cell>
          <span *ngIf="!givenRatingsMode" i18n>Rater's name</span>
          <span *ngIf="givenRatingsMode" i18n>Receiver's name</span>
        </th>
        <td *matCellDef="let rating" mat-cell>
          <a *ngIf="!givenRatingsMode" [routerLink]="['/social/user',rating.raterUid]">{{rating.rater.displayName}}</a>
          <a *ngIf="givenRatingsMode"
             [routerLink]="['/social/user',rating.receiverUid]">{{usersPublicByUid?.get(rating.receiverUid)?.displayName}}</a>
        </td>
      </ng-container>

      <!-- Role Column-->
      <ng-container matColumnDef="role">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n>Rater's role</span>
        </th>
        <td *matCellDef="let rating" mat-cell>
          <span *ngIf="rating.receiverRole === transactionRole.Borrower" i18n="lender">Lender</span>
          <span *ngIf="rating.receiverRole === transactionRole.Lender" i18n="borrower">Borrower</span>
        </td>
      </ng-container>

      <!-- Stars Column-->
      <ng-container matColumnDef="stars">
        <th *matHeaderCellDef i18n="stars" mat-header-cell>Stars</th>
        <td *matCellDef="let rating" mat-cell>
          <a [routerLink]="['/social','rating',rating.uid]">
            <app-rating-star-table *ngIf="!givenRatingsMode" [rating]="rating" [receiverName]="user?.displayName"></app-rating-star-table>
            <app-rating-star-table *ngIf="givenRatingsMode" [rating]="rating"
                                   [receiverName]="usersPublicByUid?.get(rating.receiverUid)?.displayName"></app-rating-star-table>
          </a>
          <div class="small">
            <app-report-button [uid]="rating.uid" type="rating"></app-report-button>
          </div>
        </td>
      </ng-container>

      <!-- Comment Column -->
      <ng-container matColumnDef="comment">
        <th *matHeaderCellDef i18n="comment heading" mat-header-cell>Comment</th>
        <td *matCellDef="let rating" mat-cell>
          <span *ngIf="rating.comment">{{rating.comment}} </span>
        </td>
      </ng-container>


      <!-- Creation Date Column -->
      <ng-container matColumnDef="creationDate">
        <th *matHeaderCellDef i18n="creationDate heading" mat-header-cell>Date</th>
        <td *matCellDef="let rating" mat-cell>
          <p *ngIf="rating.date" [title]="utilService.getDate(rating.date) | moment:'LLLL'">
            {{utilService.createFromNowString(utilService.getDate(rating.date))}}</p>
        </td>
      </ng-container>

      <!-- rating Name Column -->
      <ng-container matColumnDef="listingName">
        <th *matHeaderCellDef i18n="listingName heading" mat-header-cell>Listing name</th>
        <td *matCellDef="let rating" mat-cell>
          <a [routerLink]="['/browse/listing/',rating.embeddedListing.uid]"><span
                  *ngIf="rating.embeddedListing.name">{{rating.embeddedListing.name}} </span></a>
        </td>
      </ng-container>

      <!-- Rating image Column -->
      <ng-container matColumnDef="listingImgThumb">
        <th *matHeaderCellDef i18n="rater image heading" mat-header-cell>Listing image</th>
        <td *matCellDef="let rating" mat-cell>
          <a [routerLink]="['/browse/listing/',rating.embeddedListing.uid]">
            <div class="d-block d-sm-none">
              <app-img-with-blurry-background [src]="rating.embeddedListing.imgUrlThumb" alt="Picture of {{rating.embeddedListing.name}}"
                                              default="./assets/images/default/default-listing-w300.webp" i18n-alt="Picture alt text in rating card"
                                              [height]="150" [width]="75"></app-img-with-blurry-background>
            </div>
            <div class="d-sm-block d-none">
              <app-img-with-blurry-background [src]="rating.embeddedListing.imgUrlThumb" alt="Picture of {{rating.embeddedListing.name}}"
                                              default="./assets/images/default/default-listing-w300.webp" i18n-alt="Picture alt text in rating card"
                                              [height]="150" [width]="150"></app-img-with-blurry-background>
            </div>
          </a>
        </td>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

  </mat-tab>

</mat-tab-group>


<div *ngIf="thereIsMore">
  <button (click)="onClickShowMore()" class="btn btn-primary" i18n="button in item-card-group, which is used to load more ratings" type="button">Show more</button>
</div>
