import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BookComponent} from './book/book.component';
import {SharedModule} from '../shared/shared.module';
import {BookRoutingModule} from './book-routing.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatInputModule} from '@angular/material/input';
import {BrowserModule} from '../browser/browser.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import Locale from '../shared/services/locale';


@NgModule({
  declarations: [
    BookComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BookRoutingModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule.setLocale(Locale.timePickerLocale()),
    BrowserModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class BookModule {
}
