<ngb-carousel #carousel="ngbCarousel" *ngIf="categories.length>0" [animation]="false" [interval]="5000" [keyboard]="true"
              [pauseOnHover]="true" [showNavigationArrows]="true" [showNavigationIndicators]="true"
              class="animatedCarousel">
  <ng-template (slid)="activeIndex = i" *ngFor="let category of categories; index as i" ngbSlide>

    <div class="wrapper">
      <picture *ngIf="isImageActive(i)">
        <source [srcset]="category.imgUrlFull" media="(min-width: 600px), (min-height: 300px)">
        <img [src]="category.imgUrlThumb" alt="Image of {{categoryService.getCategoryElementName(category)}}"
             class="image-container" default="./assets/images/default/default-category-w300.webp"
             i18n-alt="Alt text of Blitzshare start category image">
      </picture>
      <a [routerLink]="['/browse','category',getCategoryId(category)]">
        <div class="carousel-caption center">
          <div class="container m-0 p-0"><h1
            class="carousel-headline text-shadow-primary ellipsis">{{categoryService.getCategoryName(category)}}</h1>
            <p class="carousel-paragraph text-shadow-primary">{{categoryService.getCategoryDescription(category)|shorten:100:true}}</p>
          </div>
        </div>
      </a>
    </div>
  </ng-template>
</ngb-carousel>
