<div class="container p-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
      <li class="breadcrumb-item">
        <span i18n="my listings headline">My listings</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="my listings headline">My listings</h1>
    <p class="jumbotron-lead" i18n="listings lead text">View and manage your listings.</p>
  </div>


  <!-- Show error, if no listings could be loaded -->
  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <app-mat-icon-button [callback]="refresh.bind(this)" [params]="[]" color="primary" cssClass="mb-3"
                       i18n-label="button label refresh" icon="refresh"
                       label="Refresh"></app-mat-icon-button>

  <app-listings-view (loadMoreListings)="loadMoreListings($event)" [infiniteScrollPercentage]="90" [listings]="listings"
                     [thereIsMore]="thereIsMore"></app-listings-view>

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true"></app-loading-spinner>
  <p *ngIf="listings" class="mx-1" i18n="message shown listings count and total listings count">Showing {{listings.length}} listings.</p>

</div>
