<div *ngIf="addressBarValue !== address?.formattedAddress" class="m-0">
  <br>
  <small class="text-red mt-0 mb-0">
    <span i18n="error address not matching 1">Please select an address:</span>
    <ul class="m-0">
      <li i18n="error address not matching 2">Click on the map.
      <li i18n="error address not matching 3">Enter an address and select an auto-complete option.</li>
    </ul>
  </small>
</div>
<div *ngIf="!address?.street || !address?.city || !address?.zipCode" class="m-0">
  <br>
  <small class="text-red mt-0 mb-0">
    <span i18n="error address is incomplete">Your address is incomplete:</span>
    <ul class="m-0">
      <li *ngIf="!address?.street" i18n="error address street is missing">Street is missing.
      <li *ngIf="!address?.city " i18n="error address city is missing">City is missing.
      <li *ngIf="!address?.zipCode" i18n="error address zipCode is missing">ZIP code is missing.
    </ul>
  </small>
</div>
