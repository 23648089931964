<h1 class="paragraph-headline" mat-dialog-title>
  <mat-icon class="align-middle mr-2">notifications</mat-icon>
  <span i18n="Notifications">Notifications</span>
</h1>

<mat-dialog-content>
  <div *ngIf="error" class="alert alert-danger">{{error}}</div>
  <div *ngIf="notifications.length > 0">
    <div *ngFor="let notification of notifications" class="list-item">
      <app-notification (click)="closeDialog()" [notification]="notification"></app-notification>
    </div>
  </div>
  <p *ngIf="notifications.length===0" class="mt-3" i18n="no notifications">You haven't got any notifications yet.</p>
  <a (click)="loadMore()" *ngIf="thereIsMore" class="center-all">Load more</a>
  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true"></app-loading-spinner>
</mat-dialog-content>



