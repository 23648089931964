<!-- Breadcrumb-->
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
    <li class="breadcrumb-item">
      <span i18n="first steps">First steps</span>
    </li>
  </ol>
</nav>

<div class="jumbotron mb-3">
  <h1 class="jumbotron-headline" i18n="welcome headline">Welcome to Blitzshare!</h1>
  <p class="jumbotron-lead" i18n="welcome text">Thank you for registering with Blitzshare. Before you can get started, we need some more information from
    you.</p>
  <p class="jumbotron-lead" i18n="welcome text 2">In the following, we'll guide you through the setup process:</p>
  <ol>
    <li class="jumbotron-lead" i18n>Select your language.</li>
    <li class="jumbotron-lead" i18n>Enter your personal information, such as name and address.</li>
    <li class="jumbotron-lead" i18n>Upload a user image.</li>
    <li *ngIf="payService.isPaymentActive()" class="jumbotron-lead" i18n>Create a wallet and add a bank account.</li>
  </ol>
</div>


<mat-vertical-stepper #stepper (selectionChange)="onSelectionChange($event)" [linear]="false">

  <mat-step>
    <ng-template matStepLabel><span i18n>Select your language</span></ng-template>

    <app-lang-selector></app-lang-selector>
    <br>
    <br>
    <div>
      <button i18n="Next" mat-raised-button matStepperNext>Next</button>
    </div>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel><span i18n>Enter your personal information</span></ng-template>
    <app-info-card *ngIf="payService.isPaymentActive()" i18n>This step is required before you can book an item for borrowing. Otherwise, you would not be able
      to make payments.
    </app-info-card>
    <br>
    <app-master-data (onSave)="onMasterDataSaved($event)" (onSavingFailed)="onSavingMasterDataFailed($event)" [embedded]="true"
                     [saveSubject]="saveMasterDataSubject"
                     [showSaveButton]="false"
                     id="master-data"></app-master-data>
    <br>
    <div>
      <button (click)="saveMasterData(-1)" class="mr-2" i18n="Back" mat-raised-button>Back</button>
      <button (click)="saveMasterData(+1)" i18n="Next" mat-raised-button>Next</button>
    </div>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel><span i18n>Set your user image</span></ng-template>
    <app-info-card i18n>This step is optional, but it's a good idea to put a friendly face next to your listings and bookings.</app-info-card>
    <br>
    <app-image *ngIf="selectedIndex===2" [embedded]="true"></app-image>
    <br>
    <div>
      <button *ngIf="payService.isPaymentActive()" i18n="Next" mat-raised-button matStepperNext>Next</button>
      <button (click)="finish()" *ngIf="!payService.isPaymentActive()" color="primary" i18n="Finish" mat-raised-button type="button">Finish</button>
    </div>
  </mat-step>

  <mat-step *ngIf="payService.isPaymentActive()">
    <ng-template matStepLabel><span i18n>Create a payment account</span></ng-template>
    <app-info-card i18n>This step is required before you can list your own item for rent. If you don't want to offer anything for rent, you can skip this step
      for now.
    </app-info-card>
    <br>

    <app-payment-account *ngIf="selectedIndex===3" [embedded]="true"></app-payment-account>
    <br>
    <br>
    <div>
      <button class="mr-2" i18n="Back" mat-raised-button matStepperPrevious>Back</button>
      <button (click)="finish()" color="primary" i18n="Finish" mat-raised-button type="button">Finish</button>
    </div>
  </mat-step>

</mat-vertical-stepper>

