import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../shared/services/title.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {Store} from '@ngrx/store';
import {PayService} from '../../pay/pay.service';
import {UtilService} from '../../shared/util.service';
import {AppState} from '../../store/app.reducer';

@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.scss'],
})
export class AccountOverviewComponent extends BaseComponent implements OnInit {

  constructor(
      protected store: Store<AppState>,
      private utilService: UtilService,
      public payService: PayService,
      private titleService: TitleService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Account management`);
  }

  shouldShowFirstSteps() {
    return this.utilService.isAccountSetupIncomplete(this.user);
  }
}
