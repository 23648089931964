<table *ngIf="address" class="table table-striped table-sm">

  <tbody>
  <tr *ngIf="showName && address.name">
    <th i18n="name">Name</th>
    <td>{{address.name}}</td>
  </tr>
  <tr *ngIf="address.street || address.street2">
    <th i18n="street">Street</th>
    <td>{{address.street}}<span *ngIf="address.houseNumber"> {{address.houseNumber}}</span>
      <span *ngIf="address.street && address.street2"><br></span>
      {{address.street2}}</td>
  </tr>
  <tr *ngIf="address.district">
    <th i18n="district">District</th>
    <td>{{address.district}}</td>
  </tr>
  <tr *ngIf="address.zipCode">
    <th i18n="zip code">Zip code</th>
    <td>{{address.zipCode}}</td>
  </tr>
  <tr *ngIf="address.city">
    <th i18n="city">City</th>
    <td>{{address.city}}</td>
  </tr>
  <tr *ngIf="address.state">
    <th i18n="state (inside country)">State</th>
    <td>{{address.state}}</td>
  </tr>
  <tr *ngIf="address.countryCode">
    <th i18n="Country">Country</th>
    <td>{{countryService.getCountryNameByCode(address.countryCode)}}</td>
  </tr>
  <tr *ngIf="showHint && address.hint">
    <th i18n="hint">Hint</th>
    <td>{{address.hint}}</td>
  </tr>
  <tr *ngIf="phone">
    <th i18n="phone number">Phone number</th>
    <td>{{phone}}</td>
  </tr>
  </tbody>
</table>
