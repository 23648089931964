<div class="container bg-container p-1">

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [message]="loadingSpinnerMessage"
                       [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a [routerLink]="['/admin','reports']" class="breadcrumb-item" i18n="reports">Reports</a>
      <a *ngIf="report && report.type === 'listing' && listing" [routerLink]="['/browse','listing', listing.uid]"
         class="breadcrumb-item">{{listing.name}}</a>
      <a *ngIf="report && report.type === 'rating' && rating" [routerLink]="['/social','rating', rating.uid]" class="breadcrumb-item"
         i18n="rating">Rating</a>
      <li class="breadcrumb-item"><span i18n="report">Report</span><span *ngIf="reporter"> from
        <a [routerLink]="['/social','user',reporter.uid]" class="breadcrumb-item">{{reporter.displayName}}</a></span>
      </li>
    </ol>
  </nav>

  <!-- Jumbotron -->
  <div class="row">
    <div class="col">
      <div class="jumbotron mb-3">
        <h1 *ngIf="listing" class="jumbotron-headline" i18n>Report for {{listing.name}}</h1>
        <h1 *ngIf="!listing && report?.type === 'listing'" class="jumbotron-headline" i18n>Report for invalid listing</h1>
        <h1 *ngIf="rating" class="jumbotron-headline" i18n>Report for rating</h1>
        <h1 *ngIf="!rating && report?.type === 'rating'" class="jumbotron-headline" i18n>Report for invalid rating</h1>
        <p *ngIf="!listing && report?.type === 'listing'" class="jumbotron-lead" i18n>The <a
                [routerLink]="['/browse','listing',report?.listingUid]">listing {{report?.listingUid}}</a>, which this report was
          about, was not found.</p>
        <p *ngIf="!rating && report?.type === 'rating'" class="jumbotron-lead" i18n>The <a
                [routerLink]="['/social','rating',report?.ratingUid]">rating {{report?.ratingUid}}</a>, which this report was
          about, was not found.</p>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
  </div>

  <ng-container *ngIf="listing">
    <app-item-card [listing]="listing"></app-item-card>
  </ng-container>

  <div *ngIf="rating" class="mb-3">
    <app-rating [linkRating]="true" [rating]="rating" [showListing]="true"></app-rating>
  </div>

  <div class="card-deck">

    <!-- Reporter -->
    <div *ngIf="reporter" class="info-card card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['/social','user',reporter.uid]">
            <img alt="Reporter image" class="contained-200h-150w" default="./assets/images/default/default-user-w300.webp"
                 i18n-alt="Reporter image alt text"
                 src="{{reporter.imgUrl}}"></a>
        </div>
        <div class="col">
          <div class="card-body">
            <h2 class="paragraph-headline-2" i18n="reporter headline">Reporter</h2>
            <p *ngIf="userIsReporter()" i18n="you are the reporter">You are the reporter.</p>
            <p *ngIf="!userIsReporter()">{{reporter.displayName}}</p>
            <app-user-info-internal [userPublic]="reporter"></app-user-info-internal>
          </div>
        </div>
      </div>
    </div>

    <!-- Receiver -->
    <div *ngIf="receiver" class="info-card card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['/social','user',receiver.uid]">
            <img alt="Receiver image" class="contained-200h-150w" default="./assets/images/default/default-user-w300.webp"
                 i18n-alt="Receiver image alt text"
                 src="{{receiver.imgUrl}}"></a>
        </div>
        <div class="col">
          <div class="card-body">
            <h2 class="paragraph-headline-2" i18n="receiver headline">Receiver</h2>
            <p *ngIf="userIsReceiver()" i18n="you are the receiver">You are the receiver.</p>
            <p *ngIf="!userIsReceiver()">{{receiver.displayName}}</p>
            <app-user-info-internal [userPublic]="receiver"></app-user-info-internal>
          </div>
        </div>
      </div>
    </div>

  </div>

  <ng-container *ngIf="report">
    <table class="table table-striped table-sm table-responsive">
      <tr>
        <th i18n="creation date">Creation date</th>
        <td>{{utilService.getDate(report.creationDate) | moment:'LLL'}}</td>
      </tr>
      <tr *ngIf="report.lastEditDate">
        <th i18n="last update">Last update</th>
        <td>{{utilService.getDate(report.lastEditDate) | moment:'LLL'}}</td>
      </tr>
      <tr>
        <th i18n="reporter allows contact">Contact allowed</th>
        <td>
          <app-yes-no [value]="report.allowContact"></app-yes-no>
          <div *ngIf="report.allowContact && reporter && user && reporter.uid !== user.uid">
            <app-mat-icon-button *ngIf="!showWriteMessageComponent" [callback]="onSendMessage.bind(this)" [params]="[]"
                                 color="accent" cssClass="my-3" i18n-label="Send message to reporter" icon="message"
                                 label="Send message to {{reporter?.displayName}}"></app-mat-icon-button>

            <mat-card *ngIf="showWriteMessageComponent" class="my-3">
              <app-write-message (onClose)="showWriteMessageComponent=false" *ngIf="showWriteMessageComponent"
                                 [listingUid]="listing?.uid"
                                 [receiverUid]="reporter.uid"></app-write-message>
            </mat-card>
          </div>
        </td>
      </tr>
      <tr *ngIf="reportCategory">
        <th i18n="category">Category</th>
        <td>{{reportCategory.value}}</td>
      </tr>
      <tr>
        <th i18n="reason">Reason</th>
        <td>{{report.reason}}</td>
      </tr>
    </table>

    <app-mat-icon-button *ngIf="report" [callback]="onDeleteReport.bind(this)" color="accent"
                         cssClass="mr-2 mb-3" i18n-label="delete report" icon="report" label="Delete this report"></app-mat-icon-button>

    <app-mat-icon-button *ngIf="report" [callback]="onDeleteAllReportsForReference.bind(this)" color="warn"
                         cssClass="mr-2 mb-3" i18n-label="delete all reports for reference" icon="report"
                         label="Delete all reports for reference"></app-mat-icon-button>
    <br>
    <app-mat-icon-button *ngIf="report.type === 'listing' && listing" [routerLink]="['/listing','edit',listing?.uid]" cssClass="mr-2 mb-3" i18n-label
                         icon="edit"
                         label="Edit this listing"></app-mat-icon-button>

    <app-mat-icon-button *ngIf="report.type === 'listing' && listing && listing.disabled === true" [callback]="onEnableListing.bind(this)" color="primary"
                         cssClass="mr-2 mb-3" i18n-label="enable listing" icon="play_arrow" label="Enable listing"></app-mat-icon-button>

    <app-mat-icon-button *ngIf="report.type === 'listing' && listing && listing.disabled === false" [callback]="onDisableListing.bind(this)" color="accent"
                         cssClass="mr-2 mb-3" i18n-label="disable listing" icon="pause" label="Disable listing"></app-mat-icon-button>

    <app-mat-icon-button *ngIf="report.type === 'listing' && listing" [callback]="onDeleteListing.bind(this)" color="warn" cssClass="mr-2 mb-3"
                         i18n-label="delete listing"
                         icon="delete" label="Delete listing"></app-mat-icon-button>

    <app-mat-icon-button *ngIf="report.type === 'rating' && rating" [callback]="onDeleteRating.bind(this)" color="warn" cssClass="mr-2 mb-3"
                         i18n-label="delete rating"
                         icon="delete" label="Delete rating"></app-mat-icon-button>

  </ng-container>

</div>
