import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import firebase from 'firebase/app';
import {Transaction} from '../../../shared/models/transaction.interface';
import {FormControl, FormGroup} from '@angular/forms';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {UtilService} from '../../../shared/util.service';
import {CountryService} from '../../../shared/services/country.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../../shared/services/title.service';
import {BookService} from '../../../book/book.service';
import {Listing} from '../../../shared/models/listing.interface';
import {RentParams} from '../../../shared/models/rentParams.interface';
import * as moment from 'moment';
import {ListingComponent} from '../../../browser/listing/listing.component';
import Util from '../../../shared/util';
import {BookComponent} from '../../../book/book/book.component';
import {ListingService} from '../../../listing/listing.service';
import {BOOKING_CONFIRMATION_TERMS} from '../../../shared/constants/strings';
import {PayService} from '../../../pay/pay.service';
import Locale from '../../../shared/services/locale';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import Timestamp = firebase.firestore.Timestamp;

@Component({
    selector: 'app-booking-date-selection',
    templateUrl: './booking-date-selection.component.html',
    styleUrls: ['./booking-date-selection.component.css'],
})
export class BookingDateSelection extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {
    @Input() updateBookingPeriod?: (targetPickupDate: Timestamp, targetReturnDate: Timestamp, numberOfDays: number, pricePerDay: number, fee: number) => void;
    @Input() visible: boolean = true;
    @Output() visibleChange = new EventEmitter<boolean>();

    errors: string[] = [];
    listing?: Listing;
    availableDayTimestamps: number[] = [];
    dateRangeInvalidDates: Date[] = [];
    rentParams: RentParams = {};
    minDate = new Date();
    maxDate = new Date();
    bookForm: FormGroup | undefined;
    timePickerFormat = Locale.timePickerFormat();
    numberFormatLocale = Locale.numberFormatLocale();

    listingDoesNotExist = false;

    bookingConfirmationTerms = BOOKING_CONFIRMATION_TERMS;

    constructor(
        protected store: Store<AppState>,
        private userService: UserService,
        public utilService: UtilService,
        public listingService: ListingService,
        public countryService: CountryService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public payService: PayService,
        private cdRef: ChangeDetectorRef,
        private titleService: TitleService,
        private bookService: BookService) {
        super(store);
    }

    _transaction?: Transaction;

    get transaction(): Transaction | undefined {
        return this._transaction;
    }

    @Input() set transaction(transaction: Transaction | undefined) {
        this._transaction = transaction;
        this.bookForm = this.createForm();
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.payService.isPaymentActive())
            this.bookingConfirmationTerms += ' ';

        if (this.transaction?.listingUid)
            this.listingService.fetchListing(this.transaction.listingUid).then(wrapper => {
                this.listing = wrapper.data;
                if (this.listing) {
                    const minAndMaxDate = ListingComponent.setMinAndMaxDate(this.listing);
                    this.minDate = minAndMaxDate.minDate;
                    this.maxDate = minAndMaxDate.maxDate;
                    this.availableDayTimestamps = ListingComponent.setAvailableDays(this.listing, this.transaction?.uid);
                    // this.sortExcludedDates(this.listing);
                    if (this.listing.name != null) {
                        this.titleService.setTitle($localize`Booking` + ' ' + Util.removeEmTags(this.listing.name));
                    }
                    this.listingDoesNotExist = false;
                } else
                    this.listingDoesNotExist = true;
                if (wrapper.errorMessage)
                    this.errors.push(wrapper.errorMessage);
            });
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    createForm(): FormGroup {
        return new FormGroup({
            dateFrom: new FormControl(this.transaction?.targetPickupDate.toDate()),
            dateUntil: new FormControl(this.transaction?.targetReturnDate.toDate()),
            timeFrom: new FormControl(moment(this.transaction?.targetPickupDate.toDate()).format('HH:mm')),
            timeUntil: new FormControl(moment(this.transaction?.targetReturnDate.toDate()).format('HH:mm')),
        });
    }

    availabilityFilter = (d: Date | null): boolean => {
        if (d === null)
            return false;
        const checkDay = d.setHours(0, 0, 0, 0);
        return this.availableDayTimestamps.indexOf(checkDay) > -1;
    };

    onSuggest(): void {
        if (!this.updateBookingPeriod)
            return;

        this.errors.length = 0;

        const rentParamsError = BookComponent.validateRentParams(this.rentParams);
        if (rentParamsError) {
            this.errors.push(rentParamsError);
            return;
        }
        this.updateBookingPeriod(Timestamp.fromDate(this.rentParams.dateFrom!),
            Timestamp.fromDate(this.rentParams.dateUntil!), this.rentParams.rentPeriod!, this.rentParams.price?.pricePerDay!, 0);
    }

    onCancel(): void {
        this.visible = false;
        this.visibleChange.emit(false);
    }
}
