import {Component, OnInit} from '@angular/core';
import {UserService} from '../shared/services/user.service';
import {BaseComponent} from '../shared/components/base/base.component';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.reducer';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountComponent extends BaseComponent implements OnInit {

  constructor(
      protected store: Store<AppState>,
      userService: UserService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


}
