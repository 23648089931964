import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {RentParams} from '../../models/rentParams.interface';
import Locale from '../../services/locale';

/**
 * Shows the price for the currently selected booking period.
 */
@Component({
  selector: 'app-booking-price-table',
  templateUrl: './booking-price-table.component.html',
  styleUrls: ['./booking-price-table.component.css'],
})
export class BookingPriceTableComponent implements OnInit {

  numberFormatLocale = Locale.numberFormatLocale();
  @Input() bookForm?: FormGroup;
  @Input() currencyId?: string;
  @Input() rentParams: RentParams = {};
  @Input() showPrice: boolean = true;
  @Input() currentRentPeriod?: number;


  constructor() {
  }

  ngOnInit(): void {
  }

}
