import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {TitleService} from '../../shared/services/title.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent extends BaseComponent implements OnInit {

  constructor(
      protected store: Store<AppState>,
      private titleService: TitleService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Support`);
  }


}
