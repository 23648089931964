import {Component, OnDestroy, OnInit} from '@angular/core';
import {BrowserService} from '../../browser.service';
import {Category} from '../../../shared/models/category.interface';
import {SharedService} from '../../../shared/services/shared.service';
import {CurrencyService} from '../../../shared/services/currency.service';
import {CategoryService} from '../../../shared/services/category.service';
import {TitleService} from '../../../shared/services/title.service';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {AnalyticsEventName, AnalyticsService} from '../../../shared/services/analytics.service';
import {AppState} from '../../../store/app.reducer';
import {Store} from '@ngrx/store';


@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.css'],
})
export class AllCategoriesComponent extends BaseComponent implements OnInit, OnDestroy {

  categories: Category[] = [];
  error: string | undefined;

  subcategoriesShownByCategory = new Map<Category, boolean>();

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              private browserService: BrowserService,
              public sharedService: SharedService,
              public currencyService: CurrencyService,
              public categoryService: CategoryService,
              private titleService: TitleService,
              private analyticsService: AnalyticsService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`All categories`);
    this.browserService.selectedCategorySubject.next(undefined);
    this.analyticsService.logEvent(AnalyticsEventName.ALL_CATEGORIES_VIEW);

    this.categoryService.getCategories().then(wrapper => {
      if (wrapper.data)
        this.categories = wrapper.data;
      if (wrapper.errorMessage)
        this.error = wrapper.errorMessage;
    });
  }


}
