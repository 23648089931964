import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsible-contact-details',
  templateUrl: './responsible-contact-details.component.html',
  styleUrls: ['./responsible-contact-details.component.scss']
})
export class ResponsibleContactDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
