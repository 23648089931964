import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import firebase from 'firebase/app';
import {map, takeUntil} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {messageConverter, SocialService} from '../../../social/social.service';
import {Message} from '../../../shared/models/message.interface';
import {Conversation} from '../../../shared/models/conversation.interface';
import {BookService} from '../../../book/book.service';
import {EmbeddedListing} from '../../../shared/models/embeddedListing.interface';
import {BrowserService} from '../../../browser/browser.service';
import {Listing} from '../../../shared/models/listing.interface';
import {User} from '../../../shared/models/user.interface';
import {TitleService} from '../../../shared/services/title.service';
import {UserPublic} from '../../../shared/models/userPublic.interface';
import {UtilService} from '../../../shared/util.service';
import {ListingService} from '../../../listing/listing.service';

import {firestore} from '../../../app.module';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.css'],
})
export class ConversationComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked, OnDestroy {

  newMessage = '';
  conversationUid?: string;
  messages: Message[] = [];
  embeddedListing?: EmbeddedListing;

  thereArePreviousMessages = false;
  receiver?: UserPublic;
  listing?: Listing;
  listingUid?: string;
  @ViewChild('messagesRef') messagesElementRef ?: ElementRef;
  /**
   * Receiver for outgoing messages
   */
  receiverUid?: string;
  /**
   * Sender for outgoing messages
   */
  senderUid?: string;
  private conversation?: Conversation;
  private messagesStreamUnsubscribe?: () => void;
  private latestMessageTimestamp = Timestamp.now();
  private lastVisible?: firebase.firestore.QueryDocumentSnapshot<any>;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public utilService: UtilService,
      private socialService: SocialService,
      private bookService: BookService,
      private browserService: BrowserService,
      private listingService: ListingService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private titleService: TitleService,
      private cdRef: ChangeDetectorRef) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Conversation`);

    const transactionUid$ = this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$),
        map(paramMap => paramMap.get('conversationUid')),
    );
    transactionUid$.subscribe(conversationUid => {
      this.killStream();

      if (conversationUid) {
        this.conversationUid = conversationUid;

        this.loadFirestoreUser();

        this.loadLatestMessages();

      }

      // If there is no conversationUid, navigate away
      else
        this.router.navigate(['account', 'messages']);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.killStream();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  async sendMessage() {
    this.clearAlerts();
    if (this.newMessage.trim() === '' || !this.senderUid || !this.receiverUid)
      return;

    const message: Message = {
      message: this.newMessage,
      senderUid: this.senderUid,
      date: Timestamp.now(),
      receiverUid: this.receiverUid,
    };
    if (this.listingUid)
      message.listingUid = this.listingUid;

    this.bookService.createMessage(message, () => {
          this.newMessage = '';
        },
        (errorMessage) => {
          this.addError($localize`Error sending message\: ${errorMessage}`);
        }, this.embeddedListing);
  }

  loadPreviousMessages() {
    this.enableLoadingSpinner($localize`Loading previous messages...`);
    this.socialService.fetchMessagesFromConversation(this.conversationUid!, 10, this.lastVisible).then(wrapper => {
      this.disableLoadingSpinner();
      if (wrapper.data)
        wrapper.data.forEach(message => {
          this.messages.push(message);
        });
      // If there are 10 messages, there are probably more. If there are less than 10, there are no more messages
      this.thereArePreviousMessages = wrapper.data?.length === environment.defaultLoadMessagesCount;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
      this.lastVisible = wrapper.lastVisible;
      this.sortMessages();
    });
  }

  private killStream() {
    if (this.messagesStreamUnsubscribe)
      this.messagesStreamUnsubscribe();
  }

  private streamMessages() {

    this.messagesStreamUnsubscribe = firestore.collection(environment.firestoreCollectionConversations).doc(this.conversationUid)
        .collection(environment.firestoreCollectionMessages).withConverter(messageConverter)
        .where('date', '>', this.latestMessageTimestamp)
        .orderBy('date', 'asc')
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.messages.push(doc.data());
            this.latestMessageTimestamp = doc.data().date;
          });
          if (querySnapshot.size > 0) {
            this.sortMessages();
            this.markAsRead();
            this.clearAlerts();

            // Restart stream with new timestamp
            if (this.messagesStreamUnsubscribe) {
              this.messagesStreamUnsubscribe();
              this.streamMessages();
            }
          }
        });
  }

  private sortMessages() {
    this.messages = this.messages.sort((a, b) => {
      return a.date.toMillis() - b.date.toMillis();
    });
  }

  private loadFirestoreUser() {
    this.user$.subscribe(user => {
      if (user)
        this.onUserLoaded(user);
    });
  }

  private onUserLoaded(user: User) {
    this.senderUid = user.uid;

    this.loadConversation();
  }

  private loadConversation() {
    this.socialService.fetchConversation(this.conversationUid!).then(wrapper => {
      if (wrapper.data)
        this.conversation = wrapper.data;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
      this.onConversationLoaded(this.conversation!);
    });
  }

  private onConversationLoaded(conversation: Conversation) {
    this.receiverUid = conversation.user1Uid === this.user?.uid ? conversation.user2Uid : conversation.user1Uid;
    this.listingUid = conversation.listingUid;
    this.embeddedListing = conversation.embeddedListing;
    this.loadListing();

    this.userService.fetchUserPublic(this.receiverUid).then(wrapper => {
      this.receiver = wrapper.data;
      if (this.receiver)
        this.titleService.setTitle($localize`Conversation with ${this.receiver.displayName}`);
      if (wrapper.errorMessage)
        this.addError($localize`We could not load the other user\: ${wrapper.errorMessage}`);
    });

    this.markAsRead();
  }

  private loadListing() {
    // Now, fetch the listing
    if (this.conversation?.listingUid) {
      this.listingService.fetchListing(this.conversation.listingUid).then(wrapper => {
        if (wrapper.data)
          this.onListingLoaded(wrapper.data);
        if (wrapper.errorMessage)
          this.addError(wrapper.errorMessage);
      });

    }
  }

  private onListingLoaded(listing: Listing) {
    // Copy name and imgUrlThumb from the listing
    this.listing = listing;
    this.embeddedListing = {name: listing.name, imgUrlThumb: listing && listing.imgUrls ? listing.imgUrls[0].thumb : '', uid: listing.uid!};
  }

  private loadLatestMessages() {

    this.enableLoadingSpinner($localize`Loading latest message...`);
    this.socialService.fetchMessagesFromConversation(this.conversationUid!, 10).then(wrapper => {
      this.disableLoadingSpinner();
      if (wrapper.data)
        this.messages = wrapper.data;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
      this.lastVisible = wrapper.lastVisible;

      // If there are 10 messages, there are probably more. If there are less than 10, there are no more messages
      this.thereArePreviousMessages = wrapper.data?.length === 10;
      this.sortMessages();

      this.streamMessages();

    });
  }

  private markAsRead() {
    if (this.senderUid && this.conversationUid)
      this.socialService.markConversationAsRead(this.senderUid, this.conversationUid, (conversation => {
        this.conversation = conversation;
      }), error => this.addError(error));
  }
}
