import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {TitleService} from '../../shared/services/title.service';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {PayService} from '../../pay/pay.service';
import {User} from '../../shared/models/user.interface';
import {UserSettings} from '../../shared/models/userSettings.interface';
import Locale from '../../shared/services/locale';
import {UtilService} from '../../shared/util.service';
import Util from '../../shared/util';
import {Subject} from 'rxjs';
import {MatStepper} from '@angular/material/stepper';
import {LayoutService} from '../../layout/layout.service';
import {StoreReferral} from '../../layout/store/layout.reducer';
import {environment} from '../../../environments/environment';
import {FunctionsService} from '../../shared/services/functions.service';
import {fetchUser, resetUpdateUserState, updateUserMerge} from '../../auth/store/auth.actions';
import {selectReferral} from '../../layout/store/layout.selectors';
import {take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-first-steps',
  templateUrl: './first-steps.component.html',
  styleUrls: ['./first-steps.component.scss'],
})
export class FirstStepsComponent extends BaseComponent implements OnInit {

  selectedIndex: number = 0;
  saveMasterDataSubject = new Subject();
  stepDirectionAfterSaving = 1;

  @ViewChild('stepper') private stepper: MatStepper | undefined;

  constructor(
      protected store: Store<AppState>,
      public payService: PayService,
      public layoutService: LayoutService,
      public utilService: UtilService,
      private functionsService: FunctionsService,
      private titleService: TitleService) {
    super(store);
  }

  referral$ = this.store.select(selectReferral).pipe(takeUntil(this.destroy$));

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`First steps`);
    const userFound$: Subject<null> = new Subject();
    this.user$.pipe(takeUntil(userFound$)).subscribe(user => {

      if (user?.uid && user.creationDate) {
        userFound$.next();
        this.setDefaultValues(user.uid, user);
      } else {
        setTimeout(args => {
          this.store.dispatch(fetchUser());
        }, 2500);
      }
    });
  }

  /**
   * Called, when the stepper step is changed.
   * Performs some validations and then calls the function to update the appropriate part of the listing on the server.
   * @param event selection change event containing event.selectedIndex and event.previouslySelectedIndex.
   */
  onSelectionChange(event: StepperSelectionEvent): void {
    this.clearAlerts();
    this.selectedIndex = event.selectedIndex;
  }

  finish() {
    const domain = Util.getDomain();
    const lang = Locale.getCurrentLang();
    this.utilService.redirectExternal(domain + '/' + lang);
  }

  onMasterDataSaved($event: User) {
    if (this.stepDirectionAfterSaving > 0)
      this.stepper?.next();
    if (this.stepDirectionAfterSaving < 0)
      this.stepper?.previous();
  }

  onSavingMasterDataFailed($event: string) {
    this.utilService.scrollToId('master-data');
  }

  saveMasterData(stepDirectionAfterSaving: number) {
    this.saveMasterDataSubject.next();
    this.stepDirectionAfterSaving = stepDirectionAfterSaving;
  }

  /**
   * Sets default values for the given user, like. the default language.
   * @param userUid user, whose default values should be set
   */
  private setDefaultValues(userUid: string, user: User) {

    let somethingWasChanged = false;

    const settings: UserSettings = user.settings ? {...user.settings} : {};

    if (!settings.lang) {
      settings.lang = Locale.languageLocale();
      console.log(`Setting default lang to ${settings.lang} based on the current URL or browser setting.`);
      somethingWasChanged = true;
    }
    if (!settings.timeZone) {
      settings.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (!settings.timeZone)
        settings.timeZone = Locale.defaultTimeZone();
      console.log(`Setting time zone to ${settings.timeZone}.`);
      somethingWasChanged = true;
    }

    if (!user.referralUid) {
      this.referral$.subscribe((referral) => {
        if (!referral || !referral.name || this.isReferralExpired(referral))
          return;
        this.functionsService.setReferralForNewUser(referral.name,
            referralUid => console.log(`referralUid: ${referralUid}`),
            errorMessage => console.error(`Error setting referralUid: ${errorMessage}`));
      });
    }

    user = {...user, settings};

    if (somethingWasChanged) {
      this.store.dispatch(updateUserMerge({userUpdate: user}));

      this.updateUserActionResult$.pipe(take(1)).subscribe(result => {
        this.store.dispatch(resetUpdateUserState());
        if (result.success) {
          console.log(`Successfully set default values for user ${user.uid}`, user);
        }
        if (result.errorMessage) {
          console.error(`Error setting default values for user ${user.uid}: ${result.errorMessage}`, user);
        }
      });

    }

  }

  /**
   * Checkes, if the given referral is expired
   * @param referral referral to be checked
   * @return true, if expired, false otherwise
   */
  private isReferralExpired(referral: StoreReferral) {
    if (!referral?.date)
      return false;
    if (new Date().getTime() - referral.date.getTime() > environment.referralTimeToLiveSecs * 1000)
      return true;
    return false;
  }
}
