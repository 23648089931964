<div class="container px-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
      <li class="breadcrumb-item">
        <span i18n="lending headline">Lending</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="lending headline">Lending</h1>
    <p class="jumbotron-lead" i18n="lending lead text">See, what you are lending to others, including past and future transactions.</p>
    <p><a [routerLink]="['/account','borrowing']" i18n="go to borrowing">Go to borrowing</a></p>
  </div>


  <!-- Show errors -->
  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">logout</mat-icon>
        <span i18n="open transactions">Open</span>
      </ng-template>

      <app-transaction-list [infiniteScrollPercentage]="90" [lending]="true" [open]="true"></app-transaction-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">history</mat-icon>
        <span i18n="completed transactions">Completed</span>
      </ng-template>

      <app-transaction-list [completed]="true" [infiniteScrollPercentage]="90" [lending]="true"></app-transaction-list>
    </mat-tab>
  </mat-tab-group>
</div>
