import {Injectable} from '@angular/core';
import {ImgUrls} from '../models/imgUrls.interface';
import Util from '../util';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {firebaseStorage} from '../../app.module';

export interface FilesUploadMetadata {
  uploadProgress$: Observable<number | undefined>;
  downloadUrl$: Observable<string>;
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {


  constructor(private readonly angularFirestorage: AngularFireStorage) {
  }

  deleteImageFromStorage(imgUrls: ImgUrls): void {
    try {
      // Example for valid deletion:
      // const refFull = firebaseStorage.ref().child('/img/listings/xBOUipA4qWyXvAez8gtH/2021-04-07_14-12-44.4444_20200308_174414.thumb.webp');

      const refFull = firebaseStorage.ref().child(Util.getRefFromUrl(Util.getUrlWithoutParams(imgUrls.full)));
      refFull.delete();
      const refThumb = firebaseStorage.ref().child(Util.getRefFromUrl(Util.getUrlWithoutParams(imgUrls.thumb)));
      refThumb.delete();
    } catch (e) {
      console.error(e);
    }
  }

  uploadFileAndGetMetadata(mediaFolderPath: string, fileToUpload: File): FilesUploadMetadata {
    const {name} = fileToUpload;
    const filePath = `${mediaFolderPath}/${new Date().getTime()}_${name}`;
    const uploadTask: AngularFireUploadTask = this.angularFirestorage.upload(
      filePath,
      fileToUpload,
    );
    return {
      uploadProgress$: uploadTask.percentageChanges(),
      downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
    };
  }

  private getDownloadUrl$(
    uploadTask: AngularFireUploadTask,
    path: string,
  ): Observable<string> {
    return from(uploadTask).pipe(
      switchMap((_) => firebaseStorage.ref(path).getDownloadURL()),
    );
  }
}
