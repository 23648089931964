import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {Announcement} from '../../../shared/models/announcement.interface';
import {UserService} from '../../../shared/services/user.service';
import {CANNOT_BE_UNDONE} from '../../../shared/constants/strings';
import {LayoutService} from '../../../layout/layout.service';
import {UtilService} from '../../../shared/util.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-announcements-editor-list',
  templateUrl: './announcements-editor-list.component.html',
  styleUrls: ['./announcements-editor-list.component.scss'],
})
export class AnnouncementsEditorListComponent extends BaseComponent implements OnInit {

  @Input() announcements: Announcement[] = [];

  @Output() onSelected = new EventEmitter<Announcement>();
  @Output() onDeleted = new EventEmitter<Announcement>();

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      public utilService: UtilService,
      private layoutService: LayoutService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * Selects the given announcement in the parent component.
   * @param announcement
   */
  select(announcement: Announcement) {
    this.onSelected.emit(announcement);
  }

  onDelete(announcement: Announcement) {
    this.clearAlerts();
    if (!announcement.uid) {
      this.addError($localize`Error deleting the announcement. UID is unknown.`);
      return;
    }
    this.enableLoadingSpinner($localize`Deleting announcement...`);
    this.utilService.showConfirmDialog($localize`Are you sure you want to delete this announcement?`, CANNOT_BE_UNDONE, this.delete.bind(this), [announcement], undefined, undefined, undefined, 'no');
  }

  delete(announcement: Announcement): void {
    this.layoutService.deleteAnnouncement(announcement.uid!).then(() => {
          this.disableLoadingSpinner();
          this.onDeleted.emit(announcement);
        },
        reason => {
          this.disableLoadingSpinner();
          this.addError($localize`Error deleting the announcement\: ${reason}`);
        });

  }
}
