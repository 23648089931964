<div class="component-container">
  <button (click)="onCancel()" aria-label="Cancel" class="cancel-button" i18n-aria-label="Cancel" i18n-matTooltip="Cancel"
          id="write-message" mat-icon-button matTooltip="Cancel">
    <mat-icon>close</mat-icon>
  </button>
  <!-- Show errors -->
  <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <form (ngSubmit)="sendMessage(messageRef)" *ngIf="!messageWasSent">
    <mat-form-field class="mat-form-textarea-w-100">
      <mat-label i18n="message">Message</mat-label>
      <textarea #messageRef [maxLength]="messageMaxLength" cdkAutosizeMaxRows="25" cdkAutosizeMinRows="2" cdkTextareaAutosize
                i18n-placeholder="message input placeholder" matInput placeholder="Type your message" tabindex="3"></textarea>
      <span class="character-counter">{{messageRef.value.length}}/{{messageMaxLength}}</span>
    </mat-form-field>
    <div class="button-container">

      <button aria-label="Send message" class="send-button" color="primary" i18n-aria-label="Send messages" mat-mini-fab
              type="submit">
        <mat-icon>send</mat-icon>
      </button>

    </div>
  </form>

  <div *ngIf="messageWasSent" class="alert alert-success" i18n="Your message was successfully sent.|Your message was successfully sent.">Your message was
    successfully sent.
  </div>
</div>
