import {createSelector} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';
import {Currency} from '../models/currency.interface';
import {Category} from '../models/category.interface';

export const selectShared = (state: AppState) => state.shared;

export const selectUsersPublic = createSelector(
    selectShared,
    (sharedState) => sharedState.usersPublic,
);

export const selectCurrencies = createSelector(
    selectShared,
    (sharedState) => ({
      currencies: sharedState.currencies,
      currenciesFetchDate: sharedState.currenciesFetchDate,
    }),
);

export const selectCategories = createSelector(
    selectShared,
    (sharedState) => ({
      categories: sharedState.categories,
      categoriesFetchDate: sharedState.categoriesFetchDate,
    }),
);

export interface CurrenciesWrapper {
  currencies: Currency[];
  currenciesFetchDate?: Date;
}

export interface CategoriesWrapper {
  categories: Category[];
  categoriesFetchDate?: Date;
}
