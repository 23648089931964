<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [message]="loadingSpinnerMessage"
                     [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>
<app-alerts [alerts]="alerts" [float]="true" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<div *ngIf="rating && rater && receiver" class="no-underline">
  <div class="row mx-0 p-0 my-2 p-1 jumbotron bg-rating">
    <div *ngIf="receiver.imgUrlThumb" [routerLink]="['/social','user',receiver?.uid]" class="m-0 p-0">
      <a [routerLink]="['/social','user',receiver?.uid]">
        <img [src]="receiver.imgUrlThumb" class="thumbnail"
             default="./assets/images/default/default-user-w300.webp">
      </a>
    </div>
    <div class="col mx-2 p-0">

      <div class="row">
        <div *ngIf="  receiver.displayName" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="receiver name">Receiver</span>: <br class="d-none d-lg-block">{{receiver.displayName}}
          </p>
        </div>
        <div *ngIf="receiverRatingSummary && receiverRatingSummary.overallScore" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="receiver rating">Receiver rating</span>: <br class="d-none d-lg-block">
            <app-bs-star-rating [rating]="receiverRatingSummary.overallScore" class="d-inline d-md-none" size="small"></app-bs-star-rating>
            <app-bs-star-rating [rating]="receiverRatingSummary.overallScore" class="d-none d-md-inline" size="medium"></app-bs-star-rating>
            <span *ngIf="receiverRatingSummary.overallCount" class="ml-1">({{receiverRatingSummary?.overallCount}})</span>
          </p>
        </div>
        <div *ngIf="rating.receiverRole" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="receiver role">Receiver role</span>: <br class="d-none d-lg-block">
            <span *ngIf="rating.receiverRole === 'borrower'" i18n="borrower">Borrower</span>
            <span *ngIf="rating.receiverRole === 'lender'" i18n="lender">Lender</span>
          </p>
        </div>
        <div *ngIf="rater.displayName" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="rater name">Rater name</span>: <br class="d-none d-lg-block">{{rater.displayName}}
          </p>
        </div>
        <div *ngIf="raterRatingSummary && raterRatingSummary.overallScore" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="rater rating">Rater rating</span>: <br class="d-none d-lg-block">
            <app-bs-star-rating [rating]="raterRatingSummary.overallScore" class="d-inline d-md-none" size="small"></app-bs-star-rating>
            <app-bs-star-rating [rating]="raterRatingSummary.overallScore" class="d-none d-md-inline" size="medium"></app-bs-star-rating>
            <span *ngIf="raterRatingSummary.overallCount" class="ml-1">({{raterRatingSummary?.overallCount}})</span>
          </p>
        </div>
        <div *ngIf="rating.receiverRole" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="rater role">Rater role</span>: <br class="d-none d-lg-block">
            <span *ngIf="rating.receiverRole === 'lender'" i18n="borrower">Borrower</span>
            <span *ngIf="rating.receiverRole === 'borrower'" i18n="lender">Lender</span>
          </p>
        </div>
        <div *ngIf="rating.date" class="col-auto data-col">
          <p class="message">
            <span class="data-headline" i18n="creation date">Creation date</span>: <br
            class="d-none d-lg-block">{{utilService.getDate(rating.date) | moment:'LLL'}}
          </p>
        </div>
        <div *ngIf="rating.embeddedListing && rating.embeddedListing.name && rating.embeddedListing.uid" class="col-auto data-col">
          <p class="message">
            <a [routerLink]="['/browse','listing',rating.embeddedListing.uid]" class="link"><span class="data-headline"
                                                                                                  i18n="listing">Listing</span>: <br
              class="d-none d-lg-block">{{rating.embeddedListing.name}}</a>
          </p>
        </div>
        <div *ngIf="rating.transactionUid" class="col-auto data-col">
          <p class="message">
            <a [routerLink]="['/account','transactions',rating.transactionUid]" class="link"><span class="data-headline"
                                                                                                   i18n="transaction">Transaction</span>: <br
              class="d-none d-lg-block"><small>{{rating.transactionUid}}</small></a>
          </p>
        </div>
      </div>
      <a [routerLink]="['/social','rating',rating.uid]" class="link">
        <div class="row">
          <div class="col">
            <app-rating [rating]="rating" [showListing]="false"></app-rating>
          </div>
        </div>
      </a>

    </div>
    <div *ngIf="rating.embeddedListing && rating.embeddedListing.imgUrlThumb && rating.embeddedListing.uid" class="m-0 p-0 right">
      <a [routerLink]="['/browse','listing',rating.embeddedListing.uid]">
        <img [src]="rating.embeddedListing.imgUrlThumb" class="thumbnail" default="./assets/images/default/default-listing-w300.webp">
      </a>
    </div>

  </div>
</div>

