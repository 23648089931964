import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import {setPaymentParams} from './store/pay.actions';
import {Transaction} from '../shared/models/transaction.interface';
import {UserPublic} from '../shared/models/userPublic.interface';
import {TransactionListing} from '../shared/models/transactionListing.interface';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PayService {

    constructor(private store: Store<fromApp.AppState>) {
    }

    isPaymentActive() {
        return environment.activePaymentProviders.length > 0;
    }

    setPaymentParams(amount: number, currencyId: string, transaction?: Transaction, listing?: TransactionListing, receiver?: UserPublic): void {
        this.store.dispatch(setPaymentParams({amount, currencyId, transaction, listing, receiver}));
    }

}
