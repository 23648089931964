import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {UserService} from '../../../shared/services/user.service';
import {SharedService} from '../../../shared/services/shared.service';
import {SocialService} from '../../../social/social.service';
import {Conversation} from '../../../shared/models/conversation.interface';
import {UserPublic} from '../../../shared/models/userPublic.interface';
import {Message} from '../../../shared/models/message.interface';
import {UtilService} from '../../../shared/util.service';
import {AppState} from '../../../store/app.reducer';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-conversation-preview',
  templateUrl: './conversation-preview.component.html',
  styleUrls: ['./conversation-preview.component.scss'],
})
export class ConversationPreviewComponent extends BaseComponent implements OnInit {

  sender?: UserPublic;
  receiver?: UserPublic;
  latestMessage?: Message;
  me?: UserPublic;
  other?: UserPublic;

  constructor(protected store: Store<AppState>,
              private userService: UserService,
              public utilService: UtilService,
              private sharedService: SharedService,
              public socialService: SocialService,
  ) {
    super(store);
  }

  _conversation?: Conversation;

  get conversation(): Conversation | undefined {
    return this._conversation;
  }

  @Input() set conversation(conversation: Conversation | undefined) {
    this._conversation = conversation;
    this.loadData(conversation);
  }

  private loadData(conversation: Conversation | undefined) {
    if (conversation?.user1Uid)
      this.userService.fetchUserPublic(conversation.user1Uid).then(wrapper => {
        this.sender = wrapper.data;
        this.user$.subscribe(user => {
          if (this.sender?.uid === user?.uid)
            this.me = this.sender;
          else
            this.other = this.sender;
        });

        if (wrapper.errorMessage)
          this.addError($localize`We could not load the sender\: ${wrapper.errorMessage}`);
      });
    if (conversation?.user2Uid)
      this.userService.fetchUserPublic(conversation.user2Uid).then(wrapper => {
        this.receiver = wrapper.data;
        this.user$.subscribe(user => {
          if (this.receiver?.uid === user?.uid)
            this.me = this.receiver;
          else
            this.other = this.receiver;
        });
        if (wrapper.errorMessage)
          this.addError($localize`We could not load the receiver\: ${wrapper.errorMessage}`);
      });

    if (conversation?.uid)
      this.socialService.fetchMessagesFromConversation(conversation.uid, 1).then(wrapper => {
        if (wrapper?.data)
          this.latestMessage = wrapper.data[0];
        if (wrapper.errorMessage)
          this.addError($localize`We could not load the latest message\: ${wrapper.errorMessage}`);
      });
  }

}
