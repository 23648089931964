import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-img-with-blurry-background',
    templateUrl: './img-with-blurry-background.component.html',
    styleUrls: ['./img-with-blurry-background.component.scss'],
})
export class ImgWithBlurryBackgroundComponent implements OnInit {

    @Input() alt = '';
    @Input() default = '';
    @Input() src = '';
    @Input() height?: number;
    @Input() width?: number;
    @Input() maxHeight?: number;
    @Input() maxWidth?: number;

    constructor() {
    }


    ngOnInit(): void {
    }

}
