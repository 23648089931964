import {layoutReducer, LayoutState} from '../layout/store/layout.reducer';
import {browserReducer, BrowserState} from '../browser/store/browser.reducer';
import {sharedReducer, SharedState} from '../shared/store/shared.reducer';
import {listingReducer, ListingState} from '../listing/store/listing.reducer';
import {bookReducer, BookState} from '../book/store/book.reducer';
import {payReducer, PayState} from '../pay/store/pay.reducer';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';
import {authReducer, AuthState} from '../auth/store/auth.reducer';

export interface AppState {
  auth: AuthState;
  book: BookState;
  browser: BrowserState;
  layout: LayoutState;
  listing: ListingState;
  pay: PayState;
  shared: SharedState;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: authReducer,
  book: bookReducer,
  browser: browserReducer,
  layout: layoutReducer,
  listing: listingReducer,
  pay: payReducer,
  shared: sharedReducer,
};

/**
 * Writes the specified store data into the local session storage and retrieves it on app start. Therefore, the state survives a browser refresh.
 * @see https://github.com/btroncone/ngrx-store-localstorage
 * @param reducer reducer
 */
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const keys = ['auth', 'book', 'browser', 'shared', 'layout', 'listing', 'pay'];
  return localStorageSync({keys, rehydrate: true, storage: localStorage})(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

