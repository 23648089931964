<div align="center">
  <app-loading-spinner *ngIf="showSpinner" class="spinner"></app-loading-spinner>

  <image-cropper (cropperReady)="cropperReady($event)"
                 (imageCropped)="imageCropped($event)"
                 (imageLoaded)="imageLoaded($event)"
                 (loadImageFailed)="loadImageFailed()"
                 *ngIf="img"
                 [alignImage]="'left'"
                 [aspectRatio]="16 / 9"
                 [canvasRotation]="canvasRotation"
                 [cropperMinHeight]="600"
                 [cropperMinWidth]="600"
                 [disabled]="disabled"
                 [imageFile]="img"
                 [imageQuality]="50"
                 [maintainAspectRatio]="false"
                 [onlyScaleDown]="true"
                 [resizeToHeight]="maxHeight"
                 [resizeToWidth]="maxWidth"
                 [style.display]="showCropper ? null : 'none'"
                 [transform]="transform"
                 format="webp">
  </image-cropper>

  <div *ngIf="!showSpinner">
    <button (click)="rotateLeft()" color="primary"
            i18n-matTooltip="tooltip rotate left" id="rotateLeftButton"
            mat-icon-button matTooltip="Rotate left">
      <mat-icon>rotate_left</mat-icon>
    </button>
    <button (click)="rotateRight()" color="primary"
            i18n-matTooltip="tooltip rotate right" id="rotateRightButton"
            mat-icon-button matTooltip="Rotate right">
      <mat-icon>rotate_right</mat-icon>
    </button>
    <button (click)="flipHorizontal()" color="primary"
            i18n-matTooltip="tooltip flip horizontally" id="flipHorizontalButton"
            mat-icon-button matTooltip="Flip horizontally">
      <mat-icon>swap_horiz</mat-icon>
    </button>
    <button (click)="flipVertical()" color="primary"
            i18n-matTooltip="tooltip flip vertically" id="flipVerticalButton"
            mat-icon-button matTooltip="Flip vertically">
      <mat-icon>swap_vert</mat-icon>
    </button>
    <button (click)="zoomIn()" color="primary"
            i18n-matTooltip="tooltip zoom in" id="zoomInButton"
            mat-icon-button matTooltip="Zoom in">
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button (click)="zoomOut()" color="primary"
            i18n-matTooltip="tooltip zoom out" id="zoomOutButton"
            mat-icon-button matTooltip="Zoom out">
      <mat-icon>zoom_out</mat-icon>
    </button>
    <button (click)="resetImage()" color="primary"
            i18n-matTooltip="tooltip reset picture" id="resetButton"
            mat-icon-button matTooltip="Reset the picture">
      <mat-icon>restart_alt</mat-icon>
    </button>
    <button (click)="openPreview()" color="primary"
            i18n-matTooltip="tooltip preview of cropped picture" id="previewButton"
            mat-icon-button matTooltip="Show a preview of the cropped picture">
      <mat-icon>zoom_out_map</mat-icon>
    </button>
    <button (click)="deleteImage()" color="warn"
            i18n-matTooltip="tooltip delete image" id="deleteButton"
            mat-icon-button matTooltip="Delete this image">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
