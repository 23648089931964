export enum TransactionState {
  // The borrower wants to book the item. Or the borrower wants to change the booking time, before the lender replied.
  // Next action: lender
  // Possible next states: BookingDenied, BookingAccepted
  BookingRequested = 'BookingRequested',

  // The lender denied the booking of the item.
  // Final state.
  BookingDenied = 'BookingDenied',

  // Borrower and lender agreed on the booking. This can be the result of the states BookingRequested and
  // BookingPeriodSuggestedByBorrower.
  // This state is also used, if either lender or borrow wants to undo a pickup request. This can be the result of the states ItemPickUpRequestedByBorrower and
  // ItemPickUpRequestedByLender.
  // Next action: borrower, lender
  // Possible next states borrower: ItemPickUpRequestedByBorrower, BookingCancelled
  // Possible next states lender: ItemPickUpRequestedByLender, BookingCancelled
  BookingAccepted = 'BookingAccepted',

  // Either the borrower or the lender cancelled the booking.
  // Final state.
  BookingCancelled = 'BookingCancelled',

  // The lender confirms they picked the item up from the borrower. They also confirm its state.
  // To undo this step, they can go back to BookingAccepted, as long as the borrower has not yet confirmed the pickup.
  // Next action: borrower, lender
  // Possible next states borrower: ItemPickedUp, BookingCancelled
  // Possible next states lender: BookingAccepted, BookingCancelled
  ItemPickUpRequestedByLender = 'ItemPickUpRequestedByLender',

  // The borrower confirms the lender picked the item up from them.
  // To undo this step, they can go back to BookingAccepted, as long as the lender has not yet confirmed the pickup.
  // Next action: borrower, lender
  // Possible next states borrower: BookingAccepted, BookingCancelled
  // Possible next states lender: ItemPickedUp, BookingCancelled
  ItemPickUpRequestedByBorrower = 'ItemPickUpRequestedByBorrower',

  // The item has been picked up by the borrower and both lender and borrower have confirmed that. actualPickupDate is set, when this state is activated.
  // Next action: borrower, lender
  // Possible next states borrower: ItemReturnRequestedByBorrower
  // Possible next states lender: ItemReturnRequestedByLender
  ItemPickedUp = 'ItemPickedUp',

  // The lender confirms they received the item back from the borrower in the agreed upon condition.
  // Next action: borrower
  // Possible next state: ItemReturned
  ItemReturnRequestedByLender = 'ItemReturnRequestedByLender',

  // The borrower confirms they returned the item back to the lender in the agreed upon condition.
  // Next action: lender
  // Possible next state: ItemReturned
  ItemReturnRequestedByBorrower = 'ItemReturnRequestedByBorrower',

  // The item has been returned by the borrower and both lender and borrower have confirmed that. actualReturnDate is set, when this state is activated.
  // Final state.
  ItemReturned = 'ItemReturned',
}
