import {Pipe, PipeTransform} from '@angular/core';

type KYCDocumentRefusedReasonType =
  | 'DOCUMENT_UNREADABLE'
  | 'DOCUMENT_NOT_ACCEPTED'
  | 'DOCUMENT_HAS_EXPIRED'
  | 'DOCUMENT_INCOMPLETE'
  | 'DOCUMENT_MISSING'
  | 'DOCUMENT_DO_NOT_MATCH_USER_DATA'
  | 'DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA'
  | 'DOCUMENT_FALSIFIED'
  | 'UNDERAGE_PERSON'
  | 'SPECIFIC_CASE';

@Pipe({
  name: 'mangopayRefusedReasonType',
})
export class MangopayRefusedReasonTypePipe implements PipeTransform {

  transform(value: KYCDocumentRefusedReasonType, ...args: unknown[]): unknown {
    switch (value) {
      case 'SPECIFIC_CASE':
        return $localize`Specific case.`;
      case 'DOCUMENT_DO_NOT_MATCH_USER_DATA':
        return $localize`Document does not match user data.`;
      case 'DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA':
        return $localize`Document does not match account data.`;
      case 'DOCUMENT_FALSIFIED':
        return $localize`Document was falsified.`;
      case 'DOCUMENT_HAS_EXPIRED':
        return $localize`Document has expired.`;
      case 'DOCUMENT_INCOMPLETE':
        return $localize`Document is incomplete.`;
      case 'DOCUMENT_MISSING':
        return $localize`Document is missing.`;
      case 'DOCUMENT_NOT_ACCEPTED':
        return $localize`Document was not accepted.`;
      case 'DOCUMENT_UNREADABLE':
        return $localize`Document is unreadable.`;
      case 'UNDERAGE_PERSON':
        return $localize`Underage person.`;
    }
    return $localize`Unknown`;
  }

}
