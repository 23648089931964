import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment',
})
export class MomentPipe implements PipeTransform {
  transform(date: Date | number, format: string = 'L'): any {
    if (date instanceof Number)
      date = new Date(date);
    const myMoment = moment(date);
    return myMoment.format(format);
  }

}

