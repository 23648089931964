import {Component, OnInit} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../../shared/components/base/base.component';
import {LayoutService} from '../layout.service';
import {AnalyticsEventName, AnalyticsService} from '../../shared/services/analytics.service';

@Component({
  selector: 'app-ref',
  templateUrl: './ref.component.html',
  styleUrls: ['./ref.component.scss'],
})
export class RefComponent extends BaseComponent implements OnInit {

  constructor(protected store: Store<AppState>,
              private router: Router,
              private layoutService: LayoutService,
              private analyticsService: AnalyticsService,
              private activatedRoute: ActivatedRoute) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).pipe(
        map(params => {
          return params.get('refName');
        }),
    ).subscribe(
        (refName: string | null) => {
          if (refName)
            this.onRefLinkCalled(refName);
          this.navigateToStartPage();
        },
        (error) => console.error(error),
    );
  }

  private navigateToStartPage() {
    this.router.navigate(['']);
  }

  private onRefLinkCalled(refName: string) {

    this.analyticsService.logEvent(AnalyticsEventName.ACCOUNT_CREATED, {name: refName});
    this.layoutService.setRefName(refName);
  }
}
