<table *ngIf="rating" class="table-responsive">
  <tr>
    <th>
      <app-rating-criterion-headline [criterion]="ratingCriterion.Overall" [receiverName]="receiverName"></app-rating-criterion-headline>
    </th>
    <td>
      <star-rating (ratingChange)="rating.overall = $event.rating" [rating]="rating.overall" [readOnly]="true" [showHalfStars]="true"
                   [starType]="'svg'" size="medium" style="display: inline-flex"></star-rating>
    </td>
  </tr>
  <tr *ngIf="rating.receiverRole === transactionRole.Lender">
    <th>
      <app-rating-criterion-headline [criterion]="ratingCriterion.ItemAsDescribed" [receiverName]="receiverName"></app-rating-criterion-headline>
    </th>
    <td>
      <star-rating (ratingChange)="rating.itemAsDescribed = $event.rating" [rating]="rating.itemAsDescribed" [readOnly]="true"
                   [showHalfStars]="true"
                   [starType]="'svg'" size="medium" style="display: inline-flex;"></star-rating>
    </td>
  </tr>
  <tr *ngIf="rating.receiverRole === transactionRole.Borrower">
    <th>
      <app-rating-criterion-headline [criterion]="ratingCriterion.ItemReturnCondition" [receiverName]="receiverName"></app-rating-criterion-headline>
    </th>
    <td>
      <star-rating (ratingChange)="rating.itemReturnCondition = $event.rating" [rating]="rating.itemReturnCondition" [readOnly]="true"
                   [showHalfStars]="true"
                   [starType]="'svg'" size="medium" style="display: inline-flex;"></star-rating>
    </td>
  </tr>
  <tr *ngIf="rating.punctuality">
    <th>
      <app-rating-criterion-headline [criterion]="ratingCriterion.Punctuality" [receiverName]="receiverName"></app-rating-criterion-headline>
    </th>
    <td>
      <star-rating (ratingChange)="rating.punctuality = $event.rating" [rating]="rating.punctuality" [readOnly]="true" [showHalfStars]="true"
                   [starType]="'svg'" size="medium" style="display: inline-flex;"></star-rating>
    </td>
  </tr>
  <tr *ngIf="rating.friendliness">
    <th>
      <app-rating-criterion-headline [criterion]="ratingCriterion.Friendliness" [receiverName]="receiverName"></app-rating-criterion-headline>
    </th>
    <td>
      <star-rating (ratingChange)="rating.friendliness = $event.rating" [rating]="rating.friendliness" [readOnly]="true" [showHalfStars]="true"
                   [starType]="'svg'" size="medium" style="display: inline-flex; justify-content: start; align-content: start"></star-rating>
    </td>
  </tr>

</table>
