import {createSelector} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';

export const selectListing = (state: AppState) => state.listing;

export const selectListings = createSelector(
    selectListing,
    (listingState) => listingState.listings,
);

export const selectListingsPrivate = createSelector(
    selectListing,
    (listingState) => listingState.listingsPrivate,
);

