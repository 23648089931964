import {Component, HostListener, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-modal-content',
  templateUrl: './image-modal-content.component.html',
  styleUrls: ['./image-modal-content.component.css'],
})
export class ImageModalContentComponent implements OnInit {
  @Input() imgUrl: string | undefined;

  constructor(public activeModal: NgbActiveModal) {
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ESC') {
      this.activeModal.dismiss('ESC press');
    }
  }

  ngOnInit(): void {
  }

}
