<div class="container-fluid bg-container p-0 pt-1">

  <div class="px-1 pb-3">
    <div class="jumbotron mb-3">
      <h1 class="jumbotron-headline" i18n="latest listings">Latest listings</h1>
      <p class="jumbotron-lead" i18n="latest listings lead text on browse page">Browse through the latest listings.</p>
    </div>

    <div class="d-none d-lg-block">
      <app-search-panel [navigateToCategoryOnCategorySelection]="true" [navigateToSearchPage]="false" sortIndex="creationDate_desc"
                        styleClass="row jumbotron px-0 m-0 py-2"></app-search-panel>
    </div>

    <!-- Show error, if no listings could be loaded -->

    <div *ngIf="error" class="alert alert-danger" i18n="latest listings error message" role="alert">
      The latest listings could not be loaded: {{error}}
    </div>

    <!-- Show latest listings  -->
    <app-listings-view (loadMoreListings)="loadMoreListings($event)" [infiniteScrollPercentage]="90" [listings]="listings" [periodInDays]="periodInDays"
                       [showMyListingMarker]="true" [thereIsMore]="thereIsMore" [totalCount]="totalCount"></app-listings-view>
    <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true"></app-loading-spinner>

  </div>
</div>
