import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

@Directive({
  selector: '[appRange]',
  providers: [{provide: NG_VALIDATORS, useExisting: RangeDirective, multi: true}],
})
export class RangeDirective implements Validator {
  @Input() appRange: { min: number, max: number } = {min: 0, max: 0};

  validate(control: AbstractControl): ValidationErrors | null {
    return this.appRange ? rangeValidator(this.appRange)(control)
      : null;
  }
}

export function rangeValidator(range: { min: number; max: number }): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const gte = control.value > range.max || control.value < range.min;
    return gte ? {appRange: {value: control.value}} : null;
  };
}
