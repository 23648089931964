import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {Listing} from '../../../shared/models/listing.interface';
import {UserService} from '../../../shared/services/user.service';
import {Transaction} from '../../../shared/models/transaction.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Component({
  selector: 'app-borrower-info',
  templateUrl: './borrower-info.component.html',
  styleUrls: ['./borrower-info.component.css'],
})
export class BorrowerInfoComponent extends BaseComponent implements OnInit {

  @Input() listing ?: Listing;

  showActiveTransactions = false;

  pastRentals: Transaction[] = [];
  activeTransactions: Transaction[] = [];

  constructor(
      protected store: Store<AppState>,
      userService: UserService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  onPastRentalsLoaded(pastRentals: Transaction[]) {
    this.pastRentals = pastRentals;
  }

  onActiveTransactionsLoaded(pastRentals: Transaction[]) {
    this.activeTransactions = pastRentals;
    if (this.activeTransactions.length > 0)
      this.showActiveTransactions = true;
    else
      this.showActiveTransactions = false;
  }
}
