<div class="container bg-container p-1">

  <div *ngIf="!listing && !showLoadingSpinner" class="row">
    <div class="col">
      <div class="jumbotron mb-3">
        <h1 class="jumbotron-headline" i18n="listing headline disabled">Listing page</h1>
        <p class="jumbotron-lead" i18n="listing slogan listing not found">This listing could not be found or does not exist. Maybe it was deleted.</p>
      </div>
    </div>
  </div>

  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <div *ngIf="listing && !(!listing.disabled || listing.lenderUid === user?.uid || user?.admin)" class="row">
    <div class="col">
      <div class="jumbotron mb-3">
        <h1 class="jumbotron-headline" i18n="listing headline disabled">Listing page</h1>
        <p class="jumbotron-lead" i18n="listing slogan listing disabled">This listing is disabled. Only the owner can see it.</p>
      </div>
    </div>
  </div>

  <!-- Loading spinner -->
  <div *ngIf="showLoadingSpinner" class="row">
    <div class="col center-all">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>

  <div *ngIf="listing && (!listing.disabled || listing.lenderUid === user?.uid || user?.admin)">

    <!-- Breadcrumb -->
    <div class="row">
      <div class="col">
        <nav *ngIf="path !== undefined" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <a class="breadcrumb-item" i18n="All categories link in breadcrumb" routerLink="/browse/category">All categories</a>
            <li *ngFor="let category of path, let i = index" class="breadcrumb-item">
              <a [routerLink]="['/browse','category',category.id]">{{categoryService.getCategoryName(category)}}</a>
            </li>
            <li aria-current="page" class="breadcrumb-item">{{removeEmTags(this.listing.name) | shorten:30:true}}</li>
          </ol>
        </nav>
      </div>
    </div>

    <!-- Jumbotron with name and summary -->
    <div class="row">
      <div class="col">
        <div [class]="listing.disabled?'bg-gray':''" class="jumbotron mb-3">
          <h1 [innerHTML]="listing.name" class="jumbotron-headline"></h1>
          <p [innerHTML]="listing.summary" class="lead"></p>
          <app-listing-disabled-info *ngIf="listing.disabled"></app-listing-disabled-info>
          <app-manage (listingChanged)="onListingChanged($event)"
                      *ngIf="!previewMode && (firebaseUser && listing.lenderUid === firebaseUser.uid || user?.admin)"
                      [listing]="listing"></app-manage>
        </div>
      </div>
    </div>

    <app-borrower-info *ngIf="!previewMode && (firebaseUser && listing.lenderUid !== firebaseUser.uid)" [listing]="listing"></app-borrower-info>

    <!-- Image and booking form -->
    <div class="row">
      <!-- Image -->
      <div *ngIf="listing.imgUrls" class="col-lg-6 col-sm-12 mb-3">
        <ngb-carousel #imageCarousel *ngIf="listing.imgUrls.length>0">
          <ng-template (slid)="activeImageIndex = i" *ngFor="let imageUrl of listing.imgUrls; index as i" id="{{i}}" ngbSlide>
            <div class="wrapper">
              <img (click)="openImage(i, listing.imgUrls)"
                   *ngIf="isImageActive(i)" [src]="imageUrl.full" alt="Image {{i+1}} of listing {{listing?.name}}" class="contained-300h-300w"
                   default="./assets/images/default/default-listing-w300.webp"
                   i18n-alt="Alt text of listing image in image detail component for multiple images">
            </div>
          </ng-template>
        </ngb-carousel>
        <br>
        <div class="thumbnail-container m-0 p-0">
          <app-thumbnail-widget (imgClicked)="selectImage($event.index)" [imgUrls]="listing.imgUrls"></app-thumbnail-widget>
        </div>
      </div>


      <div *ngIf="!previewMode" class="col-lg-6 col-sm-12 mb-3">
        <!-- Booking form with validation messages and booking price table-->
        <div *ngIf="user?.uid !== listing.lenderUid && bookForm">
          <h1 class="paragraph-headline" i18n="booking headline on listing view">Booking</h1>
          <form (ngSubmit)="onBook()" [appDateRangePicker]="{rentParams: rentParams, listing: listing, availableDayTimestamps: availableDayTimestamps,
                dateRangeInvalidDates: dateRangeInvalidDates}" [formGroup]="bookForm">
            <div class="container p-0 m-0">
              <div class="row">
                <div class="col">
                  <mat-form-field>
                    <mat-label i18n="rent date range picker placeholder">Rent period</mat-label>
                    <mat-date-range-input [dateFilter]="availabilityFilter" [max]="maxDate" [min]="minDate" [rangePicker]="picker">
                      <input formControlName="dateFrom"
                             i18n-placeholder="rent date range picker rent from placeholder" id="dateFrom" matStartDate
                             placeholder="Rent from">
                      <input formControlName="dateUntil"
                             i18n-placeholder="rent date range picker rent until placeholder" id="dateUntil" matEndDate
                             placeholder="Rent until">
                    </mat-date-range-input>
                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="material-time-picker">
                    <mat-form-field>
                      <mat-label i18n="pick-up time">Pick-up time</mat-label>
                      <input [format]="timePickerFormat" [ngxTimepicker]="timeFromPicker" formControlName="timeFrom" matInput readonly>
                      <ngx-material-timepicker #timeFromPicker [minutesGap]="5"></ngx-material-timepicker>
                      <ngx-material-timepicker-toggle [for]="timeFromPicker" matSuffix><span class="material-icons">schedule</span>
                      </ngx-material-timepicker-toggle>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col">
                  <div class="material-time-picker">
                    <mat-form-field>
                      <mat-label i18n="return time">Return time</mat-label>
                      <input [format]="timePickerFormat" [ngxTimepicker]="timeUntilPicker" formControlName="timeUntil" matInput readonly>
                      <ngx-material-timepicker #timeUntilPicker [minutesGap]="5"></ngx-material-timepicker>
                      <ngx-material-timepicker-toggle [for]="timeUntilPicker" matSuffix><span class="material-icons">schedule</span>
                      </ngx-material-timepicker-toggle>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <app-listing-date-range-validation-messages *ngIf="bookForm?.touched" [bookForm]="bookForm"
                                                        [dateRangeInvalidDates]="dateRangeInvalidDates"
                                                        [listing]="listing" [rentParams]="rentParams"></app-listing-date-range-validation-messages>

            <app-booking-price-table [bookForm]="bookForm" [currencyId]="currencyId" [rentParams]="rentParams"></app-booking-price-table>

            <button [disabled]="previewMode" class="btn btn-primary w-100"
                    i18n="book button" type="submit">Book now
            </button>
          </form>
          <br>
        </div>

      </div>
    </div>


    <!-- Description -->
    <div *ngIf="listing.description" class="row mb-3">
      <div class="col">
        <h1 class="paragraph-headline" i18n="description headline on listing view">Description</h1>
        <p [innerHTML]="bypassSecurityTrustHtml(listing.description)"></p>
      </div>
    </div>

    <!-- Price table -->
    <div class="row mb-3">
      <div class="col">
        <app-listing-price-table [currencyId]="currencyId" [listing]="listing"></app-listing-price-table>
      </div>
    </div>

    <!-- Address -->
    <div class="row mb-3">
      <div class="col">
        <h1 class="paragraph-headline" i18n="Address headline">Address</h1>
        <app-public-address [address]="listing?.address"></app-public-address>
        <app-map *ngIf="listing && listing.geohash" [disabled]="true" [geohash]="listing.geohash" [showMarker]="false"></app-map>
      </div>
    </div>

    <!-- Lender -->
    <div class="row mb-3">
      <div class="col w-100">
        <h1 class="paragraph-headline" i18n="lender headline">Lender</h1>
        <app-user-info [listingUid]="listing.uid" [userPublicUid]="listing.lenderUid"></app-user-info>
      </div>
    </div>

    <!-- Listing date -->
    <div class="row">
      <div class="col">
        <h1 class="paragraph-headline" i18n="listing date headline">Listing date</h1>
        <p>{{utilService.getDate(listing.creationDate) | moment:'LLLL'}}<br>
          <span *ngIf="listing.lastEditDate" [title]="utilService.getDate(listing.lastEditDate) | moment:'LLLL'" class="small" i18n="last edit date">
          Last edit {{utilService.createFromNowString(utilService.getDate(listing.lastEditDate))}}.</span>
        </p>
      </div>
    </div>

    <!-- Report listing -->
    <div *ngIf="!previewMode" class="row">
      <div class="col">
        <h1 class="paragraph-headline" i18n="report listing headline">Report listing</h1>
        <p i18n>In case, you find this listing inappropriate, offensive or illegal, please report it:</p>
        <app-report-button [uid]="listing.uid" appearance="button" type="listing"></app-report-button>
      </div>
    </div>

  </div>

</div>
