import {Component, Input, OnInit} from '@angular/core';
import Locale from '../../services/locale';

@Component({
  selector: 'app-bs-star-rating',
  templateUrl: './bs-star-rating.component.html',
  styleUrls: ['./bs-star-rating.component.css'],
})
export class BsStarRatingComponent implements OnInit {

  numberFormatLocale = Locale.numberFormatLocale();
  @Input() rating: number | undefined;
  @Input() size = 'small';

  constructor() {
  }

  ngOnInit(): void {
  }

}
