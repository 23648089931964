<div class="virtual-scroll-y-container-dialog">
  <h1 [innerHTML]="title" class="paragraph-headline" mat-dialog-title></h1>

  <div mat-dialog-content>
    <p [innerHTML]="message"></p>
  </div>

  <div class="mb-3">
    <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
  </div>

  <form (ngSubmit)="onConfirm()" [formGroup]="notifyForm">
    <mat-checkbox color="primary" formControlName="notify" required>
      <span *ngIf="listing" i18n>Notify the listing owner.</span>
      <span *ngIf="rating" i18n>Notify the rating owner.</span>
      <span *ngIf="!listing && !rating" i18n>Notify the owner.</span>
    </mat-checkbox>
    <br>
    <ng-container *ngIf="notifyForm.value.notify">
      <mat-form-field *ngIf="notifyForm.value.notify" class="mat-form-input">
        <mat-label i18n>Reason</mat-label>
        <mat-select formControlName="categoryId" id="category" panelClass="mat-select-panel-500px">
          <mat-option [value]="undefined"></mat-option>
          <mat-option *ngFor="let repCat of reportCategories" [value]="repCat.id">{{repCat.value}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="NgxEditor__Wrapper editor mb-3">

        <div class="editor-toolbar-three-rows">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbarSm1"></ngx-editor-menu>
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbarSm2"></ngx-editor-menu>
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbarSm3"></ngx-editor-menu>
        </div>
        <div class="editor-toolbar-two-rows">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbarMd1"></ngx-editor-menu>
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbarMd2"></ngx-editor-menu>
        </div>
        <ngx-editor-floating-menu [editor]="editor">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
        </ngx-editor-floating-menu>
        <ngx-editor [editor]="editor" formControlName="message" i18n-placeholder="item description input placeholder"
                    maxlength="{{messageMaxLength}}" outputFormat="html" placeholder="A detailed description of the item."></ngx-editor>
      </div>
      <div class="character-counter">{{notifyForm?.value?.message?.length ? notifyForm.value.message.length : 0}}/{{messageMaxLength}}</div>
      <p *ngIf="receiver && receiver.settings && receiver.settings.lang"><span
        i18n>Owner's language:</span> {{languageService.getLanguageNameByCode(receiver.settings.lang)}}</p>
    </ng-container>
  </form>

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [message]="loadingSpinnerMessage"
                       [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

  <div mat-dialog-actions>
    <button (click)="onConfirm()" appFocusAndSelect color="warn" mat-raised-button>{{getYesButtonLabel()}}</button>
    <button (click)="onDismiss()" class="mr-2" i18n="cancel" mat-raised-button>Cancel</button>
  </div>

</div>
