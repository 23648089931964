import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ImprintComponent} from './imprint/imprint.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {SupportComponent} from './support/support.component';
import {RefComponent} from './ref/ref.component';

const layoutRoutes: Routes = [
  {path: 'imprint', component: ImprintComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'support', component: SupportComponent},
  {path: 'ref/:refName', component: RefComponent},
];

@NgModule({
  imports: [RouterModule.forChild(layoutRoutes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {
}
