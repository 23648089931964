import firebase from 'firebase/app';
// for cloud firestore
import {NotificationType} from '../enums/notificationType.enum';
import {EmbeddedListing} from './embeddedListing.interface';
import {EmbeddedUser} from './embeddedUser.interface';
import {EmbeddedTransaction} from './embeddedTransaction.interface';
import Timestamp = firebase.firestore.Timestamp;

export const NOTIFICATION_ACTION_URL_TRANSACTION = '/account/transactions/';

export interface Notification {
  userUid: string;
  actionUrl?: string;
  read: boolean;
  creationDate: Timestamp;
  notificationType: NotificationType;
  embeddedListing?: EmbeddedListing;
  otherUser?: EmbeddedUser;
  embeddedTransaction?: EmbeddedTransaction;
  uid?: string;
}
