import {Injectable} from '@angular/core';


@Injectable()
export class LayoutEffects {


  constructor() {
  }

}

