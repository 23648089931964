<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<a *ngIf="transaction" [routerLink]="['/account','transactions',transaction.uid]" class="no-underline">
    <div [class]="bgContainer ? 'bg-container' : ''" class="row mx-0 p-0 my-2 p-1 jumbotron">
        <div *ngIf="transaction?.transactionListing?.imgUrlThumb" class="m-0 p-0">
            <div class="d-block d-sm-none">
                <app-img-with-blurry-background *ngIf="transaction && transaction.transactionListing && transaction.transactionListing.imgUrlThumb" [src]="transaction.transactionListing.imgUrlThumb"
                                                default="./assets/images/default/default-listing-w300.webp"
                                                [height]="100" [width]="60">
                </app-img-with-blurry-background>
            </div>
            <div class="d-sm-block d-none">
                <app-img-with-blurry-background *ngIf="transaction && transaction.transactionListing && transaction.transactionListing.imgUrlThumb" [src]="transaction.transactionListing.imgUrlThumb"
                                                default="./assets/images/default/default-listing-w300.webp"
                                                [height]="100" [width]="100">
                </app-img-with-blurry-background>
            </div>
            <img>
        </div>
        <div class="col mx-2 p-0 ">
            <p *ngIf="transaction?.transactionListing?.name" class="listing-name">{{transaction?.transactionListing?.name}}</p>

            <div class="row">
                <div class="col-auto data-col">
                    <p *ngIf="otherUser?.displayName" class="message">
                        <span *ngIf="lending" class="font-weight-bold" i18n="borrower">Borrower</span>
                        <span *ngIf="borrowing" class="font-weight-bold" i18n="lender">Lender</span>: <br class="d-none d-lg-block">{{otherUser?.displayName}}
                    </p>
                </div>
                <div class="col-auto data-col">
                    <p *ngIf="transaction.state" class="message">
                        <span class="font-weight-bold" i18n="booking state">Booking state</span>: <br class="d-none d-lg-block">
                        <app-transaction-state [state]="transaction.state"></app-transaction-state>
                    </p>
                </div>
                <div *ngIf="payService.isPaymentActive()" class="col-auto data-col">
                    <p *ngIf="transaction.paymentState as ps" class="message">
                        <span class="font-weight-bold" i18n="booking state">Payment state</span>: <br class="d-none d-lg-block">
                        <span *ngIf="ps === 'PAID'" i18n="paid">Paid</span>
                        <span *ngIf="ps === 'UNPAID'" i18n="unpaid">Unpaid</span>
                        <span *ngIf="ps === 'PARTLY_PAID'" i18n="partly paid">Partly paid</span>
                        <span *ngIf="ps === 'REFUNDED'" i18n="refunded">Refunded</span>
                        <span *ngIf="ps === 'TRANSFERRED'" i18n="transferred to lender">Transferred to lender</span>
                        <span *ngIf="transaction.paidAmount && transaction.currencyId && ps === 'PAID'">:
              <app-money [currencyId]="transaction.currencyId" [value]="mangopayService.convertAmount(transaction.paidAmount, transaction.currencyId, true)"></app-money></span>
                        <span *ngIf="transaction.refundedAmount && transaction.currencyId && ps === 'REFUNDED'">:
              <app-money [currencyId]="transaction.currencyId" [value]="mangopayService.convertAmount(transaction.refundedAmount, transaction.currencyId, true)"></app-money>
              <span *ngIf="transaction.paidAmount" data-toggle="tooltip"
                    i18n-matTooltip="refunded amount and paid amount tooltip"
                    matTooltip="{{getNameByRole(transactionRole.Borrower)}} paid {{mangopayService.convertAmount(transaction.paidAmount, transaction.currencyId, true)
                    | currency:transaction.currencyId:'symbol': undefined: locale}}. Our payment provider charged us a payment fee, that could not be refunded. Therefore, the refunded amount is smaller than the paid amount.">
                <mat-icon [inline]="true">help_outline</mat-icon></span>

            </span>
                    </p>
                </div>
                <div class="col-auto data-col">
                    <p *ngIf="transaction.bookingDate" class="message">
                        <span class="font-weight-bold" i18n="booking date">Booking date</span>: <br class="d-none d-lg-block">
                        <span [title]="transaction.bookingDate.toDate() | moment:'LLLL'">{{utilService.createFromNowString(transaction.bookingDate.toDate())}}</span>
                    </p>
                </div>
                <div class="col-auto data-col">
                    <p class="message">
                        <span class="font-weight-bold" i18n="pickup date">Pickup date</span>: <br
                            class="d-none d-lg-block">{{transaction.targetPickupDate.toDate() | moment:'LLL'}}
                    </p>
                </div>
                <div class="col-auto data-col">
                    <p class="message">
                        <span class="font-weight-bold" i18n="return date">Return date</span>: <br
                            class="d-none d-lg-block">{{transaction.targetReturnDate.toDate() | moment:'LLL'}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div *ngIf="transaction.newPeriodSuggestion && transaction.newPeriodSuggestion.pickupDate && transaction.newPeriodSuggestion.returnDate"
                     class="col mx-2 mb-0 p-0 ">
                    <div class="alert alert-warning mb-0 p-1 message"><span i18n="New period suggested">New period
            suggested by </span> {{getNameByRole(transaction.newPeriodSuggestion.suggester)}}<span>:</span>
                        {{transaction.newPeriodSuggestion.pickupDate.toDate() | moment:'LLL'}}
                        - {{transaction.newPeriodSuggestion.returnDate.toDate() | moment:'LLL'}}</div>
                </div>
            </div>

        </div>
        <div *ngIf="otherUser?.imgUrlThumb" class="m-0 p-0 right">
            <img [src]="otherUser?.imgUrlThumb" class="thumbnail" default="./assets/images/default/default-user-w300.webp">
        </div>
    </div>
</a>
