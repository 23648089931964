import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TitleService {

  constructor(private ngTitleService: Title) {
  }

  /**
   * Sets the page title including the default suffix.
   * @param title Title to be set
   */
  setTitle(title: string): void {
    this.ngTitleService.setTitle(title + environment.defaultTitleSuffix);
  }

  /**
   * Sets the page title without the default suffix.
   * @param title Title to be set
   */
  setRawTitle(title: string): void {
    this.ngTitleService.setTitle(title);
  }

  getTitle(): string {
    return this.ngTitleService.getTitle();
  }
}
