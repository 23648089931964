import {Component, ElementRef, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Conversation} from '../../shared/models/conversation.interface';

@Component({
  selector: 'app-messages-dialog',
  templateUrl: './conversations-dialog.component.html',
  styleUrls: ['./conversations-dialog.component.scss'],
})
export class ConversationsDialogComponent implements OnInit {

  conversations: Conversation[] = [];
  error?: string;
  private closeEventEmitter?: EventEmitter<void>;

  private readonly _matDialogRef: MatDialogRef<ConversationsDialogComponent>;
  private readonly target: ElementRef;

  constructor(public dialogRef: MatDialogRef<ConversationsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConversationsDialogModel) {
    this._matDialogRef = dialogRef;
    this.target = data.target;
    this.error = data.error;
    this.closeEventEmitter = data.closeEventEmitter;

    // Update view with given values
    this.conversations = data.conversations;
    ;
  }

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.target.nativeElement.getBoundingClientRect();
    matDialogConfig.position = {left: `${rect.right >= 350 ? rect.right - 290 : rect.left}px`, top: `${rect.bottom}px`};
    this._matDialogRef.updatePosition(matDialogConfig.position);
  }

  closeDialog() {
    this.closeEventEmitter?.emit();
  }
}

/**
 * Class to represent messages dialog model.
 */
export class ConversationsDialogModel {

  constructor(public conversations: Conversation[], public target: ElementRef, public error?: string, public closeEventEmitter?: EventEmitter<void>) {
  }
}
