<h2 class="paragraph-headline-2" i18n>Safety tips</h2>
<p i18n>Most users on Blitzshare are trustworthy and good-natured, but unfortunately, as everywhere in life, there are a few black sheep. Here are some tips to
  help you protect yourself from potential scammers:</p>
<ul>
  <li i18n>Before handing out an item, let {{borrowerName}} show you their ID card and take a picture of it.</li>
  <li i18n>Consider asking for a deposit, e.g. their ID card or a cash deposit.</li>
  <li i18n>Exchange phone numbers. Also double check, if {{borrowerName}} is giving you their real number.</li>
  <li i18n>If possible, bring a witness, when handing out the item.</li>
</ul>
<p i18n>In the worst case, Blitzshare will try to help you prosecute a scammer by providing all available information about the scammer and the transaction.</p>
