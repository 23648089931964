import {CommonModule} from '@angular/common';
import {LatestListingsComponent} from './browse/latest-listings/latest-listings.component';
import {BrowserComponent} from './browser.component';
import {BrowserRoutingModule} from './browser-routing.module';
import {SearchPanelComponent} from './search/search-panel/search-panel.component';
import {CategoryComponent} from './browse/category/category.component';
import {ListingComponent} from './listing/listing.component';
import {AllCategoriesComponent} from './browse/all-categories/all-categories.component';
import {SearchComponent} from './search/search.component';
import {CategoryListItemComponent} from './browse/all-categories/category-list-item/category-list-item.component';
import {SharedModule} from '../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {UserInfoComponent} from './listing/user-info/user-info.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatOptionModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {UserInfoInternalComponent} from './listing/user-info/user-info-internal/user-info-internal.component';
import {ManageComponent} from './listing/manage/manage.component';
import {BorrowerInfoComponent} from './listing/borrower-info/borrower-info.component';
import {AllCategoriesInnerCardGroupComponent} from './browse/all-categories/all-categories-inner-card-group/all-categories-inner-card-group.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';


@NgModule({
  declarations: [LatestListingsComponent,
    BrowserComponent,
    SearchPanelComponent,
    CategoryComponent,
    ListingComponent,
    AllCategoriesComponent,
    SearchComponent,
    CategoryListItemComponent,
    UserInfoComponent,
    UserInfoInternalComponent,
    ManageComponent,
    BorrowerInfoComponent,
    AllCategoriesInnerCardGroupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserRoutingModule,
    ReactiveFormsModule,
    NgbCarouselModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatButtonModule,
    HttpClientModule,
    MatIconModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    MatTooltipModule,
    MatCardModule,
    // NgxAuthFirebaseUIModule,
  ],
  exports: [
    ListingComponent,
    UserInfoComponent,
    UserInfoInternalComponent,
    LatestListingsComponent,
  ],
})
export class BrowserModule {
}
