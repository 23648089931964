/** Adapts the native JS Date for use with cdk-based components that work with dates. */
import {NativeDateAdapter} from '@angular/material/core';
import * as moment from 'moment';
import {Injectable} from '@angular/core';
import Locale from '../shared/services/locale';

export interface DisplayFormat {
  year: string;
  month: string;
  day: string;
}

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {


  parse(value: any): Date | null {
    const parsedMoment: moment.Moment = moment(value, 'L');
    return parsedMoment.toDate();
  }

  format(date: Date, displayFormat: DisplayFormat): string {
    const myMoment = moment(date);
    // console.log('displayFormat=');
    // console.log(displayFormat);
    if (displayFormat.year === 'numeric' && displayFormat.month === 'numeric' && displayFormat.day === 'numeric')
      return myMoment.format('L');
    if (displayFormat.year === 'numeric' && displayFormat.month === 'long' && displayFormat.day === 'numeric')
      return myMoment.format('L');
    if (displayFormat.year === 'numeric' && displayFormat.month === 'short' && displayFormat.day === undefined)
      return myMoment.format('MMM YYYY');
    return myMoment.format('L');
  }

  getFirstDayOfWeek(): number {
    return Locale.firstDayOfWeek();
  }

}
