<div class="container bg-container px-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
      <li class="breadcrumb-item">
        <span i18n="ratings headline">My ratings</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="my ratings headline">My ratings</h1>
    <p class="jumbotron-lead" i18n="ratings lead text">See, how people rated you, and, how you rated other people. Also, see transactions, which you
      have not yet rated, or, for which you have not yet been rated.</p>
  </div>

  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
  <mat-tab-group (selectedTabChange)="onTabChange($event)" mat-align-tabs="start">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">login</mat-icon>
        <span i18n>Received</span>
      </ng-template>
      <h1 class="mt-3 paragraph-headline" i18n>Received ratings</h1>

      <p *ngIf="ratingsReceived.length>0" i18n>These are the ratings I have received from other users:</p>
      <p *ngIf="ratingsReceived.length===0" i18n>I have not yet received any ratings.</p>

      <app-ratings-view (loadMoreRatings)="loadMoreRatingsReceived($event)" [ratings]="ratingsReceived"
                        [thereIsMore]="thereAreMoreRatingsReceived"></app-ratings-view>

      <app-loading-spinner *ngIf="showLoadingSpinnerRatingsReceived" [centered]="true"></app-loading-spinner>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">logout</mat-icon>
        <span i18n>Given</span>
      </ng-template>
      <h1 class="mt-3 paragraph-headline" i18n>Given ratings</h1>

      <p *ngIf="ratingsGiven.length>0" i18n>These are the ratings I have given other users:</p>
      <p *ngIf="ratingsGiven.length===0" i18n>I have not yet given any ratings.</p>

      <app-ratings-view (loadMoreRatings)="loadMoreRatingsGiven($event)" [givenRatingsMode]="true"
                        [ratings]="ratingsGiven" [thereIsMore]="thereAreMoreRatingsGiven"></app-ratings-view>

      <app-loading-spinner *ngIf="showLoadingSpinnerRatingsGiven" [centered]="true"></app-loading-spinner>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">pending</mat-icon>
        <span i18n>Unrated by me</span>
      </ng-template>
      <h1 class="mt-3 paragraph-headline" i18n>Transactions I haven't rated yet</h1>

      <p *ngIf="transactionsUnratedByMe.length>0" i18n>These are my transactions I have not yet rated:</p>
      <p *ngIf="transactionsUnratedByMe.length===0" i18n>I don't have any transactions that I have not yet rated.</p>

      <app-loading-spinner *ngIf="showLoadingSpinnerTransactionsUnratedByMe" [centered]="true"></app-loading-spinner>

      <app-transaction-preview *ngFor="let transaction of transactionsUnratedByMe" [transaction]="transaction"></app-transaction-preview>

      <div *ngIf="thereAreMoreTransactionsUnratedByMe">
        <button (click)="loadMoreTransactionsUnratedByMe()" class="btn btn-primary" i18n type="button">Show more</button>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">pending</mat-icon>
        <span i18n>Unrated by others</span>
      </ng-template>
      <h1 class="mt-3 paragraph-headline" i18n>Transactions the other party hasn't rated yet</h1>

      <p *ngIf="transactionsUnratedByOther.length>0" i18n>These are my transactions, which the other party has not yet rated:</p>
      <p *ngIf="transactionsUnratedByOther.length===0" i18n>I don't have any transactions, which the other party has not yet rated.</p>

      <app-loading-spinner *ngIf="showLoadingSpinnerTransactionsUnratedByOther" [centered]="true"></app-loading-spinner>

      <app-transaction-preview *ngFor="let transaction of transactionsUnratedByOther" [transaction]="transaction"></app-transaction-preview>

      <div *ngIf="thereAreMoreTransactionsUnratedByOther">
        <button (click)="loadMoreTransactionsUnratedByOther()" class="btn btn-primary" i18n type="button">Show more</button>
      </div>

    </mat-tab>

  </mat-tab-group>
</div>
