import {Pipe, PipeTransform} from '@angular/core';
import Util from '../util';

const UNITS = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB'];

@Pipe({
  name: 'filesize',
})
export class FilesizePipe implements PipeTransform {

  transform(filesize: number | undefined | null, ...args: unknown[]): string {
    if (filesize === undefined || filesize === null)
      return '';

    for (let unit of UNITS) {
      if (filesize < 1024)
        return `${Util.round(filesize, 2)} ${unit}`;
      filesize /= 1024;
    }

    return `${Util.round(filesize, 2)} YB`;
  }

}
