import {Component, Input, OnInit} from '@angular/core';
import {MangopayService} from '../../../pay/mangopay.service';

@Component({
  selector: 'app-commission-view',
  templateUrl: './commission-view.component.html',
  styleUrls: ['./commission-view.component.scss'],
})
export class CommissionViewComponent implements OnInit {

  constructor(public mangopayService: MangopayService) {
  }


  _commission: any = new Map<string, number>();

  get commission(): any | Map<string, number> {
    return this._commission;
  }

  @Input() set commission(commission: any | undefined) {
    this._commission.clear();
    if (!commission)
      return;
    const convertedCommission = Object.entries(commission);
    convertedCommission.forEach(entry => this._commission.set(entry[0], <number>entry[1]));
  }

  ngOnInit(): void {
  }

  areThereCommissions(): boolean {
    let commission: Map<string, number> = this.commission;
    return commission.size > 0;
  }
}
