import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../shared/services/title.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {LayoutService} from '../../layout/layout.service';
import {Announcement} from '../../shared/models/announcement.interface';
import Util from '../../shared/util';


@Component({
  selector: 'app-announcements-editor',
  templateUrl: './announcements-editor.component.html',
  styleUrls: ['./announcements-editor.component.scss'],
})
export class AnnouncementsEditorComponent extends BaseComponent implements OnInit {

  announcements: Announcement[] = [];
  selectedAnnouncement: any;

  constructor(
      protected store: Store<AppState>,
      private titleService: TitleService,
      private layoutService: LayoutService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Announcements editor`);

    this.layoutService.fetchAnnouncements(true, 100).then(wrapper => {
      if (wrapper.data)
        this.announcements = wrapper.data;
      if (wrapper.errorMessage)
        this.addError($localize`Error loading announcements\: ${wrapper.errorMessage}`);
    });
  }

  /**
   * Called, when an announcement was selected in the list. Selects it in the editor.
   * @param announcement selected announcement
   */
  onSelection(announcement: Announcement) {
    this.selectedAnnouncement = announcement;
  }

  /**
   * Called, when an announcement was saved in the editor. Puts it into the array.
   * @param announcement saved announcement
   */
  onSaved(announcement: Announcement) {
    for (let i = 0; i < this.announcements.length; i++) {
      const currAnnouncement = this.announcements[i];
      if (currAnnouncement.uid === announcement.uid) {
        // Found the announcement in the array. Replace it with the edited one.
        this.announcements[i] = announcement;
        return;
      }
    }

    // If the function has not returned yet, no announcement with the given UID exists in the array. This is a new one. Add it to the array.
    // First, make the array extensible
    this.announcements = [...this.announcements];
    this.announcements.push(announcement);
  }

  onDeletion(announcement: Announcement) {
    // First, make the array extensible
    this.announcements = [...this.announcements];
    Util.removeFromArray(this.announcements, announcement);
  }

  createNew() {
    this.selectedAnnouncement = undefined;
  }
}
