import {Pipe, PipeTransform} from '@angular/core';
import Util from '../util';

@Pipe({
  name: 'round',
})
export class RoundPipe implements PipeTransform {

  transform(value: number, decimalPlaces: number = 2): any {
    if (typeof value !== 'number')
      return value;

    return Util.round(value, decimalPlaces).toFixed(decimalPlaces);
  }

}

