import {Injectable} from '@angular/core';
import {Listing} from '../shared/models/listing.interface';
import {Store} from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import {setBookingMessage, setBookingParams} from './store/book.actions';
import {Transaction} from '../shared/models/transaction.interface';
import {environment} from '../../environments/environment';
import Util from '../shared/util';
import {Message} from '../shared/models/message.interface';
import {Notification, NOTIFICATION_ACTION_URL_TRANSACTION} from '../shared/models/notification.interface';
import {NotificationType} from '../shared/enums/notificationType.enum';
import firebase from 'firebase/app';
import {Wrapper} from '../shared/models/wrapper.model';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {TransactionState} from '../shared/enums/transactionState.enum';
import {TransactionLog} from '../shared/models/transactionLog.interface';
import {Conversation} from '../shared/models/conversation.interface';
import {EmbeddedListing} from '../shared/models/embeddedListing.interface';
import {EmbeddedTransaction} from '../shared/models/embeddedTransaction.interface';
import {UserService} from '../shared/services/user.service';
import {EmbeddedUser} from '../shared/models/embeddedUser.interface';
import {Rating} from '../shared/models/rating.interface';
import {TransactionPeriodSuggestion} from '../shared/models/transactionPeriodSuggestion.interface';
import {TransactionPeriodSuggestionState} from '../shared/models/transactionPeriodSuggestionState.type';
import {UserPublic} from '../shared/models/userPublic.interface';
import {convertToPayment, convertToRating, convertToTransaction} from '../shared/converters/modelConverters';
import {Mutex} from 'async-mutex';
import {firestore} from '../app.module';
import {Payment} from '../shared/models/payment.interface';
import Timestamp = firebase.firestore.Timestamp;
import FirestoreError = firebase.firestore.FirestoreError;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import FirebaseError = firebase.FirebaseError;


@Injectable({
  providedIn: 'root',
})
export class BookService {

  ratingMutex = new Mutex();
  transactionMutex = new Mutex();
  paymentMutex = new Mutex();


  constructor(private store: Store<fromApp.AppState>,
              private userService: UserService) {
  }

  setBookingParams(listing?: Listing): void {
    this.store.dispatch(setBookingParams({listing}));
  }

  setBookingMessage(bookingMessage: string) {
    this.store.dispatch(setBookingMessage({bookingMessage}));
  }

  /**
   * Inserts the given transaction into the firestore
   * @param transaction data to be inserted
   * @param onSuccessCallback callback to be called after successful insertion
   * @param onErrorCallback callback to be called if any step goes wrong
   */
  insertTransaction(transaction: Transaction, onSuccessCallback: ((transactionUid: string) => void), onErrorCallback: ((reason: string) => void)): void {
    Util.insertData(transaction, firestore, environment.firestoreCollectionTransactions, onSuccessCallback, onErrorCallback);
  }

  /**
   * Inserts the given message into the firestore
   * @param message data to be inserted
   * @param onSuccessCallback callback to be called after successful insertion
   * @param onErrorCallback callback to be called if any step goes wrong
   * @param embeddedListing conversation listing containing name and imgUrlThumb
   */
  insertMessage(message: Message, onSuccessCallback: ((messageUid: string) => void), onErrorCallback: ((reason: string) => void), embeddedListing?: EmbeddedListing): void {
    const conversationUid = this.createConversationUidFromMessage(message);
    const conversation: Conversation = this.createConversation(message, embeddedListing);

    firestore.collection(environment.firestoreCollectionConversations).doc(conversationUid).set(conversation).then(() => {
      firestore.collection(environment.firestoreCollectionConversations).doc(conversationUid)
          .collection(environment.firestoreCollectionMessages).add(message)
          .then(docRef => {
            if (onSuccessCallback)
              onSuccessCallback(docRef.id);
          })
          .catch((errorResponse) => {
            if (onErrorCallback)
              onErrorCallback(errorResponse.message);
          });
    })
        .catch((errorResponse) => {
          if (onErrorCallback)
            onErrorCallback(errorResponse.message);
        });
  }

  /**
   * Inserts the given rating into the firestore. After successful insertion, also updates the transaction (adding the ratingUid)
   * @param rating data to be inserted
   * @param onSuccessCallback callback to be called after successful insertion
   * @param onErrorCallback callback to be called if any step goes wrong
   * @param embeddedListing conversation listing containing name and imgUrlThumb
   */
  insertRating(rating: Rating, onSuccessCallback: ((ratingUid: string, transaction: Transaction) => void), onErrorCallback: ((reason: string) => void)): void {
    firestore.collection(environment.firestoreCollectionRatings).add(rating).then((docRef) => {
      this.updateTransactionRating(docRef.id, rating, onSuccessCallback, onErrorCallback);

    })
        .catch((errorResponse) => {
          if (onErrorCallback)
            onErrorCallback(errorResponse.message);
        });
  }

  updateTransactionRating(ratingId: string, rating: Rating, onSuccessCallback: ((ratingUid: string, transaction: Transaction) => void), onErrorCallback: ((reason: string) => void)): void {
    this.fetchTransaction(rating.transactionUid).then(wrapper => {
      // If fetching the transaction failed
      if (wrapper.errorMessage) {
        onErrorCallback(wrapper.errorMessage);
        return;
      }
      // If no transaction was loaded
      if (!wrapper.data) {
        onErrorCallback($localize`Transaction not found.`);
        return;
      }
      const transaction: Transaction = wrapper.data;
      if (rating.raterUid === transaction.lenderUid)
        transaction.ratingByLenderUid = ratingId;
      else if (rating.raterUid === transaction.borrowerUid)
        transaction.ratingByBorrowerUid = ratingId;
      else {
        onErrorCallback($localize`You are neither the borrower nor the lender of this transaction.`);
        return;
      }
      transaction.lastUpdate = Timestamp.now();
      firestore.collection(environment.firestoreCollectionTransactions).doc(transaction.uid).set(transaction).then(() => {
        if (onSuccessCallback)
          onSuccessCallback(ratingId, transaction);
      }, reason => onErrorCallback($localize`Error updating transaction with rating data\: ${reason}`));
    });

  }

  /**
   * Inserts the given notification into the firestore
   * @param notification data to be inserted
   * @param onSuccessCallback callback to be called after successful insertion
   * @param onErrorCallback callback to be called if any step goes wrong
   */
  insertNotification(notification: Notification, onSuccessCallback?: ((notificationUid: string) => void), onErrorCallback?: ((reason: string) => void)): void {
    Util.insertData(notification, firestore, environment.firestoreCollectionNotifications, onSuccessCallback, onErrorCallback);
  }

  /**
   * Sends the given message to the firestore. If successful, the onSuccessCallback is called. If any step goes wrong, the onErrorCallback is called.
   * @param message message to be created in the firestore
   * @param onSuccessCallback callback to be called after successful message and notification creation
   * @param onErrorCallback callback to be called if any step goes wrong
   * @param embeddedListing conversation listing containing name and imgUrlThumb
   */
  createMessage(message: Message, onSuccessCallback: (messageUid: string) => void, onErrorCallback: (errorMessage: string) => void, embeddedListing?: EmbeddedListing) {
    this.insertMessage(message, (messageUid => onSuccessCallback(messageUid)), onErrorCallback, embeddedListing);
  }

  /**
   * Sends the given transaction to the firestore. If successful, creates and sends an appropriate notification. If that is also successful, the
   * onSuccessCallback is called. If any step goes wrong, the onErrorCallback is called.
   * @param transaction transaction to be created in the firestore
   * @param onSuccessCallback callback to be called after successful transaction and notification creation
   * @param onErrorCallback callback to be called if any step goes wrong
   */
  createTransactionAndNotification(transaction: Transaction,
                                   onSuccessCallback: (transactionUid: string, notificationUid: string) => void,
                                   onErrorCallback: (errorMessage: string) => void) {
    this.insertTransaction(transaction, (transactionUid => {
      // Successfully inserted transaction.

      // Create a notification.
      const notification: Notification = {
        actionUrl: NOTIFICATION_ACTION_URL_TRANSACTION + transactionUid,
        creationDate: transaction.bookingDate,
        read: false,
        userUid: transaction.lenderUid,
        notificationType: NotificationType.Transaction,
      };

      if (transaction?.transactionListing) {
        const embeddedListing: EmbeddedListing = {
          name: transaction.transactionListing.name, imgUrlThumb: transaction.transactionListing.imgUrlThumb,
          uid: transaction.listingUid,
        };
        notification.embeddedListing = embeddedListing;
      }
      const embeddedTransaction: EmbeddedTransaction = {
        uid: transactionUid,
        state: transaction.state,
        borrowerUid: transaction.borrowerUid,
        lenderUid: transaction.lenderUid,
        targetPickupDate: transaction.targetPickupDate,
        targetReturnDate: transaction.targetReturnDate,
      };
      notification.embeddedTransaction = embeddedTransaction;

      // Determine otherUser (in this case, this is the logged in user, because from the view of the notification receiver they are the 'other user'.
      const actingUserUid = transaction.logs[transaction.logs.length - 1].actingUserUid;
      this.userService.fetchUserPublic(actingUserUid).then(wrapper => {
        if (wrapper.data) {
          this.insertOtherUserIntoNotification(wrapper, notification);

          this.insertNotification(notification, (notificationUid => {
            onSuccessCallback(transactionUid, notificationUid);
          }), onErrorCallback);
        }

        if (wrapper.errorMessage)
          onErrorCallback($localize`Could not load the user ${actingUserUid}\: ${wrapper.errorMessage}`);
      });

    }), onErrorCallback);
  }

  async fetchPayment(paymentUid: string): Promise<Wrapper<Payment>> {

    return this.paymentMutex.runExclusive(async () => {
      try {
        const paymentDocSnapshot = await firestore.collection(environment.firestoreCollectionPayments).withConverter(paymentConverter).doc(paymentUid).get();
        const payment: Payment | undefined = paymentDocSnapshot.data();
        if (payment) {
          const paymentWithId = {...payment, uid: paymentDocSnapshot.id};
          return new Wrapper<Payment>(paymentWithId);
        }
      } catch (e: any) {
        if (e.code === 'permission-denied')
          return new Wrapper<Payment>(undefined, $localize`You can only view payments, in which you are either sender or receiver.`);
        return new Wrapper<Payment>(undefined, e.message);
      }

      // If no payment was found
      return new Wrapper<Payment>(undefined);
    });
  }

  async fetchTransaction(transactionUid: string): Promise<Wrapper<Transaction>> {

    return this.transactionMutex.runExclusive(async () => {
      try {
        const transactionDocSnapshot = await firestore.collection(environment.firestoreCollectionTransactions).withConverter(transactionConverter).doc(transactionUid).get();
        const transaction: Transaction | undefined = transactionDocSnapshot.data();
        if (transaction) {
          const transactionWithId = {...transaction, uid: transactionDocSnapshot.id};
          return new Wrapper<Transaction>(transactionWithId);
        }
      } catch (e: any) {
        if (e.code === 'permission-denied')
          return new Wrapper<Transaction>(undefined, $localize`You can only view transactions, in which you are either lender or borrower.`);
        return new Wrapper<Transaction>(undefined, e.message);
      }

      // If no transaction was found
      return new Wrapper<Transaction>(undefined);
    });
  }

  async fetchTransactions(userUid: string, borrowing: boolean, lending: boolean, open?: boolean, completed?: boolean, itemReturned?: boolean, ratingByBorrowerUid?: string | null, ratingByLenderUid?: string | null, listingUid?: string, startAfter?: DocumentSnapshot, limit: number = environment.defaultLoadTransactionsCount): Promise<Wrapper<Transaction[]>> {
    try {
      let query;
      if (lending)
        query = firestore.collection(environment.firestoreCollectionTransactions)
            .where('lenderUid', '==', userUid);
      else
        query = firestore.collection(environment.firestoreCollectionTransactions)
            .where('borrowerUid', '==', userUid);
      if (open)
        query = query.where('state', 'not-in', [TransactionState.BookingDenied, TransactionState.BookingCancelled, TransactionState.ItemReturned]);
      if (completed)
        query = query.where('state', 'in', [TransactionState.BookingDenied, TransactionState.BookingCancelled, TransactionState.ItemReturned]);
      if (itemReturned)
        query = query.where('state', '==', TransactionState.ItemReturned);
      if (ratingByBorrowerUid !== undefined)
        query = query.where('ratingByBorrowerUid', '==', ratingByBorrowerUid);
      if (ratingByLenderUid !== undefined)
        query = query.where('ratingByLenderUid', '==', ratingByLenderUid);
      if (listingUid !== undefined)
        query = query.where('listingUid', '==', listingUid);
      query = query.withConverter(transactionConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const transactionQuerySnapshot = await query.get();
      const transactions: Transaction[] = [];
      transactionQuerySnapshot.forEach(transactionDocSnapshot => {
        const transaction: Transaction | undefined = transactionDocSnapshot.data();
        if (transaction) {
          const transactionWithId = {...transaction, uid: transactionDocSnapshot.id};
          transactions.push(transactionWithId);
        }
      });
      const lastVisible = transactionQuerySnapshot.docs[transactionQuerySnapshot.docs.length - 1];

      return new Wrapper<Transaction[]>(transactions, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Transaction[]>(undefined, $localize`You can only view your own transactions.`);
      return new Wrapper<Transaction[]>(undefined, e.message);
    }

    // If no transactions were found
    return new Wrapper<Transaction[]>(undefined);
  }

  async fetchTransactionsUnratedByMe(userUid: string, startAfter?: DocumentSnapshot, startAfter2?: DocumentSnapshot): Promise<Wrapper<Transaction[]>> {
    const transactions: Transaction[] = [];
    let error1: string | undefined;
    let error2: string | undefined;
    let lastVisible1: QueryDocumentSnapshot<Transaction>;
    let lastVisible2: QueryDocumentSnapshot<Transaction>;
    await this.fetchTransactions(userUid, true, false, undefined, undefined, true, null, undefined, undefined, startAfter).then(wrapper => {
      if (wrapper.data)
        transactions.push(...wrapper.data);
      if (wrapper.errorMessage)
        error1 = wrapper.errorMessage;
      if (wrapper.lastVisible)
        lastVisible1 = wrapper.lastVisible;
    });
    await this.fetchTransactions(userUid, false, true, undefined, undefined, true, undefined, null, undefined, startAfter2).then(wrapper => {
      if (wrapper.data)
        transactions.push(...wrapper.data);
      if (wrapper.errorMessage)
        error2 = wrapper.errorMessage;
      if (wrapper.lastVisible)
        lastVisible2 = wrapper.lastVisible;
    });
    const combinedError = error1 && error2 ? `${error1} ${error2}` : (error1 ? `${error1}` : (error2 ? `${error2}` : undefined));
    return new Wrapper<Transaction[]>(transactions, combinedError, lastVisible1!, lastVisible2!);
  }

  async fetchTransactionsUnratedByOther(userUid: string, startAfter?: DocumentSnapshot, startAfter2?: DocumentSnapshot): Promise<Wrapper<Transaction[]>> {
    const transactions: Transaction[] = [];
    let error1: string | undefined;
    let error2: string | undefined;
    let lastVisible1: QueryDocumentSnapshot<Transaction>;
    let lastVisible2: QueryDocumentSnapshot<Transaction>;
    await this.fetchTransactions(userUid, true, false, undefined, undefined, true, undefined, null, undefined, startAfter).then(wrapper => {
      if (wrapper.data)
        transactions.push(...wrapper.data);
      if (wrapper.errorMessage)
        error1 = wrapper.errorMessage;
      if (wrapper.lastVisible)
        lastVisible1 = wrapper.lastVisible;
    });
    await this.fetchTransactions(userUid, false, true, undefined, undefined, true, null, undefined, undefined, startAfter2).then(wrapper => {
      if (wrapper.data)
        transactions.push(...wrapper.data);
      if (wrapper.errorMessage)
        error2 = wrapper.errorMessage;
      if (wrapper.lastVisible)
        lastVisible2 = wrapper.lastVisible;
    });
    const combinedError = error1 && error2 ? `${error1} ${error2}` : (error1 ? `${error1}` : (error2 ? `${error2}` : undefined));
    return new Wrapper<Transaction[]>(transactions, combinedError, lastVisible1!, lastVisible2!);
  }

  async fetchRating(ratingUid: string): Promise<Wrapper<Rating>> {
    return this.ratingMutex.runExclusive(async () => {
      try {
        const ratingDocSnapshot = await firestore.collection(environment.firestoreCollectionRatings).withConverter(ratingConverter).doc(ratingUid).get();
        const rating: Rating | undefined = ratingDocSnapshot.data();
        if (rating) {
          const ratingWithId = {...rating, uid: ratingDocSnapshot.id};
          return new Wrapper<Rating>(ratingWithId);
        }
      } catch (e: any) {
        if (e.code === 'permission-denied')
          return new Wrapper<Rating>(undefined, $localize`You are not allowed to view this rating.`);
        return new Wrapper<Rating>(undefined, e.message);
      }

      // If no rating was found
      return new Wrapper<Rating>(undefined);
    });
  }

  /**
   * Sends the given rating to the backend server. If it has an ID, an existing rating is updated, otherwise a new one is added.
   * @param ratingUid ID of the rating to be sent
   * @param rating rating to be sent. Need to be provided, even if a fullRating is given
   * @param fullRating rating to be written to the cache. This rating is not sent to the firestore
   * @param merge if true, only the fields given in the rating object will be updated. Otherwise, the whole rating object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateRating(ratingUid: string, rating: Rating, fullRating: Rating | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionRatings).doc(ratingUid).set(rating, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The rating could not be updated\: ${error}`),
    );
    if (merge && !fullRating) {
      console.error('updateRating called with a merge job without providing a fullRating.');
      return;
    }
  }

  deleteRating(ratingUid: string): Promise<void> {
    return firestore.collection(environment.firestoreCollectionRatings).doc(ratingUid).delete();
  }

  async fetchRatings(receiverUid: string | undefined, raterUid: string | undefined, startAfter?: DocumentSnapshot, limit: number = environment.defaultLoadUserRatingsCount): Promise<Wrapper<Rating[]>> {
    try {
      if (!receiverUid && !raterUid)
        return new Wrapper<Rating[]>(undefined, `Neiver receiverUid, nor raterUid are given`);
      let query = firestore.collection(environment.firestoreCollectionRatings)
          .where(receiverUid ? 'receiverUid' : 'raterUid', '==', receiverUid ? receiverUid : raterUid)
          .orderBy('date', 'desc')
          .withConverter(ratingConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const ratingQuerySnapshot = await query.get();
      const ratings: Rating[] = [];
      ratingQuerySnapshot.forEach(ratingDocSnapshot => {
        const rating: Rating | undefined = ratingDocSnapshot.data();
        if (rating) {
          const ratingWithId = {...rating, uid: ratingDocSnapshot.id};
          ratings.push(ratingWithId);
        }
      });
      const lastVisible = ratingQuerySnapshot.docs[ratingQuerySnapshot.docs.length - 1];

      return new Wrapper<Rating[]>(ratings, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Rating[]>(undefined, $localize`You are not allowed to view these ratings.`);
      return new Wrapper<Rating[]>(undefined, e.message);
    }
  }

  /**
   * Creates a listener for a specific transaction. Whenever that lending changes, the success callbacks is called with
   * the current lending.
   * @param transactionUid ID of the transaction to be monitored
   * @param onSuccessCallback callback to be called with the current transaction every time it changes
   * @param onErrorCallback callback to be called with the error message, if an error occurs
   */
  streamTransaction(transactionUid: string,
                    onSuccessCallback: (transaction: Transaction) => void,
                    onErrorCallback: ((error: string) => void)): () => void {
    return firestore.collection(environment.firestoreCollectionTransactions).withConverter(transactionConverter).doc(transactionUid)
        .onSnapshot((querySnapshot) => {
          const transaction: Transaction | undefined = querySnapshot.data();
          if (transaction) {
            const transactionWithId = {...transaction, uid: querySnapshot.id};
            onSuccessCallback(transactionWithId);
          }
        }, (error: FirestoreError) => {
          onErrorCallback($localize`Error loading transaction\: ${error.message}`);
        });
  }

  /**
   * Accepts the transaction with the given transactionUid, i.e. sets the state to BookingAccepted. Also adds a log entry to the transaction. Then, creates a
   * notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is accepting the booking
   * @param onSuccessCallback callback to be called on successful accepting
   * @param onErrorCallback  callback to be called on error
   */
  acceptBooking(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    this.updateTransaction(transactionUid, TransactionState.BookingAccepted, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback);

  }

  /**
   * Accepts the booking period suggestion for the transaction with the given transactionUid, i.e. takes over the values from newPeriodSuggestion and then
   * deletes that field.
   * Also adds a log entry to the transaction. Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is accepting the booking
   * @param onSuccessCallback callback to be called on successful accepting
   * @param onErrorCallback  callback to be called on error
   * @param numberOfDays new number of days
   * @param newPeriodSuggestion object containing the new pickup and return dates
   */
  acceptBookingPeriodSuggestion(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                                onErrorCallback: (errorMessage: string, transaction?: Transaction) => void, numberOfDays?: number,
                                newPeriodSuggestion?: TransactionPeriodSuggestion) {
    this.updateTransaction(transactionUid, undefined, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback, newPeriodSuggestion?.pickupDate, newPeriodSuggestion?.returnDate, numberOfDays, newPeriodSuggestion?.pricePerDay, undefined, undefined, undefined, 'SUGGESTION_ACCEPTED');
  }

  /**
   * Retracts or denies the booking period suggestion for the transaction with the given transactionUid, i.e. deletes the field newPeriodSuggestion from it.
   * Also adds a log entry to the transaction. Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is accepting the booking
   * @param onSuccessCallback callback to be called on successful accepting
   * @param onErrorCallback  callback to be called on error
   */
  removeBookingPeriodSuggestion(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                                onErrorCallback: (errorMessage: string, transaction?: Transaction) => void, periodSuggestionState: TransactionPeriodSuggestionState) {
    this.updateTransaction(transactionUid, undefined, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback, undefined, undefined, undefined, undefined, undefined, undefined, undefined, periodSuggestionState);

  }

  /**
   * Denies the transaction with the given transactionUid, i.e. sets the state to BookingDenied. Also adds a log entry to the transaction. Then, creates a
   * notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is denying the booking
   * @param onSuccessCallback callback to be called on successful denying
   * @param onErrorCallback  callback to be called on error
   */
  denyBooking(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
              onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    this.updateTransaction(transactionUid, TransactionState.BookingDenied, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback);
  }

  /**
   * Cancels the transaction with the given transactionUid, i.e. sets the state to BookingCancelled. Also adds a log entry to the transaction. Then, creates a
   * notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is cancelling the booking
   * @param onSuccessCallback callback to be called on successful cancellation
   * @param onErrorCallback  callback to be called on error
   */
  cancelBooking(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    this.updateTransaction(transactionUid, TransactionState.BookingCancelled, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback);
  }

  /**
   * The first party marks the item as picked up - the state is set to the given one, i.e. either ItemPickUpRequestedByBorrower or ItemPickUpRequestedByLender.
   * Also adds a log entry to the transaction. Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is acting
   * @param state new state to be set
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback  callback to be called on error
   */
  requestItemPickup(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, state: TransactionState, onSuccessCallback: (transaction: Transaction) => void,
                    onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    this.updateTransaction(transactionUid, state, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback);
  }

  /**
   * Undoes the item pickup and sets the state back to BookingAccepted.
   * Also adds a log entry to the transaction. Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is acting
   * @param state new state to be set
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback  callback to be called on error
   */
  undoItemPickup(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                 onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    this.updateTransaction(transactionUid, TransactionState.BookingAccepted, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback);
  }

  /**
   * Declines the item pickup and sets the state back to BookingAccepted.
   * Also adds a log entry to the transaction. Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is acting
   * @param state new state to be set
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback  callback to be called on error
   */
  declineItemPickup(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                    onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    this.updateTransaction(transactionUid, TransactionState.BookingAccepted, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback);
  }

  /**
   * Confirms the item pickup. Sets the state to ItemPickedUp. Also sets the actualPickupDate to the current time.
   * Also adds a log entry to the transaction. Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is acting
   * @param state new state to be set
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback  callback to be called on error
   */
  confirmItemPickup(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                    onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    const now = Timestamp.now();
    this.updateTransaction(transactionUid, TransactionState.ItemPickedUp, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback, undefined, undefined, undefined, undefined, now);
  }

  /**
   * The first party marks the item as returned - the state is set to the given one, i.e. either ItemReturnRequestedByBorrower or ItemReturnRequestedByLender.
   * Also adds a log entry to the transaction. Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is acting
   * @param state new state to be set
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback  callback to be called on error
   */
  requestItemReturn(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, state: TransactionState, onSuccessCallback: (transaction: Transaction) => void,
                    onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    this.updateTransaction(transactionUid, state, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback);
  }

  /**
   * Undoes the item return and sets the state back to ItemPickedUp.
   * Also adds a log entry to the transaction. Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is acting
   * @param state new state to be set
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback  callback to be called on error
   */
  undoItemReturn(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                 onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    this.updateTransaction(transactionUid, TransactionState.ItemPickedUp, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback);
  }

  /**
   * Declines the item return and sets the state back to ItemPickedUp.
   * Also adds a log entry to the transaction. Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is acting
   * @param state new state to be set
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback  callback to be called on error
   */
  declineItemReturn(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                    onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    this.updateTransaction(transactionUid, TransactionState.ItemPickedUp, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback);
  }

  /**
   * Confirms the item return. Sets the state to ItemPickedUp. Also sets the actualReturnDate to the current time.
   * Also adds a log entry to the transaction. Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is acting
   * @param state new state to be set
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback  callback to be called on error
   */
  confirmItemReturn(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                    onErrorCallback: (errorMessage: string, transaction?: Transaction) => void) {
    const now = Timestamp.now();
    this.updateTransaction(transactionUid, TransactionState.ItemReturned, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback, undefined, undefined, undefined, undefined, undefined, now);
  }

  /**
   * Updates the state and/or other fields of the transaction with the given transactionUid. Also adds a log entry to the transaction.
   * Then, creates a notification for the other user.
   * @param transactionUid Uid of the transaction
   * @param state new state (if any) or undefined
   * @param lastUpdate last update date of the transaction, which the user has opened in the browser. If the lastUpdate in database is after the given one,
   * the process is aborted
   * @param actingUserUid uid of the user, who is denying the booking
   * @param onSuccessCallback callback to be called on successful denying
   * @param onErrorCallback  callback to be called on error
   * @param targetPickupDate? optional parameter: new pickup date to be set
   * @param targetReturnDate? optional parameter: new return date to be set
   * @param numberOfDays? optional parameter: new numberOfDays to be set
   * @param pricePerDay? optional parameter: new pricePerDay to be set
   * @param actualPickupDate? optional parameter: new actualPickupDate to be set
   * @param actualReturnDate? optional parameter: new actualReturnDate to be set
   * @param newPeriodSuggestion? optional parameter: new TransactionPeriodSuggestion to be set. If undefined, it will be unset
   * @param periodSuggestionState? new period suggestion state
   */
  updateTransaction(transactionUid: string, state: TransactionState | undefined, lastUpdate: firebase.firestore.Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                    onErrorCallback: (errorMessage: string, transaction?: Transaction) => void, targetPickupDate?: firebase.firestore.Timestamp, targetReturnDate?: firebase.firestore.Timestamp,
                    numberOfDays?: number, pricePerDay?: number, actualPickupDate?: firebase.firestore.Timestamp, actualReturnDate?: firebase.firestore.Timestamp, newPeriodSuggestion?: TransactionPeriodSuggestion, periodSuggestionState?: TransactionPeriodSuggestionState) {
    this.fetchTransaction(transactionUid).then(wrapper => {
      let transaction: Transaction | undefined = this.validateTransactionWrapper(wrapper, lastUpdate, onErrorCallback);
      if (!transaction)
        return;

      // Copy the transaction to make it writable
      transaction = {...transaction};

      const previousState = transaction.state;

      // If the state is not being changed, keep the previous state
      if (!state)
        state = previousState;

      const logs: TransactionLog[] = [...transaction.logs];
      const now = Timestamp.now();
      const log: TransactionLog = {previousState: transaction.state, state, actingUserUid, date: now};

      const embeddedTransaction: EmbeddedTransaction = {
        uid: transactionUid,
        state: state,
        borrowerUid: transaction.borrowerUid,
        lenderUid: transaction.lenderUid,
      };
      if (previousState)
        embeddedTransaction.previousState = previousState;

      if (targetPickupDate) {
        transaction.targetPickupDate = targetPickupDate;
        log.targetPickupDate = targetPickupDate;
        embeddedTransaction.targetPickupDate = targetPickupDate;
      }
      if (targetReturnDate) {
        transaction.targetReturnDate = targetReturnDate;
        log.targetReturnDate = targetReturnDate;
        embeddedTransaction.targetReturnDate = targetReturnDate;
      }
      if (actualPickupDate) {
        transaction.actualPickupDate = actualPickupDate;
        log.actualPickupDate = actualPickupDate;
      }
      if (actualReturnDate) {
        transaction.actualReturnDate = actualReturnDate;
        log.actualReturnDate = actualReturnDate;
      }
      if (periodSuggestionState) {
        transaction.periodSuggestionState = periodSuggestionState;
        log.periodSuggestionState = periodSuggestionState;
        embeddedTransaction.periodSuggestionState = periodSuggestionState;
      } else
        delete transaction.periodSuggestionState;
      if (newPeriodSuggestion) {
        transaction.newPeriodSuggestion = newPeriodSuggestion;
        log.newPeriodSuggestion = newPeriodSuggestion;
        embeddedTransaction.newPeriodSuggestion = newPeriodSuggestion;
      } else
        delete transaction.newPeriodSuggestion;
      if (numberOfDays)
        transaction.numberOfDays = numberOfDays;
      if (pricePerDay)
        transaction.pricePerDay = pricePerDay;
      if (transaction?.paidAmount)
        embeddedTransaction.paidAmount = transaction.paidAmount;
      if (transaction?.refundedAmount)
        embeddedTransaction.refundedAmount = transaction.refundedAmount;
      if (transaction?.receiverAmount)
        embeddedTransaction.receiverAmount = transaction.receiverAmount;

      logs.push(log);
      transaction = {...transaction, state, logs, lastUpdate: now};

      firestore.collection(environment.firestoreCollectionTransactions).withConverter(transactionConverter).doc(transactionUid).set(transaction).then(() => {
            onSuccessCallback(transaction!);

            // Create notification for the other (= non-acting) user
            const userUid = actingUserUid === transaction!.lenderUid ? transaction!.borrowerUid : transaction!.lenderUid;
            const notification: Notification = {
              notificationType: NotificationType.Transaction,
              read: false,
              userUid,
              actionUrl: NOTIFICATION_ACTION_URL_TRANSACTION + transactionUid,
              creationDate: now,
              embeddedTransaction: embeddedTransaction,
            };
            if (transaction?.transactionListing) {
              const embeddedListing: EmbeddedListing = {
                uid: transaction?.listingUid,
                name: transaction.transactionListing.name,
                imgUrlThumb: transaction.transactionListing.imgUrlThumb,
              };
              notification.embeddedListing = embeddedListing;
            }


            // Determine otherUser (in this case, this is the logged in user, because from the view of the notification receiver they are the 'other user'.
            this.userService.fetchUserPublic(actingUserUid).then(wrapper => {
              if (wrapper.data) {
                this.insertOtherUserIntoNotification(wrapper, notification);
                this.insertNotification(notification);
              }

              if (wrapper.errorMessage)
                onErrorCallback($localize`Could not load the user ${actingUserUid}\: ${wrapper.errorMessage}`);
            });

          },
          (reason: FirebaseError) => {
            onErrorCallback(`Error updating transaction: ${reason.message}`);
          });
    });
  }

  /**
   * Sends the given transaction to the backend server. This is a merge update. Only the given fields will be written
   * @param transactionId ID of the transaction to be sent
   * @param transaction transaction to be sent. Need to be provided, even if a fullTransaction is given
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateTransactionMerge(transactionId: string, transaction: Partial<Transaction>, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionTransactions).doc(transactionId).set(transaction, {merge: true}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The transaction could not be updated\: ${error}`),
    );
  }

  updateBookingPeriod(transactionUid: string, lastUpdate: Timestamp, actingUserUid: string, onSuccessCallback: (transaction: Transaction) => void,
                      onErrorCallback: (errorMessage: string, transaction?: Transaction) => void, newPeriodSuggestion: TransactionPeriodSuggestion, newState?: TransactionState) {
    this.updateTransaction(transactionUid, newState, lastUpdate, actingUserUid, onSuccessCallback, onErrorCallback, undefined, undefined, undefined, undefined, undefined, undefined, newPeriodSuggestion, 'SUGGESTED');
  }

  /**
   * Creates the conversation document UID. It consists of senderUid, receiverUid and (if available) listingUid separated by dashes.
   * @param message message containing the required data
   * @return the conversation document UID
   */
  createConversationUidFromMessage(message: Message) {
    return this.createConversationUid(message.senderUid, message.receiverUid, message.listingUid);
  }

  /**
   * Creates the conversation document UID. It consists of senderUid, receiverUid and (if available) listingUid separated by dashes.
   * @param message message containing the required data
   * @return the conversation document UID
   */
  createConversationUid(senderUid: string, receiverUid: string, listingUid?: string) {
    const userUids = [senderUid, receiverUid].sort();
    let conversationUid = userUids[0] + '-' + userUids[1];
    if (listingUid)
      conversationUid += '-' + listingUid;
    return conversationUid;
  }

  /**
   * Inserts the other user into the given notification. The other user is based on the given acting user in the wrapper
   * @param wrapper wrapper containing a userPublic. Must not be undefined or null
   * @param notification notification, in which the given user should be inserted as 'otherUser'.
   */
  private insertOtherUserIntoNotification(wrapper: Wrapper<UserPublic>, notification: Notification) {
    const actingUser = wrapper.data!;
    const otherUser: EmbeddedUser = {
      uid: actingUser.uid,
      displayName: actingUser.displayName,
    };
    if (actingUser.imgUrlThumb)
      otherUser.imgUrlThumb = actingUser.imgUrlThumb;
    notification.otherUser = otherUser;
  }

  /**
   * Validates the given transaction wrapper. If it's invalid, the onErrorCallback is called and undefined is returned. Otherwise, the transaction is returned.
   * @param wrapper wrapper to be validated
   * @param lastUpdate last update of the transaction, which the user is editing
   * @param onErrorCallback callback to be called, if transaction is invalid
   * @return undefined or transaction
   */
  private validateTransactionWrapper(wrapper: Wrapper<Transaction>, lastUpdate: Timestamp,
                                     onErrorCallback: (errorMessage: string, transaction?: Transaction) => void): Transaction | undefined {
    // If fetching the transaction failed
    if (wrapper.errorMessage) {
      onErrorCallback(wrapper.errorMessage);
      return undefined;
    }
    // If no transaction was loaded
    if (!wrapper.data) {
      onErrorCallback($localize`Transaction not found.`);
      return undefined;
    }
    const transaction: Transaction = wrapper.data;
    // If the transaction has been changed in between
    if (transaction.lastUpdate.toMillis() > lastUpdate.toMillis()) {
      onErrorCallback($localize`The transaction has been changed. Please review the changes and try again.`, transaction);
      return undefined;
    }
    return transaction;
  }

  /**
   * Creates a conversation from the given message.
   * @param message message with receiverUid, senderUid and optionally listingUid
   * @param embeddedListing conversation listing containing name and imgUrlThumb
   * @return conversation
   */
  private createConversation(message: Message, embeddedListing?: EmbeddedListing): Conversation {
    const userUids: string[] = [message.receiverUid, message.senderUid].sort();
    // Determine, which read flag is true and which one is false
    // The sender gets true, while the receiver gets false
    let user1Read = false;
    let user2Read = false;
    if (message.senderUid === userUids[0])
        // User1 is sender -> gets true
        // User2 is receiver -> stays false
      user1Read = true;
    if (message.senderUid === userUids[1])
        // User2 is sender -> gets true
        // User1 is receiver -> stays false
      user2Read = true;

    const conversation: Conversation = {user1Uid: userUids[0], user2Uid: userUids[1], latestMessageDate: Timestamp.now(), user1Read, user2Read};
    if (embeddedListing)
      conversation.embeddedListing = embeddedListing;
    if (message.listingUid)
      conversation.listingUid = message.listingUid;
    return conversation;
  }

}

// Firestore data converter
export const paymentConverter = {
  toFirestore(payment: Payment): Payment {
    return payment;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Payment {
    return convertToPayment(snapshot.data(options), snapshot.data(options).uid);
  },
};

// Firestore data converter
export const transactionConverter = {
  toFirestore(transaction: Transaction): Transaction {
    return transaction;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Transaction {
    return convertToTransaction(snapshot.data(options));
  },
};

// Firestore data converter
export const ratingConverter = {
  toFirestore(rating: Rating): Rating {
    return rating;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Rating {
    return convertToRating(snapshot.data(options));
  },
};
