import {Component, HostListener, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.css'],
})
export class PreviewModalComponent implements OnInit {

  img: File | undefined;

  constructor(public activeModal: NgbActiveModal) {
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ESC') {
      this.activeModal.dismiss('ESC press');
    }
  }

  ngOnInit(): void {
  }

}
