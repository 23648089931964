<form *ngIf="category" [formGroup]="form">

    <div class="row">
        <div class="col">
            <mat-form-field class="mat-form-input text-input-100">
                <mat-label i18n="id">ID</mat-label>
                <input #idRef (change)="idChange($event)" (keyup)="keyup($event)" formControlName="id"
                       i18n-placeholder matInput maxlength="{{idMaxLength}}" placeholder="Category ID"
                       required tabindex="0">
                <span class="character-counter">{{idRef.value.length}}/{{idMaxLength}}</span>
            </mat-form-field>
            <mat-form-field class="mat-form-input text-input-100">
                <mat-label i18n="imgUrl full">Image URL full</mat-label>
                <input #imgUrlRef (keyup)="keyup($event)" formControlName="imgUrlFull" i18n-placeholder="category imgUrl input placeholder"
                       matInput maxlength="{{imgUrlMaxLength}}"
                       placeholder="The URL of the category full image">
                <span class="character-counter">{{imgUrlRef.value.length}}/{{imgUrlMaxLength}}</span>
            </mat-form-field>
            <mat-form-field class="mat-form-input text-input-100">
                <mat-label i18n="imgUrl thumb">Image URL thumbnail</mat-label>
                <input #imgUrlRef (keyup)="keyup($event)" formControlName="imgUrlThumb" i18n-placeholder="category imgUrl input placeholder"
                       matInput maxlength="{{imgUrlMaxLength}}"
                       placeholder="The URL of the category image thumbnail">
                <span class="character-counter">{{imgUrlRef.value.length}}/{{imgUrlMaxLength}}</span>
            </mat-form-field>
            <img (click)="openImage(this.category.imgUrlFull)" *ngIf="this.category?.imgUrlThumb && this.category.imgUrlFull" alt="category image"
                 class="contained-300h-300w hover-pointer"
                 default="./assets/images/default/default-category-w300.webp"
                 i18n-alt="category image alt"
                 src="{{this.category?.imgUrlThumb}}">

            <app-image-uploader *ngIf="imageUploadPath && remainingImageUploads>0" [filename]="idRef.value" [onUploadCallback]="onUploadCallback"
                                [remainingUploads]="remainingImageUploads" [uploadPath]="imageUploadPath"></app-image-uploader>

        </div>
        <div class="col">
            <div formArrayName="strings">
                <mat-tab-group [disablePagination]="true" [selectedIndex]="selectedTabIndex" class="no-paging" mat-align-tabs="start">
                    <mat-tab *ngFor="let stringsFormGroup of getStringsFormArray().controls; index as stringsIndex"
                             [formGroupName]="stringsIndex">
                        <ng-template *ngIf="langRef.value" mat-tab-label>{{langRef.value}}</ng-template>
                        <ng-template *ngIf="!langRef.value" i18n="unspecified language" mat-tab-label>Unspecified language</ng-template>

                        <div>

                            <mat-divider class="py-2"></mat-divider>
                            <div class="row ml-3">
                                <div class="col-auto">

                                    <mat-form-field class="mat-form-input text-input-100">
                                        <mat-label i18n="language code">Language code</mat-label>
                                        <input #langRef (keyup)="keyup($event)" formControlName="lang"
                                               matInput maxlength="{{langMaxLength}}"
                                               placeholder="Language code" required>
                                        <span class="character-counter">{{langRef.value.length}}/{{langMaxLength}}</span>
                                    </mat-form-field>

                                    <mat-form-field class="mat-form-input text-input-100">
                                        <mat-label i18n="category name">Category name</mat-label>
                                        <input #nameRef (keyup)="keyup($event)" formControlName="name" matInput
                                               maxlength="{{nameMaxLength}}" placeholder="Category name"
                                               required tabindex="1">
                                        <span class="character-counter">{{nameRef.value.length}}/{{nameMaxLength}}</span>
                                    </mat-form-field>
                                    <mat-form-field class="mat-form-input text-input-100">
                                        <mat-label i18n="element name">Element name</mat-label>
                                        <input #elementNameRef (keyup)="keyup($event)" formControlName="elementName" matInput
                                               maxlength="{{elementNameMaxLength}}" placeholder="Element name"
                                               required tabindex="2">
                                        <span class="character-counter">{{elementNameRef.value.length}}/{{elementNameMaxLength}}</span>
                                    </mat-form-field>

                                    <mat-form-field class="mat-form-textarea-w-100">
                                        <mat-label i18n="description">Description</mat-label>
                                        <textarea #descriptionRef
                                                  (keyup)="keyup($event)" cdkAutosizeMaxRows="25" cdkAutosizeMinRows="2" cdkTextareaAutosize
                                                  formControlName="description" i18n-placeholder="category description input placeholder" matInput
                                                  maxlength="{{descriptionMaxLength}}"
                                                  placeholder="A detailed description of the category." tabindex="3"></textarea>
                                        <span class="character-counter">{{descriptionRef.value.length}}/{{descriptionMaxLength}}</span>
                                    </mat-form-field>

                                </div>
                                <div class="col">
                                    <app-mat-icon-button *ngIf="langRef.value !== 'en'" [callback]="translateStrings.bind(this)"
                                                         [params]="[langRef.value, stringsIndex]"
                                                         color="primary" cssClass="mr-2 mb-3" i18n-label="Button label translate strings" icon="translate"
                                                         label="Translate en to {{langRef.value}}"></app-mat-icon-button>
                                    <app-mat-icon-button *ngIf="langRef.value !== 'en'" [breakLabel]="false" [callback]="deleteStrings.bind(this)" [params]="[stringsIndex]"
                                                         color="warn" cssClass="mb-3" i18n-label="Button label delete strings" icon="delete"
                                                         label="Delete {{langRef.value}}"></app-mat-icon-button>
                                    <app-mat-icon-button *ngIf="langRef.value === 'en' && nameRef.value && elementNameRef.value "
                                                         [callback]="addMissingTranslations.bind(this)" color="primary"
                                                         cssClass="mb-3" i18n-label="Button label add missing translations" icon="translate"
                                                         label="Add missing translations"
                                                         tabindex="4"></app-mat-icon-button>
                                </div>
                            </div>
                        </div>

                    </mat-tab>
                </mat-tab-group>

            </div>

            <ng-container *ngFor="let lang of languages">
                <app-mat-icon-button *ngIf="shouldDisplayLanguage(lang)" [callback]="addStrings.bind(this)" [label]="lang"
                                     [params]="[lang]" color="primary" cssClass="m-1"
                                     icon="add"></app-mat-icon-button>
            </ng-container>
            <app-mat-icon-button [callback]="addStrings.bind(this)" color="primary" cssClass="m-1"
                                 i18n-label="other" icon="add"
                                 label="other"></app-mat-icon-button>
        </div>
    </div>
</form>
