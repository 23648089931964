<div class="container bg-container p-1">

    <!-- Breadcrumb-->
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <a [routerLink]="['/account']" class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
            <a *ngIf="userIsLender()" [routerLink]="['/account','lending']" class="breadcrumb-item">
                <span i18n="lending">Lending</span>
            </a>
            <a *ngIf="userIsBorrower()" [routerLink]="['/account','borrowing']" class="breadcrumb-item">
                <span i18n="borrowing">Borrowing</span>
            </a>
            <li class="breadcrumb-item">
                <span i18n="transaction">Transaction</span>
            </li>
        </ol>
    </nav>

    <div *ngIf="!transaction">
        <!-- Jumbotron no transaction-->
        <div class="jumbotron mb-3">
            <h1 class="jumbotron-headline" i18n="transaction headline">Transaction not found</h1>
            <p class="jumbotron-lead" i18n="transaction lead text">We could not find the requested transaction.</p>
        </div>

        <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

    </div>

    <div *ngIf="transaction">
        <!-- Jumbotron transaction-->
        <div class="jumbotron mb-3">
            <h1 class="jumbotron-headline" i18n="transaction headline">Transaction: {{ transaction?.transactionListing?.name }}</h1>
            <p class="jumbotron-lead" i18n="transaction lead text">Review or manage this transaction.</p>
        </div>

        <!-- Item card -->
        <div class="card w-100 mb-3">
            <div class="row no-gutters">
                <div>
                    <a [routerLink]="['/browse/listing',transaction.listingUid]">
                        <img *ngIf="transaction.transactionListing.imgUrlThumb !== undefined"
                             alt="Picture of {{transaction.transactionListing.name}}" class="contained-300h-300w"
                             default="./assets/images/default/default-listing-w300.webp"
                             i18n-alt="Picture alt text in item card"
                             src="{{transaction.transactionListing.imgUrlThumb}}"></a>
                </div>
                <div class="col">
                    <div class="card-body">
                        <h2 class="paragraph-headline-2"><a
                                [routerLink]="['/browse/listing',transaction.listingUid]">{{ transaction.transactionListing.name }}</a></h2>
                        <p>{{ transaction.transactionListing.summary }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-3">
            <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
        </div>

        <!-- State and next action -->
        <div class="card w-100 mb-3">
            <div class="row no-gutters">

                <div class="col">
                    <div class="card-body">
                        <h1 class="paragraph-headline"><span i18n="current state">Current state</span>:
                            <app-transaction-state [state]="transaction.state"></app-transaction-state>
                        </h1>

                        <!-- State BookingRequested -->
                        <div *ngIf="transaction.state === transactionState.BookingRequested">
                            <p *ngIf="userIsBorrower()" i18n="current state BookingRequested userIsBorrower">You want to rent
                                <i>{{ transaction.transactionListing.name }}</i> from {{ lender?.displayName }}. They have to accept the booking, suggest another
                                time or deny it.
                            </p>
                            <p *ngIf="userIsLender()" i18n="current state BookingRequested userIsLender">{{ borrower?.displayName }} wants to rent <i>{{ transaction.transactionListing.name }}</i> from you. You can now accept the booking, suggest another time or deny it.</p>

                            <h2 class="paragraph-headline-2" i18n="planned pickup and return headline">Planned pickup and return dates</h2>
                            <p>{{ transaction.targetPickupDate.toDate() | moment:'LLLL' }}<br>
                                {{ transaction.targetReturnDate.toDate() | moment:'LLLL' }}</p>

                            <p *ngIf="userIsBorrower() && !transaction.newPeriodSuggestion">
                                <app-mat-icon-button *ngIf="!showBookingDateSelection" [callback]="onSuggestAnotherBookingPeriod.bind(this)" [params]="[]"
                                                     color="accent" cssClass="mr-2 mb-3" i18n-label="Suggest another time" icon="edit_calendar"
                                                     label="Suggest another time"></app-mat-icon-button>
                                <app-mat-icon-button [callback]="onCancelBooking.bind(this)" [params]="[]"
                                                     color="warn" cssClass="mr-2 mb-3" i18n-label="Cancel booking" icon="event_busy"
                                                     label="Cancel booking"></app-mat-icon-button>
                            </p>
                            <p *ngIf="userIsLender() && !transaction.newPeriodSuggestion">
                                <app-mat-icon-button [callback]="onAcceptBooking.bind(this)" [params]="[]"
                                                     color="primary" cssClass="mr-2 mb-3" i18n-label="Accept booking" icon="event_available"
                                                     label="Accept booking"></app-mat-icon-button>
                                <app-mat-icon-button *ngIf="!showBookingDateSelection" [callback]="onSuggestAnotherBookingPeriod.bind(this)" [params]="[]"
                                                     color="accent" cssClass="mr-2 mb-3" i18n-label="Suggest another time" icon="edit_calendar"
                                                     label="Suggest another time"></app-mat-icon-button>
                                <app-mat-icon-button [callback]="onDenyBooking.bind(this)" [params]="[]"
                                                     color="warn" cssClass="mr-2 mb-3" i18n-label="Deny booking" icon="event_busy"
                                                     label="Deny booking"></app-mat-icon-button>
                            </p>
                        </div>

                        <!-- State BookingAccepted -->
                        <div *ngIf="transaction.state === transactionState.BookingAccepted">
                            <p *ngIf="userIsBorrower()" i18n="current state BookingAccepted userIsBorrower unpaid">You are going to rent
                                <i>{{ transaction.transactionListing.name }}</i> from {{ lender?.displayName }}. They have accepted the booking. Pick it up
                                on {{ transaction.targetPickupDate.toDate() | moment:'LLLL' }}.
                            </p>
                            <p *ngIf="userIsLender()" i18n="current state BookingAccepted userIsLender unpaid">{{ borrower?.displayName }} is going to rent
                                <i>{{ transaction.transactionListing.name }}</i> from you. You have accepted the booking. They will pick it up
                                on {{ transaction.targetPickupDate.toDate() | moment:'LLLL' }}.</p>
                            <p *ngIf="!isPickupAvailable()" i18n="hint come back at pickup time">Please come back here at pickup time to confirm the pickup.</p>
                            <p *ngIf="userIsBorrower() && !transaction.newPeriodSuggestion">
                                <app-mat-icon-button *ngIf="isPickupAvailable()" [callback]="onRequestItemPickup.bind(this)" [params]="[]"
                                                     color="primary" cssClass="mr-2 mb-3" i18n-label="Mark item as picked up" icon="logout"
                                                     label="Mark item as picked up"></app-mat-icon-button>
                                <app-mat-icon-button *ngIf="!showBookingDateSelection" [callback]="onSuggestAnotherBookingPeriod.bind(this)" [params]="[]"
                                                     color="accent" cssClass="mr-2 mb-3" i18n-label="Suggest another time" icon="edit_calendar"
                                                     label="Suggest another time"></app-mat-icon-button>
                                <app-mat-icon-button [callback]="onCancelBooking.bind(this)" [params]="[]"
                                                     color="warn" cssClass="mr-2 mb-3" i18n-label="Cancel booking" icon="event_busy"
                                                     label="Cancel booking"></app-mat-icon-button>
                            </p>
                            <p *ngIf="userIsLender() && !transaction.newPeriodSuggestion">
                                <app-mat-icon-button *ngIf="isPickupAvailable()" [callback]="onRequestItemPickup.bind(this)" [params]="[]"
                                                     color="primary" cssClass="mr-2 mb-3" i18n-label="Mark item as picked up" icon="logout"
                                                     label="Mark item as picked up"></app-mat-icon-button>
                                <app-mat-icon-button *ngIf="!showBookingDateSelection" [callback]="onSuggestAnotherBookingPeriod.bind(this)" [params]="[]"
                                                     color="accent" cssClass="mr-2 mb-3" i18n-label="Suggest another time" icon="edit_calendar"
                                                     label="Suggest another time"></app-mat-icon-button>
                                <app-mat-icon-button [callback]="onCancelBooking.bind(this)" [params]="[]"
                                                     color="warn" cssClass="mr-2 mb-3" i18n-label="Cancel booking" icon="event_busy"
                                                     label="Cancel booking"></app-mat-icon-button>
                            </p>
                        </div>

                        <!-- State BookingCancelled -->
                        <div *ngIf="transaction.state === transactionState.BookingCancelled">
                            <p i18n="current state BookingCancelled">The booking has been cancelled.</p>
                        </div>

                        <!-- State BookingDenied -->
                        <div *ngIf="transaction.state === transactionState.BookingDenied">
                            <p *ngIf="userIsBorrower()" i18n="current state BookingDenied userIsBorrower">{{ lender?.displayName }} has denied your
                                booking. You can send them a message and ask, why they denied it.
                            </p>
                            <p *ngIf="userIsLender()" i18n="current state BookingDenied userIsLender">You have denied the booking. Please consider
                                sending {{ borrower?.displayName }} a message explaining, why you denied it.
                            </p>
                        </div>

                        <!-- State ItemPickUpRequestedByLender -->
                        <div *ngIf="transaction.state === transactionState.ItemPickUpRequestedByLender">
                            <div *ngIf="userIsLender();
              then userRequestedItemPickup;
              else otherPartyRequestedItemPickup;"></div>
                        </div>

                        <!-- State ItemPickUpRequestedByBorrower -->
                        <div *ngIf="transaction.state === transactionState.ItemPickUpRequestedByBorrower">
                            <div *ngIf="userIsBorrower();
              then userRequestedItemPickup;
              else otherPartyRequestedItemPickup;"></div>
                        </div>


                        <ng-template #otherPartyRequestedItemPickup>
                            <p i18n="otherPartyRequestedItemPickup">{{ getOtherUserPublic(user?.uid)?.displayName }} has marked the item
                                <i>{{ transaction.transactionListing.name }}</i> as picked up. Please confirm it now to complete the pickup process and help
                                documenting each step.
                            </p>
                            <p *ngIf="!transaction.newPeriodSuggestion">
                                <app-mat-icon-button [callback]="onConfirmItemPickup.bind(this)" [params]="[]"
                                                     color="primary" cssClass="mr-2 mb-3" i18n-label="Confirm pickup" icon="logout"
                                                     label="Confirm pickup"></app-mat-icon-button>
                                <app-mat-icon-button [callback]="onDeclineItemPickup.bind(this)" [params]="[]"
                                                     color="warn" cssClass="mr-2 mb-3" i18n-label="Decline pickup" icon="close"
                                                     label="Decline pickup"></app-mat-icon-button>
                            </p>
                        </ng-template>
                        <ng-template #userRequestedItemPickup>
                            <p i18n="userRequestedItemPickup">You have marked the item <i>{{ transaction.transactionListing.name }}</i> as picked up.
                                {{ getOtherUserPublic(user?.uid)?.displayName }} has not yet confirmed the pickup. Please ask them to confirm it shortly
                                to complete the pickup process and help documenting each step.</p>
                            <p *ngIf="!transaction.newPeriodSuggestion">
                                <app-mat-icon-button [callback]="onUndoItemPickup.bind(this)" [params]="[]"
                                                     color="warn" cssClass="mr-2 mb-3" i18n-label="Undo item pickup" icon="undo"
                                                     label="Undo item pickup"></app-mat-icon-button>
                            </p>
                        </ng-template>


                        <!-- State ItemPickedUp -->
                        <div *ngIf="transaction.state === transactionState.ItemPickedUp">
                            <p *ngIf="userIsBorrower()" i18n="current state ItemPickedUp userIsBorrower">You have picked up the item
                                <i>{{ transaction.transactionListing.name }}</i>. {{ lender?.displayName }} has confirmed the pickup.</p>
                            <p *ngIf="userIsLender()" i18n="current state ItemPickedUp userIsLender">{{ borrower?.displayName }} has picked up the item
                                <i>{{ transaction.transactionListing.name }}</i>. You have confirmed the pickup.</p>
                            <p *ngIf="!isReturnAvailable()" i18n="hint come back at return time">Please come back here at return time to confirm the
                                return of the item.</p>
                            <p *ngIf="!transaction.newPeriodSuggestion">
                                <app-mat-icon-button [callback]="onRequestItemReturn.bind(this)" [params]="[]"
                                                     color="primary" cssClass="mr-2 mb-3" i18n-label="Mark item as returned" icon="login"
                                                     label="Mark item as returned"></app-mat-icon-button>
                            </p>
                        </div>

                        <!-- State ItemReturnRequestedByLender -->
                        <div *ngIf="transaction.state === transactionState.ItemReturnRequestedByLender">
                            <div *ngIf="userIsLender();
              then userRequestedItemReturn;
              else otherPartyRequestedItemReturn;"></div>
                        </div>

                        <!-- State ItemReturnRequestedByBorrower -->
                        <div *ngIf="transaction.state === transactionState.ItemReturnRequestedByBorrower">
                            <div *ngIf="userIsBorrower();
              then userRequestedItemReturn;
              else otherPartyRequestedItemReturn;"></div>
                        </div>

                        <ng-template #otherPartyRequestedItemReturn>
                            <p i18n="otherPartyRequestedItemReturn">{{ getOtherUserPublic(user?.uid)?.displayName }} has
                                marked the item <i>{{ transaction.transactionListing.name }}</i> as returned. Please confirm it now to complete the return process
                                and help
                                documenting each step.
                            </p>
                            <p>
                                <app-mat-icon-button [callback]="onConfirmItemReturn.bind(this)" [params]="[]"
                                                     color="primary" cssClass="mr-2 mb-3" i18n-label="Confirm return" icon="logout"
                                                     label="Confirm return"></app-mat-icon-button>
                                <app-mat-icon-button [callback]="onDeclineItemReturn.bind(this)" [params]="[]"
                                                     color="warn" cssClass="mr-2 mb-3" i18n-label="Decline return" icon="close"
                                                     label="Decline return"></app-mat-icon-button>
                            </p>
                        </ng-template>
                        <ng-template #userRequestedItemReturn>
                            <p i18n="userRequestedItemReturn">You have marked the item
                                <i>{{ transaction.transactionListing.name }}</i> as returned. {{ getOtherUserPublic(user?.uid)?.displayName }} has not yet
                                confirmed the
                                return. Please ask them to
                                confirm it shortly to complete the return process and help documenting each step.
                            </p>
                            <p>
                                <app-mat-icon-button [callback]="onUndoItemReturn.bind(this)" [params]="[]"
                                                     color="warn" cssClass="mr-2 mb-3" i18n-label="Undo item return" icon="undo"
                                                     label="Undo item return"></app-mat-icon-button>
                            </p>
                        </ng-template>

                        <!-- State ItemReturned -->
                        <div *ngIf="transaction.state === transactionState.ItemReturned">
                            <p *ngIf="userIsBorrower()" i18n="current state ItemReturned userIsBorrower">You have returned the item
                                <i>{{ transaction.transactionListing.name }}</i>. {{ lender?.displayName }} has confirmed the return.</p>
                            <p *ngIf="userIsLender()" i18n="current state ItemReturned userIsLender">{{ borrower?.displayName }} has returned the item
                                <i>{{ transaction.transactionListing.name }}</i>. You have confirmed the return.</p>
                            <p i18n="transaction complete">The transaction has been completed.</p>

                            <p *ngIf="userIsBorrower() && !transaction.ratingByBorrowerUid">
                                <app-mat-icon-button *ngIf="lender && !showRateComponent" [callback]="onRate.bind(this)" [params]="[lender?.uid]"
                                                     color="primary" cssClass="mr-2 mb-3" i18n-label="Rate lender" icon="star"
                                                     label="Rate {{lender?.displayName}}"></app-mat-icon-button>
                            </p>
                            <p *ngIf="userIsLender() && !transaction.ratingByLenderUid">
                                <app-mat-icon-button *ngIf="borrower && !showRateComponent" [callback]="onRate.bind(this)" [params]="[borrower?.uid]"
                                                     color="primary" cssClass="mr-2 mb-3" i18n-label="Rate borrower" icon="star"
                                                     label="Rate {{borrower?.displayName}}"></app-mat-icon-button>
                            </p>

                            <mat-card *ngIf="showRateComponent && ratingReceiver" class="mb-3">
                                <app-rate (onClose)="showRateComponent=false" (onRatingSent)="onRatingSent($event, ratingReceiver.uid)"
                                          [listingImgUrlThumb]="transaction.transactionListing.imgUrlThumb"
                                          [listingName]="transaction.transactionListing.name" [listingUid]="transaction.listingUid"
                                          [receiverImgUrlThumb]="ratingReceiver.imgUrlThumb" [receiverName]="ratingReceiver.displayName"
                                          [receiverRole]="userIsLender() ? transactionRole.Borrower : transactionRole.Lender"
                                          [receiverUid]="ratingReceiver.uid" [transactionUid]="transaction.uid"></app-rate>
                            </mat-card>
                        </div>

                        <!-- If the lender suggested a new booking period -->
                        <div *ngIf="transaction.newPeriodSuggestion?.suggester === transactionRole.Lender">
                            <div *ngIf="userIsLender();
              then userSuggestedNewBookingPeriod;
              else otherPartySuggestedNewBookingPeriod;"></div>
                        </div>

                        <!-- If the borrower suggested a new booking period -->
                        <div *ngIf="transaction.newPeriodSuggestion?.suggester === transactionRole.Borrower">
                            <div *ngIf="userIsBorrower();
              then userSuggestedNewBookingPeriod;
              else otherPartySuggestedNewBookingPeriod;"></div>
                        </div>


                        <ng-template #otherPartySuggestedNewBookingPeriod>
                            <mat-card class="mb-3">
                                <h3 class="paragraph-headline-3" i18n>New booking period suggestion</h3>
                                <p i18n="otherPartySuggestedNewBookingPeriod">{{ getOtherUserPublic(user?.uid)?.displayName }} has
                                    suggested another booking period. You can now accept the new booking period, send them a counter suggestion or deny it.
                                </p>
                                <ng-container *ngTemplateOutlet="newBookingPeriod"></ng-container>
                                <p>
                                    <app-mat-icon-button [callback]="onAcceptNewBookingPeriod.bind(this)" [params]="[]"
                                                         color="primary" cssClass="mr-2 mb-3" i18n-label="Accept booking period change" icon="event_available"
                                                         label="Accept booking period change"></app-mat-icon-button>
                                    <app-mat-icon-button *ngIf="!showBookingDateSelection" [callback]="onSuggestAnotherBookingPeriod.bind(this)" [params]="[]"
                                                         color="accent" cssClass="mr-2 mb-3" i18n-label="Suggest another time" icon="edit_calendar"
                                                         label="Suggest another time"></app-mat-icon-button>
                                    <app-mat-icon-button *ngIf="!showBookingDateSelection" [callback]="onDenyNewBookingBookingPeriod.bind(this)" [params]="[]"
                                                         color="warn" cssClass="mr-2 mb-3" i18n-label="Deny booking period change" icon="event_busy"
                                                         label="Deny booking period change"></app-mat-icon-button>
                                </p>
                            </mat-card>
                        </ng-template>
                        <ng-template #userSuggestedNewBookingPeriod>
                            <mat-card class="mb-3">
                                <h3 class="paragraph-headline-3" i18n>New booking period suggestion</h3>
                                <p i18n="userSuggestedNewBookingPeriod">You have suggested another booking
                                    period. {{ getOtherUserPublic(user?.uid)?.displayName }} has
                                    to accept the new time, send you a counter suggestion or deny it. You can also retract the suggestion.
                                </p>
                                <ng-container *ngTemplateOutlet="newBookingPeriod"></ng-container>
                                <p>
                                    <app-mat-icon-button [callback]="onSuggestAnotherBookingPeriod.bind(this)" [params]="[]"
                                                         color="accent" cssClass="mr-2 mb-3" i18n-label="Suggest another time" icon="edit_calendar"
                                                         label="Suggest another time"></app-mat-icon-button>
                                    <app-mat-icon-button [callback]="onRetractBookingPeriodSuggestion.bind(this)" [params]="[]"
                                                         color="warn" cssClass="mr-2 mb-3" i18n-label="Retract booking period change" icon="undo"
                                                         label="Retract booking period change"></app-mat-icon-button>
                                </p>
                            </mat-card>
                        </ng-template>

                        <ng-template #newBookingPeriod>
                            <table class="table table-striped table-sm table-responsive">
                                <tr *ngIf="transaction && transaction.newPeriodSuggestion && transaction.newPeriodSuggestion.pickupDate">
                                    <th i18n>New pickup date:</th>
                                    <td>{{ transaction.newPeriodSuggestion.pickupDate.toDate() | moment: 'LLLL' }}</td>
                                </tr>
                                <tr *ngIf="transaction && transaction.newPeriodSuggestion && transaction.newPeriodSuggestion.returnDate">
                                    <th i18n>New return date:</th>
                                    <td>{{ transaction.newPeriodSuggestion.returnDate.toDate() | moment: 'LLLL' }}</td>
                                </tr>
                                <tr *ngIf="getCurrentBookingDuration() as duration">
                                    <th i18n>Current rent period:</th>
                                    <td>
                                        {duration, plural, =0 {days} =1 {day} other {{{ duration | number:'1.0-2':numberFormatLocale }} days}}
                                    </td>
                                </tr>
                                <tr *ngIf="transaction && transaction.newPeriodSuggestion && transaction.newPeriodSuggestion.numberOfDays as duration">
                                    <th i18n>New rent period:</th>
                                    <td>
                                        {duration, plural, =0 {days} =1 {day} other {{{ duration | number:'1.0-2':numberFormatLocale }} days}}
                                    </td>
                                </tr>
                                <tr *ngIf="getSuggestedBookingPrice() as price">
                                    <th i18n>New rent price:</th>
                                    <td>
                                        <app-money [currencyId]="transaction.currencyId" [value]="price"></app-money>
                                    </td>
                                </tr>
                            </table>
                        </ng-template>

                        <p *ngIf="userIsBorrower() && !showWriteMessageComponent">
                            <app-mat-icon-button *ngIf="lender" [callback]="onSendMessage.bind(this)" [params]="[lender.uid]"
                                                 color="accent" cssClass="mr-2 mb-3" i18n-label="Send message to lender" icon="message"
                                                 label="Send message to {{lender?.displayName}}"></app-mat-icon-button>
                        </p>
                        <p *ngIf="userIsLender() && !showWriteMessageComponent">
                            <app-mat-icon-button *ngIf="borrower" [callback]="onSendMessage.bind(this)" [params]="[borrower.uid]"
                                                 color="accent" cssClass="mr-2 mb-3" i18n-label="Send message to borrower" icon="message"
                                                 label="Send message to {{borrower?.displayName}}"></app-mat-icon-button>
                        </p>

                        <mat-card *ngIf="showBookingDateSelection" class="mb-3">
                            <app-booking-date-selection [(visible)]="showBookingDateSelection" [transaction]="transaction"
                                                        [updateBookingPeriod]="updateBookingPeriod.bind(this)"></app-booking-date-selection>
                        </mat-card>

                        <mat-card *ngIf="showWriteMessageComponent" class="mb-3">
                            <app-write-message (onClose)="showWriteMessageComponent=false" (onMessageSent)="onMessageSent($event)"
                                               [listingUid]="transaction.listingUid" [receiverUid]="messageReceiverUid"></app-write-message>
                        </mat-card>

                        <app-safety-tips
                                *ngIf="(transaction.state === transactionState.BookingAccepted || transaction.state === transactionState.ItemPickUpRequestedByBorrower ||
                      transaction.state === transactionState.ItemPickUpRequestedByLender) && userIsLender()"
                                [borrowerName]="borrower?.displayName"></app-safety-tips>

                    </div>
                </div>
            </div>
        </div>


        <!-- Lender's address -->
        <div *ngIf="userIsBorrower() && transaction?.paymentState === 'PAID'" class="w-100 card mb-3">
            <div class="row no-gutters">

                <div class="col">
                    <div class="card-body">
                        <h2 class="paragraph-headline-2" i18n="Pickup and return address">Pickup and return address</h2>
                        <p i18n>Address, where <i>{{ transaction.transactionListing.name }}</i> can be picked up and returned to.</p>
                        <app-mat-icon-button *ngIf="!lenderAddress"
                                             [callback]="onLoadAddress.bind(this)" [params]="[lender?.uid]"
                                             color="primary" cssClass="mr-2 mb-3" i18n-label="Load address" icon="place"
                                             label="Load address"></app-mat-icon-button>
                        <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [message]="loadingSpinnerMessage"></app-loading-spinner>
                        <div *ngIf="lenderAddress as address">

                            <!-- Address and map -->
                            <div class="row mb-3">
                                <div class="col min-w-350px">
                                    <app-address-table [address]="lenderAddress" [phone]="lenderPhone"></app-address-table>
                                </div>
                                <div *ngIf="address.coords && address.coords.lat && address.coords.lng" class="col min-w-350px">
                                    <app-map [disabled]="true" [lat]="address.coords.lat" [lng]="address.coords.lng" [showMarker]="true"></app-map>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="lenderPhone && !lenderAddress">
                            <span i18n>Phone number: </span>{{ lenderPhone }}
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="card-deck">

            <!-- Lender -->
            <div *ngIf="lender" class="info-card card mb-3">
                <div class="row no-gutters">
                    <div>
                        <a [routerLink]="['/social','user',lender.uid]">
                            <img alt="Lender image" class="contained-200h-150w" default="./assets/images/default/default-user-w300.webp"
                                 i18n-alt="Lender image alt text"
                                 src="{{lender.imgUrl}}"></a>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <h2 class="paragraph-headline-2" i18n="lender headline">Lender</h2>
                            <p *ngIf="userIsLender()" i18n="you are the lender">You are the lender.</p>
                            <p *ngIf="!userIsLender()">{{ lender.displayName }}</p>
                            <app-user-info-internal [userPublic]="lender"></app-user-info-internal>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Borrower -->
            <div *ngIf="borrower" class="info-card card mb-3">
                <div class="row no-gutters">
                    <div>
                        <a [routerLink]="['/social','user',borrower.uid]">
                            <img alt="Borrower image" class="contained-200h-150w" default="./assets/images/default/default-user-w300.webp"
                                 i18n-alt="Borrower image alt text"
                                 src="{{borrower.imgUrl}}"></a>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <h2 class="paragraph-headline-2" i18n="borrower headline">Borrower</h2>
                            <p *ngIf="userIsBorrower()" i18n="you are the borrower">You are the borrower.</p>
                            <p *ngIf="!userIsBorrower()">{{ borrower.displayName }}</p>
                            <app-user-info-internal [userPublic]="borrower"></app-user-info-internal>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Booking date -->
            <div class="info-card card mb-3">
                <div class="row no-gutters">
                    <div>
                        <span class="material-icons card-icon text-primary">assignment_turned_in</span>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <h2 class="paragraph-headline-2" i18n="booking date headline">Booking date</h2>
                            <p>{{ transaction.bookingDate.toDate() | moment:'LLLL' }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Planned pickup and return dates -->
            <div class="info-card card mb-3">
                <div class="row no-gutters">
                    <div>
                        <span class="material-icons card-icon text-primary">date_range</span>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <h2 class="paragraph-headline-2" i18n="planned pickup and return headline">Planned pickup and return dates</h2>
                            <p>{{ transaction.targetPickupDate.toDate() | moment:'LLLL' }}</p>
                            <p>{{ transaction.targetReturnDate.toDate() | moment:'LLLL' }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Actual pickup date -->
            <div *ngIf="transaction.actualPickupDate" class="info-card card mb-3">
                <div class="row no-gutters">
                    <div>
                        <span class="material-icons card-icon text-primary">logout</span>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <h2 class="paragraph-headline-2" i18n="actual pickup date">Actual pickup date</h2>
                            <p>{{ transaction.actualPickupDate.toDate() | moment:'LLLL' }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Actual return date -->
            <div *ngIf="transaction.actualReturnDate" class="info-card card mb-3">
                <div class="row no-gutters">
                    <div>
                        <span class="material-icons card-icon text-primary">login</span>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <h2 class="paragraph-headline-2" i18n="actual return date headline">Actual return date</h2>
                            <p>{{ transaction.actualReturnDate.toDate() | moment:'LLLL' }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Rating from lender -->
            <div *ngIf="lender" class="info-card card mb-3">
                <div class="row no-gutters">
                    <div>
                        <span class="material-icons card-icon text-primary">star</span>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <h2 class="paragraph-headline-2" i18n="Rating from lender headline">Rating from {{ lender?.displayName }}</h2>
                            <app-rating [linkRating]="true" [rating]="ratingByLender"></app-rating>
                            <p *ngIf="!transaction.ratingByLenderUid && userIsLender()" i18n>You have not yet rated {{ borrower?.displayName }}.</p>
                            <p *ngIf="!transaction.ratingByLenderUid && userIsBorrower()" i18n>{{ lender?.displayName }} has not yet rated you.</p>
                            <app-mat-icon-button
                                    *ngIf="borrower && !transaction.ratingByLenderUid && userIsLender() && transaction.state === transactionState.ItemReturned"
                                    [callback]="onRate.bind(this)" [params]="[borrower?.uid]"
                                    color="primary" cssClass="mr-2 mb-3" i18n-label="Rate lender" icon="star"
                                    label="Rate {{borrower?.displayName}}"></app-mat-icon-button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Rating from borrower -->
            <div *ngIf="borrower" class="info-card card mb-3">
                <div class="row no-gutters">
                    <div>
                        <span class="material-icons card-icon text-primary">star</span>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <h2 class="paragraph-headline-2" i18n="Rating from borrower headline">Rating from {{ borrower?.displayName }}</h2>
                            <app-rating [linkRating]="true" [rating]="ratingByBorrower"></app-rating>
                            <p *ngIf="!transaction.ratingByBorrowerUid && userIsLender()" i18n>{{ borrower?.displayName }} has not yet rated you.</p>
                            <p *ngIf="!transaction.ratingByBorrowerUid && userIsBorrower()" i18n>You have not yet rated {{ lender?.displayName }}.</p>
                            <app-mat-icon-button
                                    *ngIf="lender && !transaction.ratingByBorrowerUid && userIsBorrower() && transaction.state === transactionState.ItemReturned"
                                    [callback]="onRate.bind(this)" [params]="[lender?.uid]"
                                    color="primary" cssClass="mr-2 mb-3" i18n-label="Rate lender" icon="star"
                                    label="Rate {{lender?.displayName}}"></app-mat-icon-button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Rent price -->
            <div class="info-card card mb-3">
                <div class="row no-gutters">
                    <div>
                        <span class="material-icons card-icon text-primary">paid</span>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <h2 class="paragraph-headline-2" i18n="rent price headline">Rent price</h2>

                            <table *ngIf="transaction.currencyId && transaction.numberOfDays !== undefined &&
              transaction.pricePerDay !== undefined" class="table table-striped table-sm">
                                <tbody>
                                <tr>
                                    <td i18n="number of days">Number of days</td>
                                    <td>
                                        {{ transaction.numberOfDays | number:'1.0-2':numberFormatLocale }}
                                    </td>
                                </tr>
                                <tr *ngIf="transaction.pricePerDay">
                                    <td i18n="price per day">Price per day</td>
                                    <td>
                                        <app-money [currencyId]="transaction.currencyId" [value]="transaction.pricePerDay"></app-money>
                                    </td>
                                </tr>
                                </tbody>
                                <tr>
                                    <th i18n="Total price">Total price</th>
                                    <th>
                                        <app-money [currencyId]="transaction.currencyId"
                                                   [value]="transaction.pricePerDay*transaction.numberOfDays"></app-money>
                                    </th>
                                </tr>
                                <tr *ngIf="transaction.paidAmount && transaction.currencyId">
                                    <td i18n="borrower has paid">{{ borrower?.displayName }} paid</td>
                                    <td>
                                        <app-money [currencyId]="transaction.currencyId"
                                                   [value]="mangopayService.convertAmount(transaction.paidAmount, transaction.currencyId, true)"></app-money>
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Latest message -->
            <div class="info-card card mb-3">
                <div class="row no-gutters">
                    <div>
                        <span class="material-icons card-icon text-primary">message</span>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <h2 class="paragraph-headline-2" i18n="latest message headline">Latest message</h2>
                            <div *ngIf="latestMessage">
                                <div class="row">
                                    <div class="col-auto mr-0 pr-0">
                                        <app-user-thumb [circle]="true" [size]="70" [userUid]="latestMessage.senderUid"></app-user-thumb>
                                    </div>
                                    <div class="col">
                                        <p><a [routerLink]="['/account','messages',conversation?.uid]">{{ latestMessage.message }}</a></p>
                                        <p
                                                [title]="latestMessage.date.toDate() | moment:'LLLL'"
                                                class="date">{{ utilService.createFromNowString(latestMessage.date.toDate()) }}</p>
                                    </div>
                                </div>


                            </div>
                            <p *ngIf="!latestMessage" i18n="there are no messages">There are no messages yet.</p>

                        </div>
                    </div>
                </div>
            </div>


        </div>

        <h1 class="paragraph-headline" i18n="history headline">History</h1>
        <table *ngIf="transaction.logs as logs" class="table table-striped table-sm table-responsive">
            <thead>
            <th i18n="date">Date</th>
            <th i18n="user">User</th>
            <th i18n="transaction history log state|transaction history log state">State</th>
            <th i18n="message">Message</th>
            <th i18n="new pickup date">New planned pickup date</th>
            <th i18n="new return date">New planned return date</th>
            </thead>
            <tbody>
            <tr *ngFor="let log of logs">
                <td>{{ log.date.toDate()|moment:'LLLL' }}</td>
                <td>
                    <span *ngIf="log.actingUserUid === user?.uid" i18n>You</span>
                    <span *ngIf="log.actingUserUid !== user?.uid && log.actingUserUid === lender?.uid">{{ lender?.displayName }}</span>
                    <span *ngIf="log.actingUserUid !== user?.uid && log.actingUserUid === borrower?.uid">{{ borrower?.displayName }}</span>
                </td>
                <td>
                    <app-transaction-state [periodSuggestionState]="log.periodSuggestionState"
                                           [state]="log.state"></app-transaction-state>
                </td>
                <td>
                    <app-log-message [actingUserName]="log.actingUserUid === lender?.uid ? lender?.displayName : borrower?.displayName" [borrower]="borrower"
                                     [lender]="lender" [log]="log" [transaction]="transaction"></app-log-message>
                </td>
                <td>{{ log.targetPickupDate ? (log.targetPickupDate.toDate()|moment:'LLLL') : '' }}</td>
                <td>{{ log.targetReturnDate ? (log.targetReturnDate.toDate()|moment:'LLLL') : '' }}</td>
            </tr>
            </tbody>
        </table>

    </div>
</div>
