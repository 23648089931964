<div *ngIf="shouldShowFirstSteps() && !locationContainsFirstSteps(window.location.href)" class="alert alert-info flex-left"><span
  i18n="You have not yet completed your account setup">You have not yet completed your account setup. We need some
  more information from you, before you can start renting things. Please go to <a [routerLink]="['/account','firstSteps']">first steps</a> to complete the
setup.</span>
  <div class="alert-box-actions">
    <button (click)="showAccountWarning = false" aria-label="Close alert"
            i18n-aria-label="close alert" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
