import {Component, OnDestroy, OnInit} from '@angular/core';
import {BrowserService} from '../browser.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Search} from '../../shared/models/search.interface';
import {Listing} from '../../shared/models/listing.interface';
import {takeUntil} from 'rxjs/operators';
import {TitleService} from '../../shared/services/title.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {NgCacheRouteReuseService} from 'ng-cache-route-reuse';
import {MetadataService} from '../../shared/services/metadata.service';
import {selectSearchListings} from '../store/browser.selectors';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent extends BaseComponent implements OnInit, OnDestroy {

  listings: Listing[] = [];
  totalCount = 0;
  thereIsMore = false;
  searching = false;
  periodInDays = 1;
  error: string | undefined;
  search: Search | undefined;
  showSearchHint = true;

  destroy$: Subject<null> = new Subject();
  searchListings$ = this.store.select(selectSearchListings).pipe(takeUntil(this.destroy$));

  constructor(protected store: Store<AppState>,
              private browserService: BrowserService,
              private activatedRoute: ActivatedRoute,
              private cacheRouteReuseService: NgCacheRouteReuseService,
              private router: Router,
              private metadataService: MetadataService,
              private titleService: TitleService) {
    super(store);
  }

  ngOnInit(): void {

    this.setDefaultTitle();

    this.cacheRouteReuseService
        .onAttach(SearchComponent)
        .subscribe((component) => {
          this.setDefaultTitle();
          this.resetCategory();
          this.initSearchListingsListener();
        });

    this.cacheRouteReuseService
        .onDetach(SearchComponent)
        .subscribe((component) => {
          this.ngOnDestroy();
        });

    this.initSearchListingsListener();

    this.resetCategory();

    this.browserService.periodInDaysSubject.pipe(takeUntil(this.destroy$)).subscribe(periodInDays => {
      this.periodInDays = periodInDays;
    });
    this.metadataService.updateTags($localize`Search`,
        $localize`You can use the search to find items to rent. Results can be filtered by location and availability.`,
        $localize`search listings filter location period`,
    );
  }

  private initSearchListingsListener() {
    this.searchListings$.subscribe((searchListings) => {
      if (searchListings.searching)
        this.enableLoadingSpinner($localize`Searching...`);
      else
        this.disableLoadingSpinner();
      this.showSearchHint = searchListings.searchResult === undefined && !this.showLoadingSpinner;
      this.error = searchListings.searchResult?.errorMessage;
      this.listings = searchListings.searchResult ? searchListings.searchResult.listings : [];
      this.thereIsMore = searchListings.searchResult ? searchListings.searchResult?.thereIsMore : false;
      this.totalCount = searchListings?.searchResult?.totalCount !== undefined ? searchListings.searchResult.totalCount : 0;
      this.searching = searchListings.searching;
      this.search = searchListings.search;

      if (this.search.searchTerm != null && this.search.searchTerm.length > 0)
        this.titleService.setTitle(this.search.searchTerm);
      else
        this.setDefaultTitle();

      this.periodInDays = searchListings.searchResult?.periodInDays ? searchListings.searchResult?.periodInDays : 1;
    });
  }

  /**
   * Requests more search listings
   * @param count requested number of additional listings
   */
  loadMoreListings(count: number): void {
    if (!this.listings || !this.thereIsMore || this.searching)
      return;
    this.browserService.fetchMoreSearchResults();
  }

  private resetCategory() {
    this.browserService.selectedCategorySubject.next(undefined);
  }

  private setDefaultTitle() {
    this.titleService.setTitle($localize`Search`);
  }
}
