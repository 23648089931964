import {Actions, createEffect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BrowserService} from '../browser.service';
import {
  fetchLatestListingsFromAlgolia,
  fetchMoreSearchResultsFromAlgolia,
  fetchSearchResultsFromAlgolia,
  runCurrentSearch,
  searchFailed,
  setMoreSearchResults,
  setSearchResults,
} from './browser.actions';
import {environment} from '../../../environments/environment';
import {from, Observable, of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import Util from '../../shared/util';

import {Listing} from '../../shared/models/listing.interface';
import {SearchResult} from '../../shared/models/searchResult.interface';
import {Search} from '../../shared/models/search.interface';
import {DocumentData, QueryDocumentSnapshot} from '@angular/fire/firestore';
import {Injectable} from '@angular/core';
import AlgoliaConverter from '../../shared/converters/algoliaConverter';
import {RequestOptions} from '../../shared/models/requestOptions.interface';
import algoliasearch from 'algoliasearch';
import {CategoryService} from '../../shared/services/category.service';
import {AnalyticsEventName, AnalyticsService} from '../../shared/services/analytics.service';
import {FacebookEventName, FacebookService} from '../../shared/services/facebook.service';
import {selectSearch} from './browser.selectors';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';

@Injectable()
export class BrowserEffects {

  searchLastDoc: QueryDocumentSnapshot<DocumentData> | undefined;

  client = algoliasearch(environment.algoliaAppId, environment.algoliaSearchKey);
  listingsIndex = this.client.initIndex(environment.algoliaListingsIndex);
  listingsIndexLastEditDate_desc = this.client.initIndex(environment.algoliaListingsIndex + '_lastEditDate_desc');
  listingsIndexCreationDate_desc = this.client.initIndex(environment.algoliaListingsIndex + '_creationDate_desc');
  listingsIndexPrice_asc = this.client.initIndex(environment.algoliaListingsIndex + '_price_asc');
  listingsIndexPrice_desc = this.client.initIndex(environment.algoliaListingsIndex + '_price_desc');

  fetchLatestListingsFromAlgolia = createEffect(() => this.actions.pipe(
          ofType(fetchLatestListingsFromAlgolia),
          withLatestFrom(this.store.select(selectSearch)),
          switchMap((actions) => {
            const search = actions[1];
            return Util.auxObservable(fetchSearchResultsFromAlgolia({search}));
          }),
      ),
  );

  runCurrentSearch = createEffect(() => this.actions.pipe(
          ofType(runCurrentSearch),
          withLatestFrom(this.store.select(selectSearch)),
          switchMap((actions) => {
            const search = actions[1];
            return Util.auxObservable(fetchSearchResultsFromAlgolia({search}));
          }),
      ),
  );

  fetchSearchResultsFromAlgolia = createEffect(() => this.actions.pipe(
      ofType(fetchSearchResultsFromAlgolia, fetchMoreSearchResultsFromAlgolia),
      withLatestFrom(this.store.select(selectSearch)),
      switchMap(action => this.handleSearch(action[1])),
  ));

  constructor(private actions: Actions,
              private http: HttpClient,
              private router: Router,
              private browserService: BrowserService,
              private categoryService: CategoryService,
              private facebookService: FacebookService,
              private analyticsService: AnalyticsService,
              private store: Store<AppState>) {
  }

  /**
   * Handles the Algolia search request and maps the response to handleSearchResponse.
   * @param actions store actions object
   * @return Observable of Algolia request
   */
  private handleSearch(search: Search): Observable<any> {
    const limit = search.limit ? search.limit : environment.defaultSearchListingsCount;
    this.analyticsService.logEvent(AnalyticsEventName.SEARCH, {searchTerm: search.searchTerm});
    if (search.searchTerm)
      this.facebookService.logEvent(FacebookEventName.Search, {
        search_string: search.searchTerm,
        content_category: search.categoryId,
      });
    const requestOptions: Promise<RequestOptions> = this.createRequestOptions(search, limit);
    if (search.clearCache)
        // Clears the algolia cache (see https://www.algolia.com/doc/guides/building-search-ui/going-further/improve-performance/angular/#caching)
      this.client.clearCache();
    return from(requestOptions.then(reqOpt => this.getListingsIndex(search.sortIndex).search(search.searchTerm ? search.searchTerm : '', reqOpt)))
        .pipe(
            map(response => handleSearchResponse(response, search, limit)),
            catchError((errorResponse) => handleSearchError(errorResponse)),
        );
  }

  /**
   * Delivers the appropriate Algolia index for the given sort index.
   * See https://www.algolia.com/doc/guides/managing-results/refine-results/sorting/how-to/search-in-a-replica-index/
   * @param sortIndex suffix of the sort index
   * @return the appropriate index
   */
  private getListingsIndex(sortIndex?: string) {
    if (!sortIndex)
      return this.listingsIndex;

    switch (sortIndex) {
      case 'lastEditDate_desc':
        return this.listingsIndexLastEditDate_desc;
      case 'creationDate_desc':
        return this.listingsIndexCreationDate_desc;
      case 'price_asc':
        return this.listingsIndexPrice_asc;
      case 'price_desc':
        return this.listingsIndexPrice_desc;
      default:
        return this.listingsIndex;
    }

  }

  /**
   * Creates the Requests Options for the given search and returns them.
   *
   * @param search search parameters
   * @return request options
   */
  private async createRequestOptions(search: Search, limit: number): Promise<RequestOptions> {
    const requestOptions: RequestOptions = {facetFilters: [], numericFilters: []};

    requestOptions.hitsPerPage = limit;
    if (search.offset)
      requestOptions.page = (search.offset / limit);

    if (search.coords?.lat && search.coords?.lng)
      requestOptions.aroundLatLng = `${search.coords?.lat}, ${search.coords?.lng}`;
    if (search.radius) {
      requestOptions.aroundRadius = search.radius * 1000; // meters
      if (requestOptions.aroundRadius < 1000)
          // Minimum 1000 meters
        requestOptions.aroundRadius = 1000;
    }
    if (search.categoryId) {
      const wrapper = await this.categoryService.getCategoriesById();
      if (!wrapper.data)
        throw new Error($localize`Error loading category\: ${wrapper.errorMessage}`);
      const category = wrapper.data?.get(search.categoryId);
      if (category) {
        const categoryIdFacets: string[] = [];
        categoryIdFacets.push(`categoryId:${category.id}`);
        const subcategories = this.categoryService.getSubcategories(category);
        const subcategoriesLinear = this.categoryService.getCategoriesLinear(subcategories);
        for (const subcategory of subcategoriesLinear) {
          categoryIdFacets.push(`categoryId:${subcategory.id}`);
        }
        requestOptions?.facetFilters?.push(categoryIdFacets);
      }
    }
    if (search.lenderUid)
      requestOptions.facetFilters?.push(`lenderUid:${search.lenderUid}`);

    if (search.dateFrom)
      requestOptions.numericFilters?.push(`availableFrom <= ${search.dateFrom.getTime()}`);
    if (search.dateUntil)
      requestOptions.numericFilters?.push(`availableUntil >= ${search.dateUntil.getTime()}`);
    return requestOptions;
  }


}

const handleSearchResponse = (response: any, search: Search, limit: number) => {
  const searchResponse = response;
  const listings: Listing[] = [];
  searchResponse.hits.forEach((hit: any) => listings.push(AlgoliaConverter.convertAlgoliaHitToListing(hit)));
  const offset = search.offset ? search.offset : 0;
  const searchResult: SearchResult = {listings, thereIsMore: searchResponse.nbHits > limit + offset, totalCount: searchResponse.nbHits};
  if (!search.offset || search.offset === 0)
    return setSearchResults({searchResult});
  return setMoreSearchResults({searchResult});
};

const handleSearchError = (errorResponse: any) => {

  console.log('Searchfailed: ' + errorResponse?.message + ' | ' + errorResponse?.message);
  if (errorResponse?.code === 'permission-denied')
    return of(searchFailed({errorMessage: $localize`Fetching listing failed.` + ' ' + $localize`Not authorized.`, code: 403}));
  if (errorResponse?.status === 401)
    return of(searchFailed({errorMessage: $localize`Fetching listing failed.` + ' ' + $localize`Not authenticated.`}));
  if (errorResponse?.status === 0)
    return of(searchFailed(
        {errorMessage: $localize`Fetching listing failed.` + ' ' + $localize`Blitzshare is currently not available. Please try again later.`}));
  return of(searchFailed({errorMessage: $localize`Fetching listing failed.` + ' ' + $localize`Something went wrong.`}));
};


