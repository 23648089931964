import {Component, Input, OnInit} from '@angular/core';
import {TransactionLog} from '../../../shared/models/transactionLog.interface';
import {TransactionState} from '../../../shared/enums/transactionState.enum';
import {Transaction} from '../../../shared/models/transaction.interface';
import {MangopayService} from '../../../pay/mangopay.service';
import {EmbeddedTransaction} from '../../../shared/models/embeddedTransaction.interface';
import {UserPublic} from '../../../shared/models/userPublic.interface';

@Component({
  selector: 'app-log-message',
  templateUrl: './log-message.component.html',
  styleUrls: ['./log-message.component.css'],
})
export class LogMessageComponent implements OnInit {

  @Input() log?: TransactionLog;
  @Input() transaction?: Transaction | EmbeddedTransaction;
  @Input() actingUserName?: string;
  @Input() borrower?: UserPublic;
  @Input() lender?: UserPublic;

  constructor(
    public mangopayService: MangopayService,
  ) {
  }

  public get transactionState(): typeof TransactionState {
    return TransactionState;
  }

  ngOnInit(): void {
  }

}
