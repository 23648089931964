import {createAction, props} from '@ngrx/store';
import {User} from '../../shared/models/user.interface';
import {FirebaseUser} from '../../shared/models/firebaseUser.interface';

export const clearUser = createAction('[Auth] Clearing firestore user');

// Fetching user
export const fetchUser = createAction('[Auth] Fetching firestore user');
export const resetFetchUserState = createAction('[Auth] Resetting fetchUserErrorMessage and fetchUserSuccessful');
export const fetchUserSucceeded = createAction('[Auth] Fetching user succeeded. Setting user', props<{ user: User }>());
export const fetchUserFailed = createAction('[Auth] Setting fetch firestore user error', props<{ fetchUserErrorMessage: string }>());

// Updating user
export const updateUser = createAction('[Auth] Updating user', props<{ user: User }>());
export const updateUserMerge = createAction('[Auth] Updating user with merge update', props<{ userUpdate: User }>());
export const updateUserSucceeded = createAction('[Auth] Updating user succeeded. Setting user', props<{ user: User }>());
export const updateUserFailed = createAction('[Auth] Setting update firestore user error', props<{ updateUserErrorMessage: string }>());
export const updateUserFromFirebaseUserSkipped = createAction('[Auth] User does not need to be updated from the firebase user');
export const updateUserFromFirebaseUserFailed = createAction('[Auth] Setting update firestore user error after trying to update from firebase user', props<{ updateUserErrorMessage: string }>());
export const resetUpdateUserState = createAction('[Auth] Resetting updateUserErrorMessage and updateUserSuccessful flag');

export const setFirebaseUser = createAction('[Auth] Setting Firebase user', props<{ firebaseUser: FirebaseUser }>());
export const clearFirebaseUser = createAction('[Auth] Clearing Firebase user');

