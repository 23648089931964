<div *ngIf="listing">
  <h1 class="paragraph-headline" i18n="price table headline on listing view">Price table</h1>
  <p i18n="price per day range">From
    <app-money [currencyId]="currencyId" [value]="listing.lowestPricePerDay"></app-money>
    to
    <app-money [currencyId]="currencyId" [value]="listing.highestPricePerDay"></app-money>
    per day.
  </p>
  <table class="table table-striped table-sm">
    <thead>
    <tr>
      <th i18n="Minimum period">Minimum period</th>
      <th i18n="Price per day">Price per day</th>
      <th i18n="Total price">Total price</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let price of listing.prices">
      <td i18n="number of min days for price">{price.minDays, plural, =0 {days} =1 {1 day} other {{{price.minDays}} days}}</td>
      <td>
        <app-money [currencyId]="currencyId" [value]="price.pricePerDay"></app-money>
      </td>
      <td>
        <app-money [currencyId]="currencyId" [value]="price.pricePerDay * price.minDays"></app-money>
    </tr>
    </tbody>
  </table>
</div>
