<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [message]="loadingSpinnerMessage"
                     [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

<div *ngIf="referral" class="component-container">
  <button (click)="onCancelClick()" aria-label="Cancel" class="cancel-button" i18n-aria-label="Cancel" i18n-matTooltip="Cancel" id="edit-referral"
          mat-icon-button matTooltip="Cancel">
    <mat-icon>close</mat-icon>
  </button>

  <h1 class="paragraph-headline" i18n="Edit referral">Edit referral</h1>

  <app-info-card i18n="info editing referral" icon="info">You only change the name of your referral link. Changes take effect immediately. You will not lose any recruited users.<br>
    Instead of changing an existing referral link, you can also create a new one (up to a limit of {{maxRefLinks}}).
  </app-info-card>

  <app-info-card i18n="info editing referral" icon="warning">If you delete a referral link, you will lose all recruited users forever.</app-info-card>


  <div class="mb-3">
    <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
  </div>

  <form (ngSubmit)="onSave()" *ngIf="editReferralForm !== undefined" [formGroup]="editReferralForm">

    <mat-form-field class="mat-form-input">
      <mat-label i18n="Referral name">Referral name</mat-label>
      <input #referralLinkNameRef [maxlength]="referralLinkNameMaxLength" formControlName="referralName" matInput>
      <span class="character-counter">{{referralLinkNameRef.value.length}}/{{referralLinkNameMaxLength}}</span>
    </mat-form-field>

    <p *ngIf="referralLinkNameRef.value.trim().length>0; else lineBreak" class="align-self-end"><span i18n="your referral link">Your referral link:</span> {{getReferralLink(referralLinkNameRef.value)}}</p>

    <ng-template #lineBreak><br></ng-template>

    <app-mat-icon-button color="primary" cssClass="mr-2 mb-3"
                         i18n-label="Save referral" icon="save" label="Save referral"
                         type="submit"></app-mat-icon-button>
    <app-mat-icon-button [callback]="onDeleteClick.bind(this)" color="warn"
                         cssClass="mr-2 mb-3" i18n-label="delete referral" icon="delete" label="Delete referral"
                         type="button"></app-mat-icon-button>
    <app-mat-icon-button [callback]="onCancelClick.bind(this)" color="info"
                         cssClass="mr-2 mb-3" i18n-label="cancel" icon="close" label="Cancel"
                         type="button"></app-mat-icon-button>
  </form>
</div>
