import {Component, OnInit, ViewChild} from '@angular/core';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {SharedService} from '../../shared/services/shared.service';
import {TitleService} from '../../shared/services/title.service';
import firebase from 'firebase/app';
import {Listing} from '../../shared/models/listing.interface';
import {environment} from '../../../environments/environment';
import {QuerySnapshot} from '@angular/fire/firestore';
import * as moment from 'moment';
import {CANNOT_BE_UNDONE} from '../../shared/constants/strings';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Subject} from 'rxjs';
import {SettingsService} from '../../shared/services/settings.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import {UtilService} from '../../shared/util.service';
import Util from '../../shared/util';
import {BaseComponent} from '../../shared/components/base/base.component';

import {firestore} from '../../app.module';
import {ListingService} from '../../listing/listing.service';
import FirebaseError = firebase.FirebaseError;

@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss'],
})
export class DraftsComponent extends BaseComponent implements OnInit {
  drafts: Listing[] = [];

  // Table management
  @ViewChild(MatSort) sort: MatSort | undefined;
  dataSource?: MatTableDataSource<Listing>;
  displayedColumns: string[] = ['picture', 'name', 'address', 'creationDate', 'actions'];
  sortedData?: Listing[];
  matSortActive: string = 'name';
  matSortDirection: SortDirection = 'asc';

  // Tab management
  viewMode = 'grid';
  selectedIndex = 0;
  tabViewModes = ['grid', 'table'];
  tabSelectionSubject = new Subject<string>();

  constructor(protected store: Store<AppState>,
              private sharedService: SharedService,
              private titleService: TitleService,
              private listingService: ListingService,
              private settingsService: SettingsService,
              public utilService: UtilService) {
    super(store);
  }


  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`My drafts`);

    this.enableLoadingSpinner($localize`Loading drafts...`);

    this.user$.subscribe(user => {
      if (user?.uid)
        this.loadDrafts(user.uid);
      if (user?.settings?.listingsViewMode) {
        this.viewMode = user.settings.listingsViewMode;
        this.selectedIndex = this.tabViewModes.indexOf(this.viewMode);
      }
    });

    // Synchronize setting between different components using listings-view (latest-listings, components...)
    this.tabSelectionSubject.subscribe((viewMode) => {
      this.viewMode = viewMode;
      this.selectedIndex = this.tabViewModes.indexOf(this.viewMode);
    });

    this.sort?.sort({disableClear: false, id: 'address', start: 'desc'});

  }

  createMomentFromNowString(date: Date) {
    return moment(date).fromNow();
  }

  refresh(): void {
    this.clearAlerts();
    this.enableLoadingSpinner($localize`Refreshing drafts...`);
    if (this.user?.uid)
      this.loadDrafts(this.user.uid);
  }

  onDelete(listing: Listing): void {
    this.clearAlerts();
    const imageUrl = listing?.imgUrls && listing.imgUrls[0].thumb ? listing.imgUrls[0].thumb : undefined;
    const title = listing?.name ? $localize`Are you sure you want to delete the draft '${listing.name}'?` :
        $localize`Are you sure you want to delete this unnamed draft?`;
    this.utilService.showConfirmDialog(title, CANNOT_BE_UNDONE, this.delete.bind(this), [listing], undefined, undefined, imageUrl, 'no');
  }

  delete(listing: Listing) {
    if (!listing.uid)
      return;
    this.enableLoadingSpinner($localize`Deleting draft...`);
    this.listingService.deleteListing(listing.uid).then(() => {
          let index = this.drafts.indexOf(listing);
          this.drafts.splice(index, 1);
          if (this.sortedData) {
            index = this.sortedData.indexOf(listing);
            this.sortedData.splice(index, 1);
            this.dataSource = new MatTableDataSource(this.sortedData);
          }
          this.initTable();
          this.disableLoadingSpinner();
        },
        (reason: FirebaseError) => {
          this.addError($localize`Error deleting drafts\: ${reason.message}`);
          this.disableLoadingSpinner();
        });
  }

  onDeleteAll(): void {
    this.clearAlerts();

    if (this.drafts.length === 0)
      return;

    const title = $localize`Are you sure you want to delete all ${this.drafts.length} drafts?`;
    this.utilService.showConfirmDialog(title, CANNOT_BE_UNDONE, this.deleteAll.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  deleteAll(): void {
    for (const listing of this.drafts)
      this.delete(listing);
  }


  initTable(): void {
    this.dataSource = new MatTableDataSource(this.drafts);
    if (this.sort)
      this.dataSource.sort = this.sort;
  }

  onTabChange(event: MatTabChangeEvent): void {
    const value = this.tabViewModes[event.index];
    this.viewMode = value;
    this.tabSelectionSubject.next(value);
    if (this.user?.uid)
      this.settingsService.setStringValue('listingsViewMode', value, this.user.uid);
  }

  sortData(sort: Sort) {
    this.matSortActive = sort.active;
    this.matSortDirection = sort.direction;

    const data = this.drafts.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'picture':
          const aHasImg = a.imgUrls && a.imgUrls.length > 0 && a.imgUrls[0];
          const bHasImg = b.imgUrls && b.imgUrls.length > 0 && b.imgUrls[0];
          return Util.compare(aHasImg ? 1 : 0, bHasImg ? 1 : 0, isAsc);
        case 'name':
          return Util.compare(a.name ? a.name : '', b.name ? b.name : '', isAsc);
        case 'address':
          const aAddress = a.address?.formattedAddress ? a.address.formattedAddress : '';
          const bAddress = b.address?.formattedAddress ? b.address.formattedAddress : '';
          return Util.compare(aAddress, bAddress, isAsc);
        case 'creationDate':
          return Util.compare(a.creationDate?.toMillis() ? a.creationDate?.toMillis() : '', b.creationDate?.toMillis() ? b.creationDate?.toMillis() : '', isAsc);
        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.sortedData);
  }

  /**
   * Loads draft listings
   * @param user
   * @private
   */
  private loadDrafts(userUid: string) {
    firestore.collection(environment.firestoreCollectionListings)
        .where('draft', '==', true)
        .where('lenderUid', '==', userUid)
        .orderBy('creationDate', 'desc')
        .get()
        .then((querySnapshot: QuerySnapshot<Listing>) => {
              this.drafts.length = 0;
              querySnapshot.forEach((listingDoc) => {
                const listingWithId: Listing = {...listingDoc.data(), uid: listingDoc.id};
                this.drafts.push(listingWithId);
              });
              this.disableLoadingSpinner();
              this.initTable();
            },
            ((reason: FirebaseError) => {
              this.addError($localize`Error loading drafts\: ${reason.message}`);
            }),
        );
  }

}
