import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

@Directive({
  selector: '[appNotEmpty]',
  providers: [{provide: NG_VALIDATORS, useExisting: NotEmptyDirective, multi: true}],
})
export class NotEmptyDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    return notEmpty()(control);
  }
}

export function notEmpty(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const empty = control.value.length === 0;
    return empty ? {empty: {value: control.value}} : null;
  };
}
