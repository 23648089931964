import {createSelector} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';

export const selectBook = (state: AppState) => state.book;

export const selectListing = createSelector(
    selectBook,
    (bookState) => bookState.listing,
);
export const selectBookingMessage = createSelector(
    selectBook,
    (bookState) => bookState.bookingMessage,
);

