<div class="jumbotron mb-3">
  <h1 class="jumbotron-headline" i18n="My Blitzshare headline|My Blitzshare headline">My Blitzshare</h1>
  <p class="jumbotron-lead" i18n="My Blitzshare lead text">Manage your account, view your listings, your transactions, your messages, change your settings and
    more.</p>
</div>

<div *ngIf="shouldShowFirstSteps()" class="card-deck">
  <div class="card w-100 mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','firstSteps']" class="stretched-link">
          <span class="material-icons card-icon text-primary">flight_takeoff</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','firstSteps']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="listings headline">First steps</h2>
            <p class="card-text" i18n="listings lead text">Guided account setup to get started.</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card-deck">

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','listings']" class="stretched-link">
          <span class="material-icons card-icon text-primary">dashboard</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','listings']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="listings headline">My listings</h2>
            <p class="card-text" i18n="listings lead text">View and manage your listings.</p>
          </a>
        </div>
      </div>
    </div>
  </div>


  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','drafts']" class="stretched-link">
          <span class="material-icons card-icon text-primary">edit</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','drafts']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="drafts headline">My drafts</h2>
            <p class="card-text" i18n="drafts lead text">View and manage your listing drafts.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','lending']" class="stretched-link">
          <span class="material-icons card-icon text-primary">logout</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','lending']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="lending headline">Lending</h2>
            <p class="card-text" i18n="lending lead text">See, what you are lending to others, including past and future transactions.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','borrowing']" class="stretched-link">
          <span class="material-icons card-icon text-primary">login</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','borrowing']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="borrowing headline">Borrowing</h2>
            <p class="card-text" i18n="borrowing lead text">See, what you are borrowing from others, including past and future transactions.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="payService.isPaymentActive()" class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','payment-account']" class="stretched-link">
          <span class="material-icons card-icon text-primary">attach_money</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','payment-account']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="payment-account headline">My payment account</h2>
            <p class="card-text" i18n="payment-account lead text">Manage your payment account, which is used for payouts. You need to have a payment account
              in order to list items for rent.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','messages']" class="stretched-link">
          <span class="material-icons card-icon text-primary">message</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','messages']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="messages headline">My messages</h2>
            <p class="card-text" i18n="messages lead text">View and answer your messages.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','personalInfo']" class="stretched-link">
          <span class="material-icons card-icon text-primary">manage_accounts</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','personalInfo']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="personal info headline">My personal information</h2>
            <p class="card-text" i18n="personal info lead text">Edit your first and last name, birthday, gender, nationality, phone number, home address,
              user name, email address and user image.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','ratings']" class="stretched-link">
          <span class="material-icons card-icon text-primary">star</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','ratings']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="ratings headline">My ratings</h2>
            <p class="card-text" i18n="ratings lead text">See, how people rated you, and, how you rated other people. Also, see transactions, which you
              have not yet rated, or, for which you have not yet been rated.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','referral']" class="stretched-link">
          <span class="material-icons card-icon text-primary">person_add</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','referral']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="referral program headline">Referral program</h2>
            <p class="card-text" i18n="referral program lead text">Recruit new users and earn money. Create and manage referral links and see, how they perform.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','settings']" class="stretched-link">
          <span class="material-icons card-icon text-primary">settings</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','settings']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="settings headline">Settings</h2>
            <p class="card-text" i18n="settings lead text">Change Blitzshare settings.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>
