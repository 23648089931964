import {Component, Input, OnInit} from '@angular/core';
import {Listing} from '../../models/listing.interface';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
})
export class ItemCardComponent implements OnInit {

  @Input() listingName?: string;
  @Input() listingSummary?: string;
  @Input() listingUid?: string;
  @Input() listingImgUrlThumb?: string;

  constructor() {
  }

  _listing: Listing | undefined;

  get listing(): Listing | undefined {
    return this._listing;
  }

  @Input() set listing(listing: Listing | undefined) {
    this._listing = listing;
    if (this.listing) {
      this.listingName = this.listing.name;
      this.listingSummary = this.listing.summary;
      this.listingUid = this.listing.uid;
      if (this.listing.imgUrls)
        this.listingImgUrlThumb = this.listing.imgUrls[0]?.thumb;
    }
  }

  ngOnInit(): void {
  }

}
