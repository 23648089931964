import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {AccountComponent} from './account.component';
import {AccountRoutingModule} from './account-routing.module';
import {SharedModule} from '../shared/shared.module';
import {AccountOverviewComponent} from './account-overview/account-overview.component';
import {ListingsComponent} from './listings/listings.component';
import {MessagesComponent} from './messages/messages.component';
import {BrowserModule} from '../browser/browser.module';
import {SettingsComponent} from './settings/settings.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {DraftsComponent} from './drafts/drafts.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {TransactionComponent} from './transaction/transaction.component';
import {BookingDateSelection} from './transaction/booking-date-selection/booking-date-selection.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatInputModule} from '@angular/material/input';
import {ConversationPreviewComponent} from './messages/conversation-preview/conversation-preview.component';
import {ConversationComponent} from './messages/conversation/conversation.component';
import {MatButtonModule} from '@angular/material/button';
import {NotificationComponent} from './notifications-dialog/notification/notification.component';
import {NotificationsDialogComponent} from './notifications-dialog/notifications-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {LendingComponent} from './lending/lending.component';
import {TransactionListComponent} from './transaction-list/transaction-list.component';
import {SocialModule} from '../social/social.module';
import {BorrowingComponent} from './borrowing/borrowing.component';
import {LogMessageComponent} from './transaction/log-message/log-message.component';
import {MatSelectModule} from '@angular/material/select';
import {NameEmailComponent} from './personal-info/name-email/name-email.component';
import {NgxAuthFirebaseUIModule} from 'ngx-auth-firebaseui';
import {ImageComponent} from './personal-info/image/image.component';
import {RateComponent} from './transaction/rate/rate.component';
import {StarRatingModule} from 'angular-star-rating';
import {RatingsComponent} from './ratings/ratings.component';
import {PaymentAccountComponent} from './payment-account/payment-account.component';
import {PersonalInfoComponent} from './personal-info/personal-info.component';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MasterDataComponent} from './personal-info/master-data/master-data.component';
import {AngularIbanModule} from 'angular-iban';
import {SafetyTipsComponent} from './transaction/safety-tips/safety-tips.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FirstStepsComponent} from './first-steps/first-steps.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatStepperModule} from '@angular/material/stepper';
import {LayoutModule} from '../layout/layout.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ReferralComponent } from './referral/referral.component';
import { EditReferralComponent } from './referral/edit-referral/edit-referral.component';
import { CommissionViewComponent } from './referral/commission-view/commission-view.component';


@NgModule({
  declarations: [
    AccountComponent,
    AccountOverviewComponent,
    ListingsComponent,
    MessagesComponent,
    SettingsComponent,
    DraftsComponent,
    TransactionComponent,
    BookingDateSelection,
    ConversationPreviewComponent,
    ConversationComponent,
    NotificationComponent,
    NotificationsDialogComponent,
    LendingComponent,
    TransactionListComponent,
    BorrowingComponent,
    LogMessageComponent,
    MasterDataComponent,
    NameEmailComponent,
    ImageComponent,
    RateComponent,
    RatingsComponent,
    PaymentAccountComponent,
    PersonalInfoComponent,
    SafetyTipsComponent,
    FirstStepsComponent,
    ReferralComponent,
    EditReferralComponent,
    CommissionViewComponent,
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    SharedModule,
    BrowserModule,
    MatTabsModule,
    MatIconModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    SocialModule,
    MatSelectModule,
    NgxAuthFirebaseUIModule,
    StarRatingModule,
    MatCardModule,
    MatCheckboxModule,
    // https://www.npmjs.com/package/angular-iban
    AngularIbanModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatStepperModule,
    LayoutModule,
    MatTooltipModule,
  ],
  providers: [CurrencyPipe],
  exports: [],
})
export class AccountModule {
}
