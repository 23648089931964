import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rent-period-info',
  templateUrl: './rent-period-info.component.html',
  styleUrls: ['./rent-period-info.component.css']
})
export class RentPeriodInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
