<div class="container bg-container p-1">

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="Support headline">Support</h1>
    <p class="jumbotron-lead" i18n="Support lead text">If you need help, you can send us an email or browse through our FAQ articles.</p>
  </div>

  <!-- FAQs  -->
  <mat-card>
    <h1 class="paragraph-headline" i18n>Frequently asked questions</h1>
    <app-faqs></app-faqs>
  </mat-card>
  <br>
  <!-- Contact  -->
  <mat-card>
    <h1 class="paragraph-headline" i18n>Contact us</h1>
    <p i18n> Send us an email to <a href="mailto:support@blitzshare.de">support@blitzshare.de</a> and we'll get back to you quickly.</p>
  </mat-card>

</div>

