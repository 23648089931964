import {Directive, ElementRef, EventEmitter} from '@angular/core';
import {Subscription} from 'rxjs';
import {ElementScrollPercentageService} from '../services/element-scroll-percentage.service';


/**
 * Directive, that can observes scroll position of a component.
 * https://www.bennadel.com/blog/3446-monitoring-document-and-element-scroll-percentages-using-rxjs-in-angular-6-0-2.htm
 */
@Directive({
  selector: '[appElementScrollPercentage]',
})
export class ElementScrollPercentageDirective {


  public scrollPercentageEvent: EventEmitter<number>;

  private elementRef: ElementRef;
  private elementScrollPercentageService: ElementScrollPercentageService;
  private subscription?: Subscription;

  // I initialize the element scroll percentage directive.
  constructor(elementRef: ElementRef,
              elementScrollPercentageService: ElementScrollPercentageService) {

    this.elementRef = elementRef;
    this.elementScrollPercentageService = elementScrollPercentageService;

    this.scrollPercentageEvent = new EventEmitter();
    this.subscription = undefined;

  }

  // ---
  // PUBLIC METHODS.
  // ---

  // I get called once when the directive is being unmounted.
  public ngOnDestroy(): void {

    (this.subscription) && this.subscription.unsubscribe();

  }


  // I get called once after the inputs have been bound for the first time.
  public ngOnInit(): void {

    // The purpose of the directive is to act as the GLUE between the element scroll
    // service and the host element for this directive. Let's subscribe to the scroll
    // events and then pipe them into the output event for this directive.
    this.subscription = this.elementScrollPercentageService
      .getScrollAsStream(this.elementRef.nativeElement)
      .subscribe(
        (percent: number): void => {

          this.scrollPercentageEvent.next(percent);

        },
      )
    ;

  }
}
